import { ICustomFieldDataContext } from './custom-field-data-context';

export interface IContactDataContext {
    /**
     * Contact first name
     */
    firstName?: string;
    /**
     * Contact last name
     */
    lastName?: string;
    /**
     * Contact's linked company name
     */
    company?: string;
    /**
     * Contact's deal role
     */
    dealRole?: string;
    /**
     * Contact's notes
     */
    notes?: string;
    /**
     * Any contact custom fields
     */
    customFields: ICustomFieldDataContext[];
}

export class ContactDataContext {
    firstName?: string;
    lastName?: string;
    company?: string;
    dealRole?: string;
    notes?: string;
    customFields: ICustomFieldDataContext[];

    constructor(data: IContactDataContext) {
        this.firstName = data.firstName;
        this.lastName = data.lastName;
        this.company = data.company;
        this.dealRole = data.dealRole;
        this.notes = data.notes;
        this.customFields = data.customFields;
    }

    get fullName(): string {
        return `${this.firstName}${this.lastName ? ` ${this.lastName}` : ''}`;
    }
}

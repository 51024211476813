import { BrTextInputStatus } from '@buildingradar/br_component_lib';
import { makeAutoObservable, reaction, runInAction } from 'mobx';
import { TFunction, getI18n } from 'react-i18next';

import { AchievementService } from 'src/app-features/achievements/domain/service/achievements.service';
import {
    IDealContactsApi,
    DealContactsResponse,
} from 'src/app-features/contact/data/api/deal-contacts.api.types';
import {
    DealContactCompanyForm,
    DealContactPersonForm,
} from 'src/app-features/contact/data/model/deal-contacts-form.model';
import { RoleToContactMap } from 'src/app-features/contact/data/model/deal-contacts.interface';
import {
    DealContactPerson,
    DealContactCompany,
    DealContactType,
    PredefinedCustomFields,
} from 'src/app-features/contact/data/model/deal-contacts.model';
import {
    ContactOrCompanyCreationSource,
    IDealContactsManageTrackingService,
    createDealContactsManageTrackingService,
} from 'src/app-features/contact/data/services/deal-contacts-manage-tracking.service';
import { IDealContactsStore } from 'src/app-features/contact/data/stores/deal-contacts.store.interface';
import { UserAchievementName } from 'src/data/api/graphql/br_process/generated/graphql-sdk';
import { MixpanelEventName } from 'src/data/services/mixpanel/mixpanel.model';
import {
    EventProps,
    MixpanelService,
} from 'src/data/services/mixpanel/mixpanel.service';
import { IAccountConfigurationStore } from 'src/data/stores/account-configuration/account-configuration.store.interface';
import { DealsStore } from 'src/data/stores/deals/deals.store';
import { PipelineStore } from 'src/data/stores/pipeline/pipeline.store';
import { IBaseStore } from 'src/data/stores/shared/base.store.interface';
import { ToasterStore } from 'src/data/stores/toaster/toaster.store';
import { CustomParameter } from 'src/domain/models/custom-parameter/custom-parameter.model';

import { scrollToElement } from 'src/utils/element.utils';
import { doNothing } from 'src/utils/function.utils';
import {
    Cancellable,
    handleRequest,
    handleRequestAsync,
} from 'src/utils/handle-request.utils';

import {
    ContactUpdateMetricsInfo,
    IDealContactsManageFeature,
    RoleUpdatePayload,
    RoleUpdateSource,
} from './deal-contacts-manage.feature.interface';
import {
    sortContact,
    syncCompaniesChangesInContacts,
    syncContactsChangesInCompanies,
} from './deal-contacts-manage.utils';
import {
    DealContactCandidate,
    DealCompanyCandidate,
    DealContactCandidateForm,
    DealCompanyCandidateForm,
} from 'src/app-features/contact-extraction/data/contact-extraction.model';

export class DealContactsManageFeature implements IDealContactsManageFeature {
    requestDealContactsInProgress = false;
    fieldProgress: Map<string, BrTextInputStatus> = new Map();
    createContactInProgress = false;
    contactsReferenceMap: Map<string, HTMLElement | null> = new Map();
    isCompaniesExpanded = false;
    isContactsExpanded = false;
    isArchivedExpanded = false;

    t: TFunction<'translation', undefined> = getI18n().t;

    dealContactsManageTrackingService: IDealContactsManageTrackingService;

    constructor(
        private dealContactsStore: IDealContactsStore,
        private dealContactsApi: IDealContactsApi,
        private baseStore: IBaseStore,
        private mixpanelService: MixpanelService,
        private dealsStore: DealsStore,
        private pipelineStore: PipelineStore,
        private toasterStore: ToasterStore,
        private accountConfigurationStore: IAccountConfigurationStore,
        private achievementService: AchievementService,
    ) {
        makeAutoObservable(this);
        /**
         * Reaction to sync any change/add/deletion of contacts to any company which has
         * a link with the given contact.
         */
        reaction(
            () => this.allDealContactPersons,
            (contacts) => {
                runInAction(() => {
                    syncContactsChangesInCompanies(
                        contacts,
                        this.allDealContactCompanies,
                    );
                });
            },
        );

        /**
         * Reaction to sync any changes to a given company to any contact which points to that company.
         */
        reaction(
            () => this.allDealContactCompanies,
            (companies) => {
                runInAction(() => {
                    syncCompaniesChangesInContacts(
                        companies,
                        this.allDealContactPersons,
                    );
                });
            },
        );

        this.dealContactsManageTrackingService =
            createDealContactsManageTrackingService(
                this.mixpanelService,
                this.dealContactsStore,
                this.dealsStore,
                this.pipelineStore,
            );
    }
    /**
     * getter section
     */
    get allDealContactPersons(): DealContactPerson[] {
        return Array.from(this.dealContactsStore.personsMap.values()).sort(
            sortContact,
        );
    }
    get allDealContactCompanies(): DealContactCompany[] {
        return Array.from(this.dealContactsStore.companiesMap.values()).sort(
            sortContact,
        );
    }
    get keyDealContactPersons(): DealContactPerson[] {
        return this.allDealContactPersons.filter(({ isKey }) => isKey);
    }
    get keyDealContactCompanies(): DealContactCompany[] {
        return this.allDealContactCompanies.filter(({ isKey }) => isKey);
    }
    get archivedDealContactPersons(): DealContactPerson[] {
        return this.allDealContactPersons.filter(({ isKey }) => !isKey);
    }
    get archivedDealContactCompanies(): DealContactCompany[] {
        return this.allDealContactCompanies.filter(({ isKey }) => !isKey);
    }
    get contactPersonCustomFields(): CustomParameter[] {
        return (
            this.accountConfigurationStore.accountConfiguration
                ?.contactCustomFields ?? []
        );
    }
    get contactCompanyCustomFields(): CustomParameter[] {
        return (
            this.accountConfigurationStore.accountConfiguration
                ?.companyCustomFields ?? []
        );
    }
    get contactPersonRoles(): string[] {
        return (
            this.accountConfigurationStore.accountConfiguration?.contactRoles ??
            []
        );
    }
    get contactCompanyRoles(): string[] {
        return (
            this.accountConfigurationStore.accountConfiguration?.companyRoles ??
            []
        );
    }
    get dealRolesInUse(): RoleToContactMap {
        return [
            ...this.allDealContactPersons,
            ...this.allDealContactCompanies,
        ].reduce((result: RoleToContactMap, contact) => {
            const dealRole = contact?.role;

            if (dealRole) {
                result[dealRole] = contact.id;
            }

            return result;
        }, {});
    }
    /**
     * setter section
     */

    setRequestDealContactsInProgress = (flag: boolean) => {
        this.requestDealContactsInProgress = flag;
    };

    setCreateContactInProgress = (flag: boolean) => {
        this.createContactInProgress = flag;
    };

    /**
     * implementation
     */

    requestDealContacts = (dealId: string): Cancellable => {
        this.dealContactsStore.clear();
        return handleRequest(
            this.dealContactsApi.getDealContacts,
            { dealId },
            this.onRequestDealContactsResponse,
            this.setRequestDealContactsInProgress,
            (error) =>
                this.baseStore.onRequestFailed('request-deal-companies', error),
        );
    };

    createDealContactPerson = (
        formData: DealContactPersonForm,
        dealId: string,
        onSuccess?: (contact: DealContactPerson) => void,
        creationSource?: ContactOrCompanyCreationSource,
    ) => {
        handleRequest(
            this.dealContactsApi.createContactPerson,
            {
                dealId,
                data: {
                    ...formData,
                    firstName: formData.firstName?.trim(),
                    lastName: formData.lastName?.trim(),
                },
            },
            this.onCreateContactPerson(dealId, onSuccess, creationSource),
            this.setCreateContactInProgress,
            (error) => this.baseStore.onRequestFailed('create-contact', error),
        );
    };

    createDealContactCompany = (
        data: DealContactCompanyForm,
        dealId: string,
        onSuccess?: (company: DealContactCompany) => void,
        creationSource?: ContactOrCompanyCreationSource,
    ) => {
        handleRequest(
            this.dealContactsApi.createContactCompany,
            {
                dealId,
                data: {
                    ...data,
                    name: data.name?.trim(),
                },
            },
            this.onCreateContactCompany(dealId, onSuccess, creationSource),
            this.setCreateContactInProgress,
            (error) => this.baseStore.onRequestFailed('create-company', error),
        );
    };

    updateFieldProgress = (
        key: string | string[],
        status: BrTextInputStatus,
    ) => {
        if (Array.isArray(key)) {
            key.forEach((keyItem) => this.fieldProgress.set(keyItem, status));
        } else {
            this.fieldProgress.set(key, status);
        }
    };

    updateDealContactPerson = (
        dealId: string,
        id: string,
        update: DealContactPersonForm,
        metricsInfo?: ContactUpdateMetricsInfo,
        onSuccess?: (contact: DealContactPerson) => void,
    ) => {
        handleRequest(
            this.dealContactsApi.updateContactPerson,
            {
                contactId: id,
                update: {
                    ...update,
                    firstName: update.firstName?.trim(),
                    lastName: update.lastName?.trim(),
                },
            },
            this.onUpdateContactPerson(dealId, metricsInfo, onSuccess),
            (flag: boolean) => {
                flag &&
                    metricsInfo?.field &&
                    this.updateFieldProgress(metricsInfo?.field, 'progress');
            },
            (error) => {
                this.baseStore.onRequestFailed('update-contact', error);
                error &&
                    metricsInfo?.field &&
                    this.updateFieldProgress(metricsInfo?.field, 'error');
            },
        );
    };

    updateDealContactCompany = (
        dealId: string,
        id: string,
        update: DealContactCompanyForm,
        metricsInfo?: ContactUpdateMetricsInfo,
        onSuccess?: (company: DealContactCompany) => void,
    ) => {
        handleRequest(
            this.dealContactsApi.updateContactCompany,
            {
                relevantCompanyId: id,
                update: {
                    ...update,
                    name: update.name?.trim(),
                },
            },
            this.onUpdateContactCompany(dealId, metricsInfo, onSuccess),
            (flag: boolean) => {
                flag &&
                    metricsInfo?.field &&
                    this.updateFieldProgress(metricsInfo?.field, 'progress');
            },
            (error) => {
                this.baseStore.onRequestFailed('update-company', error);
                error &&
                    metricsInfo?.field &&
                    this.updateFieldProgress(metricsInfo?.field, 'error');
            },
        );
    };

    toggleContactArchiveStatus = (
        dealId: string,
        contactId: string,
        contactType: DealContactType,
        archive: boolean,
        roleToUnarchive?: string,
    ) => {
        contactType === DealContactType.person
            ? this.updateDealContactPerson(dealId, contactId, {
                  isKey: !archive,
                  formType: contactType,
                  role: archive ? '' : roleToUnarchive,
              })
            : this.updateDealContactCompany(dealId, contactId, {
                  isKey: !archive,
                  formType: contactType,
                  role: archive ? '' : roleToUnarchive,
              });

        this.dealContactsManageTrackingService.trackToggleContactOrCompanyArchiveStatus(
            dealId,
            contactId,
            contactType,
            archive,
        );
    };

    deleteDealContactPerson = (dealId: string, contactId: string) => {
        handleRequest(
            this.dealContactsApi.deleteContactPerson,
            { dealId, contactId },
            (ok) => this.onDeleteContactPerson(ok, contactId),
            doNothing,
            (error) => this.baseStore.onRequestFailed('delete-contact', error),
        );
    };

    deleteDealContactCompany = (dealId: string, companyId: string) => {
        handleRequest(
            this.dealContactsApi.deleteContactCompany,
            { dealId, dealCompanyId: companyId },
            (success) => this.onDeleteContactCompany(success, companyId),
            doNothing,
            (error) => this.baseStore.onRequestFailed('delete-company', error),
        );
    };

    updateDealContactPersonRole = ({
        dealId,
        contactId,
        role,
        isKey,
        source,
        toasterMessage,
        callbackOnUndo,
    }: RoleUpdatePayload) => {
        handleRequest(
            this.dealContactsApi.updateContactPersonRole,
            { dealPersonId: contactId, role, isKey },
            (dealContactPerson?: DealContactPerson) =>
                this.onDealContactPersonRoleUpdated(
                    dealId,
                    source,
                    dealContactPerson,
                    toasterMessage,
                    callbackOnUndo,
                ),
            doNothing,
            (error) =>
                this.baseStore.onRequestFailed('update-contact-role', error),
        );
    };

    updateDealContactCompanyRole = ({
        dealId,
        contactId,
        role,
        isKey,
        source,
        toasterMessage,
        callbackOnUndo,
    }: RoleUpdatePayload) => {
        handleRequest(
            this.dealContactsApi.updateContactCompanyRole,
            { dealCompanyId: contactId, role, isKey },
            (dealCompany?: DealContactCompany) =>
                this.onDealContactCompanyRoleUpdated(
                    dealId,
                    source,
                    dealCompany,
                    toasterMessage,
                    callbackOnUndo,
                ),
            doNothing,
            (error) =>
                this.baseStore.onRequestFailed('update-company-role', error),
        );
    };

    createContactFromCandidates = async (
        contactCandidates: DealContactCandidate[],
        companyCandidates: DealCompanyCandidate[],
        dealId: string,
    ) => {
        const accountHasPositionCustomField =
            !!this.accountConfigurationStore.getCustomParameterByName(
                PredefinedCustomFields.position,
                'contactCustomFields',
            );

        const extractedContactsPayload = contactCandidates.map((contact) => {
            const notes = this.formatContactExtractedNotes(
                contact,
                accountHasPositionCustomField,
            );
            const shouldSaveRoleAsPosition =
                accountHasPositionCustomField && !!contact.role;

            return {
                firstName: contact.firstName,
                lastName: contact.lastName,
                companyName: contact.companyName,
                notes,
                customValues: shouldSaveRoleAsPosition
                    ? [
                          {
                              name: PredefinedCustomFields.position,
                              value: contact.role,
                          },
                      ]
                    : undefined,
            } as DealContactCandidateForm;
        });

        const extractedCompaniesPayload = companyCandidates.map((contact) => {
            const notes = this.formatContactExtractedNotes(
                contact,
                accountHasPositionCustomField,
            );
            const shouldSaveRoleAsPosition =
                accountHasPositionCustomField && !!contact.role;

            return {
                name: contact.name,
                notes,
                customValues: shouldSaveRoleAsPosition
                    ? [
                          {
                              name: PredefinedCustomFields.position,
                              value: contact.role,
                          },
                      ]
                    : undefined,
            } as DealCompanyCandidateForm;
        });

        const created = await handleRequestAsync(
            this.dealContactsApi.batchCreateContactWithCompany,
            {
                dealId,
                extractedContacts: extractedContactsPayload,
                extractedCompanies: extractedCompaniesPayload,
            },
            this.setCreateContactInProgress,
            (error) => this.baseStore.onRequestFailed('create-contact', error),
        );

        this.dealContactsManageTrackingService.trackContactOrCompanyBatchCreation(
            created?.contacts ?? [],
            dealId,
            ContactOrCompanyCreationSource.ContactExtractor,
        );

        this.dealContactsManageTrackingService.trackContactOrCompanyBatchCreation(
            created?.companies ?? [],
            dealId,
            ContactOrCompanyCreationSource.ContactExtractor,
        );

        this.achievementService.call(
            UserAchievementName.CreateYourFirstContact,
        );
        this.requestDealContacts(dealId);
    };

    formatContactExtractedNotes = (
        contact: DealContactCandidate | DealCompanyCandidate,
        contactPositionFieldExists: boolean,
    ) => {
        let notes = '';

        if (!contactPositionFieldExists && contact.role) {
            notes += `${this.t('enablement_panel.contact_extraction.create.notes.job_title')}: ${
                contact.role
            }. \n\n`;
        }

        notes += `${this.t('enablement_panel.contact_extraction.create.notes.context')}: ${
            contact.legitimateInterestText
        }. \n\n${this.t(
            'enablement_panel.contact_extraction.create.notes.sources',
        )}: \n${contact.sourceUrls.map((url, index) => `[${index}]${url}`).join('\n')}`;

        return notes;
    };

    findContactById = (id: string, type: DealContactType) => {
        if (type === DealContactType.person) {
            return this.dealContactsStore.personsMap.get(id);
        } else {
            return this.dealContactsStore.companiesMap.get(id);
        }
    };

    /**
     * callbacks for API calls
     */

    onRequestDealContactsResponse = (
        companyAndPersonData: DealContactsResponse,
    ) => {
        this.dealContactsStore.clear();
        companyAndPersonData.dealContactCompanies.forEach((company) =>
            this.dealContactsStore.addContactCompany(company),
        );
        companyAndPersonData.dealContactPersons.forEach((contact) =>
            this.dealContactsStore.addContactPerson(contact),
        );
    };

    onCreateContactPerson =
        (
            dealId: string,
            onSuccess?: (contact: DealContactPerson) => void,
            creationSource?: ContactOrCompanyCreationSource,
        ) =>
        (contact: DealContactPerson | undefined) => {
            if (!contact) {
                return;
            }

            this.dealContactsStore.addContactPerson(contact);
            this.dealContactsManageTrackingService.trackContactOrCompanyCreation(
                contact,
                dealId,
                creationSource,
            );
            onSuccess?.(contact);
            this.achievementService.call(
                UserAchievementName.CreateYourFirstContact,
            );
        };

    onCreateContactCompany =
        (
            dealId: string,
            onSuccess?: (company: DealContactCompany) => void,
            creationSource?: ContactOrCompanyCreationSource,
        ) =>
        (company: DealContactCompany | null | undefined) => {
            if (!company) {
                return;
            }

            this.dealContactsStore.addContactCompany(company);
            this.dealContactsManageTrackingService.trackContactOrCompanyCreation(
                company,
                dealId,
                creationSource,
            );
            onSuccess?.(company);
        };

    onUpdateContactPerson =
        (
            dealId: string,
            metricsInfo?: ContactUpdateMetricsInfo,
            onSuccess?: (contact: DealContactPerson) => void,
        ) =>
        (contact: DealContactPerson) => {
            this.dealContactsManageTrackingService.trackContactOrCompanyFieldEdition(
                contact,
                dealId,
                metricsInfo,
            );
            this.dealContactsStore.updateContactPerson(contact);
            this.achievementService.call(
                UserAchievementName.AddContactsEmailOrPhone,
            );
            metricsInfo?.field &&
                this.updateFieldProgress(metricsInfo?.field, 'success');
            onSuccess?.(contact);
        };

    onUpdateContactCompany =
        (
            dealId: string,
            metricsInfo?: ContactUpdateMetricsInfo,
            onSuccess?: (company: DealContactCompany) => void,
        ) =>
        (company: DealContactCompany) => {
            this.dealContactsManageTrackingService.trackContactOrCompanyFieldEdition(
                company,
                dealId,
                metricsInfo,
            );
            this.dealContactsStore.updateContactCompany(company);
            metricsInfo?.field &&
                this.updateFieldProgress(metricsInfo?.field, 'success');
            onSuccess?.(company);
        };

    onDeleteContactCompany = (success: boolean, companyId: string) => {
        if (!success) {
            return;
        }
        const relevantPersons = Array.from(
            this.dealContactsStore.personsMap.values(),
        ).filter((person) => person.relevantCompanyId === companyId);
        relevantPersons.forEach((person) =>
            this.dealContactsStore.removeContactPerson(person.id),
        );
        this.dealContactsStore.removeContactCompany(companyId);
    };

    onDeleteContactPerson = (success: boolean, contactId: string) => {
        if (success) {
            this.dealContactsStore.removeContactPerson(contactId);
        }
    };

    onDealContactPersonRoleUpdated = (
        dealId: string,
        source: RoleUpdateSource,
        updatedContactPerson: DealContactPerson | undefined,
        toasterMessage: string = this.t(
            'deal_view.contacts_section.toast.update-contact-deal-role',
        ),
        callbackOnUndo?: () => void,
    ) => {
        if (updatedContactPerson) {
            this.dealContactsStore.personsMap.set(
                updatedContactPerson.id,
                updatedContactPerson,
            );
            updatedContactPerson?.isKey
                ? this.toasterStore.showMessage({ title: toasterMessage })
                : callbackOnUndo &&
                  this.toasterStore.showMessage({
                      title: this.t(
                          'deal_view.contacts_section.toast.remove-contact-deal-role',
                      ),
                      primaryAction: callbackOnUndo,
                      primaryActionText: this.t('common.undo'),
                  });

            const { id, type, role } = updatedContactPerson;
            const roleToTrack = role?.length
                ? role
                : this.t('deal_view.contacts_section.role.no_deal_role');

            this.dealContactsManageTrackingService.trackContactOrCompanyRoleUpdate(
                type,
                dealId,
                id,
                source,
                roleToTrack,
            );

            this.achievementService.call(
                UserAchievementName.AssignContactDealRole,
            );
        }
    };

    onDealContactCompanyRoleUpdated = (
        dealId: string,
        source: RoleUpdateSource,
        updatedContactCompany: DealContactCompany | undefined,
        toasterMessage: string = this.t(
            'deal_view.contacts_section.toast.update-company-deal-role',
        ),
        callbackOnUndo?: () => void,
    ) => {
        if (updatedContactCompany) {
            this.dealContactsStore.companiesMap.set(
                updatedContactCompany.id,
                updatedContactCompany,
            );
            updatedContactCompany.isKey
                ? this.toasterStore.showMessage({ title: toasterMessage })
                : callbackOnUndo &&
                  this.toasterStore.showMessage({
                      title: this.t(
                          'deal_view.contacts_section.toast.remove-company-deal-role',
                      ),
                      primaryAction: callbackOnUndo,
                      primaryActionText: this.t('common.undo'),
                  });

            const { id, type, role } = updatedContactCompany;
            const roleToTrack = role?.length
                ? role
                : this.t('deal_view.contacts_section.role.no_deal_role');

            this.dealContactsManageTrackingService.trackContactOrCompanyRoleUpdate(
                type,
                dealId,
                id,
                source,
                roleToTrack,
            );
        }
    };

    expandContactField = (
        id: string,
        archivedContacts: (DealContactCompany | DealContactPerson)[],
        allContacts: (DealContactCompany | DealContactPerson)[],
        setExpanded: (flag: boolean) => void,
    ) => {
        const archived = archivedContacts.find((c) => c.id === id);
        const ref = this.contactsReferenceMap.get(id);

        if (!!archived && !ref) {
            this.setArchivedExpanded(true);
        } else {
            const company = allContacts.find((c) => c.id === id);
            if (!!company && !ref) {
                setExpanded(true);
            }
        }
    };

    scrollToContactId = (id: string) => {
        const isPerson = this.allDealContactPersons.find((c) => c.id === id);
        const isCompany = this.allDealContactCompanies.find((c) => c.id === id);
        if (!isPerson && !isCompany) {
            return;
        }

        if (isCompany) {
            this.expandContactField(
                id,
                this.archivedDealContactCompanies,
                this.allDealContactCompanies,
                this.setCompaniesExpanded,
            );
        } else {
            this.expandContactField(
                id,
                this.archivedDealContactPersons,
                this.allDealContactPersons,
                this.setContactsExpanded,
            );
        }

        setTimeout(() => {
            const refElement = this.contactsReferenceMap.get(id);
            !!refElement && scrollToElement(refElement, 200);
        }, 0);
    };

    setContactReference = (id: string, ref: HTMLElement | null) => {
        this.contactsReferenceMap.set(id, ref);
    };

    setCompaniesExpanded = (flag: boolean) => {
        this.isCompaniesExpanded = flag;
    };

    setContactsExpanded = (flag: boolean) => {
        this.isContactsExpanded = flag;
    };

    setArchivedExpanded = (flag: boolean) => {
        this.isArchivedExpanded = flag;
    };

    sendMixpanelEvent = (
        eventName: MixpanelEventName,
        eventProperties?: EventProps,
        dealId?: string,
    ) => {
        this.mixpanelService.trackEvent(
            eventName,
            eventProperties,
            dealId,
            this.dealsStore.getDeal,
            this.pipelineStore.getPipeline,
        );
    };

    clear = () => {
        this.fieldProgress = new Map();
        this.setCompaniesExpanded(false);
        this.setContactsExpanded(false);
        this.setArchivedExpanded(false);
        this.contactsReferenceMap.clear();
    };

    trackContactListExpansionAction = (
        contactType: DealContactType,
        dealId: string,
    ) => {
        this.dealContactsManageTrackingService.trackContactListExpansionAction(
            contactType,
            dealId,
        );
    };
}

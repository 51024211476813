import { BrTextInputStatus } from '@buildingradar/br_component_lib';
import { observer } from 'mobx-react';
import { FC, useCallback, useEffect } from 'react';
import { FormProvider } from 'react-hook-form';

import {
    DealContactCompany,
    DealContactPerson,
    DealContactType,
} from 'src/app-features/contact/data/model/deal-contacts.model';
import { DealContactFormContainer } from 'src/app-features/contact/presentation/contact-panel/components/deal-contact-form.container';
import { getEnrichmentRefinementContactConfig } from 'src/app-features/contact/presentation/contact-panel/form-utils/form-config';
import { useContactForm } from 'src/app-features/contact/presentation/contact-panel/form-utils/form-hooks';
import { SectionGreyTitleStyled } from 'src/app-features/contact-enrichment/presentation/styles';
import { Localized } from 'src/presentation/shared/localized/localized';

import { SearchRefinementCardContainer } from './search-refinement-card.container';
import { SearchRefinementCompanyFormComponent } from './search-refinement-company-form.component';
import { FormWrapperStyled } from './styles';
import { DomainEnrichmentOption } from 'src/app-features/contact-enrichment/domain/contact-enrichment.model';

interface SearchRefinementFormComponentProps {
    fieldProgress: Map<string, BrTextInputStatus>;
    dealId: string;
    selectedContact?: DealContactPerson;
    selectedCompany?: DealContactCompany;
    getDomainEnrichment: () => void;
    saveCompanyInfoAndEnrich: (
        payload: DomainEnrichmentOption,
        refined?: boolean,
    ) => void;
}
export const SearchRefinementFormComponent: FC<SearchRefinementFormComponentProps> =
    observer(
        ({
            dealId,
            selectedContact,
            fieldProgress,
            selectedCompany,
            getDomainEnrichment,
            saveCompanyInfoAndEnrich,
        }) => {
            if (!selectedContact || !selectedCompany) {
                return null;
            }

            useEffect(() => {
                getDomainEnrichment();
            }, [
                getDomainEnrichment,
                selectedContact.relevantCompanyId,
                selectedContact.relevantCompany?.name,
            ]);

            const {
                formApi: contactFormApi,
                formConfig: contactFormConfig,
                onUpdateContactField: onUpdateContact,
            } = useContactForm({
                dealId,
                selectedContact,
                customConfig: getEnrichmentRefinementContactConfig,
            });

            const hideCompany =
                fieldProgress.get('relevantCompanyId') === 'progress';

            const handleUpdateContact = useCallback(
                (field: string, value: string) => {
                    onUpdateContact(field, value);
                },
                [onUpdateContact],
            );

            return (
                <>
                    <SectionGreyTitleStyled>
                        <Localized>
                            actions.contact_enrichment.flyout.label.refine_search_parameters
                        </Localized>
                    </SectionGreyTitleStyled>
                    <FormWrapperStyled>
                        <FormProvider {...contactFormApi}>
                            <DealContactFormContainer
                                dealId={dealId}
                                type={DealContactType.person}
                                formConfig={contactFormConfig}
                                isEditMode={true}
                                onSave={handleUpdateContact}
                            />
                        </FormProvider>
                        {!hideCompany && (
                            <SearchRefinementCompanyFormComponent
                                dealId={dealId}
                                selectedCompany={selectedCompany}
                            />
                        )}
                    </FormWrapperStyled>
                    {!hideCompany && (
                        <SearchRefinementCardContainer
                            saveCompanyInfoAndEnrich={saveCompanyInfoAndEnrich}
                            hideTitle
                            key={selectedContact.relevantCompanyId}
                            useCheckbox
                        />
                    )}
                </>
            );
        },
    );

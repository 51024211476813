import { observer } from 'mobx-react';
import { FC, useCallback } from 'react';
import { FormProvider } from 'react-hook-form';

import { DealContactPerson } from 'src/app-features/contact/data/model/deal-contacts.model';
import { DealContactFormContainer } from 'src/app-features/contact/presentation/contact-panel/components/deal-contact-form.container';
import { companyOnlyFormConfig } from 'src/app-features/contact/presentation/contact-panel/form-utils/form-config';
import { useContactForm } from 'src/app-features/contact/presentation/contact-panel/form-utils/form-hooks';
import { EnrichmentIterateStateParams } from 'src/app-features/contact-enrichment/domain/contact-enrichment.feature';

interface EnrichmentCompanySelectComponentProps {
    contact?: DealContactPerson;
    dealId: string;
    iterateState: (params?: EnrichmentIterateStateParams) => void;
    clean: () => void;
}
export const EnrichmentCompanySelectComponent: FC<EnrichmentCompanySelectComponentProps> =
    observer(({ dealId, contact, iterateState, clean }) => {
        if (!contact) {
            return null;
        }
        const onUpdateDone = useCallback(() => {
            clean();
            iterateState();
        }, [clean, iterateState]);

        const { formApi, formConfig, onUpdateContactField } = useContactForm({
            dealId,
            selectedContact: contact,
            customConfig: companyOnlyFormConfig,
            onUpdateDone,
        });

        return (
            <>
                <FormProvider {...formApi}>
                    <DealContactFormContainer
                        type={contact.type}
                        formConfig={formConfig}
                        dealId={dealId}
                        isEditMode={true}
                        onSave={onUpdateContactField}
                    />
                </FormProvider>
            </>
        );
    });

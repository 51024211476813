import { ReminderPanelFields } from 'src/domain/models/reminder/reminder.model';
import { withFeatures } from 'src/utils/component.utils';

import { ReminderPanelComponent } from './reminder-panel.component';

export const ReminderPanelContainer = withFeatures(ReminderPanelComponent)(
    (f) => ({
        dealId: f.quickActionsFeature.selectedDealId,
        isPanelOpened: f.quickActionsFeature.isQuickActionsPanelOpen,
        assignToUsers: f.authFeature.assignableUsers,
        currentSelectedDealId: f.quickActionsFeature.selectedDealId ?? '',
        reminderToEdit: f.quickActionsFeature
            .selectedEntity as ReminderPanelFields,
        onRemindersCloseRequested: f.quickActionsFeature.clean,
        onCreateReminderRequested: f.remindersFeature.createReminder,
        onUpdateReminderRequested: f.remindersFeature.updateReminder,
        dismissReminder: f.remindersFeature.dismissReminder,
        triggerPipelineDataRefresh:
            f.pipelineTableViewFeature
                .triggerPipelineDataRefreshAfterCellUpdate,
        language: f.translationFeature.language,
    }),
);

export const ColouredIcon = () => {
    return (
        <svg
            width="72"
            height="72"
            viewBox="0 0 72 72"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <mask
                id="mask0_806_2509"
                style={{ maskType: 'alpha' }}
                maskUnits="userSpaceOnUse"
                x="13"
                y="8"
                width="46"
                height="56"
            >
                <path
                    d="M13.32 15.6268C13.32 14.2785 14.2554 13.1107 15.5712 12.8163L35.2211 8.41972C35.6325 8.32767 36.0591 8.32706 36.4707 8.41792L56.4207 12.8213C57.7404 13.1126 58.68 14.2822 58.68 15.6336V47.0528C58.68 48.019 58.1956 48.9207 57.39 49.4541L37.4466 62.6599C36.4772 63.3018 35.2169 63.2979 34.2515 62.6499L14.5949 49.4557C13.7981 48.9209 13.32 48.0242 13.32 47.0645V15.6268Z"
                    fill="white"
                />
            </mask>
            <g mask="url(#mask0_806_2509)">
                <mask
                    id="mask1_806_2509"
                    style={{ maskType: 'alpha' }}
                    maskUnits="userSpaceOnUse"
                    x="10"
                    y="4"
                    width="52"
                    height="63"
                >
                    <path
                        d="M10.08 14.6937C10.08 11.9971 11.9508 9.66142 14.5823 9.07264L34.5745 4.5995C35.3973 4.41542 36.2504 4.41419 37.0737 4.5959L57.4015 9.08267C60.0408 9.66522 61.92 12.0045 61.92 14.7073V47.3058C61.92 49.238 60.9512 51.0415 59.3401 52.1083L39.0255 65.5599C37.0867 66.8437 34.566 66.8358 32.6353 65.5398L12.6298 52.1115C11.0361 51.0418 10.08 49.2485 10.08 47.329V14.6937Z"
                        fill="url(#paint0_linear_806_2509)"
                    />
                </mask>
                <g mask="url(#mask1_806_2509)">
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M36.8317 41.0776C38.8946 41.0776 40.7985 40.3973 42.3315 39.2488C42.9788 39.489 43.6791 39.6203 44.41 39.6203C46.2816 39.6203 47.9523 38.7597 49.0479 37.4126C49.9544 38.4107 51.2624 39.0373 52.7169 39.0373C53.6986 39.0373 54.6136 38.7518 55.3835 38.2593C56.1223 38.7509 57.0094 39.0373 57.9633 39.0373C60.5389 39.0373 62.6269 36.9494 62.6269 34.3738C62.6269 31.7982 60.5389 29.7102 57.9633 29.7102C57.1942 29.7102 56.4685 29.8965 55.8289 30.2263C54.9783 29.5389 53.8957 29.1273 52.7169 29.1273C51.45 29.1273 50.2942 29.6028 49.4182 30.3849C48.4531 28.9054 46.8501 27.8799 44.9995 27.6987C43.4754 24.7391 40.39 22.715 36.8317 22.715C33.4319 22.715 30.4636 24.5629 28.8766 27.3092C27.9968 26.9772 27.0433 26.7955 26.0473 26.7955C23.519 26.7955 21.2642 27.9661 19.7951 29.7948C19.5074 29.7393 19.2102 29.7102 18.9063 29.7102C18.2919 29.7102 17.7053 29.8291 17.1681 30.0449C16.3411 29.1238 15.141 28.5444 13.8055 28.5444C12.3535 28.5444 11.0615 29.2294 10.235 30.2939C8.09547 30.3327 6.37305 32.0793 6.37305 34.2281C6.37305 36.4012 8.13474 38.1629 10.3079 38.1629C11.1673 38.1629 11.9623 37.8874 12.6095 37.42C12.9904 37.5243 13.3915 37.58 13.8055 37.58C14.3283 37.58 14.8304 37.4912 15.2975 37.3278C16.1527 38.3714 17.4518 39.0373 18.9063 39.0373C19.0146 39.0373 19.122 39.0336 19.2285 39.0264C20.6415 41.3072 23.167 42.8264 26.0473 42.8264C28.558 42.8264 30.799 41.6721 32.2687 39.8653C33.6129 40.6366 35.1708 41.0776 36.8317 41.0776Z"
                        fill="white"
                    />
                    <path
                        d="M-2.55125 32.0944L-4 34.2857V37.7578H15.5492V36.2697L14.4354 33.8806C14.1681 33.3071 13.3716 33.258 13.0359 33.7943L12.2385 35.0682L11.1831 33.3823C10.9144 32.953 10.3189 32.8794 9.95378 33.2304L9.24302 33.9137L8.2182 31.9791C7.95394 31.4803 7.26941 31.4061 6.9044 31.8367L5.14399 33.9137L3.60382 30.1929C3.34252 29.5617 2.46044 29.5293 2.15362 30.1398L0.256685 33.9137L-1.26531 32.0325C-1.60464 31.6131 -2.25372 31.6443 -2.55125 32.0944Z"
                        fill="#098566"
                    />
                    <path
                        d="M10.8664 31.9217L9.56476 33.6926V36.5609H27.5181V35.3316L26.5338 33.4322C26.2574 32.8991 25.5134 32.8507 25.1704 33.3436L24.4776 34.339L23.5361 32.9861C23.2655 32.5973 22.7176 32.5285 22.3593 32.8383L21.7267 33.3853L20.8182 31.8427C20.5484 31.3846 19.9145 31.3135 19.55 31.7004L17.9623 33.3853L16.5913 30.406C16.3185 29.8132 15.4882 29.7807 15.1699 30.3504L13.474 33.3853L12.1026 31.8606C11.7643 31.4845 11.1659 31.5141 10.8664 31.9217Z"
                        fill="url(#paint1_linear_806_2509)"
                    />
                    <path
                        d="M-0.112495 32.7736L-1.60626 34.5811V37.3588H17.943V36.1684L16.8013 34.2092C16.5173 33.7218 15.8311 33.675 15.4835 34.1192L14.6322 35.2071L13.5433 33.8155C13.2733 33.4705 12.7757 33.4076 12.4284 33.6747L11.6368 34.2835L10.5837 32.6932C10.3103 32.2804 9.7308 32.2136 9.3707 32.5535L7.53773 34.2835L5.93207 31.8769C5.63355 31.4295 4.98689 31.3995 4.64831 31.8175L2.65042 34.2835L1.06361 32.7145C0.730309 32.385 0.186091 32.4123 -0.112495 32.7736Z"
                        fill="#098566"
                    />
                    <path
                        d="M13.3136 32.4544L11.9586 33.9051V36.1619H29.912V35.1947L28.8928 33.6472C28.6037 33.2084 27.9768 33.1639 27.6287 33.5575L26.8715 34.4137L25.8892 33.303C25.6231 33.0021 25.1748 32.946 24.8426 33.172L24.1206 33.6633L23.177 32.4026C22.9029 32.0364 22.3778 31.9751 22.0268 32.2682L20.3562 33.6633L18.9068 31.7413C18.6064 31.343 18.0182 31.3149 17.6813 31.6829L15.8678 33.6633L14.4221 32.3985C14.0981 32.1151 13.6075 32.1398 13.3136 32.4544Z"
                        fill="url(#paint2_linear_806_2509)"
                    />
                    <path
                        d="M69.0384 32.7571L70.6063 34.7385V37.7577H50.2592V36.4638L51.4655 34.3018C51.7469 33.7974 52.4545 33.7499 52.8008 34.2122L53.705 35.419L54.8511 33.8894C55.1217 33.5283 55.6376 33.4632 55.9893 33.7457L56.8228 34.4151L57.934 32.6625C58.2064 32.2329 58.8058 32.1644 59.168 32.5215L61.0891 34.4151L62.7392 31.0847C63.0191 30.5197 63.8128 30.4873 64.1379 31.0276L66.1759 34.4151L67.8395 32.6972C68.1751 32.3506 68.739 32.3788 69.0384 32.7571Z"
                        fill="url(#paint3_linear_806_2509)"
                    />
                    <path
                        d="M50.2869 32.7571L51.8548 34.7385V37.7577H31.5077V36.4638L32.714 34.3018C32.9955 33.7974 33.703 33.7499 34.0494 34.2122L34.9536 35.419L36.0997 33.8894C36.3702 33.5283 36.8861 33.4632 37.2379 33.7457L38.0713 34.4151L39.1826 32.6625C39.4549 32.2329 40.0543 32.1644 40.4166 32.5215L42.3376 34.4151L43.9877 31.0847C44.2676 30.5197 45.0614 30.4873 45.3864 31.0276L47.4244 34.4151L49.088 32.6972C49.4236 32.3506 49.9876 32.3788 50.2869 32.7571Z"
                        fill="url(#paint4_linear_806_2509)"
                    />
                    <path
                        d="M71.033 32.2116L72.6009 34.193V37.2121H52.2538V35.9182L53.4601 33.7563C53.7416 33.2518 54.4491 33.2043 54.7955 33.6666L55.6997 34.8734L56.8457 33.3438C57.1163 32.9827 57.6322 32.9176 57.984 33.2001L58.8174 33.8695L59.9286 32.1169C60.201 31.6873 60.8004 31.6188 61.1627 31.9759L63.0837 33.8695L64.7797 31.3301C65.0782 30.8831 65.7244 30.8532 66.063 31.2707L68.1705 33.8695L69.8341 32.1516C70.1697 31.8051 70.7337 31.8333 71.033 32.2116Z"
                        fill="url(#paint5_linear_806_2509)"
                    />
                    <path
                        d="M52.6808 32.7571L54.2488 34.7385V37.7577H33.9016V36.4638L35.1079 34.3019C35.3894 33.7974 36.0969 33.7499 36.4433 34.2122L37.3475 35.419L38.4936 33.8894C38.7641 33.5283 39.28 33.4632 39.6318 33.7457L40.4652 34.4151L41.5765 32.6625C41.8489 32.2329 42.4482 32.1644 42.8105 32.5215L44.7315 34.4151L46.4275 31.8757C46.726 31.4287 47.3722 31.3988 47.7108 31.8163L49.8183 34.4151L51.4819 32.6972C51.8175 32.3507 52.3815 32.3789 52.6808 32.7571Z"
                        fill="url(#paint6_linear_806_2509)"
                    />
                    <rect
                        x="-4"
                        y="34.965"
                        width="77"
                        height="32.715"
                        fill="url(#paint7_linear_806_2509)"
                    />
                    <path
                        d="M24.6149 35.5609C24.9178 35.1841 25.3752 34.965 25.8587 34.965H45.934C46.4175 34.965 46.8749 35.1841 47.1778 35.5609L70.9131 65.0842C71.7527 66.1285 71.0093 67.68 69.6693 67.68H2.12335C0.783432 67.68 0.0400447 66.1285 0.8796 65.0842L24.6149 35.5609Z"
                        fill="url(#paint8_linear_806_2509)"
                    />
                    <path
                        d="M26.6014 35.1389C26.6757 35.0301 26.799 34.965 26.9309 34.965H44.8619C44.9937 34.965 45.117 35.0301 45.1913 35.1389L66.9884 67.056C67.1692 67.3208 66.9796 67.68 66.6589 67.68H5.13382C4.81315 67.68 4.62351 67.3208 4.80436 67.056L26.6014 35.1389Z"
                        fill="url(#paint9_linear_806_2509)"
                    />
                    <path
                        d="M27.9171 63.4909L29.4066 60.4987H41.5351L43.4501 61.2966L43.8757 66.0841L27.9171 65.6852V63.4909Z"
                        fill="url(#paint10_linear_806_2509)"
                    />
                    <path
                        d="M24.7386 57.6214L25.7265 55.0281C25.8444 54.7187 26.1411 54.5142 26.4722 54.5142H35.0984C35.5391 54.5142 35.8964 54.8715 35.8964 55.3121V57.3907C35.8964 57.7973 35.5906 58.1389 35.1865 58.1838L30.5103 58.7033H25.4842C24.9254 58.7033 24.5396 58.1436 24.7386 57.6214Z"
                        fill="url(#paint11_linear_806_2509)"
                    />
                    <path
                        d="M37.0146 57.4754L36.7791 55.5916C36.7303 55.2012 36.9733 54.8335 37.3516 54.7254L37.9832 54.5449C38.0545 54.5246 38.1283 54.5142 38.2024 54.5142H45.5768C45.879 54.5142 46.1553 54.685 46.2905 54.9553L47.3583 57.0909C47.6052 57.5848 47.2879 58.1742 46.7396 58.24L42.9667 58.6927C42.9079 58.6998 42.8485 58.7003 42.7896 58.6942L37.7242 58.1702C37.3548 58.132 37.0606 57.8439 37.0146 57.4754Z"
                        fill="url(#paint12_linear_806_2509)"
                    />
                    <path
                        d="M47.8653 55.9217V55.3492C47.8653 54.8943 48.245 54.5315 48.6994 54.5521L56.2084 54.8935C56.479 54.9058 56.7249 55.0545 56.8614 55.2885L57.6332 56.6116C57.8566 56.9946 57.7249 57.4864 57.3398 57.7064L56.8265 57.9998C56.7059 58.0687 56.5694 58.1049 56.4306 58.1049H49.3558C49.0536 58.1049 48.7773 57.9341 48.6421 57.6638L47.9495 56.2786C47.8941 56.1678 47.8653 56.0456 47.8653 55.9217Z"
                        fill="url(#paint13_linear_806_2509)"
                    />
                    <path
                        d="M15.6534 54.538L14.4179 54.8469C14.2461 54.8899 14.0934 54.9888 13.984 55.128L12.0174 57.631C11.9784 57.6807 11.9466 57.7357 11.9232 57.7944C11.749 58.2297 12.0696 58.7033 12.5386 58.7033H22.0793C22.3596 58.7033 22.6193 58.5563 22.7635 58.3159L24.101 56.0867C24.2414 55.8528 24.2525 55.5633 24.1305 55.3193L23.9485 54.9553C23.8133 54.685 23.537 54.5142 23.2348 54.5142H15.8469C15.7817 54.5142 15.7167 54.5222 15.6534 54.538Z"
                        fill="url(#paint14_linear_806_2509)"
                    />
                    <path
                        d="M21.6252 50.4331L22.2973 49.7609C22.447 49.6113 22.6499 49.5272 22.8616 49.5272H30.587C30.9534 49.5272 31.2222 49.8715 31.1333 50.227C31.1171 50.2919 31.1126 50.3592 31.12 50.4257L31.2098 51.2344C31.2623 51.7071 30.8923 52.1205 30.4168 52.1205H22.0268C21.4336 52.1205 21.0478 51.4963 21.3131 50.9657L21.4757 50.6404C21.5141 50.5637 21.5646 50.4937 21.6252 50.4331Z"
                        fill="url(#paint15_linear_806_2509)"
                    />
                    <path
                        d="M31.9067 51.3226V50.3251C31.9067 49.8845 32.2639 49.5272 32.7046 49.5272H39.945C40.3619 49.5272 40.7086 49.8482 40.7406 50.264L40.8173 51.2614C40.853 51.7248 40.4865 52.1205 40.0217 52.1205H38.4896H32.7046C32.2639 52.1205 31.9067 51.7632 31.9067 51.3226Z"
                        fill="url(#paint16_linear_806_2509)"
                    />
                    <path
                        d="M41.6502 50.8797L41.7525 50.3681C41.8271 49.9952 42.1546 49.7267 42.5349 49.7267H48.4685C48.8208 49.7267 49.1315 49.9578 49.2327 50.2953L49.3768 50.7756C49.5228 51.2623 49.1827 51.7598 48.6762 51.8003L44.755 52.114C44.7008 52.1183 44.6464 52.1171 44.5924 52.1103L42.3337 51.828C41.8736 51.7705 41.5593 51.3344 41.6502 50.8797Z"
                        fill="url(#paint17_linear_806_2509)"
                    />
                    <path
                        d="M51.0329 49.7267H54.5064C54.8327 49.7267 55.1261 49.9253 55.2472 50.2283L55.4068 50.6273C55.6165 51.1514 55.2305 51.7215 54.666 51.7215H51.1127C50.7323 51.7215 50.4048 51.4531 50.3302 51.0801L50.2504 50.6811C50.1517 50.1874 50.5293 49.7267 51.0329 49.7267Z"
                        fill="url(#paint18_linear_806_2509)"
                    />
                    <path
                        d="M15.9388 50.8361L16.706 49.8386C16.8571 49.6423 17.0907 49.5272 17.3385 49.5272H19.4886C20.071 49.5272 20.4571 50.1308 20.2131 50.6595L19.7528 51.6569C19.6223 51.9395 19.3395 52.1205 19.0283 52.1205H16.5712C15.9092 52.1205 15.5351 51.3608 15.9388 50.8361Z"
                        fill="url(#paint19_linear_806_2509)"
                    />
                    <path
                        d="M18.3575 47.2129L19.1063 46.2394C19.2537 46.0479 19.4799 45.9334 19.7215 45.9282L27.3265 45.7628C27.8937 45.7505 28.2923 46.3175 28.0886 46.847L27.7107 47.8294C27.5943 48.1323 27.306 48.3344 26.9816 48.3408L19.0056 48.4972C18.3365 48.5103 17.9494 47.7434 18.3575 47.2129Z"
                        fill="url(#paint20_linear_806_2509)"
                    />
                    <path
                        d="M29.4101 46.406L29.6628 46.0691C29.8189 45.861 30.0668 45.742 30.3269 45.7504L35.3236 45.9116C35.7541 45.9255 36.0958 46.2784 36.0958 46.7091V47.3329C36.0958 47.7735 35.7386 48.1308 35.2979 48.1308H29.9365C29.4173 48.1308 29.0365 47.6429 29.1624 47.1393L29.2744 46.6913C29.3002 46.5881 29.3463 46.4911 29.4101 46.406Z"
                        fill="url(#paint21_linear_806_2509)"
                    />
                    <path
                        d="M37.0932 47.3536V46.5349C37.0932 46.0942 37.4505 45.737 37.8912 45.737H43.1281C43.4583 45.737 43.7544 45.9403 43.8729 46.2485L44.2445 47.2147C44.4487 47.7457 44.0475 48.3138 43.4788 48.2988L37.8702 48.1512C37.4378 48.1398 37.0932 47.7861 37.0932 47.3536Z"
                        fill="url(#paint22_linear_806_2509)"
                    />
                    <path
                        d="M45.4584 47.663L45.1692 47.0268C44.9335 46.5083 45.3013 45.9169 45.8706 45.8991L50.6681 45.7492C50.9136 45.7415 51.1488 45.8472 51.306 46.0359L51.9612 46.822C52.3943 47.3417 52.0247 48.1308 51.3482 48.1308H46.1848C45.8719 48.1308 45.5878 47.9479 45.4584 47.663Z"
                        fill="url(#paint23_linear_806_2509)"
                    />
                    <path
                        d="M21.2952 43.2168L21.3135 43.1958C21.4543 43.0349 21.6538 42.9372 21.8672 42.9247L23.6165 42.8218C24.2133 42.7867 24.6353 43.3961 24.3925 43.9424L24.3374 44.0663C24.2094 44.3544 23.9236 44.5401 23.6083 44.5401H21.8957C21.2101 44.5401 20.8437 43.7327 21.2952 43.2168Z"
                        fill="url(#paint24_linear_806_2509)"
                    />
                    <path
                        d="M31.466 44.5401H26.2158C25.6227 44.5401 25.2421 43.9054 25.5074 43.3749C25.6388 43.1121 25.9059 42.9389 26.1996 42.9303L31.4803 42.775C32.0002 42.7597 32.3955 43.2379 32.2827 43.7456L32.245 43.9153C32.1638 44.2804 31.84 44.5401 31.466 44.5401Z"
                        fill="url(#paint25_linear_806_2509)"
                    />
                    <path
                        d="M33.1198 43.5691L33.1642 43.3696C33.2453 43.0046 33.5691 42.7448 33.9431 42.7448H39.1113C39.4548 42.7448 39.7597 42.9646 39.8683 43.2904L39.9348 43.4899C40.107 44.0066 39.7224 44.5401 39.1778 44.5401H33.8988C33.3883 44.5401 33.0091 44.0674 33.1198 43.5691Z"
                        fill="url(#paint26_linear_806_2509)"
                    />
                    <path
                        d="M45.577 44.5401H41.5063C41.1402 44.5401 40.821 44.291 40.7322 43.9357C40.6063 43.4321 40.9872 42.9443 41.5063 42.9443H45.4186C45.6989 42.9443 45.9586 43.0914 46.1028 43.3317L46.1843 43.4675C46.3515 43.7462 46.3076 44.1029 46.0778 44.3327C45.945 44.4655 45.7648 44.5401 45.577 44.5401Z"
                        fill="url(#paint27_linear_806_2509)"
                    />
                    <path
                        d="M49.8167 44.5401H48.2187C47.8861 44.5401 47.5883 44.3338 47.4715 44.0224C47.2759 43.5007 47.6615 42.9443 48.2187 42.9443H49.8167C50.0918 42.9443 50.3475 43.086 50.4934 43.3193C50.8255 43.8508 50.4434 44.5401 49.8167 44.5401Z"
                        fill="url(#paint28_linear_806_2509)"
                    />
                    <path
                        d="M29.0429 41.9469H23.3179C22.665 41.9469 22.2922 41.2014 22.684 40.6791C22.8383 40.4733 23.0829 40.3553 23.3399 40.3624L29.0627 40.5214C29.5658 40.5354 29.8964 41.0522 29.6981 41.5148C29.5858 41.7769 29.328 41.9469 29.0429 41.9469Z"
                        fill="url(#paint29_linear_806_2509)"
                    />
                    <path
                        d="M35.4101 41.7711L31.4753 41.9067C30.921 41.9258 30.5252 41.3753 30.72 40.8559C30.8339 40.5522 31.1242 40.351 31.4485 40.351H35.3856C35.7779 40.351 36.0958 40.669 36.0958 41.0613C36.0958 41.444 35.7926 41.7579 35.4101 41.7711Z"
                        fill="url(#paint30_linear_806_2509)"
                    />
                    <path
                        d="M41.5891 41.7474H37.4325C37.1136 41.7474 36.8334 41.536 36.7458 41.2295C36.6182 40.7831 36.9439 40.3355 37.4078 40.3195L41.5621 40.1763C41.9685 40.1623 42.3184 40.4606 42.3689 40.8642C42.4275 41.3332 42.0618 41.7474 41.5891 41.7474Z"
                        fill="url(#paint31_linear_806_2509)"
                    />
                    <path
                        d="M47.506 41.7474H43.7759C43.3903 41.7474 43.0777 41.4348 43.0777 41.0492C43.0777 40.6636 43.3903 40.351 43.7759 40.351H47.506C47.7315 40.351 47.9431 40.4599 48.0741 40.6434C48.4042 41.1055 48.0739 41.7474 47.506 41.7474Z"
                        fill="url(#paint32_linear_806_2509)"
                    />
                    <path
                        d="M44.484 39.5531H46.4288C46.8469 39.5531 47.0794 39.0694 46.8182 38.7429C46.7236 38.6246 46.5803 38.5557 46.4288 38.5557H44.484C44.1693 38.5557 43.9333 38.8436 43.995 39.1522C44.0416 39.3853 44.2463 39.5531 44.484 39.5531Z"
                        fill="url(#paint33_linear_806_2509)"
                    />
                    <path
                        d="M39.6699 39.3737L42.4443 39.5198C42.7654 39.5367 43.0164 39.2464 42.9534 38.931C42.9092 38.7102 42.721 38.5474 42.4961 38.5356L39.7217 38.3896C39.4005 38.3727 39.1495 38.6629 39.2126 38.9783C39.2567 39.1991 39.445 39.3619 39.6699 39.3737Z"
                        fill="url(#paint34_linear_806_2509)"
                    />
                    <path
                        d="M33.6024 39.5531H38.0809C38.3956 39.5531 38.6316 39.2652 38.5699 38.9566C38.5233 38.7235 38.3186 38.5557 38.0809 38.5557H33.6024C33.327 38.5557 33.1037 38.779 33.1037 39.0544C33.1037 39.3298 33.327 39.5531 33.6024 39.5531Z"
                        fill="url(#paint35_linear_806_2509)"
                    />
                    <path
                        d="M28.0616 39.3929L31.5121 39.5367C31.7523 39.5467 31.976 39.4148 32.0835 39.1998C32.2815 38.8038 32.0053 38.3354 31.5629 38.317L28.1124 38.1732C27.8722 38.1632 27.6485 38.2951 27.541 38.5101C27.343 38.906 27.6192 39.3745 28.0616 39.3929Z"
                        fill="url(#paint36_linear_806_2509)"
                    />
                    <path
                        d="M24.6468 39.5531H25.8016C26.0016 39.5531 26.1885 39.4531 26.2995 39.2866C26.5646 38.8889 26.2795 38.3562 25.8016 38.3562H24.6468C24.4467 38.3562 24.2598 38.4562 24.1488 38.6227C23.8837 39.0204 24.1688 39.5531 24.6468 39.5531Z"
                        fill="url(#paint37_linear_806_2509)"
                    />
                    <path
                        d="M30.5978 37.5583H25.888C25.5325 37.5583 25.3545 37.1285 25.6059 36.8772C25.6807 36.8024 25.7822 36.7603 25.888 36.7603H30.5978C30.8573 36.7603 31.0478 37.0043 30.9848 37.2561C30.9404 37.4337 30.7808 37.5583 30.5978 37.5583Z"
                        fill="url(#paint38_linear_806_2509)"
                    />
                    <path
                        d="M35.3949 37.5811L32.2549 37.7238C31.9048 37.7397 31.6554 37.3886 31.7855 37.0633C31.8587 36.8803 32.0359 36.7603 32.233 36.7603H35.3763C35.5647 36.7603 35.7289 36.8886 35.7746 37.0713C35.8375 37.323 35.6541 37.5693 35.3949 37.5811Z"
                        fill="url(#paint39_linear_806_2509)"
                    />
                    <path
                        d="M40.1288 37.7577H37.0437C36.8327 37.7577 36.6428 37.6292 36.5644 37.4332C36.4327 37.1038 36.6648 36.7427 37.0192 36.7259L40.1007 36.5791C40.3339 36.568 40.5517 36.6956 40.6562 36.9044C40.8522 37.2965 40.5671 37.7577 40.1288 37.7577Z"
                        fill="url(#paint40_linear_806_2509)"
                    />
                    <path
                        d="M45.3224 37.7577H42.188C41.9961 37.7577 41.8168 37.6618 41.7103 37.5021C41.4496 37.1111 41.7435 36.5896 42.213 36.61L45.3444 36.7461C45.5432 36.7548 45.7184 36.8791 45.7923 37.0638C45.9252 37.3962 45.6804 37.7577 45.3224 37.7577Z"
                        fill="url(#paint41_linear_806_2509)"
                    />
                    <path
                        d="M43.5887 36.1619H44.4741C44.8028 36.1619 44.9905 35.7866 44.7932 35.5236C44.7179 35.4231 44.5996 35.364 44.4741 35.364H43.5887C43.3291 35.364 43.1387 35.6079 43.2016 35.8597C43.246 36.0373 43.4056 36.1619 43.5887 36.1619Z"
                        fill="url(#paint42_linear_806_2509)"
                    />
                    <path
                        d="M39.0006 36.1619H42.0332C42.3298 36.1619 42.5227 35.8498 42.39 35.5846C42.3225 35.4494 42.1843 35.364 42.0332 35.364H39.0006C38.741 35.364 38.5506 35.6079 38.6135 35.8597C38.6579 36.0373 38.8175 36.1619 39.0006 36.1619Z"
                        fill="url(#paint43_linear_806_2509)"
                    />
                    <path
                        d="M34.0136 36.1619H37.3802C37.6397 36.1619 37.8302 35.918 37.7672 35.6662C37.7228 35.4886 37.5633 35.364 37.3802 35.364H34.0136C33.8305 35.364 33.6709 35.4886 33.6265 35.6662C33.5636 35.918 33.754 36.1619 34.0136 36.1619Z"
                        fill="url(#paint44_linear_806_2509)"
                    />
                    <path
                        d="M29.4255 36.1619H32.4581C32.6092 36.1619 32.7473 36.0766 32.8149 35.9414C32.9475 35.6761 32.7547 35.364 32.4581 35.364H29.4255C29.2424 35.364 29.0828 35.4886 29.0384 35.6662C28.9755 35.918 29.1659 36.1619 29.4255 36.1619Z"
                        fill="url(#paint45_linear_806_2509)"
                    />
                    <path
                        d="M26.5207 36.1619H27.7176C27.8432 36.1619 27.9614 36.1028 28.0368 36.0024C28.234 35.7393 28.0464 35.364 27.7176 35.364H26.5207C26.3951 35.364 26.2769 35.4231 26.2015 35.5236C26.0043 35.7866 26.1919 36.1619 26.5207 36.1619Z"
                        fill="url(#paint46_linear_806_2509)"
                    />
                    <path
                        d="M27.5181 60.4987H17.1451L14.9508 63.8898L14.5518 66.0841H25.9223L27.1192 62.8924L27.5181 60.4987Z"
                        fill="url(#paint47_linear_806_2509)"
                    />
                    <path
                        d="M14.5519 60.4987H9.56485H8.16848L5.37573 64.8873L5.7747 66.2836H11.1607L14.1529 62.294L14.5519 60.4987Z"
                        fill="url(#paint48_linear_806_2509)"
                    />
                    <path
                        d="M45.0726 64.8873L44.4741 60.6982L52.4534 60.2992L54.4482 61.0971L55.8446 63.6904L56.2436 65.8847H45.4715L45.0726 64.8873Z"
                        fill="url(#paint49_linear_806_2509)"
                    />
                    <path
                        d="M56.4431 60.8976H62.6271L66.0183 66.0842H57.64L56.2437 62.294L56.4431 60.8976Z"
                        fill="url(#paint50_linear_806_2509)"
                    />
                    <rect
                        x="49.4655"
                        y="23.3951"
                        width="2.55479"
                        height="13.0429"
                        rx="1.2774"
                        fill="url(#paint51_linear_806_2509)"
                    />
                    <rect
                        x="50.1331"
                        y="25.5372"
                        width="0.357012"
                        height="9.28231"
                        rx="0.178506"
                        fill="#93D1FF"
                    />
                    <rect
                        x="51.2041"
                        y="25.5372"
                        width="0.357012"
                        height="9.28231"
                        rx="0.178506"
                        fill="#93D1FF"
                    />
                    <rect
                        x="49.062"
                        y="23.3951"
                        width="3.36157"
                        height="1.34463"
                        rx="0.672313"
                        fill="url(#paint52_linear_806_2509)"
                    />
                    <rect
                        x="49.062"
                        y="35.6313"
                        width="3.36157"
                        height="1.34463"
                        rx="0.672313"
                        fill="url(#paint53_linear_806_2509)"
                    />
                    <rect
                        x="19.9423"
                        y="23.3951"
                        width="2.55479"
                        height="13.0429"
                        rx="1.2774"
                        fill="url(#paint54_linear_806_2509)"
                    />
                    <rect
                        x="20.6099"
                        y="25.5372"
                        width="0.357012"
                        height="9.28231"
                        rx="0.178506"
                        fill="#93D1FF"
                    />
                    <rect
                        x="21.6809"
                        y="25.5372"
                        width="0.357012"
                        height="9.28231"
                        rx="0.178506"
                        fill="#93D1FF"
                    />
                    <rect
                        x="19.5388"
                        y="23.3951"
                        width="3.36157"
                        height="1.34463"
                        rx="0.672313"
                        fill="url(#paint55_linear_806_2509)"
                    />
                    <rect
                        x="19.5389"
                        y="35.6313"
                        width="3.36157"
                        height="1.34463"
                        rx="0.672313"
                        fill="url(#paint56_linear_806_2509)"
                    />
                    <path
                        d="M13.9385 21.9689L9.12879 12.3495C8.05644 10.2048 5.09051 9.96426 3.68659 11.9082L1.58551 14.8173V55.5116H9.60409C14.6355 55.5116 17.5308 49.7917 14.5518 45.737C16.5541 45.737 17.6623 43.416 16.4034 41.8589L11.9586 36.3614C13.9216 36.3614 15.158 34.2473 14.1956 32.5364L10.9612 26.7862C13.4357 26.7862 15.0451 24.1821 13.9385 21.9689Z"
                        fill="url(#paint57_linear_806_2509)"
                    />
                    <path
                        d="M54.2203 36.218L58.7203 29.237C59.8478 27.4879 62.3207 27.2683 63.7389 28.7913L66.181 31.4142V63.2914H56.3802C52.3973 63.2914 50.3474 58.5262 53.0863 55.6346C51.2617 55.6346 50.3676 53.4111 51.6843 52.1479L55.7053 48.2903C53.859 48.2903 52.7913 46.197 53.8751 44.7024L56.7125 40.7898C54.3649 40.7898 52.9483 38.1912 54.2203 36.218Z"
                        fill="url(#paint58_linear_806_2509)"
                    />
                </g>
            </g>
            <defs>
                <linearGradient
                    id="paint0_linear_806_2509"
                    x1="38.0198"
                    y1="23.152"
                    x2="20.9022"
                    y2="61.4077"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#A2D904" />
                    <stop offset="1" stopColor="#3C8F01" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_806_2509"
                    x1="23.5461"
                    y1="30.5667"
                    x2="22.3057"
                    y2="37.5038"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#72019A" />
                    <stop offset="1" stopColor="#9500CA" />
                </linearGradient>
                <linearGradient
                    id="paint2_linear_806_2509"
                    x1="25.9399"
                    y1="31.8712"
                    x2="25.2946"
                    y2="36.913"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#72019A" />
                    <stop offset="1" stopColor="#9500CA" />
                </linearGradient>
                <linearGradient
                    id="paint3_linear_806_2509"
                    x1="66.1046"
                    y1="31.313"
                    x2="64.8355"
                    y2="38.7947"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#72019A" />
                    <stop offset="1" stopColor="#9500CA" />
                </linearGradient>
                <linearGradient
                    id="paint4_linear_806_2509"
                    x1="47.3532"
                    y1="31.313"
                    x2="46.0841"
                    y2="38.7947"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#72019A" />
                    <stop offset="1" stopColor="#9500CA" />
                </linearGradient>
                <linearGradient
                    id="paint5_linear_806_2509"
                    x1="68.0993"
                    y1="31.5642"
                    x2="67.1182"
                    y2="38.1637"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#72019A" />
                    <stop offset="1" stopColor="#9500CA" />
                </linearGradient>
                <linearGradient
                    id="paint6_linear_806_2509"
                    x1="49.7471"
                    y1="32.1098"
                    x2="48.766"
                    y2="38.7093"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#72019A" />
                    <stop offset="1" stopColor="#9500CA" />
                </linearGradient>
                <linearGradient
                    id="paint7_linear_806_2509"
                    x1="34.5"
                    y1="35.0525"
                    x2="34.9657"
                    y2="65.7153"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#BE8AFF" />
                    <stop offset="1" stopColor="#D900EC" />
                </linearGradient>
                <linearGradient
                    id="paint8_linear_806_2509"
                    x1="35.5499"
                    y1="38.0451"
                    x2="31.3838"
                    y2="68.527"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="#BDE3FF" />
                </linearGradient>
                <linearGradient
                    id="paint9_linear_806_2509"
                    x1="38.1036"
                    y1="44.6886"
                    x2="33.3061"
                    y2="67.3819"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F2C331" />
                    <stop offset="1" stopColor="#EE7614" />
                </linearGradient>
                <linearGradient
                    id="paint10_linear_806_2509"
                    x1="35.8219"
                    y1="61.0245"
                    x2="35.2533"
                    y2="66.2643"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="#BDE3FF" />
                </linearGradient>
                <linearGradient
                    id="paint11_linear_806_2509"
                    x1="34.5822"
                    y1="54.9086"
                    x2="34.4712"
                    y2="58.8816"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="#BDE3FF" />
                </linearGradient>
                <linearGradient
                    id="paint12_linear_806_2509"
                    x1="34.5822"
                    y1="54.9086"
                    x2="34.4712"
                    y2="58.8816"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="#BDE3FF" />
                </linearGradient>
                <linearGradient
                    id="paint13_linear_806_2509"
                    x1="34.5822"
                    y1="54.9086"
                    x2="34.4712"
                    y2="58.8816"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="#BDE3FF" />
                </linearGradient>
                <linearGradient
                    id="paint14_linear_806_2509"
                    x1="34.5822"
                    y1="54.9086"
                    x2="34.4712"
                    y2="58.8816"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="#BDE3FF" />
                </linearGradient>
                <linearGradient
                    id="paint15_linear_806_2509"
                    x1="35.2067"
                    y1="49.7714"
                    x2="35.1583"
                    y2="52.2318"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="#BDE3FF" />
                </linearGradient>
                <linearGradient
                    id="paint16_linear_806_2509"
                    x1="35.2067"
                    y1="49.7714"
                    x2="35.1583"
                    y2="52.2318"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="#BDE3FF" />
                </linearGradient>
                <linearGradient
                    id="paint17_linear_806_2509"
                    x1="35.2067"
                    y1="49.7714"
                    x2="35.1583"
                    y2="52.2318"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="#BDE3FF" />
                </linearGradient>
                <linearGradient
                    id="paint18_linear_806_2509"
                    x1="35.2067"
                    y1="49.7714"
                    x2="35.1583"
                    y2="52.2318"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="#BDE3FF" />
                </linearGradient>
                <linearGradient
                    id="paint19_linear_806_2509"
                    x1="35.2067"
                    y1="49.7714"
                    x2="35.1583"
                    y2="52.2318"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="#BDE3FF" />
                </linearGradient>
                <linearGradient
                    id="paint20_linear_806_2509"
                    x1="35.0315"
                    y1="45.9999"
                    x2="34.9672"
                    y2="48.6491"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="#BDE3FF" />
                </linearGradient>
                <linearGradient
                    id="paint21_linear_806_2509"
                    x1="35.0315"
                    y1="45.9999"
                    x2="34.9672"
                    y2="48.6491"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="#BDE3FF" />
                </linearGradient>
                <linearGradient
                    id="paint22_linear_806_2509"
                    x1="35.0315"
                    y1="45.9999"
                    x2="34.9672"
                    y2="48.6491"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="#BDE3FF" />
                </linearGradient>
                <linearGradient
                    id="paint23_linear_806_2509"
                    x1="35.0315"
                    y1="45.9999"
                    x2="34.9672"
                    y2="48.6491"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="#BDE3FF" />
                </linearGradient>
                <linearGradient
                    id="paint24_linear_806_2509"
                    x1="35.5515"
                    y1="42.9138"
                    x2="35.521"
                    y2="44.6173"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="#BDE3FF" />
                </linearGradient>
                <linearGradient
                    id="paint25_linear_806_2509"
                    x1="35.5515"
                    y1="42.9138"
                    x2="35.521"
                    y2="44.6173"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="#BDE3FF" />
                </linearGradient>
                <linearGradient
                    id="paint26_linear_806_2509"
                    x1="35.5515"
                    y1="42.9138"
                    x2="35.521"
                    y2="44.6173"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="#BDE3FF" />
                </linearGradient>
                <linearGradient
                    id="paint27_linear_806_2509"
                    x1="35.5515"
                    y1="42.9138"
                    x2="35.521"
                    y2="44.6173"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="#BDE3FF" />
                </linearGradient>
                <linearGradient
                    id="paint28_linear_806_2509"
                    x1="35.5515"
                    y1="42.9138"
                    x2="35.521"
                    y2="44.6173"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="#BDE3FF" />
                </linearGradient>
                <linearGradient
                    id="paint29_linear_806_2509"
                    x1="35.1713"
                    y1="40.3206"
                    x2="35.1363"
                    y2="42.0239"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="#BDE3FF" />
                </linearGradient>
                <linearGradient
                    id="paint30_linear_806_2509"
                    x1="35.1713"
                    y1="40.3206"
                    x2="35.1363"
                    y2="42.0239"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="#BDE3FF" />
                </linearGradient>
                <linearGradient
                    id="paint31_linear_806_2509"
                    x1="35.1713"
                    y1="40.3206"
                    x2="35.1363"
                    y2="42.0239"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="#BDE3FF" />
                </linearGradient>
                <linearGradient
                    id="paint32_linear_806_2509"
                    x1="35.1713"
                    y1="40.3206"
                    x2="35.1363"
                    y2="42.0239"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="#BDE3FF" />
                </linearGradient>
                <linearGradient
                    id="paint33_linear_806_2509"
                    x1="35.3857"
                    y1="38.2882"
                    x2="35.3618"
                    y2="39.6131"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="#BDE3FF" />
                </linearGradient>
                <linearGradient
                    id="paint34_linear_806_2509"
                    x1="35.3857"
                    y1="38.2882"
                    x2="35.3618"
                    y2="39.6131"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="#BDE3FF" />
                </linearGradient>
                <linearGradient
                    id="paint35_linear_806_2509"
                    x1="35.3857"
                    y1="38.2882"
                    x2="35.3618"
                    y2="39.6131"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="#BDE3FF" />
                </linearGradient>
                <linearGradient
                    id="paint36_linear_806_2509"
                    x1="35.3857"
                    y1="38.2882"
                    x2="35.3618"
                    y2="39.6131"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="#BDE3FF" />
                </linearGradient>
                <linearGradient
                    id="paint37_linear_806_2509"
                    x1="35.3857"
                    y1="38.2882"
                    x2="35.3618"
                    y2="39.6131"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="#BDE3FF" />
                </linearGradient>
                <linearGradient
                    id="paint38_linear_806_2509"
                    x1="35.3986"
                    y1="36.6735"
                    x2="35.3787"
                    y2="37.8092"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="#BDE3FF" />
                </linearGradient>
                <linearGradient
                    id="paint39_linear_806_2509"
                    x1="35.3986"
                    y1="36.6735"
                    x2="35.3787"
                    y2="37.8092"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="#BDE3FF" />
                </linearGradient>
                <linearGradient
                    id="paint40_linear_806_2509"
                    x1="35.3986"
                    y1="36.6735"
                    x2="35.3787"
                    y2="37.8092"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="#BDE3FF" />
                </linearGradient>
                <linearGradient
                    id="paint41_linear_806_2509"
                    x1="35.3986"
                    y1="36.6735"
                    x2="35.3787"
                    y2="37.8092"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="#BDE3FF" />
                </linearGradient>
                <linearGradient
                    id="paint42_linear_806_2509"
                    x1="35.4061"
                    y1="35.4391"
                    x2="35.3965"
                    y2="36.1964"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="#BDE3FF" />
                </linearGradient>
                <linearGradient
                    id="paint43_linear_806_2509"
                    x1="35.4061"
                    y1="35.4391"
                    x2="35.3965"
                    y2="36.1964"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="#BDE3FF" />
                </linearGradient>
                <linearGradient
                    id="paint44_linear_806_2509"
                    x1="35.4061"
                    y1="35.4391"
                    x2="35.3965"
                    y2="36.1964"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="#BDE3FF" />
                </linearGradient>
                <linearGradient
                    id="paint45_linear_806_2509"
                    x1="35.4061"
                    y1="35.4391"
                    x2="35.3965"
                    y2="36.1964"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="#BDE3FF" />
                </linearGradient>
                <linearGradient
                    id="paint46_linear_806_2509"
                    x1="35.4061"
                    y1="35.4391"
                    x2="35.3965"
                    y2="36.1964"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="#BDE3FF" />
                </linearGradient>
                <linearGradient
                    id="paint47_linear_806_2509"
                    x1="20.9744"
                    y1="61.0245"
                    x2="20.2789"
                    y2="66.2331"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="#BDE3FF" />
                </linearGradient>
                <linearGradient
                    id="paint48_linear_806_2509"
                    x1="9.92097"
                    y1="61.0433"
                    x2="8.88731"
                    y2="66.3321"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="#BDE3FF" />
                </linearGradient>
                <linearGradient
                    id="paint49_linear_806_2509"
                    x1="50.3039"
                    y1="60.8251"
                    x2="49.5404"
                    y2="66.0142"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="#BDE3FF" />
                </linearGradient>
                <linearGradient
                    id="paint50_linear_806_2509"
                    x1="61.0853"
                    y1="61.386"
                    x2="60.2969"
                    y2="66.179"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="#BDE3FF" />
                </linearGradient>
                <linearGradient
                    id="paint51_linear_806_2509"
                    x1="50.7309"
                    y1="24.6231"
                    x2="45.1408"
                    y2="28.1551"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="#BDE3FF" />
                </linearGradient>
                <linearGradient
                    id="paint52_linear_806_2509"
                    x1="50.7271"
                    y1="23.5217"
                    x2="50.5712"
                    y2="24.7786"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="#BDE3FF" />
                </linearGradient>
                <linearGradient
                    id="paint53_linear_806_2509"
                    x1="50.7271"
                    y1="35.7579"
                    x2="50.5712"
                    y2="37.0148"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="#BDE3FF" />
                </linearGradient>
                <linearGradient
                    id="paint54_linear_806_2509"
                    x1="21.2077"
                    y1="24.6231"
                    x2="15.6176"
                    y2="28.1551"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="#BDE3FF" />
                </linearGradient>
                <linearGradient
                    id="paint55_linear_806_2509"
                    x1="21.2039"
                    y1="23.5217"
                    x2="21.048"
                    y2="24.7786"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="#BDE3FF" />
                </linearGradient>
                <linearGradient
                    id="paint56_linear_806_2509"
                    x1="21.204"
                    y1="35.7579"
                    x2="21.0481"
                    y2="37.0148"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="#BDE3FF" />
                </linearGradient>
                <linearGradient
                    id="paint57_linear_806_2509"
                    x1="13.5409"
                    y1="14.8271"
                    x2="-9.33713"
                    y2="30.9466"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#72019A" />
                    <stop offset="1" stopColor="#9500CA" />
                </linearGradient>
                <linearGradient
                    id="paint58_linear_806_2509"
                    x1="62.8349"
                    y1="31.1281"
                    x2="43.7899"
                    y2="47.8502"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#72019A" />
                    <stop offset="1" stopColor="#9500CA" />
                </linearGradient>
            </defs>
        </svg>
    );
};

import {
    Dispatch,
    RefObject,
    SetStateAction,
    useCallback,
    useEffect,
    useState,
} from 'react';

import { isScrolledToBottom } from 'src/utils/element.utils';

/**
 * does anything on window resize, automatically subscribing and unsubscribing
 * @param callback takes an event of resizing
 */
export const useOnResizeWindow = (
    callback: () => void,
    callOnStart = false,
) => {
    useEffect(() => {
        if (callOnStart) {
            callback();
        }
        window.addEventListener('resize', callback);
        return () => window.removeEventListener('resize', callback);
    }, [callback, callOnStart]);
};

/**
 * does anything on event fired from an element, automatically subscribing and unsubscribing,
 * @WARNING needed only if you can't apply handlers right in JSX.
 *  Please prefer applying right in JSX when possible!
 *
 * @param event HTMLElement even you want to listen
 * @param ref React.RefObject with element you want to listen from
 * @param onEvent callback which will be called on every event firing
 *
 * @example
 * useElementEvent('click', elementRef, onClick);
 * it will subscribe on and unsubscriber from element click event on unmount/unmount or on function changing
 */
export const useElementEvent = <
    EventName extends keyof HTMLElementEventMap,
    E extends HTMLElement,
>(
    event: EventName,
    ref: RefObject<E>,
    onEvent: (e: HTMLElementEventMap[EventName]) => void,
) => {
    useEffect(() => {
        const element = ref.current;

        if (element) {
            element.addEventListener(event, onEvent);
        }

        // unsubscribing from an event before the next subscription or on component unmount
        return () => {
            if (element) {
                element.removeEventListener(event, onEvent);
            }
        };
    }, [event, ref, onEvent]);
};

export const useElementScrollToBottom = (
    onScrollToBottom: () => void,
    offset = 0,
): [HTMLElement | null, Dispatch<SetStateAction<HTMLElement | null>>] => {
    const [ref, setRef] = useState<HTMLElement | null>(null);

    const onScroll = useCallback(() => {
        if (ref && isScrolledToBottom(ref, offset)) {
            onScrollToBottom();
        }
    }, [offset, onScrollToBottom, ref]);

    useEffect(() => {
        ref?.addEventListener('scroll', onScroll);

        return () => {
            ref?.removeEventListener('scroll', onScroll);
        };
    }, [onScroll, ref]);

    return [ref, setRef];
};

export const useDocumentEventHandler = <
    EventName extends keyof HTMLElementEventMap,
>(
    event: EventName,
    handler: (e: HTMLElementEventMap[EventName]) => void,
) => {
    useEffect(() => {
        document.addEventListener(event, handler);

        return () => {
            document.removeEventListener(event, handler);
        };
    }, [event, handler]);
};

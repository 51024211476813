import { MenuItemConfigurationV2 } from '@buildingradar/br_component_lib';
import { makeAutoObservable } from 'mobx';

import {
    DealContactCompany,
    DealContactPerson,
} from 'src/app-features/contact/data/model/deal-contacts.model';
import { IDealContactsStore } from 'src/app-features/contact/data/stores/deal-contacts.store.interface';
import { IGoogleEnrichmentApi } from 'src/app-features/google-enrichment/data/google-enrichment.api';
import {
    GoogleEnrichmentData,
    GoogleEnrichmentResultKey,
} from 'src/app-features/google-enrichment/data/google-enrichment.model';
import { MixpanelEventName } from 'src/data/services/mixpanel/mixpanel.model';
import { MixpanelService } from 'src/data/services/mixpanel/mixpanel.service';
import { IAccountConfigurationStore } from 'src/data/stores/account-configuration/account-configuration.store.interface';
import { IQuickActionsStoreInterface } from 'src/data/stores/actions/quick-actions.store.interface';
import { DealsStore } from 'src/data/stores/deals/deals.store';
import { PipelineStore } from 'src/data/stores/pipeline/pipeline.store';
import { IBaseStore } from 'src/data/stores/shared/base.store.interface';
import { UserStore } from 'src/data/stores/user/user.store';
import { handleRequest } from 'src/utils/handle-request.utils';

import { IGoogleEnrichmentFeature } from './google-enrichment-interface.feature';

export class GoogleEnrichmentFeature implements IGoogleEnrichmentFeature {
    isGoogleEnrichmentLoading = false;
    isPossibleCompanyMatchesLoading = false;
    enrichmentData: GoogleEnrichmentData | null = null;
    enrichmentOptions: MenuItemConfigurationV2[] = [];
    selectedEnrichmentOption: MenuItemConfigurationV2 | null = null;
    selectedData = new Map<GoogleEnrichmentResultKey, string>();

    /* This is used to track of the number of places that were returned by google **ON FIRST LOAD**
     * after user changes the location, we set this back to undefined,
     * so we know when each request is the first request for the current selected company
     */
    initialRequestMarker?: boolean;

    get selectedContact(): DealContactPerson | undefined {
        return this.quickActionsStore.selectedEntityId
            ? this.contactsStore.personsMap.get(
                  this.quickActionsStore.selectedEntityId,
              )
            : undefined;
    }

    get selectedCompany(): DealContactCompany | undefined {
        return this.contactsStore.companiesMap.get(
            this.selectedContact
                ? this.selectedContact.relevantCompanyId ?? ''
                : this.quickActionsStore.selectedEntityId ?? '',
        );
    }

    get companyOptions(): MenuItemConfigurationV2[] {
        return Array.from(this.contactsStore.companiesMap.values()).map(
            (company) => ({
                value: company.id,
                label: company.name,
            }),
        );
    }

    get companyCustomFields(): string[] {
        const parameters =
            this.accountConfigurationStore.accountConfiguration
                ?.companyCustomFields ?? [];
        return parameters.map((parameter) => parameter.name);
    }

    selectDataToSave = (key: GoogleEnrichmentResultKey, value?: string) => {
        if (value) {
            this.selectedData.set(key, value);
        } else {
            this.selectedData.delete(key);
        }
    };
    setSelectedCompany = (companyId?: string) => {
        if (companyId && !!this.contactsStore.companiesMap.get(companyId)) {
            this.quickActionsStore.setSelectedEntityId(companyId);
            this.selectedEnrichmentOption = null;
            this.enrichmentOptions = [];
            this.enrichmentData = null;
        }
    };

    setIsGoogleEnrichmentLoading = (flag: boolean) =>
        (this.isGoogleEnrichmentLoading = flag);

    setIsPossibleCompanyMatchesLoading = (flag: boolean) =>
        (this.isPossibleCompanyMatchesLoading = flag);

    onGetEnrichmentData = (data: GoogleEnrichmentData | null) => {
        if (data) {
            this.enrichmentData = data;
            this.selectedData.clear();
            data.results.forEach((item) => {
                if (this.companyCustomFields.includes(item.key)) {
                    this.selectedData.set(item.key, item.value);
                }
            });
            this.mixpanelService.trackEvent(
                this.initialRequestMarker
                    ? MixpanelEventName.GoogleEnrichmentOutcome
                    : MixpanelEventName.ChangedGoogleEnrichmentMatch,
                {
                    companyId: this.selectedCompany?.id,
                    totalGooglePlacesFound: this.initialRequestMarker
                        ? this.enrichmentOptions.length
                        : undefined,
                    placeParametersFound: this.enrichmentData.results.map(
                        (val) => val.key,
                    ),
                },
                this.quickActionsStore.selectedDealId,
                this.dealsStore.getDeal,
                this.pipelineStore.getPipeline,
            );
        }
    };

    onGetCompanyLocationData = (
        possibleLocations: MenuItemConfigurationV2[],
    ) => {
        this.enrichmentOptions = possibleLocations;
        this.setSelectedEnrichmentOption(
            possibleLocations[0]?.value ?? null,
            true,
        );
    };

    getCompanyLocations = async (dealId: string, name: string) => {
        this.enrichmentOptions = [];
        this.initialRequestMarker = undefined;
        handleRequest(
            this.googleEnrichmentsApi.getLocationOptions,
            { dealId, name: name.trim() },
            this.onGetCompanyLocationData,
            this.setIsPossibleCompanyMatchesLoading,
            (error) =>
                this.baseStore.onRequestFailed(
                    'fetch-company-locations',
                    error,
                ),
        );
    };

    getEnrichmentData = async (placeId: string) => {
        const { user } = this.userStore;
        if (!user) {
            return;
        }
        this.enrichmentData = null;
        handleRequest(
            this.googleEnrichmentsApi.getLocationEnrichment,
            { placeId, language: user.language },
            this.onGetEnrichmentData,
            this.setIsGoogleEnrichmentLoading,
            (error) =>
                this.baseStore.onRequestFailed(
                    'fetch-google-enrichment-data',
                    error,
                ),
        );
    };

    setSelectedEnrichmentOption = (
        value?: string,
        initialRequestMarker?: boolean,
    ) => {
        const option = this.enrichmentOptions.find(
            (option) => option.value === value,
        );
        this.selectedEnrichmentOption = option ?? null;
        this.initialRequestMarker = initialRequestMarker;
    };

    constructor(
        private googleEnrichmentsApi: IGoogleEnrichmentApi,
        private quickActionsStore: IQuickActionsStoreInterface,
        private contactsStore: IDealContactsStore,
        private dealsStore: DealsStore,
        private pipelineStore: PipelineStore,
        private userStore: UserStore,
        private accountConfigurationStore: IAccountConfigurationStore,
        private baseStore: IBaseStore,
        private mixpanelService: MixpanelService,
    ) {
        makeAutoObservable(this);
    }
    clean = () => {
        this.isGoogleEnrichmentLoading = false;
        this.isPossibleCompanyMatchesLoading = false;
        this.enrichmentData = null;
        this.enrichmentOptions = [];
        this.selectedEnrichmentOption = null;
        this.selectedData.clear();
        this.quickActionsStore.clean();
    };
}

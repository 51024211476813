import { BrButton } from '@buildingradar/br_component_lib';
import styled from '@emotion/styled';
import { Box } from '@mui/material';

import { Spacing } from 'src/resources/styles/spacing';

export const SearchParameterLineStyled = styled(Box)({
    display: 'flex',
    width: '100%', // we need to make the flex children truncate properly
    height: '24px',
    alignItems: 'center',
    gap: Spacing.s0_5,
    color: 'var(--gray-70)',
});

export const SearchParametersListStyled = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
    borderRadius: '4px',
    border: '1px solid var(--blue-20)',
    padding: '12px 16px',
    alignItems: 'flex-start',
});

export const SearchImprovementButtonStyled = styled(BrButton)({
    marginLeft: '-6px',
});

import { History, Location } from 'history';
import { makeAutoObservable } from 'mobx';

import { User } from 'src/domain/models/user/user.model';
import { AppRoute } from 'src/presentation/modules/router/app-route.list';

export interface LocationService {
    redirectToOldOrNewAppIfNeeded: (user: User) => void;
    locationsList: Location[]; // the location history, newest at the beginning
    pushToHistory: (route: string, state?: unknown) => void;
    backTo: (numberAgo: number) => void;
    previousScreeningLocation: Location | undefined;
    isDealFromDashboard: boolean;
}

export class LocationServiceImpl implements LocationService {
    locationsList: Location[] = [];
    isDealFromDashboard = false;

    constructor(
        private history: History,
        private maxLocationsCount: number,
    ) {
        makeAutoObservable(this);

        this.locationsList = [this.history.location];
        this.history.listen(this.onLocationChange);
    }

    updateIsDealFromDashboard = (location: Location) => {
        if (location.pathname.startsWith(AppRoute.deals)) {
            if (
                this.locationsList.length &&
                this.locationsList[0].pathname === AppRoute.home
            ) {
                this.isDealFromDashboard = true;
            }
        } else {
            this.isDealFromDashboard = false;
        }
    };

    onLocationChange = (location: Location) => {
        this.updateIsDealFromDashboard(location);

        this.locationsList.unshift(location);
        if (this.locationsList.length > this.maxLocationsCount) {
            this.locationsList.splice(this.locationsList.length - 1, 1);
        }
    };

    redirectToOldOrNewAppIfNeeded = (user: User) => {
        const serviceDomain = 'service.buildingradar.com';
        const appDomain = 'app.buildingradar.com';
        const { location } = window;

        if (
            user.companySettings.useAppBrDomain &&
            location.host === serviceDomain
        ) {
            location.replace(location.href.replace(location.host, appDomain));
        } else if (
            !user.companySettings.useAppBrDomain &&
            location.host === appDomain
        ) {
            location.replace(
                location.href.replace(location.host, serviceDomain),
            );
        }
    };

    pushToHistory = (route: string, state?: unknown) => {
        this.history.push(route, state);
    };

    backTo = (numberAgo: number) => {
        this.history.go(-numberAgo);
    };

    get previousScreeningLocation(): Location | undefined {
        // we are looking for previous 'screening' location, but not the most recent,
        // as we're using it in screening page where the most recent location is already 'screening'
        const locationsListWithoutFirst = this.locationsList.slice(1);
        const screeningLocationChange = locationsListWithoutFirst.find(
            (location) => location.pathname.startsWith(AppRoute.screening),
        );

        return screeningLocationChange;
    }
}

import { BrTextInputStatus } from '@buildingradar/br_component_lib';
import { observer } from 'mobx-react';
import { FC, useCallback } from 'react';

import {
    DealContactCompanyForm,
    DealContactPersonForm,
} from 'src/app-features/contact/data/model/deal-contacts-form.model';
import {
    DealContactCompany,
    DealContactType,
} from 'src/app-features/contact/data/model/deal-contacts.model';
import {
    CompanyFormConfig,
    PersonFormConfig,
} from 'src/app-features/contact/presentation/contact-panel/form-utils/form-config';
import {
    SimpleFieldConfig,
    isSingleFieldConfig,
} from 'src/presentation/shared/form-control/form.model';

import { CustomFieldContactField } from './field-components/custom-field-input.component';
import { SelectFieldContactField } from './field-components/select-field-input.component';
import { SingleFieldContactField } from './field-components/single-field-input.component';
import { useAnimationStatus } from './hooks';
import { DealContactFormFieldListStyled } from './styles';

interface DealContactFormComponentProps {
    dealId: string;
    type: DealContactType;
    isEditMode: boolean;
    focusField?: string;
    fieldProgress?: Map<string, BrTextInputStatus>;
    formConfig: (CompanyFormConfig | PersonFormConfig)[];
    onSave?: (field: string, value: string) => void;
    createContactCompany: (
        form: DealContactCompanyForm,
        dealId: string,
        onSuccess?: (company: DealContactCompany) => void,
    ) => void;
}

export const DealContactFormComponent: FC<DealContactFormComponentProps> =
    observer(
        ({
            dealId,
            type,
            formConfig,
            isEditMode,
            focusField,
            fieldProgress,
            onSave,
            createContactCompany,
        }) => {
            const isPerson = type === DealContactType.person;
            const createCompanyByName = useCallback(
                (
                    name: string,
                    onSuccess: (company: DealContactCompany) => void,
                ) => {
                    createContactCompany(
                        {
                            name,
                            formType: DealContactType.company,
                            isKey: true,
                            role: '',
                        },
                        dealId,
                        onSuccess,
                    );
                },
                [createContactCompany, dealId],
            );

            const onSaveHandler = isEditMode ? onSave : undefined;

            return (
                <DealContactFormFieldListStyled className="deal-contact-form-container">
                    {formConfig.map(
                        (config: CompanyFormConfig | PersonFormConfig) => {
                            if (
                                (isPerson &&
                                    isSingleFieldConfig(
                                        config as CompanyFormConfig,
                                    )) ||
                                (!isPerson &&
                                    isSingleFieldConfig(
                                        config as PersonFormConfig,
                                    ))
                            ) {
                                const typedConfig = isPerson
                                    ? (config as SimpleFieldConfig<DealContactPersonForm>)
                                    : (config as SimpleFieldConfig<DealContactCompanyForm>);

                                const status = useAnimationStatus(
                                    fieldProgress?.get(typedConfig.name) ??
                                        'idle',
                                );

                                return (
                                    <SingleFieldContactField
                                        key={typedConfig.name.toString()}
                                        onSaveHandler={onSaveHandler}
                                        config={typedConfig}
                                        focusField={focusField}
                                        status={status}
                                    />
                                );
                            } else if (config.type === 'selection-parameter') {
                                const status = useAnimationStatus(
                                    fieldProgress?.get(config.name) ?? 'idle',
                                );

                                return (
                                    <SelectFieldContactField
                                        key={config.name.toString()}
                                        config={config}
                                        createCompanyByName={
                                            createCompanyByName
                                        }
                                        focusField={focusField}
                                        onSaveHandler={onSaveHandler}
                                        status={status}
                                    />
                                );
                            } else if (
                                config.type === 'custom-parameter-line' &&
                                config.customFields.length
                            ) {
                                return (
                                    <CustomFieldContactField
                                        key={config.type}
                                        config={config}
                                        focusField={focusField}
                                        onSaveHandler={onSaveHandler}
                                        fieldProgress={fieldProgress}
                                    />
                                );
                            }
                            return <></>;
                        },
                    )}
                </DealContactFormFieldListStyled>
            );
        },
    );

import { handleRequestAsync } from 'src/utils/handle-request.utils';
import { IOutreachStreakApi } from '../data/omni-search.api';
import { IBaseStore } from 'src/data/stores/shared/base.store.interface';
import {
    OmniSearchLocation,
    OmniSearchResult,
} from './omni-search-result.model';
import { makeAutoObservable, runInAction } from 'mobx';
import { MinimalDeal } from 'src/domain/models/deal/deal.model';
import {
    EventProps,
    MixpanelService,
} from 'src/data/services/mixpanel/mixpanel.service';
import { MixpanelEventName } from 'src/data/services/mixpanel/mixpanel.model';
import { PostHog } from 'posthog-js';

export interface IOmniSearchFeature {
    isSearching: boolean;
    searchResult: OmniSearchResult | null;
    lastSearchTermUsed: string;
    resultsRelatedDeals: MinimalDeal[];
    search: (
        searchTerm: string,
        omniSearchLocation: OmniSearchLocation,
        posthog?: PostHog,
    ) => void;
    clearSearch: () => void;
}

export class OmniSearchFeature implements IOmniSearchFeature {
    isSearching = false;
    searchResult: OmniSearchResult | null = null;
    lastSearchTermUsed = '';
    resultsRelatedDeals: MinimalDeal[] = [];

    constructor(
        private omniSearchApi: IOutreachStreakApi,
        private mixpanelService: MixpanelService,
        private baseStore: IBaseStore,
    ) {
        makeAutoObservable(this);
    }

    search = async (
        searchTerm: string,
        omniSearchLocation: OmniSearchLocation,
        posthog?: PostHog,
    ) => {
        try {
            const response = await handleRequestAsync(
                this.omniSearchApi.search,
                { searchTerm },
                (searching) => (this.isSearching = searching),
                undefined,
                'omni-search',
            );
            if (response) {
                runInAction(() => {
                    const { relatedDeals, ...searchResults } = response;
                    this.searchResult = { ...searchResults };
                    this.resultsRelatedDeals = relatedDeals;
                    this.lastSearchTermUsed = searchTerm;
                });

                this.debounce(() => {
                    this.trackSearchEvent(
                        searchTerm,
                        omniSearchLocation,
                        posthog,
                    );
                }, 3000);
            }
        } catch (error) {
            this.baseStore.onRequestFailed('omni-search-error', error as Error);
        }
    };

    clearSearch = () => {
        this.searchResult = null;
        this.lastSearchTermUsed = '';
    };

    trackSearchEvent = (
        searchTerm: string,
        omniSearchLocation: OmniSearchLocation,
        posthog?: PostHog,
    ) => {
        const trackingData: EventProps = {
            omniSearchBarLocation: omniSearchLocation,
            searchQuery: searchTerm,
        };

        this.mixpanelService.trackEvent(
            MixpanelEventName.SearchedWithOmniSearch,
            trackingData,
        );
        posthog?.capture(
            MixpanelEventName.SearchedWithOmniSearch,
            trackingData,
        );
    };

    private debounceTimeoutId: NodeJS.Timeout | null = null;

    private debounce = (callback: () => void, delay: number) => {
        if (this.debounceTimeoutId) {
            clearTimeout(this.debounceTimeoutId);
        }
        this.debounceTimeoutId = setTimeout(callback, delay);
    };
}

import { makeAutoObservable } from 'mobx';

import { LeadFieldsApi } from 'src/data/api/lead-fields/lead-fields.api';
import { ProcurementTextsStore } from 'src/data/stores/procurement-texts/procurement-texts.store';
import { IBaseStore } from 'src/data/stores/shared/base.store.interface';
import {
    Cancellable,
    emptyCancellable,
    handleRequest,
} from 'src/utils/handle-request.utils';

export interface LeadFieldsFeature {
    requestProcurementText: (
        cpvCodes?: string[] | null,
        naicsCodes?: string[] | null,
        unspscCodes?: string[] | null,
    ) => Cancellable;
    procurementTexts: string[];
    areProcurementTextsLoading: boolean;
}

export class LeadFieldsFeatureImpl implements LeadFieldsFeature {
    get areProcurementTextsLoading(): boolean {
        return this.procurementTextStore.isLoading;
    }

    get procurementTexts(): string[] {
        return this.procurementTextStore.procurementTexts;
    }

    constructor(
        private leadFieldsApi: LeadFieldsApi,
        private procurementTextStore: ProcurementTextsStore,
        private baseStore: IBaseStore,
    ) {
        makeAutoObservable(this);
    }

    requestProcurementText = (
        cpvCodes?: string[] | null,
        naicsCodes?: string[] | null,
        unspscCodes?: string[] | null,
    ): Cancellable => {
        if (
            (!cpvCodes || cpvCodes.length === 0) &&
            (!naicsCodes || naicsCodes.length === 0) &&
            (!unspscCodes || unspscCodes.length === 0)
        ) {
            return emptyCancellable;
        }

        return handleRequest(
            this.leadFieldsApi.getProcurementTexts,
            {
                cpvCodes: cpvCodes ?? [],
                naicsCodes: naicsCodes ?? [],
                unspscCodes: unspscCodes ?? [],
            },
            this.procurementTextStore.setProcurementTexts,
            this.procurementTextStore.setLoading,
            (error) =>
                this.baseStore.onRequestFailed('get-procurement-texts', error),
        );
    };
}

import { withFeatures } from 'src/utils/component.utils';

import { ContactEnrichmentComponent } from './contact-enrichment-content.component';

export const ContactEnrichmentContainer = withFeatures(
    ContactEnrichmentComponent,
)((f) => ({
    dealId: f.quickActionsFeature.selectedDealId,
    language: f.authFeature.user?.language,
    currentCompany: f.contactEnrichmentFeature.selectedCompany,
    isContactEnrichmentLoading:
        f.contactEnrichmentFeature.isContactEnrichmentLoading,
    isDomainEnrichmentLoading:
        f.contactEnrichmentFeature.isDomainEnrichmentLoading,
    currentStep: f.contactEnrichmentFeature.currentStep,
    enrichmentResults: f.contactEnrichmentFeature.contactEnrichmentResults,
    domainEnrichmentOptions: f.contactEnrichmentFeature.domainEnrichmentResults,
    iterateState: f.contactEnrichmentFeature.iterateState,
    getCustomParameterByName:
        f.accountConfigurationFeature.getCustomParameterByName,
    updateContactCompany: f.dealContactsManageFeature.updateDealContactCompany,
}));

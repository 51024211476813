import { withFeatures } from 'src/utils/component.utils';

import { NotificationsFlyout } from './notifications-flyout.component';

export const NotificationsFlyoutContainer = withFeatures(NotificationsFlyout)(
    (f) => ({
        isOpened: f.notificationsFeature.isOpened,
        toggleNotificationsOpened:
            f.notificationsFeature.toggleNotificationsOpened,
    }),
);

import { makeAutoObservable } from 'mobx';
import { OutreachActivityType } from './deal-activity.model';
import { OutreachActivityLaunchSource } from './deal-activity.utils';
import { DealContactUnionType } from 'src/app-features/contact/data/model/deal-contacts.model';

export interface OutreachActivationState {
    logOutreachChosenType?: OutreachActivityType;
    contact?: DealContactUnionType;
    dealId?: string;
    launchSource?: OutreachActivityLaunchSource;
}

export interface IDealActivityStore {
    outreachActivationState: OutreachActivationState;
    sessionId: string | null;
    setOutreachActivationState: (
        state: Partial<OutreachActivationState>,
        userId: number,
    ) => void;
    resetOutreachActivationState: () => void;
}

export class DealActivityStore implements IDealActivityStore {
    outreachActivationState: OutreachActivationState = {};
    sessionId: string | null = null;
    constructor() {
        makeAutoObservable(this);
    }

    setOutreachActivationState = (
        state: Partial<OutreachActivationState>,
        userId: number,
    ) => {
        if (this.sessionId === null) {
            this.sessionId = `${userId}_${new Date().getTime()}`;
        }
        const newState = {
            ...this.outreachActivationState,
            ...state,
        };
        this.outreachActivationState = newState;
    };

    resetOutreachActivationState = () => {
        this.outreachActivationState = {};
        this.sessionId = null;
    };
}

import { observer } from 'mobx-react';
import { FC, useCallback, useEffect, useMemo } from 'react';
import { Redirect, useParams } from 'react-router-dom';

import { Deal, DealOpenSource } from 'src/domain/models/deal/deal.model';
import { Lead } from 'src/domain/models/lead/lead.model';
import { Objective } from 'src/domain/models/objective/objective.model';
import { Pipeline } from 'src/domain/models/pipeline/pipeline.model';
import { AppRoute } from 'src/presentation/modules/router/app-route.list';
import { useConsumeDealOpenURLParameters } from 'src/presentation/modules/router/utils/use-route-data.hook';
import { SecondaryNavbarLayout } from 'src/presentation/shared/secondary-navbar-layout/secondary-navbar-layout.component';
import { withFeatures } from 'src/utils/component.utils';
import { createCtx } from 'src/utils/context.utils';
import { Cancellable, emptyCancellable } from 'src/utils/handle-request.utils';
import { useCancellableRequest } from 'src/utils/hooks/use-demo-hooks';
import { isNonNullable } from 'src/utils/is-non-nullable.utils';
import { IChildren } from 'src/utils/react.utils';

interface DealContext {
    dealId: string;
    deal: Deal;
    pipeline: Pipeline;
    relatedProject: Lead | undefined;
    reminderId: string | undefined;
    objectives: Objective[];
}

const [useDeal, DealProvider] = createCtx<DealContext>();
export default useDeal;

interface DealProviderComponentProps extends IChildren {
    dealsMap: Map<string, Deal>;
    isDealLoading: boolean;
    noDealExists: boolean;
    projectsMap: Map<string, Lead>;
    isRelatedProjectLoading: boolean;
    needToSyncDealAlert: boolean;
    objectivesMap: Map<string, Objective>;
    isUpdateObjectiveLoading: boolean;
    loadDealById: (id: string, onDone: () => void) => Cancellable;
    requestRelatedProject: (deal: Deal) => Cancellable;
    getPipeline: (pipelineId: string) => Pipeline | null;
    getObjectives: (dealId: string) => Cancellable;
    setCurrentSelectedPipeline: (pipeline: Pipeline) => void;
    syncDealAlert: (dealId: string) => void;
    trackDealOpen: (
        dealId: string,
        source: string,
        path: string,
        omniSearchResultType: string | null,
        contactId: string | null,
        companyId: string | null,
    ) => void;
}

const DealProviderComponent: FC<DealProviderComponentProps> = observer(
    ({
        dealsMap,
        isDealLoading,
        noDealExists,
        projectsMap,
        isRelatedProjectLoading,
        needToSyncDealAlert,
        objectivesMap,
        isUpdateObjectiveLoading,
        requestRelatedProject,
        loadDealById,
        getPipeline,
        getObjectives,
        setCurrentSelectedPipeline,
        syncDealAlert,
        trackDealOpen,
        children,
    }) => {
        const { dealId, reminderId } = useParams<{
            dealId?: string;
            reminderId?: string;
        }>();
        const {
            sourceAction,
            sourcePath,
            omniSearchResultType,
            linkedCompanyId,
            linkedContactId,
        } = useConsumeDealOpenURLParameters();
        const deal = dealsMap.get(dealId ?? '');

        const onPageOpenTrack = useCallback(() => {
            if (!dealId) {
                return;
            }
            trackDealOpen(
                dealId,
                sourceAction ?? DealOpenSource.DirectLink,
                sourcePath ?? '',
                omniSearchResultType,
                linkedContactId,
                linkedCompanyId,
            );
        }, [
            dealId,
            trackDealOpen,
            sourceAction,
            sourcePath,
            omniSearchResultType,
            linkedContactId,
            linkedCompanyId,
        ]);

        const requestDeal = useCallback(() => {
            if (deal) {
                onPageOpenTrack();
                return emptyCancellable;
            }
            if (isDealLoading) {
                return emptyCancellable;
            }
            if (dealId) {
                return loadDealById(dealId, onPageOpenTrack);
            }
            return emptyCancellable;
        }, [deal, isDealLoading, dealId, onPageOpenTrack, loadDealById]);

        useCancellableRequest(requestDeal, [dealId]);

        const relatedProject = projectsMap.get(deal?.project?.id ?? '');
        const requestLeadById = useCallback(
            () =>
                relatedProject || !deal
                    ? emptyCancellable
                    : requestRelatedProject(deal),
            [deal, relatedProject, requestRelatedProject],
        );
        useCancellableRequest(requestLeadById, [requestLeadById]);

        const requestObjectives = useCallback(() => {
            if (deal) {
                return getObjectives(deal.id);
            }
            return emptyCancellable;
        }, [getObjectives, deal]);
        useCancellableRequest(requestObjectives, [dealId]);

        const pipeline = getPipeline(deal?.pipeline.id ?? '');

        useEffect(() => {
            if (pipeline) {
                setCurrentSelectedPipeline(pipeline);
            }
        }, [pipeline, setCurrentSelectedPipeline]);

        useEffect(() => {
            if (needToSyncDealAlert && dealId) {
                syncDealAlert(dealId);
            }
        }, [dealId, needToSyncDealAlert, syncDealAlert]);

        const objectives = useMemo(
            () =>
                deal?.objectivesIds
                    .map((objectiveId) => objectivesMap.get(objectiveId))
                    .filter(isNonNullable) ?? [],
            // eslint-disable-next-line react-hooks/exhaustive-deps
            [deal?.objectivesIds, objectivesMap, isUpdateObjectiveLoading],
        );

        const showLoading = isDealLoading || isRelatedProjectLoading;

        if (showLoading) {
            return <SecondaryNavbarLayout title="common.loading" />;
        }

        if (noDealExists) {
            return <Redirect to={AppRoute.pipelines} />;
        }

        if (!dealId || !deal || !pipeline) {
            return null;
        }

        const context: DealContext = {
            dealId,
            deal,
            relatedProject,
            pipeline,
            reminderId,
            objectives,
        };

        return <DealProvider value={context}>{children}</DealProvider>;
    },
);

export const DealProviderContainer = withFeatures(DealProviderComponent)(
    (f) => ({
        dealsMap: f.dealViewFeature.dealsMap,
        isDealLoading: f.dealViewFeature.isDealLoading,
        noDealExists: f.dealViewFeature.noDealExists,
        projectsMap: f.dealViewFeature.projectsMap,
        isRelatedProjectLoading: f.dealViewFeature.isRelatedProjectLoading,
        needToSyncDealAlert: f.dealViewFeature.needToSyncDealAlert,
        objectivesMap: f.stageObjectivesFeature.objectivesMap,
        isUpdateObjectiveLoading:
            f.stageObjectivesFeature.isUpdateObjectiveLoading,
        loadDealById: f.dealViewFeature.loadDealById,
        requestRelatedProject: f.dealViewFeature.requestRelatedProject,
        getPipeline: f.pipelineFeature.getPipeline,
        getObjectives: f.stageObjectivesFeature.getObjectives,
        setCurrentSelectedPipeline:
            f.pipelineFeature.setCurrentSelectedPipeline,
        syncDealAlert: f.dealViewFeature.syncDealAlert,
        trackDealOpen: f.dealViewFeature.trackDealOpen,
    }),
);

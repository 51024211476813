import styled from '@emotion/styled';

export const PageStyled = styled.div`
    width: 100%;
    display: flex;
    background-color: var(--gray-background);
    // height: 100vh;
    // overflow: clip;
`;

export const ContentWrapperStyled = styled.div<{ hasSecondaryNavbar: boolean }>`
    display: flex;
    min-width: 0;
    width: 100%;
    min-height: 100vh;
    ${({ hasSecondaryNavbar }) =>
        hasSecondaryNavbar && 'background-color: var(--button-gray-bg);'}
`;

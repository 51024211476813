import { withFeatures } from 'src/utils/component.utils';

import { EnrichmentSearchParametersComponent } from './enrichment-search-parameters.component';

export const EnrichmentSearchParametersContainer = withFeatures(
    EnrichmentSearchParametersComponent,
)((f) => ({
    selectedCompany: f.contactEnrichmentFeature.selectedCompany,
    selectedContact: f.contactEnrichmentFeature.selectedContact,
    selectedDomain: f.contactEnrichmentFeature.selectedCompanyDomain,
    iterateState: f.contactEnrichmentFeature.iterateState,
}));

import unidecode from 'unidecode';

import {
    DealContactCompany,
    DealContactPerson,
} from 'src/app-features/contact/data/model/deal-contacts.model';
import { getFullName } from 'src/domain/models/user/user.model';
import LushaPNG from 'src/resources/images/lusha.png';

export interface DataProvider {
    id: number;
    logo: string;
    name: string;
    plan: string;
    description: string;
    guideKey?: string;
    generateUrl: (
        contact: DealContactPerson,
        company?: DealContactCompany,
    ) => string;
}

export const LushaDataProviderCardInfo: DataProvider = {
    id: 0,
    logo: LushaPNG,
    name: 'Lusha',
    plan: 'contact_data_provider_panel.provider.free_plan',
    description: 'contact_data_provider_panel.provider.description.lusha',
    guideKey: 'contact_data_provider_panel.provider.guide_link.lusha',
    generateUrl: (contact: DealContactPerson): string => {
        return `https://dashboard.lusha.com/prospecting/contacts?source=search_bar&platform=dashboard&subPlatform=prospecting&contactName=${unidecode(
            getFullName(contact.firstName, contact.lastName).replace(
                /\s/g,
                '+',
            ),
        )}`;
    },
};

import { withFeatures } from 'src/utils/component.utils';

import { ContactExtractionDrawerContentComponent } from './components/contact-extraction-drawer-content.component';

export const ContactExtractionDrawerContainer = withFeatures(
    ContactExtractionDrawerContentComponent,
)((f) => ({
    isOpened: f.quickActionsFeature.isQuickActionsPanelOpen,
    contactCandidates: f.contactExtractionFeature.contactExtractionCandidates,
    companyCandidates: f.contactExtractionFeature.companyExtractionCandidates,
    extractionStatus: f.contactExtractionFeature.contactExtractionResultStatus,
    extractingInProgress: f.contactExtractionFeature.extractionInProgress,
    createContactInProgress:
        f.dealContactsManageFeature.createContactInProgress,
    dealId: f.quickActionsFeature.selectedDealId,
    getAutoExtractionResult: f.contactExtractionFeature.getAutoExtractionResult,
    onPauseDealRequested: f.dealPauseFeature.openPauseModal,
    requestClose: f.quickActionsFeature.clean,
    rerunAutoExtraction: f.contactExtractionFeature.rerunAutoExtraction,
    startContactExtraction: f.contactExtractionFeature.startContactExtraction,
    abortOngoingRequests: f.contactExtractionFeature.abortOngoingRequests,
    resetExtractionResult: f.contactExtractionFeature.cleanUpContactExtraction,
    createContactsFromCandidates:
        f.dealContactsManageFeature.createContactFromCandidates,
    setQuickAction: f.quickActionsFeature.setQuickAction,
    clear: f.contactExtractionFeature.clear,
}));

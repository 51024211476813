import { BrTextInputStatus } from '@buildingradar/br_component_lib';
import { observer } from 'mobx-react';
import { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
    DealContactCompanyForm,
    DealContactPersonForm,
} from 'src/app-features/contact/data/model/deal-contacts-form.model';
import {
    DealContactCompany,
    DealContactPerson,
    DealContactType,
    PredefinedCustomFields,
} from 'src/app-features/contact/data/model/deal-contacts.model';
import { ContactUpdateMetricsInfo } from 'src/app-features/contact/domain/deal-contacts-manage.feature.interface';
import {
    DomainEnrichmentOption,
    EnrichmentResultSelection,
    EnrichmentStep,
} from 'src/app-features/contact-enrichment/domain/contact-enrichment.model';
import {
    DataToBuildEnrichmentNote,
    appendEnrichmentNote,
    buildNotesForEnrichedData,
    buildSelectedDomainOption,
    createEnrichedContactFieldPayload,
    createEnrichedDomainMetricsInfoPayload,
    createEnrichedDomainPayload,
} from 'src/app-features/contact-enrichment/domain/utils';
import {
    CustomParameter,
    CustomParameterEntityType,
} from 'src/domain/models/custom-parameter/custom-parameter.model';
import { Language } from 'src/domain/models/locale/locale.model';
import { EnrichmentIterateStateParams } from 'src/app-features/contact-enrichment/domain/contact-enrichment.feature';
import { Button } from '@buildingradar/ui_library/button';

interface ContactEnrichmentPanelActionsComponentProps {
    dealId: string;
    currentStep: EnrichmentStep;
    isLoading: boolean;
    selectedValues: EnrichmentResultSelection;
    enrichmentTarget?: DealContactPerson;
    selectedCompany?: DealContactCompany;
    selectedEnrichmentOption?: DomainEnrichmentOption;
    fieldProgress: Map<string, BrTextInputStatus>;
    domainOptions: DomainEnrichmentOption[];
    language?: Language;
    iterateState: (params?: EnrichmentIterateStateParams) => void;
    onSelectedEnrichmentOption: (option?: DomainEnrichmentOption) => void;
    updateContactPerson: (
        dealId: string,
        id: string,
        update: DealContactPersonForm,
        metricsInfo: ContactUpdateMetricsInfo,
    ) => void;
    updateContactCompany: (
        dealId: string,
        id: string,
        update: DealContactCompanyForm,
        metricsInfo: ContactUpdateMetricsInfo,
        onSuccess: () => void,
    ) => void;
    onClose: () => void;
    getCustomParameterByName: (
        name: string,
        type: CustomParameterEntityType,
    ) => CustomParameter | undefined;
}
export const ContactEnrichmentPanelActionsComponent: FC<ContactEnrichmentPanelActionsComponentProps> =
    observer(
        ({
            enrichmentTarget,
            selectedCompany,
            dealId,
            isLoading,
            currentStep,
            selectedValues,
            fieldProgress,
            selectedEnrichmentOption,
            domainOptions,
            language = Language.En,
            onSelectedEnrichmentOption,
            updateContactCompany,
            iterateState,
            updateContactPerson,
            onClose,
            getCustomParameterByName,
        }) => {
            if (!enrichmentTarget) {
                return null;
            }
            const { t } = useTranslation();

            const savable =
                !!Object.values(selectedValues).length &&
                !isLoading &&
                currentStep === EnrichmentStep.contactEnrichment;

            const { id, notes } = enrichmentTarget;

            const rerunable = useMemo(
                () =>
                    Array.from(fieldProgress.values()).every(
                        (status) => status !== 'progress',
                    ) && currentStep === EnrichmentStep.refinement,
                [currentStep, fieldProgress],
            );

            const runEnrichment = useCallback(() => {
                onSelectedEnrichmentOption();
                iterateState({
                    to: EnrichmentStep.contactEnrichment,
                    refined: true,
                });
            }, [iterateState, onSelectedEnrichmentOption]);

            const saveCompanyInfoAndEnrich = useCallback(
                (result: DomainEnrichmentOption) => {
                    updateContactCompany(
                        dealId,
                        enrichmentTarget.relevantCompanyId!,
                        createEnrichedDomainPayload(
                            result,
                            t,
                            language,
                            getCustomParameterByName,
                            selectedCompany?.notes,
                        ),
                        createEnrichedDomainMetricsInfoPayload(result),
                        runEnrichment,
                    );
                },
                [
                    updateContactCompany,
                    dealId,
                    enrichmentTarget.relevantCompanyId,
                    t,
                    language,
                    getCustomParameterByName,
                    selectedCompany?.notes,
                    runEnrichment,
                ],
            );

            const updateAndRerun = useCallback(() => {
                const selection = buildSelectedDomainOption(
                    domainOptions,
                    selectedEnrichmentOption,
                );
                if (selection) {
                    saveCompanyInfoAndEnrich(selectedEnrichmentOption!);
                } else {
                    runEnrichment();
                }
            }, [
                domainOptions,
                runEnrichment,
                saveCompanyInfoAndEnrich,
                selectedEnrichmentOption,
            ]);

            const save = () => {
                const customValues =
                    createEnrichedContactFieldPayload(selectedValues);
                const data: DataToBuildEnrichmentNote[] = Object.keys(
                    selectedValues,
                ).map(
                    (key) =>
                        ({
                            fieldType: key,
                            fieldEntityType: 'contactCustomFields',
                            providers:
                                selectedValues[
                                    key as keyof typeof PredefinedCustomFields
                                ]?.providers,
                        }) as DataToBuildEnrichmentNote,
                );

                const noteForEnrichedData = buildNotesForEnrichedData(
                    data,
                    t,
                    language ?? Language.En,
                    getCustomParameterByName,
                );

                updateContactPerson(
                    dealId,
                    id,
                    {
                        customInput: { customValues },
                        formType: DealContactType.person,
                        notes: appendEnrichmentNote(noteForEnrichedData, notes),
                    },
                    {
                        field: Object.keys(selectedValues),
                        enrichmentInfo: selectedValues,
                    },
                );
                onClose();
            };

            return (
                <>
                    {currentStep !== EnrichmentStep.refinement ? (
                        <Button disabled={!savable} onClick={save}>
                            {t(
                                'actions.contact_enrichment.flyout.actions.save_to_contact',
                            )}
                        </Button>
                    ) : (
                        <Button disabled={!rerunable} onClick={updateAndRerun}>
                            {t(
                                'actions.contact_enrichment.search_refinement.actions.rerun_search',
                            )}
                        </Button>
                    )}
                    <Button variant="outline" onClick={onClose}>
                        {t('common.close')}
                    </Button>
                </>
            );
        },
    );

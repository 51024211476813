import { AbortParams } from 'src/data/api/api-client';
import { ProcessGqlSdk } from 'src/data/api/graphql/graphql-client.wrapper';
import {
    createCrmState,
    createIntegration,
    CrmState,
    Integration,
} from 'src/domain/models/integration/integration.model';
import { CRMExportLead } from 'src/domain/models/lead/crm-export-lead.model';

import { throwRelevantError } from './export-to-crm.errors';

export interface ExportToCRMApi {
    sendToCRM: (params: ExportToCRMParams) => Promise<unknown>;
    exportDealToCrm: (params: ExportDealToCRMParams) => Promise<boolean>;
    requestIntegrations: () => Promise<Integration[]>;
    requestCrmState: (params: ExportDealToCRMParams) => Promise<CrmState>;
}

interface ExportToCRMParams extends AbortParams {
    endpoint: string;
    authKey: string;
    authSecret: string;
    lead: CRMExportLead;
    userEmail: string;
}

interface ExportDealToCRMParams extends AbortParams {
    dealId: string;
    integrationId: string;
}

/**
 * This is a special API which requests not to brweb backend but an external domain. Hence, apiClient is not used,
 * rather we use fetch directly here.
 */
export const createExportToCRMApi = (
    processGqlSdk: ProcessGqlSdk,
): ExportToCRMApi => {
    const sendToCRM = (params: ExportToCRMParams) => {
        const { endpoint, authKey, authSecret, lead, userEmail, signal } =
            params;
        const body = JSON.stringify({
            lead: JSON.stringify(lead),
            user_email: userEmail,
        });
        const init: RequestInit = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                key: authKey,
                secret: authSecret,
            },
            signal,
            body,
        };
        return fetch(endpoint, init).then((response) => {
            if (response.status >= 400) {
                throwRelevantError(response.status);
            }
            return response.json();
        });
    };

    const exportDealToCrm = async (params: ExportDealToCRMParams) => {
        const response = await processGqlSdk.ExportToCrm(params);
        return response.exportToCrm.ok;
    };

    const requestIntegrations = async () => {
        const response = await processGqlSdk.GetAllIntegrations();
        return response.allIntegrations.map(createIntegration);
    };

    const requestCrmState = async (params: ExportDealToCRMParams) => {
        const response = await processGqlSdk.GetCrmState(params);
        const { dealId, integrationId } = params;
        return createCrmState(response.deal.export.crm, dealId, integrationId);
    };

    return {
        sendToCRM,
        requestIntegrations,
        exportDealToCrm,
        requestCrmState,
    };
};

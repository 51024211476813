export enum BrAppEnvironment {
    development = 'development',
    beta = 'beta',
    staging = 'staging',
    production = 'production',
}

/**
 * Helper function to get the Br app environment based on the hostname.
 */
export function getBrAppEnvironment(): BrAppEnvironment {
    const baseUrl = window.location.host;

    if (/^localhost/.test(baseUrl) || /^127\./.test(baseUrl)) {
        return BrAppEnvironment.development;
    }
    if (/^beta/.test(baseUrl)) {
        return BrAppEnvironment.beta;
    }
    if (/^release\./.test(baseUrl)) {
        return BrAppEnvironment.staging;
    }
    return BrAppEnvironment.production;
}

export const isProductionEnv =
    getBrAppEnvironment() === BrAppEnvironment.production;

import { AbortParams } from 'src/data/api/api-client';
import { ProjectGqlSdk } from 'src/data/api/graphql/graphql-client.wrapper';
import {
    createCustomTitle,
    CustomTitle,
} from 'src/domain/models/custom-title/custom-title.model';

export interface CustomFieldsApi {
    updateCustomTitle: (params: SetCustomTitleParams) => Promise<boolean>;
    getCustomTitlesOfProjects: (
        params: GetCustomTitlesOfProjectsParams,
    ) => Promise<CustomTitle[]>;
}

interface SetCustomTitleParams extends AbortParams {
    projectId: number;
    title: string;
}

interface GetCustomTitlesOfProjectsParams extends AbortParams {
    projectIds: number[];
}

export const createCustomFieldApi = (
    projectGqlSdk: ProjectGqlSdk,
): CustomFieldsApi => {
    const updateCustomTitle = async (params: SetCustomTitleParams) => {
        const { updateProjectCustomTitle } =
            await projectGqlSdk.UpdateProjectCustomTitle(params);
        return updateProjectCustomTitle.ok;
    };

    const getCustomTitlesOfProjects = async (
        params: GetCustomTitlesOfProjectsParams,
    ): Promise<CustomTitle[]> => {
        const response = await projectGqlSdk.GetCustomTitlesOfProjects(params);

        return response.getCustomTitlesOfProjects.map((data) =>
            createCustomTitle(data.projectId, data.title),
        );
    };

    return {
        updateCustomTitle,
        getCustomTitlesOfProjects,
    };
};

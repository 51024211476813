import styled from '@emotion/styled';
import { Box } from '@mui/material';

import { Spacing } from 'src/resources/styles/spacing';

export const GoogleEnrichmentCompanySelectStyled = styled(Box)({
    paddingTop: Spacing.s1,
    marginBottom: Spacing.s2,
});

export const LoadingIndicaterWrapper = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '200px',
});

export const GoogleEnrichmentContentStyled = styled(Box)({
    minHeight: '200px',
});

export const EmptyStateStyled = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: var(--gray-20);
    color: var(--gray-40);
    width: 48px;
    height: 48px;
    margin: ${Spacing.s1} auto;
`;

export const EmptyStateWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding-top: ${Spacing.s7};
`;

export const GoogleImageStyled = styled.img({
    paddingLeft: '4px',
    maxWidth: '80px',
    height: '22px',
    objectFit: 'contain',
});

export const GoogleEnrichmentResultsWrapper = styled(Box)({
    marginTop: Spacing.s2,
    padding: '10px 16px',
    borderRadius: '2.6px',
    border: '0.65px solid var(--blue-20)',
});

export const GogoleEnrichmentResultsTitle = styled(Box)({
    color: ' var(--gray-100)',
    fontSize: '14px',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '24px',
    letterSpacing: '0.28px',
    textTransform: 'uppercase',
    marginBottom: Spacing.s2,
});

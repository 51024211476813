import { AbortParams } from 'src/data/api/api-client';
import { ProcessGqlSdk } from 'src/data/api/graphql/graphql-client.wrapper';

import {
    GoogleEnrichmentData,
    createGoogleEnrichmentData,
    createLocationData,
} from './google-enrichment.model';

interface GetGoogleEnrichmentLocationsParams extends AbortParams {
    dealId: string;
    name: string;
}

interface GetGoogleEnrichmentDataParams extends AbortParams {
    placeId: string;
    language: string;
}

export interface IGoogleEnrichmentApi {
    getLocationOptions: (
        params: GetGoogleEnrichmentLocationsParams,
    ) => Promise<{ value: string; label: string }[]>;
    getLocationEnrichment: (
        params: GetGoogleEnrichmentDataParams,
    ) => Promise<GoogleEnrichmentData | null>;
}

export const createGoogleEnrichmentApi = (
    processGqlSdk: ProcessGqlSdk,
): IGoogleEnrichmentApi => {
    const getLocationOptions = async (
        params: GetGoogleEnrichmentLocationsParams,
    ) => {
        const response = await processGqlSdk.GetDealLocationData(params);
        return (response.actionFeatureGoogleEnrichmentPlaces ?? []).map(
            createLocationData,
        );
    };

    const getLocationEnrichment = async (
        params: GetGoogleEnrichmentDataParams,
    ): Promise<GoogleEnrichmentData | null> => {
        const { placeId, language } = params;
        const response = await processGqlSdk.GetGoogleEnrichmentData({
            language,
            placeId,
        });

        return createGoogleEnrichmentData(
            response.actionFeatureGoogleEnrichmentPlaceDetails,
        );
    };
    return {
        getLocationEnrichment,
        getLocationOptions,
    };
};

import { UserApiTokenQl } from 'src/data/api/graphql/br_user/generated/graphql-sdk';

export class DeveloperApiKey {
    id: number;
    name: string;
    plainTextKey?: string;
    last4CharactersKey: string;
    createdOn: string;
    prefix: string;

    constructor(raw: UserApiTokenQl) {
        this.id = raw.id;
        this.name = raw.name;
        this.plainTextKey = raw.plainTextToken ?? undefined;
        this.last4CharactersKey = raw.last4CharactersToken;
        this.createdOn = raw.created;
        this.prefix = raw.prefix;
    }

    /**
     * Getter which formats the key in a hidden format style
     * eg.: sk-..........zzGf
     */
    get formattedHiddenApiKey(): string {
        return `${this.prefix}..........${this.last4CharactersKey}`;
    }
}

import { observer } from 'mobx-react';
import { FC } from 'react';

import { BadgeColor, BadgeColorMap } from './utils';

interface ShieldBadgeComponentProps {
    color?: BadgeColor;
    size?: string;
}

export const ShieldBadgeComponent: FC<ShieldBadgeComponentProps> = observer(
    ({ color = BadgeColor.Gray, size = '72' }) => {
        const colors = BadgeColorMap[color];

        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={size}
                height={size}
                viewBox="0 0 72 72"
                fill="none"
            >
                <path
                    d="M10.08 14.6936C10.08 11.997 11.9508 9.66136 14.5823 9.07258L34.5745 4.59944C35.3973 4.41536 36.2504 4.41413 37.0736 4.59584L57.4015 9.08261C60.0407 9.66515 61.92 12.0044 61.92 14.7072V47.3057C61.92 49.238 60.9512 51.0415 59.3401 52.1083L39.0255 65.5598C37.0866 66.8437 34.566 66.8357 32.6352 65.5398L12.6298 52.1115C11.0361 51.0417 10.08 49.2484 10.08 47.329V14.6936Z"
                    fill={`url(#paint0_linear_shield_${color})`}
                />
                <path
                    opacity="0.65"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M14.5823 9.07256C11.9508 9.66135 10.08 11.997 10.08 14.6936V47.329C10.08 49.2484 11.0361 51.0417 12.6298 52.1115L32.6352 65.5397C34.566 66.8357 37.0866 66.8437 39.0255 65.5598L59.3401 52.1083C60.9512 51.0415 61.92 49.2379 61.92 47.3057V14.7072C61.92 12.0044 60.0407 9.66514 57.4015 9.0826L37.0736 4.59583C36.2504 4.41412 35.3973 4.41534 34.5745 4.59943L14.5823 9.07256ZM16.8013 14.4106C15.8179 14.6341 15.12 15.5084 15.12 16.5169V46.6401C15.12 47.3544 15.4731 48.0225 16.0633 48.4249L34.6495 61.0961C35.3793 61.5937 36.3386 61.5967 37.0716 61.1037L55.9254 48.4238C56.5221 48.0224 56.88 47.3505 56.88 46.6314V16.5221C56.88 15.5112 56.1789 14.6355 55.1925 14.4144L36.3337 10.1866C36.0205 10.1164 35.6956 10.1169 35.3825 10.188L16.8013 14.4106Z"
                    fill={`url(#paint1_linear_shield_${color})`}
                />
                <path
                    opacity="0.65"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M14.5823 9.07269C11.9508 9.66147 10.08 11.9971 10.08 14.6937V47.3291C10.08 49.2485 11.0361 51.0418 12.6298 52.1116L32.6352 65.5399C34.566 66.8359 37.0866 66.8438 39.0255 65.5599L59.3401 52.1084C60.9512 51.0416 61.92 49.2381 61.92 47.3058V14.7073C61.92 12.0046 60.0407 9.66526 57.4015 9.08272L37.0736 4.59595C36.2504 4.41424 35.3973 4.41547 34.5745 4.59955L14.5823 9.07269ZM15.5712 12.8164C14.2554 13.1108 13.32 14.2786 13.32 15.6269V47.0646C13.32 48.0243 13.7981 48.921 14.5949 49.4558L34.2515 62.65C35.2169 63.298 36.4772 63.3019 37.4466 62.66L57.39 49.4542C58.1956 48.9208 58.68 48.0191 58.68 47.053V15.6337C58.68 14.2823 57.7404 13.1127 56.4207 12.8214L36.4707 8.41803C36.0591 8.32717 35.6325 8.32778 35.2211 8.41983L15.5712 12.8164Z"
                    fill={`url(#paint2_linear_shield_${color})`}
                />
                <g style={{ opacity: color === BadgeColor.Gray ? 0 : 1 }}>
                    <path
                        d="M51.9528 47.36L52.6674 48.6382L53.9456 49.3528L52.6674 50.0673L51.9528 51.3456L51.2382 50.0673L49.96 49.3528L51.2382 48.6382L51.9528 47.36Z"
                        fill="white"
                    />
                    <path
                        d="M22.3492 49.16L22.9047 50.1537L23.8984 50.7092L22.9047 51.2647L22.3492 52.2584L21.7937 51.2647L20.8 50.7092L21.7937 50.1537L22.3492 49.16Z"
                        fill="white"
                    />
                    <path
                        d="M42.003 14.6L41.0261 16.3474L39.2787 17.3243L41.0261 18.3011L42.003 20.0485L42.9798 18.3011L44.7272 17.3243L42.9798 16.3474L42.003 14.6Z"
                        fill="white"
                    />
                    <circle cx="25.3001" cy="47.54" r="0.9" fill="white" />
                    <circle
                        cx="1.08"
                        cy="1.08"
                        r="1.08"
                        transform="matrix(-1 0 0 1 39.88 20.36)"
                        fill="white"
                    />
                    <circle cx="56.08" cy="25.7599" r="1.08" fill="white" />
                    <path
                        d="M22.2272 20.72L22.8681 21.8663L24.0144 22.5072L22.8681 23.148L22.2272 24.2944L21.5864 23.148L20.44 22.5072L21.5864 21.8663L22.2272 20.72Z"
                        fill="white"
                    />
                </g>
                <mask
                    id="mask0_806_2533"
                    style={{ maskType: 'alpha' }}
                    maskUnits="userSpaceOnUse"
                    x="10"
                    y="4"
                    width="52"
                    height="63"
                >
                    <path
                        d="M10.08 14.6936C10.08 11.997 11.9508 9.66136 14.5823 9.07258L34.5745 4.59944C35.3972 4.41536 36.2504 4.41413 37.0736 4.59584L57.4015 9.08261C60.0407 9.66515 61.92 12.0044 61.92 14.7072V47.3057C61.92 49.238 60.9511 51.0415 59.3401 52.1083L39.0255 65.5598C37.0866 66.8437 34.566 66.8357 32.6352 65.5398L12.6298 52.1115C11.0361 51.0417 10.08 49.2484 10.08 47.329V14.6936Z"
                        fill={`url(#paint3_linear_shield_${color})`}
                    />
                </mask>
                <g mask="url(#mask0_806_2533)">
                    <rect
                        x="63.2441"
                        y="-5.76001"
                        width="23.0404"
                        height="78.9497"
                        transform="rotate(60 63.2441 -5.76001)"
                        fill="white"
                        fillOpacity="0.1"
                    />
                    <rect
                        x="66.3844"
                        y="3.1156"
                        width="6.12"
                        height="73.57"
                        transform="rotate(60 66.3844 3.1156)"
                        fill="white"
                        fillOpacity="0.1"
                    />
                    <rect
                        x="68.9685"
                        y="29.0297"
                        width="6.12"
                        height="64.2574"
                        transform="rotate(60 68.9685 29.0297)"
                        fill="white"
                        fillOpacity="0.1"
                    />
                </g>
                <path
                    d="M38.88 6.47998L40.7143 6.90927M55.8 10.44L43.7464 7.61892"
                    stroke="white"
                    strokeWidth="1.44"
                    strokeLinecap="round"
                />
                <defs>
                    <linearGradient
                        id={`paint0_linear_shield_${color}`}
                        x1="38.0197"
                        y1="23.1519"
                        x2="20.9021"
                        y2="61.4077"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor={colors.stopColor1} />
                        <stop offset="1" stopColor={colors.stopOffsetColor1} />
                    </linearGradient>
                    <linearGradient
                        id={`paint1_linear_shield_${color}`}
                        x1="50.4508"
                        y1="10.3201"
                        x2="21.308"
                        y2="60.5175"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor={colors.stopColor2} />
                        <stop offset="1" stopColor={colors.stopOffsetColor2} />
                    </linearGradient>
                    <linearGradient
                        id={`paint2_linear_shield_${color}`}
                        x1="36"
                        y1="4.6266"
                        x2="38.485"
                        y2="62.6976"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor={colors.stopColor3} />
                        <stop offset="1" stopColor={colors.stopOffsetColor3} />
                    </linearGradient>
                    <linearGradient
                        id={`paint3_linear_shield_${color}`}
                        x1="35.8237"
                        y1="18.3999"
                        x2="35.8237"
                        y2="68.7359"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor={colors.stopColor4} />
                        <stop offset="1" stopColor={colors.stopOffsetColor4} />
                    </linearGradient>
                </defs>
            </svg>
        );
    },
);

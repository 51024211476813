import {
    BrButton,
    BrButtonVariant,
    BrDialog,
} from '@buildingradar/br_component_lib';
import { useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import { observer } from 'mobx-react';
import { FC, RefObject } from 'react';
import { useTranslation } from 'react-i18next';

import { MediaQuery } from 'src/resources/styles/media.mixins';
import { Spacing } from 'src/resources/styles/spacing';

import { SubjectBodyForm } from './subject-body-form.component';

interface ExpandedTemplateModalProps {
    isOpen: boolean;
    initialSubjectValue?: string;
    initialBodyValue?: string;
    subjectFieldRef: RefObject<HTMLDivElement>;
    bodyFieldRef: RefObject<HTMLDivElement>;
    dataContext?: Record<string, any>;
    onCreateEmail: () => void;
    onCopyToClipboard: () => void;
    onClose: () => void;
}

export const ExpandedTemplateModal: FC<ExpandedTemplateModalProps> = observer(
    ({
        isOpen,
        initialSubjectValue,
        initialBodyValue,
        subjectFieldRef,
        bodyFieldRef,
        dataContext,
        onCreateEmail,
        onCopyToClipboard,
        onClose,
    }) => {
        const { t } = useTranslation();

        return (
            <BrDialog
                isDialogOpened={isOpen}
                title={{ text: '' }}
                onClose={onClose}
                showCloseButton={false}
                width={760}
                className="expand-template-modal"
                fullScreen={useMediaQuery(MediaQuery.tablet)}
            >
                <SubjectBodyForm
                    initialSubjectValue={initialSubjectValue}
                    initialBodyValue={initialBodyValue}
                    subjectFieldRef={subjectFieldRef}
                    bodyFieldRef={bodyFieldRef}
                    applyTopMargin={false}
                    bodyNumberOfRows={12}
                    dataContext={dataContext}
                />
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        marginTop: Spacing.s2,
                        '& .MuiButton-root': {
                            width: '50%',
                            '&:first-of-type': {
                                marginRight: Spacing.s2,
                            },
                        },
                    }}
                >
                    <BrButton
                        variant={BrButtonVariant.outlined}
                        onClick={onCopyToClipboard}
                    >
                        {t(
                            'actions.email_templates.copy_to_clipboard_button_label',
                        )}
                    </BrButton>
                    <BrButton onClick={onCreateEmail}>
                        {t('actions.email_templates.create_email_button_label')}
                    </BrButton>
                </Box>
            </BrDialog>
        );
    },
);

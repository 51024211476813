import { FC } from 'react';

import { AchievementCategory } from 'src/app-features/achievements/domain/model/achievement.model';
import { UserAchievementName } from 'src/data/api/graphql/br_process/generated/graphql-sdk';

import { AddContactsEmailOrPhone } from './icons/add_contacts_email_or_phone/icon';
import { AskArticleWizard } from './icons/ask_article_wizard/icon';
import { AssignContactDealRole } from './icons/assign_contact_deal_role/icon';
import { BookedFirstMeeting } from './icons/booked_first_meeting/icon';
import { CompletedANextStep } from './icons/completed a next step/icon';
import { CreateYourFirstContact } from './icons/create-your-first-contact/icon';
import { CreateYourFirstDeal } from './icons/create-your-first-deal/icon';
import { DisqualifyADeal } from './icons/disqualify_a_deal/icon';
import { OutreachByEmail } from './icons/outreach_by_email/icon';
import { OutreachByPhone } from './icons/outreach_by_phone/icon';
import { PauseADeal } from './icons/pause_a_deal/icon';
import { PlanANextStep } from './icons/plan_a_next_step/icon';

export enum BadgeColor {
    Purple,
    Green,
    Magenta,
    Pink,
    Blue,
    Lime,
    Red,
    Gold,
    Gray,
}

export enum BadgeShape {
    Circle,
    Shield,
    Hexagon,
}

interface BadgeColorDetails {
    stopColor1: string;
    stopOffsetColor1: string;
    stopColor2: string;
    stopOffsetColor2: string;
    stopColor3: string;
    stopOffsetColor3: string;
    stopColor4: string;
    stopOffsetColor4: string;
}

export const BadgeColorMap: Record<BadgeColor, BadgeColorDetails> = {
    [BadgeColor.Purple]: {
        stopColor1: '#B566FF',
        stopOffsetColor1: '#2276FF',
        stopColor2: '#8000E0',
        stopOffsetColor2: '#004FDC',
        stopColor3: '#D393FF',
        stopOffsetColor3: '#5590FF',
        stopColor4: '#0057E6',
        stopOffsetColor4: '#2BF1DF',
    },
    [BadgeColor.Green]: {
        stopColor1: '#A2D904',
        stopOffsetColor1: '#3C8F01',
        stopColor2: '#009900',
        stopOffsetColor2: '#256701',
        stopColor3: '#84D70E',
        stopOffsetColor3: '#5FDC06',
        stopColor4: '#0057E6',
        stopOffsetColor4: '#2BF1DF',
    },
    [BadgeColor.Magenta]: {
        stopColor1: '#FF48C1',
        stopOffsetColor1: '#CD0189',
        stopColor2: '#9C0369',
        stopOffsetColor2: '#9D0269',
        stopColor3: '#FF93DB',
        stopOffsetColor3: '#FF46C1',
        stopColor4: '#0057E6',
        stopOffsetColor4: '#2BF1DF',
    },
    [BadgeColor.Pink]: {
        stopColor1: '#E066FF',
        stopOffsetColor1: '#D322FF',
        stopColor2: '#72019A',
        stopOffsetColor2: '#9500CA',
        stopColor3: '#E066FF',
        stopOffsetColor3: '#D322FF',
        stopColor4: '#0057E6',
        stopOffsetColor4: '#2BF1DF',
    },
    [BadgeColor.Blue]: {
        stopColor1: '#00C8F4',
        stopOffsetColor1: '#0077FF',
        stopColor2: '#025FCA',
        stopOffsetColor2: '#00628C',
        stopColor3: '#18B9FF',
        stopOffsetColor3: '#54C1FE',
        stopColor4: '#0057E6',
        stopOffsetColor4: '#2BF1DF',
    },
    [BadgeColor.Lime]: {
        stopColor1: '#26F754',
        stopOffsetColor1: '#058E6C',
        stopColor2: '#007558',
        stopOffsetColor2: '#006851',
        stopColor3: '#0EE87C',
        stopOffsetColor3: '#0ADBB1',
        stopColor4: '#0057E6',
        stopOffsetColor4: '#2BF1DF',
    },
    [BadgeColor.Red]: {
        stopColor1: '#FE7272',
        stopOffsetColor1: '#FE2929',
        stopColor2: '#BF0000',
        stopOffsetColor2: '#9D0000',
        stopColor3: '#FF9393',
        stopOffsetColor3: '#FD7575',
        stopColor4: '#0057E6',
        stopOffsetColor4: '#2BF1DF',
    },
    [BadgeColor.Gold]: {
        stopColor1: '#F2E231',
        stopOffsetColor1: '#EEB814',
        stopColor2: '#EE9314',
        stopOffsetColor2: '#DB9E25',
        stopColor3: '#F2E231',
        stopOffsetColor3: '#F0BC21',
        stopColor4: '#0057E6',
        stopOffsetColor4: '#2BF1DF',
    },
    [BadgeColor.Gray]: {
        stopColor1: '#E3E3E3',
        stopOffsetColor1: '#C5C5C5',
        stopColor2: '#9A9A9A',
        stopOffsetColor2: '#ABABAB',
        stopColor3: '#E1E1E1',
        stopOffsetColor3: '#E2E2E2',
        stopColor4: '#0057E6',
        stopOffsetColor4: '#2BF1DF',
    },
};

export const IconMap: Record<
    UserAchievementName,
    FC<{ color: BadgeColor; size?: string }>
> = {
    [UserAchievementName.AddContactsEmailOrPhone]: AddContactsEmailOrPhone,
    [UserAchievementName.AskArticleWizard]: AskArticleWizard,
    [UserAchievementName.AssignContactDealRole]: AssignContactDealRole,
    [UserAchievementName.BookedFirstMeeting]: BookedFirstMeeting,
    [UserAchievementName.CreateYourFirstContact]: CreateYourFirstContact,
    [UserAchievementName.CreateYourFirstDeal]: CreateYourFirstDeal,
    [UserAchievementName.DisqualifyADeal]: DisqualifyADeal,
    [UserAchievementName.OutreachByEmail]: OutreachByEmail,
    [UserAchievementName.OutreachByPhone]: OutreachByPhone,
    [UserAchievementName.PauseADeal]: PauseADeal,
    [UserAchievementName.PlanANextStep]: PlanANextStep,
    [UserAchievementName.ResolveANextStep]: CompletedANextStep,
};

export const AchievedColorMap: Record<UserAchievementName, BadgeColor> = {
    [UserAchievementName.AddContactsEmailOrPhone]: BadgeColor.Magenta,
    [UserAchievementName.AskArticleWizard]: BadgeColor.Magenta,
    [UserAchievementName.AssignContactDealRole]: BadgeColor.Green,
    [UserAchievementName.BookedFirstMeeting]: BadgeColor.Lime,
    [UserAchievementName.CreateYourFirstContact]: BadgeColor.Pink,
    [UserAchievementName.CreateYourFirstDeal]: BadgeColor.Purple,
    [UserAchievementName.DisqualifyADeal]: BadgeColor.Red,
    [UserAchievementName.OutreachByEmail]: BadgeColor.Green,
    [UserAchievementName.OutreachByPhone]: BadgeColor.Red,
    [UserAchievementName.PauseADeal]: BadgeColor.Lime,
    [UserAchievementName.PlanANextStep]: BadgeColor.Purple,
    [UserAchievementName.ResolveANextStep]: BadgeColor.Pink,
};

export const ShapeMap: Record<AchievementCategory, BadgeShape> = {
    [AchievementCategory.WarriorsPath]: BadgeShape.Circle,
    [AchievementCategory.CenturionsArmy]: BadgeShape.Shield,
};

import styled from '@emotion/styled';

import { WrapperBorderRadius } from 'src/presentation/modules/lead-details/lead-details.styled';

export const GeneralSearchWrapperStyled = styled.div`
    background-color: white;
    ${WrapperBorderRadius}
    min-width: 0;
    height: 100vh;
    width: 100%;
    position: relative;
    overflow-x: hidden;
    box-shadow: 0 4px 24px 2px rgb(0 0 0 / 10%);
    display: flex;
    flex-direction: column;
    align-items: center;
`;

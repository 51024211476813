import {
    BrButtonDropdown,
    BrDrawer,
    BrMenuList,
} from '@buildingradar/br_component_lib';
import styled from '@emotion/styled';
import { Box } from '@mui/material';

import { Spacing } from 'src/resources/styles/spacing';

export const SearchMenuListStyled = styled(BrMenuList)({
    '& .MuiButtonBase-root.MuiMenuItem-root': {
        '&:hover': {
            backgroundColor: 'var(--blue-20)',
        },
        '&.Mui-selected': {
            backgroundColor: 'var(--blue-20)',
        },
    },
});

export const DrawerSearchListStyled = styled(BrDrawer)({
    '& .MuiDrawer-paper': {
        left: Spacing.s8,
        overscrollBehavior: 'contain',
    },
});

export const SearchListDrawerToggleStyled = styled(Box)({
    position: 'fixed',
    top: '20px',
    left: '64px',
    display: 'flex',
    width: '50px',
    height: '50px',
    justifyContent: 'center',
    alignItems: 'center',
    borderTopRightRadius: '5px',
    borderBottomRightRadius: '5px',
    backgroundColor: 'var(--gray-10)',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease',
    '&:hover': {
        backgroundColor: 'var(--gray-20)',
    },
});

export const SearchListTileStyled = styled(Box, {
    shouldForwardProp: (prop) => prop !== 'isSearchOwner',
})<{ isSearchOwner: boolean }>(({ isSearchOwner }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    '& .delete-search': {
        color: !isSearchOwner ? 'var(--gray-40)!important' : undefined,
        '&:hover': {
            color: isSearchOwner ? 'var(--blue-70)' : 'var(--gray-40)',
        },
    },
}));

export const CreateNewSearchButtonStyled = styled(BrButtonDropdown)({
    marginLeft: '20px',
});

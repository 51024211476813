import {
    DealActivity,
    DealActivityType,
    OutreachActivityType,
} from 'src/app-features/deal-activity/domain/deal-activity.model';
import { AbortParams } from 'src/data/api/api-client';
import {
    ActivityLogType,
    ActivityLogOutreachType,
} from 'src/data/api/graphql/br_process/generated/graphql-sdk';
import { ProcessGqlSdk } from 'src/data/api/graphql/graphql-client.wrapper';
import { createActivityLog } from 'src/domain/models/deal/deal.model';

interface CreateActivityLogParams extends AbortParams {
    dealId: string;
    type: DealActivityType;
    notes?: string;
    contactId?: string;
    relevantCompanyId?: string;
    subType: OutreachActivityType;
    customType?: string;
}

interface DeleteActivityLogParams extends AbortParams {
    activityLogId: string;
}

interface UpdateActivityLogParams extends AbortParams {
    activityLogId: string;
    notes?: string;
    contactId?: string;
    customType?: string;
}
export interface IDealActivityApi {
    createDealActivity: (
        params: CreateActivityLogParams,
    ) => Promise<DealActivity>;
    deleteDealActivity: (params: DeleteActivityLogParams) => Promise<boolean>;
    updateDealActivity: (
        params: UpdateActivityLogParams,
    ) => Promise<DealActivity>;
}

export const createDealActivityApi = (
    processGqlSdk: ProcessGqlSdk,
): IDealActivityApi => {
    const createDealActivity = async (
        params: CreateActivityLogParams,
    ): Promise<DealActivity> => {
        const response = await processGqlSdk.CreateDealActivity(
            {
                activityType: params.type.toString() as ActivityLogType,
                subType: params.subType.toString() as ActivityLogOutreachType,
                customType: params.customType,
                dealId: params.dealId,
                relevantCompanyId: params.relevantCompanyId,
                contactId: params.contactId,
                notes: params.notes,
            },
            { signal: params.signal },
        );
        return createActivityLog(response.createActivityLog);
    };

    const deleteDealActivity = async (
        params: DeleteActivityLogParams,
    ): Promise<boolean> => {
        const response = await processGqlSdk.DeleteDealActivity(
            {
                activityLogId: params.activityLogId,
            },
            {
                signal: params.signal,
            },
        );
        return response.deleteActivityLog.ok;
    };

    const updateDealActivity = async (
        params: UpdateActivityLogParams,
    ): Promise<DealActivity> => {
        const response = await processGqlSdk.UpdateDealActivity(
            {
                activityLogId: params.activityLogId,
                customType: params.customType,
                contactId: params.contactId,
                notes: params.notes,
            },
            {
                signal: params.signal,
            },
        );
        return createActivityLog(response.updateActivityLog);
    };

    return {
        createDealActivity,
        deleteDealActivity,
        updateDealActivity,
    };
};

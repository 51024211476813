import {
    AvatarSizes,
    BrAvatar,
    BrIcon,
    IconName,
} from '@buildingradar/br_component_lib';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import { User } from 'src/domain/models/user/user.model';
import { MobileMenuHeader } from 'src/presentation/modules/mobile-menu/components/mobile-menu-header/mobile-menu-header.component';
import { MobileMenuItemComponent } from 'src/presentation/modules/mobile-menu/components/mobile-menu-item/mobile-menu-item.component';
import { AppRoute } from 'src/presentation/modules/router/app-route.list';

import {
    mobileMenuTopItems,
    mobileMenuRoute,
    mobileMenuText,
    buildMobileMenuIcons,
} from './mobile-menu-content.config';
import {
    BottomItemsStyled,
    NarrowBorder,
    TopItemsStyled,
} from './mobile-menu-content.styled';

interface MobileMenuContentComponentProps {
    closeMenu: () => void;
    currentRoute: string;
    openMore: () => void;
    areNotificationsOpened: boolean;
    openNotifications: () => void;
    user: User;
}

export const MobileMenuContentComponent: FC<MobileMenuContentComponentProps> =
    observer(
        ({
            closeMenu,
            currentRoute,
            openMore,
            areNotificationsOpened,
            openNotifications,
            user,
        }) => {
            const isSearchActive = currentRoute.startsWith(
                AppRoute.generalSearch,
            );
            const isSettingsActive = currentRoute.startsWith(AppRoute.settings);

            const activeMenuIcons = buildMobileMenuIcons(true);
            const disabledMenuIcons = buildMobileMenuIcons();

            return (
                <>
                    <TopItemsStyled>
                        <MobileMenuHeader onClose={closeMenu} />
                        {mobileMenuTopItems.map((type) => {
                            const route = mobileMenuRoute[type];
                            const isActive = currentRoute.startsWith(route);
                            const Icon = isActive
                                ? activeMenuIcons[type]
                                : disabledMenuIcons[type];
                            const text = mobileMenuText[type];
                            return (
                                <MobileMenuItemComponent
                                    key={type}
                                    isActive={isActive}
                                    Icon={Icon}
                                    text={text}
                                    link={route}
                                    onClick={closeMenu}
                                />
                            );
                        })}

                        <MobileMenuItemComponent
                            isActive={false}
                            text={'navbar.mobile.more'}
                            Icon={
                                <BrIcon
                                    iconName={IconName.BrMoreHorizIcon}
                                    iconColor="var(--gray-80)"
                                />
                            }
                            onClick={openMore}
                        />
                    </TopItemsStyled>
                    <BottomItemsStyled>
                        <NarrowBorder />
                        <MobileMenuItemComponent
                            isActive={isSearchActive}
                            className="project-lookup"
                            text={'project_lookup.title'}
                            Icon={
                                <BrIcon
                                    iconName={IconName.BrSearchProjectsIcon}
                                    iconColor="var(--gray-80)"
                                />
                            }
                            link={AppRoute.generalSearch}
                            onClick={closeMenu}
                        />
                        <MobileMenuItemComponent
                            isActive={false}
                            className="notifications"
                            text={'navbar.notifications'}
                            Icon={
                                <BrIcon
                                    iconName={
                                        IconName.BrNotificationsOutlineIcon
                                    }
                                    iconColor={
                                        areNotificationsOpened
                                            ? 'var(--blue-60)'
                                            : 'var(--gray-80)'
                                    }
                                />
                            }
                            onClick={openNotifications}
                        />
                        <MobileMenuItemComponent
                            isActive={isSettingsActive}
                            className="settings"
                            text={'navbar.settings'}
                            Icon={
                                <BrAvatar
                                    avatarData={user}
                                    size={AvatarSizes.sm}
                                />
                            }
                            link={AppRoute.settings}
                            onClick={closeMenu}
                        />
                    </BottomItemsStyled>
                </>
            );
        },
    );

import {
    BrButton,
    BrButtonVariant,
    BrIcon,
    BrText,
    IconName,
} from '@buildingradar/br_component_lib';
import { observer } from 'mobx-react';
import { FC, useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import {
    ThumbsUp,
    ThumbsDown,
    XRayAnswerList,
    FeedBackWrapper,
    XRayAnswerWrapper,
    FeedBackWrapperText,
    FeedBackButtonWrapper,
    FeedBackButtonsWrapper,
    NoResultsText,
} from 'src/presentation/modules/enablement-actions-panel/actions/article-wizard/components/xray-answer/xray-answer.styled';
import { SecondaryHeader } from 'src/presentation/modules/enablement-actions-panel/actions/article-wizard/styled';
import { LoadingSpinner } from 'src/presentation/shared/loading-spinner/loading-spinner';

import {
    ArticleWizardErrorIconStyled,
    ArticleWizardInfoStyled,
    XRayAnswerListWrapper,
} from './styled';

interface ArticleWizardAnswerComponentProps {
    isLoading: boolean;
    xRayActionAnswer: string[] | string | null;
    isError: boolean;
    trackFeedbackHandler: (wasHelpful: boolean) => void;
    askQuestion: () => void;
}

export const ArticleWizardAnswerComponent: FC<ArticleWizardAnswerComponentProps> =
    observer(
        ({
            isLoading,
            xRayActionAnswer,
            isError,
            trackFeedbackHandler,
            askQuestion,
        }) => {
            const [feedbackClicked, setFeedbackClicked] =
                useState<boolean>(false);
            const { t } = useTranslation();
            const feedBackTitleText = feedbackClicked
                ? t('actions.xray_helper.xray_helper_answer_thanks')
                : t('actions.xray_helper.xray_helper_answer_feedback');

            const thumbsDownHandler = useCallback(() => {
                setFeedbackClicked(true);
                trackFeedbackHandler(false);
            }, [setFeedbackClicked, trackFeedbackHandler]);

            const thumbsUpHandler = useCallback(() => {
                setFeedbackClicked(true);
                trackFeedbackHandler(true);
            }, [setFeedbackClicked, trackFeedbackHandler]);

            useEffect(() => {
                setFeedbackClicked(false);
            }, [xRayActionAnswer]);

            const isSingleLine = xRayActionAnswer?.length === 1;

            if (typeof xRayActionAnswer === 'string') {
                return (
                    <NoResultsText>
                        {t('actions.xray_helper.no_results')}
                    </NoResultsText>
                );
            }

            if (isLoading && !xRayActionAnswer) {
                return (
                    <ArticleWizardInfoStyled>
                        <LoadingSpinner theme="primary" size={45} />
                        <BrText
                            align={'center'}
                            color="var(--gray-40)"
                            sx={{ marginTop: '22px' }}
                        >
                            {t('actions.xray_helper.loading_info')}
                        </BrText>
                    </ArticleWizardInfoStyled>
                );
            }

            if (!isLoading && isError) {
                return (
                    <ArticleWizardInfoStyled>
                        <ArticleWizardErrorIconStyled>
                            <BrIcon
                                iconName={IconName.BrSyncProblemIcon}
                                iconSize={48}
                                iconColor="var(--yellow-90)"
                            />
                        </ArticleWizardErrorIconStyled>
                        <BrText align={'center'} sx={{ marginBottom: '16px' }}>
                            {t('actions.xray_helper.error')}
                        </BrText>
                        <BrButton
                            variant={BrButtonVariant.text}
                            onClick={askQuestion}
                        >
                            {t('actions.xray_helper.try_again')}
                        </BrButton>
                    </ArticleWizardInfoStyled>
                );
            }

            return (
                <>
                    {xRayActionAnswer && (
                        <SecondaryHeader>
                            {t('actions.xray_helper.xray_helper_answer')}
                        </SecondaryHeader>
                    )}
                    <XRayAnswerWrapper>
                        {xRayActionAnswer && (
                            <XRayAnswerListWrapper>
                                {!isSingleLine ? (
                                    <XRayAnswerList>
                                        {xRayActionAnswer.map(
                                            (answer) =>
                                                answer && (
                                                    <li key={answer}>
                                                        {answer}
                                                    </li>
                                                ),
                                        )}
                                    </XRayAnswerList>
                                ) : (
                                    <p>{xRayActionAnswer[0]}</p>
                                )}
                            </XRayAnswerListWrapper>
                        )}
                        {xRayActionAnswer && !isLoading && (
                            <FeedBackWrapper>
                                <FeedBackWrapperText>
                                    {feedBackTitleText}
                                </FeedBackWrapperText>
                                {!feedbackClicked && (
                                    <FeedBackButtonsWrapper>
                                        <FeedBackButtonWrapper>
                                            <ThumbsDown
                                                onClick={thumbsDownHandler}
                                            />
                                        </FeedBackButtonWrapper>
                                        <FeedBackButtonWrapper>
                                            <ThumbsUp
                                                onClick={thumbsUpHandler}
                                            />
                                        </FeedBackButtonWrapper>
                                    </FeedBackButtonsWrapper>
                                )}
                            </FeedBackWrapper>
                        )}
                    </XRayAnswerWrapper>
                </>
            );
        },
    );

import {
    BrSelect,
    MenuItemConfigurationV2,
} from '@buildingradar/br_component_lib';
import { observer } from 'mobx-react';
import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { DealContactPersonForm } from 'src/app-features/contact/data/model/deal-contacts-form.model';
import {
    DealContactCompany,
    DealContactPerson,
    DealContactType,
} from 'src/app-features/contact/data/model/deal-contacts.model';
import { ContactUpdateMetricsInfo } from 'src/app-features/contact/domain/deal-contacts-manage.feature.interface';
import { ActionPanelLabelStyled } from 'src/presentation/modules/enablement-actions-panel/actions/styled';
import { Localized } from 'src/presentation/shared/localized/localized';

import { GoogleEnrichmentCompanySelectStyled } from './styles';

interface GoogleEnrichmentCompanySelectProps {
    dealId: string;
    selectedCompany?: DealContactCompany;
    selectedContact?: DealContactPerson;
    companyOptions: MenuItemConfigurationV2[];
    setSelectedCompany: (id?: string) => void;
    updateContactPerson: (
        dealId: string,
        id: string,
        update: DealContactPersonForm,
        metricsInfo?: ContactUpdateMetricsInfo,
    ) => void;
}
export const GoogleEnrichmentCompanySelect: FC<GoogleEnrichmentCompanySelectProps> =
    observer(
        ({
            dealId,
            selectedContact,
            selectedCompany,
            companyOptions,
            setSelectedCompany,
            updateContactPerson,
        }) => {
            const { t } = useTranslation();
            const onCompanySelect = useCallback(
                (id?: string) => {
                    if (selectedContact && selectedContact.id && id) {
                        updateContactPerson(
                            dealId,
                            selectedContact.id,
                            {
                                formType: DealContactType.person,
                                relevantCompanyId: id,
                            },
                            { field: 'relevantCompanyId' },
                        );
                    } else if (id) {
                        setSelectedCompany(id);
                    }
                },
                [
                    dealId,
                    selectedContact,
                    setSelectedCompany,
                    updateContactPerson,
                ],
            );
            return (
                <GoogleEnrichmentCompanySelectStyled>
                    <ActionPanelLabelStyled>
                        <Localized>
                            actions.google_enrichment.company_select_label
                        </Localized>
                    </ActionPanelLabelStyled>
                    <BrSelect
                        required
                        status={selectedCompany ? 'idle' : 'error'}
                        helperText={
                            selectedCompany
                                ? undefined
                                : t('common.required_field_text')
                        }
                        options={companyOptions}
                        selectedValue={selectedCompany?.id}
                        fullWidth
                        margin="none"
                        onSelectionChange={onCompanySelect}
                    />
                </GoogleEnrichmentCompanySelectStyled>
            );
        },
    );

import { observer } from 'mobx-react';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Reminder } from 'src/domain/models/reminder/reminder.model';
import { User } from 'src/domain/models/user/user.model';
import { ScrollableListWrapper } from 'src/presentation/modules/notifications-flyout/components/notifications-panel/notifications-panel.styled';
import { EmptyNotificationsList } from 'src/presentation/modules/notifications-flyout/components/shared/empty-notifications-list/empty-notifications-list.component';

import { ReminderListSection } from './reminder-list-section/reminder-list-section.component';

interface RemindersDashboardProps {
    overdueReminders: Reminder[];
    upcomingReminders: Reminder[];
    fetchingReminders: boolean;
    user: User | undefined;
    requestReminders: () => void;
}

export const RemindersDashboard: FC<RemindersDashboardProps> = observer(
    ({
        overdueReminders,
        upcomingReminders,
        fetchingReminders,
        user,
        requestReminders,
    }) => {
        useEffect(() => {
            if (user) {
                requestReminders();
            }
        }, [requestReminders, user]);

        const { t } = useTranslation();

        const shouldDisplayEmptyRemindersComponent =
            !overdueReminders.length &&
            !upcomingReminders.length &&
            !fetchingReminders;

        return (
            <ScrollableListWrapper offset={140}>
                {shouldDisplayEmptyRemindersComponent ? (
                    <EmptyNotificationsList
                        title={t(
                            'notification_flyout.reminders_tab.empty_reminders.title',
                        )}
                    />
                ) : (
                    <>
                        <ReminderListSection
                            title={t(
                                'notification_flyout.reminders_tab.overdue_section_title',
                            )}
                            fetchingReminders={fetchingReminders}
                            reminders={overdueReminders}
                        />
                        <ReminderListSection
                            title={t(
                                'notification_flyout.reminders_tab.upcoming_section_title',
                            )}
                            fetchingReminders={fetchingReminders}
                            reminders={upcomingReminders}
                        />
                    </>
                )}
            </ScrollableListWrapper>
        );
    },
);

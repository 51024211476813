import { withFeatures } from 'src/utils/component.utils';

import { GoogleEnrichmentContentComponent } from './google-enrichment-content.component';

export const GoogleEnrichmentContentContainer = withFeatures(
    GoogleEnrichmentContentComponent,
)((f) => ({
    dealId: f.quickActionsFeature.selectedDealId,
    selectedCompany: f.googleEnrichmentFeature.selectedCompany,
    isGoogleEnrichmentLoading:
        f.googleEnrichmentFeature.isGoogleEnrichmentLoading,
    isPossibleCompanyMatchesLoading:
        f.googleEnrichmentFeature.isPossibleCompanyMatchesLoading,
    enrichmentData: f.googleEnrichmentFeature.enrichmentData,
    enrichmentOptions: f.googleEnrichmentFeature.enrichmentOptions,
    selectedEnrichmentOption:
        f.googleEnrichmentFeature.selectedEnrichmentOption,
    selectedData: f.googleEnrichmentFeature.selectedData,
    customFields: f.googleEnrichmentFeature.companyCustomFields,
    selectDataToSave: f.googleEnrichmentFeature.selectDataToSave,
    getEnrichmentData: f.googleEnrichmentFeature.getEnrichmentData,
    getCompanyLocations: f.googleEnrichmentFeature.getCompanyLocations,
    setSelectedEnrichmentOption:
        f.googleEnrichmentFeature.setSelectedEnrichmentOption,
}));

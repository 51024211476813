import {
    BrButtonColor,
    BrButtonVariant,
    BrSelect,
    BrText,
    MenuItemConfiguration,
} from '@buildingradar/br_component_lib';
import { Box } from '@mui/system';
import { observer } from 'mobx-react';
import { FC, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { RECENT_ARTICLES_KEY } from 'src/domain/features/actions/quick-actions/xray/utils';
import { ToasterMessage } from 'src/domain/features/toaster/toaster.utils';
import { Lead } from 'src/domain/models/lead/lead.model';
import { ActionContentComponent } from 'src/presentation/modules/enablement-actions-panel/actions/action-content.component';
import { XRayQuestionComponent } from 'src/presentation/modules/enablement-actions-panel/actions/article-wizard/components/xray-question/xray-question.component';
import { stopPropagation } from 'src/utils/event.utils';
import { getTinySourceUrl } from 'src/utils/url.utils';

import { ArticleWizardAnswerComponent } from './answer/article-wizard-answer.component';
import { BrButtonStyled, DocumentLinkStyled, SecondaryHeader } from './styled';

interface ArticleWizardPanelComponentProps {
    xRayActionAnswer: string[] | string | null;
    isXRayAnswerLoading: boolean;
    selectedLead: Lead | undefined;
    customQuestion: string;
    selectedDocumentId: string | undefined;
    isError: boolean;
    predefinedQuestions: string[];
    setXRayActionAnswer: (answer: string[] | null) => void;
    trackXRayFeedback: (
        wasHelpful: boolean,
        question: string,
        answer: string,
        dealId?: string,
        pipelineId?: string,
        currentStageId?: string,
        leadId?: string,
        documentId?: string,
    ) => void;
    showMessage: (message: ToasterMessage) => void;
    askQuestion: (
        projectId: string,
        question: string,
        documentId?: string,
    ) => void;
    closeDrawer: () => void;
    setCustomQuestion: (q: string) => void;
    setSelectedDocumentId: (documentId: string | undefined) => void;
    abortOngoingRequests: () => void;
}

export const ArticleWizardPanelComponent: FC<ArticleWizardPanelComponentProps> =
    observer(
        ({
            xRayActionAnswer,
            isXRayAnswerLoading,
            selectedLead,
            customQuestion,
            selectedDocumentId,
            isError,
            predefinedQuestions,
            closeDrawer,
            trackXRayFeedback,
            setXRayActionAnswer,
            askQuestion,
            showMessage,
            setCustomQuestion,
            setSelectedDocumentId,
            abortOngoingRequests,
        }) => {
            const { t } = useTranslation();

            const documents = useMemo(() => {
                const docs: MenuItemConfiguration[] =
                    selectedLead?.documents.map(
                        ({ itemId, title, sourceUrls }) => {
                            const tinySourceURL = getTinySourceUrl(
                                sourceUrls[0] ?? '',
                                true,
                            );

                            return {
                                value: itemId,
                                label: title,
                                customContent: {
                                    element: (
                                        <Box>
                                            <BrText>{title}</BrText>
                                            <DocumentLinkStyled
                                                target="__blank"
                                                href={tinySourceURL.full}
                                                onClick={stopPropagation}
                                            >
                                                {tinySourceURL.tiny}
                                            </DocumentLinkStyled>
                                        </Box>
                                    ),
                                },
                            };
                        },
                    ) ?? [];
                docs.unshift({
                    value: RECENT_ARTICLES_KEY,
                    label: t('actions.xray_helper.recent_articles'),
                });
                return docs;
            }, [selectedLead?.documents, t]);

            const questionOptions = useMemo(() => {
                const questions = !predefinedQuestions.length
                    ? [t('actions.xray_helper.no_questions')]
                    : predefinedQuestions;
                return questions.map((question) => ({
                    value: question,
                    label: question,
                }));
            }, [predefinedQuestions, t]);

            const [selectedQuestion, setSelectedQuestion] = useState<string>(
                questionOptions[0].value,
            );

            const askQuestionHandler = useCallback(() => {
                askQuestion(
                    selectedLead?.id ?? '',
                    customQuestion || selectedQuestion,
                    selectedDocumentId === RECENT_ARTICLES_KEY
                        ? undefined
                        : selectedDocumentId,
                );
            }, [
                askQuestion,
                selectedLead?.id,
                customQuestion,
                selectedQuestion,
                selectedDocumentId,
            ]);

            const askWizardHandler = useCallback(() => {
                setXRayActionAnswer(null);
                askQuestionHandler();
            }, [askQuestionHandler, setXRayActionAnswer]);

            const trackFeedbackHandler = useCallback(
                (wasHelpful: boolean) => {
                    const answer =
                        typeof xRayActionAnswer === 'string'
                            ? xRayActionAnswer
                            : xRayActionAnswer?.join('') ?? '';
                    trackXRayFeedback(
                        wasHelpful,
                        customQuestion || selectedQuestion,
                        answer,
                        undefined,
                        undefined,
                        undefined,
                        selectedLead?.id,
                        selectedDocumentId,
                    );
                    showMessage({
                        title: t(
                            'actions.xray_helper.xray_helper_answer_thanks',
                        ),
                    });
                },
                [
                    customQuestion,
                    selectedDocumentId,
                    selectedLead?.id,
                    selectedQuestion,
                    showMessage,
                    t,
                    trackXRayFeedback,
                    xRayActionAnswer,
                ],
            );

            const copyHandler = useCallback(() => {
                if (xRayActionAnswer) {
                    const answer =
                        typeof xRayActionAnswer === 'string'
                            ? xRayActionAnswer
                            : xRayActionAnswer?.join(', ') ?? '';
                    navigator.clipboard.writeText(answer);
                    showMessage({
                        title: t(
                            'actions.xray_helper.xray_helper_answer_copied',
                        ),
                    });
                }
            }, [xRayActionAnswer, showMessage, t]);

            const handleCloseDrawer = () => {
                setXRayActionAnswer(null);
                abortOngoingRequests();
                closeDrawer();
            };

            return (
                <ActionContentComponent
                    title={t('actions.xray_helper.xray_helper_title')}
                    body={
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '16px',
                                height: '100%',
                            }}
                        >
                            <SecondaryHeader>
                                {t('actions.xray_helper.source_article')}
                            </SecondaryHeader>
                            <BrSelect
                                options={documents}
                                selectedValue={selectedDocumentId}
                                onSelectionChange={setSelectedDocumentId}
                                margin="none"
                            />
                            <SecondaryHeader style={{ marginTop: '8px' }}>
                                {t('actions.xray_helper.xray_helper_question')}
                            </SecondaryHeader>
                            <XRayQuestionComponent
                                options={questionOptions}
                                selectedOption={selectedQuestion}
                                customQuestion={customQuestion}
                                askWizardHandler={askWizardHandler}
                                setSelectedOption={setSelectedQuestion}
                                setCustomQuestion={setCustomQuestion}
                                setXRayActionAnswer={setXRayActionAnswer}
                            />
                            <BrButtonStyled
                                fullWidth
                                disabled={isXRayAnswerLoading}
                                onClick={askWizardHandler}
                            >
                                {t('actions.xray_helper.ask_button')}
                            </BrButtonStyled>
                            <ArticleWizardAnswerComponent
                                isLoading={isXRayAnswerLoading}
                                xRayActionAnswer={xRayActionAnswer}
                                trackFeedbackHandler={trackFeedbackHandler}
                                askQuestion={askQuestionHandler}
                                isError={isError}
                            />
                        </Box>
                    }
                    actions={
                        <>
                            {xRayActionAnswer && (
                                <BrButtonStyled
                                    variant={BrButtonVariant.outlined}
                                    color={BrButtonColor.primary}
                                    fullWidth
                                    onClick={copyHandler}
                                >
                                    {t('actions.xray_helper.copy_button')}
                                </BrButtonStyled>
                            )}
                            <BrButtonStyled
                                variant={BrButtonVariant.outlined}
                                color={BrButtonColor.primary}
                                fullWidth
                                onClick={handleCloseDrawer}
                            >
                                {t('common.close')}
                            </BrButtonStyled>
                        </>
                    }
                    onClose={handleCloseDrawer}
                />
            );
        },
    );

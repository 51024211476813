import { AddressQl } from 'src/data/api/graphql/br_search/generated/graphql-sdk';
import { titleCase } from 'src/utils/string.utils';

import { getStreetAddress } from './address.utils';

export enum PrecisionType {
    REVERSE = 'REVERSE',
    NO_DATA = 'NO_DATA',
    NOM = 'NOM',
    CNF = 'CNF',
}

export interface RawAddressData {
    city?: string;
    continent?: string;
    country?: string;
    country_code?: string;
    lat: number;
    lng: number;
    post_code?: string;
    post_code_is_exact: boolean;
    post_code_start?: string;
    post_code_end?: string;
    precision_accuracy: number;
    precision_level: string;
    precision_type: PrecisionType;
    rank: number;
    state?: string;
    road?: string;
    house_number?: string;
}

export interface Address {
    lat: number;
    lng: number;
    country: string;
    countryCode: string;
    postCode?: string;
    city?: string;
    state?: string;
    road?: string;
    rank: number;
    houseNumber?: string;
    streetAddress?: string;
    precisionType: PrecisionType;
}

export const createAddress = (rawAddress: RawAddressData): Address => {
    let streetAddress = '';
    if (rawAddress.road && rawAddress.house_number) {
        streetAddress = getStreetAddress(
            rawAddress.road,
            rawAddress.house_number,
            rawAddress.country_code ?? '',
        );
    }
    const address: Address = {
        lat: rawAddress.lat,
        lng: rawAddress.lng,
        country: rawAddress.country ?? '',
        postCode: getPostCode(rawAddress),
        countryCode: rawAddress?.country_code ?? '',
        city: rawAddress?.city ?? undefined,
        state: rawAddress?.state ?? undefined,
        road: rawAddress?.road ? titleCase(rawAddress.road) : undefined,
        rank: rawAddress?.rank,
        houseNumber: rawAddress?.house_number ?? undefined,
        precisionType: rawAddress.precision_type,
        streetAddress,
    };
    return address;
};

const getPostCode = (address: RawAddressData): string => {
    let postCode = '';
    const {
        post_code,
        road,
        post_code_is_exact,
        post_code_start,
        post_code_end,
    } = address;
    if (post_code_is_exact && post_code && road) {
        postCode = post_code.toUpperCase();
    }
    if (!post_code_is_exact && post_code_start && post_code_end) {
        postCode = setPostCodeRange(post_code_start, post_code_end);
    }
    return postCode;
};

const setPostCodeRange = (
    post_code_start: string,
    post_code_end: string,
): string => {
    const postCodeStart = post_code_start.trim();
    const postCodeEnd = post_code_end.trim();

    if (postCodeStart.length !== postCodeEnd.length) {
        return '';
    }
    let rangeText = '';
    // Check post code start and end numbers, then set post code range to post code if range is available.
    for (const index in postCodeStart.split('')) {
        if (postCodeStart[index] !== postCodeEnd[index]) {
            rangeText += '*';
            break;
        } else {
            rangeText += postCodeStart[index];
        }
    }
    rangeText = (rangeText + '*'.repeat(postCodeStart.length)).slice(
        0,
        postCodeStart.length,
    );

    /*
     * If range text doesn't have a start in first digit, set range text as post code.
     */
    if (rangeText.indexOf('*')) {
        return rangeText;
    }
    return '';
};

export const createAddressFromSdk = (
    rawAddress: AddressQl | null,
): Address | null => {
    if (!rawAddress) {
        return null;
    }

    let streetAddress = '';
    if (rawAddress.road && rawAddress.houseNumber) {
        streetAddress = getStreetAddress(
            rawAddress.road,
            rawAddress.houseNumber,
            rawAddress.countryCode ?? '',
        );
    }
    const address: Address = {
        lat: rawAddress.lat ?? 0,
        lng: rawAddress.lng ?? 0,
        country: rawAddress.country ?? '',
        postCode: getPostCodeFromSdk(rawAddress),
        countryCode: rawAddress?.countryCode ?? '',
        city: rawAddress?.city ?? undefined,
        state: rawAddress?.state ?? undefined,
        road: rawAddress?.road ? titleCase(rawAddress.road) : undefined,
        rank: rawAddress?.rank ?? 0,
        houseNumber: rawAddress?.houseNumber ?? undefined,
        precisionType: rawAddress.precisionType
            ? (rawAddress.precisionType as PrecisionType)
            : PrecisionType.NO_DATA,
        streetAddress,
    };
    return address;
};

const getPostCodeFromSdk = (address: AddressQl): string => {
    let postalCode = '';
    const { postCode, road, postCodeIsExact, postCodeStart, postCodeEnd } =
        address;
    if (postCodeIsExact && postCode && road) {
        postalCode = postCode.toUpperCase();
    }
    if (!postCodeIsExact && postCodeStart && postCodeEnd) {
        postalCode = setPostCodeRange(postCodeStart, postCodeEnd);
    }
    return postalCode;
};

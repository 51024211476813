import { Skeleton } from '@mui/lab';
import { useMediaQuery } from '@mui/material';
import { FC } from 'react';

import { MediaQuery } from 'src/resources/styles/media.mixins';

import {
    PlaceholderStyled,
    AvatarPlaceholderStyled,
    DetailPlaceholderStyled,
} from './notification-placeholder.styled';

export const NotificationPlaceholderComponent: FC = () => {
    const fullWidthMenu = useMediaQuery(MediaQuery.fullWidthMenu);
    const avatarSize = fullWidthMenu ? 34 : 40;

    return (
        <PlaceholderStyled>
            <AvatarPlaceholderStyled>
                <Skeleton
                    component="div"
                    variant="circular"
                    width={avatarSize}
                    height={avatarSize}
                />
            </AvatarPlaceholderStyled>
            <DetailPlaceholderStyled>
                <Skeleton
                    component="div"
                    variant="rectangular"
                    width={'100%'}
                    height={92}
                />
            </DetailPlaceholderStyled>
        </PlaceholderStyled>
    );
};

import { BrInfotip } from '@buildingradar/br_component_lib';
import { observer } from 'mobx-react';
import {
    FC,
    KeyboardEvent,
    RefObject,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { getTime } from 'src/utils/datetime.utils';
import { KeyNames } from 'src/utils/keyboard.utils';

import { TokenFieldContentStyled, TokenStyled } from './token-field.styled';
import {
    getMutationObserver,
    getTokenArray,
    removeTokenStyle,
    Token,
    TokenStyleMap,
} from './token-field.utils';

interface TokenFieldComponentProps {
    id?: string;
    text: string;
    data: Record<string, any>;
    pipes?: Record<string, (value: string) => string>;
    contentRef?: RefObject<HTMLDivElement>;
    rows?: number;
    registerToken?: (tokenId: string, token: string) => void;
}

export const TokenFieldComponent: FC<TokenFieldComponentProps> = observer(
    ({ id, text, data, pipes, contentRef, rows = 1, registerToken }) => {
        const [observers] = useState<MutationObserver[]>([]);
        const [key, setKey] = useState<number>(getTime());

        const { t } = useTranslation();

        const registerTokenMutations = useCallback(
            (
                tokenElement: HTMLSpanElement | null,
                tokenId: string,
                token: string,
            ) => {
                if (tokenElement) {
                    observers.push(getMutationObserver(tokenElement));
                    registerToken?.(tokenId, token);
                }
            },
            [observers, registerToken],
        );

        const deregisterTokenMutations = useCallback(() => {
            observers.forEach((observer) => observer.disconnect());
        }, [observers]);

        //deregister observers on unmount
        useEffect(() => {
            return deregisterTokenMutations;
        }, [deregisterTokenMutations]);

        const parsedTokens: (Token | string)[] = useMemo(() => {
            return getTokenArray(text, data, pipes);
        }, [text, data, pipes]);

        const onKeyDown = useCallback((e: KeyboardEvent<HTMLDivElement>) => {
            if (
                [
                    KeyNames.Enter.valueOf(),
                    KeyNames.NumpadEnter.valueOf(),
                ].includes(e.key)
            ) {
                const range = window.getSelection()?.getRangeAt(0);
                const parent = range?.startContainer.parentElement;
                if (parent?.classList.contains('token')) {
                    removeTokenStyle(parent);
                }
            }
            return true;
        }, []);

        //create elements from parsed tokens
        const render = useMemo(() => {
            //deregister token mutations on rerender
            deregisterTokenMutations();

            //contentEditable div mutations cannot be managed by React.
            //Update key of the parent element because a child might be removed without react knowing
            setKey(getTime());

            return parsedTokens.map((token, index) => {
                if (typeof token !== 'string') {
                    const style = TokenStyleMap[token.type];
                    return (
                        <BrInfotip
                            key={index}
                            content={t('common.no_information_found_text')}
                            trigger={
                                token.type !== 'notfound'
                                    ? 'disabled'
                                    : 'onHover'
                            }
                            className="tooltip"
                        >
                            <TokenStyled
                                ref={(ref) =>
                                    registerTokenMutations(
                                        ref,
                                        index.toString(),
                                        token.tokenValue,
                                    )
                                }
                                color={style.color}
                                backgroundColor={style.backgroundColor}
                                hasPadding={!!token.text}
                                className={'token'}
                                data-tokenId={index}
                            >
                                {token.text}
                            </TokenStyled>
                        </BrInfotip>
                    );
                }
                if (token === '\n') {
                    return <br key={index} />;
                }
                return token;
            });
        }, [deregisterTokenMutations, parsedTokens, registerTokenMutations, t]);

        return (
            <TokenFieldContentStyled
                id={id}
                contentEditable
                suppressContentEditableWarning
                spellCheck="false"
                ref={contentRef}
                key={key}
                rows={rows}
                onKeyDown={onKeyDown}
            >
                {render}
            </TokenFieldContentStyled>
        );
    },
);

import {
    BrButton,
    BrButtonColor,
    BrButtonVariant,
    BrIcon,
    IconName,
} from '@buildingradar/br_component_lib';
import { DialogContent, Grid, IconButton } from '@mui/material';
import { FC, ReactNode } from 'react';

import {
    ButtonGroupStyled,
    DescriptionTextStyled,
    DescriptionWrapStyled,
    Dialog,
    TextStyled,
    TitleWrapStyled,
    WarnIconWrapStyled,
} from './modal-confirm.styled';

interface ConfirmModalProps {
    icon?: IconName;
    title?: string;
    titleNode?: ReactNode;
    description?: string;
    descriptionNode?: ReactNode;
    btnCancel: string;
    btnOk: string;
    open: boolean;
    className?: string;
    btnOkVariant?: BrButtonVariant;
    btnOkColor?: BrButtonColor;
    btnCancelVariant?: BrButtonVariant;
    btnCancelColor?: BrButtonColor;
    shouldConfirmOnClose?: boolean;
    onCancel: () => void;
    onConfirm: () => void;
}
export const ConfirmModal: FC<ConfirmModalProps> = ({
    icon,
    title,
    description,
    btnCancel,
    btnOk,
    open,
    className,
    btnOkVariant,
    btnOkColor,
    btnCancelVariant,
    btnCancelColor,
    descriptionNode,
    titleNode,
    shouldConfirmOnClose = false,
    onCancel,
    onConfirm,
}) => {
    return (
        <Dialog
            onClose={shouldConfirmOnClose ? onConfirm : onCancel}
            open={open}
            className={className}
        >
            <DialogContent>
                {title ? (
                    <TitleWrapStyled container>
                        <Grid item xs={10}>
                            {icon && (
                                <WarnIconWrapStyled>
                                    <BrIcon iconName={icon} />
                                </WarnIconWrapStyled>
                            )}
                            <TextStyled>{title}</TextStyled>
                        </Grid>
                        <Grid
                            item
                            xs={2}
                            justifyContent="flex-end"
                            alignItems="baseline"
                        >
                            <IconButton
                                onClick={
                                    shouldConfirmOnClose ? onConfirm : onCancel
                                }
                            >
                                <BrIcon iconName={IconName.BrCloseIcon} />
                            </IconButton>
                        </Grid>
                    </TitleWrapStyled>
                ) : (
                    titleNode
                )}
                {description ? (
                    <DescriptionWrapStyled container>
                        <DescriptionTextStyled item xs={12}>
                            {description}
                        </DescriptionTextStyled>
                    </DescriptionWrapStyled>
                ) : (
                    descriptionNode
                )}
                <ButtonGroupStyled>
                    <Grid item xs={12}>
                        <BrButton
                            className="btn-cancel"
                            onClick={onCancel}
                            color={
                                btnCancelColor
                                    ? btnCancelColor
                                    : BrButtonColor.primary
                            }
                            variant={
                                btnCancelVariant
                                    ? btnCancelVariant
                                    : BrButtonVariant.text
                            }
                        >
                            {btnCancel}
                        </BrButton>
                        <BrButton
                            className="btn-ok"
                            onClick={onConfirm}
                            color={
                                btnOkColor ? btnOkColor : BrButtonColor.primary
                            }
                            variant={
                                btnOkVariant
                                    ? btnOkVariant
                                    : BrButtonVariant.contained
                            }
                        >
                            {btnOk}
                        </BrButton>
                    </Grid>
                </ButtonGroupStyled>
            </DialogContent>
        </Dialog>
    );
};

import { BrTextInputStatus } from '@buildingradar/br_component_lib';
import { observer } from 'mobx-react';
import { FC } from 'react';
import { Path, useFormContext } from 'react-hook-form';

import { DealContactUnionForm } from 'src/app-features/contact/data/model/deal-contacts-form.model';
import { DealContactFormField } from 'src/app-features/contact/presentation/contact-panel/components/deal-contact-form-field.component';
import { useAnimationStatus } from 'src/app-features/contact/presentation/contact-panel/components/hooks';
import { FormControl } from 'src/presentation/shared/form-control/form-control.component';
import { CustomParameterFieldConfig } from 'src/presentation/shared/form-control/form.model';

import { ContactFieldProps } from './common.utils';

interface CustomFieldContactFieldProps extends ContactFieldProps {
    config: CustomParameterFieldConfig;
    fieldProgress?: Map<string, BrTextInputStatus>;
}
export const CustomFieldContactField: FC<CustomFieldContactFieldProps> =
    observer(({ config, focusField, fieldProgress, onSaveHandler }) => {
        const { control } = useFormContext<DealContactUnionForm>();
        return (
            <>
                {config.customFields?.map((field, idx) => {
                    const status = useAnimationStatus(
                        fieldProgress?.get(`customFields.${idx}.value`) ??
                            'idle',
                    );

                    return (
                        <DealContactFormField
                            key={field.name}
                            label={field.label}
                        >
                            <FormControl<DealContactUnionForm>
                                autoFocus={field.name === focusField}
                                control={control}
                                name={
                                    `customFields.${idx}.value` as Path<DealContactUnionForm>
                                }
                                key={field.name}
                                type={config.type}
                                placeholder={field.label}
                                className={field.name}
                                customField={field}
                                onSave={onSaveHandler}
                                validate={config.validate}
                                status={status}
                            />
                        </DealContactFormField>
                    );
                })}
            </>
        );
    });

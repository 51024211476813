import { observer } from 'mobx-react';
import { FC } from 'react';

import { BadgeColor, BadgeColorMap } from './utils';

interface CircleBadgeComponentProps {
    color?: BadgeColor;
    size?: string;
}

export const CircleBadgeComponent: FC<CircleBadgeComponentProps> = observer(
    ({ color = BadgeColor.Gray, size = '72' }) => {
        const colors = BadgeColorMap[color];

        return (
            <svg
                width={size}
                height={size}
                viewBox="0 0 72 72"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <circle
                    cx="36.24"
                    cy="36.24"
                    r="30.24"
                    fill={`url(#paint0_linear_circle_${color})`}
                />
                <path
                    opacity="0.65"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M36.24 66.48C52.9411 66.48 66.48 52.9411 66.48 36.24C66.48 19.5389 52.9411 6 36.24 6C19.5389 6 6 19.5389 6 36.24C6 52.9411 19.5389 66.48 36.24 66.48ZM36.24 61.08C49.9587 61.08 61.08 49.9587 61.08 36.24C61.08 22.5212 49.9587 11.4 36.24 11.4C22.5212 11.4 11.4 22.5212 11.4 36.24C11.4 49.9587 22.5212 61.08 36.24 61.08Z"
                    fill={`url(#paint1_linear_circle_${color})`}
                />
                <path
                    opacity="0.75"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M36.24 66.48C52.9411 66.48 66.48 52.9411 66.48 36.24C66.48 19.5389 52.9411 6 36.24 6C19.5389 6 5.99997 19.5389 5.99997 36.24C5.99997 52.9411 19.5389 66.48 36.24 66.48ZM36 62.64C50.7129 62.64 62.64 50.7129 62.64 36C62.64 21.2871 50.7129 9.36 36 9.36C21.2871 9.36 9.36002 21.2871 9.36002 36C9.36002 50.7129 21.2871 62.64 36 62.64Z"
                    fill={`url(#paint2_linear_circle_${color})`}
                />
                <path
                    d="M36 7.20001C37.4683 7.20001 38.9109 7.30988 40.32 7.52185M44.1 8.35472C51.8035 10.6081 58.1697 15.9939 61.7262 23.04"
                    stroke="white"
                    strokeWidth="1.44"
                    strokeLinecap="round"
                />
                <g style={{ opacity: color === BadgeColor.Gray ? 0 : 1 }}>
                    <path
                        d="M50.8728 47.7201L51.5874 48.9983L52.8656 49.7129L51.5874 50.4275L50.8728 51.7057L50.1582 50.4275L48.88 49.7129L50.1582 48.9983L50.8728 47.7201Z"
                        fill="white"
                    />
                    <path
                        d="M24.5928 46.28L25.3074 47.5582L26.5856 48.2728L25.3074 48.9874L24.5928 50.2656L23.8783 48.9874L22.6 48.2728L23.8783 47.5582L24.5928 46.28Z"
                        fill="white"
                    />
                    <path
                        d="M45.6144 16.04L44.9598 17.211L43.7888 17.8656L44.9598 18.5202L45.6144 19.6912L46.269 18.5202L47.44 17.8656L46.269 17.211L45.6144 16.04Z"
                        fill="white"
                    />
                    <circle cx="20.44" cy="51.6801" r="1.08" fill="white" />
                    <circle
                        cx="0.9"
                        cy="0.9"
                        r="0.9"
                        transform="matrix(-1 0 0 1 43.48 20.0001)"
                        fill="white"
                    />
                    <circle cx="54.64" cy="25.7601" r="1.08" fill="white" />
                    <path
                        d="M20.4272 21.8L21.0681 22.9464L22.2144 23.5873L21.0681 24.2281L20.4272 25.3745L19.7863 24.2281L18.64 23.5873L19.7863 22.9464L20.4272 21.8Z"
                        fill="white"
                    />
                </g>

                <mask
                    id="mask0_806_2524"
                    style={{ maskType: 'alpha' }}
                    maskUnits="userSpaceOnUse"
                    x="6"
                    y="6"
                    width="60"
                    height="60"
                >
                    <circle cx="36" cy="36" r="30" fill="#D9D9D9" />
                </mask>
                <g mask="url(#mask0_806_2524)">
                    <rect
                        x="61.3299"
                        y="0.285706"
                        width="22.8576"
                        height="78.3232"
                        transform="rotate(60 61.3299 0.285706)"
                        fill="white"
                        fillOpacity="0.1"
                    />
                    <rect
                        x="64.4453"
                        y="9.09088"
                        width="6.07143"
                        height="72.9861"
                        transform="rotate(60 64.4453 9.09088)"
                        fill="white"
                        fillOpacity="0.1"
                    />
                    <rect
                        x="64.4211"
                        y="30.5135"
                        width="6.07143"
                        height="63.7474"
                        transform="rotate(60 64.4211 30.5135)"
                        fill="white"
                        fillOpacity="0.1"
                    />
                </g>
                <defs>
                    <linearGradient
                        id={`paint0_linear_circle_${color}`}
                        x1="38.5964"
                        y1="23.976"
                        x2="24.4474"
                        y2="62.6241"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor={colors.stopColor1} />
                        <stop offset="1" stopColor={colors.stopOffsetColor1} />
                    </linearGradient>
                    <linearGradient
                        id={`paint1_linear_circle_${color}`}
                        x1="53.0992"
                        y1="11.7108"
                        x2="27.4167"
                        y2="64.6665"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor={colors.stopColor2} />
                        <stop offset="1" stopColor={colors.stopOffsetColor2} />
                    </linearGradient>
                    <linearGradient
                        id={`paint2_linear_circle_${color}`}
                        x1="36.24"
                        y1="6.16181"
                        x2="38.2642"
                        y2="62.7886"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor={colors.stopColor3} />
                        <stop offset="1" stopColor={colors.stopOffsetColor3} />
                    </linearGradient>
                </defs>
            </svg>
        );
    },
);

import { IconName } from '@buildingradar/br_component_lib';

import {
    GoogleEnrichmentPartFragment,
    PlacePartFragment,
} from 'src/data/api/graphql/br_process/generated/graphql-sdk';

export type GoogleEnrichmentResultKey = 'url' | 'address' | 'telephone' | 'street' | 'city' | 'post_code' | 'country' | 'country_iso';
export const GoogleEnrichmentResultKeyList: GoogleEnrichmentResultKey[] = [
    'url',
    'address',
    'telephone',
    'street',
    'city',
    'post_code',
    'country',
    'country_iso',
];
export interface GoogleEnrichmentData {
    results: GoogleEnrichmentResult[];
    id: string;
}

interface GoogleEnrichmentResult {
    key: GoogleEnrichmentResultKey;
    value: string;
}

export const createGoogleEnrichmentData = (
    rawGoogleLocationData: GoogleEnrichmentPartFragment,
): GoogleEnrichmentData => {
    const { formattedAddress, internationalPhoneNumber, website, id, addressDetail } =
        rawGoogleLocationData;
    return {
        results: [
            {
                key: 'url' as GoogleEnrichmentResultKey,
                value: website ?? '',
            },
            {
                key: 'address' as GoogleEnrichmentResultKey,
                value: formattedAddress ?? '',
            },
            {
                key: 'telephone' as GoogleEnrichmentResultKey,
                value: internationalPhoneNumber ?? '',
            },
            {
                key: 'street' as GoogleEnrichmentResultKey,
                value: `${addressDetail?.street ?? ''} ${addressDetail?.streetNumber ?? ''}`.trim(),

            },
            {
                key: 'city' as GoogleEnrichmentResultKey,
                value: addressDetail?.city ?? '',
            },
            {
                key: 'post_code' as GoogleEnrichmentResultKey,
                value: addressDetail?.postalCode ?? '',
            },
            {
                key: 'country' as GoogleEnrichmentResultKey,
                value: addressDetail?.country ?? '',
            },
            {
                key: 'country_iso' as GoogleEnrichmentResultKey,
                value: addressDetail?.countryCode ?? '',
            }
        ].filter((result) => !!result.value),
        id,
    };
};

export const createLocationData = (rawLocationData: PlacePartFragment) => {
    const { id, name } = rawLocationData;
    return {
        value: id,
        label: name,
    };
};

export const GoogleEnrichmentFieldIcons: Record<
    GoogleEnrichmentResultKey,
    IconName
> = {
    address: IconName.BrPlaceOutlinedIcon,
    url: IconName.BrLinkIcon,
    telephone: IconName.BrCallIcon,
    street: IconName.BrCategoryOutlinedIcon,
    city: IconName.BrCategoryOutlinedIcon,
    post_code: IconName.BrCategoryOutlinedIcon,
    country: IconName.BrCategoryOutlinedIcon,
    country_iso: IconName.BrCategoryOutlinedIcon,
};

import { makeAutoObservable } from 'mobx';

import {
    CrmState,
    Integration,
    IntegrationType,
} from 'src/domain/models/integration/integration.model';

export interface ExportStore {
    integrations: Integration[];
    crmIntegrations: Integration[];
    areIntegrationsLoading: boolean;
    setLoadingIntegrations: (loading: boolean) => void;
    setIntegrations: (integrations: Integration[]) => void;
    needToRequestIntegrations: boolean;
    selectedIntegration: Integration | null;
    setSelectedIntegration: (integration: Integration) => void;
    needToRequestCrmState: boolean;
    crmState: CrmState | null;
    setCrmState: (crmState: CrmState | null) => void;
    isCrmStateLoading: boolean;
    setLoadingCrmState: (loading: boolean) => void;
    setNeedToRequestCrmState: (flag: boolean) => void;
    setNeedToRequestIntegrations: (flag: boolean) => void;
}

export class ExportStoreImpl implements ExportStore {
    integrations: Integration[] = [];
    crmIntegrations: Integration[] = [];
    areIntegrationsLoading = false;
    needToRequestIntegrations = true;
    selectedIntegration: Integration | null = null;
    needToRequestCrmState = false;
    crmState: CrmState | null = null;
    isCrmStateLoading = false;

    setIntegrations = (integrations: Integration[]) => {
        this.integrations = integrations;
        this.crmIntegrations = this.integrations.filter(
            (integration) =>
                integration.integrationType === IntegrationType.Crm,
        );
        if (this.crmIntegrations.length > 0) {
            this.setSelectedIntegration(this.crmIntegrations[0]);
        }
        this.setNeedToRequestIntegrations(false);
    };

    setLoadingIntegrations = (loading: boolean) => {
        this.areIntegrationsLoading = loading;
    };

    setSelectedIntegration = (integration: Integration) => {
        this.selectedIntegration = integration;
        this.setCrmState(null);
        this.setNeedToRequestCrmState(
            integration.integrationType !== IntegrationType.DummyCrm,
        );
    };

    setCrmState = (crmState: CrmState | null) => {
        this.crmState = crmState;
        this.needToRequestCrmState = false;
    };

    setLoadingCrmState = (loading: boolean) => {
        this.isCrmStateLoading = loading;
    };

    setNeedToRequestCrmState = (flag: boolean) => {
        this.needToRequestCrmState = flag;
    };

    setNeedToRequestIntegrations = (flag: boolean) => {
        this.needToRequestIntegrations = flag;
    };

    constructor() {
        makeAutoObservable(this);
    }
}

export interface ILeadDataContext {
    latestSource?: string;
}

export class LeadDataContext {
    latestSource?: string;

    constructor(data: ILeadDataContext) {
        this.latestSource = data.latestSource;
    }

    get latestSourceDomain(): string | undefined {
        if (!this.latestSource) {
            return undefined;
        }

        return this.latestSource
            .replace('http://', '')
            .replace('https://', '')
            .split(/[/?#]/)[0];
    }
}

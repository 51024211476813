import {
    BrButton,
    BrButtonSize,
    BrButtonVariant,
    BrIcon,
    BrText,
    IconName,
} from '@buildingradar/br_component_lib';
import { Box, Divider } from '@mui/material';
import { observer } from 'mobx-react';
import { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
    DealContactCompany,
    DealContactPerson,
} from 'src/app-features/contact/data/model/deal-contacts.model';
import { MixpanelEventName } from 'src/data/services/mixpanel/mixpanel.model';
import { EventProps } from 'src/data/services/mixpanel/mixpanel.service';
import { ElevatedCardStyled } from 'src/presentation/modules/enablement-actions-panel/actions/shared/styled';
import { Spacing } from 'src/resources/styles/spacing';
import { openLink } from 'src/utils/url.utils';

import { DataProviderLoomVideoComponent } from './data-provider-loom-video.component';
import { DataProviderFieldStyled, DataProviderHeaderStyled } from './styled';
import { DataProvider } from './utils';

interface DataProviderCardComponentProps {
    withDivider?: boolean;
    dealId: string;
    selectedContact: DealContactPerson;
    company: DealContactCompany;
    provider: DataProvider;
    sendMixpanelEvent: (
        eventName: MixpanelEventName,
        eventProperties?: EventProps,
        dealId?: string,
    ) => void;
}
export const DataProviderCardComponent: FC<DataProviderCardComponentProps> =
    observer(
        ({
            withDivider,
            provider,
            dealId,
            selectedContact,
            company,
            sendMixpanelEvent,
        }) => {
            const { t } = useTranslation();
            const [guideVideoLink, setGuideVideoLink] = useState<
                string | undefined
            >(undefined);
            const onLaunch = useCallback(
                (provider: DataProvider) => () => {
                    sendMixpanelEvent(
                        MixpanelEventName.InteractDataProvider,
                        {
                            dataProviderQuicklinkInteraction: `Open ${provider.name}`,
                        },
                        dealId,
                    );
                    openLink(provider.generateUrl(selectedContact, company));
                },
                [company, dealId, selectedContact, sendMixpanelEvent],
            );
            const onClickGuide = useCallback(
                (provider: DataProvider) => () => {
                    if (provider.guideKey) {
                        setGuideVideoLink(provider.guideKey);
                        sendMixpanelEvent(
                            MixpanelEventName.InteractDataProvider,
                            {
                                dataProviderQuicklinkInteraction: `${provider.name} guide`,
                            },
                            dealId,
                        );
                    }
                },
                [dealId, sendMixpanelEvent],
            );
            return (
                <>
                    {withDivider && (
                        <>
                            <Divider sx={{ margin: '28px 0px 16px 0px' }} />
                            <BrText color={'var(--gray-60)'} lineHeight="150%">
                                {t(
                                    'actions.contact_enrichment.data_provider.tip',
                                )}
                            </BrText>
                        </>
                    )}
                    <ElevatedCardStyled isSelected={false} key={provider.id}>
                        <DataProviderHeaderStyled>
                            <img
                                alt={provider.name}
                                src={provider.logo}
                                style={{ maxWidth: '128px', maxHeight: '28px' }}
                            />
                            <Box marginTop="2px">
                                <BrButton
                                    variant={BrButtonVariant.outlined}
                                    endIcon={IconName.BrOpenInNewIcon}
                                    size={BrButtonSize.small}
                                    onClick={onLaunch(provider)}
                                >
                                    {t(
                                        'actions.contact_enrichment.data_provider.launch',
                                    )}
                                </BrButton>
                            </Box>
                        </DataProviderHeaderStyled>
                        <DataProviderFieldStyled>
                            <BrIcon
                                iconName={IconName.BrLabelIcon}
                                iconColor="var(--gray-50)"
                                iconSize={20}
                            />
                            {t(provider.plan)}
                        </DataProviderFieldStyled>
                        <DataProviderFieldStyled>
                            <BrIcon
                                iconName={IconName.BrContactMailOutlinedIcon}
                                iconColor="var(--gray-50)"
                                iconSize={20}
                            />
                            {t(provider.description)}
                        </DataProviderFieldStyled>
                        {provider.guideKey && (
                            <DataProviderFieldStyled
                                color="var(--blue-70)"
                                sx={{ cursor: 'pointer' }}
                                marginTop={Spacing.s1}
                                onClick={onClickGuide(provider)}
                            >
                                <BrIcon
                                    iconName={
                                        IconName.BrPlayCircleFilledOutlinedIcon
                                    }
                                    iconSize={20}
                                />
                                {t(
                                    'actions.contact_enrichment.data_provider.guide',
                                )}
                            </DataProviderFieldStyled>
                        )}
                    </ElevatedCardStyled>
                    <DataProviderLoomVideoComponent
                        isOpen={!!guideVideoLink}
                        onClose={() => setGuideVideoLink(undefined)}
                        loomUrl={guideVideoLink ? t(guideVideoLink) : ''}
                    />
                </>
            );
        },
    );

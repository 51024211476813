import { BrCheckboxGroup } from '@buildingradar/br_component_lib';
import { Box, styled } from '@mui/material';

import { Spacing } from 'src/resources/styles/spacing';

export const EnrichmentResultFieldStyled = styled(Box)({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
});

export const EnrichmentResultGroupStyled = styled(BrCheckboxGroup)({
    width: '100%',
    '& .MuiFormControl-root': {
        width: '100%',
    },
    '& .MuiFormGroup-root ': {
        width: '100%',
    },
    '& .MuiFormControlLabel-root': {
        width: '100%',
    },
    '& .MuiFormControlLabel-label': {
        width: '100%',
    },
});

export const EnrichmentTargetSelectBox = styled(Box)({
    paddingTop: Spacing.s0_5,
});

export const SectionGreyTitleStyled = styled(Box)({
    color: 'var(--gray-40)',
    fontSize: '14px',
    fontWeight: 600,
    height: '28px',
    lineHeight: '28px',
    letterSpacing: '0.5px',
    textTransform: 'uppercase',
});

import { UserJobRoles } from 'src/data/api/graphql/br_user/generated/graphql-sdk';
import { Deal } from 'src/domain/models/deal/deal.model';
import { Objective } from 'src/domain/models/objective/objective.model';
import { SuccessState } from 'src/domain/models/success-state/success-state.model';

export const jobRolesToName: Record<UserJobRoles, string> = {
    [UserJobRoles.Screener]: 'Screener',
    [UserJobRoles.Researcher]: 'Researcher',
    [UserJobRoles.MeetingBooker]: 'Meeting booker',
    [UserJobRoles.SaleOwner]: 'Sale owner',
    [UserJobRoles.Supervisor]: 'Supervisor',
    [UserJobRoles.Observer]: 'Observer',
};

export const getUTMTags = () => {
    const { search } = window.location;
    const searchParams = new URLSearchParams(search);
    const params: Record<string, string> = {};
    ['utm_source', 'utm_emailblock', 'utm_cta'].forEach((utm) => {
        const utm_value = searchParams.get(utm);
        if (utm_value) {
            params[utm + '_br'] = utm_value;
        }
    });
    return params;
};

export const dealStageObjectiveCounters = (
    deal: Deal,
    objectivesMap: Map<string, Objective>,
) => {
    const objectives = deal.objectivesIds.map((objectiveId) =>
        objectivesMap.get(objectiveId),
    );
    const numberOfConfirmedStageObjectives = objectives.filter(
        (objective) => objective?.state === SuccessState.success,
    ).length;
    const numberOfUnconfirmedStageObjectives = objectives.filter(
        (objective) => objective?.state === SuccessState.undecided,
    ).length;
    const numberOfFailedStageObjectives = objectives.filter(
        (objective) => objective?.state === SuccessState.failure,
    ).length;
    const result = {
        numberOfConfirmedStageObjectives,
        numberOfUnconfirmedStageObjectives,
        percentageOfUnconfirmedStageObjectives: +(
            numberOfUnconfirmedStageObjectives / objectives.length
        ).toFixed(2),
        numberOfFailedStageObjectives,
    };
    return result;
};

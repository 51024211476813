export interface DealContactCandidate {
    firstName: string;
    lastName: string;
    companyName: string;
    legitimateInterestText: string;
    sourceUrls: string[];
    role?: string;
}

export interface DealCompanyCandidate {
    name: string;
    legitimateInterestText: string;
    sourceUrls: string[];
    role?: string;
}

export interface DealContactCandidateForm {
    firstName: string;
    lastName: string;
    companyName: string;
    notes: string;
    customValues?: CustomValue[];
}

export interface DealCompanyCandidateForm {
    name: string;
    notes: string;
    customValues?: CustomValue[];
}

export enum ContactExtractionResultStatus {
    Error = 'ERROR',
    NoContacts = 'NO_CONTACTS',
    Success = 'SUCCESS',
    InProgress = 'IN_PROGRESS',
    NeverRun = 'NEVER_RUN',
}

export interface ContactExtractionResult {
    status: ContactExtractionResultStatus;
    contactCandidates: DealContactCandidate[];
    companyCandidates: DealCompanyCandidate[];
}

type CustomValue = { name: string; value: string };

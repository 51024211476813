import { encode } from 'js-base64';
import { makeAutoObservable, runInAction, reaction } from 'mobx';
import { getI18n } from 'react-i18next';
import { IDealContactsApi } from 'src/app-features/contact/data/api/deal-contacts.api.types';

import {
    DealContactPerson,
    DealContactType,
    PredefinedCustomFields,
} from 'src/app-features/contact/data/model/deal-contacts.model';
import { IDealContactsStore as IDealContactsStoreNew } from 'src/app-features/contact/data/stores/deal-contacts.store.interface';
import { IDealActivityStore } from 'src/app-features/deal-activity/domain/deal-activity.store';
import {
    CreateContactVCardPayload,
    ISaveContactToPhoneService,
} from 'src/data/services/save-contact-to-phone/save-contact-to-phone.service';
import { IBaseStore } from 'src/data/stores/shared/base.store.interface';
import { ToasterStore } from 'src/data/stores/toaster/toaster.store';
import { doNothing } from 'src/utils/function.utils';
import { handleRequest } from 'src/utils/handle-request.utils';

export interface IQrCodeFeature {
    /**
     * Observable
     * Url of a generated contact Qr Code, used as image source to display the QR code
     */
    currentContactQrCodeUrl?: string;
    /**
     * Action
     * Use the current vCard to build a blob file and download it directly from the browser
     */
    downloadContactVCard: () => void;
    /**
     * Async Action
     * Send vCardCurrentContact data to given phone number via sms
     */
    sendContactWithSMS: (phoneNumber: string) => void;
}

export class QrCodeFeature implements IQrCodeFeature {
    currentContactQrCodeUrl?: string;

    constructor(
        private dealContactsStoreNew: IDealContactsStoreNew,
        private saveContactToPhoneService: ISaveContactToPhoneService,
        private dealContactsApi: IDealContactsApi,
        private toasterStore: ToasterStore,
        private dealActivityStore: IDealActivityStore,
        private baseStore: IBaseStore,
    ) {
        makeAutoObservable(this);

        reaction(
            () => this.vCardCurrentContact,
            async (vCard) => {
                await this.buildAndSetContactQrCode(vCard);
            },
        );
    }

    getSelectedContact = (): DealContactPerson | undefined => {
        const contact = this.dealActivityStore.outreachActivationState.contact;
        return contact?.type === DealContactType.person ? contact : undefined;
    };

    downloadContactVCard = () => {
        const contact = this.getSelectedContact();

        if (!contact || !this.vCardCurrentContact) {
            return;
        }

        const vCardFileName = `${contact.firstName}${
            contact.lastName ? `_${contact.lastName}` : ''
        }`;
        this.saveContactToPhoneService.downloadVCard(
            this.vCardCurrentContact,
            vCardFileName,
        );
    };

    private buildAndSetContactQrCode = async (vCard?: string) => {
        if (!vCard) {
            return;
        }

        const url = await this.saveContactToPhoneService.buildQrCodeUrl(vCard);

        runInAction(() => {
            this.currentContactQrCodeUrl = url;
        });
    };

    sendContactWithSMS = async (phoneNumber: string) => {
        if (!this.vCardCurrentContact) {
            return;
        }
        const { t } = getI18n();

        handleRequest(
            this.dealContactsApi.sendContactWithSMS,
            { phoneNumber, vcardB64: encode(this.vCardCurrentContact) },
            () => {
                this.toasterStore.showMessage({
                    title: t(
                        'actions.save_contact_to_phone.contact_sent_success',
                        { phoneNumber },
                    ),
                    type: 'success',
                });
            },
            doNothing,
            (error) =>
                this.baseStore.onRequestFailed('send-contact-sms', error, {
                    errorMessage: t(
                        'actions.save_contact_to_phone.contact_sent_fail',
                        {
                            phoneNumber,
                        },
                    ),
                }),
        );
    };

    get vCardCurrentContact(): string | undefined {
        const contact = this.getSelectedContact();

        if (!contact) {
            return undefined;
        }

        const linkedCompany = contact.relevantCompanyId
            ? this.dealContactsStoreNew.companiesMap.get(
                  contact.relevantCompanyId,
              )
            : undefined;

        const payload: CreateContactVCardPayload = {
            firstName: contact.firstName,
            lastName: contact.lastName,
            organization: contact.relevantCompany?.name,
            workPhone: contact.customFields?.find(
                ({ name }) => name === PredefinedCustomFields.telephone,
            )?.value,
            cellPhone: contact.customFields?.find(
                ({ name }) => name === PredefinedCustomFields.mobile,
            )?.value,
            workEmail: contact.customFields?.find(
                ({ name }) => name === PredefinedCustomFields.mail,
            )?.value,
            title: contact.customFields?.find(
                ({ name }) => name === PredefinedCustomFields.position,
            )?.value,
            workAddress: linkedCompany?.customFields?.find(
                ({ name }) => name === PredefinedCustomFields.address,
            )?.value,
        };

        return this.saveContactToPhoneService.buildContactVCard(payload);
    }
}

import {
    Language,
    languageToLocale,
} from 'src/domain/models/locale/locale.model';

import { pluck } from './object.utils';

export interface NumberRange {
    lower: number;
    upper: number;
}

export const formatNumberByLanguage = (
    value: number,
    language: Language = Language.De,
) => {
    const locale = languageToLocale[language];
    const formatter = new Intl.NumberFormat(locale, {
        notation: 'compact',
        compactDisplay: 'short',
    });
    const formatParts = formatter.formatToParts(value);

    return formatParts.map(pluck('value')).join('');
};

export const formatNumberRangeByLanguage = (
    value: number,
    ranges: NumberRange[],
    language: Language = Language.De,
    options?: Intl.NumberFormatOptions,
): string | undefined => {
    const minRangeValue = ranges[0].lower;
    const maxRangeValue = ranges[ranges.length - 1].upper;

    const locale = languageToLocale[language];
    const formatter = new Intl.NumberFormat(locale, options);

    if (value < minRangeValue) {
        const rangeFormatParts = formatter.formatToParts(minRangeValue);
        return '< ' + rangeFormatParts.map(pluck('value')).join('');
    } else if (value > maxRangeValue) {
        const rangeFormatParts = formatter.formatToParts(maxRangeValue);
        return '> ' + rangeFormatParts.map(pluck('value')).join('');
    } else {
        const closestRange = ranges.find(
            (range) => range.lower <= value && value <= range.upper,
        );
        if (closestRange === undefined) {
            return undefined;
        }
        const rangeLowerFormatParts = formatter.formatToParts(
            closestRange.lower,
        );
        const rangeUpperFormatParts = formatter.formatToParts(
            closestRange.upper,
        );

        const rangeUpperUnit = rangeUpperFormatParts.find(
            (part) => part.type === 'compact',
        )?.value;

        const rangeUpperString = rangeUpperFormatParts
            .map(pluck('value'))
            .join('');
        const rangeLowerString = rangeLowerFormatParts
            .filter(
                (part) =>
                    part.type !== 'compact' || part.value !== rangeUpperUnit,
            )
            .map(pluck('value'))
            .join('');

        return rangeLowerString.trim() + ' \u2013 ' + rangeUpperString.trim();
    }
};

/**
 * return value that not greater and not less than some specific values
 * @param value
 * @param limits min max values which we'd like to limit the value by
 */
export const getLimitedValue = (
    value: number,
    limits: {
        min?: number;
        max?: number;
    },
): number => {
    const min = limits.min ?? value;
    const max = limits.max ?? value;

    return Math.max(min, Math.min(max, value));
};

export const formatNumberAsKPlus = (num: number): string => {
    if (num >= 1000) {
        return Math.floor(num / 1000) + 'k+';
    }
    return num.toString();
};

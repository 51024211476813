import { BrIcon, IconName } from '@buildingradar/br_component_lib';
import { observer } from 'mobx-react';
import { FC } from 'react';

import { Localized } from 'src/presentation/shared/localized/localized';

import {
    ExceptionStateIconStyled,
    ExceptionStateTextStyled,
    ExceptionStateWrapperStyled,
} from './styles';

interface EnrichmentExceptionIconComponentProps {
    isPaused?: boolean;
}
export const EnrichmentExceptionIconComponent: FC<EnrichmentExceptionIconComponentProps> =
    observer(({ isPaused }) => {
        return (
            <ExceptionStateWrapperStyled>
                <ExceptionStateIconStyled>
                    <BrIcon
                        iconColor={'var(--yellow-90)'}
                        iconName={
                            isPaused
                                ? IconName.BrPauseIcon
                                : IconName.BrPersonNotFoundIcon
                        }
                        iconSize={32}
                    />
                </ExceptionStateIconStyled>
                <ExceptionStateTextStyled>
                    <Localized>
                        {isPaused
                            ? 'actions.contact_enrichment.flyout.paused_hint'
                            : 'actions.contact_enrichment.flyout.failed_hint'}
                    </Localized>
                </ExceptionStateTextStyled>
            </ExceptionStateWrapperStyled>
        );
    });

import { AbortParams } from 'src/data/api/api-client';
import {
    UserAuthenticationStatus,
    UserSettingName,
} from 'src/data/api/graphql/br_user/generated/graphql-sdk';
import {
    ProcessGqlSdk,
    UserGqlSdk,
} from 'src/data/api/graphql/graphql-client.wrapper';
import {
    mapAccountType,
    mapLicenseType,
} from 'src/domain/models/company/company.model';
import { SettingsValueType } from 'src/domain/models/settings/settings.model';
import { createUser, User } from 'src/domain/models/user/user.model';

interface ChangeUserSettingParams extends AbortParams {
    setting: UserSettingName;
    value: SettingsValueType;
}

interface ChangePasswordParams extends AbortParams {
    password: string;
    new_password: string;
    repeat_new_password: string;
}

interface ImpersonateUserParams extends AbortParams {
    email: string;
}
type UserWithHubSpotToken = {
    status: UserAuthenticationStatus.Success;
    user: User;
    hubspotToken?: string;
};

type FailedUserLogin = {
    status:
        | UserAuthenticationStatus.UserInactive
        | UserAuthenticationStatus.InvalidCredentials;
};

export type UserAuthResponse = UserWithHubSpotToken | FailedUserLogin;
export interface UsersApi {
    getCurrentUser: (params: AbortParams) => Promise<UserWithHubSpotToken>;
    changeUserSetting: (params: ChangeUserSettingParams) => Promise<boolean>;
    changePassword: (params: ChangePasswordParams) => Promise<boolean>;
    impersonateUser: (
        params: ImpersonateUserParams,
    ) => Promise<UserWithHubSpotToken>;
}

export const createUsersApi = (
    processGqlSdk: ProcessGqlSdk,
    userGqlSdk: UserGqlSdk,
): UsersApi => {
    const getCurrentUser = async ({
        signal,
    }: AbortParams): Promise<UserWithHubSpotToken> => {
        const rawUser = await userGqlSdk.GetCurrentUser(undefined, { signal });
        const response = await processGqlSdk.GetMyHubSpotToken();
        const { hubspotToken } = response.me;
        const companyType = await processGqlSdk.GetCompanyTypes();
        const { accountType, licenseType } = companyType.company;
        return {
            status: UserAuthenticationStatus.Success,
            user: createUser(
                rawUser.currentUser,
                mapAccountType[accountType],
                mapLicenseType[licenseType],
            ),
            hubspotToken,
        };
    };

    const changeUserSetting = async ({
        setting,
        value,
        signal,
    }: ChangeUserSettingParams): Promise<boolean> => {
        const response = await userGqlSdk.updateUserSetting(
            { setting, value },
            { signal },
        );
        return response.updateUserSetting.ok;
    };

    const changePassword = async ({
        password,
        new_password,
        repeat_new_password,
        signal,
    }: ChangePasswordParams) => {
        const { updatePassword } = await userGqlSdk.UpdatePassword(
            {
                oldPassword: password,
                newPassword: new_password,
                repeatNewPassword: repeat_new_password,
            },
            { signal },
        );
        return updatePassword.ok;
    };

    const impersonateUser = async ({
        email,
        signal,
    }: ImpersonateUserParams): Promise<UserWithHubSpotToken> => {
        const response = await userGqlSdk.ImpersonateUser(
            { email },
            { signal },
        );
        const companyType = await processGqlSdk.GetCompanyTypes();
        const { accountType, licenseType } = companyType.company;
        return {
            status: UserAuthenticationStatus.Success,
            user: createUser(
                response.adminImpersonateUser.user,
                mapAccountType[accountType],
                mapLicenseType[licenseType],
            ),
        };
    };

    return {
        getCurrentUser,
        changeUserSetting,
        changePassword,
        impersonateUser,
    };
};

import { BrSelect } from '@buildingradar/br_component_lib';
import { observer } from 'mobx-react';
import { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { DealContactPerson } from 'src/app-features/contact/data/model/deal-contacts.model';
import { getFullName } from 'src/domain/models/user/user.model';
import { ActionPanelLabelStyled } from 'src/presentation/modules/enablement-actions-panel/actions/styled';
import { EnrichmentIterateStateParams } from 'src/app-features/contact-enrichment/domain/contact-enrichment.feature';

interface EnrichmentContactComponentProps {
    isEnrichmentLoading: boolean;
    contact?: DealContactPerson;
    contacts: DealContactPerson[];
    setTargetContact: (contact?: DealContactPerson) => void;
    clean: () => void;
    iterateState: (params?: EnrichmentIterateStateParams) => void;
}
export const EnrichmentContactSelectComponent: FC<EnrichmentContactComponentProps> =
    observer(
        ({
            isEnrichmentLoading,
            contact,
            contacts,
            setTargetContact,
            iterateState,
            clean,
        }) => {
            if (!contact) {
                return null;
            }
            const { t } = useTranslation();
            const options = useMemo(() => {
                return contacts.map(({ id, firstName, lastName }) => ({
                    value: id,
                    label: getFullName(firstName, lastName),
                }));
            }, [contacts]);
            const onSelectionChange = useCallback(
                (id?: string) => {
                    setTargetContact(
                        contacts.find((contact) => contact.id === id),
                    );
                    clean();
                    iterateState();
                },
                [clean, contacts, iterateState, setTargetContact],
            );
            return (
                <>
                    <ActionPanelLabelStyled>
                        {t('actions.contact_enrichment.input.contact_label')}
                    </ActionPanelLabelStyled>
                    <BrSelect
                        disabled={isEnrichmentLoading}
                        selectedValue={contact.id}
                        options={options}
                        fullWidth
                        onSelectionChange={onSelectionChange}
                        margin="none"
                    />
                </>
            );
        },
    );

import { Currency } from 'src/domain/models/currency-value/currency-value.model';
import { Language } from 'src/domain/models/locale/locale.model';
import {
    formatNumberRangeByLanguage,
    NumberRange,
} from 'src/utils/number.utils';

import { DocumentType } from './document.model';

type RangeAttributeType = 'gfa' | 'volumeReference' | 'nFloors' | 'height';

const GFA_RANGES: NumberRange[] = [
    { lower: 500, upper: 1000 },
    { lower: 1000, upper: 2000 },
    { lower: 2000, upper: 3000 },
    { lower: 3000, upper: 4000 },
    { lower: 4000, upper: 5000 },
    { lower: 5000, upper: 10000 },
];

const VOLUME_RANGES: NumberRange[] = [
    { lower: 0, upper: 200000 },
    { lower: 200000, upper: 1000000 },
    { lower: 1000000, upper: 5000000 },
    { lower: 5000000, upper: 10000000 },
    { lower: 10000000, upper: 20000000 },
    { lower: 20000000, upper: 50000000 },
    { lower: 50000000, upper: 100000000 },
];

const HEIGHT_RANGES: NumberRange[] = [
    { lower: 10, upper: 20 },
    { lower: 20, upper: 50 },
    { lower: 50, upper: 100 },
];

const FLOORS_RANGES: NumberRange[] = [
    { lower: 2, upper: 4 },
    { lower: 5, upper: 10 },
    { lower: 11, upper: 20 },
];

export const getRangeAttribute = (
    value: number,
    type: RangeAttributeType,
    language: Language,
    currency?: Currency,
): string | undefined => {
    let unit = '';
    let ranges: NumberRange[] = [];
    let formatterOptions: Intl.NumberFormatOptions = { notation: 'standard' };

    switch (type) {
        case 'gfa':
            unit = 'm\u00B2';
            ranges = GFA_RANGES;
            break;
        case 'nFloors':
            ranges = FLOORS_RANGES;
            break;
        case 'height':
            unit = 'm';
            ranges = HEIGHT_RANGES;
            break;
        case 'volumeReference':
            ranges = VOLUME_RANGES;
            formatterOptions = { notation: 'compact', compactDisplay: 'short' };
            if (currency === Currency.USD) {
                unit = ' $';
            } else {
                unit = ' €';
            }
            break;
    }

    const formattedRange = formatNumberRangeByLanguage(
        value,
        ranges,
        language,
        formatterOptions,
    );
    if (formattedRange === undefined) {
        return undefined;
    }
    return formattedRange + unit;
};

/**
 * Helper function to get document type from document id by using a defined separator.
 * @param documentId Eg.: item_id:|:article:|:the new airbus helicopter's logistics hub in spain will be the international amazon of parts of the company:|:2023:|:12:|:7
 * @returns The enum for the document type.
 */
export const getDocumentTypeFromId = (documentId: string): DocumentType => {
    const ID_SEPARATOR = ':|:';
    const [, type] = documentId.split(ID_SEPARATOR);

    switch (type) {
        case 'article':
            return DocumentType.Article;
        case 'tender':
            return DocumentType.Tender;
        default:
            throw new Error(`Unknown document type: ${type}`);
    }
};

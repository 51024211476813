export const hasScroll = (element: HTMLElement | null | undefined): boolean =>
    element ? element.scrollHeight > element.clientHeight : false;

export const isScrolledToBottom = (element: HTMLElement, offset = 0): boolean =>
    element.scrollTop + element.clientHeight >= element.scrollHeight - offset;

export const isScrolledToTop = (element: HTMLElement, offset = 0): boolean =>
    element.scrollTop <= offset;

/**
 * peform element scrolling inside its parent
 * @param element
 * @param offset
 */
export const scrollElementIntoView = (element: HTMLElement, offset = 0) => {
    const parent = element.parentElement;
    if (!parent) {
        return;
    }

    const elementTop = element.offsetTop;
    const offsetPosition = elementTop - offset;

    parent.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
    });
};

export const isElementTopInViewport = (
    element: HTMLElement,
    offsetTop = 0,
    offsetBottom = 0,
) => {
    const rect = element.getBoundingClientRect();
    return (
        rect.top - offsetTop >= 0 &&
        rect.top + offsetBottom <=
            (window.innerHeight ?? document.documentElement.clientHeight)
    );
};

export const scrollToElement = (element: HTMLElement, offset = 0) => {
    const elementPosition = element.getBoundingClientRect().top;
    const offsetPosition = elementPosition + window.scrollY - offset;
    window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
    });
};

export const getRoot = () => document.getElementById('root');

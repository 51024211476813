import {
    BrSelect,
    MenuItemConfiguration,
} from '@buildingradar/br_component_lib';
import { observer } from 'mobx-react';
import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { TextInput } from 'src/presentation/shared/text-input/text-input';

interface XRayQuestionComponentProps {
    options: MenuItemConfiguration[];
    selectedOption: string;
    customQuestion: string;
    askWizardHandler: () => void;
    setSelectedOption: (selectedOption: string) => void;
    setCustomQuestion: (customQuestion: string) => void;
    setXRayActionAnswer: (answer: string[] | null) => void;
}

export const XRayQuestionComponent: FC<XRayQuestionComponentProps> = observer(
    ({
        options,
        selectedOption,
        customQuestion,
        askWizardHandler,
        setSelectedOption,
        setCustomQuestion,
        setXRayActionAnswer,
    }) => {
        const { t } = useTranslation();
        const selectQuestionHandler = useCallback(
            (value: string | undefined) => {
                setSelectedOption(value ?? '');
                setCustomQuestion(value ?? '');
                setXRayActionAnswer(null);
            },
            [setCustomQuestion, setSelectedOption, setXRayActionAnswer],
        );

        const onPressEnter = useCallback(() => {
            askWizardHandler();
        }, [askWizardHandler]);

        return (
            <>
                <BrSelect
                    fullWidth
                    selectedValue={selectedOption}
                    options={options}
                    onSelectionChange={selectQuestionHandler}
                    margin="none"
                />
                <TextInput
                    placeholder={t(
                        'actions.xray_helper.xray_helper_question_placeholder',
                    )}
                    value={customQuestion}
                    onChange={setCustomQuestion}
                    onPressEnter={onPressEnter}
                    minRows={3}
                    disableLineBreaks={true}
                    hasPermanentBorder
                />
            </>
        );
    },
);

import { css } from '@emotion/react';

import { DealHeaderStickyWrapperStyled } from 'src/presentation/modules/deal-view/components/deal-header-sticky/deal-header-sticky.styled';
import { PrimaryNavbarStyled } from 'src/presentation/modules/primary-nav-bar/primary-nav-bar.styled';
import { GeneralSearchWrapperStyled } from 'src/presentation/modules/project-lookup/project-lookup.styled';
import { ContentWrapperStyled } from 'src/presentation/modules/session/session-layout.styled';
import { SingleLeadWrapperStyled } from 'src/presentation/modules/single-lead-page/single-lead-page.styled';
import { SecondaryNavBarStyled } from 'src/presentation/shared/secondary-navbar-layout/secondary-navbar-layout.styled';

export const ImpersonationHeaderHeight = '64px';

/**
 * Fixes full viewport height UI elements
 * when impersonation header is visible
 * ie. Drawers, Navbar
 */
export const impersonationStyles = css`
    .MuiDrawer-root .MuiPaper-root {
        height: calc(100% - ${ImpersonationHeaderHeight}) !important;
        top: ${ImpersonationHeaderHeight} !important;
    }

    ${PrimaryNavbarStyled} {
        top: ${ImpersonationHeaderHeight} !important;
        height: calc(100vh - ${ImpersonationHeaderHeight}) !important;
    }

    ${ContentWrapperStyled} {
        min-height: calc(100vh - ${ImpersonationHeaderHeight}) !important;
    }

    ${SecondaryNavBarStyled}, ${SingleLeadWrapperStyled}, ${GeneralSearchWrapperStyled} {
        height: calc(100vh - ${ImpersonationHeaderHeight}) !important;
    }

    ${DealHeaderStickyWrapperStyled} {
        top: ${ImpersonationHeaderHeight} !important;
    }
`;

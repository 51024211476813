import { OutreachBlockType as SdkOutreachBlockType } from 'src/data/api/graphql/br_process/generated/graphql-sdk';
import { ProcessGqlSdk } from 'src/data/api/graphql/graphql-client.wrapper';

interface UpsertOutreachBlockParams {
    id?: string;
    title: string;
    text: string;
    type: SdkOutreachBlockType;
    dealId: string;
    vpoSessionId: string;
}

interface DeleteOutreachBlockParams {
    id: string;
    dealId: string;
    vpoSessionId: string;
}

export interface IPromptBlockApi {
    upsertOutreachBlock: (params: UpsertOutreachBlockParams) => Promise<string>;
    deleteOutreachBlock: (
        params: DeleteOutreachBlockParams,
    ) => Promise<boolean>;
}

export const createPromptBlockApi = (
    processGqlSdk: ProcessGqlSdk,
): IPromptBlockApi => {
    const upsertOutreachBlock = async ({
        id,
        title,
        text,
        type,
        dealId,
        vpoSessionId,
    }: UpsertOutreachBlockParams) => {
        if (!id) {
            return (
                await processGqlSdk.CreateOutreachBlock({
                    dealId,
                    vpoSessionId,
                    outreachBlock: { title, text, type },
                })
            ).createOutreachBlock.id;
        } else {
            await processGqlSdk.UpdateOutreachBlock({
                dealId,
                vpoSessionId,
                outreachBlock: { id, title, text },
            });

            return id;
        }
    };

    const deleteOutreachBlock = async ({
        id,
        dealId,
        vpoSessionId,
    }: DeleteOutreachBlockParams) => {
        const response = await processGqlSdk.DeleteOutreachBlock({
            dealId,
            vpoSessionId,
            outreachBlockId: id,
        });

        return response.deleteOutreachBlock.ok;
    };

    return {
        upsertOutreachBlock,
        deleteOutreachBlock,
    };
};

import { BrIcon, BrInfotip, IconName } from '@buildingradar/br_component_lib';
import { IconButton } from '@mui/material';
import { observer } from 'mobx-react';
import { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { Link } from 'src/presentation/shared/link/link.component';

import {
    CardWrapperStyled,
    CardHeaderBadgeWrapperStyled,
    CardDetailsStyled,
    CardSubtitleStyled,
    CardTitleStyled,
    WhenStyled,
} from './notification-card.styled';

interface NotificationCardProps {
    linkUrl: string;
    headerBadge: ReactNode;
    title: string;
    subTitle: string;
    when: string;
    onLinkClickedHandler: () => void;
    onEdit?: () => void;
    onDismiss?: () => void;
}

export const NotificationCard: FC<NotificationCardProps> = observer(
    ({
        linkUrl,
        headerBadge,
        title,
        subTitle,
        when,
        onLinkClickedHandler,
        onEdit,
        onDismiss,
    }) => {
        const { t } = useTranslation();

        return (
            <CardWrapperStyled className="notification-card">
                <Link
                    onClick={onLinkClickedHandler}
                    href={linkUrl}
                    className="link"
                >
                    <CardHeaderBadgeWrapperStyled>
                        {headerBadge}
                    </CardHeaderBadgeWrapperStyled>
                    <CardDetailsStyled>
                        <CardTitleStyled>{title}</CardTitleStyled>
                        <CardSubtitleStyled>{subTitle}</CardSubtitleStyled>
                        <WhenStyled>{when}</WhenStyled>
                    </CardDetailsStyled>
                </Link>
                <>
                    {onEdit && (
                        <BrInfotip content={t('common.edit')} placement="top">
                            <IconButton onClick={onEdit}>
                                <BrIcon iconName={IconName.BrEditOutlineIcon} />
                            </IconButton>
                        </BrInfotip>
                    )}
                    {onDismiss && (
                        <BrInfotip
                            content={t('common.dismiss')}
                            placement="top"
                        >
                            <IconButton onClick={onDismiss}>
                                <BrIcon
                                    iconName={IconName.BrDeleteOutlineIcon}
                                />
                            </IconButton>
                        </BrInfotip>
                    )}
                </>
            </CardWrapperStyled>
        );
    },
);

/// to take type `T`, and make its key `K` and optional parameter (from `K: PropType` to `K: PropType | undefined`)
export type Optional<T, K extends keyof T> = Pick<Partial<T>, K> & Omit<T, K>;

export interface KeyValuePair<T> {
    key: string;
    value: T;
    additional?: string;
}

export const isNotEmpty = <T extends string | number>(
    value: T | null | undefined,
): boolean => {
    if (value === null || value === undefined) {
        return false;
    }

    if (typeof value === 'string' && value.trim() === '') {
        return false;
    }

    return true;
};

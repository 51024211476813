export enum KeyCodes {
    Enter = 13,
    Esc = 27,
}

export enum KeyNames {
    Enter = 'Enter',
    NumpadEnter = 'NumpadEnter',
    Esc = 'Escape',
}

import { useCallback, useMemo } from 'react';
import { UseFormReturn, useForm } from 'react-hook-form';

import {
    useDealContactsManageFeature,
    useDealViewFeature,
    useStageObjectivesFeature,
} from 'src/app-context/use-features';
import {
    DealContactCompanyForm,
    DealContactPersonForm,
    DealContactUnionForm,
} from 'src/app-features/contact/data/model/deal-contacts-form.model';
import {
    DealContactCompany,
    DealContactPerson,
    DealContactToEdit,
    DealContactType,
} from 'src/app-features/contact/data/model/deal-contacts.model';
import { buildUpdateObject } from 'src/app-features/contact/presentation/contact-panel/contact-detail-info-panel.utils';
import { MixpanelEventLocation } from 'src/data/services/mixpanel/mixpanel.model';
import { SuccessState } from 'src/domain/models/success-state/success-state.model';
import { FormConfig } from 'src/presentation/shared/form-control/form.model';
import { isNotEmpty } from 'src/utils/string.utils';

import {
    GetValuesFn,
    buildContactFormConfig,
    getDefaultContactFormData,
} from './form-config';
import { mapDealContactToForm } from './form-mapper';

const NonEmptyContactFields = ['firstName', 'name', 'lastName'];
const checkContactFormError = async (
    formApi: UseFormReturn<DealContactUnionForm, any, undefined>,
    isPerson: boolean,
    onErrorCheckIntercept?: (
        hasError: boolean,
        formApi: UseFormReturn<DealContactUnionForm, any, undefined>,
    ) => boolean,
    field?: string,
) => {
    const { trigger, getValues } = formApi;
    /**
     * unfortunately errors only give you result from the previous time
     * the validation is triggered. and when is that? I have no idea.
     */

    const shouldCheckRequiredFields =
        !field || NonEmptyContactFields.includes(field);

    /* why is this conditional? because the update function uses the same error checking function.
     * if one of the required field is set to empty, any edits made to other fields will not be
     * saved because it won't pass the check.
     */

    let isValid = false;
    if (shouldCheckRequiredFields) {
        /**
         *   you can force trigger a validation with this, BUT if you're in notes Tab,
         *   it will not update the input fields states from the other tab.
         *   AND when it returns `false` it means it has errors. (why? who decided?)
         *  (even official api doc doesn't tell you what the return result means. )
         */
        const forceCheck = !(await trigger(isPerson ? 'firstName' : 'name', {
            shouldFocus: true,
        }));
        /**
         *  Think you're safe? Absolutely not. apparently when you don't interact with inputs at all
         *  and directly press `create`, even `trigger` doesn't validate the form correctly.
         *  So it's up to you to manually check the form. Honestly what kind of bug is this??
         */
        const areRequiredFieldsValid = !(isPerson
            ? isNotEmpty(getValues('firstName')) ||
              isNotEmpty(getValues('lastName'))
            : isNotEmpty(getValues('name')));

        isValid = forceCheck || areRequiredFieldsValid;
    }

    if (onErrorCheckIntercept) {
        return onErrorCheckIntercept(isValid, formApi);
    }
    return isValid;
};

export const useContactForm = ({
    dealId,
    selectedContact,
    customConfig,
    onCreationDone,
    onErrorCheckIntercept,
    onUpdateDone,
}: UseContactFormParams) => {
    const {
        findContactById,
        allDealContactCompanies: companies,
        contactPersonCustomFields: personCustomFields,
        contactCompanyCustomFields: companyCustomFields,
        updateDealContactCompany: updateContactCompany,
        updateDealContactPerson: updateContactPerson,
        createDealContactCompany: createContactCompany,
        createDealContactPerson: createContactPerson,
    } = useDealContactsManageFeature();
    const { objectivesMap, updateObjective } = useStageObjectivesFeature();
    const { dealsMap } = useDealViewFeature();
    const deal = dealsMap.get(dealId);
    if (!deal) {
        throw new Error(
            'Failed to create contact form: cannot find deal associated with dealId',
        );
    }
    const isEditMode = !!selectedContact && !!selectedContact.id;
    const isPerson = selectedContact.type === DealContactType.person;
    const defaultData: DealContactPersonForm | DealContactCompanyForm =
        getDefaultContactFormData(
            isPerson,
            personCustomFields,
            companyCustomFields,
            selectedContact.role,
            isPerson ? selectedContact.relevantCompanyId : undefined,
        );

    const formApi = useForm<DealContactUnionForm>({
        defaultValues: selectedContact.id
            ? mapDealContactToForm(selectedContact)
            : defaultData,
    });

    const formConfig = useMemo(
        () =>
            buildContactFormConfig(
                isPerson,
                companies,
                personCustomFields,
                companyCustomFields,
                formApi.getValues,
                customConfig,
            ),
        [
            isPerson,
            companies,
            personCustomFields,
            companyCustomFields,
            formApi.getValues,
            customConfig,
        ],
    );

    const onSubmitCreation = useCallback(
        (contactForm: DealContactPersonForm | DealContactCompanyForm) => {
            contactForm.isKey = true;
            deal?.objectivesIds.forEach((objectiveId) => {
                const linkedFieldName =
                    objectivesMap.get(objectiveId)?.linkedField?.name;
                if (linkedFieldName && linkedFieldName === contactForm.role) {
                    updateObjective(
                        objectiveId,
                        { state: SuccessState.success },
                        deal.id,
                        MixpanelEventLocation.SegmentControl,
                    );
                }
            });
            if (isPerson) {
                createContactPerson(
                    contactForm as DealContactPersonForm,
                    deal.id,
                );
            } else {
                createContactCompany(
                    contactForm as DealContactCompanyForm,
                    deal.id,
                );
            }
            onCreationDone?.();
        },
        [
            createContactCompany,
            createContactPerson,
            deal.id,
            deal?.objectivesIds,
            isPerson,
            objectivesMap,
            onCreationDone,
            updateObjective,
        ],
    );

    const onCreateContact = useCallback(async () => {
        if (!deal) {
            throw new Error(
                'Cannot find the deal that user is attempting to create an contact with.',
            );
        }
        if (
            await checkContactFormError(
                formApi,
                isPerson,
                onErrorCheckIntercept,
            )
        ) {
            return;
        }
        formApi.handleSubmit(onSubmitCreation)();
    }, [deal, formApi, isPerson, onErrorCheckIntercept, onSubmitCreation]);

    const onUpdateSubmit = useCallback(
        (field: string, value: string) => {
            const contact = findContactById(
                selectedContact.id!,
                selectedContact.type,
            );
            const updateObject = buildUpdateObject(
                contact ?? selectedContact,
                field,
                value,
            );
            if (Object.keys(updateObject).length > 0) {
                // the '!' is checked by 'isEditMode'
                isPerson
                    ? updateContactPerson(
                          deal.id,
                          selectedContact.id!,
                          updateObject,
                          { field },
                          onUpdateDone,
                      )
                    : updateContactCompany(
                          deal.id,
                          selectedContact.id!,
                          updateObject,
                          { field },
                          onUpdateDone,
                      );
            }
        },
        [
            deal.id,
            findContactById,
            isPerson,
            onUpdateDone,
            selectedContact,
            updateContactCompany,
            updateContactPerson,
        ],
    );
    const onUpdateContactField = useCallback(
        async (field: string, value: string) => {
            if (
                !isEditMode ||
                (await checkContactFormError(
                    formApi,
                    isPerson,
                    onErrorCheckIntercept,
                    field,
                ))
            ) {
                return;
            }
            /// This one is done to ensure that: even if the required field is temporarily empty (thus invalid form),
            //  the other fields can still get saved
            if (!NonEmptyContactFields.includes(field)) {
                formApi.clearErrors();
            }
            await formApi.trigger(isPerson ? 'firstName' : 'name', {
                shouldFocus: true,
            });
            onUpdateSubmit(field, value);
        },
        [formApi, isEditMode, isPerson, onErrorCheckIntercept, onUpdateSubmit],
    );

    return {
        isEditMode,
        isPerson,
        formApi,
        formConfig,
        onCreateContact,
        onUpdateContactField,
    };
};

interface UseContactFormParams {
    dealId: string;
    selectedContact: DealContactToEdit;
    customConfig?:
        | FormConfig<DealContactPersonForm>
        | FormConfig<DealContactCompanyForm>
        | ((
              getValues: GetValuesFn,
          ) =>
              | FormConfig<DealContactPersonForm>
              | FormConfig<DealContactCompanyForm>);
    onCreationDone?: () => void;
    onErrorCheckIntercept?: (
        hasError: boolean,
        formApi: UseFormReturn<DealContactUnionForm, any, undefined>,
    ) => boolean;
    onUpdateDone?: (contact: DealContactPerson | DealContactCompany) => void;
}

import styled from '@emotion/styled';

import { Link } from 'src/presentation/shared/link/link.component';
import { MediaQuery } from 'src/resources/styles/media.mixins';
import { textEllipsisMixin } from 'src/resources/styles/mixins';

export const StyledFolderCounter = styled.div<{ isActive: boolean }>`
    color: ${({ isActive }) =>
        isActive ? 'var(--accent-link)' : 'var(--text-disabled)'};
    opacity: ${({ isActive }) => (isActive ? '1' : '0')};
    transition: opacity 0.3s ease-out;

    ${MediaQuery.tablet} {
        opacity: 1;
        color: ${({ isActive }) =>
            isActive ? 'var(--accent-link)' : 'var(--main-font)'};
    }
`;

export const StyledTagItemStyled = styled.li<{ isActive: boolean }>`
    color: ${({ isActive }) =>
        isActive ? 'var(--accent-link)' : 'var(--text-disabled)'};
    list-style: none;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.4px;
    margin-top: 6px;

    &:hover {
        color: var(--accent-link);
    }

    &:hover ${StyledFolderCounter} {
        color: var(--accent-link);
    }
`;

export const StyledSearchTagLink = styled(Link)`
    padding-left: 17px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
`;

export const StyledSearchTag = styled.div`
    min-height: 32px;
    font-size: 14px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const StyledSearchTagName = styled.div<{ isActive: boolean }>`
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    ${MediaQuery.tablet} {
        margin-left: 10px;
        color: ${({ isActive }) =>
            isActive ? 'var(--accent-link)' : 'var(--main-font)'};
        max-width: 190px;
        ${textEllipsisMixin};
    }
`;

export class WsSubscriptionClosedError extends Error {
    code: number;
    reason: string;
    jsonError: string;
    closedGracefully: boolean;

    constructor(message: string, closeEvent: CloseEvent, jsonError: string) {
        super(message);

        this.code = closeEvent.code;
        this.reason = closeEvent.reason;
        this.closedGracefully = closeEvent.wasClean;
        this.jsonError = jsonError;

        Error.captureStackTrace(this, this.constructor);
    }
}

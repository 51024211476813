import {
    DealActivity,
    DealActivityType,
    OutreachActivityType,
} from 'src/app-features/deal-activity/domain/deal-activity.model';
import {
    DealAlertQl,
    DealAnnualSalesPeriodFragment,
    DealPartFragment,
    DealQuarterlySalesPeriodFragment,
    DealState as RawDealState,
    MinimalDealPartFragment,
    PipelineDealPartFragment,
    ProjectPartFragment,
    SalesPeriodFrequency,
    PipelineStatisticsDealPartFragment,
    ActivityLogPartFragment,
    ActivityLogType,
} from 'src/data/api/graphql/br_process/generated/graphql-sdk';
import {
    Comment,
    createComment,
} from 'src/domain/models/comment/comment.model';
import {
    Currency,
    CurrencyValue,
} from 'src/domain/models/currency-value/currency-value.model';
import {
    createCustomParameter,
    CustomParameter,
} from 'src/domain/models/custom-parameter/custom-parameter.model';
import { Reminder } from 'src/domain/models/reminder/reminder.model';
import { pluck } from 'src/utils/object.utils';

////// Simple Project Data

interface ProjectData {
    id: string;
    type?: string;
    title?: string;
}

const createProjectData = (rawProjectData: ProjectPartFragment) => {
    return {
        id: `${rawProjectData.projectId}`,
        type: '',
        title: '',
    };
};

////// Deal

export enum DealAlertType {
    UNASSIGNED = 'UNASSIGNED',
    FAILED_SO = 'FAILED_SO',
    OVERDUE_REMINDER = 'OVERDUE_REMINDER',
    UPCOMING_REMINDER = 'UPCOMING_REMINDER',
    RECENTLY_ADDED_TO_STAGE = 'RECENTLY_ADDED_TO_STAGE',
    READY_TO_REACTIVATE = 'READY_TO_REACTIVATE',
    SLOW_MOVING = 'SLOW_MOVING',
    PAUSED = 'PAUSED',
    HEALTHY = 'HEALTHY',
}

export interface Alert {
    type: DealAlertType;
    currentUserRelated: boolean;
}

export const createDealAlert = (rawAlert: DealAlertQl): Alert => {
    return {
        type: DealAlertType[rawAlert.type],
        currentUserRelated: rawAlert.currentUserRelated ?? false,
    };
};

/**
 * TODO: NEW
 * The new model interface to hold deals of a given pipeline.
 */
export interface PipelineDeal {
    id: string;
    alert: Alert;
    title: string;
    stageId: string;
    comments: Comment[];
    reminders: Reminder[];
    stageUpdatedDate?: Date;
    assigneeId?: number;
    customFields?: CustomParameter[];
    customParameterTagValue?: string | null;
    pauseData: PauseDataType;
    outreachAttemptCount?: number;
    lastOutreachAttemptDate?: Date;
}

export interface MinimalDeal {
    id: string;
    title: string;
    stageId: string;
    dealRevenue: CurrencyValue;
    assignee: number | null;
    exportedDate: DealExportedDate | null;
    createdDate: Date;
    completedDate: Date;
    state: DealState | null;
    pipeline: {
        id: string;
        name: string;
    };
    pauseData: PauseDataType;
    expectedCurrentStageConversionDate: boolean;
    expectedSalesPeriod: string | null;
    alert: Alert;
    reminders: Reminder[];
    comments: Comment[];
    customFields: CustomParameter[];
    project?: ProjectData;
}

export enum DealState {
    inPlay = 'IN_PLAY',
    done = 'DONE',
    disqualified = 'DISQUALIFIED',
}

export const StateModelToRaw: Record<DealState, RawDealState> = {
    [DealState.inPlay]: RawDealState.InPlay,
    [DealState.done]: RawDealState.Done,
    [DealState.disqualified]: RawDealState.Disqualified,
};

const StateRawToModel: Record<RawDealState, DealState> = {
    [RawDealState.InPlay]: DealState.inPlay,
    [RawDealState.Done]: DealState.done,
    [RawDealState.Disqualified]: DealState.disqualified,
};

export interface DealExportedDate {
    csv: Date | null;
    email: Date | null;
}

const createDealExportedDate = (
    exportedDate: { email?: any | null; csv?: any } | null | undefined,
): DealExportedDate => {
    return {
        csv: exportedDate?.csv ?? null,
        email: exportedDate?.email ?? null,
    };
};

export interface Deal {
    id: string;
    title: string;
    dealRevenue: CurrencyValue;
    /**
     * we are going to show only the first project for the first release
     */
    project: ProjectData | null;
    pipeline: {
        id: string;
        name: string;
    };
    state: DealState;
    stageId: string;
    objectivesIds: string[];
    notes: string;
    assigneeId: number | null;
    stageUpdatedDate: Date | null;
    completedDate: Date | null;
    exportedDate: DealExportedDate | null;
    reminders: Reminder[] | null;
    pauseData: PauseDataType;
    alert: Alert;
    outreachActivityLog: DealActivity[];
}

const createCurrency = (rawCurrency: string): Currency => {
    switch (rawCurrency) {
        case Currency.EUR:
        case Currency.USD:
            return rawCurrency;
        default:
            return Currency.EUR;
    }
};

interface PauseDataUnpaused {
    isPaused: false;
    isPausedUntilDatePassed: false;
    pausedUntil: null;
}
type PauseDataPaused = {
    isPaused: true;
    isPausedUntilDatePassed: boolean;
    pausedUntil: Date;
};

type PauseDataType = PauseDataPaused | PauseDataUnpaused;

export const getPauseData = (
    pausedUntilDate: Date | string | undefined | null,
): PauseDataType => {
    if (pausedUntilDate) {
        const pausedUntil = new Date(pausedUntilDate);
        pausedUntil.setHours(0, 0, 0, 0);
        const isPausedUntilDatePassed = pausedUntil <= new Date();
        return {
            isPaused: true,
            pausedUntil,
            isPausedUntilDatePassed,
        };
    } else {
        return {
            isPaused: false,
            pausedUntil: null,
            isPausedUntilDatePassed: false,
        };
    }
};

/**
 * TODO: NEW
 * Converts from a raw graphql PipelineDealPartFragment to a PipelineDeal model interface
 */
export const createPipelineDeal = (
    rawPipelineDeal: PipelineDealPartFragment,
): PipelineDeal => {
    const customFields =
        rawPipelineDeal.customFields?.map(createCustomParameter) ?? [];
    return {
        id: rawPipelineDeal.id,
        alert: createDealAlert(rawPipelineDeal.alert),
        title: rawPipelineDeal.title,
        stageId: rawPipelineDeal.currentStage.id,
        comments: rawPipelineDeal.comments
            ? rawPipelineDeal.comments.map((comment) => createComment(comment))
            : [],
        reminders: rawPipelineDeal.reminders
            ? rawPipelineDeal.reminders.map(
                  (reminder) => new Reminder(reminder),
              )
            : [],
        stageUpdatedDate: rawPipelineDeal.stageUpdatedDate,
        assigneeId: rawPipelineDeal.assignee,
        customFields,
        customParameterTagValue: customFields.find((field) => field.isTag)
            ?.value,
        pauseData: getPauseData(rawPipelineDeal.pausedUntil),
        outreachAttemptCount:
            rawPipelineDeal.outreachLogStats.count ?? undefined,
        lastOutreachAttemptDate:
            rawPipelineDeal.outreachLogStats.lastActivityDate ?? undefined,
    };
};

export const createMinimalDealOfPipeline = (
    rawDeal: PipelineStatisticsDealPartFragment,
    pipeline: { id: string; name: string },
): MinimalDeal => {
    const {
        id,
        title,
        value,
        currentStage,
        assignee,
        state,
        createdDate,
        completedDate,
        pausedUntil,
        expectedCurrentStageConversionDate,
        expectedSalesPeriod,
        alert,
        reminders,
        comments,
        customFields,
        exportedDate,
        project,
    } = rawDeal;

    return {
        id,
        dealRevenue: {
            value: value.value,
            currency: createCurrency(value.currency),
        },
        title,
        stageId: currentStage.id,
        assignee,
        exportedDate: createDealExportedDate(exportedDate),
        createdDate: new Date(createdDate),
        state: StateRawToModel[state],
        pipeline,
        completedDate: new Date(completedDate),
        pauseData: getPauseData(pausedUntil),
        expectedCurrentStageConversionDate,
        expectedSalesPeriod:
            convertSalesPeriodObjectToStringFormat(expectedSalesPeriod),
        alert: createDealAlert(alert),
        reminders: reminders
            ? reminders.map((reminder) => new Reminder(reminder))
            : [],
        comments: comments
            ? comments.map((comment) => createComment(comment))
            : [],
        customFields: customFields
            ? customFields.map((customField) =>
                  createCustomParameter(customField),
              )
            : [],
        project: project?.projectId
            ? { id: project.projectId.toString() }
            : undefined,
    };
};

export const createMinimalDeal = (
    rawMinimalDeal: MinimalDealPartFragment,
): MinimalDeal => {
    return createMinimalDealOfPipeline(rawMinimalDeal, rawMinimalDeal.pipeline);
};

/**
 * Converts a annual/quarterly sales period fragment into a formatted string.
 * @param period is the annual or quarterly object provided by our BE
 * @returns Return a string in the format `Q1-2022` (quarterly) or `2022` (annual)
 */
const convertSalesPeriodObjectToStringFormat = (
    period?:
        | DealAnnualSalesPeriodFragment
        | null
        | DealQuarterlySalesPeriodFragment
        | null,
): string | null => {
    if (!period) {
        return null;
    }

    if (period.frequency === SalesPeriodFrequency.Annually) {
        return period.year.toString();
    }

    return formatQuarterlyPeriod(period as DealQuarterlySalesPeriodFragment);
};

const formatQuarterlyPeriod = (
    period: DealQuarterlySalesPeriodFragment,
): string => {
    return `Q${period.quarter}-${period.year}`;
};

export const createDeal = (rawDeal: DealPartFragment): Deal => {
    const {
        id,
        title,
        value,
        currentStage,
        currentObjectives,
        pipeline,
        project,
        notes,
        assignee,
        stageUpdatedDate,
        state,
        completedDate,
        exportedDate,
        reminders,
        pausedUntil,
        alert,
        activityLog,
    } = rawDeal;
    return {
        id,
        dealRevenue: {
            value: value.value,
            currency: createCurrency(value.currency),
        },
        title,
        objectivesIds: currentObjectives
            ? currentObjectives.map(pluck('id'))
            : [],
        stageId: currentStage.id,
        project: project ? createProjectData(project) : null,
        pipeline: {
            name: pipeline.name,
            id: pipeline.id,
        },
        notes: notes ?? '',
        assigneeId: assignee === UNASSIGNED_USER_ID ? null : assignee,
        stageUpdatedDate: stageUpdatedDate ? new Date(stageUpdatedDate) : null,
        state: StateRawToModel[state],
        completedDate: completedDate ? new Date(completedDate) : null,
        exportedDate: createDealExportedDate(exportedDate),
        reminders: reminders
            ? reminders.map((reminder) => new Reminder(reminder))
            : null,
        pauseData: getPauseData(pausedUntil),
        alert: createDealAlert(alert),
        outreachActivityLog: activityLog
            .filter(({ type }) => type === ActivityLogType.OutreachAttempt)
            .map(createActivityLog),
    };
};

export const UNASSIGNED_USER_ID = 0;

export enum DealOpenSource {
    DealTable = 'Deal Table',
    DealBox = 'Deal Box',
    DealTableComments = 'Deal Table - Comments',
    NotificationsMentions = 'Notifications - mentions',
    NotificationsNextSteps = 'Notifications - next steps',
    DealTableNextSteps = 'Deal Table - Next Steps',
    CreatedDealToaster = 'Created deal toaster',
    DirectLink = 'Direct link',
    OmniSearch = 'Omni Search',
}

export function createActivityLog(
    activityLog: ActivityLogPartFragment,
): DealActivity {
    return {
        id: activityLog.id,
        companyBid: activityLog.companyBid,
        createdDate: new Date(activityLog.createdDate),
        updatedDate: new Date(activityLog.updatedDate),
        userId: activityLog.userId,
        type: activityLog.type.toString() as DealActivityType,
        notes: activityLog.notes ?? undefined,
        contactId: activityLog.contactId ?? undefined,
        relevantCompanyId: activityLog.relevantCompanyId ?? undefined,
        subType:
            (activityLog.subType?.toString() as OutreachActivityType) ??
            OutreachActivityType.OTHER,
        customType: activityLog.customType ?? undefined,
    };
}

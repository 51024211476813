import { Box, styled } from '@mui/material';

export const UserAssignmentStyled = styled(Box, {
    shouldForwardProp: (prop) => prop !== 'fullWidth',
})<{
    fullWidth: boolean;
}>`
    display: flex;
    align-items: center;
    ${({ fullWidth }) => (fullWidth ? 'width:100%;' : '')}
    gap: 8px,
`;

import { BrTextInputStatus } from '@buildingradar/br_component_lib';
import styled from '@emotion/styled';
import { Box } from '@mui/material';

export const DealContactFormFieldListStyled = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    width: '100%',
    gap: '8px',
    padding: '8px 0px',
});

export const DealContactNoteFormStyled = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    width: '100%',
    gap: '8px',
    padding: '8px',
    marginTop: '16px',
    height: '100%',
    position: 'relative',
});
export const DealContactNoteStatusStyled = styled(Box)({
    position: 'absolute',
    top: '28px',
    right: '18px',
});
export const DealContactFormFieldWrapperStyled = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    width: '100%',
});

export const DealContactFormLabelStyled = styled(Box, {
    shouldForwardProp: (prop) => prop !== 'required',
})<{ required: boolean }>(({ required }) => ({
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '24px',
    height: '40px',
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    '&:after': required
        ? {
              content: '"*"',
              color: 'var(--coral-red)',
              fontWeight: 'normal',
              marginLeft: '5px',
          }
        : undefined,
    marginBottom: 0,
}));

export const NotesFieldWrapperStyled = styled(Box, {
    shouldForwardProp: (prop) => prop !== 'isHighlighted',
})<{ isHighlighted?: boolean }>(({ isHighlighted = false }) => ({
    width: '100%',
    '& .MuiFormControl-root': {
        '& .MuiInputBase-root': {},
    },
}));

export const ContactNotesPreviewStyled = styled(Box, {
    shouldForwardProp: (prop) => prop !== 'isHighlighted' && prop !== 'status',
})<{ isHighlighted?: boolean; status?: BrTextInputStatus }>(
    ({ isHighlighted = false, status = 'idle' }) => ({
        fontWeight: '400',
        fontSize: '1rem',
        lineHeight: '1.4375em',
        letterSpacing: '0.00938em',
        color: 'var(--gray-70)',
        boxSizing: 'border-box',
        cursor: 'text',
        width: '100%',
        height: '410px',
        overflowY: 'clip',
        position: 'relative',
        padding: '8.5px 14px',
        flex: 1,
        boxShadow: isHighlighted
            ? '0px 0px 9px 0px rgb(84 85 251 / 20%)'
            : undefined,
        wordBreak: 'break-word',
        paddingRight: '22px',

        ':after': {
            content: '""',
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            borderWidth: '1px',
            borderStyle: 'solid',
            borderRadius: '4px',
            borderColor: (() => {
                switch (status) {
                    case 'progress':
                        return 'var(--blue-60)';
                    case 'success':
                        return 'var(--green-30)';
                    default:
                        return isHighlighted
                            ? 'var(--blue-30)'
                            : 'var(--gray-20)';
                }
            })(),
            transition: 'border-color 0.3s',
        },

        ':hover': {
            ':after': {
                borderColor: status === 'idle' ? 'var(--gray-30)' : undefined,
            },
        },
    }),
);

import { BrButton, BrButtonVariant } from '@buildingradar/br_component_lib';
import { observer } from 'mobx-react';
import { FC, RefObject, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import {
    DealContactToEdit,
    DealContactType,
    DealContactUnionType,
} from 'src/app-features/contact/data/model/deal-contacts.model';
import { createContactClipboardContent } from 'src/app-features/contact/domain/deal-contacts-manage.utils';
import { ToasterMessage } from 'src/domain/features/toaster/toaster.utils';
import { copyValueToClipboard } from 'src/utils/string.utils';

interface ContactPanelActionButtonsComponentProps {
    closeButtonRef: RefObject<HTMLButtonElement>;
    selectedContact: DealContactToEdit;
    findContactById: (
        id: string,
        type: DealContactType,
    ) => DealContactUnionType | undefined;
    showMessage: (message: ToasterMessage) => void;
    onCreateContact: () => Promise<void>;
    onCloseHandler: () => void;
}
export const ContactPanelActionButtonsComponent: FC<ContactPanelActionButtonsComponentProps> =
    observer(
        ({
            closeButtonRef,
            selectedContact,
            onCloseHandler,
            showMessage,
            findContactById,
            onCreateContact,
        }) => {
            const { t } = useTranslation();
            const isEditMode = !!selectedContact && !!selectedContact.id;
            const isPerson = selectedContact.type === DealContactType.person;

            const onCopy = useCallback(() => {
                copyValueToClipboard(
                    createContactClipboardContent(
                        findContactById(
                            selectedContact.id!,
                            selectedContact.type,
                        ),
                        t,
                    ),
                );
                showMessage({ title: t('common.copied_to_clipboard') });
            }, [
                findContactById,
                selectedContact.id,
                selectedContact.type,
                showMessage,
                t,
            ]);

            return (
                <>
                    {isEditMode && (
                        <BrButton
                            fullWidth
                            variant={BrButtonVariant.outlined}
                            onClick={onCopy}
                        >
                            {t('contact_panel.actions.copy_all')}
                        </BrButton>
                    )}
                    {!isEditMode && (
                        <BrButton fullWidth onClick={onCreateContact}>
                            {isPerson
                                ? t('contact_panel.actions.create_person')
                                : t('contact_panel.actions.create_company')}
                        </BrButton>
                    )}
                    <BrButton
                        ref={closeButtonRef}
                        variant={BrButtonVariant.outlined}
                        fullWidth
                        onClick={onCloseHandler}
                    >
                        {t('common.close')}
                    </BrButton>
                </>
            );
        },
    );

import { MentionPartFragment } from 'src/data/api/graphql/br_process/generated/graphql-sdk';

export interface Mention {
    creatorId: number;
    deal: {
        id: string;
        title: string;
    };
    updatedDate: Date;
}

export const createMention = (rawData: MentionPartFragment): Mention => {
    const { creatorBid, deal, updatedDate } = rawData;
    return {
        creatorId: creatorBid,
        deal: {
            id: deal.id,
            title: deal.title,
        },
        updatedDate: new Date(updatedDate),
    };
};

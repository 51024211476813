import {
    IntegrationPartFragment,
    IntegrationType as RawIntegrationType,
    IntegrationProvider as RawIntegrationProvider,
    EntitySyncState as RawEntitySyncState,
    CrmStatePartFragment,
} from 'src/data/api/graphql/br_process/generated/graphql-sdk';

export enum IntegrationType {
    Crm = 'CRM',
    DummyCrm = 'DUMMYCRM',
}

export enum IntegrationProvider {
    Dynamics = 'DYNAMICS',
    FakeCrm = 'FAKE_CRM',
    Salesforce = 'SALESFORCE',
    ExternalUrl = 'EXTERNAL_URL',
}

export interface Integration {
    id: string;
    name: string;
    integrationType: IntegrationType;
    driver: string;
    provider: IntegrationProvider;
    updatedDate: Date;
    mapper: string;
    createdDate: Date;
}

const RawIntegrationTypeToModel: Record<RawIntegrationType, IntegrationType> = {
    [RawIntegrationType.Crm]: IntegrationType.Crm,
};

const RawIntegrationProviderToModel: Record<
    RawIntegrationProvider,
    IntegrationProvider
> = {
    [RawIntegrationProvider.CrmFake]: IntegrationProvider.FakeCrm,
    [RawIntegrationProvider.CrmSalesforce]: IntegrationProvider.Salesforce,
    [RawIntegrationProvider.CrmDynamics]: IntegrationProvider.Dynamics,
    [RawIntegrationProvider.ExternalUrl]: IntegrationProvider.ExternalUrl,
};

export const createIntegration = (
    integration: IntegrationPartFragment,
): Integration => {
    const {
        id,
        name,
        integrationType,
        driver,
        provider,
        updatedDate,
        createdDate,
        mapper,
    } = integration;
    return {
        id,
        name,
        integrationType: RawIntegrationTypeToModel[integrationType],
        driver,
        provider: RawIntegrationProviderToModel[provider],
        updatedDate: new Date(updatedDate),
        createdDate: new Date(createdDate),
        mapper,
    };
};

export enum EntitySyncState {
    Created = 'CREATED',
    Error = 'ERROR',
    Exported = 'EXPORTED',
}
export interface CrmState {
    dealId: string;
    integrationId: string;
    state: EntitySyncState | null;
    remoteUrl: string | null;
    exportedDate: Date | null;
}

const RawEntitySyncStateToModel: Record<RawEntitySyncState, EntitySyncState> = {
    [RawEntitySyncState.Created]: EntitySyncState.Created,
    [RawEntitySyncState.Error]: EntitySyncState.Error,
    [RawEntitySyncState.Exported]: EntitySyncState.Exported,
};

export const createCrmState = (
    crmState: CrmStatePartFragment,
    dealId: string,
    integrationId: string,
): CrmState => {
    const { exportedDate, remoteUrl, state } = crmState;
    return {
        dealId,
        integrationId,
        state: state ? RawEntitySyncStateToModel[state] : null,
        exportedDate: exportedDate ? new Date(exportedDate) : null,
        remoteUrl: remoteUrl ?? null,
    };
};

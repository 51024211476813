import styled from '@emotion/styled';

import { MediaQuery } from 'src/resources/styles/media.mixins';

export const PlaceholderStyled = styled.div`
    display: flex;
    padding: 18px 8px;
    border-bottom: 2px solid var(--gray-border-color);

    ${MediaQuery.fullWidthMenu} {
        flex-direction: column;
    }
`;

export const DetailPlaceholderStyled = styled.div`
    margin-left: 16px;
    width: 100%;

    ${MediaQuery.fullWidthMenu} {
        margin-left: 0;
    }
`;

export const AvatarPlaceholderStyled = styled.div`
    flex: none;

    ${MediaQuery.fullWidthMenu} {
        margin-bottom: 12px;
    }
`;

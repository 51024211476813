import { TFunction, Trans } from 'react-i18next';

import { ToasterMessage } from 'src/domain/features/toaster/toaster.utils';

import { WrappedWebIconsTipText } from './web-icons-panel.styled';

const wrapTooltipText = (
    key: string,
    text: string,
    t: TFunction<'translation', undefined>,
) => (
    <WrappedWebIconsTipText>
        <Trans
            t={t}
            i18nKey={key}
            values={{ action_entity: text }}
            shouldUnescape
            components={{ bold: <strong /> }}
        />
    </WrappedWebIconsTipText>
);

export const copyClipboardTooltipText = (
    text: string,
    t: TFunction<'translation', undefined>,
) =>
    wrapTooltipText(
        'web_actions.action_icons_tooltip.copy_to_clipboard',
        text,
        t,
    );

export const linkContactTooltipText = (
    text: string,
    t: TFunction<'translation', undefined>,
) => wrapTooltipText('web_actions.action_icons_tooltip.link_to', text, t);

export const googleSearchTooltipText = (
    text: string,
    t: TFunction<'translation', undefined>,
) =>
    wrapTooltipText(
        'web_actions.action_icons_tooltip.search_on_google',
        text,
        t,
    );

export const linkedinSearchTooltipText = (
    text: string,
    t: TFunction<'translation', undefined>,
) =>
    wrapTooltipText(
        'web_actions.action_icons_tooltip.search_on_linkedin',
        text,
        t,
    );

export const createToastForCopyClipboard = (
    text: string,
    t: TFunction<'translation', undefined>,
): ToasterMessage => ({
    title: text,
    message: t('common.copied_to_clipboard'),
});

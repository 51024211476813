import {
    AchievementDetail,
    ColleagueAchievementsDetails,
} from 'src/app-features/achievements/domain/model/achievement.model';
import {
    createAchievementArray,
    createAchievementMap,
} from 'src/app-features/achievements/domain/utils';
import { AbortParams } from 'src/data/api/api-client';
import {
    UserAchievementName,
    UserAchievementState,
} from 'src/data/api/graphql/br_process/generated/graphql-sdk';
import { ProcessGqlSdk } from 'src/data/api/graphql/graphql-client.wrapper';

export interface IAchievementsApi {
    getAchievements: (params: AbortParams) => Promise<AchievementDetail[]>;
    setAchievementsEnabled: (
        params: SetAchievementsEnabledProps,
    ) => Promise<boolean>;
    updateAchievementState: (
        params: UpdateAchievementStateProps,
    ) => Promise<boolean>;
    getTeamsAchievements: (
        params: AbortParams,
    ) => Promise<ColleagueAchievementsDetails[]>;
}

export const createAchievementsApi = (
    processGqlSdk: ProcessGqlSdk,
): IAchievementsApi => {
    const getAchievements = async ({ signal }: AbortParams) => {
        const { userAchievements } = await processGqlSdk.GetUserAchievements(
            undefined,
            { signal },
        );

        return createAchievementArray(userAchievements.achievements);
    };

    const setAchievementsEnabled = async (
        params: SetAchievementsEnabledProps,
    ) => {
        return (await processGqlSdk.SetAchievementsOptIn(params))
            .updateUserAchievementsEnrolmentOptIn.ok;
    };

    const updateAchievementState = async (
        params: UpdateAchievementStateProps,
    ) => {
        return (await processGqlSdk.UpdateAchievementState(params))
            .updateUserAchievementState.ok;
    };

    const getTeamsAchievements = async ({ signal }: AbortParams) => {
        const achievements = await processGqlSdk.GetTeamsAchievements(
            undefined,
            { signal },
        );

        return achievements.colleaguesAchievements.map((c) => ({
            ...c,
            achievements: createAchievementMap(c.achievements),
        }));
    };

    return {
        getAchievements,
        setAchievementsEnabled,
        updateAchievementState,
        getTeamsAchievements,
    };
};

interface SetAchievementsEnabledProps extends AbortParams {
    optIn: boolean;
}

interface UpdateAchievementStateProps extends AbortParams {
    name: UserAchievementName;
    state: UserAchievementState;
}

import { BrIcon, IconName, IconSize } from '@buildingradar/br_component_lib';
import { IconButton, useMediaQuery } from '@mui/material';
import { observer } from 'mobx-react';
import { FC, useCallback } from 'react';

import { Localized } from 'src/presentation/shared/localized/localized';
import { MediaQuery } from 'src/resources/styles/media.mixins';

import {
    CloseMenuWrapperStyled,
    CloseNotificationsWrapperStyled,
    NotificationPanelWrapper,
    NotificationsHeaderStyled,
    NotificationsTitle,
} from './notifications-panel.styled';
import { NotificationsTab } from './notifications-tab.component';

interface NotificationsPanelProps {
    toggleNotificationsOpened: (isOpen: boolean) => void;
}

export const NotificationsPanel: FC<NotificationsPanelProps> = observer(
    ({ toggleNotificationsOpened }) => {
        const isTablet = useMediaQuery(MediaQuery.tablet);

        const onNotificationPanelDismissed = useCallback(() => {
            toggleNotificationsOpened(false);
        }, [toggleNotificationsOpened]);

        return (
            <NotificationPanelWrapper className="notifications-list">
                <NotificationsHeaderStyled>
                    <CloseNotificationsWrapperStyled
                        onClick={onNotificationPanelDismissed}
                    >
                        <BrIcon
                            iconName={IconName.BrArrowBackIosIcon}
                            iconSize={IconSize.large}
                            iconColor="var(--gray-80)"
                        />
                    </CloseNotificationsWrapperStyled>
                    <NotificationsTitle>
                        <Localized>navbar.notifications</Localized>{' '}
                    </NotificationsTitle>

                    {!isTablet && (
                        <CloseMenuWrapperStyled>
                            <IconButton onClick={onNotificationPanelDismissed}>
                                <BrIcon iconName={IconName.BrCloseIcon} />
                            </IconButton>
                        </CloseMenuWrapperStyled>
                    )}
                </NotificationsHeaderStyled>
                <NotificationsTab />
            </NotificationPanelWrapper>
        );
    },
);

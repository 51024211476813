import { IconName } from '@buildingradar/br_component_lib';
import { observer } from 'mobx-react';
import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { ConfirmModal } from 'src/presentation/shared/modal-confirm/modal-confirm';

interface LinkClickWarningComponentProps {
    clickedLink: string | undefined;
    setClickedLink: (link?: string) => void;
}

export const LinkClickWarningComponent: FC<LinkClickWarningComponentProps> =
    observer(({ clickedLink, setClickedLink }) => {
        const { t } = useTranslation();

        const onConfirmVisitLink = useCallback(() => {
            if (clickedLink) {
                const url = clickedLink.match(/^https?:/)
                    ? clickedLink
                    : '//' + clickedLink;
                window.open(url, '_blank');
            }
            setClickedLink();
        }, [clickedLink, setClickedLink]);

        const onCancel = useCallback(() => setClickedLink(), [setClickedLink]);

        return (
            <ConfirmModal
                icon={IconName.BrWarningIcon}
                title={t('external_link.title')}
                description={t('external_link.description')}
                btnCancel={t('common.cancel')}
                btnOk={t('common.confirm')}
                open={!!clickedLink}
                onCancel={onCancel}
                onConfirm={onConfirmVisitLink}
            />
        );
    });

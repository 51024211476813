import styled from '@emotion/styled';
import { ZIndex } from 'src/resources/styles/zIndex';

export const useBackdropClasses = () => ({
    marginLeft: '64px',
});

export const StyledNotificationContent = styled.div`
    display: flex;
    height: 100%;
    width: 656px;
`;

export const createNotificationsSwipableClasses =
    (fullWidthMenu: boolean) => () => ({
        zIndex: ZIndex.zDrawerAsSecondLayer,
        '.MuiDrawer-paper': {
            maxWidth: '100%',
            marginLeft: '64px',
            boxShadow: 'none',
            borderTopRightRadius: fullWidthMenu ? 0 : 16,
            borderBottomRightRadius: fullWidthMenu ? 0 : 16,
            transitionTimingFunction: 'ease-in-out!important',
        },

        marginLeft: '64px',
    });

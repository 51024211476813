export const GrayIcon = () => {
    return (
        <svg
            width="72"
            height="72"
            viewBox="0 0 72 72"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g
                clipPath="url(#clip0_806_2522)"
                filter="url(#filter0_d_806_2522)"
            >
                <path
                    d="M52.6376 36.7836L56 33.014L52.6376 29.2442L47.4417 31.8422H47.03L49.7167 29.1551L48.0596 27.498L43.7158 31.8422H42.0858L40.057 28.3268L40.8718 26.9154L46.8057 25.3257L46.199 23.0623L42.5292 24.0455L42.7566 23.6516L47.7758 20.3373L46.397 15.1875L41.2472 16.5675L40.9118 22.1589L40.4986 22.874L39.5144 19.2033L37.2509 19.8097L38.8415 25.7438L38.027 27.1547H33.9672L33.1533 25.7448L34.7433 19.8097L32.4798 19.2033L31.4962 22.8746L31.1694 22.3085L30.8251 16.5675L25.6753 15.1875L24.2962 20.3373L29.1903 23.5688L29.4653 24.0452L25.7952 23.0623L25.1888 25.3257L31.1224 26.9154L31.9378 28.3278L29.9093 31.8422H28.28L23.9355 27.498L22.2784 29.1551L24.9658 31.8422H24.5583L19.3624 29.2442L16 33.014L19.3624 36.7836L24.5583 34.1859H24.9658L22.2784 36.873L23.9355 38.5301L28.28 34.1859H29.9093L31.9378 37.7003L31.1224 39.1124L25.1888 40.702L25.7952 42.9658L29.4656 41.9825L29.1815 42.4751L24.2721 45.717L25.6524 50.8656L30.8022 49.4865L31.1453 43.7614L31.4962 43.1538L32.4798 46.8248L34.7433 46.2181L33.1533 40.2833L33.9675 38.8734H38.027L38.8415 40.2842L37.2509 46.2181L39.5144 46.8248L40.4986 43.1547L40.8837 43.8221L41.2243 49.4865L46.3729 50.8656L47.7529 45.717L42.7938 42.4424L42.5283 41.9825L46.199 42.9658L46.8057 40.702L40.8715 39.1124L40.057 37.7012L42.0858 34.1859H43.7158L48.0596 38.5301L49.7167 36.873L47.03 34.1859H47.4417L52.6376 36.7836Z"
                    fill="white"
                />
            </g>
            <defs>
                <filter
                    id="filter0_d_806_2522"
                    x="10"
                    y="10"
                    width="52"
                    height="52"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="3" />
                    <feGaussianBlur stdDeviation="3" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_806_2522"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_806_2522"
                        result="shape"
                    />
                </filter>
                <clipPath id="clip0_806_2522">
                    <rect
                        width="40"
                        height="40"
                        fill="white"
                        transform="translate(16 13)"
                    />
                </clipPath>
            </defs>
        </svg>
    );
};

export interface CallbackService<
    CallbackFnType extends (...args: any[]) => any,
> {
    callbacks: CallbackFnType[];
    registerCallback: (callback: CallbackFnType) => void;
    call: (...args: Parameters<CallbackFnType>) => void;
}

export const createCallbackService = <
    T extends (...args: any[]) => any,
>(): CallbackService<T> => {
    const callbacks: T[] = [];

    const registerCallback = (callback: T) => {
        callbacks.push(callback);
    };

    const call = (...args: Parameters<T>) => {
        callbacks.forEach((c) => c(...args));
    };

    return { callbacks, registerCallback, call };
};

import {
    NormalizedPipelineReport,
    Pipeline,
    StagePipelineData,
} from 'src/domain/models/pipeline/pipeline.model';

export interface PipelineNormalizerService {
    normalize: (pipeline: Pipeline) => NormalizedPipelineReport;
}

/**
 * A service for calculations of normalized pipeline data
 */
export const createPipelineNormalizerService =
    (): PipelineNormalizerService => {
        const normalize = (pipeline: Pipeline) => {
            const newStages: StagePipelineData[] = [];

            const { config, statistics, name } = pipeline;
            const {
                averageDealValue,
                revenueGoal,
                timePeriodStart,
                stageConfig,
            } = config;

            // normalizing factor for all stages should always be depend on later stages
            let normalizingFactor = 1;

            // so we start calculating it from the last stages and we make only one loop
            for (let i = stageConfig.stageIdsInOrder.length - 1; i >= 0; i--) {
                const stageId = stageConfig.stageIdsInOrder[i];
                const stage = stageConfig.stageMap.get(stageId);
                if (!stage) {
                    continue;
                }
                const { targetConversionRate, name, id } = stage;
                const stageStatistics = statistics.stages.find(
                    (stage) => stage.id === id,
                );
                const sizeOfHealthyProjects = stageStatistics
                    ? stageStatistics.totalValueOfHealthyDeals
                    : 0;
                const sizeOfRiskProjects = stageStatistics
                    ? stageStatistics.totalValueOfAtRiskDeals
                    : 0;
                normalizingFactor = targetConversionRate * normalizingFactor;

                const normalizedProjectValue =
                    normalizingFactor * sizeOfHealthyProjects;
                const normalizedProjectAtRiskValue =
                    normalizingFactor * sizeOfRiskProjects;

                const newStageValue: StagePipelineData = {
                    conversionRate: targetConversionRate,
                    projectValue: normalizedProjectValue,
                    projectAtRiskValue: normalizedProjectAtRiskValue,
                    showConversionRateInput: true,
                    stageName: name,
                    stageId: id,
                };

                newStages.push(newStageValue);
            }

            newStages.reverse();

            return {
                name,
                averageDealSize: averageDealValue.value,
                annualRevenueGoal: revenueGoal.value,
                timePeriodStart,
                stages: newStages,
            };
        };

        return {
            normalize,
        };
    };

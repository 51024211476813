import { BrIcon, IconName } from '@buildingradar/br_component_lib';
import { observer } from 'mobx-react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { DealContactType } from 'src/app-features/contact/data/model/deal-contacts.model';
import { ContactCardAvatar } from 'src/app-features/contact/presentation/contact-section/components/contact-card/components/profile-section/contact-card-avatar.component';

import { getFullName } from 'src/domain/models/user/user.model';

import { ContactExtractionCandidateCardComponent } from './contract-extraction-candidate-card.component';
import {
    DealContactCandidate,
    DealCompanyCandidate,
    ContactExtractionResultStatus,
} from 'src/app-features/contact-extraction/data/contact-extraction.model';
import clsx from 'clsx';

interface ContactExtractionCandidatesProps {
    onSelectionChange: (
        selected: boolean,
        candidate: DealContactCandidate,
    ) => void;
    onSelectCompany: (
        selected: boolean,
        candidate: DealCompanyCandidate,
    ) => void;
    contactCandidates: DealContactCandidate[];
    companyCandidates: DealCompanyCandidate[];
    status: ContactExtractionResultStatus;
}

export const ContactExtractionCandidatesComponent: FC<ContactExtractionCandidatesProps> =
    observer(
        ({
            contactCandidates,
            companyCandidates,
            onSelectionChange,
            onSelectCompany,
        }) => {
            const { t } = useTranslation();
            return (
                <>
                    <div className="mt-2 text-gray-400">
                        {t('enablement_panel.contact_extraction.found_label', {
                            contacts: contactCandidates.length,
                            companies: companyCandidates.length,
                        })}
                    </div>
                    {contactCandidates.length > 0 && (
                        <>
                            <div className="my-4 font-bold">
                                {`${t(
                                    'enablement_panel.contact_extraction.label.extracted_contacts',
                                )}(${contactCandidates.length})`}
                            </div>
                            <div className="flex w-full flex-col gap-4">
                                {contactCandidates.map((candidate, index) => (
                                    <ContactExtractionCandidateCardComponent
                                        key={index}
                                        name={getFullName(
                                            candidate.firstName,
                                            candidate.lastName,
                                        )}
                                        companyName={candidate.companyName}
                                        sourceUrls={candidate.sourceUrls}
                                        role={candidate.role}
                                        legitimateInterestText={
                                            candidate.legitimateInterestText
                                        }
                                        initialSelected={index === 0}
                                        onSelectionChange={(selected) =>
                                            onSelectionChange(
                                                selected,
                                                candidate,
                                            )
                                        }
                                        avatar={
                                            <ContactCardAvatar
                                                avatarData={{
                                                    firstName:
                                                        candidate.firstName,
                                                    lastName:
                                                        candidate.lastName,
                                                }}
                                                archived={false}
                                                contactType={
                                                    DealContactType.person
                                                }
                                            />
                                        }
                                    />
                                ))}
                            </div>
                        </>
                    )}
                    {companyCandidates.length > 0 && (
                        <>
                            <div
                                className={clsx({
                                    'mb-4 font-bold': true,
                                    'mt-6': companyCandidates.length > 0,
                                    'mt-4': companyCandidates.length === 0,
                                })}
                            >
                                {`${t(
                                    'enablement_panel.contact_extraction.label.extracted_companies',
                                )}(${companyCandidates.length})`}
                            </div>
                            <div className="gap-4ƒ flex w-full flex-col">
                                {companyCandidates.map((candidate, index) => (
                                    <ContactExtractionCandidateCardComponent
                                        key={index}
                                        name={candidate.name}
                                        sourceUrls={candidate.sourceUrls}
                                        role={candidate.role}
                                        legitimateInterestText={
                                            candidate.legitimateInterestText
                                        }
                                        onSelectionChange={(selected) =>
                                            onSelectCompany(selected, candidate)
                                        }
                                        showCompany={false}
                                        avatar={
                                            <div className="flex size-10 shrink-0 items-center justify-center rounded-full border-2 border-solid border-[--magenta-medium]">
                                                <BrIcon
                                                    iconName={
                                                        IconName.BrCorporateIcon
                                                    }
                                                    iconColor="var(--magenta-dark)"
                                                />
                                            </div>
                                        }
                                    />
                                ))}
                            </div>
                        </>
                    )}
                </>
            );
        },
    );

import { makeAutoObservable } from 'mobx';

import {
    OperationStep,
    IDealBatchOperationsStore,
} from 'src/data/stores/deal-batch-operations/deal-batch-operations.store';

export interface IDealBatchOperationsFeature {
    selectedDealIds: string[];
    selectedDealsCount: number;
    currentOperationStep: OperationStep;
    updateInProgress: boolean;
    isBatchOperationSuccessful: boolean;
    setSelectedDealIds: (ids: string[]) => void;
    goToNextStep: () => void;
}

export class DealBatchOperationsFeature implements IDealBatchOperationsFeature {
    constructor(private dealBatchOperationsStore: IDealBatchOperationsStore) {
        makeAutoObservable(this);
    }

    get selectedDealIds() {
        return this.dealBatchOperationsStore.selectedDealIds;
    }

    get selectedDealsCount() {
        return this.selectedDealIds.length;
    }

    get currentOperationStep() {
        return this.dealBatchOperationsStore.currentOperationStep;
    }

    get updateInProgress() {
        return this.dealBatchOperationsStore.updateInProgress;
    }

    get isBatchOperationSuccessful() {
        return this.dealBatchOperationsStore.isBatchOperationSuccessful;
    }

    get goToNextStep() {
        return this.dealBatchOperationsStore.goToNextStep;
    }

    setSelectedDealIds = (ids: string[]) => {
        this.dealBatchOperationsStore.setSelectedDealIds(ids);
    };
}

import { FolderTag } from 'src/data/api/graphql/br_search/generated/graphql-sdk';
import { SearchTag } from 'src/domain/models/search-tag/search-tag.model';

import {
    prepareFolderCounter,
    prepareFolderCounterForExportedFolder,
} from './folder.utils';

export enum FolderType {
    inbox = 'inbox',
    interesting = 'interesting',
    notInteresting = 'not_interesting',
    snoozed = 'snoozed',
    done = 'done',
    exported = 'exported',
}

export const readableFolder: Record<FolderType, string> = {
    [FolderType.inbox]: 'screening.title',
    [FolderType.interesting]: 'lead.folder_type.interesting',
    [FolderType.snoozed]: 'lead.folder_type.snoozed',
    [FolderType.notInteresting]: 'lead.folder_type.disqualified',
    [FolderType.done]: 'lead.folder_type.archived',
    [FolderType.exported]: 'lead.folder_type.qualified',
};

export const FolderTagToFolderTypeMap: { [key in FolderTag]?: FolderType } = {
    [FolderTag.Inbox]: FolderType.inbox,
    [FolderTag.Interesting]: FolderType.interesting,
    [FolderTag.Snoozed]: FolderType.snoozed,
    [FolderTag.NotInteresting]: FolderType.notInteresting,
    [FolderTag.Done]: FolderType.done,
    [FolderTag.ExportedAsCsv]: FolderType.exported,
    [FolderTag.ExportedAsPdf]: FolderType.exported,
    [FolderTag.ExportedToCrm]: FolderType.exported,
};

export const FolderTypeToFolderTagSdkMap: { [key in FolderType]?: FolderTag } =
    {
        [FolderType.inbox]: FolderTag.Inbox,
        [FolderType.interesting]: FolderTag.Interesting,
        [FolderType.snoozed]: FolderTag.Snoozed,
        [FolderType.notInteresting]: FolderTag.NotInteresting,
        [FolderType.done]: FolderTag.Done,
        [FolderType.exported]: FolderTag.ExportedAsCsv,
    };

export interface RawFolderCounters {
    inbox?: Record<string, number>;
    interesting?: Record<string, number>;
    exported_as_pdf?: Record<string, number>;
    exported_as_csv?: Record<string, number>;
    exported_to_crm?: Record<string, number>;
    done?: Record<string, number>;
    not_interesting?: Record<string, number>;
    snoozed?: Record<string, number>;
    nonZeroInboxItems?: boolean;
}

// make it depend on FolderType

export type FolderCounters = Record<FolderType, Record<SearchTag, number>>;

export enum StringSearchTag {
    None = 'null',
    Unassigned = 'unassigned',
    all = 'all',
}

export const createFolderCounters = (
    rawCounters: RawFolderCounters,
): FolderCounters => {
    return {
        [FolderType.inbox]: prepareFolderCounter(rawCounters.inbox, true),
        [FolderType.interesting]: prepareFolderCounter(rawCounters.interesting),
        [FolderType.notInteresting]: prepareFolderCounter(
            rawCounters.not_interesting,
        ),
        [FolderType.snoozed]: prepareFolderCounter(rawCounters.snoozed),
        [FolderType.done]: prepareFolderCounter(rawCounters.done),
        [FolderType.exported]: prepareFolderCounterForExportedFolder(
            rawCounters.exported_as_csv,
            rawCounters.exported_as_pdf,
            rawCounters.exported_to_crm,
        ),
    };
};

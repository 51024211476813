import styled from '@emotion/styled';

import { MediaQuery } from 'src/resources/styles/media.mixins';
import { Spacing } from 'src/resources/styles/spacing';

export const CardTitleStyled = styled('div')`
    color: 'var(--black)';
`;

export const CardSubtitleStyled = styled.div({
    color: 'var(--gray-80)',
    fontWeight: 500,
    letterSpacing: '0.4px',
});

export const NotificationsListStyled = styled.ul({
    listStyle: 'none',
    height: '100%',
});

export const CardHeaderBadgeWrapperStyled = styled.div`
    margin-right: 16px;

    ${MediaQuery.fullWidthMenu} {
        margin-bottom: 6px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
`;

export const CardDetailsStyled = styled.div`
    line-height: 24px;
    font-size: 16px;
    margin-right: 6px;
    letter-spacing: 0.44px;

    ${MediaQuery.fullWidthMenu} {
        margin-right: 0;
    }
`;

export const WhenStyled = styled('div')`
    color: 'var(--gray-60)')};
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
`;

export const CardWrapperStyled = styled('li')`
    width: 100%;
    padding: ${Spacing.s2} ${Spacing.s1};
    display: flex;
    border-bottom: 1px solid var(--gray-20);
    align-items: center;

    :hover,
    :focus {
        background-color: var(--gray-20);

        ${CardSubtitleStyled} {
            color: var(--blue-60);
        }
    }

    a {
        display: flex;
        width: 100%;

        ${MediaQuery.fullWidthMenu} {
            flex-direction: column;
        }
    }
`;

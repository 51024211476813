import { Language } from 'src/domain/models/locale/locale.model';
import {
    getUserFullName,
    User,
    UserRole,
} from 'src/domain/models/user/user.model';

import { EventType, PendoLocale, PendoMetaData } from './pendo.model';

const pendo = window.pendo;
export interface PendoService {
    init: (user: User) => void;
    trackEvent: (eventName: EventType) => void;
    updateLocale: (language: Language) => void;
}

export const createPendoService = (): PendoService => {
    let pendoOptions: PendoMetaData | undefined = undefined;
    const init = (user: User) => {
        const userRole = user.roles.includes(UserRole.owner) ? 'owner' : 'user';
        const locale =
            user.language === Language.De ? PendoLocale.DE : PendoLocale.EN;
        pendoOptions = {
            visitor: {
                id: user.itemId, // Required if user is logged in
                email: user.email,
                full_name: getUserFullName(user),
                role: userRole,
                locale,
                // You can add any additional visitor level key-values here,
                // as long as it's not one of the above reserved names.
            },

            account: {
                id: user.companyId, // Highly recommended
                companyName: user.company,
                // You can add any additional account level key-values here,
                // as long as it's not one of the above reserved names.
            },
        };
        pendo.initialize(pendoOptions);
    };

    const trackEvent = (eventName: EventType) => {
        pendo.track(eventName);
    };

    const updateLocale = (language: Language) => {
        if (pendoOptions) {
            pendoOptions.visitor.locale =
                language === Language.De ? PendoLocale.DE : PendoLocale.EN;
            pendo.updateOptions(pendoOptions);
        }
    };

    return {
        init,
        trackEvent,
        updateLocale,
    };
};

import { Contact } from 'src/domain/models/contact/contact.model';

const compareCompaniesByAlphabets = (
    companyNext: Contact,
    companyPrev: Contact,
) => {
    const companyNameNext = companyNext.companyName.trim().toLowerCase();
    const companyNamePrev = companyPrev.companyName.trim().toLowerCase();

    if (companyNameNext < companyNamePrev) {
        return -1;
    }
    if (companyNameNext > companyNamePrev) {
        return 1;
    }
    return 0;
};
export const sortCompaniesByDataAndAlphabets = (
    associatedCompanies: Contact[],
) => {
    associatedCompanies.sort(compareCompaniesByAlphabets);
    return associatedCompanies;
};

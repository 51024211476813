import { makeAutoObservable } from 'mobx';

import { FilterModule } from 'src/domain/models/filter-module/filter-module.model';

import { IFilterModuleConfigStore } from './store.interface';

export class FilterModuleConfigStore implements IFilterModuleConfigStore {
    filterModulesMap?: Map<number, FilterModule>;

    constructor() {
        makeAutoObservable(this);
    }
}

import { BrInfotip } from '@buildingradar/br_component_lib';
import { useMediaQuery } from '@mui/material';
import { observer } from 'mobx-react';
import { FC } from 'react';

import { Link } from 'src/presentation/shared/link/link.component';
import { MediaQuery } from 'src/resources/styles/media.mixins';

import {
    StyledItemWrapper as StyledNavItemWrapper,
    StyledBottomCorner,
    StyledItemShadow as StyledNavItemShadow,
    StyledIconWrapper,
} from './nav-item.styled';
import { useTranslation } from 'react-i18next';

interface NavItemProps {
    name: string;
    className: string;
    icon: JSX.Element;
    route: string;
    isActive: boolean;
    isShadowVisible?: boolean;
    hideTooltip?: boolean;
    showNudgeElement?: boolean;
    nudgeTooltip?: string;
    onClick?: () => void;
}

export const NavItemComponent: FC<NavItemProps> = observer(
    ({
        name,
        icon,
        route,
        isActive,
        className,
        isShadowVisible = false,
        hideTooltip = false,
        showNudgeElement = false,
        nudgeTooltip,
        onClick,
    }) => {
        const { t } = useTranslation();
        const isTablet = useMediaQuery(MediaQuery.tablet);
        const shouldHideTooltip = hideTooltip || showNudgeElement;

        return (
            <BrInfotip
                placement="right"
                showArrow
                content={name}
                trigger={shouldHideTooltip ? 'disabled' : 'onHover'}
            >
                <StyledNavItemWrapper
                    isActive={isActive}
                    className={className}
                    onClick={onClick}
                >
                    {isActive && (
                        <>
                            <StyledBottomCorner />
                            <StyledNavItemShadow
                                hasAnimation={!isTablet}
                                isVisible={isShadowVisible}
                            />
                        </>
                    )}
                    <Link href={route}>
                        <StyledIconWrapper>{icon}</StyledIconWrapper>
                    </Link>
                    {showNudgeElement && (
                        <BrInfotip
                            content={
                                nudgeTooltip ??
                                t('app.main_menu.default_nudge_tooltip')
                            }
                            placement="right"
                            showArrow
                        >
                            <div className="absolute right-2 top-2 z-10">
                                <span className="relative flex size-3">
                                    <span className="absolute inline-flex h-full w-full animate-ping rounded-full bg-red-400 opacity-75"></span>
                                    <span className="relative inline-flex size-3 rounded-full bg-red-500"></span>
                                </span>
                            </div>
                        </BrInfotip>
                    )}
                </StyledNavItemWrapper>
            </BrInfotip>
        );
    },
);

import { observer } from 'mobx-react';
import { FC } from 'react';
import { Path, useFormContext, useFormState } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
    DealContactCompanyForm,
    DealContactPersonForm,
    DealContactUnionForm,
} from 'src/app-features/contact/data/model/deal-contacts-form.model';
import { DealContactFormField } from 'src/app-features/contact/presentation/contact-panel/components/deal-contact-form-field.component';
import { FormControl } from 'src/presentation/shared/form-control/form-control.component';
import { SimpleFieldConfig } from 'src/presentation/shared/form-control/form.model';

import { ContactFieldProps } from './common.utils';

interface SingleFieldContactFieldProps extends ContactFieldProps {
    config:
        | SimpleFieldConfig<DealContactPersonForm>
        | SimpleFieldConfig<DealContactCompanyForm>;
}
export const SingleFieldContactField: FC<SingleFieldContactFieldProps> =
    observer(({ config, focusField, status, onSaveHandler }) => {
        const { t } = useTranslation();
        const { control } = useFormContext<DealContactUnionForm>();
        const { errors: contactErrors } = useFormState<DealContactUnionForm>({
            control,
        }) as any;
        return (
            <DealContactFormField
                label={t(config.label)}
                required={config.required}
            >
                <FormControl<DealContactUnionForm>
                    control={control}
                    autoFocus={focusField === config.name}
                    name={config.name as Path<DealContactUnionForm>}
                    error={!!(contactErrors && contactErrors[config.name])}
                    required={config.required}
                    type={config.type}
                    errorText={t(
                        config.errorText ?? 'common.required_field_text',
                    )}
                    placeholder={t(config.placeholder)}
                    className={config.className}
                    onSave={onSaveHandler}
                    validate={config.validate}
                    status={status}
                />
            </DealContactFormField>
        );
    });

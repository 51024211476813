import { ValueCurrencyPartFragment } from 'src/data/api/graphql/br_process/generated/graphql-sdk';

export enum Currency {
    EUR = 'EUR',
    USD = 'USD',
}

export interface CurrencyValue {
    value: number;
    currency: Currency;
}

export const currencySymbol: Record<Currency, string> = {
    [Currency.EUR]: '€',
    [Currency.USD]: '$',
};

export const createCurrencyValue = (
    rawCurrencyValue: ValueCurrencyPartFragment,
): CurrencyValue => {
    const { currency, value } = rawCurrencyValue;
    switch (currency) {
        case Currency.USD:
        case Currency.EUR: {
            return { currency, value };
        }
        default: {
            return { currency: Currency.EUR, value };
        }
    }
};

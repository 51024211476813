import { withFeatures } from 'src/utils/component.utils';

import { RemindersDashboard } from './reminders-dashboard.component';

export const RemindersDashboardContainer = withFeatures(RemindersDashboard)(
    (f) => ({
        overdueReminders: f.notificationsFeature.overdueReminders,
        upcomingReminders: f.notificationsFeature.upcomingReminders,
        fetchingReminders: f.notificationsFeature.isFetchingReminders,
        user: f.authFeature.user,
        requestReminders: f.notificationsFeature.requestReminders,
    }),
);

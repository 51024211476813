import { AppRoute } from 'src/presentation/modules/router/app-route.list';
import { isNonNullable } from 'src/utils/is-non-nullable.utils';

import { FolderType, StringSearchTag } from './folder.model';

const emptyFolderCounter: Record<string, number> = {};
export const prepareFolderCounter = (
    rawFolderCounter?: Record<string, number>,
    excludeUnassigned?: boolean,
): Record<string, number> => {
    if (!rawFolderCounter) {
        return emptyFolderCounter;
    }

    return Object.keys(rawFolderCounter)
        .filter((searchTag) =>
            excludeUnassigned && searchTag === StringSearchTag.None
                ? null
                : searchTag,
        )
        .filter(isNonNullable)
        .reduce<Record<string, number>>((newCounter, key) => {
            const newKey =
                key === StringSearchTag.None
                    ? StringSearchTag.Unassigned
                    : `${key}`;
            return {
                ...newCounter,
                [newKey]: rawFolderCounter[key],
            };
        }, {});
};

export const prepareFolderCounterForExportedFolder = (
    rawFolderCounterForCSV?: Record<string, number>,
    rawFolderCounterForPDF?: Record<string, number>,
    rawFolderCounterForCRM?: Record<string, number>,
): Record<string, number> => {
    if (
        !rawFolderCounterForCSV &&
        !rawFolderCounterForPDF &&
        !rawFolderCounterForCRM
    ) {
        return emptyFolderCounter;
    }
    return [
        rawFolderCounterForCSV,
        rawFolderCounterForPDF,
        rawFolderCounterForCRM,
    ].reduce<Record<string, number>>((mergedCount, rawFolderCounter) => {
        if (!rawFolderCounter) {
            return {
                ...mergedCount,
            };
        }
        Object.keys(rawFolderCounter).forEach((key) => {
            const newKey =
                key === StringSearchTag.None
                    ? StringSearchTag.Unassigned
                    : `${key}`;
            if (!mergedCount[newKey]) {
                mergedCount[key] = rawFolderCounter[newKey];
            } else {
                mergedCount[newKey] += rawFolderCounter[newKey];
            }
        });
        return {
            ...mergedCount,
        };
    }, {});
};

export const createFolderRoute = (folder: FolderType, searchId?: string) => {
    const searchTagParam = searchId ? `?search-tag=${searchId}` : '';
    const folderUrl =
        folder === FolderType.inbox ? AppRoute.screening : `/${folder}`;
    return `${folderUrl}${searchTagParam}`;
};

import styled from '@emotion/styled';
import { Link as RouterLink } from 'react-router-dom';

export const StyledLink = styled(RouterLink)`
    text-decoration: none;
    /* color: unset; */

    &:hover,
    &:focus {
        /* color: unset; */
        text-decoration: none;
    }
`;

export const StyledA = styled.a`
    text-decoration: none;
    cursor: initial;
    /* color: inherit; */

    &:hover,
    &:focus {
        /* color: unset; */
        text-decoration: none;
    }
`;

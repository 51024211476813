import { observer } from 'mobx-react';
import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import {
    useAuthFeature,
    useNavbarFeature,
    useNotificationsFeature,
} from 'src/app-context/use-features';
import { Mention } from 'src/domain/models/mention/mention.model';

import { MentionComponent } from './mention.component';

interface MentionContainerProps {
    mention: Mention;
}

export const MentionContainer: FC<MentionContainerProps> = observer(
    ({ mention }) => {
        const { user } = useAuthFeature();
        const { t } = useTranslation();
        const { toggleNotificationsOpened } = useNotificationsFeature();
        const { toggleMobileMenu } = useNavbarFeature();

        const closeNotifications = useCallback(() => {
            toggleNotificationsOpened(false);
            toggleMobileMenu(false);
        }, [toggleNotificationsOpened, toggleMobileMenu]);

        if (!user) {
            return null;
        }

        return (
            <MentionComponent
                mention={mention}
                user={user}
                t={t}
                closeNotifications={closeNotifications}
            />
        );
    },
);

import { css, Global } from '@emotion/react';

import { fontStyles } from './font-styles';
import { hubSpotStyles } from './hubspot-styles';
import { mainStyles } from './main-styles';
import { resetStyles } from './reset-styles';
import { themeStyles } from './theme-styles';

const styles = css`
    ${fontStyles};
    ${mainStyles};
    ${resetStyles};
    ${themeStyles};
    ${hubSpotStyles};
`;

export const GlobalStyles = () => <Global styles={styles} />;

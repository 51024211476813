import { MenuItemConfiguration } from '@buildingradar/br_component_lib';

import { isNonNullable } from './is-non-nullable.utils';

export const cutText = (text: string, maxLength: number) => {
    return text.length > maxLength
        ? `${text.slice(0, maxLength).trimEnd()}...`
        : text;
};

export const copyValueToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
};

export const titleCase = (str: string): string => {
    return str.replace(/([^\s:\\-])([^\s:\\-]*)/g, ($0, $1, $2) => {
        return $1.toUpperCase() + $2.toLowerCase();
    });
};

export const addSpaceAroundControlCharacters = (text: string) => {
    return text.replace(/[\n\t]/g, (character) => {
        return ` ${character} `;
    });
};

export const isNotEmpty = (value?: string | null): value is string => {
    return value !== undefined && value !== null && value !== '';
};

export const mapStringsToOptions = (
    values: string[],
): MenuItemConfiguration[] => {
    return values
        .map((value) => (value ? { value, label: value } : null))
        .filter(isNonNullable);
};

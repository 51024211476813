import { MinimalDeal } from 'src/domain/models/deal/deal.model';

export const sortDealsByStageConversion = (
    dealA: MinimalDeal,
    dealB: MinimalDeal,
) => {
    if (dealA.pauseData.isPaused && !dealB.pauseData.isPaused) {
        return 1;
    } else if (!dealA.pauseData.isPaused && dealB.pauseData.isPaused) {
        return -1;
    } else if (dealA.pauseData.isPaused && dealB.pauseData.isPaused) {
        return dealA.pauseData.pausedUntil > dealB.pauseData.pausedUntil
            ? 1
            : -1;
    } else {
        return dealA.expectedCurrentStageConversionDate >
            dealB.expectedCurrentStageConversionDate
            ? 1
            : -1;
    }
};

export const GrayIcon = () => {
    return (
        <svg
            width="72"
            height="72"
            viewBox="0 0 72 72"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_806_2489)">
                <g filter="url(#filter0_ddd_806_2489)">
                    <path
                        d="M31.1836 13.5986C31.1836 12.7157 31.8994 12 32.7823 12C33.6652 12 34.3809 12.7157 34.3809 13.5986V45.5713C34.3809 46.4542 33.6652 47.1699 32.7823 47.1699C31.8994 47.1699 31.1836 46.4542 31.1836 45.5713V13.5986Z"
                        fill="#A2A9B0"
                    />
                    <path
                        d="M31.1836 19.6735C31.1836 18.7906 31.8994 18.0748 32.7823 18.0748C33.6652 18.0748 34.3809 18.7906 34.3809 19.6735V36.2993C34.3809 37.1822 33.6652 37.8979 32.7823 37.8979C31.8994 37.8979 31.1836 37.1822 31.1836 36.2993V19.6735Z"
                        fill="#878D95"
                    />
                    <path
                        d="M36.619 18.0748L43.5449 35.6592C44.0832 37.026 45.414 38.0249 46.7736 37.4687C49.0139 36.5522 50.8456 34.4073 50.3672 33.0714C48.3304 27.3831 44.9358 19.1091 36.619 18.0748Z"
                        fill="#A2A9B0"
                    />
                    <path
                        d="M24.1947 32.9041L18.2124 18.6282C17.1213 16.0246 19.1582 13.1842 21.9653 13.484C41.2117 15.5397 47.1714 20.6789 50.3672 33.19C47.9771 28.1325 42.4725 28.7508 29.3895 34.8726C27.4106 35.7986 25.0391 34.9192 24.1947 32.9041Z"
                        fill="url(#paint0_linear_806_2489)"
                    />
                    <path
                        d="M15.5201 49.6919L12.1477 43.2134C11.6457 42.2489 12.4998 41.1376 13.561 41.3747C29.805 45.0037 44.7944 46.8075 59.2785 35.7134C60.3338 34.905 61.7688 36.0435 61.2217 37.255L55.7209 49.4354C54.8934 51.2678 53.0692 52.4455 51.0587 52.4455H20.0578C18.1501 52.4455 16.4009 51.3841 15.5201 49.6919Z"
                        fill="url(#paint1_linear_806_2489)"
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M26.9577 52.4456H25.3697C24.9572 48.8699 25.4866 46.3067 26.7151 43.7268C27.2532 43.7883 27.7901 43.844 28.3258 43.8934C27.0937 46.6387 26.6083 49.0057 26.9577 52.4456ZM36.5495 52.4456H34.9615C34.5698 49.0498 35.0275 46.5671 36.1268 44.1162C36.7079 44.0909 37.2877 44.0553 37.8662 44.0086C36.6721 46.7052 36.205 49.0539 36.5495 52.4456ZM46.7762 52.4456H45.1868C44.7764 48.2745 45.3839 45.3314 46.7439 42.3156C47.4026 42.1096 48.0599 41.8845 48.7159 41.6392C47.0827 45.2652 46.3997 48.085 46.7762 52.4456Z"
                        fill="#A2A9B0"
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M48.3729 39.3364C47.9141 39.0715 47.3275 39.2287 47.0626 39.6875L40.6243 50.8389C40.5323 50.9983 40.3443 51.0718 40.1623 51.0446C39.1533 50.8936 38.109 51.3589 37.568 52.2959L35.9693 55.0648C35.6162 55.6765 35.8258 56.4587 36.4375 56.8118L39.2064 58.4105C39.8181 58.7636 40.6002 58.554 40.9534 57.9424L42.552 55.1734C43.093 54.2364 42.9737 53.0994 42.3386 52.3011C42.224 52.157 42.1936 51.9575 42.2857 51.7981L48.724 40.6466C48.9889 40.1879 48.8317 39.6012 48.3729 39.3364Z"
                        fill="white"
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M38.4612 39.3364C38.0024 39.0715 37.4158 39.2287 37.1509 39.6875L30.7126 50.8389C30.6206 50.9983 30.4326 51.0718 30.2506 51.0446C29.2416 50.8936 28.1973 51.3589 27.6563 52.2959L26.0576 55.0648C25.7045 55.6765 25.9141 56.4587 26.5257 56.8118L29.2947 58.4105C29.9064 58.7636 30.6885 58.5541 31.0417 57.9424L32.6403 55.1734C33.1813 54.2364 33.062 53.0994 32.4269 52.3011C32.3123 52.1571 32.2819 51.9575 32.374 51.7981L38.8123 40.6466C39.0772 40.1879 38.92 39.6012 38.4612 39.3364Z"
                        fill="white"
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M28.5497 39.3364C28.0909 39.0715 27.5043 39.2287 27.2395 39.6875L20.8012 50.8389C20.7091 50.9983 20.5211 51.0718 20.3391 51.0446C19.3301 50.8936 18.2858 51.3589 17.7448 52.2959L16.1462 55.0648C15.793 55.6765 16.0026 56.4587 16.6143 56.8118L19.3832 58.4105C19.9949 58.7636 20.777 58.554 21.1302 57.9424L22.7288 55.1734C23.2698 54.2364 23.1506 53.0994 22.5154 52.3011C22.4008 52.157 22.3705 51.9575 22.4625 51.7981L28.9008 40.6466C29.1657 40.1879 29.0085 39.6012 28.5497 39.3364Z"
                        fill="white"
                    />
                </g>
            </g>
            <defs>
                <filter
                    id="filter0_ddd_806_2489"
                    x="-3"
                    y="-1.75"
                    width="79.3421"
                    height="76.582"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="1.25" />
                    <feGaussianBlur stdDeviation="7.5" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_806_2489"
                    />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="5" />
                    <feGaussianBlur stdDeviation="5" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.02 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="effect1_dropShadow_806_2489"
                        result="effect2_dropShadow_806_2489"
                    />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="1.25" />
                    <feGaussianBlur stdDeviation="2.5" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.01 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="effect2_dropShadow_806_2489"
                        result="effect3_dropShadow_806_2489"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect3_dropShadow_806_2489"
                        result="shape"
                    />
                </filter>
                <linearGradient
                    id="paint0_linear_806_2489"
                    x1="31.0866"
                    y1="22.3754"
                    x2="35.4395"
                    y2="36.5878"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="#E1E1E1" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_806_2489"
                    x1="32.0286"
                    y1="42.4007"
                    x2="33.8915"
                    y2="54.2449"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="#E1E1E1" />
                </linearGradient>
                <clipPath id="clip0_806_2489">
                    <rect width="72" height="72" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

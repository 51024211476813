export const GrayIcon = () => {
    return (
        <svg
            width="72"
            height="72"
            viewBox="0 0 72 72"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <mask
                id="mask0_806_2507"
                style={{ maskType: 'alpha' }}
                maskUnits="userSpaceOnUse"
                x="13"
                y="8"
                width="46"
                height="56"
            >
                <path
                    d="M13.32 15.6268C13.32 14.2785 14.2554 13.1107 15.5712 12.8163L35.2211 8.41972C35.6325 8.32767 36.0591 8.32706 36.4707 8.41792L56.4207 12.8213C57.7404 13.1126 58.68 14.2822 58.68 15.6336V47.0528C58.68 48.019 58.1956 48.9207 57.39 49.4541L37.4466 62.6599C36.4772 63.3018 35.2169 63.2979 34.2515 62.6499L14.5949 49.4557C13.7981 48.9209 13.32 48.0242 13.32 47.0645V15.6268Z"
                    fill="white"
                />
            </mask>
            <g mask="url(#mask0_806_2507)">
                <path
                    d="M32.04 19.9008L35.8416 18L39.6432 19.9008V64.1088H32.04V19.9008Z"
                    fill="url(#paint0_linear_806_2507)"
                />
                <path
                    d="M37.189 36.4351C37.1647 36.3857 37.152 36.3313 37.152 36.2763V28.08C37.152 27.8811 37.3132 27.72 37.512 27.72H39.2833C39.4821 27.72 39.6433 27.8811 39.6433 28.08V39.8801C39.6433 40.2613 39.1283 40.381 38.9602 40.0389L37.189 36.4351Z"
                    fill="#B6B6B6"
                />
                <path
                    d="M32.04 19.9008L35.8416 18V63.3888H32.04V19.9008Z"
                    fill="#B6B6B6"
                />
                <path
                    d="M37.08 25.1299H50.3769C51.3225 25.1299 52.2078 25.594 52.7456 26.3717L54.6671 29.15C55.3488 30.1357 55.3488 31.4406 54.6671 32.4264L52.7456 35.2047C52.2078 35.9824 51.3225 36.4465 50.3769 36.4465H37.08V25.1299Z"
                    fill="url(#paint1_linear_806_2507)"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M38.8896 29.1111C39.0047 29.0478 39.1451 29.0522 39.256 29.1226L40.5648 29.9532C40.6688 30.0192 40.7319 30.1339 40.7319 30.2571C40.7319 30.3804 40.6688 30.495 40.5648 30.5611L39.7636 31.0695L40.5488 31.5125C40.7219 31.6102 40.7831 31.8297 40.6854 32.0029C40.5877 32.1761 40.3682 32.2373 40.195 32.1396L39.4231 31.7041V31.826C39.4231 32.0248 39.2619 32.186 39.0631 32.186C38.8642 32.186 38.7031 32.0248 38.7031 31.826V29.4265C38.7031 29.2952 38.7746 29.1743 38.8896 29.1111ZM39.4231 30.4329V30.0814L39.7 30.2571L39.4231 30.4329Z"
                    fill="#B6B6B6"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M50.8875 29.1038C51.0101 29.0431 51.1566 29.0571 51.2654 29.14L52.3561 29.9706C52.4455 30.0387 52.498 30.1446 52.498 30.257C52.498 30.3694 52.4455 30.4753 52.3561 30.5434L51.6641 31.0704L52.3398 31.5278C52.5044 31.6392 52.5476 31.8631 52.4361 32.0277C52.3247 32.1924 52.1008 32.2355 51.9362 32.124L51.4073 31.766V31.8259C51.4073 32.0247 51.2461 32.1859 51.0473 32.1859C50.8485 32.1859 50.6873 32.0247 50.6873 31.8259V29.4264C50.6873 29.2896 50.7649 29.1646 50.8875 29.1038ZM51.4073 30.3609V30.1531L51.5438 30.257L51.4073 30.3609Z"
                    fill="#B6B6B6"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M41.6446 29.0955C41.7769 29.0388 41.9304 29.0664 42.0347 29.1657L42.9072 29.9963C43.0512 30.1333 43.0568 30.3612 42.9197 30.5052C42.7827 30.6492 42.5548 30.6548 42.4108 30.5178L42.1465 30.2661V30.3208L42.8915 30.9512C43.0433 31.0796 43.0622 31.3068 42.9338 31.4586C42.8054 31.6104 42.5782 31.6293 42.4265 31.5009L42.1465 31.2639V31.8259C42.1465 32.0247 41.9853 32.1859 41.7865 32.1859C41.5876 32.1859 41.4265 32.0247 41.4265 31.8259V29.4264C41.4265 29.2824 41.5123 29.1523 41.6446 29.0955Z"
                    fill="#B6B6B6"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M48.4002 29.0955C48.5326 29.0388 48.686 29.0664 48.7903 29.1657L49.6628 29.9963C49.8068 30.1333 49.8124 30.3612 49.6754 30.5052C49.5383 30.6492 49.3104 30.6548 49.1664 30.5178L48.9021 30.2661V30.3208L49.6471 30.9512C49.7989 31.0796 49.8179 31.3068 49.6894 31.4586C49.561 31.6104 49.3338 31.6293 49.1821 31.5009L48.9021 31.2639V31.8259C48.9021 32.0247 48.7409 32.1859 48.5421 32.1859C48.3432 32.1859 48.1821 32.0247 48.1821 31.8259V29.4264C48.1821 29.2824 48.2679 29.1523 48.4002 29.0955Z"
                    fill="#B6B6B6"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M43.9162 29.1026C44.0401 29.0424 44.1876 29.0582 44.296 29.1433L45.6005 30.1683L46.905 29.1433C47.0134 29.0582 47.1609 29.0424 47.2848 29.1026C47.4088 29.1629 47.4875 29.2886 47.4875 29.4264V31.8259C47.4875 31.9637 47.4088 32.0894 47.2848 32.1497C47.1609 32.2099 47.0134 32.1941 46.905 32.109L45.6005 31.084L44.296 32.109C44.1876 32.1941 44.0401 32.2099 43.9162 32.1497C43.7922 32.0894 43.7136 31.9637 43.7136 31.8259V29.4264C43.7136 29.2886 43.7922 29.1629 43.9162 29.1026ZM45.0178 30.6262L44.4336 30.1671V31.0852L45.0178 30.6262ZM46.1832 30.6262L46.7675 31.0852V30.1671L46.1832 30.6262Z"
                    fill="#B6B6B6"
                />
                <path
                    d="M34.56 25.2H19.7679L15.84 30.96L19.7679 36.72H34.56V25.2Z"
                    fill="#B6B6B6"
                />
            </g>
            <defs>
                <linearGradient
                    id="paint0_linear_806_2507"
                    x1="34.8373"
                    y1="37.4354"
                    x2="46.0812"
                    y2="41.414"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="#D7D9DA" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_806_2507"
                    x1="43.9672"
                    y1="29.9"
                    x2="46.2576"
                    y2="38.0301"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="#D7D9DA" />
                </linearGradient>
            </defs>
        </svg>
    );
};

import {
    SdkToSearchTypeMap,
    SearchType,
} from 'src/app-features/searches-configuration/domain/models/configured-search';
import { SearchAndSubscriptionPartFragment } from 'src/data/api/graphql/br_search/generated/graphql-sdk';

export interface SearchSubscriptionData {
    name: string;
    id: number;
    paused: boolean;
    subscriberIds: number[];
    ownerId: number;
    type: SearchType;
    filterModuleIds: number[];
}

export const createSubscription = (
    rawSubscription: SearchAndSubscriptionPartFragment,
): SearchSubscriptionData => {
    const { name, itemId, paused, subscriberIds, userId, type, searchData } =
        rawSubscription;
    return {
        name,
        id: itemId,
        paused,
        subscriberIds,
        ownerId: userId,
        type: SdkToSearchTypeMap[type],
        filterModuleIds: searchData.filterModules.map((filter) => filter.key),
    };
};

import { BrIcon, BrText, IconName } from '@buildingradar/br_component_lib';
import { observer } from 'mobx-react';
import { FC, ReactNode, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { isNotEmpty } from 'src/utils/string.utils';
import { getTinySourceUrl, openLink } from 'src/utils/url.utils';

import clsx from 'clsx';
import { Checkbox } from '@buildingradar/ui_library/checkbox';
import { Dropdown, DropdownItem } from '@buildingradar/ui_library/dropdown';
import { ChevronDown } from 'lucide-react';
import { getRoot } from 'src/utils/element.utils';

interface Props {
    name: string;
    role?: string;
    legitimateInterestText: string;
    companyName?: string;
    sourceUrls: string[];
    initialSelected?: boolean;
    showCompany?: boolean;
    showRole?: boolean;
    avatar?: ReactNode;
    email?: string;
    phone?: string;
    sourceText?: string;
    onSelectionChange?: (selected: boolean) => void;
}

export const ContactExtractionCandidateCardComponent: FC<Props> = observer(
    ({
        name,
        role,
        legitimateInterestText,
        companyName,
        sourceUrls,
        initialSelected = false,
        showCompany = true,
        showRole = true,
        avatar,
        email,
        phone,
        sourceText,
        onSelectionChange,
    }) => {
        const { t } = useTranslation();
        const [isSelected, setIsSelected] = useState<boolean>(initialSelected);
        const onSelectChange = useCallback(
            (newValue: boolean) => {
                setIsSelected(newValue);
                onSelectionChange?.(newValue);
            },
            [onSelectionChange],
        );
        return (
            <div
                className={clsx({
                    'shadow-card mt-4 flex flex-col items-start rounded border border-solid px-[18px] pb-[5px] pt-[18px]':
                        true,
                    'border-[--blue-30]': isSelected,
                    'border-gray-100': !isSelected,
                })}
            >
                <div className="flex w-full flex-row items-start justify-between">
                    <div className="flex w-full flex-col items-start gap-2 pt-0.5">
                        <div className="flex w-full flex-row items-center justify-between gap-2.5">
                            <div className="flex items-center gap-2.5 font-bold">
                                {avatar}
                                {name}
                            </div>
                            <Checkbox
                                checked={isSelected}
                                onCheckedChange={onSelectChange}
                            />
                        </div>
                        {showRole && (
                            <div className="flex w-full flex-row items-center justify-start gap-2.5">
                                <BrIcon
                                    iconColor={'var(--gray-50)'}
                                    iconName={IconName.BrBriefcaseOutlineIcon}
                                />
                                <div className="text-gray-800">
                                    {isNotEmpty(role)
                                        ? role
                                        : t(
                                              'enablement_panel.contact_extraction.create.undetected',
                                          )}
                                </div>
                            </div>
                        )}
                        {showCompany && (
                            <div className="flex w-full flex-row items-center justify-start gap-2.5">
                                <BrIcon
                                    iconColor={'var(--gray-50)'}
                                    iconName={IconName.BrCompanyIcon}
                                />
                                <div className="text-gray-800">
                                    {isNotEmpty(companyName)
                                        ? companyName
                                        : t(
                                              'enablement_panel.contact_extraction.create.undetected',
                                          )}
                                </div>
                            </div>
                        )}
                        {!!email && (
                            <div className="flex w-full flex-row items-center justify-start gap-2.5">
                                <BrIcon
                                    iconColor={'var(--gray-50)'}
                                    iconName={IconName.BrEmailIcon}
                                />
                                <div className="text-gray-800">{email}</div>
                            </div>
                        )}
                        {!!phone && (
                            <div className="flex w-full flex-row items-center justify-start gap-2.5">
                                <BrIcon
                                    iconColor={'var(--gray-50)'}
                                    iconName={IconName.BrCallIcon}
                                />
                                <div className="text-gray-800">{phone}</div>
                            </div>
                        )}
                    </div>
                </div>
                {legitimateInterestText.length > 0 && (
                    <div className="mb-[5px] mt-2.5 rounded bg-[#fff2ff] px-2.5 py-3 leading-snug text-[#9F1AA2]">
                        {legitimateInterestText}
                    </div>
                )}
                <Dropdown
                    align="start"
                    container={getRoot()}
                    classes={{
                        trigger: 'px-2',
                    }}
                    label={
                        <div className="flex flex-row items-center justify-start gap-2">
                            {sourceText ??
                                t(
                                    'enablement_panel.contact_extraction.candidate.source_button',
                                    {
                                        count: sourceUrls.length,
                                    },
                                )}
                            <ChevronDown size={16} />
                        </div>
                    }
                    buttonProp={{
                        variant: 'ghost',
                    }}
                >
                    {sourceUrls.map((url, index) => (
                        <DropdownItem
                            key={index}
                            onSelect={() => openLink(url)}
                            className="flex w-[285px] flex-row gap-2 px-2"
                        >
                            <BrIcon
                                className="mr-2"
                                iconName={IconName.BrOpenInNewIcon}
                            />
                            <BrText color="var(--gray-80)">
                                {getTinySourceUrl(url, true)?.tiny}
                            </BrText>
                        </DropdownItem>
                    ))}
                </Dropdown>
            </div>
        );
    },
);

import { ProcessGqlSdk } from 'src/data/api/graphql/graphql-client.wrapper';
import {
    createMention,
    Mention,
} from 'src/domain/models/mention/mention.model';
import { Reminder } from 'src/domain/models/reminder/reminder.model';

export interface NotificationsApi {
    getMentions: () => Promise<Mention[]>;
    getReminders: () => Promise<Reminder[]>;
}

export const createNotificationsApi = (
    processGqlSdk: ProcessGqlSdk,
): NotificationsApi => {
    const getMentions = async (): Promise<Mention[]> => {
        const response = await processGqlSdk.GetMyMentions();
        const { me } = response;
        return me.mentions?.map(createMention) ?? [];
    };

    const getReminders = async (): Promise<Reminder[]> => {
        const response = await processGqlSdk.GetMyReminders();
        const { me } = response;

        return (me.reminders ?? []).map(
            (rawReminder) => new Reminder(rawReminder),
        );
    };

    return { getMentions, getReminders };
};

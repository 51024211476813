import { AvatarSizes, BrAvatar } from '@buildingradar/br_component_lib';
import { useMediaQuery } from '@mui/material';
import { observer } from 'mobx-react';
import { FC, useCallback, useMemo } from 'react';

import { TranslationFn } from 'src/domain/features/translation/translation.feature';
import { DealOpenSource } from 'src/domain/models/deal/deal.model';
import { Mention } from 'src/domain/models/mention/mention.model';
import { getUserFullName, User } from 'src/domain/models/user/user.model';
import { NotificationCard } from 'src/presentation/modules/notifications-flyout/components/shared/notification-card/notification-card.component';
import { createDealPageLinkToComments } from 'src/presentation/modules/router/utils/route.utils';
import { MediaQuery } from 'src/resources/styles/media.mixins';
import { useUpdatableDateAgo } from 'src/utils/datetime.utils';

interface MentionComponentProps {
    mention: Mention;
    user: User;
    t: TranslationFn;
    closeNotifications: () => void;
}

export const MentionComponent: FC<MentionComponentProps> = observer(
    ({ mention, user, t, closeNotifications }) => {
        const { creatorId, updatedDate, deal } = mention;
        const author = user.colleagues.find(
            (colleague) => colleague.itemId === creatorId,
        );

        if (!author) {
            return null;
        }

        const authorFullName = getUserFullName(author);
        const notificationText =
            'navbar.notifications.mentioned_you_in_a_comment';
        const dateAgo = useUpdatableDateAgo(updatedDate, user.language);
        const urlToLead = createDealPageLinkToComments(
            deal.id,
            DealOpenSource.NotificationsMentions,
        );

        const fullWidthMenu = useMediaQuery(MediaQuery.fullWidthMenu);

        const userAvatar = useMemo(
            () => (
                <BrAvatar
                    avatarData={author}
                    size={fullWidthMenu ? AvatarSizes.m : AvatarSizes.l}
                />
            ),
            [author, fullWidthMenu],
        );

        const clickLinkHandler = useCallback(() => {
            closeNotifications();
        }, [closeNotifications]);

        return (
            <NotificationCard
                linkUrl={urlToLead}
                headerBadge={userAvatar}
                title={`${authorFullName} ${t(notificationText)}`}
                subTitle={deal.title}
                when={dateAgo}
                onLinkClickedHandler={clickLinkHandler}
            />
        );
    },
);

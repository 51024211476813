import { FC, ReactNode } from 'react';

import {
    DealContactFormFieldWrapperStyled,
    DealContactFormLabelStyled,
} from './styles';

interface DealContactFormFieldProps {
    label: string;
    required?: boolean;
    children: ReactNode;
}

export const DealContactFormField: FC<DealContactFormFieldProps> = ({
    label,
    required = false,
    children,
}) => {
    return (
        <DealContactFormFieldWrapperStyled>
            <DealContactFormLabelStyled required={required}>
                {label}
            </DealContactFormLabelStyled>
            {children}
        </DealContactFormFieldWrapperStyled>
    );
};

import { makeAutoObservable } from 'mobx';

export enum OperationStep {
    action = 'action',
    confirmation = 'confirmation',
    progress = 'progress',
    result = 'result',
}
export interface IDealBatchOperationsStore {
    selectedDealIds: string[];
    currentOperationStep: OperationStep;
    updateInProgress: boolean;
    isBatchOperationSuccessful: boolean;
    setSelectedDealIds: (ids: string[]) => void;
    initializeBatchOperationSteps: () => void;
    goToNextStep: () => void;
    setUpdateInProgress: (flag: boolean) => void;
    setBatchOperationStatus: (successful: boolean) => void;
}

const BatchOperationSteps = [
    OperationStep.action,
    OperationStep.confirmation,
    OperationStep.progress,
    OperationStep.result,
];

export class DealBatchOperationsStore implements IDealBatchOperationsStore {
    selectedDealIds: string[] = [];
    currentStepIndex = 0;
    updateInProgress = false;
    isBatchOperationSuccessful = false;

    constructor() {
        makeAutoObservable(this);
    }

    get currentOperationStep(): OperationStep {
        return BatchOperationSteps[this.currentStepIndex];
    }

    setSelectedDealIds = (ids: string[]) => {
        this.selectedDealIds = ids;
    };

    initializeBatchOperationSteps = () => {
        this.currentStepIndex = 0;
    };

    goToNextStep = () => {
        if (this.currentStepIndex < BatchOperationSteps.length - 1) {
            this.currentStepIndex += 1;
        }
    };

    setUpdateInProgress = (flag: boolean) => {
        this.updateInProgress = flag;
    };

    setBatchOperationStatus = (successful: boolean) => {
        this.isBatchOperationSuccessful = successful;
    };
}

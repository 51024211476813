import styled from '@emotion/styled';

import { WrapperBorderRadius } from 'src/presentation/modules/lead-details/lead-details.styled';
import { MediaQuery } from 'src/resources/styles/media.mixins';

export const SingleLeadWrapperStyled = styled.div`
    background-color: white;
    ${WrapperBorderRadius}
    min-width: 0;
    height: 100%;
    width: 100%;
    position: relative;
    overflow-x: hidden;
    height: 100vh;
    box-shadow: 0 4px 24px 2px rgb(0 0 0 / 10%);
    background: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    ${MediaQuery.tablet} {
        justify-content: start;
    }
`;

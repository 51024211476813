export const ColouredIcon = () => {
    return (
        <svg
            width="72"
            height="72"
            viewBox="0 0 72 72"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_806_2502)">
                <path
                    d="M13.9427 40.0518C11.4462 41.4932 10.8488 45.1324 13.0109 48.8772C14.8052 51.985 18.5334 53.2228 20.7936 51.9179L23.7837 50.1916C24.0048 48.4073 23.5472 46.2477 22.378 44.2225C20.2159 40.4777 16.4393 38.6104 13.9427 40.0518Z"
                    fill="url(#paint0_linear_806_2502)"
                />
                <path
                    d="M19.1625 49.0925C17.9142 49.8132 16.099 49.006 15.108 47.2896C14.1171 45.5732 14.3257 43.5976 15.574 42.8769L19.246 40.7568C20.4227 41.5619 21.5258 42.7463 22.378 44.2224C22.8356 45.0149 23.1841 45.828 23.4257 46.6311L19.1625 49.0925Z"
                    fill="url(#paint1_linear_806_2502)"
                />
                <path
                    d="M19.1625 49.0926C19.8594 48.6902 19.8716 47.543 19.3531 46.2805C19.1591 45.8081 19.3024 45.2448 19.7447 44.9895L21.4867 43.9837L23.1178 46.809L19.1625 49.0926Z"
                    fill="url(#paint2_linear_806_2502)"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M21.4711 43.9925C21.589 44.173 21.703 44.3584 21.8129 44.5488C22.2705 45.3412 22.619 46.1543 22.8606 46.9573L21.116 47.9645C20.9052 47.1402 20.5628 46.2983 20.1178 45.5275C20.0053 45.3327 19.8888 45.1487 19.7688 44.9753L21.4711 43.9925Z"
                    fill="url(#paint3_linear_806_2502)"
                />
                <path
                    d="M20.7936 51.9177C22.4888 50.939 22.1242 47.6976 20.6828 45.201C19.2414 42.7045 17.1343 41.976 15.5739 42.8768L19.246 40.7568C20.4226 41.5618 21.5257 42.7462 22.378 44.2223C23.5472 46.2475 24.0048 48.4071 23.7837 50.1914L20.7936 51.9177Z"
                    fill="url(#paint4_linear_806_2502)"
                />
                <path
                    d="M43.722 39.4336C41.5278 39.7587 38.6886 38.9666 36.8869 35.8459C35.0851 32.7252 35.2241 29.2718 37.1973 28.1326L31.1642 31.6158C29.191 32.755 29.052 36.2084 30.8538 39.3291C32.6555 42.4498 35.7157 44.0561 37.6889 42.9168L43.722 39.4336Z"
                    fill="#BD6C0D"
                />
                <path
                    d="M23.0992 51.3264C23.8572 49.3281 23.8871 46.8367 22.378 44.2228C20.3722 40.7487 16.977 38.8905 14.5033 39.7907L13.9427 40.052C14.1233 39.9478 14.3105 39.8609 14.5033 39.7907L34.2745 30.5737L41.1258 26.6181L52.6488 17.705C55.1454 16.2636 58.922 18.131 61.0841 21.8758C63.2461 25.6206 62.975 29.8249 60.4784 31.2663L46.6717 36.224L39.8205 40.1796L23.9027 52.0276C23.5962 52.2557 22.9637 51.6837 23.0992 51.3264Z"
                    fill="url(#paint5_linear_806_2502)"
                />
                <path
                    d="M22.8164 50.7504L23.7838 50.1919C24.0049 48.4076 23.5473 46.2481 22.3781 44.2229C21.5258 42.7468 20.4227 41.5624 19.2461 40.7573L18.6813 41.0834C19.8579 41.8884 20.9609 43.0727 21.8131 44.5488C23.1028 46.7826 23.2685 48.9269 22.8164 50.7504Z"
                    fill="url(#paint6_linear_806_2502)"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M44.4615 37.4997L39.8206 40.1791L37.3165 42.0429C37.9741 40.3763 38.0146 38.2356 36.6977 35.9547C35.2696 33.481 33.0476 31.9607 31.2018 32.0057L34.2746 30.5732L38.3291 28.2324C40.0534 28.4579 41.9936 29.9152 43.2848 32.1517C44.352 34.0001 44.7366 35.9663 44.4615 37.4997Z"
                    fill="#BD6C0D"
                />
                <path
                    d="M37.5065 43.022C38.8907 41.2811 39.6298 38.423 37.8281 35.3023C36.0263 32.1816 32.9612 30.5782 30.9819 31.7209L37.569 27.9179C39.5483 26.7751 42.6134 28.3786 44.4151 31.4993C46.2169 34.62 46.0729 38.0762 44.0936 39.2189L37.5065 43.022Z"
                    fill="url(#paint7_linear_806_2502)"
                />
                <path
                    d="M38.1993 35.0879L43.8499 31.8256L50.9581 44.1373C51.2488 44.6409 50.8279 45.2571 50.2531 45.1696L48.0977 44.8413C47.7771 44.7925 47.4654 44.9724 47.3474 45.2745L46.554 47.3053C46.3424 47.8469 45.5983 47.9032 45.3075 47.3997L38.1993 35.0879Z"
                    fill="url(#paint8_linear_806_2502)"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M40.889 39.7467L38.1993 35.088L43.8498 31.8256L46.5397 36.4847C46.124 37.5517 45.3559 38.4899 44.287 39.107C43.2178 39.7243 42.0212 39.9203 40.889 39.7467Z"
                    fill="#BD6C0D"
                />
                <circle
                    cx="40.786"
                    cy="34.348"
                    r="5.21973"
                    transform="rotate(-30 40.786 34.348)"
                    fill="#BD6C0D"
                />
                <circle
                    cx="41.0244"
                    cy="33.4567"
                    r="5.21973"
                    transform="rotate(-30 41.0244 33.4567)"
                    fill="url(#paint9_linear_806_2502)"
                />
            </g>
            <defs>
                <linearGradient
                    id="paint0_linear_806_2502"
                    x1="21.7606"
                    y1="50.9829"
                    x2="15.3043"
                    y2="39.8002"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#BDE3FF" />
                    <stop offset="1" stopColor="#7FC7FC" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_806_2502"
                    x1="16.8672"
                    y1="42.5072"
                    x2="19.9664"
                    y2="47.8752"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#BDE3FF" />
                    <stop offset="1" stopColor="#7FC7FC" />
                </linearGradient>
                <linearGradient
                    id="paint2_linear_806_2502"
                    x1="21.1739"
                    y1="44.2916"
                    x2="22.7499"
                    y2="47.0214"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="#BDE3FF" />
                </linearGradient>
                <linearGradient
                    id="paint3_linear_806_2502"
                    x1="21.7632"
                    y1="47.5012"
                    x2="20.226"
                    y2="44.8387"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#BDE3FF" />
                    <stop offset="1" stopColor="#7FC7FC" />
                </linearGradient>
                <linearGradient
                    id="paint4_linear_806_2502"
                    x1="19.8502"
                    y1="40.8151"
                    x2="24.8936"
                    y2="49.5505"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="#BDE3FF" />
                </linearGradient>
                <linearGradient
                    id="paint5_linear_806_2502"
                    x1="53.4131"
                    y1="17.8746"
                    x2="60.9782"
                    y2="30.9777"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="#BDE3FF" />
                </linearGradient>
                <linearGradient
                    id="paint6_linear_806_2502"
                    x1="23.9773"
                    y1="48.9502"
                    x2="18.5944"
                    y2="39.6268"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#BDE3FF" />
                    <stop offset="1" stopColor="#7FC7FC" />
                </linearGradient>
                <linearGradient
                    id="paint7_linear_806_2502"
                    x1="47.5747"
                    y1="27.5618"
                    x2="51.1679"
                    y2="35.352"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F2C331" />
                    <stop offset="1" stopColor="#EE7614" />
                </linearGradient>
                <linearGradient
                    id="paint8_linear_806_2502"
                    x1="48.9303"
                    y1="35.3974"
                    x2="52.1981"
                    y2="44.9974"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F2C331" />
                    <stop offset="1" stopColor="#EE7614" />
                </linearGradient>
                <linearGradient
                    id="paint9_linear_806_2502"
                    x1="41.4312"
                    y1="31.3398"
                    x2="38.9889"
                    y2="38.0109"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FF4A4A" />
                    <stop offset="1" stopColor="#E60000" />
                </linearGradient>
                <clipPath id="clip0_806_2502">
                    <rect width="72" height="72" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

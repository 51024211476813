import {
    BrIcon,
    BrTabs,
    BrText,
    BrTextInputStatus,
    IconName,
    TabConfiguration,
    TabsVariant,
    TextSize,
    TextWeight,
} from '@buildingradar/br_component_lib';
import { useMediaQuery } from '@mui/material';
import { observer } from 'mobx-react';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FormProvider, UseFormReturn, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { DealContactUnionForm } from 'src/app-features/contact/data/model/deal-contacts-form.model';
import {
    DealContactToEdit,
    DealContactType,
    DealContactUnionType,
} from 'src/app-features/contact/data/model/deal-contacts.model';
import { ToasterMessage } from 'src/domain/features/toaster/toaster.utils';
import { getFullName } from 'src/domain/models/user/user.model';
import { ActionContentComponent } from 'src/presentation/modules/enablement-actions-panel/actions/action-content.component';
import { MediaQuery } from 'src/resources/styles/media.mixins';
import { useDocumentEventHandler } from 'src/utils/hooks/use-event-handlers';

import { ContactPanelActionButtonsComponent } from './components/contact-panel-action-buttons.component';
import { ContactPanelConfirmModal } from './components/contact-panel-confirm-modal.component';
import {
    ContactPanelTabValue,
    getContactPanelTabConfigurations,
} from './contact-detail-info-panel.utils';
import { useContactForm } from './form-utils/form-hooks';
import {
    ContactDetailPanelActionBodyStyled,
    ContactDetailPanelHeaderStyled,
} from './styles';

interface ContactDetailInfoPanelComponentProps {
    dealId: string;
    selectedContact: DealContactToEdit;
    fieldProgress: Map<string, BrTextInputStatus>;
    findContactById: (
        id: string,
        type: DealContactType,
    ) => DealContactUnionType | undefined;
    showMessage: (message: ToasterMessage) => void;
    onClose: () => void;
    onLinkClicked: (link: string) => void;
    clear: () => void;
}

export const ContactDetailInfoPanelComponent: FC<ContactDetailInfoPanelComponentProps> =
    observer(
        ({
            dealId,
            selectedContact,
            fieldProgress,
            findContactById,
            showMessage,
            onLinkClicked,
            onClose,
            clear,
        }) => {
            const isMobile = useMediaQuery(MediaQuery.phone);
            const { t } = useTranslation();
            const isPerson = selectedContact.type === DealContactType.person;

            const [selectedTab, setSelectedTab] =
                useState<ContactPanelTabValue>(
                    selectedContact?.focusField === 'notes'
                        ? ContactPanelTabValue.notes
                        : ContactPanelTabValue.detail,
                );

            const onErrorCheckIntercept = useCallback(
                (
                    hasError: boolean,
                    formApi: UseFormReturn<
                        DealContactUnionForm,
                        any,
                        undefined
                    >,
                ) => {
                    if (hasError) {
                        if (
                            selectedTab === ContactPanelTabValue.notes &&
                            !formApi.formState.errors.notes
                        ) {
                            setSelectedTab(ContactPanelTabValue.detail);
                            setTimeout(
                                () =>
                                    formApi.trigger(undefined, {
                                        shouldFocus: true,
                                    }),
                                200,
                            );
                            /** trigger it again because
                             *    1) errors is empty without getting triggered first.
                             *    2) `trigger` alone doesn't transition the error state of input fields.
                             *    3) the function doesn't transition the state without a setTimeout.
                             */
                        }
                        return true;
                    }
                    return false;
                },
                [selectedTab],
            );

            const onCreationDone = useCallback(() => {
                showMessage({
                    title: isPerson
                        ? t('contact_panel.toasts.contact_created')
                        : t('contact_panel.toasts.company_created'),
                });
                onClose();
            }, [isPerson, onClose, showMessage, t]);

            const {
                isEditMode,
                formConfig,
                formApi,
                onCreateContact,
                onUpdateContactField,
            } = useContactForm({
                dealId,
                selectedContact,
                onCreationDone,
                onErrorCheckIntercept,
            });

            const [isOnCloseModalOpen, setOnCloseModalOpen] = useState(false);
            const closeButtonRef = useRef<HTMLButtonElement>(null);
            const { control } = formApi;
            /** this is for real-time updating for the title at the drawer panel when it edit mode */
            const names = useWatch({
                control,
                name: ['firstName', 'lastName', 'name'],
                defaultValue: {
                    firstName: isPerson ? selectedContact.firstName : undefined,
                    lastName: isPerson ? selectedContact.lastName : undefined,
                    name: !isPerson ? selectedContact.name : undefined,
                },
            });

            const title = useMemo(
                () =>
                    isEditMode
                        ? isPerson
                            ? getFullName(names[0], names[1])
                            : names[2]
                        : isPerson
                          ? t('contact_panel.header.create_person')
                          : t('contact_panel.header.create_company'),
                [isEditMode, isPerson, names, t],
            );

            const handleEnterClick = useCallback(
                (event: KeyboardEvent) => {
                    const enterKey =
                        ['Enter', 'NumpadEnter'].includes(event.key) &&
                        !event.shiftKey;
                    if (enterKey) {
                        if (!isEditMode) {
                            onCreateContact();
                        } else {
                            closeButtonRef.current?.focus();
                        }
                    }
                },
                [isEditMode, onCreateContact],
            );
            useDocumentEventHandler('keydown', handleEnterClick);

            const noteStatus = fieldProgress.get('notes');
            const tabConfigurations: TabConfiguration[] = useMemo(
                () =>
                    getContactPanelTabConfigurations({
                        isEditMode,
                        selectedContact,
                        dealId,
                        noteStatus,
                        formConfig,
                        onLinkClicked,
                        t,
                        onSave: onUpdateContactField,
                    }),
                [
                    isEditMode,
                    selectedContact,
                    dealId,
                    noteStatus,
                    formConfig,
                    onLinkClicked,
                    t,
                    onUpdateContactField,
                ],
            );

            const onCloseHandler = useCallback(() => {
                const { formState } = formApi;
                const { isDirty } = formState;
                if (isDirty) {
                    if (isEditMode) {
                        closeButtonRef.current?.focus();
                        onClose();
                    } else {
                        setOnCloseModalOpen(true);
                    }
                } else {
                    onClose();
                }
            }, [formApi, isEditMode, onClose]);

            useEffect(() => {
                return () => {
                    clear();
                };
            }, [clear]);

            return (
                <>
                    <ActionContentComponent
                        contentSxOverride={{
                            width: isMobile ? '100%' : '400px',
                            padding: 0,
                        }}
                        actionSxOverride={{
                            padding: '16px 16px 32px',
                            margin: '0 -16px',
                        }}
                        bodySxOverride={{
                            marginTop: 0,
                            marginLeft: '-16px',
                            marginRight: '-16px',
                        }}
                        headerSxOverride={{
                            border: 'none',
                            padding: '16px 16px 0px',
                            margin: '0px -16px',
                        }}
                        title={
                            <ContactDetailPanelHeaderStyled>
                                <BrIcon
                                    iconName={
                                        isPerson
                                            ? IconName.BrPersonIcon
                                            : IconName.BrCompanyIcon
                                    }
                                    iconColor={'var(--blue-60)'}
                                />
                                <BrText
                                    size={TextSize.md}
                                    color="var(--blue-60)"
                                    weight={TextWeight.bold}
                                >
                                    {title}
                                </BrText>
                            </ContactDetailPanelHeaderStyled>
                        }
                        body={
                            <ContactDetailPanelActionBodyStyled>
                                <FormProvider {...formApi}>
                                    <BrTabs
                                        variant={TabsVariant.fullWidth}
                                        configurations={tabConfigurations}
                                        onTabChanged={(val) => {
                                            setSelectedTab(
                                                val as ContactPanelTabValue,
                                            );
                                            clear();
                                        }}
                                        selectedValue={selectedTab}
                                    />
                                </FormProvider>
                            </ContactDetailPanelActionBodyStyled>
                        }
                        actions={
                            <ContactPanelActionButtonsComponent
                                closeButtonRef={closeButtonRef}
                                selectedContact={selectedContact}
                                findContactById={findContactById}
                                showMessage={showMessage}
                                onCreateContact={onCreateContact}
                                onCloseHandler={onCloseHandler}
                            />
                        }
                        onClose={onCloseHandler}
                    />
                    <ContactPanelConfirmModal
                        isPerson={isPerson}
                        open={isOnCloseModalOpen}
                        onCancel={onClose}
                        onConfirm={() => setOnCloseModalOpen(false)}
                    />
                </>
            );
        },
    );

import {
    FilterCriteria,
    SearchFilterCriteria,
} from 'src/app-features/searches-configuration/domain/models/configured-search';
import { FilterOperation } from 'src/app-features/searches-configuration/domain/models/filter-module-config-option';
import { Search } from 'src/domain/models/search/search.model';

/**
 * After linking filters, we need to add them to the in memory user search
 * @param filterIds Ids of the linked filters
 * @param inMemoryUserSearch The search to which the filters will be linked
 */
export const syncInMemorySearchAfterLinkingFilters = (
    filterIds: number[],
    inMemoryUserSearch?: Search,
) => {
    if (inMemoryUserSearch) {
        filterIds.forEach((filterId) => {
            inMemoryUserSearch.searchData.filterModules.push({
                key: filterId,
                filter: FilterOperation.or,
                value: filterId.toString(),
            });
        });
    }
};

/**
 * After unlinking a filter, we need to remove it from the in memory user search
 * @param filterId Unlinked filter id
 * @param userSearch The search from which the filter was unlinked
 */
export const syncInMemorySearchAfterUnlinkingFilter = (
    filterId: number,
    userSearch?: Search,
) => {
    if (userSearch) {
        userSearch.searchData.filterModules =
            userSearch.searchData.filterModules.filter(
                (filter) => filter.key !== filterId,
            );
    }
};

/**
 * Remove the criteria with empty values
 * @param filterCriteria The full criteria object containing all possible criteria for the type of filter being created
 * @returns A criteria object with the criteria props only where there is at list one filter applied
 */
export const removeEmptyCriteriaFromSearchFilterObject = (
    filterCriteria: SearchFilterCriteria,
) => {
    const filterModuleData: SearchFilterCriteria = {};

    for (const [key, value] of Object.entries(filterCriteria)) {
        if (value.length) {
            if (key === 'location') {
                filterModuleData[key] = prepareLocationCriteriaPayload(
                    value,
                ) as any;
            } else {
                filterModuleData[key] = value;
            }
        }
    }

    return filterModuleData;
};

/**
 * Differently from other filters, the location filter criteria needs to be an array of string containing
 * all location properties. This function prepares the payload for it.
 * @param locations The array of location criteria objects
 * @returns
 */
const prepareLocationCriteriaPayload = (
    locations: FilterCriteria[],
): string[] => {
    const locationsPayload = locations.map((locationCriteriaObj) => {
        const keyPropAsObj = JSON.parse(locationCriteriaObj.key);
        const { key, value, ...rest } = keyPropAsObj;

        const locAux = {
            key: locationCriteriaObj.value,
            filter: locationCriteriaObj.filter,
            value: locationCriteriaObj.value,
            ...rest,
        } as FilterCriteria;

        return JSON.stringify(locAux);
    });

    return locationsPayload;
};

import { AbortParams } from 'src/data/api/api-client';
import { PipelineStatisticsFragment } from 'src/data/api/graphql/br_process/generated/graphql-sdk';
import { ProcessGqlSdk } from 'src/data/api/graphql/graphql-client.wrapper';

export interface IPipelineStatisticsAPI {
    getPipelineStatistics: (
        params: GetPipelineStatisticsParams,
    ) => Promise<PipelineStatisticsFragment | null>;
}

export const createPipelineStatisticsApi = (
    processGqlSdk: ProcessGqlSdk,
): IPipelineStatisticsAPI => {
    const getPipelineStatistics = async (
        params: GetPipelineStatisticsParams,
    ) => {
        const response = await processGqlSdk.GetPipelineStatistics(params, {
            signal: params.signal,
        });
        return response.pipelineStatistics ?? null;
    };

    return { getPipelineStatistics };
};

interface GetPipelineStatisticsParams extends AbortParams {
    forCurrentUser: boolean;
    pipelineId: string;
}

import {
    ArticleDocumentPartFragment,
    FolderTag,
    ProjectPartFragment,
    TenderDocumentPartFragment,
} from 'src/data/api/graphql/br_project/generated/graphql-sdk';
import { RawPopulatedDocumentGroup } from 'src/data/api/lookup-tool/lookup-tool.api';
import {
    createDocument,
    createDocumentLegacy,
    Document,
} from 'src/domain/models/document/document.model';
import { FolderType } from 'src/domain/models/folder/folder.model';
import { ProjectAiGeneratedData } from './project-ai-generated-data';

interface DocGroupData {
    pipeDbCollection: string;
}
export interface Lead {
    id: string;
    searchIds: number[];
    mergedDocument: Document;
    documents: Document[];
    updatedInProduct: Date;
    createdInProduct: Date;
    folderUpdated: Date;
    userFolderTag: UserFolderTag;
    docGroupData: DocGroupData | null;
    withGeneratedData?: boolean;
}

export const createLead = (
    raw: ProjectPartFragment,
    generatedProjectsData: ProjectAiGeneratedData[],
): Lead => {
    const generatedDataMap = new Map<string, ProjectAiGeneratedData>();
    generatedProjectsData.forEach((data) => {
        generatedDataMap.set(data.dgrpId, data);
    });

    const mergedDocument = createDocument(
        raw.mergedDocument as
            | ArticleDocumentPartFragment
            | TenderDocumentPartFragment,
        raw.docGroupId,
        generatedDataMap,
    );

    return {
        id: raw.id.toString(),
        mergedDocument,
        documents: raw.documents.length
            ? raw.documents.map((doc) =>
                  createDocument(
                      doc as
                          | ArticleDocumentPartFragment
                          | TenderDocumentPartFragment,
                      raw.docGroupId,
                  ),
              )
            : [mergedDocument],
        searchIds: raw.searchIds,
        updatedInProduct: new Date(raw.updated),
        createdInProduct: new Date(raw.created),
        userFolderTag: FolderTagSdkToUserFolderTag[
            raw.folderTag
        ] as UserFolderTag,
        folderUpdated: new Date(raw.folderUpdated),
        docGroupData: null,
        withGeneratedData: !!generatedDataMap.get(raw.docGroupId),
    };
};

/**
 * @TODO when backend removes three different exported folder,
 * we could merge it with FolderType enum and use only one of them.
 */
export enum UserFolderTag {
    exportedAsPDF = 'exported_as_pdf',
    exportedAsCSV = 'exported_as_csv',
    exportedToCRM = 'exported_to_crm',
    archived = 'done',
    notInteresting = 'not_interesting',
    snoozed = 'snoozed',
    interesting = 'interesting',
    inbox = 'inbox',
}

const FolderTagSdkToUserFolderTag: { [key in FolderTag]?: UserFolderTag } = {
    [FolderTag.Inbox]: UserFolderTag.inbox,
    [FolderTag.Interesting]: UserFolderTag.interesting,
    [FolderTag.Snoozed]: UserFolderTag.snoozed,
    [FolderTag.NotInteresting]: UserFolderTag.notInteresting,
    [FolderTag.Done]: UserFolderTag.archived,
    [FolderTag.ExportedToCrm]: UserFolderTag.exportedAsPDF,
    [FolderTag.ExportedAsCsv]: UserFolderTag.exportedAsCSV,
    [FolderTag.ExportedAsPdf]: UserFolderTag.exportedAsPDF,
};

export const UserFolderTagToFolderTagSdk: {
    [key in UserFolderTag]?: FolderTag;
} = {
    [UserFolderTag.inbox]: FolderTag.Inbox,
    [UserFolderTag.interesting]: FolderTag.Interesting,
    [UserFolderTag.snoozed]: FolderTag.Snoozed,
    [UserFolderTag.notInteresting]: FolderTag.NotInteresting,
    [UserFolderTag.archived]: FolderTag.Done,
    [UserFolderTag.exportedAsCSV]: FolderTag.ExportedAsCsv,
    [UserFolderTag.exportedAsPDF]: FolderTag.ExportedAsPdf,
    [UserFolderTag.exportedToCRM]: FolderTag.ExportedToCrm,
};

export const folderTagToVisibleFolder: Record<UserFolderTag, FolderType> = {
    [UserFolderTag.inbox]: FolderType.inbox,
    [UserFolderTag.interesting]: FolderType.interesting,
    [UserFolderTag.notInteresting]: FolderType.notInteresting,
    [UserFolderTag.archived]: FolderType.done,
    [UserFolderTag.snoozed]: FolderType.interesting,
    [UserFolderTag.exportedToCRM]: FolderType.exported,
    [UserFolderTag.exportedAsCSV]: FolderType.exported,
    [UserFolderTag.exportedAsPDF]: FolderType.exported,
};

export const createLeadFromDocGroup = (
    documentGroup: RawPopulatedDocumentGroup,
): Lead => {
    return {
        id: documentGroup.doc_group_id,
        searchIds: [0],
        mergedDocument: createDocumentLegacy(documentGroup.merged_document),
        documents: [createDocumentLegacy(documentGroup.merged_document)],
        userFolderTag: UserFolderTag.interesting,
        createdInProduct: new Date(documentGroup.doc_group_created),
        updatedInProduct: new Date(documentGroup.doc_group_updated),
        folderUpdated: new Date(),
        docGroupData: { pipeDbCollection: documentGroup.pipe_db_collection },
    };
};

import { IContactDataContext } from 'src/domain/features/actions/quick-actions/email-templates/data-context/contact-data-context';
import { getFullName } from 'src/domain/models/user/user.model';

export const generatePipeFunctions = (
    contactDataContext: IContactDataContext,
): Record<string, () => string> => {
    return {
        germanSalute: () => {
            const gender = contactDataContext.customFields.find(
                ({ name }) => name === 'gender',
            )?.value;

            if (gender) {
                if (['männlich', 'male', 'm'].includes(gender.toLowerCase())) {
                    return `Sehr geehrter Herr ${contactDataContext.lastName}`;
                } else if (
                    ['female', 'f', 'weiblich', 'w'].includes(
                        gender.toLowerCase(),
                    )
                ) {
                    return `Sehr geehrte Frau ${contactDataContext.lastName}`;
                }
            }
            const fullName = getFullName(
                contactDataContext.firstName,
                contactDataContext.lastName,
            );
            return `Guten Tag ${fullName}`;
        },
    };
};

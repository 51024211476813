import {
    BrButton,
    BrButtonColor,
    BrButtonVariant,
} from '@buildingradar/br_component_lib';
import { observer } from 'mobx-react';
import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { DealContactCompanyForm } from 'src/app-features/contact/data/model/deal-contacts-form.model';
import { DealContactCompany } from 'src/app-features/contact/data/model/deal-contacts.model';
import { ContactUpdateMetricsInfo } from 'src/app-features/contact/domain/deal-contacts-manage.feature.interface';
import {
    GoogleEnrichmentData,
    GoogleEnrichmentResultKey,
} from 'src/app-features/google-enrichment/data/google-enrichment.model';
import { ToasterMessage } from 'src/domain/features/toaster/toaster.utils';
import { Language } from 'src/domain/models/locale/locale.model';

import { buildGoogleEnrichmentCompanyUpdatePayload } from './utils';

interface GoogleEnrichmentActionsComponentProps {
    dealId: string;
    isGoogleEnrichmentLoading: boolean;
    isPossibleCompanyMatchesLoading: boolean;
    enrichmentData: GoogleEnrichmentData | null;
    selectedData: Map<GoogleEnrichmentResultKey, string>;
    selectedCompany?: DealContactCompany;
    customFields: string[];
    language?: Language;
    onClose: () => void;
    updateCompany: (
        dealId: string,
        id: string,
        update: DealContactCompanyForm,
        metricsInfo?: ContactUpdateMetricsInfo,
        onSuccess?: (company: DealContactCompany) => void,
    ) => void;
    showMessage: (message: ToasterMessage) => void;
}
export const GoogleEnrichmentActionsComponent: FC<GoogleEnrichmentActionsComponentProps> =
    observer(
        ({
            dealId,
            isGoogleEnrichmentLoading,
            isPossibleCompanyMatchesLoading,
            selectedData,
            selectedCompany,
            customFields,
            language,
            onClose,
            updateCompany,
            showMessage,
        }) => {
            const { t } = useTranslation();

            const onSaveSuccess = useCallback(() => {
                showMessage({
                    title: t('actions.google_enrichment.toast.save_success'),
                    type: 'success',
                });
                onClose();
            }, [onClose, showMessage, t]);

            const onSave = useCallback(() => {
                if (!selectedCompany) {
                    return;
                }
                const { form, metricsInfo } =
                    buildGoogleEnrichmentCompanyUpdatePayload(
                        selectedCompany.notes ?? '',
                        selectedData,
                        customFields,
                        t,
                        language ?? Language.De,
                    );
                updateCompany(
                    dealId,
                    selectedCompany?.id,
                    form,
                    metricsInfo,
                    onSaveSuccess,
                );
            }, [
                customFields,
                dealId,
                language,
                onSaveSuccess,
                selectedCompany,
                selectedData,
                t,
                updateCompany,
            ]);

            return (
                <>
                    <BrButton
                        className="save-google-result-to-company"
                        fullWidth
                        onClick={onSave}
                        disabled={
                            isGoogleEnrichmentLoading ||
                            isPossibleCompanyMatchesLoading ||
                            !selectedData.size ||
                            !selectedCompany
                        }
                    >
                        {t('actions.google_enrichment.button.save')}
                    </BrButton>
                    <BrButton
                        variant={BrButtonVariant.outlined}
                        color={BrButtonColor.standard}
                        fullWidth
                        onClick={onClose}
                    >
                        {t('common.close')}
                    </BrButton>
                </>
            );
        },
    );

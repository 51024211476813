import { observer } from 'mobx-react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { DomainEnrichmentOption } from 'src/app-features/contact-enrichment/domain/contact-enrichment.model';
import { EnrichmentSourcesBadgeComponent } from 'src/app-features/contact-enrichment/presentation/components/source-badge/source-badge.component';
import { Link } from 'src/presentation/shared/link/link.component';
import { addProtocolIfNeeded } from 'src/utils/url.utils';

import { Button } from '@buildingradar/ui_library/button';
import clsx from 'clsx';
import { Checkbox } from '@buildingradar/ui_library/checkbox';

interface SearchRefinementDomainSelectionProps {
    results: DomainEnrichmentOption[];
    onDomainSelected: (domain?: DomainEnrichmentOption) => void;
    useCheckbox?: boolean;
    selectedOption?: DomainEnrichmentOption;
}
export const SearchRefinementDomainSelection: FC<SearchRefinementDomainSelectionProps> =
    observer(({ results, useCheckbox, selectedOption, onDomainSelected }) => {
        const { t } = useTranslation();

        const handleOnSelect =
            (option: string) =>
            (_e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                const selected = results.find((item) => item.domain === option);
                if (selected) {
                    onDomainSelected(selected);
                }
            };
        return (
            <>
                {results.map((result, index) => (
                    <div
                        className={clsx(
                            'group mx-0 my-1 flex h-12 items-center justify-between rounded border border-solid border-transparent',
                            {
                                'p-2 transition-[border-color] duration-300':
                                    !useCheckbox,
                                'hover:border-indigo-100 hover:shadow-card':
                                    !useCheckbox,
                                '!border-indigo-100 shadow-card':
                                    !useCheckbox && index === 0,
                            },
                        )}
                        key={result.domain}
                    >
                        <div className="flex items-center gap-2">
                            {useCheckbox && (
                                <Checkbox
                                    className="border-2"
                                    checked={
                                        selectedOption?.domain === result.domain
                                    }
                                    onCheckedChange={(checked) =>
                                        onDomainSelected(
                                            checked ? result : undefined,
                                        )
                                    }
                                />
                            )}
                            <EnrichmentSourcesBadgeComponent
                                providers={result.providers}
                                highlight={(result.providers?.length ?? 0) > 1}
                            />
                            <Link
                                href={addProtocolIfNeeded(result.domain)}
                                target="_blank"
                            >
                                <div className="w-[160px] overflow-clip text-ellipsis text-nowrap tracking-wide text-indigo-700">
                                    {result.domain}
                                </div>
                            </Link>
                        </div>
                        {!useCheckbox && (
                            <Button
                                className={clsx(
                                    'select-btn w-fit-content hidden h-8 px-1 text-sm',
                                    {
                                        'group-hover:inline-flex': !useCheckbox,
                                        '!inline-flex':
                                            !useCheckbox && index === 0,
                                    },
                                )}
                                size="sm"
                                variant="outline"
                                onClick={handleOnSelect(result.domain)}
                            >
                                {t('common.select')}
                            </Button>
                        )}
                    </div>
                ))}
            </>
        );
    });

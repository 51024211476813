import { StrictMode, Suspense } from 'react';
import { createRoot } from 'react-dom/client';

import { App } from './app';
import { initializeSentry } from './sentry/instrument';

import './index.css';
import '@buildingradar/ui_library/styles.css';

import './resources/translations/i18next';
import { PostHogProvider } from 'posthog-js/react';
import { PostHogConfig } from 'posthog-js';
import { getRoot } from './utils/element.utils';

const options: Partial<PostHogConfig> = {
    api_host: import.meta.env.VITE_PUBLIC_POSTHOG_HOST,
    disable_session_recording: true,
    session_recording: {
        maskAllInputs: false,
        maskInputOptions: {
            password: true,
        },
    },
};

initializeSentry();

const container = getRoot();
const root = createRoot(container as HTMLElement);

root.render(
    <StrictMode>
        <PostHogProvider
            apiKey={import.meta.env.VITE_PUBLIC_POSTHOG_KEY}
            options={options}
        >
            <Suspense fallback="Loading translations, please wait">
                <App />
            </Suspense>
        </PostHogProvider>
    </StrictMode>,
);

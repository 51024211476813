import { makeAutoObservable } from 'mobx';

import { ActionFeaturesIdentifiers } from 'src/domain/features/account-configuration/helpers/action-feature.helper';

import {
    IQuickActionsStoreInterface,
    QuickActionData,
} from './quick-actions.store.interface';

export class QuickActionsStoreImpl implements IQuickActionsStoreInterface {
    currentQuickAction?: ActionFeaturesIdentifiers = undefined;
    selectedEntityId?: string = undefined;
    selectedEntity?: any = undefined;
    selectedDealId?: string = undefined;

    setSelectedEntityId = (entityId?: string) => {
        this.selectedEntityId = entityId;
    };

    setSelectedEntity = (entity?: any) => {
        this.selectedEntity = entity;
    };

    clean = () => {
        this.setQuickAction({});
    };

    constructor() {
        makeAutoObservable(this);
    }

    setQuickAction = (data: QuickActionData) => {
        const { dealId, entityId, entity, quickAction } = data;
        this.currentQuickAction = quickAction;
        this.selectedDealId = dealId;
        this.selectedEntityId = entityId;
        this.selectedEntity = entity;
    };
}

import {
    BrButtonColor,
    BrButtonSize,
    BrButtonVariant,
    BrInfotip,
    BrTextInput,
    IconName,
} from '@buildingradar/br_component_lib';
import { Button } from '@buildingradar/ui_library/button';
import { Checkbox, CheckedState } from '@buildingradar/ui_library/checkbox';
import { observer } from 'mobx-react';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DomainEnrichmentOption } from 'src/app-features/contact-enrichment/domain/contact-enrichment.model';
import {
    DomainInputField,
    SearchIconButtonStyled,
} from 'src/app-features/contact-enrichment/presentation/components/search-refinement/styles';
import { createGoogleQueryLink } from 'src/app-features/quick-actions/utils/quick-actions.utils';
import { ActionPanelLabelStyled } from 'src/presentation/modules/enablement-actions-panel/actions/styled';
import { googleSearchTooltipText } from 'src/presentation/shared/web-icons-panel/utils';
import { isNotEmpty } from 'src/utils/string.utils';

const CUSTOM_INPUT_CHECKBOX_IDENTIFIER = 'CUSTOM_INPUT';
interface CustomDomainInputProps {
    initialDomain?: string | null;
    relevantCompany?: { id: string; name: string };
    onDomainSelected: (domain?: DomainEnrichmentOption) => void;
    useCheckbox?: boolean;
    selectedOption?: DomainEnrichmentOption;
}

export const CustomDomainInput: FC<CustomDomainInputProps> = observer(
    ({
        initialDomain,
        relevantCompany,
        selectedOption,
        useCheckbox,
        onDomainSelected,
    }) => {
        const { t } = useTranslation();

        const [domainName, setDomainName] = useState<string>(
            initialDomain ?? '',
        );

        const buildOption = (domain: string) => ({
            domain,
            name: CUSTOM_INPUT_CHECKBOX_IDENTIFIER,
            providers: [],
            isCustom: true,
        });
        const contactSearchTerm = relevantCompany ? relevantCompany.name : '';

        const handleCustomDomainChanged = (domain: string) => {
            setDomainName(domain);
            if (selectedOption?.name === CUSTOM_INPUT_CHECKBOX_IDENTIFIER) {
                onDomainSelected(buildOption(domain));
            }
        };

        const handleDomainSelected = () => {
            onDomainSelected(buildOption(domainName));
        };

        const handleCheckboxChanged = (selected?: CheckedState) => {
            onDomainSelected(selected ? buildOption(domainName) : undefined);
        };

        const onSearchButtonClickHandler = () => {
            window.open(createGoogleQueryLink(contactSearchTerm), '_blank');
        };

        return (
            <>
                <ActionPanelLabelStyled>
                    {t(
                        'actions.contact_enrichment.search_refinement.input.company_label',
                    )}
                </ActionPanelLabelStyled>
                <DomainInputField>
                    {useCheckbox && (
                        <Checkbox
                            className="border-2"
                            checked={
                                selectedOption?.name ===
                                CUSTOM_INPUT_CHECKBOX_IDENTIFIER
                            }
                            onCheckedChange={handleCheckboxChanged}
                        />
                    )}

                    <BrInfotip
                        content={googleSearchTooltipText(contactSearchTerm, t)}
                        placement="top"
                        showArrow
                    >
                        <SearchIconButtonStyled
                            className="google-search-icon"
                            size={BrButtonSize.small}
                            color={BrButtonColor.standard}
                            variant={BrButtonVariant.text}
                            startIcon={IconName.BrSearchWebIcon}
                            onClick={onSearchButtonClickHandler}
                        />
                    </BrInfotip>
                    <BrTextInput
                        margin="none"
                        className="custom-domain-input"
                        placeholder={t(
                            'actions.contact_enrichment.search_refinement.input.company_placeholder',
                        )}
                        value={domainName}
                        onChange={handleCustomDomainChanged}
                    />
                    {!useCheckbox && (
                        <Button
                            disabled={!isNotEmpty(domainName)}
                            className="select-btn-custom-input w-fit-content h-8 px-1 text-sm"
                            variant="outline"
                            size="sm"
                            color={
                                !isNotEmpty(domainName)
                                    ? 'secondary'
                                    : 'primary'
                            }
                            onClick={handleDomainSelected}
                        >
                            {t('common.select')}
                        </Button>
                    )}
                </DomainInputField>
            </>
        );
    },
);

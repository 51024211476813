import { AbortParams } from 'src/data/api/api-client';
import { ProjectGqlSdk } from 'src/data/api/graphql/graphql-client.wrapper';
import {
    createFolderCounters,
    FolderCounters,
    RawFolderCounters,
} from 'src/domain/models/folder/folder.model';

interface GetFolderCountersParams extends AbortParams {
    searchTags: number[];
}
export interface FolderCountersApi {
    getFolderCounters: (
        params: GetFolderCountersParams,
    ) => Promise<FolderCounters>;
}

export const createFolderCountersApi = (
    projectGqlSdk: ProjectGqlSdk,
): FolderCountersApi => {
    const getFolderCounters = async (
        params: GetFolderCountersParams,
    ): Promise<FolderCounters> => {
        const { getAllFolderCounts } =
            await projectGqlSdk.GetAllFoldersCounter(params);
        const rawFolderCounters =
            getAllFolderCounts.folderCounts as RawFolderCounters;

        return createFolderCounters(rawFolderCounters);
    };
    return {
        getFolderCounters,
    };
};

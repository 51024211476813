import { BrToastify } from '@buildingradar/br_component_lib';
import styled from '@emotion/styled';

import { MediaQuery } from 'src/resources/styles/media.mixins';

export const BrToastifyStyled = styled(BrToastify)({
    [MediaQuery.phone]: {
        '&&&.Toastify__toast-container': {
            maxWidth: 'calc(100% - 24px)',
        },
        '&.Toastify__toast-container--bottom-left ': {
            bottom: '12px',
            left: '12px',
        },
    },
});

export const GrayIcon = () => {
    return (
        <svg
            width="72"
            height="72"
            viewBox="0 0 72 72"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <mask
                id="mask0_806_2510"
                style={{ maskType: 'alpha' }}
                maskUnits="userSpaceOnUse"
                x="13"
                y="8"
                width="46"
                height="56"
            >
                <path
                    d="M13.32 15.6268C13.32 14.2785 14.2554 13.1107 15.5712 12.8163L35.2211 8.41972C35.6325 8.32767 36.0591 8.32706 36.4707 8.41792L56.4207 12.8213C57.7404 13.1126 58.68 14.2822 58.68 15.6336V47.0528C58.68 48.019 58.1956 48.9207 57.39 49.4541L37.4466 62.6599C36.4772 63.3018 35.2169 63.2979 34.2515 62.6499L14.5949 49.4557C13.7981 48.9209 13.32 48.0242 13.32 47.0645V15.6268Z"
                    fill="white"
                />
            </mask>
            <g mask="url(#mask0_806_2510)">
                <mask
                    id="mask1_806_2510"
                    style={{ maskType: 'alpha' }}
                    maskUnits="userSpaceOnUse"
                    x="10"
                    y="4"
                    width="52"
                    height="63"
                >
                    <path
                        d="M10.08 14.6937C10.08 11.9971 11.9508 9.66142 14.5823 9.07264L34.5745 4.5995C35.3973 4.41542 36.2504 4.41419 37.0737 4.5959L57.4015 9.08267C60.0408 9.66522 61.92 12.0045 61.92 14.7073V47.3058C61.92 49.238 60.9512 51.0415 59.3401 52.1083L39.0255 65.5599C37.0867 66.8437 34.566 66.8358 32.6353 65.5398L12.6298 52.1115C11.0361 51.0418 10.08 49.2485 10.08 47.329V14.6937Z"
                        fill="url(#paint0_linear_806_2510)"
                    />
                </mask>
                <g mask="url(#mask1_806_2510)">
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M37.924 43.6756C39.7854 43.6756 41.5034 43.0618 42.8867 42.0255C43.4708 42.2422 44.1026 42.3606 44.7622 42.3606C46.4511 42.3606 47.9586 41.5841 48.9472 40.3686C49.7651 41.2692 50.9454 41.8346 52.2578 41.8346C53.1437 41.8346 53.9693 41.577 54.664 41.1326C55.3307 41.5762 56.1311 41.8346 56.9919 41.8346C59.316 41.8346 61.2 39.9506 61.2 37.6265C61.2 35.3025 59.316 33.4184 56.9919 33.4184C56.2979 33.4184 55.643 33.5865 55.0659 33.8841C54.2984 33.2639 53.3215 32.8924 52.2578 32.8924C51.1147 32.8924 50.0718 33.3214 49.2812 34.0272C48.4104 32.6922 46.964 31.7669 45.2941 31.6033C43.9189 28.9328 41.1348 27.1063 37.924 27.1063C34.8562 27.1063 32.1779 28.7738 30.7459 31.2519C29.952 30.9523 29.0916 30.7884 28.1929 30.7884C25.9115 30.7884 23.8768 31.8446 22.5512 33.4948C22.2916 33.4447 22.0235 33.4184 21.7492 33.4184C21.1949 33.4184 20.6655 33.5256 20.1808 33.7204C19.4345 32.8893 18.3516 32.3664 17.1466 32.3664C15.8364 32.3664 14.6706 32.9845 13.9248 33.9451C11.9942 33.9801 10.44 35.5561 10.44 37.495C10.44 39.456 12.0296 41.0456 13.9906 41.0456C14.766 41.0456 15.4834 40.797 16.0674 40.3752C16.4111 40.4693 16.773 40.5196 17.1466 40.5196C17.6184 40.5196 18.0714 40.4395 18.4929 40.2921C19.2646 41.2337 20.4367 41.8346 21.7492 41.8346C21.847 41.8346 21.9439 41.8313 22.04 41.8247C23.315 43.8828 25.5938 45.2537 28.1929 45.2537C30.4583 45.2537 32.4805 44.2121 33.8067 42.5817C35.0196 43.2777 36.4253 43.6756 37.924 43.6756Z"
                        fill="white"
                    />
                    <path
                        d="M2.38728 35.5696L1.08002 37.547V40.68H18.72V39.3373L17.715 37.1814C17.4738 36.664 16.7551 36.6197 16.4521 37.1036L15.7326 38.2531L14.7803 36.7318C14.5378 36.3444 14.0005 36.2781 13.671 36.5948L13.0297 37.2113L12.105 35.4657C11.8665 35.0156 11.2488 34.9486 10.9195 35.3372L9.33099 37.2113L7.94123 33.8539C7.70545 33.2843 6.90952 33.2551 6.63266 33.806L4.92098 37.2113L3.54763 35.5139C3.24144 35.1354 2.65575 35.1636 2.38728 35.5696Z"
                        fill="#098566"
                    />
                    <path
                        d="M14.4945 35.4139L13.32 37.0119V39.6H29.52V38.4908L28.6318 36.7769C28.3824 36.2958 27.7111 36.2521 27.4015 36.6969L26.7765 37.5951L25.9269 36.3743C25.6827 36.0234 25.1883 35.9614 24.865 36.241L24.2942 36.7346L23.4744 35.3426C23.231 34.9292 22.659 34.8651 22.33 35.2142L20.8974 36.7346L19.6603 34.0462C19.4142 33.5113 18.6649 33.482 18.3777 33.996L16.8474 36.7346L15.61 35.3588C15.3047 35.0194 14.7648 35.0461 14.4945 35.4139Z"
                        fill="#B6B6B6"
                    />
                    <path
                        d="M4.58787 36.1826L3.23999 37.8136V40.32H20.88V39.2458L19.8498 37.478C19.5936 37.0383 18.9744 36.996 18.6607 37.3968L17.8926 38.3785L16.91 37.1228C16.6664 36.8115 16.2174 36.7548 15.904 36.9957L15.1897 37.5451L14.2394 36.1101C13.9927 35.7376 13.4699 35.6774 13.1449 35.984L11.491 37.5451L10.0421 35.3735C9.77275 34.9698 9.18924 34.9428 8.88373 35.3199L7.08096 37.5451L5.64911 36.1293C5.34837 35.832 4.8573 35.8566 4.58787 36.1826Z"
                        fill="#098566"
                    />
                    <path
                        d="M16.7027 35.8946L15.48 37.2036V39.24H31.68V38.3672L30.7604 36.9709C30.4996 36.575 29.9339 36.5348 29.6198 36.89L28.9365 37.6625L28.0501 36.6603C27.81 36.3888 27.4055 36.3382 27.1058 36.5421L26.4542 36.9854L25.6028 35.8478C25.3555 35.5174 24.8817 35.4621 24.5649 35.7266L23.0575 36.9854L21.7497 35.2512C21.4786 34.8917 20.9478 34.8664 20.6438 35.1984L19.0075 36.9854L17.7029 35.8442C17.4105 35.5884 16.9679 35.6107 16.7027 35.8946Z"
                        fill="#B6B6B6"
                    />
                    <path
                        d="M66.9852 36.1678L68.4 37.9557V40.68H50.04V39.5124L51.1286 37.5616C51.3825 37.1065 52.021 37.0636 52.3335 37.4807L53.1494 38.5697L54.1835 37.1894C54.4277 36.8636 54.8932 36.8049 55.2106 37.0598L55.9626 37.6638L56.9654 36.0824C57.2111 35.6947 57.752 35.633 58.0789 35.9551L59.8123 37.6638L61.3012 34.6587C61.5538 34.1489 62.27 34.1196 62.5633 34.6071L64.4023 37.6638L65.9034 36.1137C66.2062 35.801 66.7151 35.8264 66.9852 36.1678Z"
                        fill="#B6B6B6"
                    />
                    <path
                        d="M50.0652 36.1678L51.48 37.9557V40.68H33.12V39.5124L34.2085 37.5616C34.4625 37.1065 35.1009 37.0636 35.4135 37.4807L36.2293 38.5697L37.2635 37.1894C37.5076 36.8636 37.9731 36.8049 38.2906 37.0598L39.0426 37.6638L40.0453 36.0824C40.2911 35.6947 40.8319 35.633 41.1588 35.9551L42.8923 37.6638L44.3812 34.6587C44.6338 34.1489 45.35 34.1196 45.6433 34.6071L47.4823 37.6638L48.9834 36.1137C49.2862 35.801 49.7951 35.8264 50.0652 36.1678Z"
                        fill="#B6B6B6"
                    />
                    <path
                        d="M68.7852 35.6755L70.2 37.4634V40.1877H51.84V39.0201L52.9285 37.0693C53.1825 36.6142 53.8209 36.5713 54.1334 36.9884L54.9493 38.0773L55.9835 36.6971C56.2276 36.3713 56.6931 36.3125 57.0105 36.5675L57.7625 37.1715L58.7653 35.5901C59.0111 35.2024 59.5519 35.1406 59.8788 35.4628L61.6122 37.1715L63.1425 34.8801C63.4119 34.4768 63.995 34.4498 64.3005 34.8265L66.2022 37.1715L67.7033 35.6214C68.0062 35.3087 68.5151 35.3341 68.7852 35.6755Z"
                        fill="#B6B6B6"
                    />
                    <path
                        d="M52.2252 36.1678L53.64 37.9557V40.68H35.28V39.5124L36.3685 37.5616C36.6225 37.1065 37.261 37.0636 37.5735 37.4807L38.3894 38.5697L39.4235 37.1894C39.6677 36.8636 40.1332 36.8049 40.4506 37.0598L41.2026 37.6638L42.2053 36.0824C42.4511 35.6947 42.992 35.633 43.3188 35.9552L45.0523 37.6638L46.5826 35.3724C46.852 34.9691 47.435 34.9421 47.7406 35.3188L49.6423 37.6638L51.1434 36.1137C51.4462 35.801 51.9551 35.8264 52.2252 36.1678Z"
                        fill="#B6B6B6"
                    />
                    <rect
                        x="1.08002"
                        y="38.16"
                        width="69.48"
                        height="29.52"
                        fill="url(#paint1_linear_806_2510)"
                    />
                    <path
                        d="M26.9004 38.6977C27.1737 38.3577 27.5864 38.16 28.0227 38.16H46.1374C46.5736 38.16 46.9864 38.3577 47.2597 38.6977L68.677 65.3377C69.4345 66.28 68.7637 67.68 67.5547 67.68H6.60539C5.39633 67.68 4.72554 66.28 5.4831 65.3377L26.9004 38.6977Z"
                        fill="url(#paint2_linear_806_2510)"
                    />
                    <path
                        d="M28.6928 38.3169C28.7599 38.2187 28.8712 38.16 28.9901 38.16H45.1699C45.2889 38.16 45.4001 38.2187 45.4672 38.3169L65.1355 67.1169C65.2987 67.3559 65.1276 67.68 64.8382 67.68H9.32181C9.03245 67.68 8.86134 67.3559 9.02452 67.1169L28.6928 38.3169Z"
                        fill="#B6B6B6"
                    />
                    <path
                        d="M29.88 63.9L31.224 61.2H42.168L43.896 61.92L44.28 66.24L29.88 65.88V63.9Z"
                        fill="url(#paint3_linear_806_2510)"
                    />
                    <path
                        d="M27.0119 58.6037L27.9034 56.2637C28.0097 55.9845 28.2774 55.8 28.5762 55.8H36.36C36.7576 55.8 37.08 56.1223 37.08 56.52V58.3956C37.08 58.7624 36.8041 59.0706 36.4395 59.1112L32.22 59.58H27.6848C27.1805 59.58 26.8324 59.0749 27.0119 58.6037Z"
                        fill="url(#paint4_linear_806_2510)"
                    />
                    <path
                        d="M38.089 58.472L37.8765 56.7721C37.8325 56.4198 38.0518 56.088 38.3932 55.9905L38.963 55.8277C39.0274 55.8093 39.0939 55.8 39.1608 55.8H45.815C46.0877 55.8 46.337 55.9541 46.459 56.198L47.4225 58.125C47.6453 58.5707 47.359 59.1025 46.8643 59.1619L43.4599 59.5704C43.4068 59.5768 43.3532 59.5772 43.3 59.5717L38.7293 59.0989C38.396 59.0644 38.1306 58.8045 38.089 58.472Z"
                        fill="url(#paint5_linear_806_2510)"
                    />
                    <path
                        d="M47.88 57.07V56.5535C47.88 56.143 48.2226 55.8156 48.6327 55.8342L55.4083 56.1422C55.6525 56.1533 55.8744 56.2875 55.9975 56.4987L56.694 57.6925C56.8956 58.0381 56.7767 58.4819 56.4293 58.6804L55.966 58.9451C55.8572 59.0073 55.7341 59.04 55.6088 59.04H49.225C48.9523 59.04 48.703 58.8859 48.581 58.642L47.956 57.392C47.906 57.292 47.88 57.1818 47.88 57.07Z"
                        fill="url(#paint6_linear_806_2510)"
                    />
                    <path
                        d="M18.814 55.8215L17.6992 56.1002C17.5441 56.1389 17.4064 56.2282 17.3077 56.3539L15.5331 58.6124C15.4979 58.6572 15.4692 58.7069 15.4481 58.7598C15.2909 59.1526 15.5803 59.58 16.0034 59.58H24.6123C24.8652 59.58 25.0996 59.4473 25.2297 59.2304L26.4366 57.2189C26.5633 57.0078 26.5733 56.7467 26.4632 56.5265L26.299 56.198C26.177 55.9541 25.9277 55.8 25.655 55.8H18.9886C18.9298 55.8 18.8711 55.8072 18.814 55.8215Z"
                        fill="url(#paint7_linear_806_2510)"
                    />
                    <path
                        d="M24.2026 52.1174L24.8091 51.5109C24.9441 51.3758 25.1273 51.3 25.3182 51.3H32.2892C32.6198 51.3 32.8623 51.6107 32.7821 51.9314C32.7675 51.99 32.7634 52.0507 32.7701 52.1108L32.8512 52.8405C32.8985 53.267 32.5647 53.64 32.1356 53.64H24.565C24.0297 53.64 23.6816 53.0767 23.921 52.598L24.0677 52.3045C24.1023 52.2353 24.1479 52.1721 24.2026 52.1174Z"
                        fill="url(#paint8_linear_806_2510)"
                    />
                    <path
                        d="M33.48 52.92V52.02C33.48 51.6223 33.8023 51.3 34.2 51.3H40.7333C41.1095 51.3 41.4223 51.5896 41.4511 51.9648L41.5204 52.8648C41.5525 53.2829 41.2219 53.64 40.8025 53.64H39.42H34.2C33.8023 53.64 33.48 53.3176 33.48 52.92Z"
                        fill="url(#paint9_linear_806_2510)"
                    />
                    <path
                        d="M42.2719 52.5204L42.3642 52.0588C42.4315 51.7222 42.727 51.48 43.0703 51.48H48.4243C48.7423 51.48 49.0226 51.6885 49.1139 51.9931L49.2439 52.4265C49.3757 52.8657 49.0688 53.3145 48.6117 53.3511L45.0735 53.6341C45.0246 53.638 44.9755 53.6369 44.9268 53.6308L42.8886 53.3761C42.4735 53.3242 42.1899 52.9306 42.2719 52.5204Z"
                        fill="url(#paint10_linear_806_2510)"
                    />
                    <path
                        d="M50.7383 51.48H53.8725C54.1669 51.48 54.4317 51.6592 54.541 51.9326L54.685 52.2926C54.8742 52.7655 54.5259 53.28 54.0165 53.28H50.8103C50.467 53.28 50.1715 53.0377 50.1042 52.7012L50.0322 52.3412C49.9431 51.8957 50.2839 51.48 50.7383 51.48Z"
                        fill="url(#paint11_linear_806_2510)"
                    />
                    <path
                        d="M19.0715 52.481L19.7638 51.581C19.9001 51.4038 20.111 51.3 20.3345 51.3H22.2747C22.8001 51.3 23.1486 51.8446 22.9284 52.3217L22.513 53.2217C22.3954 53.4767 22.1402 53.64 21.8593 53.64H19.6422C19.0448 53.64 18.7073 52.9545 19.0715 52.481Z"
                        fill="url(#paint12_linear_806_2510)"
                    />
                    <path
                        d="M21.254 49.2118L21.9297 48.3333C22.0626 48.1605 22.2667 48.0572 22.4847 48.0525L29.3471 47.9033C29.8589 47.8922 30.2185 48.4038 30.0347 48.8816L29.6938 49.7681C29.5887 50.0414 29.3286 50.2238 29.0359 50.2295L21.8388 50.3706C21.235 50.3825 20.8858 49.6904 21.254 49.2118Z"
                        fill="url(#paint13_linear_806_2510)"
                    />
                    <path
                        d="M31.2272 48.4837L31.4552 48.1797C31.5961 47.9919 31.8197 47.8845 32.0544 47.8921L36.5632 48.0375C36.9516 48.0501 37.26 48.3685 37.26 48.7572V49.32C37.26 49.7176 36.9376 50.04 36.54 50.04H31.7021C31.2337 50.04 30.89 49.5998 31.0036 49.1454L31.1047 48.7411C31.128 48.648 31.1696 48.5605 31.2272 48.4837Z"
                        fill="url(#paint14_linear_806_2510)"
                    />
                    <path
                        d="M38.16 49.3387V48.6C38.16 48.2024 38.4823 47.88 38.88 47.88H43.6055C43.9034 47.88 44.1705 48.0635 44.2775 48.3415L44.6128 49.2134C44.7971 49.6926 44.4351 50.2051 43.9219 50.1916L38.861 50.0585C38.4709 50.0482 38.16 49.729 38.16 49.3387Z"
                        fill="url(#paint15_linear_806_2510)"
                    />
                    <path
                        d="M45.7081 49.6179L45.4472 49.0438C45.2345 48.5759 45.5664 48.0423 46.0801 48.0262L50.4091 47.891C50.6305 47.884 50.8429 47.9795 50.9847 48.1497L51.5759 48.8591C51.9667 49.328 51.6332 50.04 51.0227 50.04H46.3636C46.0812 50.04 45.825 49.875 45.7081 49.6179Z"
                        fill="url(#paint16_linear_806_2510)"
                    />
                    <path
                        d="M23.9048 45.6059L23.9214 45.5869C24.0485 45.4417 24.2284 45.3536 24.421 45.3423L25.9995 45.2494C26.538 45.2178 26.9188 45.7677 26.6997 46.2606L26.65 46.3724C26.5345 46.6324 26.2766 46.8 25.9921 46.8H24.4467C23.8281 46.8 23.4975 46.0714 23.9048 45.6059Z"
                        fill="url(#paint17_linear_806_2510)"
                    />
                    <path
                        d="M33.0824 46.8H28.345C27.8097 46.8 27.4664 46.2273 27.7057 45.7485C27.8243 45.5114 28.0653 45.3551 28.3303 45.3473L33.0953 45.2072C33.5644 45.1934 33.9211 45.6249 33.8193 46.0831L33.7853 46.2362C33.7121 46.5656 33.4199 46.8 33.0824 46.8Z"
                        fill="url(#paint18_linear_806_2510)"
                    />
                    <path
                        d="M34.5747 45.9238L34.6147 45.7438C34.6879 45.4144 34.9801 45.18 35.3175 45.18H39.981C40.2909 45.18 40.5661 45.3783 40.6641 45.6723L40.7241 45.8523C40.8795 46.3185 40.5325 46.8 40.041 46.8H35.2775C34.8169 46.8 34.4748 46.3735 34.5747 45.9238Z"
                        fill="url(#paint19_linear_806_2510)"
                    />
                    <path
                        d="M45.8153 46.8H42.1421C41.8118 46.8 41.5238 46.5751 41.4436 46.2546C41.33 45.8002 41.6737 45.36 42.1421 45.36H45.6723C45.9252 45.36 46.1596 45.4927 46.2897 45.7096L46.3633 45.8321C46.5141 46.0836 46.4745 46.4055 46.2672 46.6128C46.1473 46.7327 45.9848 46.8 45.8153 46.8Z"
                        fill="url(#paint20_linear_806_2510)"
                    />
                    <path
                        d="M49.6409 46.8H48.1989C47.8988 46.8 47.6302 46.6138 47.5248 46.3328C47.3483 45.8621 47.6962 45.36 48.1989 45.36H49.6409C49.8892 45.36 50.1199 45.4879 50.2515 45.6984C50.5512 46.1779 50.2064 46.8 49.6409 46.8Z"
                        fill="url(#paint21_linear_806_2510)"
                    />
                    <path
                        d="M30.8959 44.46H25.7301C25.1409 44.46 24.8045 43.7873 25.158 43.316C25.2973 43.1303 25.5179 43.0238 25.7499 43.0302L30.9137 43.1737C31.3677 43.1863 31.666 43.6527 31.4871 44.0701C31.3858 44.3066 31.1532 44.46 30.8959 44.46Z"
                        fill="url(#paint22_linear_806_2510)"
                    />
                    <path
                        d="M36.6413 44.3013L33.0908 44.4237C32.5906 44.441 32.2335 43.9442 32.4092 43.4755C32.512 43.2015 32.7739 43.02 33.0666 43.02H36.6192C36.9731 43.02 37.26 43.3069 37.26 43.6608C37.26 44.0062 36.9864 44.2894 36.6413 44.3013Z"
                        fill="url(#paint23_linear_806_2510)"
                    />
                    <path
                        d="M42.2169 44.28H38.4661C38.1784 44.28 37.9256 44.0892 37.8465 43.8126C37.7314 43.4098 38.0252 43.006 38.4439 42.9916L42.1924 42.8623C42.5591 42.8497 42.8749 43.1189 42.9204 43.483C42.9733 43.9062 42.6433 44.28 42.2169 44.28Z"
                        fill="url(#paint24_linear_806_2510)"
                    />
                    <path
                        d="M47.5558 44.28H44.19C43.8421 44.28 43.56 43.9979 43.56 43.65C43.56 43.302 43.8421 43.02 44.19 43.02H47.5558C47.7593 43.02 47.9502 43.1182 48.0685 43.2838C48.3663 43.7008 48.0683 44.28 47.5558 44.28Z"
                        fill="url(#paint25_linear_806_2510)"
                    />
                    <path
                        d="M44.829 42.3H46.5838C46.9611 42.3 47.1709 41.8635 46.9352 41.5689C46.8498 41.4621 46.7205 41.4 46.5838 41.4H44.829C44.545 41.4 44.332 41.6598 44.3877 41.9382C44.4298 42.1486 44.6145 42.3 44.829 42.3Z"
                        fill="url(#paint26_linear_806_2510)"
                    />
                    <path
                        d="M40.485 42.1381L42.9884 42.2699C43.2782 42.2851 43.5047 42.0232 43.4478 41.7387C43.4079 41.5394 43.2381 41.3925 43.0351 41.3818L40.5317 41.2501C40.2419 41.2348 40.0154 41.4967 40.0723 41.7813C40.1122 41.9806 40.282 42.1275 40.485 42.1381Z"
                        fill="url(#paint27_linear_806_2510)"
                    />
                    <path
                        d="M35.01 42.3H39.0511C39.3351 42.3 39.5481 42.0402 39.4924 41.7617C39.4503 41.5514 39.2656 41.4 39.0511 41.4H35.01C34.7615 41.4 34.56 41.6014 34.56 41.85C34.56 42.0985 34.7615 42.3 35.01 42.3Z"
                        fill="url(#paint28_linear_806_2510)"
                    />
                    <path
                        d="M30.0103 42.1554L33.1239 42.2851C33.3406 42.2942 33.5425 42.1752 33.6395 41.9812C33.8181 41.6239 33.5689 41.2012 33.1698 41.1845L30.0562 41.0548C29.8395 41.0458 29.6376 41.1648 29.5406 41.3588C29.362 41.7161 29.6112 42.1388 30.0103 42.1554Z"
                        fill="url(#paint29_linear_806_2510)"
                    />
                    <path
                        d="M26.929 42.3H27.971C28.1516 42.3 28.3202 42.2097 28.4204 42.0595C28.6596 41.7007 28.4023 41.22 27.971 41.22H26.929C26.7485 41.22 26.5799 41.3102 26.4797 41.4604C26.2405 41.8193 26.4977 42.3 26.929 42.3Z"
                        fill="url(#paint30_linear_806_2510)"
                    />
                    <path
                        d="M32.299 40.5H28.0492C27.7284 40.5 27.5678 40.1122 27.7946 39.8854C27.8621 39.8179 27.9537 39.78 28.0492 39.78H32.299C32.5332 39.78 32.705 40.0001 32.6482 40.2273C32.6082 40.3875 32.4642 40.5 32.299 40.5Z"
                        fill="url(#paint31_linear_806_2510)"
                    />
                    <path
                        d="M36.6276 40.5205L33.7942 40.6493C33.4784 40.6637 33.2533 40.3469 33.3707 40.0534C33.4367 39.8882 33.5967 39.78 33.7745 39.78H36.6108C36.7808 39.78 36.929 39.8957 36.9702 40.0606C37.027 40.2877 36.8615 40.5099 36.6276 40.5205Z"
                        fill="url(#paint32_linear_806_2510)"
                    />
                    <path
                        d="M40.8992 40.68H38.1154C37.925 40.68 37.7537 40.564 37.6829 40.3872C37.564 40.0899 37.7735 39.7641 38.0933 39.7489L40.8739 39.6165C41.0843 39.6064 41.2808 39.7215 41.3751 39.91C41.5519 40.2637 41.2947 40.68 40.8992 40.68Z"
                        fill="url(#paint33_linear_806_2510)"
                    />
                    <path
                        d="M45.5856 40.68H42.7573C42.5841 40.68 42.4223 40.5934 42.3263 40.4493C42.091 40.0964 42.3562 39.6259 42.7798 39.6443L45.6054 39.7672C45.7848 39.775 45.9429 39.8871 46.0096 40.0538C46.1295 40.3537 45.9087 40.68 45.5856 40.68Z"
                        fill="url(#paint34_linear_806_2510)"
                    />
                    <path
                        d="M44.0211 39.24H44.82C45.1167 39.24 45.286 38.9013 45.108 38.664C45.04 38.5733 44.9333 38.52 44.82 38.52H44.0211C43.7869 38.52 43.615 38.7401 43.6719 38.9673C43.7119 39.1275 43.8559 39.24 44.0211 39.24Z"
                        fill="url(#paint35_linear_806_2510)"
                    />
                    <path
                        d="M39.8811 39.24H42.6175C42.8851 39.24 43.0592 38.9583 42.9395 38.719C42.8785 38.597 42.7539 38.52 42.6175 38.52H39.8811C39.6469 38.52 39.475 38.7401 39.5319 38.9673C39.5719 39.1275 39.7159 39.24 39.8811 39.24Z"
                        fill="url(#paint36_linear_806_2510)"
                    />
                    <path
                        d="M35.3811 39.24H38.4189C38.6531 39.24 38.825 39.0199 38.7682 38.7926C38.7281 38.6324 38.5841 38.52 38.4189 38.52H35.3811C35.2159 38.52 35.0719 38.6324 35.0319 38.7926C34.975 39.0199 35.1469 39.24 35.3811 39.24Z"
                        fill="url(#paint37_linear_806_2510)"
                    />
                    <path
                        d="M31.2411 39.24H33.9775C34.1139 39.24 34.2385 39.1629 34.2995 39.041C34.4192 38.8016 34.2451 38.52 33.9775 38.52H31.2411C31.0759 38.52 30.9319 38.6324 30.8919 38.7926C30.835 39.0199 31.0069 39.24 31.2411 39.24Z"
                        fill="url(#paint38_linear_806_2510)"
                    />
                    <path
                        d="M28.62 39.24H29.7C29.8133 39.24 29.92 39.1866 29.988 39.096C30.166 38.8586 29.9967 38.52 29.7 38.52H28.62C28.5067 38.52 28.4 38.5733 28.332 38.664C28.154 38.9013 28.3234 39.24 28.62 39.24Z"
                        fill="url(#paint39_linear_806_2510)"
                    />
                    <path
                        d="M29.52 61.2H20.16L18.18 64.26L17.82 66.24H28.08L29.16 63.36L29.52 61.2Z"
                        fill="url(#paint40_linear_806_2510)"
                    />
                    <path
                        d="M17.82 61.2H13.32H12.06L9.54004 65.16L9.90004 66.42H14.76L17.46 62.82L17.82 61.2Z"
                        fill="url(#paint41_linear_806_2510)"
                    />
                    <path
                        d="M45.3601 65.16L44.8201 61.38L52.0201 61.02L53.8201 61.74L55.0801 64.08L55.4401 66.06H45.7201L45.3601 65.16Z"
                        fill="url(#paint42_linear_806_2510)"
                    />
                    <path
                        d="M55.6201 61.56H61.2001L64.2601 66.24H56.7001L55.4401 62.82L55.6201 61.56Z"
                        fill="url(#paint43_linear_806_2510)"
                    />
                    <rect
                        x="49.324"
                        y="27.72"
                        width="2.30528"
                        height="11.7691"
                        rx="1.15264"
                        fill="url(#paint44_linear_806_2510)"
                    />
                    <rect
                        x="49.9264"
                        y="29.6528"
                        width="0.322145"
                        height="8.37578"
                        rx="0.161073"
                        fill="#B6B6B6"
                    />
                    <rect
                        x="50.8928"
                        y="29.6528"
                        width="0.322145"
                        height="8.37578"
                        rx="0.161073"
                        fill="#B6B6B6"
                    />
                    <rect
                        x="48.96"
                        y="27.72"
                        width="3.03327"
                        height="1.21331"
                        rx="0.606654"
                        fill="url(#paint45_linear_806_2510)"
                    />
                    <rect
                        x="48.96"
                        y="38.7612"
                        width="3.03327"
                        height="1.21331"
                        rx="0.606654"
                        fill="url(#paint46_linear_806_2510)"
                    />
                    <rect
                        x="22.6841"
                        y="27.72"
                        width="2.30528"
                        height="11.7691"
                        rx="1.15264"
                        fill="url(#paint47_linear_806_2510)"
                    />
                    <rect
                        x="23.2865"
                        y="29.6528"
                        width="0.322145"
                        height="8.37578"
                        rx="0.161073"
                        fill="#B6B6B6"
                    />
                    <rect
                        x="24.2529"
                        y="29.6528"
                        width="0.322145"
                        height="8.37578"
                        rx="0.161073"
                        fill="#B6B6B6"
                    />
                    <rect
                        x="22.3201"
                        y="27.72"
                        width="3.03327"
                        height="1.21331"
                        rx="0.606654"
                        fill="url(#paint48_linear_806_2510)"
                    />
                    <rect
                        x="22.3201"
                        y="38.7612"
                        width="3.03327"
                        height="1.21331"
                        rx="0.606654"
                        fill="url(#paint49_linear_806_2510)"
                    />
                    <path
                        d="M17.2665 26.4331L12.9266 17.7532C11.959 15.8179 9.28268 15.6009 8.01588 17.3549L6.12 19.98V56.7H13.3555C17.8955 56.7 20.508 51.5387 17.82 47.88C19.6267 47.88 20.6267 45.7856 19.4907 44.3806L15.48 39.42C17.2513 39.42 18.367 37.5124 17.4986 35.9685L14.58 30.78C16.8129 30.78 18.2651 28.4302 17.2665 26.4331Z"
                        fill="#B6B6B6"
                    />
                    <path
                        d="M53.6143 39.2906L57.6749 32.9914C58.6923 31.4131 60.9237 31.2149 62.2033 32.5892L64.407 34.9559V63.7199H55.5633C51.9694 63.7199 50.1197 59.4201 52.5911 56.8109C50.9447 56.8109 50.1379 54.8046 51.326 53.6648L54.9543 50.1839C53.2884 50.1839 52.3249 48.295 53.3029 46.9464L55.8632 43.4159C53.7448 43.4159 52.4666 41.0711 53.6143 39.2906Z"
                        fill="#B6B6B6"
                    />
                </g>
            </g>
            <defs>
                <linearGradient
                    id="paint0_linear_806_2510"
                    x1="38.0198"
                    y1="23.152"
                    x2="20.9022"
                    y2="61.4077"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#9FF12B" />
                    <stop offset="1" stopColor="#57D100" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_806_2510"
                    x1="35.82"
                    y1="38.2389"
                    x2="36.2402"
                    y2="65.9071"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#E4E4E4" />
                    <stop offset="1" stopColor="#CCC7C7" />
                </linearGradient>
                <linearGradient
                    id="paint2_linear_806_2510"
                    x1="36.7674"
                    y1="40.9393"
                    x2="33.0082"
                    y2="68.4443"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="#BDE3FF" />
                </linearGradient>
                <linearGradient
                    id="paint3_linear_806_2510"
                    x1="37.0128"
                    y1="61.6745"
                    x2="36.4998"
                    y2="66.4025"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="#BDE3FF" />
                </linearGradient>
                <linearGradient
                    id="paint4_linear_806_2510"
                    x1="30.55"
                    y1="57.3933"
                    x2="30.6729"
                    y2="60.3193"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="#D7D9DA" />
                </linearGradient>
                <linearGradient
                    id="paint5_linear_806_2510"
                    x1="30.55"
                    y1="57.3933"
                    x2="30.6729"
                    y2="60.3193"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="#D7D9DA" />
                </linearGradient>
                <linearGradient
                    id="paint6_linear_806_2510"
                    x1="30.55"
                    y1="57.3933"
                    x2="30.6729"
                    y2="60.3193"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="#D7D9DA" />
                </linearGradient>
                <linearGradient
                    id="paint7_linear_806_2510"
                    x1="30.55"
                    y1="57.3933"
                    x2="30.6729"
                    y2="60.3193"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="#D7D9DA" />
                </linearGradient>
                <linearGradient
                    id="paint8_linear_806_2510"
                    x1="31.7557"
                    y1="52.2863"
                    x2="31.8093"
                    y2="54.0993"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="#D7D9DA" />
                </linearGradient>
                <linearGradient
                    id="paint9_linear_806_2510"
                    x1="31.7557"
                    y1="52.2863"
                    x2="31.8093"
                    y2="54.0993"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="#D7D9DA" />
                </linearGradient>
                <linearGradient
                    id="paint10_linear_806_2510"
                    x1="31.7557"
                    y1="52.2863"
                    x2="31.8093"
                    y2="54.0993"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="#D7D9DA" />
                </linearGradient>
                <linearGradient
                    id="paint11_linear_806_2510"
                    x1="31.7557"
                    y1="52.2863"
                    x2="31.8093"
                    y2="54.0993"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="#D7D9DA" />
                </linearGradient>
                <linearGradient
                    id="paint12_linear_806_2510"
                    x1="31.7557"
                    y1="52.2863"
                    x2="31.8093"
                    y2="54.0993"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="#D7D9DA" />
                </linearGradient>
                <linearGradient
                    id="paint13_linear_806_2510"
                    x1="32.1939"
                    y1="48.9422"
                    x2="32.265"
                    y2="50.8937"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="#D7D9DA" />
                </linearGradient>
                <linearGradient
                    id="paint14_linear_806_2510"
                    x1="32.1939"
                    y1="48.9422"
                    x2="32.265"
                    y2="50.8937"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="#D7D9DA" />
                </linearGradient>
                <linearGradient
                    id="paint15_linear_806_2510"
                    x1="32.1939"
                    y1="48.9422"
                    x2="32.265"
                    y2="50.8937"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="#D7D9DA" />
                </linearGradient>
                <linearGradient
                    id="paint16_linear_806_2510"
                    x1="32.1939"
                    y1="48.9422"
                    x2="32.265"
                    y2="50.8937"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="#D7D9DA" />
                </linearGradient>
                <linearGradient
                    id="paint17_linear_806_2510"
                    x1="33.1908"
                    y1="45.8628"
                    x2="33.2245"
                    y2="47.1182"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="#D7D9DA" />
                </linearGradient>
                <linearGradient
                    id="paint18_linear_806_2510"
                    x1="33.1908"
                    y1="45.8628"
                    x2="33.2245"
                    y2="47.1182"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="#D7D9DA" />
                </linearGradient>
                <linearGradient
                    id="paint19_linear_806_2510"
                    x1="33.1908"
                    y1="45.8628"
                    x2="33.2245"
                    y2="47.1182"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="#D7D9DA" />
                </linearGradient>
                <linearGradient
                    id="paint20_linear_806_2510"
                    x1="33.1908"
                    y1="45.8628"
                    x2="33.2245"
                    y2="47.1182"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="#D7D9DA" />
                </linearGradient>
                <linearGradient
                    id="paint21_linear_806_2510"
                    x1="33.1908"
                    y1="45.8628"
                    x2="33.2245"
                    y2="47.1182"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="#D7D9DA" />
                </linearGradient>
                <linearGradient
                    id="paint22_linear_806_2510"
                    x1="33.3064"
                    y1="43.5228"
                    x2="33.3451"
                    y2="44.7778"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="#D7D9DA" />
                </linearGradient>
                <linearGradient
                    id="paint23_linear_806_2510"
                    x1="33.3064"
                    y1="43.5228"
                    x2="33.3451"
                    y2="44.7778"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="#D7D9DA" />
                </linearGradient>
                <linearGradient
                    id="paint24_linear_806_2510"
                    x1="33.3064"
                    y1="43.5228"
                    x2="33.3451"
                    y2="44.7778"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="#D7D9DA" />
                </linearGradient>
                <linearGradient
                    id="paint25_linear_806_2510"
                    x1="33.3064"
                    y1="43.5228"
                    x2="33.3451"
                    y2="44.7778"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="#D7D9DA" />
                </linearGradient>
                <linearGradient
                    id="paint26_linear_806_2510"
                    x1="33.8668"
                    y1="41.5711"
                    x2="33.8933"
                    y2="42.5474"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="#D7D9DA" />
                </linearGradient>
                <linearGradient
                    id="paint27_linear_806_2510"
                    x1="33.8668"
                    y1="41.5711"
                    x2="33.8933"
                    y2="42.5474"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="#D7D9DA" />
                </linearGradient>
                <linearGradient
                    id="paint28_linear_806_2510"
                    x1="33.8668"
                    y1="41.5711"
                    x2="33.8933"
                    y2="42.5474"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="#D7D9DA" />
                </linearGradient>
                <linearGradient
                    id="paint29_linear_806_2510"
                    x1="33.8668"
                    y1="41.5711"
                    x2="33.8933"
                    y2="42.5474"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="#D7D9DA" />
                </linearGradient>
                <linearGradient
                    id="paint30_linear_806_2510"
                    x1="33.8668"
                    y1="41.5711"
                    x2="33.8933"
                    y2="42.5474"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="#D7D9DA" />
                </linearGradient>
                <linearGradient
                    id="paint31_linear_806_2510"
                    x1="34.1997"
                    y1="40.0552"
                    x2="34.2218"
                    y2="40.8921"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="#D7D9DA" />
                </linearGradient>
                <linearGradient
                    id="paint32_linear_806_2510"
                    x1="34.1997"
                    y1="40.0552"
                    x2="34.2218"
                    y2="40.8921"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="#D7D9DA" />
                </linearGradient>
                <linearGradient
                    id="paint33_linear_806_2510"
                    x1="34.1997"
                    y1="40.0552"
                    x2="34.2218"
                    y2="40.8921"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="#D7D9DA" />
                </linearGradient>
                <linearGradient
                    id="paint34_linear_806_2510"
                    x1="34.1997"
                    y1="40.0552"
                    x2="34.2218"
                    y2="40.8921"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="#D7D9DA" />
                </linearGradient>
                <linearGradient
                    id="paint35_linear_806_2510"
                    x1="34.3899"
                    y1="38.8234"
                    x2="34.4005"
                    y2="39.3816"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="#D7D9DA" />
                </linearGradient>
                <linearGradient
                    id="paint36_linear_806_2510"
                    x1="34.3899"
                    y1="38.8234"
                    x2="34.4005"
                    y2="39.3816"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="#D7D9DA" />
                </linearGradient>
                <linearGradient
                    id="paint37_linear_806_2510"
                    x1="34.3899"
                    y1="38.8234"
                    x2="34.4005"
                    y2="39.3816"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="#D7D9DA" />
                </linearGradient>
                <linearGradient
                    id="paint38_linear_806_2510"
                    x1="34.3899"
                    y1="38.8234"
                    x2="34.4005"
                    y2="39.3816"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="#D7D9DA" />
                </linearGradient>
                <linearGradient
                    id="paint39_linear_806_2510"
                    x1="34.3899"
                    y1="38.8234"
                    x2="34.4005"
                    y2="39.3816"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="#D7D9DA" />
                </linearGradient>
                <linearGradient
                    id="paint40_linear_806_2510"
                    x1="23.6154"
                    y1="61.6745"
                    x2="22.9877"
                    y2="66.3743"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="#BDE3FF" />
                </linearGradient>
                <linearGradient
                    id="paint41_linear_806_2510"
                    x1="13.6414"
                    y1="61.6914"
                    x2="12.7087"
                    y2="66.4637"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="#BDE3FF" />
                </linearGradient>
                <linearGradient
                    id="paint42_linear_806_2510"
                    x1="50.0805"
                    y1="61.4945"
                    x2="49.3916"
                    y2="66.1768"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="#BDE3FF" />
                </linearGradient>
                <linearGradient
                    id="paint43_linear_806_2510"
                    x1="59.8089"
                    y1="62.0006"
                    x2="59.0974"
                    y2="66.3256"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="#BDE3FF" />
                </linearGradient>
                <linearGradient
                    id="paint44_linear_806_2510"
                    x1="50.1721"
                    y1="32.6808"
                    x2="53.4321"
                    y2="34.051"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="#D7D9DA" />
                </linearGradient>
                <linearGradient
                    id="paint45_linear_806_2510"
                    x1="50.0759"
                    y1="28.2314"
                    x2="50.2454"
                    y2="29.1407"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="#D7D9DA" />
                </linearGradient>
                <linearGradient
                    id="paint46_linear_806_2510"
                    x1="50.0759"
                    y1="39.2726"
                    x2="50.2454"
                    y2="40.1818"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="#D7D9DA" />
                </linearGradient>
                <linearGradient
                    id="paint47_linear_806_2510"
                    x1="23.5322"
                    y1="32.6808"
                    x2="26.7922"
                    y2="34.051"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="#D7D9DA" />
                </linearGradient>
                <linearGradient
                    id="paint48_linear_806_2510"
                    x1="23.436"
                    y1="28.2314"
                    x2="23.6055"
                    y2="29.1407"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="#D7D9DA" />
                </linearGradient>
                <linearGradient
                    id="paint49_linear_806_2510"
                    x1="23.436"
                    y1="39.2726"
                    x2="23.6055"
                    y2="40.1818"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="#D7D9DA" />
                </linearGradient>
            </defs>
        </svg>
    );
};

import { AbortParams } from 'src/data/api/api-client';
import {
    ExportedType,
    FilterKeys,
    FilterOperators,
    QueryParamsQl,
} from 'src/data/api/graphql/br_process/generated/graphql-sdk';
import { ProcessGqlSdk } from 'src/data/api/graphql/graphql-client.wrapper';
import {
    DealExport,
    emailRawToModel,
    languageModelToRaw,
    OutboundEmail,
} from 'src/domain/models/deal-export/deal-export.model';
import { Language } from 'src/domain/models/locale/locale.model';

interface GetCSVByIdParams extends AbortParams {
    id: string;
    baseUrl: string;
    delimiter: string;
}

interface GetCSVsByIdsParams extends AbortParams {
    ids: string[];
    baseUrl: string;
    delimiter: string;
}

interface EditDealExportParams extends AbortParams {
    dealId: string;
    exportedType: ExportedType;
}

interface DealEmailExportParams extends AbortParams {
    inEmail: {
        senderName: string;
        toName: string;
        toEmail: string;
        language: Language;
        dealId: string;
        dealOwner: string;
        dealOwnerEmail: string;
        dealUrl: string;
    };
}

export interface DealsExportApi {
    updateDealExported: (params: EditDealExportParams) => Promise<boolean>;
    exportDealEmail: (
        params: DealEmailExportParams,
    ) => Promise<{ ok: boolean; email: OutboundEmail; dealId: string }>;
    getCSVById: (
        params: GetCSVByIdParams,
    ) => Promise<{ id: string; csv: DealExport }>;
    getMultipleCSVsById: (
        params: GetCSVsByIdsParams,
    ) => Promise<{ id: string; csv: DealExport }[]>;
}

export const createDealsExportApi = (
    processGqlSdk: ProcessGqlSdk,
): DealsExportApi => {
    const getCSVById = async (
        params: GetCSVByIdParams,
    ): Promise<{ id: string; csv: DealExport }> => {
        const { id: dealId, baseUrl, delimiter } = params;
        const response = await processGqlSdk.ExportCSV({
            dealId,
            baseUrl,
            delimiter,
        });
        const { csv } = response.deal.export;
        return { id: dealId, csv };
    };

    const getMultipleCSVsById = async (
        params: GetCSVsByIdsParams,
    ): Promise<{ id: string; csv: DealExport }[]> => {
        const { ids, delimiter, baseUrl } = params;
        const queryParams: QueryParamsQl = {
            filtering: [
                {
                    field: FilterKeys.FkDealId,
                    fop: FilterOperators.FopIn,
                    values: ids,
                },
            ],
        };
        const response = await processGqlSdk.ExportBatchCSV({
            delimiter,
            baseUrl,
            queryParams,
        });

        return response.allDeals.map((dealExport) => ({
            id: dealExport.id,
            csv: dealExport.export.csv,
        }));
    };

    const updateDealExported = async (
        params: EditDealExportParams,
    ): Promise<boolean> => {
        const response = await processGqlSdk.UpdateDealInformation({
            dealId: params.dealId,
            updateDeal: { exported: params.exportedType },
        });
        return response.updateDeal.ok ?? false;
    };

    const exportDealEmail = async (
        params: DealEmailExportParams,
    ): Promise<{ ok: boolean; email: OutboundEmail; dealId: string }> => {
        const { inEmail } = params;
        const inEmailQueryParams = {
            ...inEmail,
            language: languageModelToRaw[inEmail.language],
        };
        const response = await processGqlSdk.exportEmail(inEmailQueryParams);
        return {
            ok: response.exportDealEmail.ok ?? false,
            email: emailRawToModel(response.exportDealEmail.email),
            dealId: inEmail.dealId,
        };
    };

    return {
        updateDealExported,
        exportDealEmail,
        getCSVById,
        getMultipleCSVsById,
    };
};

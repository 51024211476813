import { AbortParams } from 'src/data/api/api-client';
import { ProcessGqlSdk } from 'src/data/api/graphql/graphql-client.wrapper';
import {
    Comment,
    createComment,
} from 'src/domain/models/comment/comment.model';

export interface CommentsApi {
    getComments: (params: GetCommentsParams) => Promise<Comment[]>;
    addComment: (params: AddCommentsParams) => Promise<Comment | undefined>;
    editComment: (params: EditCommentParams) => Promise<Comment | undefined>;
    deleteComment: (params: DeleteCommentParams) => Promise<boolean>;
}

interface GetCommentsParams extends AbortParams {
    dealId: string;
}

interface AddCommentsParams extends AbortParams {
    content: string;
    dealId: string;
}

interface EditCommentParams extends AbortParams {
    content: string;
    notifiedUserIds: number[];
    commentId: string;
}

interface DeleteCommentParams extends AbortParams {
    commentId: string;
}

export const createCommentsApi = (
    processGqlSdk: ProcessGqlSdk,
): CommentsApi => {
    const getComments = async (params: GetCommentsParams) => {
        const response = await processGqlSdk.GetDealComments({
            dealId: params.dealId,
        });
        return response.deal.comments
            ? response.deal.comments.map(createComment)
            : [];
    };

    const addComment = async (params: AddCommentsParams) => {
        const response = await processGqlSdk.AddDealComment({
            dealId: params.dealId,
            data: {
                content: params.content,
            },
        });
        return response.addDealComment.id
            ? createComment(response.addDealComment)
            : undefined;
    };

    const editComment = async (params: EditCommentParams) => {
        const response = await processGqlSdk.UpdateComment({
            commentId: params.commentId,
            data: {
                content: params.content,
                mentionedUserBids: params.notifiedUserIds,
            },
        });
        return response.updateComment.id
            ? createComment(response.updateComment)
            : undefined;
    };

    const deleteComment = async (params: DeleteCommentParams) => {
        const response = await processGqlSdk.DeleteComment({
            commentId: params.commentId,
        });
        return response.deleteComment.ok;
    };

    return {
        getComments,
        addComment,
        editComment,
        deleteComment,
    };
};

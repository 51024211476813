import { observer } from 'mobx-react';
import { FC, ReactNode } from 'react';

import { ItemContent } from 'src/presentation/modules/mobile-menu/components/mobile-menu-item-content/mobile-menu-item-content.component';
import { Link } from 'src/presentation/shared/link/link.component';

interface MobileMenuItemComponentProps {
    isActive: boolean;
    className?: string;
    linkClassName?: string;
    Icon: ReactNode;
    toLegacyApp?: boolean;
    text: string;
    link?: string | undefined;
    onClick?: () => void;
}

export const MobileMenuItemComponent: FC<MobileMenuItemComponentProps> =
    observer((props) => {
        const { isActive, Icon, text, className, linkClassName } = props;

        if (props.link === undefined && props.onClick === undefined) {
            return (
                <a className={linkClassName}>
                    <ItemContent
                        className={className}
                        isActive={isActive}
                        Icon={Icon}
                        text={text}
                    />
                </a>
            );
        }

        if (props.link) {
            return (
                <Link
                    className={linkClassName}
                    onClick={props.onClick}
                    href={props.link}
                    toLegacyApp={props.toLegacyApp}
                >
                    <ItemContent
                        className={className}
                        isActive={isActive}
                        Icon={Icon}
                        text={text}
                    />
                </Link>
            );
        }
        return (
            <ItemContent
                onClick={props.onClick}
                className={className}
                isActive={isActive}
                Icon={Icon}
                text={text}
            />
        );
    });

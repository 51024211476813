import {
    BrIcon,
    BrText,
    IconName,
    IconSize,
    TextSize,
    TextWeight,
} from '@buildingradar/br_component_lib';
import { Box, IconButton } from '@mui/material';
import { observer } from 'mobx-react';
import { CSSProperties, FC, ReactNode } from 'react';

import {
    DrawerActionStyled,
    DrawerBodyStyled,
    DrawerContentStyled,
    DrawerHeaderStyled,
} from './styled';

interface ActionContentComponentProps {
    title: ReactNode | string;
    body: ReactNode;
    actions?: ReactNode;
    onClose: () => void;
    contentSxOverride?: CSSProperties;
    headerSxOverride?: CSSProperties;
    bodySxOverride?: CSSProperties;
    actionSxOverride?: CSSProperties;
}

export const ActionContentComponent: FC<ActionContentComponentProps> = observer(
    ({
        title,
        body,
        actions,
        onClose,
        contentSxOverride,
        headerSxOverride,
        bodySxOverride,
        actionSxOverride,
    }) => {
        return (
            <DrawerContentStyled
                style={contentSxOverride}
                className="helper-flyout"
            >
                <DrawerHeaderStyled
                    style={headerSxOverride}
                    className="helper-flyout-header"
                >
                    <Box className="helper-flyout-title">
                        {typeof title === 'string' ? (
                            <BrText
                                size={TextSize.md}
                                color="var(--blue-60)"
                                weight={TextWeight.bold}
                            >
                                {title}
                            </BrText>
                        ) : (
                            title
                        )}
                    </Box>
                    <IconButton
                        onClick={onClose}
                        className="close-helper-flyout"
                    >
                        <BrIcon
                            iconName={IconName.BrCloseIcon}
                            iconSize={IconSize.large}
                            iconColor="var(--blue-30)"
                        />
                    </IconButton>
                </DrawerHeaderStyled>
                <DrawerBodyStyled
                    style={bodySxOverride}
                    className="helper-flyout-content-area"
                >
                    {body}
                </DrawerBodyStyled>
                {actions && (
                    <DrawerActionStyled
                        style={actionSxOverride}
                        className="helper-flyout-ctas"
                    >
                        {actions}
                    </DrawerActionStyled>
                )}
            </DrawerContentStyled>
        );
    },
);

import { makeAutoObservable } from 'mobx';

import { PipelineApi } from 'src/data/api/pipeline/pipeline.api';
import { MixpanelService } from 'src/data/services/mixpanel/mixpanel.service';
import { IAccountConfigurationStore } from 'src/data/stores/account-configuration/account-configuration.store.interface';
import { PipelineStore } from 'src/data/stores/pipeline/pipeline.store';
import { IPipelineDynamicFiltersStore } from 'src/data/stores/pipeline-data/dynamic-filters/pipeline-dynamic-filters.store.interface';
import { IPipelinePerformanceViewPreferencesStore } from 'src/data/stores/pipeline-data/view-preferences/pipeline-performance-view-preferences.store.interface';
import { IBaseStore } from 'src/data/stores/shared/base.store.interface';
import { UserStore } from 'src/data/stores/user/user.store';
import {
    DynamicFilter,
    DynamicFilterMetric,
    orderedDynamicFilters,
} from 'src/domain/models/dynamic-filter/dynamic-filter.model';
import {
    getPipelineViewDataTypeBasedOnVisualizationMode,
    RefetchPipelineViewDataSource,
} from 'src/domain/models/performance-view-preferences/performance-view-preferences.model';
import { StageStatistics } from 'src/domain/models/pipeline/pipeline.model';
import {
    handleRequest,
    handleRequestAsync,
} from 'src/utils/handle-request.utils';

import { sortDealsByStageConversion } from './pipeline-data.feature.util';

export interface PipelineDataFeature {
    /**
     * Data used to display the pipeline information in the `Box view` mode
     */
    stageStatistics: Map<string, StageStatistics>;
    isStageStatisticsLoading: boolean;
    selectedDynamicFilters: Set<DynamicFilter>;
    availableDynamicFilters: DynamicFilter[];
    isDynamicFilterActive: boolean;
    isDynamicFiltersLoading: boolean;
    requestStageStatistics: (pipelineId: string, stageIds: string[]) => void;
    getDynamicFilterCount: (filter: DynamicFilter) => number;
    toggleDynamicFilter: (filter: DynamicFilter) => void;
    isDynamicFilterEnabled: (filter: DynamicFilter) => boolean;
    clearDynamicFilters: () => void;
    requestPipelineDynamicFilterMetrics: (pipelineId: string) => Promise<void>;
}

export class PipelineDataFeatureImpl implements PipelineDataFeature {
    stageStatistics: Map<string, StageStatistics> = new Map();
    isPipelineStageStatisticsLoading = false;
    dynamicFilterMetrics: DynamicFilterMetric[] = [];

    get isDynamicFilterActive(): boolean {
        return !!this.selectedDynamicFilters.size;
    }

    get isDynamicFiltersLoading(): boolean {
        return this.pipelineDynamicFiltersStore.isDynamicFiltersLoading;
    }

    get isStageStatisticsLoading() {
        return (
            this.isPipelineStageStatisticsLoading ||
            this.isDynamicFiltersLoading
        );
    }

    constructor(
        private pipelineApi: PipelineApi,
        private mixpanelService: MixpanelService,
        private performanceViewPreferencesStore: IPipelinePerformanceViewPreferencesStore,
        private pipelineDynamicFiltersStore: IPipelineDynamicFiltersStore,
        private pipelineStore: PipelineStore,
        private userStore: UserStore,
        private accountConfigurationStore: IAccountConfigurationStore,
        private baseStore: IBaseStore,
    ) {
        makeAutoObservable(this);
    }

    get selectedDynamicFilters() {
        return this.pipelineDynamicFiltersStore.selectedDynamicFilters;
    }

    get availableDynamicFilters() {
        return orderedDynamicFilters.filter(
            (d) => this.getDynamicFilterCount(d) > 0,
        );
    }

    getDynamicFilterCount = (filter: DynamicFilter) => {
        const filterMetric = this.dynamicFilterMetrics.find(
            ({ dynamicFilter }) => dynamicFilter === filter,
        );

        return filterMetric?.count ?? 0;
    };

    toggleDynamicFilter = (filter: DynamicFilter) => {
        if (this.selectedDynamicFilters.has(filter)) {
            this.selectedDynamicFilters.delete(filter);
        } else {
            this.selectedDynamicFilters.add(filter);
        }

        const pipelineVisualizationMode =
            this.performanceViewPreferencesStore.visualizationModePreference;
        this.performanceViewPreferencesStore.setRefetchPipelineViewDataState({
            active: true,
            refetchType: getPipelineViewDataTypeBasedOnVisualizationMode(
                pipelineVisualizationMode,
            ),
            source: RefetchPipelineViewDataSource.dynamicFilters,
        });
    };

    isDynamicFilterEnabled = (filter: DynamicFilter) => {
        return this.selectedDynamicFilters.has(filter);
    };

    clearDynamicFilters = () => {
        if (this.selectedDynamicFilters.size) {
            this.pipelineDynamicFiltersStore.clearDynamicFilters();

            const pipelineVisualizationMode =
                this.performanceViewPreferencesStore
                    .visualizationModePreference;
            this.performanceViewPreferencesStore.setRefetchPipelineViewDataState(
                {
                    active: true,
                    refetchType:
                        getPipelineViewDataTypeBasedOnVisualizationMode(
                            pipelineVisualizationMode,
                        ),
                    source: RefetchPipelineViewDataSource.dynamicFilters,
                },
            );
        }
    };

    updateDynamicFilters = () => {
        this.pipelineDynamicFiltersStore.setDynamicFilters(
            new Set(
                [...this.selectedDynamicFilters].filter(
                    (df) => this.getDynamicFilterCount(df) > 0,
                ),
            ),
        );
    };

    setIsStageStatisticsLoading = (flag: boolean) => {
        this.isPipelineStageStatisticsLoading = flag;
    };

    onRequestStageStatistics = (stageStatistics: StageStatistics[]) => {
        this.stageStatistics = new Map();
        if (stageStatistics) {
            stageStatistics.forEach((stageStatistic) => {
                stageStatistic.inPlayDealsOfStage =
                    stageStatistic.inPlayDealsOfStage.sort(
                        sortDealsByStageConversion,
                    );
                this.stageStatistics.set(stageStatistic.id, stageStatistic);
            });
            this.performanceViewPreferencesStore.setRefetchPipelineViewDataState(
                { active: false },
            );
        }
    };

    requestStageStatistics = (pipelineId: string, stageIds: string[]) => {
        const viewPreferences =
            this.buildCurrentPipelineViewPreferencesParams();

        const pipeline = this.pipelineStore.getPipeline(pipelineId);

        if (!pipeline) {
            return;
        }

        handleRequest(
            this.pipelineApi.getPipelineStatistics,
            {
                pipelineId,
                pipelineName: pipeline.name,
                stageIds,
                viewPreferences,
                dynamicFilters: Array.from(this.selectedDynamicFilters),
                customParameterTag:
                    this.accountConfigurationStore.customParameterTag,
            },
            this.onRequestStageStatistics,
            this.setIsStageStatisticsLoading,
            (error) =>
                this.baseStore.onRequestFailed(
                    'request-stage-statistics',
                    error,
                ),
            'request-stage-statistics',
        );
    };

    requestPipelineDynamicFilterMetrics = async (pipelineId: string) => {
        const viewPreferences =
            this.buildCurrentPipelineViewPreferencesParams();
        const dynamicFilterMetrics = await handleRequestAsync(
            this.pipelineApi.getPipelineDynamicFilterMetrics,
            {
                pipelineId,
                viewPreferences,
                customParameterTag:
                    this.accountConfigurationStore.customParameterTag,
            },
            this.pipelineDynamicFiltersStore.setIsDynamicFiltersLoading,
            (error) =>
                this.baseStore.onRequestFailed(
                    'request-dynamic-filters',
                    error,
                ),
            'request-dynamic-filters',
        );
        if (dynamicFilterMetrics?.length !== undefined) {
            this.dynamicFilterMetrics = dynamicFilterMetrics;
            this.updateDynamicFilters();
        }
    };

    private buildCurrentPipelineViewPreferencesParams = () => {
        const customParameterTag =
            this.accountConfigurationStore.customParameterTag;

        return this.performanceViewPreferencesStore.getCurrentPipelineViewPreferencesParams(
            customParameterTag?.name,
        );
    };
}

import { ProcessGqlSdk } from 'src/data/api/graphql/graphql-client.wrapper';
import { AccountConfiguration } from 'src/domain/models/account-configuration/account-configuration.model';

export interface IAccountConfigurationApi {
    getAccountConfiguration(): Promise<AccountConfiguration>;
}

export const createAccountConfigurationApi = (
    processGqlSdk: ProcessGqlSdk,
): IAccountConfigurationApi => {
    const getAccountConfiguration = async (): Promise<AccountConfiguration> => {
        const { company } = await processGqlSdk.GetAccountConfiguration();
        const rawActionFeatures = company.config?.actionFeatures;
        const rawSalesPeriod = company.config?.salesPeriod;
        const rawDealCustomFields = company.config?.customFields.deals;
        const rawContactCustomFields = company.config?.customFields.contacts;
        const rawCompanyCustomFields =
            company.config?.customFields.relevantCompanies;
        const rawRoles = company.config?.roles;

        return new AccountConfiguration(
            rawSalesPeriod,
            rawActionFeatures,
            rawDealCustomFields,
            rawContactCustomFields,
            rawCompanyCustomFields,
            rawRoles,
            company.config?.dealValueVisible,
        );
    };

    return {
        getAccountConfiguration,
    };
};

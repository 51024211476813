import { BrText } from '@buildingradar/br_component_lib';
import styled from '@emotion/styled';
import { Box } from '@mui/material';

export const ExceptionStateIconStyled = styled(Box)({
    marginLeft: '4px',
    width: '48px',
    height: '48px',
    borderRadius: '24px',
    background: 'var(--yellow-10)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '16px',
});

export const ExceptionStateWrapperStyled = styled(Box)({
    width: '100%',
    height: 'auto',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '24px 0px',
});

export const ExceptionStateTextStyled = styled(BrText)({
    flexGrow: '1',
    lineHeight: '24px',
    letterSpacing: '0.4px',
    color: 'var(--gray-80)',
    maxWidth: 'calc(100% - 48px - 20px)',
});

export const LinkStyled = styled.a`
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.4px;
    color: var(--accent-link);
    white-space: pre-wrap;
    word-break: break-word;
    width: calc(100% - 40px);
`;

import { observer } from 'mobx-react';
import { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ActionFeatureLaunchSource } from 'src/domain/features/account-configuration/helpers/action-feature.helper';
import { DealOpenSource } from 'src/domain/models/deal/deal.model';
import { Language } from 'src/domain/models/locale/locale.model';
import { Reminder } from 'src/domain/models/reminder/reminder.model';
import { NotificationCard } from 'src/presentation/modules/notifications-flyout/components/shared/notification-card/notification-card.component';
import { createDealPageLink } from 'src/presentation/modules/router/utils/route.utils';
import { cutText } from 'src/utils/string.utils';

import { ReminderBadge } from './reminder-badge.component';
import { formatReminderDueDate } from './reminder-due-date.helper';

interface ReminderCardProps {
    reminder: Reminder;
    language?: Language;
    toggleNotificationsOpened: (flag: boolean) => void;
    onEditReminderRequested: (
        launchSource: ActionFeatureLaunchSource,
        reminder: Reminder,
    ) => void;
    dismissReminder: (reminderId: string) => void;
}

const CUT_TEXT_AT = 48;

export const ReminderCard: FC<ReminderCardProps> = observer(
    ({
        reminder,
        language,
        toggleNotificationsOpened,
        onEditReminderRequested,
        dismissReminder,
    }) => {
        const { id, description, dueDate, target } = reminder;
        const { title, id: dealId } = target;

        const { t } = useTranslation();

        const urlToLead = createDealPageLink(
            dealId,
            DealOpenSource.NotificationsNextSteps,
        );

        const reminderDescription = useMemo(
            () => cutText(description.replaceAll('\n', ' '), CUT_TEXT_AT),
            [description],
        );

        const reminderTargetTitle = useMemo(
            () => cutText(title ?? '', CUT_TEXT_AT),
            [title],
        );

        const reminderBadge = useMemo(
            () => <ReminderBadge dueDate={dueDate} />,
            [dueDate],
        );

        const handleReminderCardClicked = useCallback(
            (timeout = true) => {
                toggleNotificationsOpened(false);
                setTimeout(
                    () =>
                        onEditReminderRequested(
                            ActionFeatureLaunchSource.NotificationFlyoutReminderTab,
                            reminder,
                        ),
                    timeout ? 500 : 0,
                );
            },
            [onEditReminderRequested, reminder, toggleNotificationsOpened],
        );

        const onEditIconClicked = useCallback(() => {
            handleReminderCardClicked(false);
        }, [handleReminderCardClicked]);

        const onDismissIconClicked = useCallback(() => {
            if (id) {
                dismissReminder(id);
            }
        }, [dismissReminder, id]);

        return (
            <NotificationCard
                linkUrl={urlToLead}
                headerBadge={reminderBadge}
                title={reminderDescription}
                subTitle={reminderTargetTitle}
                when={formatReminderDueDate(dueDate, t, language)}
                onLinkClickedHandler={handleReminderCardClicked}
                onEdit={onEditIconClicked}
                onDismiss={onDismissIconClicked}
            />
        );
    },
);

import { makeAutoObservable } from 'mobx';

import { DynamicFilter } from 'src/domain/models/dynamic-filter/dynamic-filter.model';

import { IPipelineDynamicFiltersStore } from './pipeline-dynamic-filters.store.interface';

export class PipelineDynamicFiltersStore
    implements IPipelineDynamicFiltersStore
{
    selectedDynamicFilters: Set<DynamicFilter> = new Set();
    isDynamicFiltersLoading = false;

    setDynamicFilters = (filter: Set<DynamicFilter>) => {
        this.selectedDynamicFilters = filter;
    };

    clearDynamicFilters = () => {
        this.selectedDynamicFilters = new Set();
    };

    setIsDynamicFiltersLoading = (flag: boolean) => {
        this.isDynamicFiltersLoading = flag;
    };

    constructor() {
        makeAutoObservable(this);
    }
}

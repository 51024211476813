import { Locale as DateFnsLocale } from 'date-fns';
import { de, enGB } from 'date-fns/locale';

import { UserLanguage } from 'src/data/api/graphql/br_user/generated/graphql-sdk';

enum Locale {
    En = 'en-GB',
    De = 'de-DE',
}

export enum Language {
    En = 'en',
    De = 'de',
}

export const languageToLocale: Record<Language, Locale> = {
    [Language.De]: Locale.De,
    [Language.En]: Locale.En,
};

export const readableLanguage: Record<Language, string> = {
    [Language.De]: 'Deutsch',
    [Language.En]: 'English',
};

export const languageToDateFnsLocale: Record<Language, DateFnsLocale> = {
    [Language.De]: de,
    [Language.En]: enGB,
};

export const languageToBrUserLanguage: Record<Language, UserLanguage> = {
    [Language.De]: UserLanguage.De,
    [Language.En]: UserLanguage.En,
};

import {
    BrText,
    BrTextInputStatus,
    TextSize,
    getEndAdornment,
    useShownStatus,
} from '@buildingradar/br_component_lib';
import { observer } from 'mobx-react';
import { FC, useMemo } from 'react';

import { TextPreviewStyled } from './styles';
import { textToHTML } from './utils';
import clsx from 'clsx';

interface TextPreviewProps {
    multiline?: boolean;
    value?: string;
    status: BrTextInputStatus;
    helperText?:
        | string
        | ((status: BrTextInputStatus | undefined) => string | undefined);
    isHighlighted?: boolean;
    isPreviewMode: boolean;
    placeholder?: string;
    obscure?: boolean;
    onClick: () => void;
    onLinkClicked: (link: string) => void;
}

export const TextPreview: FC<TextPreviewProps> = observer(
    ({
        value,
        multiline = false,
        isHighlighted,
        status,
        isPreviewMode,
        placeholder,
        helperText,
        obscure,
        onClick,
        onLinkClicked,
    }) => {
        const shownStatus = useShownStatus(status);
        const endAdornment = getEndAdornment(null, status, shownStatus);

        const textHTML = useMemo(
            () =>
                isPreviewMode && value
                    ? textToHTML(value, onLinkClicked)
                    : null,
            [isPreviewMode, onLinkClicked, value],
        );

        let helperTextValue: string | undefined;

        if (helperText) {
            if (typeof helperText === 'function') {
                helperTextValue = (
                    helperText as (
                        status: BrTextInputStatus | undefined,
                    ) => string | undefined
                )(status);
            } else {
                helperTextValue = helperText;
            }
        }

        return (
            <div
                className="relative w-full min-w-0"
                tabIndex={0}
                onFocus={onClick}
            >
                <TextPreviewStyled
                    multiline={multiline}
                    onClick={onClick}
                    isHighlighted={isHighlighted}
                    status={status}
                >
                    {value?.length ? (
                        obscure ? (
                            Array.from({ length: value.length }).map(() => '•')
                        ) : (
                            textHTML
                        )
                    ) : (
                        <div style={{ color: '#b4b6b8' }}>{placeholder}</div>
                    )}
                </TextPreviewStyled>
                <div
                    className={clsx({
                        'absolute right-[10px]': true,
                        'bottom-[16px]': multiline,
                        'bottom-[20px]': !multiline,
                    })}
                >
                    {endAdornment}
                </div>
                {helperTextValue && (
                    <BrText
                        color={
                            status === 'error'
                                ? 'var(--red-50)'
                                : 'var(--gray-50)'
                        }
                        size={TextSize.sm}
                        sx={{
                            fontStyle: 'italic',
                            marginTop: '2px',
                            letterSpacing: '0.03333em',
                            position: 'absolute',
                        }}
                    >
                        {helperTextValue}
                    </BrText>
                )}
            </div>
        );
    },
);

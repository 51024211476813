import { BrButton } from '@buildingradar/br_component_lib';
import styled from '@emotion/styled';

export const DocumentLinkStyled = styled.a({
    color: 'var(--gray-50)',
    ':hover': {
        textDecoration: 'underline',
    },
});

export const SecondaryHeader = styled.h4`
    font-weight: 500;
    font-size: 16px;
    margin-bottom: -8px;
`;

export const BrButtonStyled = styled(BrButton)`
    height: fit-content;
`;

import { PredefinedCustomFields } from 'src/app-features/contact/data/model/deal-contacts.model';
import ClearbitPNG from 'src/resources/images/clearbit_logo.png';
import ContactOutPNG from 'src/resources/images/contactout.png';
import DealfrontPNG from 'src/resources/images/dealfront.png';
import GooglePNG from 'src/resources/images/google_logo.png';
import HunterPNG from 'src/resources/images/hunter.png';
import LushaPNG from 'src/resources/images/lusha.png';
import RocketreachPNG from 'src/resources/images/rocketreach.png';

export enum DataProviderSource {
    Lusha = 'LUSHA',
    Hunter = 'HUNTER',
    ContactOut = 'CONTACT_OUT',
    Clearbit = 'CLEARBIT',
    Google = 'GOOGLE_PLACES',
    Mock = 'MOCK',
    RocketReach = 'ROCKET_REACH',
    Dealfront = 'DEAL_FRONT',
}

export interface ContactEnrichmentResult {
    emails: ContactEnrichmentData[];
    phones: ContactEnrichmentData[];
    linkedins: ContactEnrichmentData[];
    positions: ContactEnrichmentData[];
}

export interface ContactEnrichmentData {
    value: string;
    providers: DataProviderSource[];
}

export const DataProviderLogo: Record<DataProviderSource, string> = {
    [DataProviderSource.Lusha]: LushaPNG,
    [DataProviderSource.Hunter]: HunterPNG,
    [DataProviderSource.ContactOut]: ContactOutPNG,
    [DataProviderSource.Mock]: '',
    [DataProviderSource.Clearbit]: ClearbitPNG,
    [DataProviderSource.Google]: GooglePNG,
    [DataProviderSource.RocketReach]: RocketreachPNG,
    [DataProviderSource.Dealfront]: DealfrontPNG,
};

export const DataProviderDisplayValues: Record<
    DataProviderSource,
    { name: string; url: string }
> = {
    [DataProviderSource.Lusha]: {
        name: 'Lusha',
        url: 'https://www.lusha.com/',
    },
    [DataProviderSource.Hunter]: {
        name: 'Hunter.io',
        url: 'https://hunter.io/',
    },
    [DataProviderSource.ContactOut]: {
        name: 'ContactOut',
        url: 'https://contactout.com/',
    },
    [DataProviderSource.Mock]: { name: '', url: '' },
    [DataProviderSource.Clearbit]: {
        name: 'Clearbit',
        url: 'https://clearbit.com/',
    },
    [DataProviderSource.Google]: {
        name: 'Google Places',
        url: 'https://maps.google.com',
    },
    [DataProviderSource.RocketReach]: {
        name: 'RocketReach',
        url: 'https://rocketreach.co/',
    },
    [DataProviderSource.Dealfront]: {
        name: 'Dealfront',
        url: 'https://www.echobot.de/',
    },
};

export interface ContactEnrichmentParams {
    linkedin?: string;
    domain?: string;
    contactId?: string;
}

export interface DomainEnrichmentOption {
    name?: string;
    domain: string;
    providers: DataProviderSource[];
    isCustom: boolean;
}

export type EnrichmentResultSelection = Partial<{
    [key in PredefinedCustomFields]: ContactEnrichmentData;
}>;

export enum EnrichmentStep {
    idle,
    companySelection,
    domainEnrichment,
    contactEnrichment,
    refinement,
}

import { Box, styled } from '@mui/material';

export const ErrorPageStyled = styled(Box)`
    padding: 30px;
`;

export const ErrorContentStyled = styled(Box)`
    margin-top: 20px;
`;

export const HomeLinkWrapperStyled = styled(Box)`
    margin-top: 30px;
`;

import { matchMedia } from 'src/utils/browser.utils';

export const MediaQuery = {
    smallPhone: '@media (max-width: 431px)',
    phone: '@media (max-width: 766px)',
    tablet: '@media (max-width: 1022px)',
    smallDesktop: '@media (max-width: 1438px)',
    largeDesktop: '@media (min-width: 1440px)',
    fullWidthMenu: '@media (max-width: 448px)',
    shrinkDealDetailsColumnBreakpoint: '@media (max-width: 1580px)',
};

export const checkMediaQuery = (mediaString: string) => {
    const query = mediaString.replace(/^@media( ?)/, '');
    const mediaQuery = matchMedia(query);
    return mediaQuery.matches;
};

import { BrDialog } from '@buildingradar/br_component_lib';
import { useMediaQuery } from '@mui/material';
import { observer } from 'mobx-react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { LoomVideoComponent } from 'src/presentation/shared/loom-video/loom-video.component';
import { MediaQuery } from 'src/resources/styles/media.mixins';

interface DataProviderLoomVideoComponentProps {
    isOpen: boolean;
    loomUrl: string;
    onClose: () => void;
}

export const DataProviderLoomVideoComponent: FC<DataProviderLoomVideoComponentProps> =
    observer(({ isOpen, loomUrl, onClose }) => {
        const { t } = useTranslation();
        const isMobile = useMediaQuery(MediaQuery.phone);

        return (
            <BrDialog
                isDialogOpened={isOpen}
                width={1200}
                fullScreen={isMobile}
                onClose={onClose}
                title={{
                    text: t(
                        'contact_data_provider_panel.provider.guide_modal.title',
                    ),
                }}
                showCloseButton={false}
            >
                <LoomVideoComponent url={loomUrl} />
            </BrDialog>
        );
    });

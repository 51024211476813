/**
 * Enum with all available feature flags.
 */
export enum FeatureFlag {
    outreachColumnsInDealTable = 'outreach-count-deal-table',
    outreachTarget = 'outreach-target',
    placeholderFlag = 'placeholderFlag',
    omniSearch = 'omni-search',
    autoContactExtraction = 'auto-contact-extraction',
    outreachLogNextAction = 'outreach-log-next-action',
}

/**
 * Type for a feature flag value.
 */
export type FeatureFlagValue =
    | string
    | number
    | boolean
    | {
          [key: string]: string | number | boolean;
      };

export interface NonAnonymousUserContext {
    isAnonymous: false;
    user_id: string;
    email: string;
    customData: {
        company_bid: number;
    };
}

import { withFeatures } from 'src/utils/component.utils';

import { ArticleWizardPanelComponent } from './article-wizard-panel.component';

export const ArticleWizardPanelContainer = withFeatures(
    ArticleWizardPanelComponent,
)((f) => ({
    xRayActionAnswer: f.xRayFeature.xRayActionAnswer,
    isXRayAnswerLoading: f.xRayFeature.isXRayAnswerLoading,
    selectedLead: f.xRayFeature.selectedLead,
    customQuestion: f.xRayFeature.customQuestion,
    selectedDocumentId: f.xRayFeature.selectedDocumentId,
    isError: f.xRayFeature.isArticleWizardError,
    predefinedQuestions: f.xRayFeature.predefinedQuestions,
    setXRayActionAnswer: f.xRayFeature.setXRayActionAnswer,
    trackXRayFeedback: f.xRayFeature.trackXRayFeedback,
    showMessage: f.toasterFeature.showMessage,
    closeDrawer: f.quickActionsFeature.clean,
    askQuestion: f.xRayFeature.askQuestionNew,
    setCustomQuestion: f.xRayFeature.setCustomQuestion,
    setSelectedDocumentId: f.xRayFeature.setSelectedDocumentId,
    abortOngoingRequests: f.xRayFeature.abortOngoingRequests,
}));

export interface IMeDataContext {
    firstName?: string;
    lastName?: string;
    email?: string;
}

export class MeDataContext {
    firstName?: string;
    lastName?: string;
    email?: string;

    constructor(data: IMeDataContext) {
        this.firstName = data.firstName;
        this.lastName = data.lastName;
        this.email = data.email;
    }
}

import { ReminderQl } from 'src/data/api/graphql/br_process/generated/graphql-sdk';

import {
    ReminderTargetType,
    TargetTypeSdkToModelMap,
} from './reminder-target-type';

export class Reminder {
    id?: string;
    assignee: number;
    description: string;
    dueDate: Date;
    createdOn?: Date;
    completedOn?: Date;
    target: ReminderTarget;
    assigneeId: number;

    constructor(raw: ReminderQl) {
        const dueDate = new Date(raw.dueDate);
        dueDate.setHours(0, 0, 0, 0);

        this.id = raw.id;
        this.description = raw.description;
        this.dueDate = dueDate;
        this.createdOn = raw.createdDate;
        this.completedOn = raw.completedDate;
        this.assignee = raw.assignee;
        this.target = {
            id: raw.target.id,
            type: TargetTypeSdkToModelMap[raw.target.type],
            title: raw.target.title,
        };
        this.assigneeId = raw.assignee;
    }
}
/**
 * Holds the necessary data to update a existing reminder
 */
export interface ReminderPanelFields {
    id?: string;
    description?: string;
    dueDate?: Date;
    assigneeId?: number;
    target?: ReminderTarget;
}

/**
 * Holds the necessary data to update a existing reminder
 */

export interface UpdateReminderData {
    description?: string;
    dueDate: Date;
    assigneeId: number;
}

/**
 * Holds the necessary data to create a new reminder
 */
export interface CreateReminderData extends UpdateReminderData {
    target: ReminderTarget;
}

interface ReminderTarget {
    id: string;
    type: ReminderTargetType;
    title?: string;
}

import { Box } from '@mui/material';
import { FC, JSXElementConstructor } from 'react';

import { BadgeColor } from 'src/app-features/achievements/presentation/achievements-section/component/achievement-badge/utils';

interface BadgeIconProps {
    color: BadgeColor;
    size?: string;
}

export const getJSXConstructor =
    (
        ColorIcon: JSXElementConstructor<unknown>,
        GrayIcon: JSXElementConstructor<unknown>,
        GoldIcon: JSXElementConstructor<unknown>,
    ): FC<BadgeIconProps> =>
    ({ color, size = '72' }) => {
        return (
            <Box
                sx={{
                    width: `${size}px`,
                    height: `${size}px`,
                    svg: { width: 'inherit', height: 'inherit' },
                }}
            >
                {color === BadgeColor.Gray ? (
                    <GrayIcon />
                ) : color === BadgeColor.Gold ? (
                    <GoldIcon />
                ) : (
                    <ColorIcon />
                )}
            </Box>
        );
    };

import styled from '@emotion/styled';

export const ElevatedCardStyled = styled.div<{
    isSelected: boolean;
}>`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 18px 18px 5px;
    border: ${({ isSelected }) =>
        isSelected ? '1px solid #BFC1FD' : '1px solid #F2F4F8'};
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    margin: 16px 0 0 0;
    .MuiButtonBase-root {
        font-weight: 400;
    }
`;

import {
    SearchFilterCriteria,
    SearchType,
    SearchTypeToSdkMap,
} from 'src/app-features/searches-configuration/domain/models/configured-search';
import {
    DocumentResults,
    createDocumentGroup,
} from 'src/app-features/searches-configuration/domain/models/document.model';
import {
    FilterLocation,
    createFilterLocation,
} from 'src/app-features/searches-configuration/domain/models/filter-location';
import {
    TenderChildCode,
    TenderRootCode,
    createTenderChildCode,
    createTenderRootCode,
} from 'src/app-features/searches-configuration/domain/models/tender-code';
import { AbortParams } from 'src/data/api/api-client';
import { FolderTag } from 'src/data/api/graphql/br_project/generated/graphql-sdk';
import {
    CountSearchByFilterModulesInputQl,
    FilterModuleType,
    SearchByFilterModulesInputQl,
} from 'src/data/api/graphql/br_search/generated/graphql-sdk';
import {
    ProjectGqlSdk,
    SearchGqlSdk,
} from 'src/data/api/graphql/graphql-client.wrapper';
import {
    FilterModule,
    buildFilterModule,
} from 'src/domain/models/filter-module/filter-module.model';

type GetParams = AbortParams;

interface UpsertSearchParams extends AbortParams {
    id: number | undefined;
    name: string;
    type: SearchType;
    filterModuleIds: number[];
}

export interface SearchFilterModuleParam {
    moduleId: number;
    data: SearchFilterCriteria;
}

interface SearchByFilterModulesParams extends AbortParams {
    searchInput: {
        filterModules: SearchFilterModuleParam[];
        paginationConf: {
            offset: number;
        };
        searchType: SearchType;
    };
}

interface SearchCountByFilterModulesParams extends AbortParams {
    searchInput: {
        filterModules: SearchFilterModuleParam[];
        searchType: SearchType;
    };
}

interface CreateFilterModuleParams extends AbortParams {
    name: string;
    data: SearchFilterCriteria;
    type: FilterModuleType;
}

interface UpdateFilterModuleParams
    extends Omit<CreateFilterModuleParams, 'type'> {
    id: number;
}

interface ByIdParams extends AbortParams {
    id: number;
}

interface InboxSearchesParams extends AbortParams {
    docGroupsInfo: { id: string; collection: string }[];
    searchId: number;
}

interface GetFilterSuggestionsParams extends AbortParams {
    partialText: string;
}

interface GetChildTenderCodesParams extends AbortParams {
    code: string;
}

interface GetFilteredTenderCodesParams extends AbortParams {
    textSearch: string;
}

export interface ISearchesApi {
    getFilterModules: (params: GetParams) => Promise<FilterModule[]>;
    upsertSearch: (params: UpsertSearchParams) => Promise<number>;
    deleteSearch: (params: ByIdParams) => Promise<boolean>;
    searchByFilterModules: (
        params: SearchByFilterModulesParams,
    ) => Promise<DocumentResults>;
    searchCountByFilterModules: (
        params: SearchCountByFilterModulesParams,
    ) => Promise<number>;
    createFilterModule: (
        params: CreateFilterModuleParams,
    ) => Promise<FilterModule | undefined>;
    updateFilterModule: (
        params: UpdateFilterModuleParams,
    ) => Promise<FilterModule | undefined>;
    deleteFilterModule: (params: ByIdParams) => Promise<boolean>;
    inboxSearches: (params: InboxSearchesParams) => Promise<number[]>;
    getFilterLocations: (
        params: GetFilterSuggestionsParams,
    ) => Promise<FilterLocation[]>;
    getDomainSources: (params: GetFilterSuggestionsParams) => Promise<string[]>;
    getTenderRootCodes: () => Promise<TenderRootCode[]>;
    getChildTenderCodes: (
        params: GetChildTenderCodesParams,
    ) => Promise<TenderChildCode[]>;
    getFilteredTenderCodes: (
        params: GetFilteredTenderCodesParams,
    ) => Promise<TenderRootCode[]>;
}

export const createSearchesApi = (
    searchGqlSdk: SearchGqlSdk,
    projectGqlSdk: ProjectGqlSdk,
): ISearchesApi => {
    const getFilterModuleById = async (
        params: ByIdParams,
    ): Promise<FilterModule | undefined> => {
        const { filterModules } =
            await searchGqlSdk.GetFilterModuleById(params);
        return filterModules.length
            ? buildFilterModule(filterModules[0])
            : undefined;
    };

    const getFilterModules = async (
        params: GetParams,
    ): Promise<FilterModule[]> => {
        const { filterModules } = await searchGqlSdk.GetFilterModules();
        return filterModules.map(buildFilterModule);
    };

    const upsertSearch = async (
        params: UpsertSearchParams,
    ): Promise<number> => {
        const { upsertSearch } = await searchGqlSdk.UpsertSearch({
            ...params,
            type: SearchTypeToSdkMap[params.type],
        });
        return upsertSearch.id;
    };

    const deleteSearch = async (params: ByIdParams): Promise<boolean> => {
        const { deleteSearch } = await searchGqlSdk.DeleteSearch(params);
        return deleteSearch.ok;
    };

    const buildSearchByFilterModulesPayload = (
        params: SearchByFilterModulesParams | SearchCountByFilterModulesParams,
    ): SearchByFilterModulesInputQl | CountSearchByFilterModulesInputQl => {
        const filterModules = params.searchInput.filterModules.map(
            (filterModule) => ({
                ...filterModule,
                data: JSON.stringify(filterModule.data),
            }),
        );

        return {
            ...params.searchInput,
            searchType: SearchTypeToSdkMap[params.searchInput.searchType],
            filterModules,
        };
    };

    const searchByFilterModules = async (
        params: SearchByFilterModulesParams,
    ): Promise<DocumentResults> => {
        const response = await searchGqlSdk.SearchByFilterModules({
            searchInput: buildSearchByFilterModulesPayload(params),
        });

        const documents = {
            totalCount: response.searchByFilterModules.totalCount,
            documentGroups: response.searchByFilterModules.docGroups.map(
                (docGroup) =>
                    createDocumentGroup(
                        docGroup,
                        params.searchInput.searchType,
                    ),
            ),
        };

        return documents;
    };

    const searchCountByFilterModules = async (
        params: SearchCountByFilterModulesParams,
    ): Promise<number> => {
        const { countSearchByFilterModules } =
            await searchGqlSdk.SearchCountByFilterModules({
                searchInput: buildSearchByFilterModulesPayload(params),
            });
        return countSearchByFilterModules.totalCount;
    };

    const createFilterModule = async (
        params: CreateFilterModuleParams,
    ): Promise<FilterModule> => {
        const { createFilterModule } =
            await searchGqlSdk.CreateFilterModule(params);
        return buildFilterModule(createFilterModule);
    };

    const updateFilterModule = async (
        params: UpdateFilterModuleParams,
    ): Promise<FilterModule | undefined> => {
        const { updateFilterModule } =
            await searchGqlSdk.UpdateFilterModule(params);
        if (updateFilterModule.ok) {
            return getFilterModuleById({
                id: params.id,
                signal: params.signal,
            });
        }
    };

    const deleteFilterModule = async (params: ByIdParams): Promise<boolean> => {
        const { deleteFilterModule } =
            await searchGqlSdk.DeleteFilterModule(params);
        return deleteFilterModule.ok;
    };

    const inboxSearches = async (
        params: InboxSearchesParams,
    ): Promise<number[]> => {
        const { createProjectFromDocGroup } =
            await projectGqlSdk.CreateProjectFromDocGroup({
                ...params,
                folderTag: FolderTag.Inbox,
            });

        return createProjectFromDocGroup.projectIds;
    };

    const getFilterLocations = async (
        params: GetFilterSuggestionsParams,
    ): Promise<FilterLocation[]> => {
        const response = await searchGqlSdk.GetLocationSuggestions(params);

        return response.locationSuggestions.map(createFilterLocation);
    };

    const getDomainSources = async (
        params: GetFilterSuggestionsParams,
    ): Promise<string[]> => {
        const response = await searchGqlSdk.GetSourceDomainSuggestions(params);

        return response.autocompleteSourceDomains;
    };

    const getTenderRootCodes = async (): Promise<TenderRootCode[]> => {
        const response = await searchGqlSdk.GetTenderRootCodes();

        return response.tenderCodeTree.map(createTenderRootCode);
    };

    const getChildTenderCodes = async (
        params: GetChildTenderCodesParams,
    ): Promise<TenderChildCode[]> => {
        const response = await searchGqlSdk.GetChildTenderCodes({
            tenderCodeId: params.code,
        });

        return response.tenderChildCodes.map(createTenderChildCode);
    };

    const getFilteredTenderCodes = async (
        params: GetFilteredTenderCodesParams,
    ): Promise<TenderRootCode[]> => {
        const response = await searchGqlSdk.GetFilteredTenderCodes({
            textSearch: params.textSearch,
        });

        return response.tenderCodesFiltered.map(createTenderRootCode);
    };

    return {
        getFilterModules,
        createFilterModule,
        updateFilterModule,
        upsertSearch,
        deleteSearch,
        searchByFilterModules,
        searchCountByFilterModules,
        deleteFilterModule,
        inboxSearches,
        getFilterLocations,
        getDomainSources,
        getTenderRootCodes,
        getChildTenderCodes,
        getFilteredTenderCodes,
    };
};

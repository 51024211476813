import { User } from 'src/domain/models/user/user.model';
import {
    BrAppEnvironment,
    getBrAppEnvironment,
} from 'src/utils/environment.utils';

import { NonAnonymousUserContext } from './feature-flags.model';

export const anonymousUser = { user_id: 'anonymous', isAnonymous: true };

const devCycleKeyMap: {
    [key in BrAppEnvironment]: { featureFlagKey: string };
} = {
    development: {
        featureFlagKey: import.meta.env.VITE_FEATURE_FLAGS_KEY_DEV ?? '',
    },
    beta: { featureFlagKey: import.meta.env.VITE_FEATURE_FLAGS_KEY_DEV ?? '' },
    staging: {
        featureFlagKey: import.meta.env.VITE_FEATURE_FLAGS_KEY_DEV ?? '',
    },
    production: {
        featureFlagKey: import.meta.env.VITE_FEATURE_FLAGS_KEY_PROD ?? '',
    },
};

/**
 * Helper function to get the DevCycle environment key from the hostname.
 */
export function getDevCycleEnvKey(): string {
    const environment = getBrAppEnvironment();
    return devCycleKeyMap[environment].featureFlagKey;
}

/**
 * Convert a non-anonymous user to a DevCycle user context.
 */
export function getNonAnonymousUser(user: User): NonAnonymousUserContext {
    return {
        isAnonymous: false,
        user_id: user.itemId.toFixed(),
        email: user.email,
        customData: {
            company_bid: user.companyId,
        },
    };
}

import { FilterOpQl } from 'src/data/api/graphql/br_process/generated/graphql-sdk';

const buildQueryStringFromFilter = (filter: FilterOpQl): string => {
    const filterQuery = `{field: ${filter.field}, fop: ${filter.fop}, ${
        filter.value !== undefined
            ? `value: ${
                  filter.value.name
                      ? `{name: "${filter.value.name}", value: "${filter.value.value}"}}`
                      : `"${filter.value}"}`
              }`
            : `values: [${
                  filter.values?.[0].name
                      ? filter.values
                            ?.map(
                                (v) =>
                                    `{name: "${v.name}", ${
                                        v.value !== undefined
                                            ? `value: "${v.value}"`
                                            : 'missing: true'
                                    }}`,
                            )
                            .join(',')
                      : filter.values
              }]}`
    }`;

    return filterQuery;
};

export const buildQueryStringFromFilterList = (filters: FilterOpQl[]) => {
    const filtersQueryString = filters
        .map(buildQueryStringFromFilter)
        .join(',');

    return `[${filtersQueryString}]`;
};

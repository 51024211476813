import { BrIcon, BrInfotip, IconName } from '@buildingradar/br_component_lib';
import { Box } from '@mui/material';
import { observer } from 'mobx-react';
import { FC, Fragment, ReactElement, useMemo } from 'react';

import { SearchType } from 'src/app-features/searches-configuration/domain/models/configured-search';
import { BuildSearchUrlQueryParam } from 'src/app-features/searches-configuration/presentation/utils/utils';
import { TranslationFn } from 'src/domain/features/translation/translation.feature';
import { User } from 'src/domain/models/user/user.model';
import { StyledIcon } from 'src/presentation/modules/primary-nav-bar/primary-nav-bar.styled';
import { AppRoute } from 'src/presentation/modules/router/app-route.list';
import { Link } from 'src/presentation/shared/link/link.component';

import {
    ItemRow,
    ItemRowText,
    ItemRowDivider,
    StyledMenuIconWrapper,
} from './sub-menu.styled';

interface SubMenuComponentProps {
    t: TranslationFn;
    user: User;
}

interface RouteOption {
    text: string;
    icon: ReactElement;
    path: AppRoute;
    legacy: false;
    className: string;
    divider?: boolean;
}

export const SubMenuComponent: FC<SubMenuComponentProps> = observer(
    ({ t, user }) => {
        const shouldShowSearches =
            !user.featureToggle.inboxOnly || user.isImpersonated;

        const submenuOptions = useMemo(() => {
            const options: RouteOption[] = [
                {
                    text: 'deal_view.state.disqualified',
                    icon: <BrIcon iconName={IconName.BrCancelOutlineIcon} />,
                    path: AppRoute.disqualified,
                    legacy: false,
                    className: 'disqualified',
                },
            ];

            if (shouldShowSearches) {
                options.push({
                    text: 'navbar.configure_searches',
                    icon: <BrIcon iconName={IconName.BrSearchIcon} />,
                    legacy: false,
                    className: 'configure-searches',
                    path: `${AppRoute.configureNewSearch}${BuildSearchUrlQueryParam(
                        SearchType.Article,
                    )}` as AppRoute,
                    divider: true,
                });
            }

            return options;
        }, [shouldShowSearches]);

        return (
            <BrInfotip
                placement="right"
                showArrow
                content={
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            margin: '0 -12px',
                        }}
                    >
                        {submenuOptions.map((option, index) => (
                            <Fragment key={index}>
                                {option.divider && <ItemRowDivider />}
                                <Link
                                    className={option.className}
                                    toLegacyApp={!!option.legacy}
                                    href={option.path}
                                >
                                    <ItemRow>
                                        {option.icon}
                                        <ItemRowText>
                                            {t(option.text)}
                                        </ItemRowText>
                                    </ItemRow>
                                </Link>
                            </Fragment>
                        ))}
                    </Box>
                }
            >
                <StyledMenuIconWrapper>
                    <StyledIcon>
                        <BrIcon
                            iconName={IconName.BrMoreHorizIcon}
                            iconSize={30}
                        />
                    </StyledIcon>
                </StyledMenuIconWrapper>
            </BrInfotip>
        );
    },
);

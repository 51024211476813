import { observer } from 'mobx-react';
import { FC, useState } from 'react';

interface LoomVideoComponentProps {
    url: string;
}

export const LoomVideoComponent: FC<LoomVideoComponentProps> = observer(
    ({ url }) => {
        const [loomUrl] = useState<string>(url);

        return (
            <div
                style={{
                    position: 'relative',
                    paddingBottom: '56.25%',
                    height: 0,
                }}
            >
                <iframe
                    title="loom"
                    src={loomUrl}
                    frameBorder="0"
                    allowFullScreen
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                    }}
                />
            </div>
        );
    },
);

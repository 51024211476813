import { MenuItemConfiguration } from '@buildingradar/br_component_lib';

import { CustomParameter } from 'src/domain/models/custom-parameter/custom-parameter.model';

export type SingleFieldType = 'single-line-text' | 'multi-line-text'; // here could be later 'multiline-text', 'number', 'date', 'phone'...
type FormControlType =
    | SingleFieldType
    | 'fields-container-line'
    | 'custom-parameter-line'
    | 'selection-parameter';

interface BaseFormControlConfig<Type extends FormControlType> {
    type: Type;
    errorText?: string;
    validate?: (value: string) => boolean;
}

interface SingleFieldTextConfig<FormValue>
    extends BaseFormControlConfig<SingleFieldType> {
    name: keyof FormValue;
    required?: boolean;
    placeholder: string;
    className: string;
    label: string;
}
interface CustomParameterConfig
    extends BaseFormControlConfig<'custom-parameter-line'> {
    customFields: CustomParameter[];
}

export type SimpleFieldConfig<FormValue> = SingleFieldTextConfig<FormValue>; // | other simple fields config
export type CustomParameterFieldConfig = CustomParameterConfig;
export const isSingleFieldConfig = <FormValue>(
    config: FormControlConfig<FormValue>,
): config is SimpleFieldConfig<FormValue> =>
    ['single-line-text', 'multi-line-text'].includes(config.type);

interface FieldsContainerLine<FormValue>
    extends BaseFormControlConfig<'fields-container-line'> {
    fields: SimpleFieldConfig<FormValue>[];
}

export interface SelectParameterConfig<FormValue>
    extends BaseFormControlConfig<'selection-parameter'> {
    options: MenuItemConfiguration[];
    name: keyof FormValue;
    placeholder: string;
    required?: boolean;
    className: string;
    resettable: boolean;
    label: string;
}

export type FormControlConfig<FormValue> =
    | SimpleFieldConfig<FormValue>
    | FieldsContainerLine<FormValue>
    | CustomParameterFieldConfig
    | SelectParameterConfig<FormValue>;

export type FormConfig<T extends object> = FormControlConfig<T>[];

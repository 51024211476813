import styled from '@emotion/styled';
import { ThumbDownAltOutlined, ThumbUpAltOutlined } from '@mui/icons-material';

export const XRayAnswerWrapper = styled.div`
    width: 100%;
`;

export const XRayAnswerList = styled.ul`
    width: 100%;
`;

export const ThumbsUp = styled(ThumbUpAltOutlined)`
    color: var(--blue-cornflower);
`;

export const ThumbsDown = styled(ThumbDownAltOutlined)`
    color: var(--demand-deal-border-color);
`;

export const FeedBackWrapperText = styled.p`
    font-size: 14px;
    font-style: italic;
`;

export const FeedBackButtonsWrapper = styled.div`
    display: flex;
    width: 100%;
    margin-top: 12px;
    flex-wrap: wrap;
    justify-content: center;
`;

export const FeedBackWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
    margin-top: 12px;
    border-radius: 100px;
`;

export const FeedBackButtonWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 28px;
    height: 28px;
    cursor: pointer;
    border: 1px solid var(--toggle-track-active);
    border-radius: 0 3px 3px 0;
    :first-child {
        border-right: none;
        border-radius: 3px 0 0 3px;
    }
    svg {
        font-size: 18px;
    }

    :hover {
        background-color: rgba(0, 0, 0, 0.04);
    }
`;

export const NoResultsText = styled.p`
    font-size: 16px;
    text-align: center;
    color: var(--gray-50);
    margin: 30px 0;
`;

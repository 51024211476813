import {
    ArticleDocument,
    MergedDocument,
} from 'src/app-features/searches-configuration/domain/models/document.model';
import { DocumentType as DocumentTypeForSdk } from 'src/app-features/searches-configuration/domain/models/document.model';
import { ArticleDocumentPartFragment } from 'src/data/api/graphql/br_project/generated/graphql-sdk';
import {
    Address,
    createAddress,
    createAddressFromSdk,
    RawAddressData,
} from 'src/domain/models/address/address.model';
import {
    RawBrDateTime,
    createNullableBrDateTimeFromSdk,
    BrDateTimeSdk,
    createNullableBrDateTime,
    BrDateTime,
} from 'src/domain/models/br-time/br-time.model';
import { Category } from 'src/domain/models/category/category.model';
import { createContacts } from 'src/domain/models/contact/contact.model';
import {
    createBaseDocument,
    RawBaseDocument,
    BaseDocument,
    DocumentType,
    Document,
    createBaseDocumentLegacy,
} from 'src/domain/models/document/document.model';
import { ConstructionPhase } from 'src/domain/models/phase/phase.model';
import { ProjectAiGeneratedData } from '../lead/project-ai-generated-data';

export interface RawArticle extends RawBaseDocument<DocumentType.Article> {
    abstract: string;
    address: RawAddressData;
    paragraphs: string[];
    construction_start_date?: RawBrDateTime | null;
    construction_end_date?: RawBrDateTime | null;
    gfa?: null | number;
    n_residential_units?: null | number; // check if null is possible
    n_floors?: null | number; // example: 12; the number of the floors
    n_rooms?: null | number; // example: 12; the number of the rooms in the apartment
    height?: null | number;
}

// CONSIDER THAT MOST OF THE FIELDS COULD BE NULLABLE!!!
export interface Article extends BaseDocument<DocumentType.Article> {
    type: DocumentType.Article;
    itemId: string;
    title: string;
    categories: Category[];
    constructionPhases: ConstructionPhase[];
    volumeReference: number | null;
    address: Address | null;
    abstract: string;
    paragraphs: string[];
    constructionStartDate: BrDateTimeSdk | BrDateTime | null;
    constructionEndDate: BrDateTimeSdk | BrDateTime | null;
    gfa?: number | null;
    nResidentialUnits?: number | null;
}

export const createArticleLegacy = (rawArticle: RawArticle): Article => {
    const {
        construction_start_date, // ALSO IN PROJECT
        construction_end_date,
    } = rawArticle;

    const baseDocument = createBaseDocumentLegacy(rawArticle);

    let descriptions = baseDocument.descriptions;
    if (rawArticle.abstract) {
        descriptions = [...descriptions, rawArticle.abstract];
    }

    if (rawArticle.paragraphs) {
        descriptions = [...descriptions, ...rawArticle.paragraphs];
    }

    const description = descriptions.length > 0 ? descriptions.join(' ') : '';
    return {
        ...baseDocument,
        abstract: rawArticle.abstract,
        address: createAddress(rawArticle.address),
        contacts: rawArticle.contacts
            ? createContacts(rawArticle.contacts)
            : [],
        paragraphs: rawArticle.paragraphs,
        constructionStartDate: createNullableBrDateTime(
            construction_start_date,
        ), // ALSO IN PROJECT
        constructionEndDate: createNullableBrDateTime(construction_end_date),
        descriptions,
        description,
        gfa: rawArticle.gfa,
        nResidentialUnits: rawArticle.n_residential_units,
    };
};

/**
 * It determines the title and summary of the article. If there is a Ai generated data
 * for the article, it uses it, otherwise it uses the title, abstract and paragraphs of the article.
 */
const getArticleTitleAndSummary = (
    docGroupId: string,
    baseDocument: BaseDocument<DocumentType.Article>,
    generatedProjectsData?: Map<string, ProjectAiGeneratedData>,
    abstract?: string | null | undefined,
    paragraphs?: string[] | null | undefined,
) => {
    const generatedData = generatedProjectsData?.get(docGroupId);
    const title = generatedData ? generatedData.title : baseDocument.title;
    let descriptions: string[];
    let description: string;
    if (generatedData) {
        descriptions = [generatedData.summary];
        description = generatedData.summary;
    } else {
        descriptions = baseDocument.descriptions;
        if (abstract) {
            descriptions = [...descriptions, abstract];
        }
        if (paragraphs) {
            descriptions = [...descriptions, ...paragraphs];
        }
        description = descriptions.length > 0 ? descriptions.join(' ') : '';
    }

    return { title, descriptions, description };
};

export const createArticle = (
    rawArticle: ArticleDocumentPartFragment,
    docGroupId: string,
    generatedProjectsData?: Map<string, ProjectAiGeneratedData>,
): Article => {
    const baseDocument = createBaseDocument(rawArticle, DocumentType.Article);

    const { title, description, descriptions } = getArticleTitleAndSummary(
        docGroupId,
        baseDocument,
        generatedProjectsData,
        rawArticle.abstract,
        rawArticle.paragraphs,
    );

    return {
        ...baseDocument,
        abstract: rawArticle.abstract ?? '',
        address: createAddressFromSdk(rawArticle.address ?? null),
        paragraphs: rawArticle.paragraphs ?? [],
        constructionStartDate: createNullableBrDateTimeFromSdk(
            rawArticle.constructionStartDate ?? null,
        ),
        constructionEndDate: createNullableBrDateTimeFromSdk(
            rawArticle.constructionEndDate ?? null,
        ),
        title,
        descriptions,
        description,
        gfa: rawArticle.gfa,
        nResidentialUnits: rawArticle.nResidentialUnits,
    };
};

export const isArticle = (
    document: Document | MergedDocument,
): document is Article | ArticleDocument => {
    if (Object.prototype.hasOwnProperty.call(document, 'isSdkInterface')) {
        return isArticleSdk(document as MergedDocument);
    } else {
        return isArticleV1(document as Document);
    }
};

const isArticleV1 = (document: Document): document is Article =>
    document.type === DocumentType.Article;

const isArticleSdk = (document: MergedDocument): document is ArticleDocument =>
    document.type === DocumentTypeForSdk.Article;

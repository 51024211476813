import { observer } from 'mobx-react';
import { FC } from 'react';

import {
    ContactEnrichmentData,
    ContactEnrichmentResult,
    EnrichmentResultSelection,
} from 'src/app-features/contact-enrichment/domain/contact-enrichment.model';
import { EnrichmentResultFieldComponent } from 'src/app-features/contact-enrichment/presentation/components/enrichment-result/contact-enrichment-result-fields.component';
import { PredefinedCustomFields } from 'src/app-features/contact/data/model/deal-contacts.model';

interface EnrichmentResultDisplayComponentProps {
    enrichmentResults: ContactEnrichmentResult;
    selectedValues: EnrichmentResultSelection;
    onSelect: (
        key: PredefinedCustomFields,
        value?: ContactEnrichmentData,
    ) => void;
    setClickedLink: (link: string) => void;
}
export const EnrichmentResultDisplayComponent: FC<EnrichmentResultDisplayComponentProps> =
    observer(
        ({ enrichmentResults, selectedValues, onSelect, setClickedLink }) => {
            return (
                <>
                    {!!enrichmentResults.positions.length && (
                        <EnrichmentResultFieldComponent
                            options={enrichmentResults.positions}
                            label={
                                'actions.contact_enrichment.result.positions_label'
                            }
                            selectedValue={selectedValues.position}
                            onSelect={(val) =>
                                onSelect(PredefinedCustomFields.position, val)
                            }
                            setClickedLink={setClickedLink}
                        />
                    )}

                    {!!enrichmentResults.phones.length && (
                        <EnrichmentResultFieldComponent
                            options={enrichmentResults.phones}
                            selectedValue={selectedValues.mobile}
                            label={
                                'actions.contact_enrichment.result.phones_label'
                            }
                            onSelect={(val) =>
                                onSelect(PredefinedCustomFields.mobile, val)
                            }
                            setClickedLink={setClickedLink}
                        />
                    )}
                    {!!enrichmentResults.emails.length && (
                        <EnrichmentResultFieldComponent
                            options={enrichmentResults.emails}
                            label={
                                'actions.contact_enrichment.result.emails_label'
                            }
                            selectedValue={selectedValues.mail}
                            onSelect={(val) =>
                                onSelect(PredefinedCustomFields.mail, val)
                            }
                            setClickedLink={setClickedLink}
                        />
                    )}
                    {!!enrichmentResults.linkedins.length && (
                        <EnrichmentResultFieldComponent
                            options={enrichmentResults.linkedins}
                            label={
                                'actions.contact_enrichment.result.linkedins_label'
                            }
                            selectedValue={selectedValues.linkedin}
                            onSelect={(val) =>
                                onSelect(PredefinedCustomFields.linkedin, val)
                            }
                            setClickedLink={setClickedLink}
                            isLink
                        />
                    )}
                </>
            );
        },
    );

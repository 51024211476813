import { BrTextInputStatus } from '@buildingradar/br_component_lib';
import { makeAutoObservable } from 'mobx';

import { CustomParametersApi } from 'src/data/api/custom-parameters/custom-parameters.api';
import { CustomParametersStore } from 'src/data/stores/custom-parameters/custom-parameters.store';
import { IBaseStore } from 'src/data/stores/shared/base.store.interface';
import { CustomParameter } from 'src/domain/models/custom-parameter/custom-parameter.model';
import { handleRequest } from 'src/utils/handle-request.utils';

import {
    PredefinedDealCustomFields,
    filterValidNonNullableParameters,
} from './custom-parameters.utils';

export interface CustomParametersFeature {
    customParameters: CustomParameter[];
    hiddenParameters: CustomParameter[];
    parametersMap: Map<string, CustomParameter>;
    isLoading: boolean;
    showHiddenParameters: boolean;
    fieldProgress: Map<string, BrTextInputStatus>;
    dealDisqualificationParameter: CustomParameter | undefined;
    setShowHiddenParameters: (flag: boolean) => void;
    requestParameters: (dealId: string) => void;
    updateParameterValue: (dealId: string, name: string, value: string) => void;
}

export class CustomParametersFeatureImpl implements CustomParametersFeature {
    isLoading = false;
    showHiddenParameters = false;
    fieldProgress = new Map();

    constructor(
        private customParametersApi: CustomParametersApi,
        private parametersStore: CustomParametersStore,
        private baseStore: IBaseStore,
    ) {
        makeAutoObservable(this);
    }

    get parametersMap(): Map<string, CustomParameter> {
        return this.parametersStore.parametersMap;
    }

    get customParameters(): CustomParameter[] {
        return filterValidNonNullableParameters(
            this.parametersStore.orderedParamNames.map((name) =>
                this.parametersStore.parametersMap.get(name),
            ),
        );
    }

    get hiddenParameters(): CustomParameter[] {
        return filterValidNonNullableParameters(
            this.parametersStore.hiddenParamNames.map((name) =>
                this.parametersStore.parametersMap.get(name),
            ),
        );
    }

    get dealDisqualificationParameter(): CustomParameter | undefined {
        return this.parametersStore.parametersMap.get(
            PredefinedDealCustomFields.dealDisqualificationReason,
        );
    }

    setShowHiddenParameters = (flag: boolean) => {
        this.showHiddenParameters = flag;
    };

    setIsLoading = (flag: boolean) => {
        this.isLoading = flag;
    };

    requestParameters = (dealId: string) => {
        this.parametersStore.clearParameters();
        handleRequest(
            this.customParametersApi.getParameters,
            { dealId },
            this.setParameters,
            this.setIsLoading,
            (error) =>
                this.baseStore.onRequestFailed(
                    'request-custom-parameters',
                    error,
                ),
        );
    };

    setParameters = (parameters: CustomParameter[]) => {
        this.parametersStore.setParameters(parameters);
    };

    onUpdateParameterRequest = (name: string, value: string) => {
        this.parametersStore.setParameterValue(name, value);
    };

    onUpdateParamError = (error?: Error) => {
        this.baseStore.onRequestFailed('update-custom-parameter-value', error);
    };

    updateFieldProgress = (key: string, status: BrTextInputStatus) => {
        this.fieldProgress.set(key, status);
    };

    updateParameterValue = (dealId: string, name: string, value: string) => {
        handleRequest(
            this.customParametersApi.updateParameterValue,
            { dealId, name, value },
            () => {
                this.onUpdateParameterRequest(name, value);
                this.updateFieldProgress(name, 'success');
            },
            (flag) => flag && this.updateFieldProgress(name, 'progress'),
            (error) => {
                this.onUpdateParamError(error);
                error && this.updateFieldProgress(name, 'error');
            },
        );
    };
}

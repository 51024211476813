import { BrIcon, IconName, IconSize } from '@buildingradar/br_component_lib';
import { IconButton } from '@mui/material';
import { observer } from 'mobx-react';
import { FC } from 'react';

import {
    CloseMenuWrapperStyled,
    MobileMenuHeaderWrapper,
} from './mobile-menu-header.styled';

export const MobileMenuHeader: FC<{ onClose: () => void }> = observer(
    ({ onClose }) => {
        return (
            <MobileMenuHeaderWrapper>
                <CloseMenuWrapperStyled>
                    <IconButton onClick={onClose}>
                        <BrIcon
                            iconName={IconName.BrMenuMobileOpenIcon}
                            iconColor="var(--gray-70)"
                            iconSize={IconSize.large}
                        />
                    </IconButton>
                </CloseMenuWrapperStyled>
                Menu
            </MobileMenuHeaderWrapper>
        );
    },
);

import { TFunction } from 'react-i18next';

import { SearchFilterCriteria } from 'src/app-features/searches-configuration/domain/models/configured-search';
import { FilterLocation } from 'src/app-features/searches-configuration/domain/models/filter-location';
import {
    AutocompleteDataContext,
    FilterModuleConfigOption,
    FilterOperation,
    MultiselectDataContext,
    PeriodRangeDataContext,
    SwitchDataContext,
    TreeViewDataContext,
} from 'src/app-features/searches-configuration/domain/models/filter-module-config-option';
import { FilterModuleType } from 'src/domain/models/filter-module/filter-module.model';
import { TreeNode } from 'src/presentation/shared/br-tree-view-selector/tree-node.models';
import { KeyValuePair } from 'src/utils/type.utils';

export const getFilterModuleConfigOptionsByType = (
    type: FilterModuleType,
    fetchers: {
        locationFetcher: (term: string) => Promise<FilterLocation[]>;
        domainSourcesFetcher: (term: string) => Promise<KeyValuePair<string>[]>;
        rootTenderCodesFetcher: () => Promise<TreeNode[]>;
        childrenTenderCodesFetcher: (parentCode: string) => Promise<TreeNode[]>;
        filteredTenderCodesFetcher: (term: string) => Promise<TreeNode[]>;
    },
    t: TFunction<'translation', undefined>,
) => {
    const generalOptions = getGeneralFilterModuleOptions(
        fetchers.locationFetcher,
        fetchers.domainSourcesFetcher,
        t,
    );

    if (type === FilterModuleType.General) {
        return generalOptions;
    } else {
        const yearRangeOptions = getYearRangeOptions(t);
        if (type === FilterModuleType.Article) {
            return [
                ...generalOptions,
                ...getArticleFilterModuleOptions(t, yearRangeOptions),
            ];
        } else {
            const deadlineRangeOptions = getDeadlineRangeOptions(t);
            return [
                ...generalOptions,
                ...getTenderFilterModuleOptions(
                    fetchers.rootTenderCodesFetcher,
                    fetchers.childrenTenderCodesFetcher,
                    fetchers.filteredTenderCodesFetcher,
                    t,
                    yearRangeOptions,
                    deadlineRangeOptions,
                ),
            ];
        }
    }
};

export const hasAtLeastOneCriteriaAdded = (
    criteriaObject: SearchFilterCriteria,
) => Object.values(criteriaObject).some((sfc) => sfc.length > 0);

const DaysInYear = 365;
const DaysInMonth = 30;

const getYearRangeOptions = (
    t: TFunction<'translation', undefined>,
): { value: number; label: string }[] => [
    {
        value: Number.NEGATIVE_INFINITY,
        label: t('date_range_picker.infinity_in_the_past'),
    },
    {
        value: -10 * DaysInYear,
        label: t('date_range_picker.n_years_in_the_past', { count: 10 }),
    },
    {
        value: -9 * DaysInYear,
        label: t('date_range_picker.n_years_in_the_past', { count: 9 }),
    },
    {
        value: -8 * DaysInYear,
        label: t('date_range_picker.n_years_in_the_past', { count: 8 }),
    },
    {
        value: -7 * DaysInYear,
        label: t('date_range_picker.n_years_in_the_past', { count: 7 }),
    },
    {
        value: -6 * DaysInYear,
        label: t('date_range_picker.n_years_in_the_past', { count: 6 }),
    },
    {
        value: -5 * DaysInYear,
        label: t('date_range_picker.n_years_in_the_past', { count: 5 }),
    },
    {
        value: -4 * DaysInYear,
        label: t('date_range_picker.n_years_in_the_past', { count: 4 }),
    },
    {
        value: -3 * DaysInYear,
        label: t('date_range_picker.n_years_in_the_past', { count: 3 }),
    },
    {
        value: -2 * DaysInYear,
        label: t('date_range_picker.n_years_in_the_past', { count: 2 }),
    },
    {
        value: -1 * DaysInYear,
        label: t('date_range_picker.n_years_in_the_past', { count: 1 }),
    },
    { value: 0 * DaysInYear, label: t('common.today') },
    {
        value: 1 * DaysInYear,
        label: t('date_range_picker.n_years_in_the_future', { count: 1 }),
    },
    {
        value: 2 * DaysInYear,
        label: t('date_range_picker.n_years_in_the_future', { count: 2 }),
    },
    {
        value: 3 * DaysInYear,
        label: t('date_range_picker.n_years_in_the_future', { count: 3 }),
    },
    {
        value: 4 * DaysInYear,
        label: t('date_range_picker.n_years_in_the_future', { count: 4 }),
    },
    {
        value: 5 * DaysInYear,
        label: t('date_range_picker.n_years_in_the_future', { count: 5 }),
    },
    {
        value: 6 * DaysInYear,
        label: t('date_range_picker.n_years_in_the_future', { count: 6 }),
    },
    {
        value: 7 * DaysInYear,
        label: t('date_range_picker.n_years_in_the_future', { count: 7 }),
    },
    {
        value: 8 * DaysInYear,
        label: t('date_range_picker.n_years_in_the_future', { count: 8 }),
    },
    {
        value: 9 * DaysInYear,
        label: t('date_range_picker.n_years_in_the_future', { count: 9 }),
    },
    {
        value: 10 * DaysInYear,
        label: t('date_range_picker.n_years_in_the_future', { count: 10 }),
    },
    {
        value: Number.POSITIVE_INFINITY,
        label: t('date_range_picker.infinity_in_the_future'),
    },
];

const getDeadlineRangeOptions = (
    t: TFunction<'translation', undefined>,
): { value: number; label: string }[] => [
    {
        value: Number.NEGATIVE_INFINITY,
        label: t('date_range_picker.infinity_in_the_past'),
    },
    {
        value: -1 * DaysInYear,
        label: t('date_range_picker.n_years_in_the_past', { count: 1 }),
    },
    {
        value: -6 * DaysInMonth,
        label: t('date_range_picker.n_months_in_the_past', { count: 6 }),
    },
    {
        value: -5 * DaysInMonth,
        label: t('date_range_picker.n_months_in_the_past', { count: 5 }),
    },
    {
        value: -4 * DaysInMonth,
        label: t('date_range_picker.n_months_in_the_past', { count: 4 }),
    },
    {
        value: -3 * DaysInMonth,
        label: t('date_range_picker.n_months_in_the_past', { count: 3 }),
    },
    {
        value: -2 * DaysInMonth,
        label: t('date_range_picker.n_months_in_the_past', { count: 2 }),
    },
    {
        value: -1 * DaysInMonth,
        label: t('date_range_picker.n_days_in_the_past', { count: 30 }),
    },
    {
        value: -14,
        label: t('date_range_picker.n_days_in_the_past', { count: 14 }),
    },
    {
        value: -7,
        label: t('date_range_picker.n_days_in_the_past', { count: 7 }),
    },
    {
        value: -6,
        label: t('date_range_picker.n_days_in_the_past', { count: 6 }),
    },
    {
        value: -5,
        label: t('date_range_picker.n_days_in_the_past', { count: 5 }),
    },
    {
        value: -4,
        label: t('date_range_picker.n_days_in_the_past', { count: 4 }),
    },
    {
        value: -3,
        label: t('date_range_picker.n_days_in_the_past', { count: 3 }),
    },
    {
        value: -2,
        label: t('date_range_picker.n_days_in_the_past', { count: 2 }),
    },
    {
        value: -1,
        label: t('date_range_picker.n_days_in_the_past', { count: 1 }),
    },
    { value: 0 * DaysInMonth, label: t('common.today') },
    {
        value: 1,
        label: t('date_range_picker.n_days_in_the_future', { count: 1 }),
    },
    {
        value: 2,
        label: t('date_range_picker.n_days_in_the_future', { count: 2 }),
    },
    {
        value: 3,
        label: t('date_range_picker.n_days_in_the_future', { count: 3 }),
    },
    {
        value: 4,
        label: t('date_range_picker.n_days_in_the_future', { count: 4 }),
    },
    {
        value: 5,
        label: t('date_range_picker.n_days_in_the_future', { count: 5 }),
    },
    {
        value: 6,
        label: t('date_range_picker.n_days_in_the_future', { count: 6 }),
    },
    {
        value: 7,
        label: t('date_range_picker.n_days_in_the_future', { count: 7 }),
    },
    {
        value: 14,
        label: t('date_range_picker.n_days_in_the_future', { count: 14 }),
    },
    {
        value: 30,
        label: t('date_range_picker.n_days_in_the_future', { count: 30 }),
    },
    {
        value: 2 * DaysInMonth,
        label: t('date_range_picker.n_months_in_the_future', { count: 2 }),
    },
    {
        value: 3 * DaysInMonth,
        label: t('date_range_picker.n_months_in_the_future', { count: 3 }),
    },
    {
        value: 4 * DaysInMonth,
        label: t('date_range_picker.n_months_in_the_future', { count: 4 }),
    },
    {
        value: 5 * DaysInMonth,
        label: t('date_range_picker.n_months_in_the_future', { count: 5 }),
    },
    {
        value: 6 * DaysInMonth,
        label: t('date_range_picker.n_months_in_the_future', { count: 6 }),
    },
    {
        value: 1 * DaysInYear,
        label: t('date_range_picker.n_years_in_the_future', { count: 1 }),
    },
    {
        value: Number.POSITIVE_INFINITY,
        label: t('date_range_picker.infinity_in_the_future'),
    },
];

const getCategoryOptions = (
    t: TFunction<'translation', undefined>,
): MultiselectDataContext => ({
    options: [
        {
            value: 'office',
            label: t(
                'search_configuration.filter_edition.criteria_list_option.category.office',
            ),
        },
        {
            value: 'education_science',
            label: t(
                'search_configuration.filter_edition.criteria_list_option.category.edu_science',
            ),
            children: [
                {
                    value: 'university',
                    label: t(
                        'search_configuration.filter_edition.criteria_list_option.category.edu_science.universities',
                    ),
                },
                {
                    value: 'research',
                    label: t(
                        'search_configuration.filter_edition.criteria_list_option.category.edu_science.research_facilities',
                    ),
                },
                {
                    value: 'library',
                    label: t(
                        'search_configuration.filter_edition.criteria_list_option.category.edu_science.libraries',
                    ),
                },
                {
                    value: 'kindergarden',
                    label: t(
                        'search_configuration.filter_edition.criteria_list_option.category.edu_science.child_care',
                    ),
                },
                {
                    value: 'school',
                    label: t(
                        'search_configuration.filter_edition.criteria_list_option.category.edu_science.schools',
                    ),
                },
            ],
        },
        {
            value: 'health',
            label: t(
                'search_configuration.filter_edition.criteria_list_option.category.health',
            ),
            children: [
                {
                    value: 'hospitals',
                    label: t(
                        'search_configuration.filter_edition.criteria_list_option.category.health.hospitals',
                    ),
                },
                {
                    value: 'pharmacies',
                    label: t(
                        'search_configuration.filter_edition.criteria_list_option.category.health.pharmacies',
                    ),
                },
                {
                    value: 'caring_home',
                    label: t(
                        'search_configuration.filter_edition.criteria_list_option.category.health.caring_homes',
                    ),
                },
            ],
        },
        {
            value: 'sport',
            label: t(
                'search_configuration.filter_edition.criteria_list_option.sport',
            ),
            children: [
                {
                    value: 'wellness',
                    label: t(
                        'search_configuration.filter_edition.criteria_list_option.sport.swimming_wellness',
                    ),
                },
                {
                    value: 'stadium',
                    label: t(
                        'search_configuration.filter_edition.criteria_list_option.sport.stadiums',
                    ),
                },
                {
                    value: 'gym',
                    label: t(
                        'search_configuration.filter_edition.criteria_list_option.sport.gyms',
                    ),
                },
                {
                    value: 'sports_halls',
                    label: t(
                        'search_configuration.filter_edition.criteria_list_option.sport.sports_halls',
                    ),
                },
            ],
        },
        {
            value: 'hospitality',
            label: t(
                'search_configuration.filter_edition.criteria_list_option.hospitality',
            ),
            children: [
                {
                    value: 'hotel',
                    label: t(
                        'search_configuration.filter_edition.criteria_list_option.hospitality.hotels',
                    ),
                },
                {
                    value: 'gastronomy',
                    label: t(
                        'search_configuration.filter_edition.criteria_list_option.gastronomy',
                    ),
                },
                {
                    value: 'accomodation',
                    label: t(
                        'search_configuration.filter_edition.criteria_list_option.vacation_rentals',
                    ),
                },
            ],
        },
        {
            value: 'landscaping',
            label: t(
                'search_configuration.filter_edition.criteria_list_option.landscaping',
            ),
        },
        {
            value: 'infrastructure',
            label: t(
                'search_configuration.filter_edition.criteria_list_option.infrastructure',
            ),
            children: [
                {
                    value: 'airport',
                    label: t(
                        'search_configuration.filter_edition.criteria_list_option.infrastructure.airports',
                    ),
                },
                {
                    value: 'bridge',
                    label: t(
                        'search_configuration.filter_edition.criteria_list_option.infrastructure.bridges',
                    ),
                },
                {
                    value: 'harbor',
                    label: t(
                        'search_configuration.filter_edition.criteria_list_option.infrastructure.harbors',
                    ),
                },
                {
                    value: 'lineworks',
                    label: t(
                        'search_configuration.filter_edition.criteria_list_option.infrastructure.line_works',
                    ),
                },
                {
                    value: 'parking',
                    label: t(
                        'search_configuration.filter_edition.criteria_list_option.infrastructure.parking_spaces',
                    ),
                },
                {
                    value: 'rail',
                    label: t(
                        'search_configuration.filter_edition.criteria_list_option.infrastructure.rail_works',
                    ),
                },
                {
                    value: 'road',
                    label: t(
                        'search_configuration.filter_edition.criteria_list_option.infrastructure.road_works',
                    ),
                },
                {
                    value: 'sewage',
                    label: t(
                        'search_configuration.filter_edition.criteria_list_option.infrastructure.sewages',
                    ),
                },
                {
                    value: 'transit_station',
                    label: t(
                        'search_configuration.filter_edition.criteria_list_option.infrastructure.transit_stations',
                    ),
                },
                {
                    value: 'tunnel',
                    label: t(
                        'search_configuration.filter_edition.criteria_list_option.infrastructure.tunnels',
                    ),
                },
            ],
        },
        {
            value: 'agriculture',
            label: t(
                'search_configuration.filter_edition.criteria_list_option.agriculture',
            ),
        },
        {
            value: 'commercial',
            label: t(
                'search_configuration.filter_edition.criteria_list_option.commercial',
            ),
            children: [
                {
                    value: 'retail',
                    label: t(
                        'search_configuration.filter_edition.criteria_list_option.commercial.retail_shop',
                    ),
                },
                {
                    value: 'consumer_goods',
                    label: t(
                        'search_configuration.filter_edition.criteria_list_option.commercial.supermarket_wholesale',
                    ),
                },
                {
                    value: 'banking',
                    label: t(
                        'search_configuration.filter_edition.criteria_list_option.commercial.banking',
                    ),
                },
                {
                    value: 'automotive',
                    label: t(
                        'search_configuration.filter_edition.criteria_list_option.commercial.car_dealers',
                    ),
                },
            ],
        },
        {
            value: 'residential',
            label: t(
                'search_configuration.filter_edition.criteria_list_option.residential',
            ),
            children: [
                {
                    value: 'single_house',
                    label: t(
                        'search_configuration.filter_edition.criteria_list_option.residential.single_family_homes',
                    ),
                },
                {
                    value: 'row_house',
                    label: t(
                        'search_configuration.filter_edition.criteria_list_option.residential.multi_family_homes',
                    ),
                },
                {
                    value: 'apartment',
                    label: t(
                        'search_configuration.filter_edition.criteria_list_option.residential.apartments',
                    ),
                },
                {
                    value: 'residential_dormitory',
                    label: t(
                        'search_configuration.filter_edition.criteria_list_option.residential.residential_dormitories',
                    ),
                },
                {
                    value: 'caring_home',
                    label: t(
                        'search_configuration.filter_edition.criteria_list_option.residential.caring_homes',
                    ),
                },
            ],
        },
        {
            value: 'culture_event',
            label: t(
                'search_configuration.filter_edition.criteria_list_option.culture_events',
            ),
            children: [
                {
                    value: 'conference',
                    label: t(
                        'search_configuration.filter_edition.criteria_list_option.culture_events.conferences',
                    ),
                },
                {
                    value: 'museum',
                    label: t(
                        'search_configuration.filter_edition.criteria_list_option.culture_events.museums',
                    ),
                },
                {
                    value: 'theater',
                    label: t(
                        'search_configuration.filter_edition.criteria_list_option.culture_events.theater',
                    ),
                },
                {
                    value: 'music',
                    label: t(
                        'search_configuration.filter_edition.criteria_list_option.culture_events.music',
                    ),
                },
                {
                    value: 'cinema',
                    label: t(
                        'search_configuration.filter_edition.criteria_list_option.culture_events.cinema',
                    ),
                },
                {
                    value: 'casino',
                    label: t(
                        'search_configuration.filter_edition.criteria_list_option.culture_events.casino',
                    ),
                },
                {
                    value: 'zoo',
                    label: t(
                        'search_configuration.filter_edition.criteria_list_option.culture_events.zoos',
                    ),
                },
                {
                    value: 'monument',
                    label: t(
                        'search_configuration.filter_edition.criteria_list_option.culture_events.monuments',
                    ),
                },
            ],
        },
        {
            value: 'industrial',
            label: t(
                'search_configuration.filter_edition.criteria_list_option.industrial',
            ),
            children: [
                {
                    value: 'logistics',
                    label: t(
                        'search_configuration.filter_edition.criteria_list_option.industrial.logistics',
                    ),
                },
                {
                    value: 'utilities',
                    label: t(
                        'search_configuration.filter_edition.criteria_list_option.industrial.utilities',
                    ),
                },
                {
                    value: 'disposal',
                    label: t(
                        'search_configuration.filter_edition.criteria_list_option.industrial.disposal',
                    ),
                },
                {
                    value: 'factories',
                    label: t(
                        'search_configuration.filter_edition.criteria_list_option.industrial.factories',
                    ),
                },
            ],
        },
        {
            value: 'public_service_administration',
            label: t(
                'search_configuration.filter_edition.criteria_list_option.public_service_administration',
            ),
            children: [
                {
                    value: 'military',
                    label: t(
                        'search_configuration.filter_edition.criteria_list_option.public_service_administration.military',
                    ),
                },
                {
                    value: 'police',
                    label: t(
                        'search_configuration.filter_edition.criteria_list_option.public_service_administration.police',
                    ),
                },
                {
                    value: 'fire_service',
                    label: t(
                        'search_configuration.filter_edition.criteria_list_option.public_service_administration.fire_service',
                    ),
                },
                {
                    value: 'prison',
                    label: t(
                        'search_configuration.filter_edition.criteria_list_option.public_service_administration.prisons',
                    ),
                },
                {
                    value: 'gov_administration',
                    label: t(
                        'search_configuration.filter_edition.criteria_list_option.public_service_administration.gov_administration',
                    ),
                },
            ],
        },
        {
            value: 'historical_religious',
            label: t(
                'search_configuration.filter_edition.criteria_list_option.historical_religious',
            ),
        },
    ],
});

const getGeneralFilterModuleOptions = (
    locationFetcher: (term: string) => Promise<FilterLocation[]>,
    domainSourcesFetcher: (term: string) => Promise<KeyValuePair<string>[]>,
    t: TFunction<'translation', undefined>,
): FilterModuleConfigOption<unknown>[] => [
    {
        name: 'name',
        label: t(
            'search_configuration.filter_edition.criteria_list_option.free_text_search',
        ),
        type: FilterModuleType.General,
        uiType: 'freeTextCriteria',
        defaultOperation: FilterOperation.or,
        allowedOperations: [
            { operation: FilterOperation.not, color: 'var(--red-50)' },
            { operation: FilterOperation.exact, color: 'var(--green-50)' },
            { operation: FilterOperation.notExact, color: 'var(--red-50)' },
        ],
    },
    {
        name: 'associated_companies',
        label: t(
            'search_configuration.filter_edition.criteria_list_option.associated_companies',
        ),
        type: FilterModuleType.General,
        uiType: 'freeTextCriteria',
        defaultOperation: FilterOperation.or,
        allowedOperations: [
            { operation: FilterOperation.not, color: 'var(--red-50)' },
        ],
    },
    {
        name: 'category',
        label: t(
            'search_configuration.filter_edition.criteria_list_option.category',
        ),
        type: FilterModuleType.General,
        uiType: 'multiselectCriteria',
        defaultOperation: FilterOperation.or,
        allowedOperations: [
            { operation: FilterOperation.not, color: 'var(--red-50)' },
        ],
        uiDataContext: {
            options: getCategoryOptions(t).options,
        } as MultiselectDataContext,
        extraFields: {
            invalid: false,
        },
    },
    {
        name: 'location',
        label: t(
            'search_configuration.filter_edition.criteria_list_option.location',
        ),
        type: FilterModuleType.General,
        uiType: 'autocompleteCriteria',
        defaultOperation: FilterOperation.or,
        allowedOperations: [
            { operation: FilterOperation.not, color: 'var(--red-50)' },
        ],
        uiDataContext: {
            requestCallback: locationFetcher,
        } as AutocompleteDataContext<FilterLocation>,
    },
    {
        name: 'zip_ranges',
        label: t(
            'search_configuration.filter_edition.criteria_list_option.free_zip_ranges',
        ),
        type: FilterModuleType.General,
        uiType: 'freeTextCriteria',
        defaultOperation: FilterOperation.or,
        allowedOperations: [
            { operation: FilterOperation.not, color: 'var(--red-50)' },
        ],
        extraFields: {
            additional: 'range',
        },
        isDisabledCallback: (
            searchFilterCriteriaContext: SearchFilterCriteria,
        ) => {
            return !searchFilterCriteriaContext['location']?.length;
        },
    },
    {
        name: 'contacts_exist',
        label: t(
            'search_configuration.filter_edition.criteria_list_option.min_one_company',
        ),
        type: FilterModuleType.General,
        uiType: 'switchCriteria',
        defaultOperation: FilterOperation.or,
        uiDataContext: {
            enabledValue: 'must_exist',
        } as SwitchDataContext,
    },
    {
        name: 'language',
        label: t(
            'search_configuration.filter_edition.criteria_list_option.language',
        ),
        type: FilterModuleType.General,
        uiType: 'multiselectCriteria',
        defaultOperation: FilterOperation.or,
        uiDataContext: {
            options: [
                { value: 'EN', label: t('language.english') },
                { value: 'DE', label: t('language.german') },
            ],
        } as MultiselectDataContext,
    },
    {
        name: 'excluded_sources',
        label: t(
            'search_configuration.filter_edition.criteria_list_option.excluded_sources',
        ),
        type: FilterModuleType.General,
        uiType: 'autocompleteCriteria',
        defaultOperation: FilterOperation.or,
        uiDataContext: {
            requestCallback: domainSourcesFetcher,
        } as AutocompleteDataContext<KeyValuePair<string>>,
    },
];

const getArticleFilterModuleOptions = (
    t: TFunction<'translation', undefined>,
    yearRangeOptions: { value: number; label: string }[],
): FilterModuleConfigOption<unknown>[] => [
    {
        name: 'construction_start',
        label: t(
            'search_configuration.filter_edition.criteria_list_option.construction_start',
        ),
        type: FilterModuleType.Article,
        uiType: 'periodRangeCriteria',
        defaultOperation: FilterOperation.or,
        uiDataContext: {
            fromLabel: t('date_range_picker.from'),
            fromOptions: yearRangeOptions,
            toLabel: t('date_range_picker.to'),
            toOptions: yearRangeOptions,
        } as PeriodRangeDataContext,
    },
    {
        name: 'construction_end',
        label: t(
            'search_configuration.filter_edition.criteria_list_option.construction_end',
        ),
        type: FilterModuleType.Article,
        uiType: 'periodRangeCriteria',
        defaultOperation: FilterOperation.or,
        uiDataContext: {
            fromLabel: t('date_range_picker.from'),
            fromOptions: yearRangeOptions,
            toLabel: t('date_range_picker.to'),
            toOptions: yearRangeOptions,
        } as PeriodRangeDataContext,
    },
    {
        name: 'volume',
        label: t(
            'search_configuration.filter_edition.criteria_list_option.volume',
        ),
        type: FilterModuleType.Article,
        uiType: 'multiselectCriteria',
        defaultOperation: FilterOperation.or,
        allowedOperations: [
            { operation: FilterOperation.not, color: 'var(--red-50)' },
        ],
        uiDataContext: {
            options: [
                {
                    value: '0-200000',
                    label: '0 - 200,000',
                },
                {
                    value: '200000-1000000',
                    label: '200,000 - 1M',
                },
                {
                    value: '1000000-5000000',
                    label: '1M - 5M',
                },
                {
                    value: '5000000-10000000',
                    label: '5M - 10M',
                },
                {
                    value: '10000000-20000000',
                    label: '10M - 20M',
                },
                {
                    value: '20000000-50000000',
                    label: '20M - 50M',
                },
                {
                    value: '50000000-100000000',
                    label: '50M - 100M',
                },
                {
                    value: '100000000-',
                    label: '> 100M',
                },
            ],
        } as MultiselectDataContext,
    },
    {
        name: 'construction_type',
        label: t(
            'search_configuration.filter_edition.criteria_list_option.construction_type',
        ),
        type: FilterModuleType.Article,
        uiType: 'multiselectCriteria',
        defaultOperation: FilterOperation.or,
        allowedOperations: [
            { operation: FilterOperation.not, color: 'var(--red-50)' },
        ],
        uiDataContext: {
            options: [
                {
                    value: 'new_building',
                    label: t(
                        'search_configuration.filter_edition.criteria_list_option.construction_type.new_building',
                    ),
                },
                {
                    value: 'renovation',
                    label: t(
                        'search_configuration.filter_edition.criteria_list_option.construction_type.renovation',
                    ),
                },
            ],
        } as MultiselectDataContext,
    },
    {
        name: 'article_phases',
        label: t(
            'search_configuration.filter_edition.criteria_list_option.article_phase',
        ),
        type: FilterModuleType.Article,
        uiType: 'multiselectCriteria',
        defaultOperation: FilterOperation.or,
        allowedOperations: [
            { operation: FilterOperation.not, color: 'var(--red-50)' },
        ],
        uiDataContext: {
            options: [
                {
                    value: 'planned',
                    label: t(
                        'search_configuration.filter_edition.criteria_list_option.article_phase.planned',
                    ),
                    children: [
                        {
                            value: 'pre_planning',
                            label: t(
                                'search_configuration.filter_edition.criteria_list_option.article_phase.planned.pre_planning',
                            ),
                        },
                        {
                            value: 'implementation_planning',
                            label: t(
                                'search_configuration.filter_edition.criteria_list_option.article_phase.planned.implementation_planning',
                            ),
                        },
                    ],
                },
                {
                    value: 'under_construction',
                    label: t(
                        'search_configuration.filter_edition.criteria_list_option.article_phase.under_construction',
                    ),
                    children: [
                        {
                            value: 'structural_work',
                            label: t(
                                'search_configuration.filter_edition.criteria_list_option.article_phase.under_construction.structural_work',
                            ),
                        },
                        {
                            value: 'interior_work',
                            label: t(
                                'search_configuration.filter_edition.criteria_list_option.article_phase.under_construction.interior_work',
                            ),
                        },
                        {
                            value: 'finishing_work',
                            label: t(
                                'search_configuration.filter_edition.criteria_list_option.article_phase.under_construction.finishing_work',
                            ),
                        },
                    ],
                },
            ],
        } as MultiselectDataContext,
        extraFields: {
            invalid: false,
        },
    },
    {
        name: 'precision_mode',
        label: t(
            'search_configuration.filter_edition.criteria_list_option.precision_mode',
        ),
        type: FilterModuleType.Article,
        uiType: 'switchCriteria',
        defaultOperation: FilterOperation.or,
        uiDataContext: {
            enabledValue: 'must_exist',
        } as SwitchDataContext,
        isDisabledCallback: (
            searchFilterCriteriaContext: SearchFilterCriteria,
        ) => {
            return !searchFilterCriteriaContext['language']
                ?.map((c) => c.key)
                .includes('DE');
        },
    },
    {
        name: 'detail_mode',
        label: t(
            'search_configuration.filter_edition.criteria_list_option.detail_mode',
        ),
        type: FilterModuleType.Article,
        uiType: 'switchCriteria',
        defaultOperation: FilterOperation.or,
        uiDataContext: {
            enabledValue: 'must_exist',
        } as SwitchDataContext,
    },
];

const getTenderFilterModuleOptions = (
    rootTenderCodesFetcher: () => Promise<TreeNode[]>,
    childrenTenderCodesFetcher: (parentCode: string) => Promise<TreeNode[]>,
    filteredTenderCodesFetcher: (term: string) => Promise<TreeNode[]>,
    t: TFunction<'translation', undefined>,
    yearRangeOptions: { value: number; label: string }[],
    deadlineRangeOptions: { value: number; label: string }[],
): FilterModuleConfigOption<unknown>[] => [
    {
        name: 'contract_start',
        label: t(
            'search_configuration.filter_edition.criteria_list_option.contract_start',
        ),
        type: FilterModuleType.Tender,
        uiType: 'periodRangeCriteria',
        defaultOperation: FilterOperation.or,
        uiDataContext: {
            fromLabel: t('date_range_picker.from'),
            fromOptions: yearRangeOptions,
            toLabel: t('date_range_picker.to'),
            toOptions: yearRangeOptions,
        } as PeriodRangeDataContext,
    },
    {
        name: 'contract_end',
        label: t(
            'search_configuration.filter_edition.criteria_list_option.contract_end',
        ),
        type: FilterModuleType.Tender,
        uiType: 'periodRangeCriteria',
        defaultOperation: FilterOperation.or,
        uiDataContext: {
            fromLabel: t('date_range_picker.from'),
            fromOptions: yearRangeOptions,
            toLabel: t('date_range_picker.to'),
            toOptions: yearRangeOptions,
        } as PeriodRangeDataContext,
    },
    {
        name: 'deadline',
        label: t(
            'search_configuration.filter_edition.criteria_list_option.deadline',
        ),
        type: FilterModuleType.Tender,
        uiType: 'periodRangeCriteria',
        defaultOperation: FilterOperation.or,
        uiDataContext: {
            fromLabel: t('date_range_picker.from'),
            fromOptions: deadlineRangeOptions,
            toLabel: t('date_range_picker.to'),
            toOptions: deadlineRangeOptions,
        } as PeriodRangeDataContext,
    },
    {
        name: 'volume',
        label: t(
            'search_configuration.filter_edition.criteria_list_option.contract_value',
        ),
        type: FilterModuleType.Tender,
        uiType: 'multiselectCriteria',
        defaultOperation: FilterOperation.or,
        allowedOperations: [
            { operation: FilterOperation.not, color: 'var(--red-50)' },
        ],
        uiDataContext: {
            options: [
                {
                    value: '0-200000',
                    label: '0 - 200,000',
                },
                {
                    value: '200000-1000000',
                    label: '200,000 - 1M',
                },
                {
                    value: '1000000-5000000',
                    label: '1M - 5M',
                },
                {
                    value: '5000000-',
                    label: '> 5M',
                },
            ],
        } as MultiselectDataContext,
    },
    {
        name: 'tender_phases',
        label: t(
            'search_configuration.filter_edition.criteria_list_option.tender_phases',
        ),
        type: FilterModuleType.Tender,
        uiType: 'multiselectCriteria',
        defaultOperation: FilterOperation.or,
        allowedOperations: [
            { operation: FilterOperation.not, color: 'var(--red-50)' },
        ],
        uiDataContext: {
            options: [
                {
                    value: 'pre_tender_information',
                    label: t(
                        'search_configuration.filter_edition.criteria_list_option.tender_phases.pre_tender_information',
                    ),
                },
                {
                    value: 'open_tender',
                    label: t(
                        'search_configuration.filter_edition.criteria_list_option.tender_phases.open_tender',
                    ),
                },
                {
                    value: 'passed_tender',
                    label: t(
                        'search_configuration.filter_edition.criteria_list_option.tender_phases.passed_tender',
                    ),
                },
                {
                    value: 'awarded_tender',
                    label: t(
                        'search_configuration.filter_edition.criteria_list_option.tender_phases.awarded_tender',
                    ),
                },
            ],
        } as MultiselectDataContext,
    },
    {
        name: 'tender_codes',
        label: t(
            'search_configuration.filter_edition.criteria_list_option.tender_codes',
        ),
        type: FilterModuleType.Tender,
        uiType: 'treeViewCriteria',
        defaultOperation: FilterOperation.or,
        allowedOperations: [
            { operation: FilterOperation.not, color: 'var(--red-50)' },
        ],
        uiDataContext: {
            rootRequestCallback: rootTenderCodesFetcher,
            childrenRequestCallback: childrenTenderCodesFetcher,
            filteredChildrenRequestCallback: filteredTenderCodesFetcher,
        } as TreeViewDataContext,
    },
];

import { BrButton } from '@buildingradar/br_component_lib';
import styled from '@emotion/styled';
import { Box } from '@mui/material';

import { Spacing } from 'src/resources/styles/spacing';

export const DomainInputField = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    marginBottom: Spacing.s1,
    gap: Spacing.s0_5,
    '& .google-search-icon': {
        cursor: 'pointer',
        flex: 1,
    },
    '& .custom-domain-input': {
        flex: 5,
        '& input': {
            height: '14px',
        },
    },
    '& .search-domain-icon': {
        cursor: 'pointer',
        marginTop: Spacing.s1,
    },
    '& .select-btn': {
        display: 'none',
        flex: 2,
        marginTop: Spacing.s1,
    },
});

export const SearchIconButtonStyled = styled(BrButton)({
    maxWidth: '32px',
    minWidth: '0',
    height: '32px',
    margin: '0',
    padding: '0 !important',
    '& .MuiSvgIcon-root': {
        fontSize: '32px !important',
        color: 'var(--gray-50)',
    },
});

export const FormWrapperStyled = styled(Box)({
    '& .deal-contact-form-container': { paddingTop: '0 !important' },
    marginBottom: '24px',
});

import { observer } from 'mobx-react';
import { FC, useEffect } from 'react';

import {
    useAuthFeature,
    useNotificationsFeature,
} from 'src/app-context/use-features';
import { useAnchorTag } from 'src/presentation/modules/router/utils/use-route-data.hook';

import { OtherSectionComponent } from './other-section.component';

const NOTIFICATIONS_HASH_URL = 'notifications';

export const OtherSectionContainer: FC = observer(() => {
    const { user } = useAuthFeature();
    const { isOpened, overdueReminders, toggleNotificationsOpened } =
        useNotificationsFeature();

    const showNotificationsMarker = overdueReminders.length > 0;
    const shouldOpenNotifications = useAnchorTag(NOTIFICATIONS_HASH_URL);

    useEffect(() => {
        if (shouldOpenNotifications) {
            toggleNotificationsOpened(true);
        }
    }, [shouldOpenNotifications, toggleNotificationsOpened]);

    if (!user) {
        return null;
    }

    return (
        <OtherSectionComponent
            showNotificationsMarker={showNotificationsMarker}
            areNotificationsOpened={isOpened}
            toggleNotifications={toggleNotificationsOpened}
        />
    );
});

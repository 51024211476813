import { withFeatures } from 'src/utils/component.utils';

import { SearchRefinementCard } from './search-refinement-card.component';

export const SearchRefinementCardContainer = withFeatures(SearchRefinementCard)(
    (f) => ({
        iterateState: f.contactEnrichmentFeature.iterateState,
        onSelectDomainOption:
            f.contactEnrichmentFeature.onSelectDomainEnrichmentResult,
        isLoading: f.contactEnrichmentFeature.isDomainEnrichmentLoading,
        results: f.contactEnrichmentFeature.domainEnrichmentResults,
        contact: f.contactEnrichmentFeature.selectedContact,
        initialDomain: f.contactEnrichmentFeature.selectedCompanyDomain,
        currentCompany: f.contactEnrichmentFeature.selectedCompany,
        selectedDomainOption:
            f.contactEnrichmentFeature.domainEnrichmentResultSelection,
    }),
);

import {
    BrButton,
    BrButtonColor,
    BrSelect,
    BrText,
    IconSize,
    TextSize,
} from '@buildingradar/br_component_lib';
import { Global } from '@emotion/react';
import { observer } from 'mobx-react';
import { FC, useCallback, useMemo } from 'react';

import { User } from 'src/domain/models/user/user.model';
import { impersonationStyles } from 'src/presentation/modules/impersonation/impersonation-global-styles';
import BrBrandLogo from 'src/presentation/shared/br-brand-logo/br-brand-logo.component';
import { Localized } from 'src/presentation/shared/localized/localized';

import {
    ImpersonationHeaderStyled,
    ImpersonationInputContainer,
    ImpersonationSwitchContainer,
} from './styles';
import { getImpersonationOptions } from './utils';

interface ImpersonationHeaderComponentProps {
    user: User | undefined;
    isLoading: boolean;
    impersonateUser: (email: string) => void;
    endImpersonation: () => void;
}

export const ImpersonationHeaderComponent: FC<ImpersonationHeaderComponentProps> =
    observer(({ user, isLoading, impersonateUser, endImpersonation }) => {
        const { isImpersonated, colleagues, email } = user ?? {};

        const userOptions = useMemo(() => {
            return isImpersonated && colleagues
                ? getImpersonationOptions(colleagues)
                : [];
        }, [colleagues, isImpersonated]);

        const onSelectionChange = useCallback(
            (emailValue?: string) =>
                emailValue &&
                emailValue !== email &&
                impersonateUser(emailValue),
            [impersonateUser, email],
        );

        if (!user || !isImpersonated) {
            return null;
        }

        return (
            <ImpersonationHeaderStyled>
                <Global styles={impersonationStyles} />
                <BrBrandLogo size={IconSize.large} />
                <ImpersonationInputContainer>
                    <BrText size={TextSize.h4}>
                        <Localized placeholderMap={{ account: user.company }}>
                            impersonation.access_text
                        </Localized>
                    </BrText>
                    <ImpersonationSwitchContainer>
                        <BrSelect
                            options={userOptions}
                            selectedValue={user.email}
                            onSelectionChange={onSelectionChange}
                            margin={'none'}
                            fullWidth
                            enableSearch
                            disabled={isLoading}
                        />
                    </ImpersonationSwitchContainer>
                    <BrButton
                        color={BrButtonColor.danger}
                        onClick={endImpersonation}
                    >
                        <Localized>impersonation.end_impersonation</Localized>
                    </BrButton>
                </ImpersonationInputContainer>
            </ImpersonationHeaderStyled>
        );
    });

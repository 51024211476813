import { UNAUTHORIZED_MESSAGE } from 'src/data/stores/errors/errors.store';
import { getCookie } from 'src/utils/cookie.utils';
import { buildUrl } from 'src/utils/url.utils';

/**
 * string[][] is required in following example
 * [
 *  [ 'param1', value1 ],
 *  [ 'param1', value2 ],
 *  [ 'param1', value3 ],
 * ]
 * Record wouldn't allow you to have several values for the same key
 * { param1: value1 }
 */
export type QueryParams = Record<string, string> | string[][];

interface ApiRequest {
    url: string;
    query?: QueryParams;
    headers?: object;
    body?: object;
    signal: AbortSignal;
    isBodyJson?: boolean;
    isUrlParamsData?: boolean;
}

export interface AbortParams {
    signal: AbortSignal;
}

type FullApiRequest = ApiRequest & {
    method: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH' | 'HEAD' | 'OPTIONS';
};

/**
 * API client which allow us to make and cancel requests
 */
export interface ApiClient {
    request: <R>(request: FullApiRequest) => Promise<R>;
    get: <R>(request: ApiRequest) => Promise<R>;
    post: <R>(request: ApiRequest) => Promise<R>;
    delete: <R>(request: ApiRequest) => Promise<R>;
    put: <R>(request: ApiRequest) => Promise<R>;
}

const isErrorResponse = ({ status }: { status: number }): boolean => {
    return status >= 400;
};

export const createApiClient = (
    baseHref: string,
    extraHeaders?: object,
): ApiClient => {
    const request = async <R = never>(request: FullApiRequest): Promise<R> => {
        const url = `${baseHref}${buildUrl(request.url, request.query)}`;
        const xsrfToken = getCookie('XSRF-TOKEN');
        let body;
        if (!request.isUrlParamsData) {
            if (!request.isBodyJson) {
                body = request.body
                    ? Array.isArray(request.body)
                        ? new URLSearchParams(request.body)
                        : new URLSearchParams(Object.entries(request.body))
                    : undefined;
            } else {
                body = JSON.stringify(request.body);
            }
        } else {
            body = request.body as URLSearchParams;
        }

        const init: RequestInit = {
            method: request.method,
            headers: {
                'Content-Type': 'application/json',
                ...extraHeaders,
                ...request.headers,
                'X-XSRF-TOKEN': xsrfToken,
            },
            signal: request.signal,
            body,
        };

        return fetch(url, init).then((response) => {
            if (response.status === 401) {
                throw new Error(UNAUTHORIZED_MESSAGE);
            }

            // probably to this on every not handled requests, non 4xx;
            if (isErrorResponse(response)) {
                throw new Error(response.statusText);
            }
            return response.json();
        });
    };

    const get = <R = never>(apiRequest: ApiRequest): Promise<R> =>
        request({
            ...apiRequest,
            method: 'GET',
        });

    const post = <R = never>(apiRequest: ApiRequest): Promise<R> =>
        request({
            ...apiRequest,
            headers: apiRequest.headers ?? {
                'Content-Type':
                    'application/x-www-form-urlencoded;charset=UTF-8',
            },
            method: 'POST',
        });

    const deleteRequest = <R = never>(apiRequest: ApiRequest): Promise<R> =>
        request({
            ...apiRequest,
            method: 'DELETE',
        });

    const put = <R = never>(apiRequest: ApiRequest): Promise<R> =>
        request({
            ...apiRequest,
            headers: apiRequest.headers ?? {
                'Content-Type':
                    'application/x-www-form-urlencoded;charset=UTF-8',
            },
            method: 'PUT',
        });

    return {
        request,
        get,
        post,
        put,
        delete: deleteRequest,
    };
};

import { Colleague } from 'src/domain/models/colleague/colleague.model';
import { BR_EMAIL_DOMAIN } from 'src/domain/models/user/user.model';

export const getImpersonationOptions = (colleagues: Colleague[]) => {
    return (
        colleagues
            .filter(({ active }) => active)
            .sort((colleagueA, colleagueB) => {
                const firstEmail = colleagueA.email;
                const secondEmail = colleagueB.email;
                const isFirstEmailBr = firstEmail.includes(BR_EMAIL_DOMAIN);
                const isSecondEmailBr = secondEmail.includes(BR_EMAIL_DOMAIN);
                if (isFirstEmailBr && !isSecondEmailBr) {
                    return -1;
                } else if (!isFirstEmailBr && isSecondEmailBr) {
                    return 1;
                }
                return firstEmail.localeCompare(secondEmail);
            })
            .map(({ email }) => ({
                value: email,
                label: email,
            })) ?? []
    );
};

import { observer } from 'mobx-react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ActionContentComponent } from 'src/presentation/modules/enablement-actions-panel/actions/action-content.component';

import { GoogleEnrichmentActionsContainer } from './actions/google-enrichment-actions.container';
import { GoogleEnrichmentContentContainer } from './content/google-enrichment-content.container';

interface GoogleEnrichmentPanelComponentProps {
    onClose: () => void;
}

export const GoogleEnrichmentPanelComponent: FC<GoogleEnrichmentPanelComponentProps> =
    observer(({ onClose }) => {
        const { t } = useTranslation();
        return (
            <ActionContentComponent
                title={t('actions.google_enrichment.side_panel_title')}
                body={<GoogleEnrichmentContentContainer />}
                actions={<GoogleEnrichmentActionsContainer />}
                onClose={onClose}
            />
        );
    });

import { withFeatures } from 'src/utils/component.utils';

import { DealContactFormComponent } from './deal-contact-form.component';

export const DealContactFormContainer = withFeatures(DealContactFormComponent)(
    (f) => ({
        createContactCompany:
            f.dealContactsManageFeature.createDealContactCompany,
        fieldProgress: f.dealContactsManageFeature.fieldProgress,
    }),
);

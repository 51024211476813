import { makeAutoObservable } from 'mobx';

import { Comment } from 'src/domain/models/comment/comment.model';
import { sortCommentByDate } from 'src/domain/models/comment/comment.utils';

export interface CommentsStore {
    commentsMap: Map<string, Comment>;
    orderedCommentIds: string[];
    preFilledCommentText: string;
    setPreFilledCommentText: (text: string) => void;
    setComments: (comments: Comment[]) => void;
    setComment: (comment: Comment) => void;
    deleteComment: (commentId: string) => void;
    isLoading: boolean;
    setLoading: (flag: boolean) => void;
    isModifyCommentLoading: boolean;
    setModifyCommentLoading: (flag: boolean) => void;
    clear: () => void;
}

export class CommentsStoreImpl implements CommentsStore {
    commentsMap: Map<string, Comment> = new Map<string, Comment>();
    orderedCommentIds: string[] = [];
    isLoading = false;
    isModifyCommentLoading = false;
    error: Error | undefined = undefined;
    preFilledCommentText = '';

    setPreFilledCommentText = (text: string) => {
        this.preFilledCommentText = text;
    };
    setComments = (comments: Comment[]) => {
        this.clear();
        comments.forEach((comment) => {
            this.commentsMap.set(comment.id, comment);
        });
        this.orderedCommentIds = this.reorderedCommentIds();
    };

    setComment = (comment: Comment) => {
        this.commentsMap.set(comment.id, comment);
        this.orderedCommentIds = this.reorderedCommentIds();
    };

    deleteComment = (commentId: string) => {
        this.commentsMap.delete(commentId);
    };

    reorderedCommentIds = (): string[] => {
        return Array.from(this.commentsMap)
            .map(([, value]) => value)
            .sort(sortCommentByDate)
            .map((comment) => comment.id);
    };

    setLoading = (flag: boolean) => {
        this.isLoading = flag;
    };

    setModifyCommentLoading = (flag: boolean) => {
        this.isModifyCommentLoading = flag;
    };

    setError = (error?: Error) => {
        this.error = error;
    };

    clear = () => {
        this.commentsMap.clear();
        this.orderedCommentIds = [];
    };

    constructor() {
        makeAutoObservable(this);
    }
}

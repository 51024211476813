import { BrIcon, BrInfotip, IconName } from '@buildingradar/br_component_lib';
import { useMediaQuery } from '@mui/material';
import { observer } from 'mobx-react';
import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { NavItemComponent } from 'src/presentation/modules/primary-nav-bar/components/nav-item/nav-item.component';
import { AppRoute } from 'src/presentation/modules/router/app-route.list';
import { useCurrentRoute } from 'src/presentation/modules/router/utils/use-route-data.hook';
import { MediaQuery } from 'src/resources/styles/media.mixins';

import {
    NewNotificationsMarker,
    ArrowStyled,
    HelpIconStyled,
    StyledOtherItem,
    StyledOtherSection,
    NotificationIconStyled,
    NotificationActiveIconStyled,
    StyledOtherItemButton,
} from './other-section.styled';
import { OtherRoutes, readableOtherRoutes } from './other-section.util';

interface OtherSectionProps {
    areNotificationsOpened: boolean;
    showNotificationsMarker: boolean;
    toggleNotifications: (flag: boolean) => void;
}

export const OtherSectionComponent: FC<OtherSectionProps> = observer(
    ({
        areNotificationsOpened,
        showNotificationsMarker,
        toggleNotifications,
    }) => {
        const { t } = useTranslation();

        const toggleNotificationsOpened = useCallback(
            () => toggleNotifications(!areNotificationsOpened),
            [areNotificationsOpened, toggleNotifications],
        );

        const isTablet = useMediaQuery(MediaQuery.tablet);

        const currentRoute = useCurrentRoute();
        const isGeneralSearchActive = currentRoute === AppRoute.generalSearch;
        const isSettingsActive =
            currentRoute?.startsWith(AppRoute.settings) ?? false;

        return (
            <StyledOtherSection>
                <NavItemComponent
                    className="project-lookup"
                    name={t(readableOtherRoutes[OtherRoutes.searches])}
                    icon={
                        <BrIcon
                            iconName={IconName.BrSearchProjectsIcon}
                            iconSize={32}
                            iconColor={
                                isGeneralSearchActive
                                    ? 'var(--accent-link)'
                                    : 'var(--main-font)'
                            }
                        />
                    }
                    route={AppRoute.generalSearch}
                    isActive={isGeneralSearchActive}
                    isShadowVisible={areNotificationsOpened}
                />
                <BrInfotip
                    placement="right"
                    content={t('navbar.notifications')}
                    showArrow
                >
                    <StyledOtherItemButton
                        className="notifications"
                        onClick={toggleNotificationsOpened}
                    >
                        {showNotificationsMarker && <NewNotificationsMarker />}
                        <ArrowStyled
                            hasAnimation={!isTablet}
                            isVisible={areNotificationsOpened}
                        />
                        {areNotificationsOpened ? (
                            <NotificationActiveIconStyled />
                        ) : (
                            <NotificationIconStyled />
                        )}
                    </StyledOtherItemButton>
                </BrInfotip>
                <BrInfotip
                    placement="right"
                    content={t('navbar.resource_center')}
                    showArrow
                >
                    <span className="resource-center">
                        <StyledOtherItem>
                            <HelpIconStyled />
                        </StyledOtherItem>
                    </span>
                </BrInfotip>
                <NavItemComponent
                    className="settings"
                    name={t(readableOtherRoutes[OtherRoutes.settings])}
                    icon={
                        <BrIcon
                            iconName={
                                isSettingsActive
                                    ? IconName.BrSettingsFilledIcon
                                    : IconName.BrSettingsIcon
                            }
                            iconSize={32}
                            iconColor={
                                isSettingsActive
                                    ? 'var(--accent-link)'
                                    : 'var(--main-font)'
                            }
                        />
                    }
                    route={AppRoute.settings}
                    isActive={isSettingsActive}
                    isShadowVisible={areNotificationsOpened}
                />
            </StyledOtherSection>
        );
    },
);

import { BrIcon, IconName, IconSize } from '@buildingradar/br_component_lib';
import { Box } from '@mui/material';
import React from 'react';

import { Spacing } from 'src/resources/styles/spacing';

interface Props {
    size?: number | IconSize;
}

const BrBrandLogo: React.FC<Props> = ({ size }) => {
    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                '& .logo-icon': {
                    marginRight: Spacing.s1_5,
                },
                '& .logo-text-icon': {
                    marginTop: Spacing.s1_5,
                },
            }}
        >
            <BrIcon
                className="logo-icon"
                iconName={IconName.BrLogoIcon}
                iconColor="#F6173B"
                iconSize={size}
            />
            <BrIcon
                className="logo-text-icon"
                iconName={IconName.BrLogoTextIcon}
                iconSize={size}
            />
        </Box>
    );
};

export default BrBrandLogo;

export const GoldIcon = () => {
    return (
        <svg
            width="72"
            height="72"
            viewBox="0 0 72 72"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_806_2500)">
                <path
                    d="M12.7899 40.6164C10.3159 42.0448 9.72388 45.6512 11.8665 49.3622C13.6446 52.442 17.3392 53.6686 19.579 52.3755L22.5421 50.6647C22.7612 48.8965 22.3077 46.7565 21.149 44.7495C19.0065 41.0385 15.264 39.188 12.7899 40.6164Z"
                    fill="url(#paint0_linear_806_2500)"
                />
                <path
                    d="M17.9626 49.5756C16.7256 50.2898 14.9267 49.4899 13.9447 47.789C12.9627 46.0881 13.1694 44.1303 14.4064 43.4161L18.0453 41.3151C19.2114 42.1129 20.3045 43.2866 21.1491 44.7494C21.6025 45.5348 21.9479 46.3405 22.1873 47.1364L17.9626 49.5756Z"
                    fill="url(#paint1_linear_806_2500)"
                />
                <path
                    d="M17.9626 49.5757C18.8025 49.0907 18.6388 47.5139 17.746 45.9677L20.2658 44.5128L21.8823 47.3126L17.9626 49.5757Z"
                    fill="url(#paint2_linear_806_2500)"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M20.2504 44.5216C20.3672 44.7004 20.4802 44.8842 20.5891 45.0728C21.0425 45.8581 21.3879 46.6638 21.6273 47.4597L19.8985 48.4578C19.6896 47.6409 19.3503 46.8066 18.9092 46.0427C18.7978 45.8497 18.6823 45.6673 18.5634 45.4956L20.2504 44.5216Z"
                    fill="url(#paint3_linear_806_2500)"
                />
                <path
                    d="M19.579 52.3752C21.2589 51.4054 20.8976 48.1932 19.4692 45.7192C18.0408 43.2451 15.9526 42.5232 14.4064 43.4159L18.0453 41.315C19.2114 42.1128 20.3045 43.2865 21.149 44.7493C22.3077 46.7562 22.7612 48.8963 22.5421 50.6645L19.579 52.3752Z"
                    fill="url(#paint4_linear_806_2500)"
                />
                <path
                    d="M42.3005 40.0039C40.1261 40.326 37.3125 39.541 35.527 36.4485C33.7416 33.3559 33.8793 29.9337 35.8347 28.8047L29.856 32.2565C27.9006 33.3855 27.7629 36.8077 29.5484 39.9002C31.3339 42.9928 34.3665 44.5846 36.3219 43.4556L42.3005 40.0039Z"
                    fill="#C8AE00"
                />
                <path
                    d="M21.8638 51.7893C22.6149 49.809 22.6446 47.3401 21.149 44.7497C19.1614 41.307 15.7968 39.4655 13.3454 40.3576L12.7899 40.6166C12.9688 40.5133 13.1543 40.4272 13.3454 40.3576L32.9383 31.2238L39.7277 27.3039L51.1469 18.4712C53.6209 17.0428 57.3634 18.8933 59.506 22.6043C61.6486 26.3154 61.3799 30.4817 58.9058 31.9101L45.2237 36.8231L38.4342 40.743L22.66 52.4841C22.3563 52.7102 21.7295 52.1433 21.8638 51.7893Z"
                    fill="url(#paint5_linear_806_2500)"
                />
                <path
                    d="M21.5835 51.2185L22.5422 50.665C22.7613 48.8969 22.3078 46.7568 21.1491 44.7499C20.3046 43.287 19.2115 42.1133 18.0454 41.3156L17.4858 41.6386C18.6518 42.4364 19.7448 43.6101 20.5893 45.0728C21.8674 47.2865 22.0316 49.4114 21.5835 51.2185Z"
                    fill="url(#paint6_linear_806_2500)"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M43.0333 38.0874L38.4343 40.7426L35.9528 42.5896C36.6045 40.938 36.6446 38.8167 35.3396 36.5563C33.9243 34.105 31.7224 32.5983 29.8932 32.6429L32.9383 31.2234L36.9563 28.9036C38.6651 29.1272 40.5877 30.5712 41.8673 32.7876C42.9249 34.6193 43.306 36.5678 43.0333 38.0874Z"
                    fill="#C8AE00"
                />
                <path
                    d="M36.1411 43.5598C37.5128 41.8346 38.2452 39.0023 36.4597 35.9098C34.6742 32.8172 31.6368 31.2283 29.6753 32.3607L36.203 28.592C38.1644 27.4595 41.2019 29.0485 42.9874 32.141C44.7729 35.2336 44.6302 38.6586 42.6688 39.7911L36.1411 43.5598Z"
                    fill="url(#paint7_linear_806_2500)"
                />
                <path
                    d="M36.8277 35.6973L42.4273 32.4644L49.4713 44.6651C49.7594 45.1641 49.3424 45.7748 48.7728 45.6881L46.6368 45.3628C46.319 45.3144 46.0102 45.4927 45.8932 45.792L45.107 47.8045C44.8973 48.3412 44.1599 48.397 43.8718 47.898L36.8277 35.6973Z"
                    fill="url(#paint8_linear_806_2500)"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M39.4932 40.3141L36.8277 35.6974L42.4273 32.4645L45.0929 37.0815C44.6809 38.1389 43.9197 39.0686 42.8605 39.6802C41.801 40.2919 40.6151 40.4862 39.4932 40.3141Z"
                    fill="#C8AE00"
                />
                <ellipse
                    cx="39.3911"
                    cy="34.9641"
                    rx="5.17265"
                    ry="5.17265"
                    transform="rotate(-30 39.3911 34.9641)"
                    fill="#C8AE00"
                />
                <ellipse
                    cx="39.6274"
                    cy="34.0808"
                    rx="5.17265"
                    ry="5.17265"
                    transform="rotate(-30 39.6274 34.0808)"
                    fill="url(#paint9_linear_806_2500)"
                />
            </g>
            <defs>
                <linearGradient
                    id="paint0_linear_806_2500"
                    x1="20.5373"
                    y1="51.4489"
                    x2="14.1392"
                    y2="40.3671"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FFECBD" />
                    <stop offset="1" stopColor="#FBC94C" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_806_2500"
                    x1="15.6879"
                    y1="43.0497"
                    x2="18.7592"
                    y2="48.3692"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FFECBD" />
                    <stop offset="1" stopColor="#FBC94C" />
                </linearGradient>
                <linearGradient
                    id="paint2_linear_806_2500"
                    x1="18.6425"
                    y1="46.9226"
                    x2="19.4048"
                    y2="47.3014"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="#F8F1C3" />
                </linearGradient>
                <linearGradient
                    id="paint3_linear_806_2500"
                    x1="20.0451"
                    y1="47.2147"
                    x2="21.0136"
                    y2="45.898"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FFF29A" />
                    <stop offset="1" stopColor="#F5D709" />
                </linearGradient>
                <linearGradient
                    id="paint4_linear_806_2500"
                    x1="17.8719"
                    y1="45.6778"
                    x2="19.5754"
                    y2="47.0349"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="#F8F1C3" />
                </linearGradient>
                <linearGradient
                    id="paint5_linear_806_2500"
                    x1="51.9042"
                    y1="18.6392"
                    x2="59.401"
                    y2="31.6242"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="#FFECBD" />
                </linearGradient>
                <linearGradient
                    id="paint6_linear_806_2500"
                    x1="22.7339"
                    y1="49.4345"
                    x2="17.3997"
                    y2="40.1953"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FFECBD" />
                    <stop offset="1" stopColor="#FCD87F" />
                </linearGradient>
                <linearGradient
                    id="paint7_linear_806_2500"
                    x1="45.0096"
                    y1="39.552"
                    x2="30.9978"
                    y2="34.7662"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FFF29A" />
                    <stop offset="1" stopColor="#F5D709" />
                </linearGradient>
                <linearGradient
                    id="paint8_linear_806_2500"
                    x1="47.4263"
                    y1="49.4171"
                    x2="35.4366"
                    y2="37.1065"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FFF29A" />
                    <stop offset="1" stopColor="#F5D709" />
                </linearGradient>
                <linearGradient
                    id="paint9_linear_806_2500"
                    x1="42.2895"
                    y1="28.011"
                    x2="41.0148"
                    y2="37.7791"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FFF29A" />
                    <stop offset="1" stopColor="#F5D709" />
                </linearGradient>
                <clipPath id="clip0_806_2500">
                    <rect width="72" height="72" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

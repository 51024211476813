import { css } from '@emotion/react';

export const mainStyles = css`
    * {
        font-family: Roboto, RobotoDraft, Helvetica, Arial, sans-serif;
        text-rendering: optimizeLegibility;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    html {
        scroll-behavior: smooth;
    }
`;

import { ICustomFieldDataContext } from './custom-field-data-context';

export interface IDealDataContext {
    /**
     * Also referred to as the deal ‘title’, which is populated by an article title
     */
    name: string;
    /**
     * The email address of the assigned deal owner
     */
    ownerEmail?: string;
    /**
     * The name of the assigned deal owner
     */
    ownerName?: string;
    /**
     * Deal notes
     */
    notes?: string;
    /**
     * Numerical value of the deal value (user can include currency symbol in the template itself)
     */
    value: string;
    /**
     * The currency used for the deal
     */
    currency: string;
    /**
     * Any deal custom fields
     */
    customFields: ICustomFieldDataContext[];
}

export interface Assignee {
    name: string;
    email: string;
}

export class DealDataContext {
    name: string;
    ownerEmail?: string;
    ownerName?: string;
    notes?: string;
    value: string;
    currency: string;
    customFields: ICustomFieldDataContext[];

    constructor(data: IDealDataContext) {
        this.name = data.name;
        this.ownerEmail = data.ownerEmail;
        this.ownerName = data.ownerName;
        this.notes = data.notes;
        this.value = data.value;
        this.currency = data.currency;
        this.customFields = data.customFields;
    }
}

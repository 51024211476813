import { makeAutoObservable } from 'mobx';

import { DealsApi } from 'src/data/api/deal/deal.api';
import { DealsStore } from 'src/data/stores/deals/deals.store';
import { IBaseStore } from 'src/data/stores/shared/base.store.interface';
import { doNothing } from 'src/utils/function.utils';
import { Cancellable, handleRequest } from 'src/utils/handle-request.utils';

export interface DashboardFeature {
    requestDealsCount: () => Cancellable;
    dealsCount: number;
    needToRequestDealsList: boolean;
}

export class DashboardFeatureImpl implements DashboardFeature {
    constructor(
        private dealsApi: DealsApi,
        private dealsStore: DealsStore,
        private baseStore: IBaseStore,
    ) {
        makeAutoObservable(this);
    }

    get dealsCount() {
        return this.dealsStore.dealsCount;
    }

    get needToRequestDealsList() {
        return this.dealsStore.needToRequestDealsList;
    }

    requestDealsCount = () => {
        return handleRequest(
            this.dealsApi.getDealsCount,
            {},
            this.dealsStore.setDealsCount,
            doNothing,
            (error) =>
                this.baseStore.onRequestFailed('request-deals-count', error),
        );
    };
}

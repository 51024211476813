import { makeAutoObservable } from 'mobx';

import { MinimalDealsCollection } from 'src/data/api/deal/deal.api';
import {
    FilterOpQl,
    SortingOpQl,
    PaginationConfQl,
} from 'src/data/api/graphql/br_process/generated/graphql-sdk';
import {
    EmptyFilterValue,
    filterFields,
    FilterFn,
    filterOperators,
    sortFields,
    SortFn,
    sortOrder,
    SortOrderType,
} from 'src/domain/features/deals-list/deals-list.feature.util';
import { Deal, MinimalDeal } from 'src/domain/models/deal/deal.model';
import {
    DealExport,
    OutboundEmail,
} from 'src/domain/models/deal-export/deal-export.model';
import { isNonNullable } from 'src/utils/is-non-nullable.utils';

export interface DealsStore {
    isMinimalDealsListLoading: boolean;
    dealsIds: string[];
    dealsMap: Map<string, Deal>;
    minimalDealsIds: string[];
    csvExportData: Map<string, DealExport>;
    emailExportData: Map<string, OutboundEmail>;
    minimalDealsMap: Map<string, MinimalDeal>;
    minimalDealsList: MinimalDeal[];
    groupedDealList: Record<string, MinimalDeal[]>;
    needToRequestDealsList: boolean;
    dealsCount: number;
    filterOptions: FilterOpQl[];
    sortOptions: SortingOpQl[];
    paginationOptions: PaginationConfQl;
    dealsTablePaginationParams: DealsTablePaginationParameters;
    needToSyncDealAlert: boolean;
    dealSwitchEvents: (() => void)[];
    registerDealSwitchEvent: (event: () => void) => void;
    setDealsCount: (count: number) => void;
    setNeedToRequestDealsList: (flag: boolean) => void;
    setMinimalDeals: ({
        minimalDeals,
        totalCount,
        hasNextPage,
        elementsOnPageCount,
    }: MinimalDealsCollection) => void;
    setLoadingDeals: (loading: boolean) => void;
    resetMinimalDeals: () => void;
    setFilterOption: FilterFn;
    setSortOption: SortFn;
    setPaginationOption: (pageSize: number, offset?: number) => void;
    clearOptions: () => void;
    getDeal: (dealId: string) => Deal | undefined;
    setNeedToSyncDealAlert: (shouldSync: boolean) => void;
}

export interface DealsTablePaginationParameters {
    totalDealsCount: number;
    hasNextPageDeals: boolean;
    dealsTableOffset: number;
    elementsOnPageCount: number;
}

export class DealsStoreImpl implements DealsStore {
    isMinimalDealsListLoading = false;
    dealsIds: string[] = [];
    csvExportData = new Map();
    emailExportData = new Map();
    dealsMap: Map<string, Deal> = new Map();
    minimalDealsIds: string[] = [];
    minimalDealsMap: Map<string, MinimalDeal> = new Map();
    needToRequestDealsList = false;
    dealsCount = 0;
    filterOptions: FilterOpQl[] = [];
    sortOptions: SortingOpQl[] = [];
    paginationOptions: PaginationConfQl = {};
    totalDealsCount = 0;
    hasNextPageDeals = false;
    elementsOnPageCount = 0;
    dealsTableOffset = 0;
    needToSyncDealAlert = false;
    dealSwitchEvents: (() => void)[] = [];

    get minimalDealsList(): MinimalDeal[] {
        return this.minimalDealsIds
            .map((id) => this.minimalDealsMap.get(id))
            .filter(isNonNullable);
    }

    get groupedDealList(): Record<string, MinimalDeal[]> {
        return this.minimalDealsList.reduce<Record<string, MinimalDeal[]>>(
            (result, current) => {
                const dealsByStage = result[current.stageId] ?? [];
                dealsByStage.push(current);
                result[current.stageId] = dealsByStage;
                return result;
            },
            {},
        );
    }

    get dealsTablePaginationParams(): DealsTablePaginationParameters {
        return {
            totalDealsCount: this.totalDealsCount,
            hasNextPageDeals: this.hasNextPageDeals,
            dealsTableOffset: this.dealsTableOffset,
            elementsOnPageCount: this.elementsOnPageCount,
        };
    }

    setNeedToSyncDealAlert = (shouldSync: boolean) => {
        this.needToSyncDealAlert = shouldSync;
    };

    setDealsCount = (count: number) => {
        this.dealsCount = count;
    };

    resetMinimalDeals = () => {
        this.minimalDealsIds = [];
    };

    setMinimalDeals = ({
        minimalDeals: deals,
        totalCount,
        hasNextPage,
        elementsOnPageCount,
    }: MinimalDealsCollection) => {
        this.resetMinimalDeals();
        this.totalDealsCount = totalCount;
        this.hasNextPageDeals = hasNextPage;
        this.elementsOnPageCount = elementsOnPageCount;

        deals.forEach((deal) => {
            this.minimalDealsIds.push(deal.id);
            this.minimalDealsMap.set(deal.id, deal);
            const dealMapObj = this.dealsMap.get(deal.id);
            if (dealMapObj) {
                const dealObj = {
                    ...dealMapObj,
                    ...deal,
                    state: deal.state ?? dealMapObj.state,
                };
                this.dealsMap.set(dealObj.id, dealObj);
            }
        });

        this.setNeedToRequestDealsList(false);
    };

    setLoadingDeals = (loading: boolean) => {
        this.isMinimalDealsListLoading = loading;
    };

    setNeedToRequestDealsList = (flag: boolean) => {
        this.needToRequestDealsList = flag;
    };

    addFilter = (filter: FilterOpQl) => {
        const index = this.filterOptions.findIndex(
            (option) =>
                option.field === filter.field && option.fop === filter.fop,
        );
        if (index < 0) {
            this.filterOptions.push(filter);
        } else {
            this.filterOptions[index] = filter;
        }
    };

    removeFilter = (filter: FilterOpQl) => {
        const index = this.filterOptions.findIndex(
            (option) =>
                option.field === filter.field && option.fop === filter.fop,
        );
        if (index >= 0) {
            this.filterOptions.splice(index, 1);
        }
    };

    setFilterOption = (
        field: string,
        value: string | string[],
        filterOperator?: string,
    ) => {
        const filterField = filterFields[field];
        const fop =
            filterOperators[
                filterOperator ?? (Array.isArray(value) ? 'contains' : 'equal')
            ];
        if (value.length > 0) {
            if (Array.isArray(value)) {
                this.addFilter({ field: filterField, fop, values: value });
            } else {
                if (filterOperator === 'regex') {
                    value = `(?i)${value}`;
                }

                if (value === EmptyFilterValue) {
                    value = '';
                }

                this.addFilter({ field: filterField, fop, value });
            }
        } else {
            this.removeFilter({ field: filterField, fop });
        }
    };

    setSortOption = (field: string, order: SortOrderType) => {
        const fieldName = sortFields[field];
        const sortValue = sortOrder[order ?? 'asc'];
        const sorting: SortingOpQl = { field: fieldName, sop: sortValue };
        if (this.sortOptions.length > 0) {
            this.sortOptions[0] = sorting;
        } else {
            this.sortOptions.push(sorting);
        }
    };

    setPaginationOption = (pageSize: number, offset?: number) => {
        this.dealsTableOffset = offset ?? 0;
        this.paginationOptions = { first: pageSize, offset };
    };

    clearOptions = () => {
        this.filterOptions.length = 0;
        this.sortOptions.length = 0;
    };

    getDeal = (dealId: string) => {
        return this.dealsMap.get(dealId);
    };

    registerDealSwitchEvent = (event: () => void) => {
        this.dealSwitchEvents.push(event);
    };

    constructor() {
        makeAutoObservable(this);
    }
}

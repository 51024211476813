import { observer } from 'mobx-react';
import { FC } from 'react';

import { BadgeColor, BadgeColorMap } from './utils';

interface HexagonalBadgeComponentProps {
    color?: BadgeColor;
    size?: string;
}

export const HexagonalBadgeComponent: FC<HexagonalBadgeComponentProps> =
    observer(({ color = BadgeColor.Gray, size = '72' }) => {
        const colors = BadgeColorMap[color];

        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={size}
                height={size}
                viewBox="0 0 72 72"
                fill="none"
            >
                <path
                    d="M33.0937 5.29847C34.8892 4.24916 37.1108 4.24917 38.9063 5.29847L60.8663 18.1322C62.6336 19.1651 63.72 21.0583 63.72 23.1053V48.8947C63.72 50.9417 62.6336 52.8349 60.8663 53.8677L38.9063 66.7015C37.1108 67.7508 34.8892 67.7508 33.0937 66.7015L11.1337 53.8677C9.36638 52.8349 8.28 50.9417 8.28 48.8947V23.1053C8.28 21.0583 9.36638 19.1651 11.1337 18.1322L33.0937 5.29847Z"
                    fill={`url(#paint0_linear_hexagon_${color})`}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M38.9063 5.29845C37.1108 4.24915 34.8892 4.24915 33.0937 5.29845L11.1337 18.1322C9.36638 19.1651 8.28 21.0583 8.28 23.1052V48.8947C8.28 50.9417 9.36638 52.8349 11.1337 53.8677L33.0937 66.7015C34.8892 67.7508 37.1108 67.7508 38.9063 66.7015L60.8663 53.8677C62.6336 52.8349 63.72 50.9417 63.72 48.8947V23.1052C63.72 21.0583 62.6336 19.1651 60.8663 18.1322L38.9063 5.29845ZM37.4585 10.2165C36.5581 9.68771 35.4419 9.68771 34.5415 10.2165L14.7415 21.8451C13.8609 22.3623 13.32 23.3072 13.32 24.3285V47.6714C13.32 48.6927 13.8609 49.6376 14.7415 50.1548L34.5415 61.7834C35.4419 62.3122 36.5581 62.3122 37.4585 61.7834L57.2585 50.1548C58.1391 49.6376 58.68 48.6927 58.68 47.6714V24.3285C58.68 23.3072 58.1391 22.3623 57.2585 21.8451L37.4585 10.2165Z"
                    fill={`url(#paint2_linear_hexagon_${color})`}
                />
                <path
                    d="M39.96 7.56006L41.7836 8.64006M44.595 10.3051L53.28 15.4486"
                    stroke="white"
                    strokeWidth="1.44"
                    strokeLinecap="round"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    opacity="0.85"
                    d="M38.194 8.85347C36.8402 8.05535 35.1598 8.05535 33.806 8.85347L14.006 20.5266C12.6886 21.3033 11.88 22.7187 11.88 24.248V47.752C11.88 49.2814 12.6886 50.6967 14.006 51.4734L33.806 63.1466C35.1598 63.9447 36.8402 63.9447 38.194 63.1466L57.994 51.4734C59.3114 50.6967 60.12 49.2814 60.12 47.752V24.248C60.12 22.7187 59.3114 21.3033 57.994 20.5266L38.194 8.85347ZM37.4585 10.2166C36.5581 9.68777 35.4419 9.68777 34.5415 10.2166L14.7415 21.8452C13.8609 22.3624 13.32 23.3073 13.32 24.3285V47.6715C13.32 48.6928 13.8609 49.6377 14.7415 50.1549L34.5415 61.7834C35.4419 62.3123 36.5581 62.3123 37.4585 61.7834L57.2585 50.1549C58.1391 49.6377 58.68 48.6928 58.68 47.6715V24.3285C58.68 23.3073 58.1391 22.3624 57.2585 21.8452L37.4585 10.2166Z"
                    fill={`url(#paint1_linear_hexagon_${color})`}
                />
                <g style={{ opacity: color === BadgeColor.Gray ? 0 : 1 }}>
                    <path
                        d="M45.8328 48.4401L46.5474 49.7183L47.8256 50.4329L46.5474 51.1474L45.8328 52.4256L45.1182 51.1474L43.84 50.4329L45.1182 49.7183L45.8328 48.4401Z"
                        fill="white"
                    />
                    <path
                        d="M30.9056 13.52L31.5602 14.691L32.7312 15.3456L31.5602 16.0002L30.9056 17.1712L30.251 16.0002L29.08 15.3456L30.251 14.691L30.9056 13.52Z"
                        fill="white"
                    />
                    <circle cx="23.68" cy="46.2801" r="1.08" fill="white" />
                    <circle cx="33.94" cy="18.38" r="0.9" fill="white" />
                    <circle cx="54.64" cy="25.7601" r="1.08" fill="white" />
                    <path
                        d="M41.6672 23.96L42.3081 25.1063L43.4544 25.7472L42.3081 26.388L41.6672 27.5344L41.0264 26.388L39.88 25.7472L41.0264 25.1063L41.6672 23.96Z"
                        fill="white"
                    />
                </g>
                <mask
                    id="mask0_806_2542"
                    style={{ maskType: 'alpha' }}
                    maskUnits="userSpaceOnUse"
                    x="8"
                    y="4"
                    width="56"
                    height="64"
                >
                    <path
                        d="M33.0937 5.29847C34.8892 4.24916 37.1109 4.24917 38.9064 5.29847L60.8664 18.1322C62.6337 19.1651 63.72 21.0583 63.72 23.1053V48.8947C63.72 50.9417 62.6337 52.8349 60.8664 53.8677L38.9064 66.7015C37.1109 67.7508 34.8892 67.7508 33.0937 66.7015L11.1337 53.8677C9.36642 52.8349 8.28004 50.9417 8.28004 48.8947V23.1053C8.28004 21.0583 9.36643 19.1651 11.1337 18.1322L33.0937 5.29847Z"
                        fill={`url(#paint3_linear_hexagon_${color})`}
                    />
                </mask>
                <g mask="url(#mask0_806_2542)">
                    <rect
                        x="57.6"
                        y="5.03992"
                        width="23.0404"
                        height="68.8711"
                        transform="rotate(60 57.6 5.03992)"
                        fill="white"
                        fillOpacity="0.15"
                    />
                    <rect
                        x="63.6202"
                        y="13.9156"
                        width="6.12"
                        height="67.8454"
                        transform="rotate(60 63.6202 13.9156)"
                        fill="white"
                        fillOpacity="0.15"
                    />
                    <rect
                        x="70.0157"
                        y="30.96"
                        width="6.12"
                        height="62.5567"
                        transform="rotate(60 70.0157 30.96)"
                        fill="white"
                        fillOpacity="0.15"
                    />
                </g>
                <defs>
                    <linearGradient
                        id={`paint0_linear_hexagon_${color}`}
                        x1="38.16"
                        y1="22.86"
                        x2="21.176"
                        y2="62.551"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor={colors.stopColor1} />
                        <stop offset="1" stopColor={colors.stopOffsetColor1} />
                    </linearGradient>
                    <linearGradient
                        id={`paint1_linear_hexagon_${color}`}
                        x1="36"
                        y1="4.67996"
                        x2="38.3935"
                        y2="63.6228"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor={colors.stopColor2} />
                        <stop offset="1" stopColor={colors.stopOffsetColor2} />
                    </linearGradient>
                    <linearGradient
                        id={`paint2_linear_hexagon_${color}`}
                        x1="49.4472"
                        y1="13.4945"
                        x2="23.9134"
                        y2="59.2646"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor={colors.stopColor3} />
                        <stop offset="1" stopColor={colors.stopOffsetColor3} />
                    </linearGradient>
                    <linearGradient
                        id={`paint3_linear_hexagon_${color}`}
                        x1="36"
                        y1="18"
                        x2="36"
                        y2="69.48"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor={colors.stopColor4} />
                        <stop offset="1" stopColor={colors.stopOffsetColor4} />
                    </linearGradient>
                </defs>
            </svg>
        );
    });

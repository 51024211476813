import { makeAutoObservable } from 'mobx';

import { UserSettingName } from 'src/data/api/graphql/br_user/generated/graphql-sdk';
import { PipelineViewPreferencesParams } from 'src/data/api/pipeline/pipeline.api';
import {
    getPipelineViewDataTypeBasedOnVisualizationMode,
    PerformanceViewVisualizationLayout,
    RefetchPipelineViewDataSource,
    RefetchPipelineViewDataState,
    ViewPreferenceValueType,
} from 'src/domain/models/performance-view-preferences/performance-view-preferences.model';
import { SettingsValueType } from 'src/domain/models/settings/settings.model';

import { IPipelinePerformanceViewPreferencesStore } from './pipeline-performance-view-preferences.store.interface';

export class PipelinePerformanceViewPreferences
    implements IPipelinePerformanceViewPreferencesStore
{
    preferences: ViewPreferenceValueType[] = [
        { setting: UserSettingName.PipelineViewPreferencesShowPausedDeals },
        { setting: UserSettingName.PipelineViewPreferencesAssignees },
        { setting: UserSettingName.PipelineViewPreferencesCustomParameterTag },
        {
            setting: UserSettingName.PipelineViewPreferencesVisualizationMode,
        },
        { setting: UserSettingName.PipelineViewPreferencesPaginationRows },
        {
            setting: UserSettingName.PipelineViewPreferencesSorting,
        },
        {
            setting: UserSettingName.PipelineViewPreferencesStageIds,
        },
    ];

    refetchPipelineViewDataState: RefetchPipelineViewDataState = {
        active: false,
        source: undefined,
        refetchType: undefined,
    };

    constructor() {
        makeAutoObservable(this);
    }

    get visualizationModePreference(): PerformanceViewVisualizationLayout {
        const value = this.getPreferenceBySettingName(
            UserSettingName.PipelineViewPreferencesVisualizationMode,
        );

        return PerformanceViewVisualizationLayout[
            value as keyof typeof PerformanceViewVisualizationLayout
        ];
    }

    setPreference(name: UserSettingName, value: SettingsValueType) {
        const preference = this.preferences.find((p) => p.setting === name);
        if (preference) {
            preference.value = value;

            this.setRefetchPipelineViewDataState({
                active: true,
                refetchType: getPipelineViewDataTypeBasedOnVisualizationMode(
                    this.visualizationModePreference,
                ),
                source: RefetchPipelineViewDataSource.viewPreferences,
            });
        }
    }

    setRefetchPipelineViewDataState(state: RefetchPipelineViewDataState) {
        this.refetchPipelineViewDataState = state;
    }

    getPreferenceBySettingName(
        settingName: string,
    ): SettingsValueType | undefined {
        return this.preferences.find((p) => p.setting === settingName)?.value;
    }

    getCurrentPipelineViewPreferencesParams(customParameterTagName?: string) {
        const viewMode = this.visualizationModePreference;

        const cdptSetting = this.getPreferenceBySettingName(
            UserSettingName.PipelineViewPreferencesCustomParameterTag,
        ) as Record<string, string[]>;

        const viewPreferences: PipelineViewPreferencesParams = {
            usersIds: this.getPreferenceBySettingName(
                UserSettingName.PipelineViewPreferencesAssignees,
            ) as number[],
            showPausedDeals: this.getPreferenceBySettingName(
                UserSettingName.PipelineViewPreferencesShowPausedDeals,
            ) as boolean,
            customParameterTagOptions:
                cdptSetting && customParameterTagName
                    ? cdptSetting[customParameterTagName]
                    : [],
            stageIds:
                viewMode === PerformanceViewVisualizationLayout.table
                    ? (this.getPreferenceBySettingName(
                          UserSettingName.PipelineViewPreferencesStageIds,
                      ) as string[])
                    : [],
        };

        return viewPreferences;
    }
}

import { makeAutoObservable } from 'mobx';

import { AchievementService } from 'src/app-features/achievements/domain/service/achievements.service';
import { XRayApi } from 'src/data/api/actions/xray/xray.api';
import { UserAchievementName } from 'src/data/api/graphql/br_process/generated/graphql-sdk';
import { MixpanelEventName } from 'src/data/services/mixpanel/mixpanel.model';
import { MixpanelService } from 'src/data/services/mixpanel/mixpanel.service';
import { IAccountConfigurationStore } from 'src/data/stores/account-configuration/account-configuration.store.interface';
import { IQuickActionsStoreInterface } from 'src/data/stores/actions/quick-actions.store.interface';
import { DealsStore } from 'src/data/stores/deals/deals.store';
import { LeadsStore } from 'src/data/stores/leads/leads.store';
import { PipelineStore } from 'src/data/stores/pipeline/pipeline.store';
import { IBaseStore } from 'src/data/stores/shared/base.store.interface';
import {
    ActionFeatureLaunchSource,
    ActionFeaturesIdentifiers,
} from 'src/domain/features/account-configuration/helpers/action-feature.helper';
import { Lead } from 'src/domain/models/lead/lead.model';
import i18n from 'src/resources/translations/i18next';
import {
    abortOngoingRequests,
    handleRequest,
    isAbortError,
} from 'src/utils/handle-request.utils';

import { ARTICLE_WIZARD_REQUEST_KEY, RECENT_ARTICLES_KEY } from './utils';
import { IXRayFeature } from './xray-interface.feature';

export class XRayFeature implements IXRayFeature {
    xRayActionAnswer: string[] | string | null = null;
    isXRayAnswerLoading = false;
    customQuestion = '';
    selectedDocumentId: string | undefined = undefined;
    isArticleWizardError = false;

    constructor(
        private quickActionsStore: IQuickActionsStoreInterface,
        private dealsStore: DealsStore,
        private pipelineStore: PipelineStore,
        private mixpanelService: MixpanelService,
        private leadsStore: LeadsStore,
        private xRayApi: XRayApi,
        private baseStore: IBaseStore,
        private accountConfigurationStore: IAccountConfigurationStore,
        private achievementService: AchievementService,
    ) {
        makeAutoObservable(this);
    }

    get selectedLead(): Lead | undefined {
        const leadId = this.quickActionsStore.selectedEntityId;
        return leadId ? this.leadsStore.leads.get(leadId) : undefined;
    }

    get predefinedQuestions() {
        return (
            (this.accountConfigurationStore.actionFeaturesConfigurations?.find(
                (actionFeature) =>
                    actionFeature.name === ActionFeaturesIdentifiers.xray,
            )?.settings.questions ?? []) as string[]
        ).filter((q) => !!q);
    }

    setSelectedDocumentId = (documentId: string | undefined) => {
        this.selectedDocumentId = documentId;
    };

    setCustomQuestion = (question: string) => {
        this.customQuestion = question;
    };

    setXRayActionAnswer = (answer: string[] | string | null) => {
        this.xRayActionAnswer = answer;
    };

    cleanXRayActionAnswer = () => {
        this.xRayActionAnswer = null;
        this.quickActionsStore.clean();
    };

    setLoadingAnswer = (isLoading: boolean) => {
        this.isXRayAnswerLoading = isLoading;
    };

    isValidAnswer = (answer: string | null): answer is string =>
        !!answer && answer.length > 1;

    onSuccessAnswerNew = (
        question: string,
        answer: string | null,
        projectId: string,
        documentId?: string,
    ) => {
        this.setXRayActionAnswer(
            this.isValidAnswer(answer) ? answer.split('\n') : '',
        );
        this.mixpanelService.trackEvent(MixpanelEventName.XRayRequestAnswer, {
            question,
            answer: this.isValidAnswer(answer) ? answer : 'No results',
            projectId,
            documentId: documentId ?? null,
        });

        if (!this.predefinedQuestions.includes(question.trim())) {
            this.achievementService.call(UserAchievementName.AskArticleWizard);
        }
    };

    askQuestionNew = (
        projectId: string,
        question: string,
        documentId?: string,
    ) => {
        this.setXRayActionAnswer(null);

        const onNextValue = (value: string) => {
            if (this.isValidAnswer(value)) {
                this.setXRayActionAnswer(value.split('\n'));
            }
        };

        handleRequest(
            this.xRayApi.getAnswerHybrid,
            {
                projectId,
                question,
                documentId,
                onNextValue,
            },
            (answer) =>
                this.onSuccessAnswerNew(
                    question,
                    answer,
                    projectId,
                    documentId,
                ),
            this.setLoadingAnswer,
            (error) => {
                this.baseStore.onRequestFailed('x-ray-ask-question', error);
                if (error && !isAbortError(error)) {
                    this.isArticleWizardError = true;
                }
            },
            ARTICLE_WIZARD_REQUEST_KEY,
        );
    };

    trackXRayFeedback = (
        wasHelpful: boolean,
        question: string,
        answer: string,
        dealId?: string,
        pipelineId?: string,
        currentStageId?: string,
        leadId?: string,
        documentId?: string,
    ) => {
        this.mixpanelService.trackEvent(
            MixpanelEventName.XRayResponseFeedback,
            {
                wasHelpful,
                question,
                answer,
                dealId: dealId ?? null,
                pipelineId: pipelineId ?? null,
                currentStageId: currentStageId ?? null,
                leadId: leadId ?? null,
                documentId: documentId ?? null,
            },
        );
    };

    abortOngoingRequests = () => {
        abortOngoingRequests(ARTICLE_WIZARD_REQUEST_KEY);
    };

    setArticleWizardEntity = (
        leadId: string,
        documentId?: string,
        launchSource?: ActionFeatureLaunchSource,
        dealId?: string,
    ) => {
        this.quickActionsStore.setQuickAction({
            launchSource,
            dealId,
            entityId: leadId,
            quickAction: ActionFeaturesIdentifiers.xray,
        });
        this.mixpanelService.trackEvent(
            MixpanelEventName.LaunchedQuickAction,
            {
                quickActionName: ActionFeaturesIdentifiers.xray.toString(),
                launchSource: launchSource ?? null,
                projectId: leadId,
                documentId: documentId ?? null,
            },
            dealId,
            this.dealsStore.getDeal,
            this.pipelineStore.getPipeline,
        );
        this.setSelectedDocumentId(documentId ?? RECENT_ARTICLES_KEY);
        this.setCustomQuestion('');
        this.abortOngoingRequests();
        this.setLoadingAnswer(false);
        this.setXRayActionAnswer(null);
        this.isArticleWizardError = false;
    };

    summarizeLead = (
        leadId: string,
        documentId?: string,
        launchSource?: ActionFeatureLaunchSource,
    ) => {
        this.setArticleWizardEntity(leadId, documentId, launchSource);
        this.setCustomQuestion(i18n.t('actions.xray_helper.summarize_prompt'));
        this.askQuestionNew(leadId, this.customQuestion, documentId);
    };

    runFirstQuestionForLead = (
        leadId: string,
        launchSource?: ActionFeatureLaunchSource,
    ) => {
        this.setArticleWizardEntity(leadId, undefined, launchSource);

        if (!this.predefinedQuestions.length) {
            return;
        }

        const firstQuestion = this.predefinedQuestions[0];
        this.askQuestionNew(leadId, firstQuestion);
    };
}

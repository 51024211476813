import styled from '@emotion/styled';

import { MediaQuery } from 'src/resources/styles/media.mixins';

export const EmptyNotificationsStyled = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 320px;
    margin: 0 auto;
`;

export const EmptyNotificationsMain = styled.div`
    padding: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    ${MediaQuery.fullWidthMenu} {
        padding: 24px;
    }
`;

export const EmptyNotificationsZero = styled.div`
    font-size: 60px;
    font-weight: 300;
    line-height: 70px;
    letter-spacing: 0;
    text-align: center;
    color: var(--text-disabled);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 128px;
    height: 128px;
    border-radius: 50%;
    background: white;
    margin-bottom: 24px;
`;

export const EmptyNotificationsTitle = styled.h3`
    color: black;
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0.15px;
    margin-bottom: 8px;
`;

export const EmptyNotificationsDescription = styled.div`
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.44px;
    color: var(--gray-text-color);
`;

export const EmptyNotificationsTip = styled.div`
    padding: 8px 24px;
    border: 1px solid var(--gray-border-color);
    border-radius: 8px;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
    text-align: center;
    font-weight: 400;
    color: var(--gray-text-color);
`;

export const TipBold = styled.span`
    font-weight: 700;
`;

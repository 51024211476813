import { withFeatures } from 'src/utils/component.utils';

import { EnrichmentContactSelectComponent } from './enrichment-contact-select.component';

export const EnrichmentContactSelectContainer = withFeatures(
    EnrichmentContactSelectComponent,
)((f) => ({
    contact: f.contactEnrichmentFeature.selectedContact,
    contacts: f.dealContactsManageFeature.keyDealContactPersons,
    isEnrichmentLoading: f.contactEnrichmentFeature.isContactEnrichmentLoading,
    setTargetContact: f.contactEnrichmentFeature.setTargetContact,
    iterateState: f.contactEnrichmentFeature.iterateState,
    clean: f.contactEnrichmentFeature.clean,
}));

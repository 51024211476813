import {
    BrButtonColor,
    BrButtonVariant,
    BrText,
    TextWeight,
} from '@buildingradar/br_component_lib';
import { observer } from 'mobx-react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import {
    DealContactCompany,
    DealContactPerson,
} from 'src/app-features/contact/data/model/deal-contacts.model';
import { EnrichmentStep } from 'src/app-features/contact-enrichment/domain/contact-enrichment.model';
import { SectionGreyTitleStyled } from 'src/app-features/contact-enrichment/presentation/styles';
import { getFullName } from 'src/domain/models/user/user.model';
import { addProtocolIfNeeded, openLink } from 'src/utils/url.utils';

import {
    SearchImprovementButtonStyled,
    SearchParameterLineStyled,
    SearchParametersListStyled,
} from './styles';
import { EnrichmentIterateStateParams } from 'src/app-features/contact-enrichment/domain/contact-enrichment.feature';

interface EnrichmentSearchParametersComponentProps {
    selectedContact?: DealContactPerson;
    selectedCompany?: DealContactCompany;
    selectedDomain?: string;
    iterateState: (params?: EnrichmentIterateStateParams) => void;
}
export const EnrichmentSearchParametersComponent: FC<EnrichmentSearchParametersComponentProps> =
    observer(
        ({
            selectedCompany,
            selectedContact,
            selectedDomain,
            iterateState,
        }) => {
            if (!selectedContact || !selectedCompany) {
                return null;
            }
            const { t } = useTranslation();
            const contactName = getFullName(
                selectedContact.firstName,
                selectedContact.lastName,
            );
            const onLinkClicked = (
                e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
                link: string,
            ) => {
                openLink(link);
                e.preventDefault();
                e.stopPropagation();
            };

            return (
                <SearchParametersListStyled>
                    <SectionGreyTitleStyled>
                        {t(
                            'actions.contact_enrichment.search_refinement.label.search_parameters',
                        )}
                    </SectionGreyTitleStyled>
                    <SearchParameterLineStyled>
                        <BrText weight={TextWeight.semibold1}>
                            {t(
                                'actions.contact_enrichment.search_refinement.label.contact',
                            )}
                            :
                        </BrText>
                        <BrText noWrap>{contactName}</BrText>
                    </SearchParameterLineStyled>
                    <SearchParameterLineStyled>
                        <BrText weight={TextWeight.semibold1}>
                            {t(
                                'actions.contact_enrichment.search_refinement.label.company',
                            )}
                            :
                        </BrText>
                        <BrText noWrap>{selectedCompany.name}</BrText>
                    </SearchParameterLineStyled>
                    {selectedDomain && (
                        <SearchParameterLineStyled>
                            <BrText weight={TextWeight.semibold1}>
                                {t(
                                    'actions.contact_enrichment.search_refinement.label.company_domain',
                                )}
                                :
                            </BrText>
                            <Link
                                style={{ minWidth: 0 }}
                                onClick={(e) =>
                                    onLinkClicked(e, selectedDomain)
                                }
                                to={addProtocolIfNeeded(selectedDomain)}
                            >
                                <BrText color="var(--blue-60)" noWrap>
                                    {selectedDomain}
                                </BrText>
                            </Link>
                        </SearchParameterLineStyled>
                    )}
                    <SearchImprovementButtonStyled
                        color={BrButtonColor.primary}
                        variant={BrButtonVariant.text}
                        onClick={() =>
                            iterateState({ to: EnrichmentStep.refinement })
                        }
                    >
                        {t(
                            'actions.contact_enrichment.flyout.label.refine_search_parameters',
                        )}
                    </SearchImprovementButtonStyled>
                </SearchParametersListStyled>
            );
        },
    );

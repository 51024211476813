import * as Sentry from '@sentry/react';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { initReactI18next, TFunction } from 'react-i18next';

import { getLocalizationVersion, TranslationError } from './translation.utils';

const fallbackLng = 'en';

const projectToken = import.meta.env.VITE_LOCALIZATION_TOKEN;

const version = getLocalizationVersion();

const loadPath = `https://cdn.simplelocalize.io/${projectToken}/${version}/{{lng}}`;

const isLanguageLoaded = () =>
    i18n.hasResourceBundle(i18n.resolvedLanguage, 'translation');

i18n.use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng,
        backend: {
            loadPath,
        },
        parseMissingKeyHandler: (key) => {
            if (isLanguageLoaded() && key) {
                Sentry.captureException(
                    new TranslationError(
                        `Missing i18n translation key: ${key}`,
                    ),
                );
            }
            return key;
        },
        missingInterpolationHandler: (text, value) => {
            if (isLanguageLoaded()) {
                Sentry.captureException(
                    new TranslationError(
                        `i18n interpolation error: ${text} -> ${value}`,
                    ),
                );
            }
        },
    });

export type TranslationFn = TFunction<'translation', undefined>;

export default i18n;

import { ALL_AVATAR_OPTION_ID } from '@buildingradar/br_component_lib';
import isEqual from 'lodash/isEqual';
import { makeAutoObservable, reaction, runInAction } from 'mobx';
import { getI18n, TFunction } from 'react-i18next';

import { UserSettingName } from 'src/data/api/graphql/br_user/generated/graphql-sdk';
import { UsersApi } from 'src/data/api/user/user.api';
import { AccountConfigurationStore } from 'src/data/stores/account-configuration/account-configuration.store';
import { PipelineStore } from 'src/data/stores/pipeline/pipeline.store';
import { IPipelinePerformanceViewPreferencesStore } from 'src/data/stores/pipeline-data/view-preferences/pipeline-performance-view-preferences.store.interface';
import { IBaseStore } from 'src/data/stores/shared/base.store.interface';
import { UserStore } from 'src/data/stores/user/user.store';
import {
    PerformanceViewVisualizationLayout,
    ViewPreferenceValueType,
} from 'src/domain/models/performance-view-preferences/performance-view-preferences.model';
import {
    SettingsValueType,
    SortingSetting,
    userSettingsMap,
} from 'src/domain/models/settings/settings.model';
import { handleRequest } from 'src/utils/handle-request.utils';

import { IPipelinePerformanceViewPreferencesFeature } from './pipeline-performance-view-preferences-interface.feature';
import { PreferenceDefaultValue, buildDefaultPreferenceValues } from './utils';

export class PipelinePerformanceViewPreferencesFeature
    implements IPipelinePerformanceViewPreferencesFeature
{
    t: TFunction<'translation', undefined> = getI18n().t;

    savingPreferences = false;
    preferencesInitialised = false;

    constructor(
        private viewPreferencesStore: IPipelinePerformanceViewPreferencesStore,
        private userStore: UserStore,
        private userApi: UsersApi,
        private accountConfigurationStore: AccountConfigurationStore,
        private pipelineStore: PipelineStore,
        private baseStore: IBaseStore,
    ) {
        makeAutoObservable(this);

        /**
         * Reaction which is triggered whenever the current selected pipeline changes.
         * When this happens, we need to check if the selected stage ids are still valid. If at least one is valid,
         * we don't need to do anything, as the valid id will be used in the next deal fetch. If none is valid, we reset
         * the user preference for the stage ids to be an empty array, meaning all possible ids for the new pipeline will be selected.
         */
        reaction(
            () => this.pipelineStore.currentSelectedPipeline,
            (_current, previous) => {
                runInAction(() => {
                    const newStageIds = this.pipelineStore
                        .getPipelineStages()
                        .stages.map(({ id }) => id);

                    if (
                        (this.selectedStageIds &&
                            !this.selectedStageIds.length) ||
                        !previous
                    ) {
                        return;
                    }

                    const atLeastOneStateStillExists = newStageIds.some((id) =>
                        this.selectedStageIds.includes(id),
                    );

                    if (!atLeastOneStateStillExists) {
                        this.savePreference(
                            UserSettingName.PipelineViewPreferencesStageIds,
                            [],
                        );
                    }
                });
            },
        );
    }

    getDefaultValueByPreferenceName = (
        preferenceName: UserSettingName,
    ): SettingsValueType | undefined => {
        const defaultValues = buildDefaultPreferenceValues(
            this.accountConfigurationStore.customParameterTag,
        );

        const defaultValue = defaultValues.find(
            (preference) => preference.name === preferenceName,
        );
        return defaultValue?.value;
    };

    get assigneePreferences(): string[] {
        const assignees = this.viewPreferencesStore.getPreferenceBySettingName(
            UserSettingName.PipelineViewPreferencesAssignees,
        ) as number[];

        const assigneesCount = assignees.length;
        return assigneesCount
            ? assignees.map((id) => id.toString())
            : [ALL_AVATAR_OPTION_ID];
    }

    get showPausedDealsPreference() {
        return this.viewPreferencesStore.getPreferenceBySettingName(
            UserSettingName.PipelineViewPreferencesShowPausedDeals,
        ) as boolean;
    }

    get customParameterTagPreferences(): string[] {
        const customParameterTag =
            this.accountConfigurationStore.customParameterTag;
        if (customParameterTag) {
            return (
                this.viewPreferencesStore.getPreferenceBySettingName(
                    UserSettingName.PipelineViewPreferencesCustomParameterTag,
                ) as Record<string, string[]>
            )[customParameterTag.name];
        }
        return [];
    }

    get visualizationMode(): PerformanceViewVisualizationLayout {
        return this.viewPreferencesStore.visualizationModePreference;
    }

    get paginationRowsCount() {
        return this.viewPreferencesStore.getPreferenceBySettingName(
            UserSettingName.PipelineViewPreferencesPaginationRows,
        ) as number;
    }

    get sortingPreferences() {
        return this.viewPreferencesStore.getPreferenceBySettingName(
            UserSettingName.PipelineViewPreferencesSorting,
        ) as SortingSetting[];
    }

    get selectedStageIds() {
        const ids = this.viewPreferencesStore.getPreferenceBySettingName(
            UserSettingName.PipelineViewPreferencesStageIds,
        ) as string[];
        return ids;
    }

    get thereAreOverwrittenPreferences(): boolean {
        const overwritten = this.getOverwrittenPreferences();
        return overwritten.length > 0;
    }

    get additionalActivePreferencesCount(): number {
        const additionalPreferences =
            this.viewPreferencesStore.preferences.filter(
                ({ setting }) =>
                    setting ===
                        UserSettingName.PipelineViewPreferencesShowPausedDeals ||
                    setting ===
                        UserSettingName.PipelineViewPreferencesCustomParameterTag ||
                    (setting ===
                        UserSettingName.PipelineViewPreferencesStageIds &&
                        this.visualizationMode ===
                            PerformanceViewVisualizationLayout.table),
            );

        const preferencesChanged = additionalPreferences.filter(
            (preference) => {
                const defaultValue = this.getDefaultValueByPreferenceName(
                    preference.setting,
                );

                return this.hasPreferenceChanged(
                    preference.setting,
                    defaultValue,
                    preference.value,
                );
            },
        );

        return preferencesChanged.length;
    }

    get refetchPipelineViewDataState() {
        return this.viewPreferencesStore.refetchPipelineViewDataState;
    }

    initializeSavedPreferences = () => {
        const {
            pipelineViewPreferencesAssignees,
            pipelineViewPreferencesShowPausedDeals,
            pipelineViewPreferencesCustomParameterTag,
            pipelineViewPreferencesVisualizationMode,
            pipelineViewPreferencesPaginationRows,
            pipelineViewPreferencesSorting,
            pipelineViewPreferencesStageIds,
        } = this.userStore.user?.settings || {};

        this.viewPreferencesStore.setPreference(
            UserSettingName.PipelineViewPreferencesAssignees,
            pipelineViewPreferencesAssignees ??
                this.getDefaultValueByPreferenceName(
                    UserSettingName.PipelineViewPreferencesAssignees,
                )!,
        );

        this.viewPreferencesStore.setPreference(
            UserSettingName.PipelineViewPreferencesShowPausedDeals,
            pipelineViewPreferencesShowPausedDeals ??
                this.getDefaultValueByPreferenceName(
                    UserSettingName.PipelineViewPreferencesShowPausedDeals,
                )!,
        );

        if (this.accountConfigurationStore.customParameterTag) {
            this.viewPreferencesStore.setPreference(
                UserSettingName.PipelineViewPreferencesCustomParameterTag,
                pipelineViewPreferencesCustomParameterTag ??
                    this.getDefaultValueByPreferenceName(
                        UserSettingName.PipelineViewPreferencesCustomParameterTag,
                    )!,
            );
        }

        this.viewPreferencesStore.setPreference(
            UserSettingName.PipelineViewPreferencesVisualizationMode,
            pipelineViewPreferencesVisualizationMode ??
                this.getDefaultValueByPreferenceName(
                    UserSettingName.PipelineViewPreferencesVisualizationMode,
                )!,
        );

        this.viewPreferencesStore.setPreference(
            UserSettingName.PipelineViewPreferencesPaginationRows,
            pipelineViewPreferencesPaginationRows ??
                this.getDefaultValueByPreferenceName(
                    UserSettingName.PipelineViewPreferencesPaginationRows,
                )!,
        );

        this.viewPreferencesStore.setPreference(
            UserSettingName.PipelineViewPreferencesSorting,
            pipelineViewPreferencesSorting ??
                this.getDefaultValueByPreferenceName(
                    UserSettingName.PipelineViewPreferencesSorting,
                )!,
        );

        this.viewPreferencesStore.setPreference(
            UserSettingName.PipelineViewPreferencesStageIds,
            (pipelineViewPreferencesStageIds ?? []).length > 0
                ? pipelineViewPreferencesStageIds!
                : this.getDefaultValueByPreferenceName(
                      UserSettingName.PipelineViewPreferencesStageIds,
                  )!,
        );

        this.preferencesInitialised = true;
    };

    onPreferenceSaved = (
        setting: UserSettingName,
        value: SettingsValueType,
    ) => {
        this.viewPreferencesStore.setPreference(setting, value);
        this.updateUserSettings([{ setting, value }]);
    };

    onSavingPreference = (saving: boolean) => {
        this.savingPreferences = saving;
    };

    savePreference = (setting: UserSettingName, value: SettingsValueType) => {
        handleRequest(
            this.userApi.changeUserSetting,
            { setting, value },
            () => this.onPreferenceSaved(setting, value),
            this.onSavingPreference,
            (error) =>
                this.baseStore.onRequestFailed(
                    'save-pipeline-view-preference',
                    error,
                ),
        );
    };

    resetPreferences = async () => {
        try {
            runInAction(() => {
                this.savingPreferences = true;
            });

            const overwrittenPreferences = this.getOverwrittenPreferences();

            const defaultValues = buildDefaultPreferenceValues(
                this.accountConfigurationStore.customParameterTag,
            );

            const requests = overwrittenPreferences.map(({ setting }) =>
                this.userApi.changeUserSetting({
                    setting,
                    value: defaultValues.find(({ name }) => name === setting)!
                        .value,
                    signal: new AbortController().signal,
                }),
            );

            await Promise.all(requests);

            runInAction(() => {
                this.onResetPreferences(overwrittenPreferences, defaultValues);
                this.savingPreferences = false;
            });
        } catch (error) {
            this.baseStore.onRequestFailed(
                'reset-pipeline-view-preferences',
                error as Error,
            );
        }
    };

    private onResetPreferences = (
        overwrittenPreferences: ViewPreferenceValueType[],
        defaultValues: PreferenceDefaultValue[],
    ) => {
        const preferencesRestored = overwrittenPreferences.map(
            (preference) => ({
                ...preference,
                value: defaultValues.find(
                    ({ name }) => name === preference.setting,
                )!.value,
            }),
        );

        preferencesRestored.forEach(({ setting, value }) =>
            this.viewPreferencesStore.setPreference(setting, value),
        );
        this.updateUserSettings(preferencesRestored);
    };

    private updateUserSettings = (
        savedPreferences: ViewPreferenceValueType[],
    ) => {
        const currentUser = this.userStore.user;

        if (currentUser) {
            savedPreferences.forEach((preference) => {
                currentUser.settings = {
                    ...currentUser.settings,
                    [userSettingsMap[preference.setting]]: preference.value,
                };
            });
        }
    };

    private getOverwrittenPreferences = () => {
        const defaultValues = buildDefaultPreferenceValues(
            this.accountConfigurationStore.customParameterTag,
        );

        const overwritten = this.viewPreferencesStore.preferences.filter(
            (preference) => {
                const settingName = userSettingsMap[preference.setting];
                const savedPreference =
                    this.userStore.user?.settings[settingName];
                const defaultValue = defaultValues.find(
                    ({ name }) => name === preference.setting,
                )?.value;

                return this.hasPreferenceChanged(
                    preference.setting,
                    defaultValue,
                    savedPreference,
                );
            },
        );

        return overwritten;
    };

    /**
     * Helper function which compares preferences values.
     * @param settingName The setting name associated with the preference
     * @param baseValue The base value for comparison
     * @param value The value to be compared
     * @returns Returns true case the preference value has changed, false otherwise
     */
    private hasPreferenceChanged = (
        settingName: UserSettingName,
        baseValue?: SettingsValueType | null,
        value?: SettingsValueType | null,
    ) => {
        let first: any = baseValue;
        let second: any = value;

        if (
            baseValue === null ||
            baseValue === undefined ||
            value === null ||
            value === undefined
        ) {
            return false;
        }

        if (
            settingName ===
            UserSettingName.PipelineViewPreferencesCustomParameterTag
        ) {
            const customParameterTag =
                this.accountConfigurationStore.customParameterTag;
            first = (baseValue as Record<string, string[]>)?.[
                customParameterTag?.name ?? ''
            ]
                ?.slice()
                .sort();
            second = (value as Record<string, string[]>)?.[
                customParameterTag?.name ?? ''
            ]
                ?.slice()
                .sort();
        }

        return !isEqual(first, second);
    };
}

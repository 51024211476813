import { withFeatures } from 'src/utils/component.utils';

import { SearchRefinementFormComponent } from './search-refinement-form.component';

export const SearchRefinementFormContainer = withFeatures(
    SearchRefinementFormComponent,
)((f) => ({
    dealId: f.quickActionsFeature.selectedDealId,
    fieldProgress: f.dealContactsManageFeature.fieldProgress,
    selectedContact: f.contactEnrichmentFeature.selectedContact,
    selectedCompany: f.contactEnrichmentFeature.selectedCompany,
    getDomainEnrichment: f.contactEnrichmentFeature.getDomainEnrichmentData,
}));

import { getJSXConstructor } from 'src/app-features/achievements/presentation/achievements-section/component/achievement-badge/icons/utils';

import { ColouredIcon } from './coloured-icon';
import { GoldIcon } from './gold-icon';
import { GrayIcon } from './gray-icon';

export const BookedFirstMeeting = getJSXConstructor(
    ColouredIcon,
    GrayIcon,
    GoldIcon,
);

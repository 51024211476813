import { withFeatures } from 'src/utils/component.utils';

import { GoogleEnrichmentActionsComponent } from './google-enrichment-actions.component';

export const GoogleEnrichmentActionsContainer = withFeatures(
    GoogleEnrichmentActionsComponent,
)((f) => ({
    dealId: f.quickActionsFeature.selectedDealId,
    isGoogleEnrichmentLoading:
        f.googleEnrichmentFeature.isGoogleEnrichmentLoading,
    isPossibleCompanyMatchesLoading:
        f.googleEnrichmentFeature.isPossibleCompanyMatchesLoading,
    enrichmentData: f.googleEnrichmentFeature.enrichmentData,
    selectedData: f.googleEnrichmentFeature.selectedData,
    selectedCompany: f.googleEnrichmentFeature.selectedCompany,
    customFields: f.googleEnrichmentFeature.companyCustomFields,
    language: f.authFeature.user?.language,
    onClose: f.quickActionsFeature.clean,
    updateCompany: f.dealContactsManageFeature.updateDealContactCompany,
    showMessage: f.toasterFeature.showMessage,
}));

import styled from '@emotion/styled';

export const MobileMenuWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: var(--button-gray-bg);
    height: 100%;
    max-width: 100%;
    width: 448px;
`;

import { withFeatures } from 'src/utils/component.utils';

import { DataProviderCardComponent } from './data-provider-card.component';

export const DataProviderCardContainer = withFeatures(
    DataProviderCardComponent,
)((f) => ({
    selectedContact: f.contactEnrichmentFeature.selectedContact!,
    company: f.contactEnrichmentFeature.selectedCompany!,
    dealId: f.quickActionsFeature.selectedDealId,
    sendMixpanelEvent: f.dealContactsManageFeature.sendMixpanelEvent,
}));

import { useContext } from 'react';

import { AppFeatures, FeaturesContext } from './app-context';

export const useFeatures = () => useContext<AppFeatures>(FeaturesContext);
export const useAuthFeature = () => useFeatures().authFeature;
export const useNavbarFeature = () => useFeatures().navbarFeature;
export const useTranslationFeature = () => useFeatures().translationFeature;
export const useLeadsListFeature = () => useFeatures().leadsListFeature;
export const useExportFeature = () => useFeatures().exportFeature;
export const useLeadsActionsFeature = () => useFeatures().leadActionsFeature;
export const useStageObjectivesFeature = () =>
    useFeatures().stageObjectivesFeature;
export const useDealActionsFeature = () => useFeatures().dealActionsFeature;
export const useDealPauseFeature = () => useFeatures().dealPauseFeature;
export const useCommentsFeature = () => useFeatures().commentsFeature;
export const useNotificationsFeature = () => useFeatures().notificationsFeature;
export const useLeadFieldsFeature = () => useFeatures().leadFieldsFeature;
export const useProjectLookupFeature = () => useFeatures().projectLookupFeature;
export const usePipelineFeature = () => useFeatures().pipelineFeature;
export const useDealsListFeature = () => useFeatures().dealsListFeature;
export const useDealViewFeature = () => useFeatures().dealViewFeature;
export const useDealExportFeature = () => useFeatures().dealExportFeature;
export const useContactExtractionFeature = () =>
    useFeatures().contactExtractionFeature;
export const useDealMoveProcessFeature = () =>
    useFeatures().dealMoveProcessFeature;
export const useDealContactsManageFeature = () =>
    useFeatures().dealContactsManageFeature;
export const useRemindersFeature = () => useFeatures().remindersFeature;
export const useQuickActionFeature = () => useFeatures().quickActionsFeature;
export const useDealBatchOperationsFeature = () =>
    useFeatures().dealBatchOperationsFeature;

/**
 * @Idea
 * here is a new idea: to make all features lazy and returned asynchronously
 * Pros: the initial bundle will be lighter
 * Cons: it will require additional time for loading pages with unloaded features
 *
 * Can be done with lazy-imported features and save it to a hook.
 * Then it will require a check if feature is not undefined
 *
 * Can also be wrapped in a type with 'isLoaded' flag
 * @example
 *  const leadDetailsFeature = useLeadDetailsFeature()
 *  if (!leadDetailsFeature) {
 *      return null;
 *  }
 *
 *   leadDetailsFeature.someMethod()
 *   leadDetailsFeature.someValue
 */

import {
    SdkToSearchTypeMap,
    SearchType,
} from 'src/app-features/searches-configuration/domain/models/configured-search';
import { SearchSubscribedPartFragment } from 'src/data/api/graphql/br_search/generated/graphql-sdk';

export interface SearchFilterModule {
    key: number;
    filter: string;
    value: string;
}

export interface Search {
    id: number;
    name: string;
    type: SearchType;
    ownerId: number;
    searchData: {
        filterModules: SearchFilterModule[];
    };
}

export const createSearchFromSubscribedSearch = (
    rawSearch: SearchSubscribedPartFragment,
): Search => {
    return {
        id: rawSearch.itemId,
        name: rawSearch.name,
        type: SdkToSearchTypeMap[rawSearch.type],
        ownerId: rawSearch.userId,
        searchData: {
            filterModules: rawSearch.searchData.filterModules,
        },
    };
};

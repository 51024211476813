import {
    BrTextInput,
    BrTextInputStatus,
} from '@buildingradar/br_component_lib';
import { FC, useEffect, useRef } from 'react';

import { KeyNames } from 'src/utils/keyboard.utils';

interface TextInputProps {
    type?: 'text' | 'password' | 'date' | 'datetime-local' | 'tel' | 'email' | 'number';
    name?: string;
    placeholder?: string;
    value?: string;
    blurOnEnter?: boolean;
    disableLineBreaks?: boolean;
    maxLength?: number;
    maxRows?: number;
    minRows?: number;
    hasPermanentBorder?: boolean;
    className?: string;
    focusInput?: boolean;
    selectInput?: boolean;
    isHighlighted?: boolean;
    multiline?: boolean;
    helperText?:
        | string
        | ((status: BrTextInputStatus | undefined) => string | undefined);
    status?: BrTextInputStatus;
    onChange?: (value: string) => void;
    onPressEnter?: () => void;
    onPressShiftEnter?: () => void;
    onPressEsc?: () => void;
    onFocus?: () => void;
    onBlur?: () => void;
}

export const TextInput: FC<TextInputProps> = ({
    type,
    name,
    value,
    placeholder,
    blurOnEnter,
    disableLineBreaks = false,
    maxLength,
    maxRows,
    minRows,
    hasPermanentBorder = false,
    className,
    focusInput,
    isHighlighted = false,
    multiline = false,
    helperText,
    status,
    onChange,
    onPressEnter,
    onPressShiftEnter,
    onPressEsc,
    onFocus,
    onBlur,
}) => {
    const textFieldRef = useRef<HTMLTextAreaElement>(null);

    useEffect(() => {
        if (focusInput && textFieldRef.current) {
            textFieldRef.current.focus();
            let element: HTMLTextAreaElement | HTMLInputElement | null =
                textFieldRef.current.querySelector('textarea');
            if (!element) {
                element = textFieldRef.current.querySelector('input');
            }
            const endIndex = element?.value.length ?? 0;
            element?.setSelectionRange(endIndex, endIndex);
        }
    }, [focusInput]);

    const onKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === KeyNames.Enter) {
            if (e.shiftKey) {
                onPressShiftEnter?.();
            } else {
                onPressEnter?.();
            }
            if (!e.shiftKey && blurOnEnter && textFieldRef.current) {
                textFieldRef.current.blur();
            }
        }

        if (e.key === KeyNames.Esc && textFieldRef.current) {
            onPressEsc && onPressEsc();
            // setTimeout to be sure that handling blur event would be called after onPressEsc(setState into it) is finished.
            setTimeout(() => textFieldRef.current?.blur(), 0);
        }
    };

    const textAreaClassName = `text-area ${className ?? ''}`;

    return (
        <BrTextInput
            name={name}
            margin="none"
            asHiddenInput={!hasPermanentBorder}
            disableLineBreaks={disableLineBreaks}
            className={textAreaClassName}
            inputRef={textFieldRef}
            placeholder={placeholder}
            value={value ?? ''}
            multiline={multiline}
            helperText={helperText}
            status={status}
            fullWidth
            autoFocus={focusInput}
            maxRows={maxRows}
            minRows={minRows}
            onChange={onChange}
            onFocusOut={onBlur}
            onFocusIn={onFocus}
            onKeyDown={onKeyDown}
            maxLength={maxLength}
            isHighlighted={isHighlighted}
            type={type}
        />
    );
};

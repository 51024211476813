import { withFeatures } from 'src/utils/component.utils';

import { ContactDetailInfoPanelComponent } from './contact-detail-info-panel.component';

export const ContactDetailInfoPanelContainer = withFeatures(
    ContactDetailInfoPanelComponent,
)((f) => ({
    selectedContact: f.quickActionsFeature.selectedEntity,
    dealId: f.quickActionsFeature.selectedDealId,
    fieldProgress: f.dealContactsManageFeature.fieldProgress,
    findContactById: f.dealContactsManageFeature.findContactById,
    showMessage: f.toasterFeature.showMessage,
    onClose: f.quickActionsFeature.clean,
    clear: f.dealContactsManageFeature.clear,
    onLinkClicked: f.externalLinkFeature.setClickedLink,
}));

interface RawAssociatedSearch {
    item_id: number;
    name: string;
}

export interface AssociatedSearch {
    itemId: number;
    name: string;
}

export const createAssociatedSearch = (
    rawAssociatedSearches: RawAssociatedSearch,
): AssociatedSearch => {
    const { item_id, name } = rawAssociatedSearches;
    return {
        itemId: item_id,
        name,
    };
};

import {
    IconName,
    MenuItemConfigurationV2,
} from '@buildingradar/br_component_lib';
import { capitalize } from 'lodash';
import { TFunction } from 'react-i18next';

import {
    ConfiguredSearch,
    SearchType,
} from 'src/app-features/searches-configuration/domain/models/configured-search';
import { DocumentGroup } from 'src/app-features/searches-configuration/domain/models/document.model';
import { SubscribedSearchTileComponent } from 'src/app-features/searches-configuration/presentation/subscribed-searches/subscribed-searches-tile.component';
import { FolderType } from 'src/domain/models/folder/folder.model';

/**
 * It maps the folder type to the icon name to be displayed in the UI
 * @param item The lead result entity
 * @returns The icon name to be displayed
 */
export const getIconByLeadItem = (
    item: DocumentGroup,
): IconName | undefined => {
    switch (item.folderType) {
        case FolderType.interesting:
            return IconName.BrStarBorderOutlineIcon;
        case FolderType.inbox:
            return IconName.BrInboxIcon;
        case FolderType.notInteresting:
            return IconName.BrCancelOutlineIcon;
        case FolderType.done:
            return IconName.BrCheckCircleOutlineIcon;
        case FolderType.exported:
            return IconName.BrDownloadOutlineIcon;
        case FolderType.snoozed:
            return IconName.BrDeleteOutlineIcon;
        default:
            return undefined;
    }
};

/**
 * It maps the folder type to the description to be displayed in the UI
 * @param item The lead result entity
 * @returns The description to be displayed
 */
export const getDescriptionForLeadItem = (
    item: DocumentGroup,
): string | undefined => {
    switch (item.folderType) {
        case FolderType.interesting:
            return 'In Pipeline';
        case FolderType.inbox:
            return 'Inboxed';
        case FolderType.notInteresting:
            return 'Disqualified';
        case FolderType.done:
            return 'Marked Done';
        case FolderType.exported:
            return 'Exported';
        case FolderType.snoozed:
            return 'Snoozed';
        default:
            return undefined;
    }
};

export const BuildSearchUrlQueryParam = (type: SearchType) => `?type=${type}`;

export const buildUserSubscribedSearchOptions = (
    searches: ConfiguredSearch[],
    selectedId: string | undefined,
    onDeleteSearch: (id: number) => (e: React.MouseEvent<HTMLElement>) => void,
    t: TFunction<'translation', undefined>,
): MenuItemConfigurationV2[] => {
    const articleSearches = searches.filter(
        (s) => s.id !== 0 && s.type === SearchType.Article,
    );
    const tenderSearches = searches.filter(
        (s) => s.id !== 0 && s.type === SearchType.Tender,
    );

    const searchItems: MenuItemConfigurationV2[] = [];
    if (articleSearches.length) {
        searchItems.push({
            value: SearchType.Article,
            label: capitalize(
                t('search_configuration.subscribed_list.article_search_group'),
            ),
            children: articleSearches.map((search) => ({
                value: search.id.toString(),
                customContent: {
                    element: (
                        <SubscribedSearchTileComponent
                            isSelected={search.id.toString() === selectedId}
                            search={search}
                            onDeleteSearch={onDeleteSearch(search.id)}
                        />
                    ),
                },
            })),
        });
    }

    if (tenderSearches.length) {
        searchItems.push({
            value: SearchType.Tender,
            label: capitalize(
                t('search_configuration.subscribed_list.tender_search_group'),
            ),
            children: tenderSearches.map((search) => ({
                value: search.id.toString(),
                customContent: {
                    element: (
                        <SubscribedSearchTileComponent
                            isSelected={search.id.toString() === selectedId}
                            search={search}
                            onDeleteSearch={onDeleteSearch(search.id)}
                        />
                    ),
                },
            })),
        });
    }

    return searchItems;
};

import styled from '@emotion/styled';

import { Spacing } from 'src/resources/styles/spacing';

export const TokenFieldContentStyled = styled.div<{
    rows: number;
}>`
    display: inline-block;
    border-radius: 4px;
    padding: ${Spacing.s1} ${Spacing.s1_5};
    border: 2px solid var(--gray-20);
    white-space: pre-wrap;
    line-height: 22px;
    overflow-y: auto;
    min-height: ${({ rows }) => rows * 40}px;
    max-height: ${({ rows }) => (rows + 2) * 40}px;
    width: 100%;

    &:hover {
        border-color: var(--gray-30);
    }

    &:focus {
        border-color: var(--blue-60);
    }
`;

export const TokenStyled = styled.span<{
    backgroundColor: string;
    color: string;
    hasPadding?: boolean;
}>`
    border-radius: 1px;
    font-weight: 500;
    background-color: ${({ backgroundColor }) => backgroundColor};
    color: ${({ color }) => color};
    ${({ hasPadding }) => hasPadding && 'padding: 0px 2px;'}
`;

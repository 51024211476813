import { Language } from 'src/domain/models/locale/locale.model';
import { copyValueToClipboard } from 'src/utils/string.utils';

export const splitEmailSubjectAndBody = (
    fullEmailText: string,
    language: Language,
) => {
    const contentParts = fullEmailText.split('\n').filter((part) => !!part);
    if (!contentParts.length) {
        return {
            subject: '',
            body: '',
        };
    }

    const otherLanguage = language === Language.En ? Language.De : Language.En;

    const subjectKeyIdentifier = LanguageEmailSubjectIdentifier[language];
    const otherSubjectKeyIdentifier =
        LanguageEmailSubjectIdentifier[otherLanguage];
    const subject = contentParts[0].includes(subjectKeyIdentifier)
        ? contentParts[0].replace(subjectKeyIdentifier, '')
        : contentParts[0].includes(otherSubjectKeyIdentifier)
          ? contentParts[0].replace(otherSubjectKeyIdentifier, '')
          : '';

    const body = (
        subject ? fullEmailText.replace(contentParts[0], '') : fullEmailText
    )
        .replace(/^\n+/, '')
        .trimEnd();

    return {
        subject,
        body,
    };
};

export const copyGeneratedContentToClipboard = (
    content: string,
    email: string,
    language: Language,
) => {
    const emailAddress = `${email}\n\n`;
    const { subject, body } = splitEmailSubjectAndBody(content, language);
    const subjectContent = `${subject}\n\n`;
    const copiedContent = `${emailAddress}${subjectContent.trim()}${body.trim()}`;

    copyValueToClipboard(copiedContent);
};

const LanguageEmailSubjectIdentifier = {
    [Language.En]: 'Subject:',
    [Language.De]: 'Betreff:',
};

/**
 * Adds a empty line between each paragraph line for easier reading
 */
export const formatCallScriptContent = (content: string) => {
    const lines = content.length
        ? content
              .split('\n')
              .map((line) => line.trim())
              .filter(Boolean)
        : [];

    return lines.join('\n\n');
};

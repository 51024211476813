import { BrCheckbox, BrIcon, BrInfotip } from '@buildingradar/br_component_lib';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import {
    GoogleEnrichmentFieldIcons,
    GoogleEnrichmentResultKey,
} from 'src/app-features/google-enrichment/data/google-enrichment.model';
import { CopyToClipboard } from 'src/presentation/shared/copy-to-clipboard/copy-to-clipboard';
import { addProtocolIfNeeded } from 'src/utils/url.utils';

import clsx from 'clsx';

interface CompanyFieldProps {
    className?: string;
    value: string | undefined;
    config: GoogleEnrichmentResultKey;
    checked?: boolean;
    hasCustomFieldMapping?: boolean;
    onCheckedChange?: (checked: boolean) => void;
}
export const GoogleEnrichmentInfoField: FC<CompanyFieldProps> = ({
    className,
    value,
    config,
    hasCustomFieldMapping = false,
    checked = false,
    onCheckedChange,
}) => {
    const { t } = useTranslation();

    if (!value || !hasCustomFieldMapping) {
        return null;
    }

    const tooltipText = hasCustomFieldMapping
        ? t('actions.google_enrichment.results.tooltip.direct_save')
        : t('actions.google_enrichment.results.tooltip.note_save');

    const hrefValue =
        config === 'address'
            ? `https://www.google.com/maps/place/${value}`
            : config === 'url'
              ? addProtocolIfNeeded(value)
              : config === 'telephone'
                ? `tel:${value}`
                : undefined;
    return (
        <div
            className={`${className} my-1 flex min-h-[40px] w-full items-center justify-center`}
        >
            <BrInfotip
                content={tooltipText}
                placement="top"
                disableMargin
                disableInteractive
                showArrow
            >
                <BrCheckbox
                    className="m-0 pb-2 pl-1 pr-0 pt-2"
                    disablePadding
                    checked={checked}
                    onCheckedChange={onCheckedChange}
                />
            </BrInfotip>
            <div className="mr-2 flex h-[40px] min-w-[40px] items-center justify-center">
                <BrIcon
                    iconName={GoogleEnrichmentFieldIcons[config]}
                    iconColor="var(--gray-40)"
                />
            </div>
            <div className="group flex w-full items-center justify-center overflow-hidden text-ellipsis whitespace-nowrap sm:justify-start">
                <a
                    className={clsx({
                        [config]: true,
                        'spacing whitespace-pre-line break-words text-[16px] leading-4 tracking-[0.44px]':
                            true,
                        'cursor:none text-[--main-font] group-hover:cursor-default':
                            !hrefValue,
                        'text-[--accent-link] group-hover:text-[--accent-action-button-hover]':
                            !!hrefValue,
                    })}
                    href={hrefValue}
                    target="_blank"
                    rel="noreferrer"
                >
                    {value}
                </a>
                <div className="hidden group-hover:block">
                    <CopyToClipboard
                        text={value}
                        tooltipText={t('common.copy_to_clipboard')}
                    />
                </div>
            </div>
        </div>
    );
};

import { makeAutoObservable } from 'mobx';

export interface ProcurementTextsStore {
    procurementTexts: string[];
    isLoading: boolean;
    error?: Error;
    setProcurementTexts: (procurementTexts: string[]) => void;
    setLoading: (flag: boolean) => void;
    setError: (error?: Error) => void;
}

export class ProcurementTextsStoreImpl implements ProcurementTextsStore {
    procurementTexts: string[] = [];
    isLoading = false;
    error: Error | undefined = undefined;

    setProcurementTexts = (procurementTexts: string[]) => {
        this.procurementTexts = procurementTexts;
    };

    setLoading = (flag: boolean) => {
        this.isLoading = flag;
    };

    setError = (error?: Error) => {
        this.error = error;
    };

    constructor() {
        makeAutoObservable(this);
    }
}

import { withFeatures } from 'src/utils/component.utils';

import { MobileMenuSwiperComponent } from './mobile-menu-swiper.component';

export const MobileMenuSwiperContainer = withFeatures(
    MobileMenuSwiperComponent,
)((f) => ({
    isOpened: f.navbarFeature.isMobileMenuOpened,
    toggleMenuOpen: f.navbarFeature.toggleMobileMenu,
    toggleNotifications: f.notificationsFeature.toggleNotificationsOpened,
}));

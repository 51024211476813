import {
    BrDateTimePartFragment,
    BrTimePrecision,
} from 'src/data/api/graphql/br_search/generated/graphql-sdk';
import {
    Language,
    languageToLocale,
} from 'src/domain/models/locale/locale.model';

export interface RawBrDateTime {
    precision: number;
    hour: number;
    month: number;
    second: number;
    microsecond: number;
    year: number;
    day: number;
    minute: number;
}

export interface BrDateTime {
    precision: BrDatePrecision;
    hour: number;
    month: number;
    second: number;
    microsecond: number;
    year: number;
    day: number;
    minute: number;
}

export const createNullableBrDateTime = (
    rawBrDateTime?: RawBrDateTime | null,
): BrDateTime | null => {
    if (!rawBrDateTime) {
        return null;
    }
    return createBrDateTime(rawBrDateTime);
};

const createBrDateTime = (rawBrDateTime: RawBrDateTime): BrDateTime => {
    return {
        ...rawBrDateTime,
        precision: getPrecision(rawBrDateTime.precision),
    };
};

const getPrecision = (rawPrecision: number): BrDatePrecision => {
    switch (rawPrecision) {
        case 1:
            return BrDatePrecision.Year;
        case 2:
            return BrDatePrecision.Month;
        case 3:
            return BrDatePrecision.Day;
        default:
            return BrDatePrecision.Day;
    }
};
export enum BrDatePrecision {
    Month = 'month',
    Day = 'day',
    Year = 'year',
    DateTime = 'date-time',
}

export const LocaleDateOptions: Record<
    BrDatePrecision,
    Intl.DateTimeFormatOptions
> = {
    [BrDatePrecision.Day]: {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
    },
    [BrDatePrecision.Month]: {
        month: 'short',
        year: 'numeric',
    },
    [BrDatePrecision.Year]: {
        year: 'numeric',
    },
    [BrDatePrecision.DateTime]: {
        hour12: false,
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
    },
};

const LocaleDateOptionsSdk: Record<
    BrDatePrecisionSdk,
    Intl.DateTimeFormatOptions
> = {
    [BrDatePrecision.Day]: {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
    },
    [BrDatePrecision.Month]: {
        month: 'short',
        year: 'numeric',
    },
    [BrDatePrecision.Year]: {
        year: 'numeric',
    },
};

export const getTextFromBrDate = (
    date: BrDateTime | BrDateTimeSdk,
    language: Language,
) => {
    const locale = languageToLocale[language];
    const parsedDate = new Date(
        date.year,
        date.month - 1,
        date.day,
        date.hour,
        date.minute,
        date.second,
        date.microsecond / 1000,
    );

    let options = LocaleDateOptions[date.precision];
    if (!options) {
        options = LocaleDateOptionsSdk[date.precision as BrDatePrecisionSdk];
    }

    return parsedDate.toLocaleDateString(locale, options);
};

export enum BrDatePrecisionSdk {
    Month = 'month',
    Day = 'day',
    Year = 'year',
}

const SdkTimePrecisionToBrDatePrecision: {
    [SdkKey in BrTimePrecision]?: BrDatePrecisionSdk;
} = {
    [BrTimePrecision.Month]: BrDatePrecisionSdk.Month,
    [BrTimePrecision.Day]: BrDatePrecisionSdk.Day,
    [BrTimePrecision.Year]: BrDatePrecisionSdk.Year,
};

export interface BrDateTimeSdk {
    precision: BrDatePrecisionSdk;
    hour: number;
    month: number;
    second: number;
    microsecond: number;
    year: number;
    day: number;
    minute: number;
}

const createBrDateTimeFromSdk = (
    rawBrDateTime: BrDateTimePartFragment,
): BrDateTimeSdk => {
    return {
        year: rawBrDateTime.year ?? 0,
        month: rawBrDateTime.month ?? 0,
        day: rawBrDateTime.day ?? 0,
        hour: rawBrDateTime.hour ?? 0,
        minute: rawBrDateTime.minute ?? 0,
        second: rawBrDateTime.second ?? 0,
        microsecond: rawBrDateTime.microsecond ?? 0,
        precision:
            SdkTimePrecisionToBrDatePrecision[rawBrDateTime.precision] ??
            BrDatePrecisionSdk.Day,
    };
};

export const createNullableBrDateTimeFromSdk = (
    rawBrDateTime: BrDateTimePartFragment | null,
): BrDateTimeSdk | null =>
    rawBrDateTime ? createBrDateTimeFromSdk(rawBrDateTime) : null;

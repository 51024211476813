import { BrButton } from '@buildingradar/br_component_lib';
import * as Sentry from '@sentry/react';
import { PureComponent, ReactNode } from 'react';

import { AppRoute } from 'src/presentation/modules/router/app-route.list';
import { Localized } from 'src/presentation/shared/localized/localized';

import {
    ErrorContentStyled,
    ErrorPageStyled,
    HomeLinkWrapperStyled,
} from './styles';

interface ErrorBoundaryState {
    error: Error | null;
}

export class ErrorBoundary extends PureComponent<
    { children: ReactNode },
    ErrorBoundaryState
> {
    state = {
        error: null,
    };

    componentDidCatch(error: Error): void {
        Sentry.captureException(error);
    }

    static getDerivedStateFromError(error: Error) {
        return { error };
    }

    onGoHome = () => {
        const homePage = window.location.origin + AppRoute.empty;
        window.location.assign(homePage);
    };

    render() {
        const { error } = this.state;
        if (error) {
            console.error(error);

            return (
                <ErrorPageStyled>
                    <h1>
                        <Localized>error_page.title</Localized>
                    </h1>
                    <ErrorContentStyled>
                        <h3>
                            <Localized>error_page.message</Localized>
                        </h3>
                        <HomeLinkWrapperStyled>
                            <BrButton onClick={this.onGoHome}>
                                <Localized>error_page.go_to_home</Localized>
                            </BrButton>
                        </HomeLinkWrapperStyled>
                    </ErrorContentStyled>
                </ErrorPageStyled>
            );
        }

        return this.props.children;
    }
}

import { MergedDocument } from 'src/app-features/searches-configuration/domain/models/document.model';
import { PhaseQl } from 'src/data/api/graphql/br_search/generated/graphql-sdk';
import {
    Document,
    DocumentType,
} from 'src/domain/models/document/document.model';

export interface RawConstructionPhase {
    ranking: number;
    sub_phases: string[];
    name: string;
}

export interface ConstructionPhase {
    ranking: number;
    subPhases: string[];
    name: string;
}
export const createConstructionPhase = (
    phase: RawConstructionPhase,
): ConstructionPhase => {
    const { ranking, sub_phases: subPhases, name } = phase;
    return {
        ranking,
        subPhases,
        name,
    };
};

export const createConstructionPhaseFromSdk = (
    phase: PhaseQl,
): ConstructionPhase => {
    const { ranking, subPhases, name } = phase;
    return {
        ranking: ranking ?? 0,
        subPhases: subPhases ?? [],
        name,
    };
};

const readablePhase: Record<string, string> = {
    pre_tender_information: 'lead.phase.pre_bid_information',
    open_tender: 'lead.phase.open_bid',
    passed_tender: 'lead.phase.past_bid',
    awarded_tender: 'lead.phase.awarded_bid',
    planned: 'lead.phase.planned',
    under_construction: 'lead.phase.under_construction',
    pre_planning: 'lead.phase.pre_planning',
    implementation_planning: 'lead.phase.implementation_planning',
    existing: 'lead.phase.existing',
    canceled: 'lead.phase.canceled',
    // add other from "allPhases" on text-representation.utils.ts
};

const getPhaseText = (phaseName: string): string => {
    return readablePhase[phaseName] ?? phaseName;
};

export const getReadablePhase = (
    document: Document | MergedDocument,
): string | null => {
    if (document.type === DocumentType.Tender && document.competitionPhase) {
        return getPhaseText(document.competitionPhase);
    }

    const { constructionPhases } = document;
    if (!constructionPhases?.length) {
        return null;
    }

    const firstPhase = constructionPhases[0];
    const finishedPhase = firstPhase.ranking === 100;

    if (document.type === DocumentType.Article && !finishedPhase) {
        return null;
    }

    return getPhaseText(firstPhase.name);
};

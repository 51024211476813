import { makeAutoObservable } from 'mobx';

import {
    DealContactCandidate,
    DealCompanyCandidate,
    ContactExtractionResultStatus,
} from 'src/app-features/contact-extraction/data/contact-extraction.model';
import { IContactExtractionStore } from './contact-extraction.interface.store';

export class ContactExtractionStore implements IContactExtractionStore {
    contactExtractionCandidates: DealContactCandidate[] = [];
    companyExtractionCandidates: DealCompanyCandidate[] = [];
    contactExtractionResultStatus?: ContactExtractionResultStatus;

    constructor() {
        makeAutoObservable(this);
    }
    setContactExtractionCandidates = (candidates: DealContactCandidate[]) => {
        this.contactExtractionCandidates = candidates;
    };
    setCompanyExtractionCandidates = (candidates: DealCompanyCandidate[]) => {
        this.companyExtractionCandidates = candidates;
    };
    setContactExtractionResultStatus = (
        status?: ContactExtractionResultStatus,
    ) => {
        this.contactExtractionResultStatus = status;
    };

    clear = () => {
        this.contactExtractionCandidates = [];
        this.companyExtractionCandidates = [];
        this.contactExtractionResultStatus = undefined;
    };
}

import { makeAutoObservable } from 'mobx';

import { FilterModule } from 'src/domain/models/filter-module/filter-module.model';
import { SearchSubscriptionData } from 'src/domain/models/subscription/subscription.model';
import { sortNewArray } from 'src/utils/array.utils';
import { isNonNullable } from 'src/utils/is-non-nullable.utils';

export interface SubscriptionsStore {
    subscriptions: Map<number, SearchSubscriptionData>;
    subscriptionsList: SearchSubscriptionData[];
    set: (subscription: SearchSubscriptionData) => void;
    setSubscriptions: (subscriptions: SearchSubscriptionData[]) => void;
    clear: () => void;
    subscribeUserToSearchSubscription: (
        subscriberId: number,
        searchId: number,
    ) => void;
    unsubscribeUserToSearchSubscription: (
        subscriberId: number,
        searchId: number,
    ) => void;
    filterModules: Map<number, FilterModule>;
    setFilterModules: (filterModules: FilterModule[]) => void;
    pauseSearch: (searchId: number) => void;
    resumeSearch: (searchId: number) => void;
    deleteSearch: (searchId: number) => void;
}

export class SubscriptionsStoreImpl implements SubscriptionsStore {
    subscriptions: Map<number, SearchSubscriptionData> = new Map();
    searchIds: number[] = [];
    filterModules: Map<number, FilterModule> = new Map();

    get subscriptionsList() {
        return sortNewArray(
            this.searchIds
                .map((searchId) => this.subscriptions.get(searchId))
                .filter(isNonNullable),
        )((a, b) => a.name.localeCompare(b.name));
    }

    set = (subscription: SearchSubscriptionData) => {
        this.subscriptions.set(subscription.id, subscription);
        this.searchIds.push(subscription.id);
    };

    setSubscriptions = (subscriptions: SearchSubscriptionData[]) => {
        this.searchIds = [];
        subscriptions.forEach((subscription) => {
            this.set(subscription);
        });
    };

    clear = () => {
        this.subscriptions.clear();
    };

    subscribeUserToSearchSubscription = (
        subscriberId: number,
        searchId: number,
    ) => {
        const searchSubscription = this.subscriptions.get(searchId);
        if (!searchSubscription) {
            return;
        }

        searchSubscription.subscriberIds = [
            ...searchSubscription.subscriberIds,
            subscriberId,
        ];
        this.subscriptions.set(searchSubscription.id, {
            ...searchSubscription,
        });
    };

    unsubscribeUserToSearchSubscription = (
        subscriberId: number,
        searchId: number,
    ) => {
        const searchSubscription = this.subscriptions.get(searchId);
        if (!searchSubscription) {
            return;
        }

        searchSubscription.subscriberIds = [
            ...searchSubscription.subscriberIds.filter(
                (id) => id !== subscriberId,
            ),
        ];
        this.subscriptions.set(searchSubscription.id, {
            ...searchSubscription,
        });
    };

    setFilterModules = (filterModules: FilterModule[]) => {
        filterModules.forEach((filter) =>
            this.filterModules.set(filter.id, filter),
        );
    };

    pauseSearch = (searchId: number) => {
        const search = this.subscriptions.get(searchId);
        if (search) {
            search.paused = true;
        }
    };

    resumeSearch = (searchId: number) => {
        const search = this.subscriptions.get(searchId);
        if (search) {
            search.paused = false;
        }
    };

    deleteSearch = (searchId: number) => {
        this.subscriptions.delete(searchId);
    };

    constructor() {
        makeAutoObservable(this);
    }
}

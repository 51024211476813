import { DealAlertType, DealState } from 'src/domain/models/deal/deal.model';

export const dealTypeToString: Record<DealState | DealAlertType, string> = {
    [DealState.inPlay]: 'In play',
    [DealState.done]: 'Marked done',
    [DealState.disqualified]: 'Disqualified',
    [DealAlertType.UNASSIGNED]: 'Critical - unassigned',
    [DealAlertType.FAILED_SO]: 'Critical - failed SO',
    [DealAlertType.OVERDUE_REMINDER]: 'Critical - overdue reminder',
    [DealAlertType.UPCOMING_REMINDER]: 'Upcoming reminder',
    [DealAlertType.RECENTLY_ADDED_TO_STAGE]: 'Recently added to stage',
    [DealAlertType.READY_TO_REACTIVATE]: 'Ready to reactivate',
    [DealAlertType.SLOW_MOVING]: 'Slow moving',
    [DealAlertType.PAUSED]: 'Paused',
    [DealAlertType.HEALTHY]: 'Healthy',
};

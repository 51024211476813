import { makeAutoObservable } from 'mobx';

import { FolderCountersApi } from 'src/data/api/folder-counters/folder-counters.api';
import { FolderCountersStore } from 'src/data/stores/folder-counters/folder-counters.store';
import { IBaseStore } from 'src/data/stores/shared/base.store.interface';
import { UserStore } from 'src/data/stores/user/user.store';
import {
    FolderCounters,
    FolderType,
} from 'src/domain/models/folder/folder.model';
import { Search } from 'src/domain/models/search/search.model';
import { sortNewArray } from 'src/utils/array.utils';
import { Cancellable, handleRequest } from 'src/utils/handle-request.utils';

export interface NavbarFeature {
    allSearches: Search[];
    folderCounters: FolderCounters;
    isLoading: boolean;
    allSubFolderCount: (folder: FolderType) => number;
    isMobileMenuOpened: boolean;
    toggleMobileMenu: (flag?: boolean) => void;
    requestFolderCounters: () => Cancellable;
}

export class NavbarFeatureImpl implements NavbarFeature {
    isMobileMenuOpened = false;

    get user() {
        return this.userStore.user;
    }

    get allSearches(): Search[] {
        return this.user
            ? sortNewArray(this.user.searches)((a, b) =>
                  a.name.localeCompare(b.name),
              )
            : [];
    }

    get isLoading(): boolean {
        return this.folderCountersStore.isLoading;
    }

    get folderCounters(): FolderCounters {
        return this.folderCountersStore.folderCounters;
    }

    constructor(
        private folderCountersApi: FolderCountersApi,
        private userStore: UserStore,
        private folderCountersStore: FolderCountersStore,
        private baseStore: IBaseStore,
    ) {
        makeAutoObservable(this);
    }

    toggleMobileMenu = (flag?: boolean) => {
        const newValue = flag !== undefined ? flag : !this.isMobileMenuOpened;
        this.isMobileMenuOpened = newValue;
    };

    requestFolderCounters = (): Cancellable => {
        const allSearchTags = this.userStore.searchTags;
        return handleRequest(
            this.folderCountersApi.getFolderCounters,
            { searchTags: allSearchTags.map((tag) => Number(tag)) },
            this.folderCountersStore.setFolderCounters,
            this.folderCountersStore.setLoading,
            (error) =>
                this.baseStore.onRequestFailed(
                    'request-folder-counters',
                    error,
                ),
        );
    };

    allSubFolderCount = (folder: FolderType): number => {
        const folderValues = Object.values(this.folderCounters[folder]);
        if (!folderValues.length) {
            return 0;
        }
        return folderValues.reduce((total, current = 0) => total + current);
    };
}

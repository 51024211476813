export const GrayIcon = () => {
    return (
        <svg
            width="72"
            height="72"
            viewBox="0 0 72 72"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M31.3947 41.7727C31.6501 42.0964 31.955 42.3777 32.2981 42.6064L34.2247 43.8908C34.793 44.2697 35.2526 44.7903 35.5581 45.4013L37.2378 48.7606C37.7077 49.7004 38.7531 50.2042 39.7811 49.9862C41.0452 49.718 41.8429 48.4643 41.5502 47.2056L41.013 44.8954C40.9096 44.451 40.7296 44.028 40.4809 43.6454L39.6222 41.7706L42.9956 41.0705C44.8171 40.6924 46.1229 39.0877 46.1229 37.2274V23.2497L41.3578 22.3833C40.9395 22.3073 40.5115 22.2997 40.0908 22.3609L34.6579 23.1511C34.2094 23.2163 33.7756 23.3587 33.3758 23.572L30.3005 25.2122H25.8849V39.9308H30.3005L31.3947 41.7727Z"
                fill="url(#paint0_linear_806_2516)"
            />
            <path
                d="M21.96 25.2122C21.96 24.6703 22.3993 24.231 22.9412 24.231H27.1115C27.6534 24.231 28.0927 24.6703 28.0927 25.2122V39.6855C28.0927 40.2274 27.6534 40.6667 27.1115 40.6667H22.9412C22.3993 40.6667 21.96 40.2274 21.96 39.6855V25.2122Z"
                fill="#B6B6B6"
            />
            <path
                d="M32.2981 42.6065C31.955 42.3777 31.6501 42.0963 31.3947 41.7728L30.3005 39.9308H25.8849V36.9744L30.5458 37.3551C32.3631 39.951 33.1027 41.2322 35.5826 42.3839C36.4935 45.3922 37.4273 47.5664 39.4548 50.0315C38.5383 50.0927 37.658 49.601 37.2378 48.7605L35.5581 45.4013C35.2527 44.7903 34.793 44.2697 34.2247 43.8909L32.2981 42.6065Z"
                fill="#B6B6B6"
            />
            <path
                d="M46.1229 24.1083V37.2274C46.1229 39.0877 44.817 40.6924 42.9956 41.0705L40.3025 41.6294L38.273 38.8269C38.1643 37.4119 38.398 36.8456 39.3769 36.2512C38.8807 34.6704 38.7575 33.7846 39.3769 32.2036C38.9006 30.4715 38.9172 29.5013 39.6222 27.788C39.0705 26.2269 39.298 25.4268 40.4808 24.1083H46.1229Z"
                fill="#B6B6B6"
            />
            <path
                d="M39.8676 25.2122C39.8676 23.9929 40.856 23.0045 42.0753 23.0045H46.2456C47.4649 23.0045 48.4534 23.9929 48.4534 25.2122C48.4534 26.4316 47.4649 27.42 46.2456 27.42H42.0753C40.856 27.42 39.8676 26.4316 39.8676 25.2122Z"
                fill="url(#paint1_linear_806_2516)"
            />
            <path
                d="M39.8676 24.9669C39.8676 23.8831 40.7462 23.0045 41.83 23.0045H42.8113C42.3471 24.7301 42.376 25.6948 42.8113 27.42H41.83C40.7462 27.42 39.8676 26.5414 39.8676 25.4576V24.9669Z"
                fill="#C9C7C7"
            />
            <path
                d="M39.8676 29.6278C39.8676 28.4084 40.856 27.42 42.0753 27.42H47.4722C48.6915 27.42 49.6799 28.4084 49.6799 29.6278C49.6799 30.8471 48.6915 31.8356 47.4722 31.8356H42.0753C40.856 31.8356 39.8676 30.8471 39.8676 29.6278Z"
                fill="url(#paint2_linear_806_2516)"
            />
            <path
                d="M39.8676 29.3825C39.8676 28.2986 40.7462 27.42 41.83 27.42H42.8113C42.2725 29.182 42.2702 30.164 42.8113 31.8356H41.83C40.7462 31.8356 39.8676 30.9569 39.8676 29.8731V29.3825Z"
                fill="#C9C7C7"
            />
            <path
                d="M39.8676 34.0434C39.8676 32.824 40.856 31.8356 42.0753 31.8356H47.4722C48.6915 31.8356 49.6799 32.824 49.6799 34.0434C49.6799 35.2627 48.6915 36.2511 47.4722 36.2511H42.0753C40.856 36.2511 39.8676 35.2627 39.8676 34.0434Z"
                fill="url(#paint3_linear_806_2516)"
            />
            <path
                d="M39.8676 33.798C39.8676 32.7142 40.7462 31.8356 41.83 31.8356H42.8113C42.2751 33.5228 42.2659 34.4791 42.8113 36.2511H41.83C40.7462 36.2511 39.8676 35.3725 39.8676 34.2887V33.798Z"
                fill="#C9C7C7"
            />
            <path
                d="M39.8676 38.4589C39.8676 37.2396 40.856 36.2512 42.0753 36.2512H46.4909C47.7102 36.2512 48.6987 37.2396 48.6987 38.4589C48.6987 39.6783 47.7102 40.6667 46.4909 40.6667H42.0753C40.856 40.6667 39.8676 39.6783 39.8676 38.4589Z"
                fill="url(#paint4_linear_806_2516)"
            />
            <path
                d="M39.8676 38.2136C39.8676 37.1298 40.7462 36.2512 41.83 36.2512H42.8113C42.4052 37.9354 42.3844 38.8988 42.8113 40.6667H41.83C40.7462 40.6667 39.8676 39.7881 39.8676 38.7043V38.2136Z"
                fill="#C9C7C7"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_806_2516"
                    x1="33.3306"
                    y1="34.0029"
                    x2="43.0774"
                    y2="49.2749"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="#D7D9DA" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_806_2516"
                    x1="43.0263"
                    y1="24.8657"
                    x2="43.8024"
                    y2="28.1037"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="#D7D9DA" />
                </linearGradient>
                <linearGradient
                    id="paint2_linear_806_2516"
                    x1="43.4776"
                    y1="29.2812"
                    x2="44.1654"
                    y2="32.561"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="#D7D9DA" />
                </linearGradient>
                <linearGradient
                    id="paint3_linear_806_2516"
                    x1="43.4776"
                    y1="33.6968"
                    x2="44.1654"
                    y2="36.9766"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="#D7D9DA" />
                </linearGradient>
                <linearGradient
                    id="paint4_linear_806_2516"
                    x1="43.1166"
                    y1="38.1124"
                    x2="43.8733"
                    y2="41.3601"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="#D7D9DA" />
                </linearGradient>
            </defs>
        </svg>
    );
};

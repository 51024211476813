import { withFeatures } from 'src/utils/component.utils';

import { EnrichmentCompanySelectComponent } from './enrichment-company-select.component';

export const EnrichmentCompanySelectContainer = withFeatures(
    EnrichmentCompanySelectComponent,
)((f) => ({
    dealId: f.quickActionsFeature.selectedDealId,
    contact: f.contactEnrichmentFeature.selectedContact,
    iterateState: f.contactEnrichmentFeature.iterateState,
    clean: f.contactEnrichmentFeature.clean,
}));

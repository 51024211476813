import {
    ArticleDocumentPartFragment,
    DocumentSearchResultPartFragment,
    TenderDocumentPartFragment,
} from 'src/data/api/graphql/br_search/generated/graphql-sdk';
import {
    Address,
    createAddressFromSdk,
} from 'src/domain/models/address/address.model';
import {
    BrDateTimeSdk,
    createNullableBrDateTimeFromSdk,
} from 'src/domain/models/br-time/br-time.model';
import {
    Category,
    createCategoryFromSdk,
} from 'src/domain/models/category/category.model';
import {
    Contact,
    createContactsFromSdk,
} from 'src/domain/models/contact/contact.model';
import {
    FolderTagToFolderTypeMap,
    FolderType,
} from 'src/domain/models/folder/folder.model';
import { Image, createImageFromSdk } from 'src/domain/models/image/image.model';
import {
    ConstructionPhase,
    createConstructionPhaseFromSdk,
} from 'src/domain/models/phase/phase.model';

import { SearchType } from './configured-search';

export enum DocumentType {
    Article = 'article',
    Tender = 'tender',
}

/**
 * Interface for the base properties of article and tender documents.
 */
interface BaseDocument {
    isSdkInterface: boolean;
    itemId: string;
    title: string;
    titleAlternative?: string;
    titleEnglish?: string;
    titleAlternativeEnglish?: string;
    categories?: Category[];
    constructionPhases?: ConstructionPhase[];
    volumeReference?: number;
    sourceUrls?: string[];
    sourceDomains?: string[];
    language?: string;
    description?: string;
    descriptions?: string[];
    sourcePublishedDate: BrDateTimeSdk | null;
    images?: Image[];
    contacts: Contact[];
}

/**
 * Interface for specialized article documents.
 */
export interface ArticleDocument extends BaseDocument {
    type: DocumentType.Article;
    address: Address | null;
    abstract?: string;
    paragraphs?: string[];
    gfa?: number;
    nResidentialUnits?: number;
    constructionStartDate: BrDateTimeSdk | null;
    constructionEndDate: BrDateTimeSdk | null;
}

/**
 * Interface for specialized tender documents.
 */
export interface TenderDocument extends BaseDocument {
    type: DocumentType.Tender;
    allLotsAwarded?: boolean;
    announcedDate: BrDateTimeSdk | null;
    awardCriteria?: string;
    competitionDeadlineDate: BrDateTimeSdk | null;
    competitionDeadlineDateEst: boolean;
    contractStartDate: BrDateTimeSdk | null;
    contractEndDate: BrDateTimeSdk | null;
    contractType?: string;
    frameworkAgreement?: string;
    competitionPhase: string;
    applicationUrl?: string;
    openingDate: BrDateTimeSdk | null;
    documentType?: string;
    directive?: string;
    address: Address | null;
    awardedDate: BrDateTimeSdk | null;
    procedureType?: string;
    quantityScope?: string;
    referenceNumber?: string;
    tenderType?: string;
    cpvCodes?: string[];
    naicsCodes?: string[];
    unspscCodes?: string[];
}

export type MergedDocument = ArticleDocument | TenderDocument;

export interface DocumentGroup {
    id: string;
    created: Date;
    documentIds: string[];
    pipeDbCollection: string;
    folderType?: FolderType;
    updated: Date;
    documents: { title: string }[];
    mergedDocument: MergedDocument;
}

const createBaseDocument = (
    rawDocument: ArticleDocumentPartFragment | TenderDocumentPartFragment,
): BaseDocument => {
    return {
        isSdkInterface: true,
        itemId: rawDocument.itemId,
        title: rawDocument.title,
        titleAlternative: rawDocument.titleAlternative ?? undefined,
        titleEnglish: rawDocument.titleEnglish ?? undefined,
        titleAlternativeEnglish:
            rawDocument.titleAlternativeEnglish ?? undefined,
        volumeReference: rawDocument.volumeReference
            ? Number(rawDocument.volumeReference)
            : undefined,
        sourceUrls: rawDocument.sourceUrls ?? undefined,
        sourceDomains: rawDocument.sourceDomains ?? undefined,
        language: rawDocument.language ?? undefined,
        description: rawDocument.description ?? undefined,
        descriptions: rawDocument.descriptions ?? undefined,
        categories: rawDocument.categories
            ? rawDocument.categories.map(createCategoryFromSdk)
            : [],
        contacts: createContactsFromSdk(rawDocument.contacts ?? []),
        constructionPhases: rawDocument.constructionPhases
            ? rawDocument.constructionPhases.map(createConstructionPhaseFromSdk)
            : [],
        sourcePublishedDate: createNullableBrDateTimeFromSdk(
            rawDocument.sourcePublishedDate ?? null,
        ),
        images: (rawDocument.images ?? []).map(createImageFromSdk),
    };
};

const createArticleDocument = (
    rawArticle: ArticleDocumentPartFragment,
): ArticleDocument => {
    const baseDocument = createBaseDocument(rawArticle);

    let descriptions = baseDocument.descriptions ?? [];
    if (rawArticle.abstract) {
        descriptions = [...descriptions, rawArticle.abstract];
    }
    if (rawArticle.paragraphs) {
        descriptions = [...descriptions, ...rawArticle.paragraphs];
    }
    const description = descriptions.length > 0 ? descriptions.join(' ') : '';

    return {
        ...baseDocument,
        type: DocumentType.Article,
        address: createAddressFromSdk(rawArticle.address ?? null),
        abstract: rawArticle.abstract ?? undefined,
        description,
        descriptions,
        paragraphs: rawArticle.paragraphs ?? undefined,
        gfa: rawArticle.gfa ?? undefined,
        nResidentialUnits: rawArticle.nResidentialUnits ?? undefined,
        constructionStartDate: createNullableBrDateTimeFromSdk(
            rawArticle.constructionStartDate ?? null,
        ),
        constructionEndDate: createNullableBrDateTimeFromSdk(
            rawArticle.constructionEndDate ?? null,
        ),
    };
};

const createTenderDocument = (
    rawTender: TenderDocumentPartFragment,
): TenderDocument => {
    return {
        ...createBaseDocument(rawTender),
        type: DocumentType.Tender,
        allLotsAwarded: rawTender.allLotsAwarded ?? undefined,
        announcedDate: createNullableBrDateTimeFromSdk(
            rawTender.announcedDate ?? null,
        ),
        awardCriteria: rawTender.awardCriteria ?? undefined,
        competitionDeadlineDate: createNullableBrDateTimeFromSdk(
            rawTender.competitionDeadlineDate ?? null,
        ),
        competitionDeadlineDateEst:
            rawTender.competitionDeadlineDateEst ?? false,
        contractStartDate: createNullableBrDateTimeFromSdk(
            rawTender.contractStartDate ?? null,
        ),
        contractEndDate: createNullableBrDateTimeFromSdk(
            rawTender.contractEndDate ?? null,
        ),
        contractType: rawTender.contractType ?? undefined,
        frameworkAgreement: rawTender.frameworkAgreement ?? undefined,
        competitionPhase: rawTender.competitionPhase ?? '',
        applicationUrl: rawTender.applicationUrl ?? undefined,
        openingDate: createNullableBrDateTimeFromSdk(
            rawTender.openingDate ?? null,
        ),
        documentType: rawTender.documentType ?? undefined,
        directive: rawTender.directive ?? undefined,
        address: createAddressFromSdk(rawTender.contractAddress ?? null),
        awardedDate: createNullableBrDateTimeFromSdk(
            rawTender.awardedDate ?? null,
        ),
        procedureType: rawTender.procedureType ?? undefined,
        quantityScope: rawTender.quantityScope ?? undefined,
        referenceNumber: rawTender.referenceNumber ?? undefined,
        tenderType: rawTender.tenderType ?? undefined,
        cpvCodes: rawTender.cpvCodes ?? undefined,
        naicsCodes: rawTender.naicsCodes ?? undefined,
        unspscCodes: rawTender.unspscCodes ?? undefined,
    };
};

export interface DocumentResults {
    totalCount: number;
    documentGroups: DocumentGroup[];
}

export const createDocumentGroup = (
    raw: DocumentSearchResultPartFragment,
    type: SearchType,
): DocumentGroup => {
    return {
        id: raw.id,
        created: new Date(raw.created),
        documentIds: raw.documentIds,
        pipeDbCollection: raw.pipeDbCollection,
        updated: new Date(raw.updated),
        folderType: raw.folder
            ? FolderTagToFolderTypeMap[raw.folder]
            : undefined,
        documents: raw.documents.map((document) => ({
            title: (document as { title: string }).title,
        })),
        mergedDocument:
            type === SearchType.Article
                ? createArticleDocument(
                      raw.mergedDocument as ArticleDocumentPartFragment,
                  )
                : createTenderDocument(
                      raw.mergedDocument as TenderDocumentPartFragment,
                  ),
    };
};

import { makeAutoObservable } from 'mobx';
import { UserStore } from 'src/data/stores/user/user.store';
import { IVpoGenerationApi } from '../api/vpo-generation.api';
import {
    Cancellable,
    handleRequest,
    isAbortError,
} from 'src/utils/handle-request.utils';
import { IBaseStore } from 'src/data/stores/shared/base.store.interface';
import { IVpoGenerationStore } from './vpo-generation.store';
import { IAccountConfigurationStore } from 'src/data/stores/account-configuration/account-configuration.store.interface';
import {
    copyGeneratedContentToClipboard,
    formatCallScriptContent,
} from './utils';
import { Language } from 'src/domain/models/locale/locale.model';
import { getI18n } from 'react-i18next';
import { ToasterStore } from 'src/data/stores/toaster/toaster.store';
import { IDealActivityStore } from 'src/app-features/deal-activity/domain/deal-activity.store';
import { DealsStore } from 'src/data/stores/deals/deals.store';
import {
    filterDealActivitiesByContactId,
    hasAtLeastOneActivityOfType,
} from 'src/app-features/deal-activity/domain/deal-activity.utils';
import { OutreachActivityType } from 'src/app-features/deal-activity/domain/deal-activity.model';
import { OutreachPromptBlock } from './model/outreach-block.model';
import { PropositionType } from './model/propositionType';
import { UserSettingName } from 'src/data/api/graphql/br_user/generated/graphql-sdk';
import { userSettingsMap } from 'src/domain/models/settings/settings.model';
import { DealContactType } from 'src/app-features/contact/data/model/deal-contacts.model';
import { UserAchievementName } from 'src/data/api/graphql/br_process/generated/graphql-sdk';
import { AchievementService } from 'src/app-features/achievements/domain/service/achievements.service';

export interface GeneratePropositionPayload {
    /**
     * Id of the project associated to the deal
     */
    projectId?: number;
    /**
     * Id of the contact to whom the email will be sent
     */
    contactId: string;
    /**
     * Id of the deal associated to the email
     */
    dealId: string;
    /**
     * Optional text to send to the AI to adjust the proposition output
     * eg.: "Please adjust the text to be more formal". It will use the first generated
     * output as the base and adjust it according to the text provided
     */
    promptAdjustmentText?: string;
}

export interface IVpoGenerationFeature {
    /**
     * This is initially the generated content by the AI, but it can be updated by the user
     * in the UI.
     */
    currentContent: string;
    /**
     * This flag will be true while the AI is generating the content, regardless of the
     * type of generation (stream or http based).
     */
    isLongGenerationInProgress: boolean;
    /**
     * This flag is used to know if there is a first generation in progress.
     */
    isInitialGenerationInProgress: boolean;
    /**
     * Getter that holds the current proposition type to be generated.
     * It's based on the outreach activity type and the previous activities of the contact in the deal.
     */
    propositionType: PropositionType;

    accountBlocksForCurrentPropositionType: OutreachPromptBlock[];
    /**
     * This method is used to set the current generated content.
     * The content is feed by the AI and can be updated by the user in the UI.
     */
    setCurrentContent: (content: string) => void;
    /**
     * This method runs the generation of the proposition.
     */
    generateProposition: (args: GeneratePropositionPayload) => Cancellable;
    /**
     * This method is used to reset the states of the feature
     */
    clearStates: () => void;
    /**
     * It takes the generated content and splits the body from the subject based on the user language.
     * Then, it copies the passed email address, the subject and the body to the clipboard.
     * @param emailAddress The email address to be used
     */
    copyGeneratedContentToClipboard: (emailAddress: string) => void;
    /**
     * It trigger the completion of one of the two outreach achievements based on the proposition type.
     * If the proposition type is 'first-email' or 'follow-up-email' it will trigger the email achievement.
     * If the proposition type is 'call-script' it will trigger the phone achievement.
     */
    completeOutreachAchievement: () => void;
}

const VPO_GENERATION_REQUEST_KEY = 'vpo-generation-request-key';

export class VpoGenerationFeature implements IVpoGenerationFeature {
    currentContent = '';
    /**
     * This will hold a copy of the latest currentContent to be used as a base for the next new content in case
     * a adjustment text is passed.
     */
    baseContentForAdjustment: string | null = null;
    vpoSessionId: string | null = null;
    isGenerationInProgress = false;
    isFirstGenerationRequestCompleted = false;
    isGenerationError = false;

    constructor(
        private vpoGenerationApi: IVpoGenerationApi,
        private userStore: UserStore,
        private vpoGenerationStore: IVpoGenerationStore,
        private accountConfigurationStore: IAccountConfigurationStore,
        private toasterStore: ToasterStore,
        private dealActivityStore: IDealActivityStore,
        private dealStore: DealsStore,
        private baseStore: IBaseStore,
        private achievementsService: AchievementService,
    ) {
        makeAutoObservable(this);
    }

    setCurrentContent = (content: string) => {
        this.currentContent = content;
    };

    setIsGenerating = (generating: boolean) =>
        (this.isGenerationInProgress = generating);

    get isLongGenerationInProgress() {
        return this.isGenerationInProgress;
    }

    get isInitialGenerationInProgress() {
        return (
            this.isGenerationInProgress &&
            !this.isFirstGenerationRequestCompleted &&
            !this.currentContent.length
        );
    }

    get propositionType(): PropositionType {
        const { logOutreachChosenType, dealId, contact } =
            this.dealActivityStore.outreachActivationState;

        if (
            !logOutreachChosenType ||
            !dealId ||
            !contact ||
            contact.type !== DealContactType.person
        ) {
            return 'not-defined';
        }

        if (logOutreachChosenType === OutreachActivityType.phoneCall) {
            return 'call-script';
        } else {
            const currentDeal = this.dealStore.getDeal(dealId);

            const activities = filterDealActivitiesByContactId(
                currentDeal?.outreachActivityLog || [],
                contact.id,
            );

            const hasEmailLog = hasAtLeastOneActivityOfType(
                activities,
                OutreachActivityType.email,
            );

            return hasEmailLog ? 'follow-up-email' : 'first-email';
        }
    }

    get accountBlocksForCurrentPropositionType() {
        let blocks: OutreachPromptBlock[] = [];
        if (this.propositionType === 'not-defined') blocks = [];
        else if (this.propositionType === 'first-email') {
            blocks = [
                ...this.accountConfigurationStore.accountPromptBlocksConfig
                    .valueProposition,
                ...this.accountConfigurationStore.accountPromptBlocksConfig
                    .desiredOutcome,
                ...this.accountConfigurationStore.accountPromptBlocksConfig
                    .writingStyle,
            ];
        } else if (this.propositionType === 'follow-up-email') {
            blocks = [
                ...this.accountConfigurationStore.accountPromptBlocksConfig
                    .writingStyle,
            ];
        } else if (this.propositionType === 'call-script') {
            blocks = [
                ...this.accountConfigurationStore.accountPromptBlocksConfig
                    .valueProposition,
            ];
        }

        return blocks;
    }

    generateProposition = (
        payload: GeneratePropositionPayload,
    ): Cancellable => {
        const { promptAdjustmentText, projectId, contactId, dealId } = payload;

        if (this.isGenerationError) {
            this.isGenerationError = false;
        }

        if (promptAdjustmentText) {
            this.baseContentForAdjustment = this.currentContent;
        }

        this.setCurrentContent('');

        if (!this.vpoSessionId) {
            this.vpoGenerationStore.buildVpoSessionId(
                this.userStore.user?.itemId ?? 0,
            );
        }

        const onNextValue = (
            value: string,
            propositionType: PropositionType,
        ) => {
            if (this.isValidAnswer(value)) {
                if (propositionType === 'call-script') {
                    this.setCurrentContent(formatCallScriptContent(value));
                } else {
                    this.setCurrentContent(value);
                }
            }
        };

        const accountBlockIdForProposition =
            this.accountBlocksForCurrentPropositionType.map(
                (block) => block.id,
            );

        const settingName =
            this.propositionType === 'call-script'
                ? UserSettingName.OutreachCallBlockIds
                : UserSettingName.OutreachBlockIds;

        const settingPropName = userSettingsMap[settingName as UserSettingName];

        const outreachBlockIds = (
            this.userStore.user?.settings[settingPropName] as string[]
        ).filter((id) => accountBlockIdForProposition.includes(id));

        return handleRequest(
            this.vpoGenerationApi.getGeneratedContentHybrid,
            {
                projectId,
                contactId,
                dealId,
                regenerationSpec: promptAdjustmentText
                    ? {
                          previousText: this.baseContentForAdjustment ?? '',
                          userRawPrompt: promptAdjustmentText,
                      }
                    : undefined,
                propositionType: this.propositionType,
                outreachBlockIds,
                vpoSessionId: this.vpoSessionId ?? '',
                onNextValue,
            },
            (content) => this.onSuccessGeneration(content),
            this.setIsGenerating,
            (error) => {
                this.baseStore.onRequestFailed(
                    VPO_GENERATION_REQUEST_KEY,
                    error,
                );
                if (error && !isAbortError(error)) {
                    this.isGenerationError = true;
                }
            },
            VPO_GENERATION_REQUEST_KEY,
        );
    };

    clearStates = () => {
        this.vpoGenerationStore.clearVpoSessionId();
        this.isFirstGenerationRequestCompleted = false;
    };

    copyGeneratedContentToClipboard = (emailAddress: string) => {
        if (!this.currentContent) return;

        copyGeneratedContentToClipboard(
            this.currentContent,
            emailAddress,
            this.userStore.user?.language ?? Language.En,
        );

        const { t } = getI18n();
        this.toasterStore.showMessage({
            title: t('common.copied_to_clipboard'),
        });
    };

    completeOutreachAchievement = () => {
        if (
            this.propositionType === 'first-email' ||
            this.propositionType === 'follow-up-email'
        ) {
            this.achievementsService.call(UserAchievementName.OutreachByEmail);
        } else {
            this.achievementsService.call(UserAchievementName.OutreachByPhone);
        }
    };

    private isValidAnswer = (answer: string | null): answer is string =>
        !!answer && answer.length > 1;

    private onSuccessGeneration = (content: string) => {
        const validContent = this.isValidAnswer(content) ? content : '';

        if (this.propositionType === 'call-script') {
            this.setCurrentContent(formatCallScriptContent(validContent));
        } else {
            this.setCurrentContent(validContent);
        }

        if (!this.isFirstGenerationRequestCompleted) {
            this.isFirstGenerationRequestCompleted = true;
        }
    };
}

import { TranslationFn } from 'src/domain/features/translation/translation.feature';
import { Contact } from 'src/domain/models/contact/contact.model';
import { Lead } from 'src/domain/models/lead/lead.model';
import { User } from 'src/domain/models/user/user.model';
import { defaultStyle, pageMargins } from 'src/resources/styles/pdf.styles';

export interface ExportService {
    downloadPDF: (
        lead: Lead,
        procurementTexts: string[],
        associatedCompanies: Contact[],
        leadCustomTitle: string | undefined,
        user: User,
        t: TranslationFn,
    ) => Promise<void>;
}

export const createExportService = (): ExportService => {
    const downloadPDF = async (
        lead: Lead,
        procurementTexts: string[],
        associatedCompanies: Contact[],
        leadCustomTitle: string | undefined,
        user: User,
        t: TranslationFn,
    ): Promise<void> => {
        const { footer, getHeader, getPDFContentFromLead, info } = await import(
            '../../../utils/pdf.utils'
        );
        const content = await getPDFContentFromLead(
            lead,
            procurementTexts,
            associatedCompanies,
            leadCustomTitle,
            user,
            t,
        );

        const { createPdf } = await import('pdfmake/build/pdfmake');
        const { vfs } = await import('../../../resources/fonts/vfs.base64');
        const header = await getHeader();
        window.pdfMake.vfs = vfs;

        const fileName = `building_radar_${lead.id}.pdf`;
        const pdf = createPdf({
            pageMargins,
            defaultStyle,
            info: info(fileName),
            header,
            footer,
            content,
        });
        pdf.download(fileName);
    };
    return { downloadPDF };
};

import { BrSelect, BrSelectElement } from '@buildingradar/br_component_lib';
import { useMediaQuery } from '@mui/material';
import { observer } from 'mobx-react';
import { FC, useCallback, useRef } from 'react';
import { Controller, Path, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
    DealContactCompanyForm,
    DealContactPersonForm,
    DealContactUnionForm,
} from 'src/app-features/contact/data/model/deal-contacts-form.model';
import { DealContactCompany } from 'src/app-features/contact/data/model/deal-contacts.model';
import { DealContactFormField } from 'src/app-features/contact/presentation/contact-panel/components/deal-contact-form-field.component';
import { SelectParameterConfig } from 'src/presentation/shared/form-control/form.model';
import { MediaQuery } from 'src/resources/styles/media.mixins';

import { ContactFieldProps } from './common.utils';

interface SelectFieldContactFieldProps extends ContactFieldProps {
    config:
        | SelectParameterConfig<DealContactPersonForm>
        | SelectParameterConfig<DealContactCompanyForm>;
    companies?: DealContactCompany[];
    createCompanyByName: (
        name: string,
        onSuccess: (company: DealContactCompany) => void,
    ) => void;
}
export const SelectFieldContactField: FC<SelectFieldContactFieldProps> =
    observer(
        ({
            config,
            companies = [],
            status,
            focusField,
            onSaveHandler,
            createCompanyByName,
        }) => {
            const { t } = useTranslation();
            const isMobile = useMediaQuery(MediaQuery.phone);
            const { control } = useFormContext<DealContactUnionForm>();

            return (
                <DealContactFormField
                    key={config.name.toString()}
                    label={t(config.label)}
                >
                    <Controller<DealContactUnionForm>
                        name={config.name as Path<DealContactUnionForm>}
                        control={control}
                        rules={{ required: config.required }}
                        render={({ field }) => {
                            const isCompanyField =
                                field.name === 'relevantCompanyId';
                            const onChange = useCallback(
                                (value: string | undefined) => {
                                    field.onChange(value ?? null);
                                    onSaveHandler?.(field.name, value ?? '');
                                },
                                [field],
                            );
                            const conditional = (content: any) =>
                                isCompanyField ? content : undefined;
                            const selectRef = useRef<BrSelectElement>(null);

                            const onCompanyCreate = useCallback(
                                (name: string) => {
                                    createCompanyByName(name, (company) => {
                                        onChange(company.id);
                                        selectRef.current?.setOpen(false);
                                    });
                                },
                                [onChange],
                            );

                            return (
                                <BrSelect
                                    key={companies.length}
                                    ref={selectRef}
                                    autoCloseAfterSelect
                                    stopEnterKeyPropagation
                                    useSheetMenu={isMobile}
                                    options={config.options}
                                    selectedValue={field.value?.toString()}
                                    enableClearButton={config.resettable}
                                    placeholder={t(config.placeholder)}
                                    onSelectionChange={onChange}
                                    margin="none"
                                    fullWidth
                                    createNewButtonText={conditional(
                                        `${t(
                                            'deal_view.contacts_section.contact_form.create_new_company_button',
                                        )}...`,
                                    )}
                                    onCreateNewItem={conditional(
                                        onCompanyCreate,
                                    )}
                                    emptyOptionsText={conditional(
                                        t(
                                            'deal_view.contacts_section.company_input.no_option_text',
                                        ),
                                    )}
                                    createNewInputPlaceholder={conditional(
                                        t(
                                            'deal_view.contacts_section.company_input.placeholder',
                                        ),
                                    )}
                                    createFormButtonText={conditional(
                                        t(
                                            'deal_view.contacts_section.company_input.create_company_form_button',
                                        ),
                                    )}
                                    status={
                                        !field.value && config.required
                                            ? 'error'
                                            : status
                                    }
                                    helperText={
                                        !field.value && config.required
                                            ? t('common.required_field_text')
                                            : undefined
                                    }
                                    autoFocus={focusField === field.name}
                                />
                            );
                        }}
                    />
                </DealContactFormField>
            );
        },
    );

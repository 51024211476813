import { BrTextInputStatus } from '@buildingradar/br_component_lib';
import { observer } from 'mobx-react';
import { useCallback } from 'react';
import { Control, Controller, FieldValues, Path } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useTranslationFeature } from 'src/app-context/use-features';
import { CUSTOM_PARAMETER_SAVE_DEBOUNCE } from 'src/domain/features/custom-parameters/custom-parameters.utils';
import { CustomParameter } from 'src/domain/models/custom-parameter/custom-parameter.model';
import { StringParameterContainer } from 'src/presentation/modules/deal-view/components/deal-custom-parameter/components/string-parameter/string-parameter.container';
import { useDebounceCallback } from 'src/utils/hooks/use-debounce';

import { CustomFieldsForm } from './custom-form-fields.component';
import { FormInputWrapper, NoteFieldStyled } from './form-control.styled';
import { SingleFieldType } from './form.model';
import { StatusText } from './utils';

interface FormInputProps<FormType extends FieldValues> {
    name: Path<FormType>;
    control: Control<FormType, object>;
    placeholder: string;
    autoFocus?: boolean;
    // if we add another type of validation,
    // we'll need to update message text
    required?: boolean;
    error?: boolean;
    errorText?: string;
    className?: string;
    customField?: CustomParameter;
    type: SingleFieldType | 'custom-parameter-line';
    inputType?: 'password' | 'text';
    status?: BrTextInputStatus;
    onSave?: (field: string, value: string) => void;
    validate?: (value: string) => boolean;
}

export const FormControl = observer(
    <FormType extends Record<string, any>>({
        name,
        control,
        required = false,
        placeholder,
        autoFocus = false,
        error,
        errorText,
        className,
        type,
        customField,
        inputType,
        status = 'idle',
        onSave,
        validate,
    }: FormInputProps<FormType>) => {
        const { t } = useTranslation();

        const { language } = useTranslationFeature();

        return (
            <FormInputWrapper>
                <Controller<FormType>
                    name={name}
                    rules={{ required, validate }}
                    control={control}
                    render={({ field }) => {
                        const onBlur = useCallback(() => {
                            field.onBlur();
                        }, [field]);

                        const saveDebounced = useDebounceCallback(
                            onSave,
                            CUSTOM_PARAMETER_SAVE_DEBOUNCE,
                            [],
                        );
                        const onChange = useCallback(
                            (value: string) => {
                                field.onChange(value);
                                saveDebounced(field.name, value);
                            },
                            [field, saveDebounced],
                        );

                        if (type !== 'custom-parameter-line') {
                            if (field.name === 'notes') {
                                return (
                                    <NoteFieldStyled>
                                        <StringParameterContainer
                                            placeholder={placeholder}
                                            value={field.value}
                                            setValue={field.onChange}
                                            saveValue={onBlur}
                                            multiline
                                            name={'deal_note'}
                                            autoFocus={autoFocus}
                                        />
                                    </NoteFieldStyled>
                                );
                            }
                            return (
                                <StringParameterContainer
                                    autoFocus={autoFocus}
                                    margin="none"
                                    status={error ? 'error' : status}
                                    className={className}
                                    helperText={
                                        error && errorText
                                            ? errorText
                                            : t(StatusText[status])
                                    }
                                    placeholder={
                                        field.value ? undefined : placeholder
                                    }
                                    name={field.name}
                                    onFocusOut={onBlur}
                                    value={field.value}
                                    inputRef={field.ref}
                                    type={inputType}
                                    labelShrink={false}
                                    setValue={onChange}
                                    multiline={type === 'multi-line-text'}
                                />
                            );
                        } else if (
                            type === 'custom-parameter-line' &&
                            customField
                        ) {
                            return (
                                <CustomFieldsForm
                                    field={field}
                                    placeholder={placeholder}
                                    error={error}
                                    errorText={errorText}
                                    className={className}
                                    customField={customField}
                                    onSave={onSave}
                                    autoFocus={autoFocus}
                                    language={language}
                                    status={status}
                                />
                            );
                        }

                        return <></>;
                    }}
                />
            </FormInputWrapper>
        );
    },
);

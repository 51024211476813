import { Box } from '@mui/material';
import { observer } from 'mobx-react';
import { FC, useMemo } from 'react';

import { AchievementDetail } from 'src/app-features/achievements/domain/model/achievement.model';
import { UserAchievementState } from 'src/data/api/graphql/br_process/generated/graphql-sdk';

import { CircleBadgeComponent } from './circle-badge.component';
import { HexagonalBadgeComponent } from './hexagonal-badge.component';
import { ShieldBadgeComponent } from './shield-badge.component';
import {
    AchievedColorMap,
    BadgeColor,
    BadgeShape,
    IconMap,
    ShapeMap,
} from './utils';

interface AchievementBadgeComponentProps {
    achievement: AchievementDetail;
    size?: string;
}

export const AchievementBadgeComponent: FC<AchievementBadgeComponentProps> =
    observer(({ achievement, size }) => {
        const shape = ShapeMap[achievement.category];
        const color =
            achievement.state === UserAchievementState.Achieved
                ? AchievedColorMap[achievement.name]
                : BadgeColor.Gray;

        const badgeBase = useMemo(() => {
            if (shape === BadgeShape.Circle) {
                return <CircleBadgeComponent color={color} size={size} />;
            }

            if (shape === BadgeShape.Shield) {
                return <ShieldBadgeComponent color={color} size={size} />;
            }

            if (shape === BadgeShape.Hexagon) {
                return <HexagonalBadgeComponent color={color} size={size} />;
            }

            return <div>Unhandled achievement</div>;
        }, [color, shape, size]);

        const Icon = IconMap[achievement.name];

        return (
            <Box sx={{ position: 'relative', lineHeight: 0 }}>
                {badgeBase}
                <Box sx={{ position: 'absolute', top: 0 }}>
                    <Icon color={color} size={size} />
                </Box>
            </Box>
        );
    });

import { add } from 'date-fns';
import { TFunction } from 'i18next';
import { DealContactPersonForm } from 'src/app-features/contact/data/model/deal-contacts-form.model';
import {
    DealContactType,
    DealContactUnionType,
    PredefinedCustomFields,
} from 'src/app-features/contact/data/model/deal-contacts.model';
import {
    OutreachActivityResult,
    OutreachNextAction,
    OutreachNextActionContactCreateData,
    OutreachNextActionData,
} from 'src/app-features/deal-activity/domain/deal-activity.model';
import { ReminderTargetType } from 'src/domain/models/reminder/reminder-target-type';
import { getFullName, User } from 'src/domain/models/user/user.model';

const AllOutreachNextActions = Object.values(OutreachNextAction);
export const NextStepOutreachNextActions = AllOutreachNextActions.filter((a) =>
    a.includes('reminder'),
);

const OutreachResultToAction: Record<
    OutreachActivityResult,
    OutreachNextAction
> = {
    [OutreachActivityResult.didNotConnect]: OutreachNextAction.reminderTomorrow,
    [OutreachActivityResult.other]: OutreachNextAction.reminderNextWeek,
    [OutreachActivityResult.interested]: OutreachNextAction.none,
    [OutreachActivityResult.referral]: OutreachNextAction.createContact,
    [OutreachActivityResult.futureInterest]: OutreachNextAction.none,
    [OutreachActivityResult.notInterested]: OutreachNextAction.none,
    [OutreachActivityResult.emailSent]: OutreachNextAction.none,
};

export const getDefaultOutreachNextActionData = (
    result: OutreachActivityResult,
): OutreachNextActionData => {
    const action = OutreachResultToAction[result];
    const hasDefaultAction = action !== OutreachNextAction.none;
    const selectedAction = !hasDefaultAction
        ? OutreachNextAction.reminderTomorrow
        : action;
    return {
        action: selectedAction,
        reminderDate: getNextActionDefaultReminderDate(selectedAction),
        contactCreationData:
            getNextActionDefaultContactCreationData(selectedAction),
        enabled: hasDefaultAction,
    };
};

export const getNextActionDefaultReminderDate = (
    action?: OutreachNextAction,
) => {
    switch (action) {
        case OutreachNextAction.reminderTomorrow:
            return add(new Date(), { days: 1 });
        case OutreachNextAction.reminderNextWeek:
            return add(new Date(), { days: 7 });
        case OutreachNextAction.reminderNextMonth:
            return add(new Date(), { months: 1 });
        case OutreachNextAction.reminderCustom:
            return new Date();
        default:
            return undefined;
    }
};
export const getNextActionDefaultContactCreationData = (
    action: OutreachNextAction,
): OutreachNextActionContactCreateData | undefined => {
    if (action !== OutreachNextAction.createContact) {
        return undefined;
    }
    return {
        firstName: '',
        lastName: '',
        relevantCompanyId: undefined,
        email: undefined,
        mobile: undefined,
        telephone: undefined,
    };
};
export const OutreachNextActionMetricsName: Record<OutreachNextAction, string> =
    {
        [OutreachNextAction.none]: 'Unchecked',
        [OutreachNextAction.reminderTomorrow]: 'Follow up tomorrow',
        [OutreachNextAction.reminderNextWeek]: 'Remind me next week',
        [OutreachNextAction.reminderNextMonth]: 'Remind me next month',
        [OutreachNextAction.reminderCustom]: 'Remind me on a specific date',
        [OutreachNextAction.createContact]: 'Create contact',
    };

export const createNBAReminderPayload = (
    user: User,
    dueDate: Date,
    contact: DealContactUnionType,
    dealId: string,
    t: TFunction,
) => ({
    assigneeId: user.itemId,
    dueDate: dueDate,
    description: t('outreach_log.next_best_action.next_step_note', {
        name:
            contact.type === DealContactType.person
                ? getFullName(contact.firstName, contact.lastName)
                : contact.name,
    }),
    target: {
        type: ReminderTargetType.Deal,
        id: dealId,
    },
});

export const createNBAContactPayload = (
    data: OutreachNextActionContactCreateData,
): DealContactPersonForm => ({
    formType: DealContactType.person,
    firstName: data.firstName,
    lastName: data.lastName,
    isKey: true,
    role: '',
    notes: '',
    relevantCompanyId: data.relevantCompanyId,
    extracted: false,
    customInput: {
        customValues: [
            data.email && {
                name: PredefinedCustomFields.mail,
                value: data.email,
            },
            data.mobile && {
                name: PredefinedCustomFields.mobile,
                value: data.mobile,
            },
            data.telephone && {
                name: PredefinedCustomFields.telephone,
                value: data.telephone,
            },
        ].filter(Boolean) as { name: string; value: string }[],
    },
});

import { DealContactPerson } from 'src/app-features/contact/data/model/deal-contacts.model';
import { CustomParameter } from 'src/domain/models/custom-parameter/custom-parameter.model';
import { Deal } from 'src/domain/models/deal/deal.model';
import { Lead } from 'src/domain/models/lead/lead.model';
import { User } from 'src/domain/models/user/user.model';

import {
    ContactDataContext,
    IContactDataContext,
} from './contact-data-context';
import {
    Assignee,
    DealDataContext,
    IDealDataContext,
} from './deal-data-context';
import { ILeadDataContext, LeadDataContext } from './lead-data-context';
import { IMeDataContext, MeDataContext } from './me-data-context';

export const buildContactDataContext = (
    contact?: DealContactPerson,
): IContactDataContext | undefined => {
    if (!contact) {
        return undefined;
    }

    return new ContactDataContext({
        firstName: contact.firstName ?? undefined,
        lastName: contact.lastName ?? undefined,
        company: contact?.relevantCompany?.name,
        notes: contact?.notes ?? undefined,
        dealRole: contact?.role ?? undefined,
        customFields: contact.customFields?.map(buildCustomParamContext) ?? [],
    });
};

export const buildDealDataContext = (
    customParams: CustomParameter[],
    deal?: Deal,
    assignee?: Assignee,
): IDealDataContext | undefined => {
    if (!deal) {
        return undefined;
    }

    return new DealDataContext({
        name: deal.title,
        ownerEmail: assignee?.email,
        ownerName: assignee?.name,
        notes: deal.notes,
        value: deal.dealRevenue.value.toString(),
        currency: deal.dealRevenue.currency,
        customFields: customParams.map(buildCustomParamContext),
    });
};

export const buildLeadDataContext = (
    lead?: Lead,
): ILeadDataContext | undefined => {
    return new LeadDataContext({
        latestSource: lead?.mergedDocument.sourceDomains[0],
    });
};

export const buildMeDataContext = (user?: User): IMeDataContext => {
    return new MeDataContext({
        firstName: user?.firstName,
        lastName: user?.lastName,
        email: user?.email,
    });
};

const buildCustomParamContext = ({
    name,
    value,
    label,
    type,
}: CustomParameter) => ({
    name,
    value: value ?? undefined,
    label,
    type,
});

import { SuggestedLocationResultQl } from 'src/data/api/graphql/br_search/generated/graphql-sdk';

export interface FilterLocation {
    key: string;
    country_code: string;
    population: number;
    two_fishes_id: string;
    type: number;
    value: string;
}

export const createFilterLocation = (
    raw: SuggestedLocationResultQl,
): FilterLocation => ({
    key: raw.key,
    country_code: raw.countryCode,
    population: raw.population,
    two_fishes_id: raw.twoFishesId,
    type: raw.type,
    value: raw.value,
});

export const GoldIcon = () => {
    return (
        <svg
            width="72"
            height="72"
            viewBox="0 0 72 72"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_806_2490)">
                <g filter="url(#filter0_ddd_806_2490)">
                    <path
                        d="M31.4869 14.0736C31.4869 13.1907 32.2026 12.475 33.0855 12.475C33.9684 12.475 34.6842 13.1907 34.6842 14.0736V46.0463C34.6842 46.9292 33.9684 47.6449 33.0855 47.6449C32.2026 47.6449 31.4869 46.9292 31.4869 46.0463V14.0736Z"
                        fill="#C0B104"
                    />
                    <path
                        d="M31.4869 20.1484C31.4869 19.2655 32.2026 18.5498 33.0855 18.5498C33.9684 18.5498 34.6842 19.2655 34.6842 20.1484V36.7742C34.6842 37.6571 33.9684 38.3729 33.0855 38.3729C32.2026 38.3729 31.4869 37.6571 31.4869 36.7742V20.1484Z"
                        fill="#AEA000"
                    />
                    <path
                        d="M36.9222 18.5498L43.8482 36.1342C44.3865 37.5009 45.7173 38.4999 47.0769 37.9437C49.3172 37.0272 51.1488 34.8823 50.6705 33.5464C48.6337 27.8581 45.2391 19.5841 36.9222 18.5498Z"
                        fill="#C0B104"
                    />
                    <path
                        d="M24.498 33.3791L18.5156 19.1032C17.4246 16.4996 19.4615 13.6592 22.2685 13.959C41.5149 16.0147 47.4747 21.1538 50.6705 33.665C48.2803 28.6075 42.7757 29.2258 29.6928 35.3476C27.7139 36.2735 25.3424 35.3941 24.498 33.3791Z"
                        fill="url(#paint0_linear_806_2490)"
                    />
                    <path
                        d="M15.8234 50.1669L12.451 43.6883C11.9489 42.7239 12.8031 41.6126 13.8642 41.8497C30.1083 45.4787 45.0977 47.2825 59.5818 36.1883C60.6371 35.38 62.0721 36.5185 61.525 37.73L56.0242 49.9104C55.1967 51.7427 53.3725 52.9205 51.362 52.9205H20.361C18.4534 52.9205 16.7042 51.859 15.8234 50.1669Z"
                        fill="url(#paint1_linear_806_2490)"
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M27.2609 52.9205H25.673C25.2605 49.3449 25.7899 46.7816 27.0184 44.2018C27.5565 44.2633 28.0934 44.319 28.6291 44.3684C27.397 47.1137 26.9116 49.4807 27.2609 52.9205ZM36.8528 52.9205H35.2648C34.873 49.5248 35.3308 47.0421 36.4301 44.5912C37.0112 44.5659 37.591 44.5303 38.1695 44.4836C36.9753 47.1802 36.5083 49.5289 36.8528 52.9205ZM47.0794 52.9205H45.4901C45.0797 48.7495 45.6872 45.8063 47.0471 42.7905C47.7059 42.5846 48.3632 42.3595 49.0191 42.1141C47.386 45.7402 46.703 48.56 47.0794 52.9205Z"
                        fill="#C0B104"
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M48.6762 39.8113C48.2174 39.5465 47.6308 39.7037 47.3659 40.1624L40.9552 51.2662C40.8469 51.4537 40.6257 51.5394 40.4109 51.512C39.4191 51.3856 38.4025 51.8508 37.8713 52.7709L36.2726 55.5398C35.9195 56.1515 36.129 56.9337 36.7407 57.2868L39.5097 58.8855C40.1213 59.2386 40.9035 59.029 41.2567 58.4173L42.8553 55.6484C43.3865 54.7283 43.2811 53.6153 42.6757 52.8196C42.5447 52.6473 42.5083 52.4129 42.6165 52.2254L49.0273 41.1216C49.2921 40.6628 49.135 40.0762 48.6762 39.8113Z"
                        fill="white"
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M38.7645 39.8113C38.3057 39.5465 37.7191 39.7037 37.4542 40.1624L31.0435 51.2662C30.9352 51.4537 30.714 51.5394 30.4992 51.512C29.5074 51.3856 28.4908 51.8508 27.9596 52.7709L26.3609 55.5398C26.0078 56.1515 26.2173 56.9337 26.829 57.2868L29.5979 58.8855C30.2096 59.2386 30.9918 59.029 31.345 58.4173L32.9436 55.6484C33.4748 54.7283 33.3694 53.6153 32.764 52.8196C32.6329 52.6473 32.5965 52.4129 32.7048 52.2254L39.1156 41.1216C39.3804 40.6628 39.2232 40.0762 38.7645 39.8113Z"
                        fill="white"
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M28.853 39.8113C28.3942 39.5465 27.8076 39.7037 27.5427 40.1624L21.132 51.2662C21.0237 51.4537 20.8025 51.5394 20.5877 51.512C19.5959 51.3856 18.5793 51.8508 18.0481 52.7709L16.4494 55.5398C16.0963 56.1515 16.3059 56.9337 16.9176 57.2868L19.6865 58.8855C20.2982 59.2386 21.0803 59.029 21.4335 58.4173L23.0321 55.6484C23.5634 54.7283 23.4579 53.6153 22.8526 52.8196C22.7215 52.6473 22.6851 52.4129 22.7933 52.2254L29.2041 41.1216C29.469 40.6628 29.3118 40.0762 28.853 39.8113Z"
                        fill="white"
                    />
                </g>
            </g>
            <defs>
                <filter
                    id="filter0_ddd_806_2490"
                    x="-5.34047"
                    y="-3.69846"
                    width="84.6296"
                    height="81.8695"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="1.47031" />
                    <feGaussianBlur stdDeviation="8.82188" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_806_2490"
                    />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="5.88125" />
                    <feGaussianBlur stdDeviation="5.88125" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.02 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="effect1_dropShadow_806_2490"
                        result="effect2_dropShadow_806_2490"
                    />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="1.47031" />
                    <feGaussianBlur stdDeviation="2.94062" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.01 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="effect2_dropShadow_806_2490"
                        result="effect3_dropShadow_806_2490"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect3_dropShadow_806_2490"
                        result="shape"
                    />
                </filter>
                <linearGradient
                    id="paint0_linear_806_2490"
                    x1="30.1564"
                    y1="23.1172"
                    x2="34.9672"
                    y2="38.5009"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FFF6D8" />
                    <stop offset="1" stopColor="#FBE8A7" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_806_2490"
                    x1="30.4565"
                    y1="43.084"
                    x2="32.5208"
                    y2="55.9379"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FFF6D8" />
                    <stop offset="1" stopColor="#FBE8A7" />
                </linearGradient>
                <clipPath id="clip0_806_2490">
                    <rect width="72" height="72" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export const ColouredIcon = () => {
    return (
        <svg
            width="72"
            height="72"
            viewBox="0 0 72 72"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_806_2488)">
                <g filter="url(#filter0_ddd_806_2488)">
                    <path
                        d="M31.8438 13.3223C31.8438 12.4394 32.5595 11.7238 33.4423 11.7238C34.3252 11.7238 35.0409 12.4394 35.0409 13.3223V45.2925C35.0409 46.1754 34.3252 46.891 33.4423 46.891C32.5595 46.891 31.8438 46.1754 31.8438 45.2925V13.3223Z"
                        fill="#93D1FF"
                    />
                    <path
                        d="M31.8438 19.3966C31.8438 18.5138 32.5595 17.7981 33.4423 17.7981C34.3252 17.7981 35.0409 18.5138 35.0409 19.3966V36.0211C35.0409 36.904 34.3252 37.6197 33.4423 37.6197C32.5595 37.6197 31.8438 36.904 31.8438 36.0211V19.3966Z"
                        fill="#76AFD9"
                    />
                    <path
                        d="M37.2787 17.7981L44.2041 35.3812C44.7424 36.7478 46.0732 37.7467 47.4326 37.1905C49.6728 36.274 51.5043 34.1293 51.026 32.7935C48.9893 27.1057 45.595 18.8323 37.2787 17.7981Z"
                        fill="#93D1FF"
                    />
                    <path
                        d="M24.8554 32.6262L18.8736 18.3515C17.7826 15.7481 19.8194 12.9078 22.6262 13.2076C41.8711 15.2632 47.8304 20.402 51.026 32.9121C48.636 27.8551 43.1318 28.4733 30.0499 34.5946C28.0711 35.5205 25.6998 34.6412 24.8554 32.6262Z"
                        fill="url(#paint0_linear_806_2488)"
                    />
                    <path
                        d="M16.1815 49.4128L12.8093 42.9348C12.3073 41.9704 13.1614 40.8592 14.2225 41.0962C30.4652 44.725 45.4536 46.5287 59.9365 35.4353C60.9918 34.6271 62.4267 35.7655 61.8796 36.9769L56.3792 49.1563C55.5518 50.9886 53.7278 52.1662 51.7173 52.1662H20.7188C18.8113 52.1662 17.0622 51.1048 16.1815 49.4128Z"
                        fill="url(#paint1_linear_806_2488)"
                    />
                    <path
                        opacity="0.5"
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M27.6181 52.1662H26.0303C25.6178 48.5908 26.1472 46.0278 27.3756 43.4481C27.9137 43.5096 28.4505 43.5653 28.9861 43.6147C27.7542 46.3598 27.2688 48.7266 27.6181 52.1662ZM37.2092 52.1662H35.6213C35.2296 48.7707 35.6873 46.2883 36.7865 43.8375C37.3676 43.8122 37.9474 43.7766 38.5258 43.7299C37.3318 46.4263 36.8647 48.7748 37.2092 52.1662ZM47.4351 52.1662H45.8459C45.4355 47.9955 46.043 45.0526 47.4028 42.037C48.0615 41.8311 48.7188 41.606 49.3747 41.3607C47.7416 44.9864 47.0587 47.806 47.4351 52.1662Z"
                        fill="#93D1FF"
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M49.0318 39.0581C48.573 38.7932 47.9865 38.9504 47.7216 39.4091L41.4701 50.237C41.2684 50.5864 40.8538 50.7383 40.4504 50.7374C39.5646 50.7356 38.7023 51.1945 38.2277 52.0167L36.6291 54.7854C36.276 55.397 36.4856 56.1791 37.0972 56.5322L39.8659 58.1308C40.4776 58.4839 41.2597 58.2743 41.6128 57.6627L43.2113 54.894C43.686 54.0718 43.6523 53.0956 43.2078 52.3295C43.0054 51.9805 42.9296 51.5455 43.1313 51.1961L49.3828 40.3682C49.6477 39.9095 49.4905 39.3229 49.0318 39.0581Z"
                        fill="url(#paint2_linear_806_2488)"
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M39.1209 39.0581C38.6621 38.7932 38.0755 38.9504 37.8107 39.4091L31.5592 50.237C31.3575 50.5864 30.9429 50.7383 30.5395 50.7374C29.6536 50.7356 28.7914 51.1945 28.3167 52.0167L26.7182 54.7854C26.365 55.397 26.5746 56.1791 27.1863 56.5323L29.955 58.1308C30.5666 58.4839 31.3487 58.2743 31.7019 57.6627L33.3004 54.894C33.775 54.0718 33.7414 53.0957 33.2969 52.3295C33.0944 51.9805 33.0187 51.5455 33.2204 51.1962L39.4719 40.3682C39.7368 39.9095 39.5796 39.3229 39.1209 39.0581Z"
                        fill="url(#paint3_linear_806_2488)"
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M29.21 39.058C28.7513 38.7932 28.1647 38.9503 27.8998 39.4091L21.6483 50.237C21.4466 50.5863 21.0321 50.7382 20.6286 50.7374C19.7428 50.7355 18.8806 51.1945 18.406 52.0166L16.8074 54.7853C16.4543 55.397 16.6639 56.1791 17.2755 56.5322L20.0442 58.1307C20.6559 58.4839 21.438 58.2743 21.7911 57.6626L23.3896 54.8939C23.8643 54.0717 23.8306 53.0956 23.3861 52.3294C23.1836 51.9804 23.1079 51.5454 23.3096 51.196L29.561 40.3682C29.8259 39.9095 29.6687 39.3229 29.21 39.058Z"
                        fill="url(#paint4_linear_806_2488)"
                    />
                </g>
            </g>
            <defs>
                <filter
                    id="filter0_ddd_806_2488"
                    x="-4.9808"
                    y="-4.44846"
                    width="84.6232"
                    height="81.8634"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="1.4702" />
                    <feGaussianBlur stdDeviation="8.82121" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_806_2488"
                    />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="5.88081" />
                    <feGaussianBlur stdDeviation="5.88081" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.02 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="effect1_dropShadow_806_2488"
                        result="effect2_dropShadow_806_2488"
                    />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="1.4702" />
                    <feGaussianBlur stdDeviation="2.9404" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.01 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="effect2_dropShadow_806_2488"
                        result="effect3_dropShadow_806_2488"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect3_dropShadow_806_2488"
                        result="shape"
                    />
                </filter>
                <linearGradient
                    id="paint0_linear_806_2488"
                    x1="34.8001"
                    y1="13.1858"
                    x2="34.8001"
                    y2="34.9629"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="#E4F8FF" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_806_2488"
                    x1="32.6887"
                    y1="42.1222"
                    x2="34.5515"
                    y2="53.9655"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="#BDE3FF" />
                </linearGradient>
                <linearGradient
                    id="paint2_linear_806_2488"
                    x1="46.291"
                    y1="46.0974"
                    x2="41.2179"
                    y2="72.8217"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F4CE57" />
                    <stop offset="1" stopColor="#F08229" />
                </linearGradient>
                <linearGradient
                    id="paint3_linear_806_2488"
                    x1="36.38"
                    y1="46.0974"
                    x2="31.307"
                    y2="72.8217"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F4CE57" />
                    <stop offset="1" stopColor="#F08229" />
                </linearGradient>
                <linearGradient
                    id="paint4_linear_806_2488"
                    x1="26.4693"
                    y1="46.0974"
                    x2="21.3962"
                    y2="72.8217"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F4CE57" />
                    <stop offset="1" stopColor="#F08229" />
                </linearGradient>
                <clipPath id="clip0_806_2488">
                    <rect width="72" height="72" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

import { makeAutoObservable } from 'mobx';
import { getI18n, TFunction } from 'react-i18next';

import { DealsExportApi } from 'src/data/api/deal-export/deal-export.api';
import { ExportedType } from 'src/data/api/graphql/br_process/generated/graphql-sdk';
import { DealsStore } from 'src/data/stores/deals/deals.store';
import { IBaseStore } from 'src/data/stores/shared/base.store.interface';
import { UserStore } from 'src/data/stores/user/user.store';
import { Deal, DealExportedDate } from 'src/domain/models/deal/deal.model';
import {
    DealExport,
    OutboundEmail,
} from 'src/domain/models/deal-export/deal-export.model';
import { getUserFullName } from 'src/domain/models/user/user.model';
import { doNothing } from 'src/utils/function.utils';
import {
    Cancellable,
    emptyCancellable,
    handleRequest,
} from 'src/utils/handle-request.utils';

export interface MultiExportCSVParams {
    ids: string[];
    baseUrl: string;
}

export interface DealExportFeature {
    getDealCSV: (id: string, baseUrl: string) => Cancellable;
    getMultipleDealsCSV: (csvQueryParams: MultiExportCSVParams) => Cancellable;
    exportDealEmail: (mail: string, deal: Deal) => Cancellable | null;
    removeExport: (id: string) => void;
    removeEmailData: (id: string) => void;
    clearExport: () => void;
    isExportLoading: boolean;
    isEmailSentFinished: boolean;
    csvExportData: Map<string, DealExport>;
    emailExportData: Map<string, OutboundEmail>;
}

export class DealExportFeatureImpl implements DealExportFeature {
    t: TFunction<'translation', undefined> = getI18n().t;
    isExportCSVLoading = false;
    isExportEmailLoading = false;

    get csvExportData() {
        return this.dealsStore.csvExportData;
    }

    get emailExportData() {
        return this.dealsStore.emailExportData;
    }

    get isExportLoading() {
        return this.isExportCSVLoading;
    }

    get isEmailSentFinished() {
        return this.isExportEmailLoading;
    }

    setLoadingCSVData = (loading: boolean) => {
        this.isExportCSVLoading = loading;
    };

    setLoadingEmail = (loading: boolean) => {
        this.isExportEmailLoading = loading;
    };

    constructor(
        private dealsStore: DealsStore,
        private userStore: UserStore,
        private dealsExportApi: DealsExportApi,
        private baseStore: IBaseStore,
    ) {
        makeAutoObservable(this);
    }

    getDealCSV = (id: string, baseUrl: string) => {
        const { user } = this.userStore;
        if (!user) {
            return emptyCancellable;
        }
        return handleRequest(
            this.dealsExportApi.getCSVById,
            { id, baseUrl, delimiter: user.settings.csvDelimiter },
            this.onCSVDataLoaded,
            this.setLoadingCSVData,
            (error) => this.onCSVDataLoadError('get-deal-csv', error),
        );
    };

    getMultipleDealsCSV = (csvQueryParams: MultiExportCSVParams) => {
        const { user } = this.userStore;
        if (!user) {
            return emptyCancellable;
        }
        const { ids, baseUrl } = csvQueryParams;
        return handleRequest(
            this.dealsExportApi.getMultipleCSVsById,
            { ids, baseUrl, delimiter: user.settings.csvDelimiter },
            this.onMultipleCSVDataLoaded,
            this.setLoadingCSVData,
            (error) => this.onCSVDataLoadError('get-multiple-deals-csv', error),
        );
    };

    exportDealEmail = (mail: string, deal: Deal) => {
        const { user } = this.userStore;
        if (!user) {
            return null;
        }
        const dealUrl = window.location.href;
        const users = user?.colleagues ?? [user];
        const recipient = users.find((user) => user.email.includes(mail));
        const owner = users.find((user) => user.itemId === deal.assigneeId);
        const inEmail = {
            senderName: user.firstName,
            toName: getUserFullName(recipient),
            toEmail: mail,
            language: user.language,
            dealId: deal.id,
            dealOwner: getUserFullName(owner),
            dealUrl,
            dealOwnerEmail: `${owner?.email}`,
        };
        return handleRequest(
            this.dealsExportApi.exportDealEmail,
            { inEmail },
            this.onEmailSent,
            this.setLoadingEmail,
            (error?: Error) => this.onEmailSendError(inEmail.toEmail, error),
        );
    };

    removeExport = (id: string) => {
        this.dealsStore.csvExportData.delete(id);
    };

    removeEmailData = (id: string) => {
        this.dealsStore.emailExportData.delete(id);
    };

    clearExport = () => {
        this.dealsStore.csvExportData.clear();
    };

    onCSVDataLoadError = (errorKey: string, error?: Error) => {
        this.baseStore.onRequestFailed(errorKey, error, {
            errorMessage: this.t('deal_export.download_failure_message'),
        });
    };

    onEmailSendError = (email: string, error?: Error) => {
        this.baseStore.onRequestFailed('send-email', error, {
            errorMessage: this.t('deal_export.email_send_failure_message', {
                email,
            }),
        });
    };

    onEmailSent = (params: {
        ok: boolean;
        email: OutboundEmail;
        dealId: string;
    }) => {
        const { dealId, email } = params;
        this.dealsStore.emailExportData.set(dealId, email);
        this.updateExportedDateInStore(dealId, 'email');
        this.updateDealExported(dealId, ExportedType.Email);
    };

    onMultipleCSVDataLoaded = (params: { id: string; csv: DealExport }[]) => {
        params.forEach(({ id, csv }) => {
            this.updateExportedDateInStore(id, 'csv');
            this.dealsStore.csvExportData.set(id, csv);
        });
    };

    onCSVDataLoaded = ({ id, csv }: { id: string; csv: DealExport }) => {
        this.dealsStore.csvExportData.set(id, csv);
        this.updateExportedDateInStore(id, 'csv');
        this.updateDealExported(id, ExportedType.Csv);
    };

    updateExportedDateInStore = (
        dealId: string,
        exportType: keyof DealExportedDate,
    ) => {
        const deal = this.dealsStore.dealsMap.get(dealId);
        if (!deal) {
            return;
        }
        const { exportedDate } = deal;
        if (exportedDate) {
            exportedDate[exportType] = new Date();
        }
    };

    updateDealExported = (dealId: string, exportedType: ExportedType) => {
        const deal = this.dealsStore.dealsMap.get(dealId);
        if (!deal) {
            return;
        }
        handleRequest(
            this.dealsExportApi.updateDealExported,
            { dealId, exportedType },
            doNothing,
            doNothing,
            (error) =>
                this.baseStore.onRequestFailed('update-deal-exported', error),
        );
    };
}

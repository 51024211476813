import {
    CustomParameter,
    CustomParameterType,
} from 'src/domain/models/custom-parameter/custom-parameter.model';
import { formatDate } from 'src/presentation/shared/input/datetime-input.utils';
import { isNonNullable } from 'src/utils/is-non-nullable.utils';

/** regex for splitting text either on token or on new line */
const splitPattern = /({{.*?}}|\n)/;

/** regex for getting token characteristics */
const parseToken = /{{(.*?)}}/;

/** separating character for context path */
const pathSeparator = '.';

/** separating character for custom field context path
 * ie: {{contact/gender | function}} will search for custom field
 * with id 'gender' in contact
 */
const customFieldSeparator = '/';

type TokenType = 'found' | 'notfound' | 'selection';

/** separating character for fallback
 * ie: {{contact/gender ~ GenderFallback}} will render [GenderFallback]
 * if gender custom field is not defined
 */
const fallbackSeparator = '~';

export interface Token {
    text: string;
    type: TokenType;
    tokenValue: string;
}

interface TokenValue {
    value?: string;
    noCustomValueUsedLabelInstead?: boolean;
}

interface TokenStyle {
    color: string;
    backgroundColor: string;
}

/** color scheme for different token types */
export const TokenStyleMap: Record<TokenType, TokenStyle> = {
    found: {
        color: 'var(--green-80)',
        backgroundColor: 'rgba(169, 239, 188, 0.5)',
    },
    notfound: {
        color: 'var(--red-80)',
        backgroundColor: 'rgba(254, 215, 218, 0.5)',
    },
    selection: {
        color: 'rgba(48, 39, 162, 1)',
        backgroundColor: 'rgba(221, 223, 254, 0.5)',
    },
};

/**
 * return value from object with dot notation string
 * 'contact.firstname' => data['contact']['firstname']
 * @param {string} tokenKey - dot notation key string
 * @param {object} data - data to get value of
 */
const getValue = (tokenKey: string, data: Record<string, any>): TokenValue => {
    if (tokenKey === '') {
        return {
            value: tokenKey,
        };
    }

    const customField = tokenKey.split(customFieldSeparator);
    if (customField.length > 1) {
        const source = data[customField[0]];
        const customFieldId = customField[1];
        return getCustomFieldValue(source, customFieldId);
    }

    const paths = tokenKey.split(pathSeparator);
    const value = paths.reduce((a, v) => a?.[v], data);
    if (typeof value != 'string') {
        return {
            value: undefined,
        };
    }
    return {
        value,
    };
};

const getCustomFieldValue = (
    source: any,
    customFieldId: string,
): TokenValue => {
    if (source && customFieldId) {
        const field = source.customFields?.find(
            ({ name }: { name: string }) => name === customFieldId,
        ) as CustomParameter;
        if (field) {
            if (field.value === '') {
                return {
                    value: field.label,
                    noCustomValueUsedLabelInstead: true,
                };
            } else if (field.value) {
                return {
                    value:
                        field.type === CustomParameterType.ISODATE
                            ? formatDate(field.value) ?? field.value
                            : field.value,
                };
            }
        }
    }

    return {
        value: undefined,
    };
};

/**
 * parse and replace tokens
 * @param {string} text - text to be parsed
 * @param {object} context - context used to fill parsed text
 */
export const getTokenArray = (
    text: string,
    context: Record<string, any>,
    pipes?: Record<string, (value: string) => string>,
): (Token | string)[] => {
    return text.split(splitPattern).map((r) => {
        const match = r.match(parseToken);
        if (match) {
            const tokens = match[1].split('|');

            const tokenParams = tokens[0].trim().split(fallbackSeparator);
            const key = tokenParams[0].trim();
            const fallback = tokenParams[1]?.trim();

            const fn = tokens[1]?.trim();
            const tokenValue = getValue(key, context);
            if (isNonNullable(tokenValue.value) && fn && pipes?.[fn]) {
                tokenValue.value = pipes[fn](tokenValue.value);
            }

            let text = `[${fallback ?? key}]`;
            let type: TokenType = 'notfound';

            if (tokenValue.value) {
                if (tokenValue.noCustomValueUsedLabelInstead) {
                    text = `[${fallback ?? tokenValue.value}]`;
                } else {
                    text = tokenValue.value;
                    type = 'found';
                }
            }

            return {
                text,
                type,
                tokenValue: r,
            };
        }
        return r;
    });
};

export const recreateTemplateFromHtml = (
    html: string,
    tokenMap: Map<string, string>,
) => {
    const htmlText = html.replace(/<br>/g, '\n');
    const element = document.createElement('div');
    element.innerHTML = htmlText;
    const tokens = element.getElementsByClassName('token');
    if (tokens) {
        for (const token of tokens) {
            const tokenId = token.getAttribute('data-tokenId');
            if (tokenId) {
                const tokenValue = tokenMap.get(tokenId);
                if (tokenValue) {
                    token.innerHTML = tokenValue;
                }
            }
        }
    }
    return element.innerText;
};

export const removeTokenStyle = (element: HTMLElement) => {
    element.classList.remove('token');
    element.style.backgroundColor = 'inherit';
    element.style.color = 'inherit';
    element.style.fontWeight = 'normal';
    if (element.parentElement) {
        if (element.parentElement.classList.contains('tooltip')) {
            element.parentElement.style.pointerEvents = 'none';
        }
    }
};

/**
 * Return mutation observer which tracks changes of
 * the child element in the dom of content editable div
 * @param {HTMLElement} element - element to be tracked
 */
export const getMutationObserver = (element: HTMLElement) => {
    const config = {
        childList: true,
        characterData: true,
        characterDataOldValue: true,
        subtree: true,
    };
    const observer = new MutationObserver(function (mutations) {
        mutations.forEach(function (mutation) {
            if (mutation.type === 'characterData') {
                if (element) {
                    observer.disconnect();
                }
            }
        });
    });

    observer.observe(element, config);
    return observer;
};

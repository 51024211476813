import { Tristate } from 'src/data/api/graphql/br_process/generated/graphql-sdk';

export enum SuccessState {
    failure = 'FAILURE',
    success = 'SUCCESS',
    undecided = 'NOT_DECIDED',
}

interface WithSuccessState {
    state: SuccessState;
}

export const SuccessStateModelToRaw: Record<SuccessState, Tristate> = {
    [SuccessState.failure]: Tristate.Failure,
    [SuccessState.success]: Tristate.Success,
    [SuccessState.undecided]: Tristate.NotDecided,
};

export const SuccessStateRawToModel: Record<Tristate, SuccessState> = {
    [Tristate.Failure]: SuccessState.failure,
    [Tristate.Success]: SuccessState.success,
    [Tristate.NotDecided]: SuccessState.undecided,
};

export const isSuccessState = (item: WithSuccessState): boolean =>
    item.state === SuccessState.success;
export const isFailureState = (item: WithSuccessState): boolean =>
    item.state === SuccessState.failure;
export const isUndecidedState = (item: WithSuccessState): boolean =>
    item.state === SuccessState.undecided;

import { IconName } from '@buildingradar/br_component_lib';

import { FilterModuleType as SdkFilterModuleType } from 'src/data/api/graphql/br_search/generated/graphql-sdk';

interface RawFilterModule {
    id: number;
    name: string;
    data: string;
    type: string;
    linkedSearchIds: number[];
    updated: number;
    userId?: number | null;
}

export interface FilterModule {
    id: number;
    name: string;
    data: string;
    type: FilterModuleType;
    linkedSearchIds: number[];
    lastUpdatedDate: Date;
    ownerId: number | null;
}

export const buildFilterModule = (
    rawFilterModule: RawFilterModule,
): FilterModule => {
    const { id, name, type, updated, userId, linkedSearchIds } =
        rawFilterModule;
    return {
        id,
        name,
        data: JSON.stringify(rawFilterModule.data),
        type: FilterModuleSdkToTypeMap[
            type
                .toString()
                .toUpperCase() as keyof typeof FilterModuleSdkToTypeMap
        ],
        linkedSearchIds,
        lastUpdatedDate: new Date(updated),
        ownerId: userId ?? null,
    };
};

export enum FilterModuleType {
    Article = 'article',
    Tender = 'tender',
    General = 'general',
}

export const FilterModuleTypeToSdkMap = {
    [FilterModuleType.Article]: SdkFilterModuleType.Article,
    [FilterModuleType.Tender]: SdkFilterModuleType.Tender,
    [FilterModuleType.General]: SdkFilterModuleType.Generic,
};

const FilterModuleSdkToTypeMap = {
    [SdkFilterModuleType.Article]: FilterModuleType.Article,
    [SdkFilterModuleType.Tender]: FilterModuleType.Tender,
    [SdkFilterModuleType.Generic]: FilterModuleType.General,
};

export const FilterModuleTypeIconMap = {
    [FilterModuleType.Article]: IconName.BrArticleIcon,
    [FilterModuleType.Tender]: IconName.BrDescriptionOutlinedIcon,
    [FilterModuleType.General]: IconName.BrSearchIcon,
};

import { ContactQl } from 'src/data/api/graphql/br_search/generated/graphql-sdk';
import {
    Address,
    createAddress,
    createAddressFromSdk,
    RawAddressData,
} from 'src/domain/models/address/address.model';

export interface RawContact {
    company_name?: string;
    homepage?: string;
    associated_item_id?: string;
    email?: string;
    fax?: string;
    phone_landline?: string;
    phone_mobile?: string;
    address?: RawAddressData;
    company_roles?: string[];
    place_id?: string;
    title?: string;
    alternative_names?: string[];
    company_contacts?: string;
    name?: string;
    person_roles?: string[];
}

export interface ContactPerson {
    name: string;
    personRoles?: string[];
    email?: string;
    phoneLandline?: string;
    phoneMobile?: string;
    fax?: string;
    address?: Address;
}

export interface Contact {
    companyName: string;
    homepage: string;
    associatedItemId?: string;
    email?: string;
    fax?: string;
    personRoles?: string[];
    contactsPage?: string;
    phoneLandline?: string;
    phoneMobile?: string;
    address: Address | null;
    roles?: string[];
    placeId?: string;
    title?: string;
    alternativeNames?: string[];
    contactPage?: string;
    name?: string;
    contactPersons: ContactPerson[];
    companyRoles: string[];
}

const CompanyRoleConstants: Record<string, string> = {
    contract_award_winner: 'lead.contact_role.contract_winner',
    contracting_authority: 'lead.contact_role.contracting_authority',
    project_lead: 'lead.contact_role.project_lead',
    architect: 'lead.contact_role.architect',
    client: 'lead.contact_role.client',
};

const createContact = (rawContact: RawContact): Contact => {
    return {
        companyName: rawContact.company_name ?? '',
        homepage: rawContact.homepage ?? '',
        contactsPage: rawContact.company_contacts,
        associatedItemId: rawContact.associated_item_id,
        email: rawContact.email,
        fax: rawContact.fax,
        phoneLandline: rawContact.phone_landline,
        phoneMobile: rawContact.phone_mobile,
        address: rawContact.address ? createAddress(rawContact.address) : null,
        roles: rawContact.company_roles
            ? getContactRoles(rawContact.company_roles)
            : undefined,
        personRoles: rawContact.person_roles
            ? getContactRoles(rawContact.person_roles)
            : undefined,
        placeId: rawContact.place_id,
        title: rawContact.title,
        alternativeNames: rawContact.alternative_names,
        contactPage: rawContact.company_contacts,
        name: rawContact.name,
        contactPersons: [],
        companyRoles: rawContact.company_roles
            ? getContactRoles(rawContact.company_roles)
            : [],
    };
};

export const createContacts = (rawContacts: RawContact[]): Contact[] => {
    return rawContacts
        .filter((rawContact) => rawContact.company_name)
        .map(createContact);
};

const getContactRoles = (roles: string[]): string[] => {
    const localizedPersonRoles = roles.map((role) => {
        return CompanyRoleConstants[role] ?? role;
    });
    return localizedPersonRoles;
};

export const createContactsFromSdk = (rawContacts: ContactQl[]): Contact[] => {
    return rawContacts
        .filter((rawContact) => rawContact.companyName)
        .map(createContactFromSdk);
};

const createContactFromSdk = (rawContact: ContactQl): Contact => {
    return {
        address: rawContact.address
            ? createAddressFromSdk(rawContact.address)
            : null,
        associatedItemId: rawContact.associatedItemId ?? undefined,
        companyName: rawContact.companyName ?? '',
        roles: rawContact.companyRoles
            ? getContactRoles(rawContact.companyRoles)
            : undefined,
        email: rawContact.email ?? undefined,
        fax: rawContact.fax ?? undefined,
        homepage: rawContact.homepage ?? '',
        name: rawContact.name ?? undefined,
        personRoles: rawContact.personRoles
            ? getContactRoles(rawContact.personRoles)
            : undefined,
        phoneLandline: rawContact.phoneLandline ?? undefined,
        phoneMobile: rawContact.phoneMobile ?? undefined,
        title: rawContact.title ?? undefined,
        companyRoles: [],
        contactPersons: [],
    };
};

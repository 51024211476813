import {
    BrDatePicker,
    BrSelect,
    BrTextInputStatus,
} from '@buildingradar/br_component_lib';
import { observer } from 'mobx-react';
import { useCallback } from 'react';
import { Path, ControllerRenderProps, FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { CUSTOM_PARAMETER_SAVE_DEBOUNCE } from 'src/domain/features/custom-parameters/custom-parameters.utils';
import { CustomParameter } from 'src/domain/models/custom-parameter/custom-parameter.model';
import { Language } from 'src/domain/models/locale/locale.model';
import { StringParameterContainer } from 'src/presentation/modules/deal-view/components/deal-custom-parameter/components/string-parameter/string-parameter.container';
import {
    formatToApiDateFormat,
    getLocaleForDatePicker,
} from 'src/utils/datetime.utils';
import { useDebounceCallback } from 'src/utils/hooks/use-debounce';
import { mapStringsToOptions } from 'src/utils/string.utils';

import { StatusText } from './utils';

const decisionsEnableSearchThreshold = 5;
interface CustomFormFieldsProps<FormType extends FieldValues> {
    field: ControllerRenderProps<FormType, Path<FormType>>;
    autoFocus?: boolean;
    placeholder: string;
    error?: boolean;
    errorText?: string;
    className?: string;
    customField?: CustomParameter;
    status?: BrTextInputStatus;
    onSave?: (field: string, value: string) => void;
    language: Language;
    multiline?: boolean;
}

export const CustomFieldsForm = observer(
    <FormType extends Record<string, any>>({
        field,
        placeholder,
        autoFocus = false,
        error,
        errorText,
        className,
        customField,
        status = 'idle',
        onSave,
        language,
        multiline = false,
    }: CustomFormFieldsProps<FormType>) => {
        const onChange = useCallback(
            (value: string | undefined) => {
                field.onChange(value ?? '');
                onSave?.(field.name, value ?? '');
            },
            [field, onSave],
        );

        const onBlur = useCallback(() => {
            field.onBlur();
        }, [field]);

        const onDateChange = useCallback(
            (value: Date | null) => {
                const formattedDate = value ? formatToApiDateFormat(value) : '';
                field.onChange(formattedDate);
                onSave?.(field.name, formattedDate);
            },
            [field, onSave],
        );
        const { t } = useTranslation();

        const saveDebounced = useDebounceCallback(
            onSave,
            CUSTOM_PARAMETER_SAVE_DEBOUNCE,
            [],
        );
        const onTextChange = useCallback(
            (value: string) => {
                field.onChange(value);
                saveDebounced(field.name, value);
            },
            [field, saveDebounced],
        );

        return (
            <>
                {customField?.type === 'DECISION' && (
                    <BrSelect
                        options={
                            customField.acceptedValues
                                ? mapStringsToOptions(
                                      customField.acceptedValues,
                                  )
                                : []
                        }
                        selectedValue={field.value ?? ''}
                        onSelectionChange={onChange}
                        fullWidth
                        enableSearch={
                            (customField.acceptedValues?.length ?? 0) >=
                            decisionsEnableSearchThreshold
                        }
                        searchLabel={t('common.search')}
                        searchPlaceholder={t('common.search_placeholder')}
                        enableClearButton
                        margin="none"
                        status={status}
                        helperText={
                            error && errorText
                                ? errorText
                                : t(StatusText[status])
                        }
                        autoFocus={autoFocus}
                    />
                )}
                {customField?.type === 'STRING' && (
                    <StringParameterContainer
                        autoFocus={autoFocus}
                        margin="none"
                        status={status}
                        className={className}
                        helperText={
                            error && errorText
                                ? errorText
                                : t(StatusText[status])
                        }
                        placeholder={field.value ? undefined : placeholder}
                        name={field.name}
                        onFocusOut={onBlur}
                        value={field.value}
                        inputRef={(ref) => {
                            field.ref(ref);
                        }}
                        labelShrink={false}
                        setValue={onTextChange}
                        multiline={multiline}
                    />
                )}
                {customField?.type === 'ISODATE' && (
                    <BrDatePicker
                        autoFocus={autoFocus}
                        value={field.value ? new Date(field.value) : undefined}
                        className="picker"
                        id="custom-date-picker"
                        margin="dense"
                        onDateChange={onDateChange}
                        fullWidth
                        locale={getLocaleForDatePicker(language)}
                        status={status}
                        helperText={
                            error && errorText
                                ? errorText
                                : t(StatusText[status])
                        }
                    />
                )}
            </>
        );
    },
);

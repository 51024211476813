import styled from '@emotion/styled';
import { Box, Typography } from '@mui/material';

import { MediaQuery } from 'src/resources/styles/media.mixins';
import { textEllipsisMixin } from 'src/resources/styles/mixins';
import { Spacing } from 'src/resources/styles/spacing';

export const NotificationPanelWrapper = styled.div`
    width: 100%;
    height: 100%;
    background: var(--gray-background);
    max-width: 100%;
    position: relative;
    padding: 12px 32px 24px;
    flex-direction: column;
    display: flex;
    overflow: hidden;

    ${MediaQuery.phone} {
        padding: 12px 24px 24px;
    }
`;

export const NotificationsTitle = styled.h2`
    font-size: 28px;
    font-weight: 500;
    line-height: 33px;
    letter-spacing: 0;
    color: var(--main-font);
    ${textEllipsisMixin}

    ${MediaQuery.tablet} {
        font-size: 20px;
        line-height: 28px;
        letter-spacing: 0.15;
    }
`;

export const NotificationsHeaderStyled = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 48px;
    margin-bottom: 16px;
    flex: none;

    ${MediaQuery.tablet} {
        justify-content: flex-start;
    }
`;

export const CloseMenuWrapperStyled = styled.div`
    margin-right: -10px;
`;

export const CloseNotificationsWrapperStyled = styled.button`
    margin-right: 8px;
`;

export const SectionListTitleStyled = styled(Typography)({
    fontWeight: 'bold',
    fontSize: '14px',
    letterSpacing: '1px',
    lineHeight: '24px',
    marginBottom: Spacing.s1,
    color: 'var(--gray-60)',
});

export const ScrollableListWrapper = styled(Box, {
    shouldForwardProp: (prop) => prop !== 'offset',
})<{
    offset: number;
}>(({ offset }) => ({
    overflowY: 'auto',
    width: '100%',
    height: `calc(100vh - ${offset}px)`,
    overscrollBehavior: 'contain',
}));

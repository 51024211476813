import {
    DevCycleClient,
    DevCycleUser,
    initializeDevCycle as initialize,
} from '@devcycle/js-client-sdk';

import { User } from 'src/domain/models/user/user.model';

import {
    anonymousUser,
    getDevCycleEnvKey,
    getNonAnonymousUser,
} from './feature-flags.utils';

let dvcClient: DevCycleClient | null = null;

const initializeDevCycle = (user: DevCycleUser) => {
    let logLevel = import.meta.env.VITE_FEATURE_FLAGS_LOG_LEVEL;
    if (
        logLevel !== 'debug' &&
        logLevel !== 'info' &&
        logLevel !== 'warn' &&
        logLevel !== 'error'
    ) {
        logLevel = undefined;
    }
    return initialize(getDevCycleEnvKey(), user, {
        enableEdgeDB: true,
        logLevel,
    });
};

export const getDVCClient = () => {
    if (!dvcClient) {
        dvcClient = initializeDevCycle(anonymousUser);
    }
    return dvcClient;
};

export interface FeatureFlagsService {
    logIn: (user: User) => void;
    logOut: () => void;
}

/**
 * Create and return a DevCycle feature flags service.
 *
 * The service is used to log in and log out users. Feature flag resolution is done
 * formally independently of the service with a useFeatureFlag() hook.
 */
export const createFeatureFlagsService = (): FeatureFlagsService => {
    function identify(user?: User) {
        const dvcUser: DevCycleUser = user
            ? getNonAnonymousUser(user)
            : anonymousUser;
        getDVCClient().identifyUser(dvcUser);
    }

    return {
        logIn: (user: User) => {
            identify(user);
        },
        logOut: () => {
            identify();
        },
    };
};

import { FC } from 'react';

import { DEFAULT_SNACKBAR_DURATION } from 'src/data/stores/toaster/toaster.store';

import { BrToastifyStyled } from './toaster.styled';

export const ToasterComponent: FC = () => {
    return (
        <BrToastifyStyled
            autoClose={DEFAULT_SNACKBAR_DURATION}
            position="bottom-left"
        />
    );
};

import { BrIcon, IconName, IconSize } from '@buildingradar/br_component_lib';
import { ReactElement } from 'react';

import { AppRoute } from 'src/presentation/modules/router/app-route.list';

type MobileMenuItemType =
    | 'dashboard'
    | 'screening'
    | 'pipeline'
    | 'marked-done'
    | 'disqualified';

export const mobileMenuTopItems: MobileMenuItemType[] = [
    'dashboard',
    'screening',
    'pipeline',
    'marked-done',
];

export const buildMobileMenuIcons = (
    isActive = false,
): Record<MobileMenuItemType, ReactElement> => ({
    dashboard: (
        <BrIcon
            iconName={IconName.BrLogoIcon}
            iconColor={isActive ? 'var(--blue-60)' : 'var(--gray-80)'}
            iconSize={IconSize.small}
        />
    ),
    screening: (
        <BrIcon
            iconName={IconName.BrInboxIcon}
            iconColor={isActive ? 'var(--blue-60)' : 'var(--gray-80)'}
            iconSize={IconSize.small}
        />
    ),
    pipeline: (
        <BrIcon
            iconName={IconName.BrStarBorderRoundedIcon}
            iconColor={isActive ? 'var(--blue-60)' : 'var(--gray-80)'}
            iconSize={IconSize.large}
        />
    ),
    'marked-done': (
        <BrIcon
            iconName={IconName.BrCheckCircleOutlineIcon}
            iconColor={isActive ? 'var(--blue-60)' : 'var(--gray-80)'}
        />
    ),
    disqualified: (
        <BrIcon
            iconName={IconName.BrCancelOutlineIcon}
            iconColor={isActive ? 'var(--blue-60)' : 'var(--gray-80)'}
        />
    ),
});

export const mobileMenuClassNames: Record<MobileMenuItemType, string> = {
    dashboard: 'home',
    screening: 'screening',
    pipeline: 'pipelines',
    'marked-done': 'marked-done',
    disqualified: 'disqualified',
};

export const mobileMenuText: Record<MobileMenuItemType, string> = {
    dashboard: 'navbar.mobile.dashboard',
    screening: 'screening.title',
    pipeline: 'pipelines.title',
    'marked-done': 'deal_view.state.marked_done',
    disqualified: 'deal_view.state.disqualified',
};

export const mobileMenuRoute: Record<MobileMenuItemType, AppRoute> = {
    dashboard: AppRoute.home,
    screening: AppRoute.screening,
    pipeline: AppRoute.pipelines,
    'marked-done': AppRoute.markedDone,
    disqualified: AppRoute.disqualified,
};

import { BrIcon, IconName } from '@buildingradar/br_component_lib';
import Box from '@mui/material/Box';
import { observer } from 'mobx-react';
import { FC } from 'react';

import { isDateOverdue } from 'src/utils/datetime.utils';

interface ReminderBadgeProps {
    dueDate: Date;
}

export const ReminderBadge: FC<ReminderBadgeProps> = observer(({ dueDate }) => {
    const isOverdue = isDateOverdue(dueDate);

    const backgroundColor = isOverdue ? 'var(--red-20)' : 'var(--blue-20)';
    const iconColor = isOverdue ? 'var(--red-60)' : 'var(--blue-60)';

    return (
        <Box
            sx={{
                width: '40px',
                height: '40px',
                borderRadius: '50%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor,
            }}
        >
            <BrIcon
                iconName={
                    isOverdue
                        ? IconName.BrNotificationsActiveIcon
                        : IconName.BrSignPostIcon
                }
                iconColor={iconColor}
            />
        </Box>
    );
});

import {
    MergedDocument,
    TenderDocument,
} from 'src/app-features/searches-configuration/domain/models/document.model';
import { DocumentType as DocumentTypeForSdk } from 'src/app-features/searches-configuration/domain/models/document.model';
import { TenderDocumentPartFragment } from 'src/data/api/graphql/br_project/generated/graphql-sdk';
import {
    Address,
    createAddress,
    createAddressFromSdk,
    RawAddressData,
} from 'src/domain/models/address/address.model';
import {
    BrDateTime,
    BrDateTimeSdk,
    createNullableBrDateTime,
    createNullableBrDateTimeFromSdk,
    RawBrDateTime,
} from 'src/domain/models/br-time/br-time.model';
import { Category } from 'src/domain/models/category/category.model';
import { RawContact } from 'src/domain/models/contact/contact.model';
import {
    BaseDocument,
    createBaseDocument,
    DocumentType,
    RawBaseDocument,
    Document,
    createBaseDocumentLegacy,
} from 'src/domain/models/document/document.model';
import { ConstructionPhase } from 'src/domain/models/phase/phase.model';
import { ProjectAiGeneratedData } from '../lead/project-ai-generated-data';

export interface RawTender extends RawBaseDocument<DocumentType.Tender> {
    all_lots_awarded: boolean | null;
    announced_date?: RawBrDateTime;
    award_criteria: string | null;
    contract_end_date?: RawBrDateTime;
    contract_start_date?: RawBrDateTime;
    competition_deadline_date_est: boolean;
    competition_deadline_date: null | RawBrDateTime;
    framework_agreement: string | null;
    opening_date?: RawBrDateTime;
    competition_phase: string;
    document_family_id: string;
    document_family_refs: null | string[];
    contract_type: string | null;
    directive: string | null;
    document_type: string | null;
    application_url: string | null;
    contacts: null | RawContact[];
    contract_address: RawAddressData;
    procedure_type: null | string;
    quantity_scope: null | string;
    reference_number: null | string;
    tender_type: null | string;
    awarded_date: null | RawBrDateTime;
    cpv_codes: null | string[];
    naics_codes: null | string[];
    unspsc_codes: null | string[];
    raw_data: unknown;
}

export interface Tender extends BaseDocument<DocumentType.Tender> {
    allLotsAwarded: boolean | null;
    announcedDate: BrDateTimeSdk | BrDateTime | null;
    awardCriteria: string | null;
    type: DocumentType.Tender;
    itemId: string;
    title: string;
    categories: Category[];
    constructionPhases: ConstructionPhase[];
    frameworkAgreement: string | null;
    volumeReference: number | null;
    competitionDeadlineDate: BrDateTimeSdk | BrDateTime | null;
    openingDate: BrDateTimeSdk | BrDateTime | null;
    competitionDeadlineDateEst: boolean;
    contractStartDate: BrDateTimeSdk | BrDateTime | null;
    contractEndDate: BrDateTimeSdk | BrDateTime | null;
    competitionPhase: string;
    contractType: string | null;
    directive: string | null;
    tenderDocType: string | null;
    applicationUrl: string | null;
    address: Address | null;
    awardedDate: BrDateTimeSdk | BrDateTime | null;
    procedureType: string | null;
    quantityScope: string | null;
    referenceNumber: string | null;
    tenderType: string | null;
    cpvCodes: string[] | null;
    naicsCodes: string[] | null;
    unspscCodes: string[] | null;
    rawData: unknown;
}

export const createTenderLegacy = (rawTender: RawTender): Tender => {
    const baseDocument = createBaseDocumentLegacy(rawTender);

    return {
        ...baseDocument,
        allLotsAwarded: rawTender.all_lots_awarded,
        announcedDate: createNullableBrDateTime(
            rawTender.announced_date ?? null,
        ),
        awardCriteria: rawTender.award_criteria,
        competitionDeadlineDate: createNullableBrDateTime(
            rawTender.competition_deadline_date,
        ),
        competitionDeadlineDateEst: rawTender.competition_deadline_date_est,
        contractStartDate: createNullableBrDateTime(
            rawTender.contract_start_date ?? null,
        ),
        contractType: rawTender.contract_type,
        contractEndDate: createNullableBrDateTime(
            rawTender.contract_end_date ?? null,
        ),
        frameworkAgreement: rawTender.framework_agreement,
        competitionPhase: rawTender.competition_phase,
        applicationUrl: rawTender.application_url,
        openingDate: createNullableBrDateTime(rawTender.opening_date ?? null),
        tenderDocType: rawTender.document_type,
        directive: rawTender.directive,
        address: createAddress(rawTender.contract_address),
        awardedDate: createNullableBrDateTime(rawTender.awarded_date),
        procedureType: rawTender.procedure_type ?? null,
        quantityScope: rawTender.quantity_scope,
        referenceNumber: rawTender.reference_number,
        tenderType: rawTender.tender_type,
        cpvCodes: rawTender.cpv_codes ?? null,
        naicsCodes: rawTender.naics_codes ?? null,
        unspscCodes: rawTender.unspsc_codes ?? null,
        rawData: rawTender.raw_data,
    };
};

export const createTender = (
    rawTender: TenderDocumentPartFragment,
    docGroupId: string,
    generatedProjectsData?: Map<string, ProjectAiGeneratedData>,
): Tender => {
    const baseDocument = createBaseDocument(rawTender, DocumentType.Tender);

    const generatedData = generatedProjectsData?.get(docGroupId);

    return {
        ...baseDocument,
        title: generatedData ? generatedData.title : baseDocument.title,
        description: generatedData
            ? generatedData.summary
            : baseDocument.description,
        descriptions: generatedData
            ? [generatedData.summary]
            : baseDocument.descriptions,
        allLotsAwarded: rawTender.allLotsAwarded ?? null,
        announcedDate: createNullableBrDateTimeFromSdk(
            rawTender.announcedDate ?? null,
        ),
        awardCriteria: rawTender.awardCriteria ?? null,
        competitionDeadlineDate: createNullableBrDateTimeFromSdk(
            rawTender.competitionDeadlineDate ?? null,
        ),
        competitionDeadlineDateEst:
            rawTender.competitionDeadlineDateEst ?? false,
        contractStartDate: createNullableBrDateTimeFromSdk(
            rawTender.contractStartDate ?? null,
        ),
        contractType: rawTender.contractType ?? null,
        contractEndDate: createNullableBrDateTimeFromSdk(
            rawTender.contractEndDate ?? null,
        ),
        frameworkAgreement: rawTender.frameworkAgreement ?? null,
        competitionPhase: rawTender.competitionPhase ?? '',
        applicationUrl: rawTender.applicationUrl ?? null,
        openingDate: createNullableBrDateTimeFromSdk(
            rawTender.openingDate ?? null,
        ),
        tenderDocType: rawTender.documentType ?? null,
        directive: rawTender.directive ?? null,
        address: createAddressFromSdk(rawTender.contractAddress ?? null),
        awardedDate: createNullableBrDateTimeFromSdk(
            rawTender.awardedDate ?? null,
        ),
        procedureType: rawTender.procedureType ?? null,
        quantityScope: rawTender.quantityScope ?? null,
        referenceNumber: rawTender.referenceNumber ?? null,
        tenderType: rawTender.tenderType ?? null,
        cpvCodes: rawTender.cpvCodes ?? null,
        naicsCodes: rawTender.naicsCodes ?? null,
        unspscCodes: rawTender.unspscCodes ?? null,
        rawData: rawTender.rawDataString,
    };
};

export const isTender = (
    document: Document | MergedDocument,
): document is Tender | TenderDocument => {
    if (Object.prototype.hasOwnProperty.call(document, 'isSdkInterface')) {
        return isTenderSdk(document as MergedDocument);
    } else {
        return isTenderV1(document as Document);
    }
};

const isTenderV1 = (document: Document): document is Tender =>
    document.type === DocumentType.Tender;

const isTenderSdk = (document: MergedDocument): document is TenderDocument =>
    document.type === DocumentTypeForSdk.Tender;

const procedureTypeMap: Record<string, string> = {
    restricted: 'lead.tender.procedure_type.restricted',
    negotiated: 'lead.tender.procedure_type.negotiated',
    open: 'lead.tender.procedure_type.open',
    direct_award: 'lead.tender.procedure_type.direct_award',
};

export const getReadableProcedureType = (
    procedureType: string | null | undefined,
): string | undefined => {
    if (!procedureType) {
        return undefined;
    }
    return procedureTypeMap[procedureType];
};

const readableTenderDocType: Record<string, string> = {
    public_works_concession: 'lead.tender.doc_type.public_works_concession',
    contract_award_notice: 'lead.tender.doc_type.contract_award_notice',
    results_of_design_contest: 'lead.tender.doc_type.results_of_design_contest',
    contract_notice_utilities: 'lead.tender.doc_type.contract_notice_utilities',
    notice_for_additional_information:
        'lead.tender.doc_type.notice_for_additional_information',
    design_contest_notice: 'lead.tender.doc_type.design_contest_notice',
    periodic_indicative_notice:
        'lead.tender.doc_type.periodic_indicative_notice',
    notice_on_a_buyer_profile: 'lead.tender.doc_type.notice_on_a_buyer_profile',
    qualification_system_utilities:
        'lead.tender.doc_type.qualification_system_utilities',
    prior_information_notice: 'lead.tender.doc_type.prior_information_notice',
    contract_award_notice_utilities:
        'lead.tender.doc_type.contract_award_notice_utilities',
    prior_information_notice_with_call_for_competition:
        'lead.tender.doc_type.prior_information_notice_with_call_for_competition',
    rectification: 'lead.tender.doc_type.rectification',
    contract_notice: 'lead.tender.doc_type.contract_notice',
    services: 'lead.tender.doc_type.services',
    tender_document: 'lead.tender.doc_type.tender_document',
};

export const getReadableTenderDocType = (
    tenderDocType: string | null,
): string | undefined => {
    if (!tenderDocType) {
        return undefined;
    }

    return readableTenderDocType[tenderDocType];
};

import { MenuItemConfiguration } from '@buildingradar/br_component_lib';
import { computed, makeAutoObservable } from 'mobx';
import { getI18n, TFunction } from 'react-i18next';

import { DealContactPerson, PredefinedCustomFields } from 'src/app-features/contact/data/model/deal-contacts.model';
import { IDealContactsStore as IDealContactsStoreNew } from 'src/app-features/contact/data/stores/deal-contacts.store.interface';
import { IAccountConfigurationStore } from 'src/data/stores/account-configuration/account-configuration.store.interface';
import { IQuickActionsStoreInterface } from 'src/data/stores/actions/quick-actions.store.interface';
import { CustomParametersStore } from 'src/data/stores/custom-parameters/custom-parameters.store';
import { DealsStore } from 'src/data/stores/deals/deals.store';
import { LeadsStore } from 'src/data/stores/leads/leads.store';
import { ToasterStore } from 'src/data/stores/toaster/toaster.store';
import { UserStore } from 'src/data/stores/user/user.store';
import { ActionFeaturesIdentifiers } from 'src/domain/features/account-configuration/helpers/action-feature.helper';

import { EmailTemplate } from 'src/domain/models/email-templates/email-templates.model';
import {
    getFullName,
    getUserFullName,
} from 'src/domain/models/user/user.model';
import { createToastForCopyClipboard } from 'src/presentation/shared/web-icons-panel/utils';

import {
    buildContactDataContext,
    buildDealDataContext,
    buildLeadDataContext,
    buildMeDataContext,
} from './data-context/builder';
import { TemplateDataContext } from './data-context/template-data-context';
import { IEmailTemplatesFeature } from './email-templates-interface.feature';
import { copyEmailToClipboard } from './utils';

export class EmailTemplateFeature implements IEmailTemplatesFeature {
    currentDealId?: string;

    t: TFunction<'translation', undefined> = getI18n().t;

    constructor(
        private dealContactsStoreNew: IDealContactsStoreNew,
        private accountConfigurationStore: IAccountConfigurationStore,
        private quickActionStore: IQuickActionsStoreInterface,
        private dealStore: DealsStore,
        private userStore: UserStore,
        private leadStore: LeadsStore,
        private parametersStore: CustomParametersStore,
        private toasterStore: ToasterStore,
    ) {
        makeAutoObservable(this, { templateDataContext: computed.struct });
    }

    get selectableTemplates(): MenuItemConfiguration[] {
        return this.originalTemplates.map(({ name }) => ({
            value: name,
            label: name,
        }));
    }

    get selectableContacts(): MenuItemConfiguration[] {
        const contacts = Array.from(
            this.dealContactsStoreNew.personsMap.values(),
        );
        return contacts.map(({ id, firstName, lastName }) => ({
            value: id,
            label: getFullName(firstName, lastName),
        }));
    }

    get emailAddressForSelectedContact(): string | undefined {
        const selectedContact = this.getSelectedContact();
        if (!selectedContact) {
            return undefined;
        }

        return (
            selectedContact.customFields?.find(
                ({ name }) => name === PredefinedCustomFields.mail,
            )?.value ?? undefined
        );
    }

    get templateDataContext(): TemplateDataContext | undefined {
        const deal = this.currentDealId
            ? this.dealStore.dealsMap.get(this.currentDealId)
            : undefined;
        const assignee = this.getAssignedUserNameAndEmail(
            deal?.assigneeId ?? undefined,
        );
        const lead = deal?.project
            ? this.leadStore.leads.get(deal?.project.id)
            : undefined;
        const customDealParams = Array.from(
            this.parametersStore.parametersMap.values(),
        );

        const dealContext = buildDealDataContext(
            customDealParams,
            deal,
            assignee,
        );
        const contactContext = buildContactDataContext(
            this.getSelectedContact(),
        );
        const leadContext = buildLeadDataContext(lead);
        const meDataContext = buildMeDataContext(this.userStore.user);

        const dataContext = new TemplateDataContext(
            dealContext,
            contactContext,
            leadContext,
            meDataContext,
        );

        return dataContext;
    }

    get originalTemplates(): EmailTemplate[] {
        const emailTemplateConfig =
            this.accountConfigurationStore.getActionFeatureConfigurationByIdentifier(
                ActionFeaturesIdentifiers.email_templates,
            );

        const templates = emailTemplateConfig?.settings
            ? (emailTemplateConfig.settings.templates as EmailTemplate[])
            : [];

        return templates;
    }

    getSelectedContact = (): DealContactPerson | undefined => {
        return Array.from(this.dealContactsStoreNew.personsMap.values()).find(
            ({ id }) => id === this.quickActionStore.selectedEntityId,
        );
    };

    getAssignedUserNameAndEmail = (
        assigneeId?: number,
    ): { name: string; email: string } | undefined => {
        if (!this.userStore.user) {
            return undefined;
        }

        const { colleagues } = this.userStore.user;

        const assignee = colleagues.find(({ itemId }) => itemId === assigneeId);
        if (!assignee) {
            return undefined;
        }

        return {
            name: getUserFullName(assignee),
            email: assignee.email,
        };
    };

    setCurrentDealId = (id?: string) => {
        this.currentDealId = id;
    };

    createEmailForNativeOS = (subject: string, body: string) => {
        document.location.href = `mailto:${
            this.emailAddressForSelectedContact ?? ''
        }?subject=${subject}&body=${encodeURIComponent(body)}`;
    };

    copyEmailToClipboard = (subject: string, body: string) => {
        copyEmailToClipboard(
            this.emailAddressForSelectedContact,
            subject,
            body,
        );
        const toastMessage = createToastForCopyClipboard(
            this.t('actions.email_templates.copied_to_clipboard_message'),
            this.t,
        );

        this.toasterStore.showMessage(toastMessage);
    };
}

import { observer } from 'mobx-react';
import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EnrichmentIterateStateParams } from 'src/app-features/contact-enrichment/domain/contact-enrichment.feature';

import {
    ContactEnrichmentResult,
    DomainEnrichmentOption,
    EnrichmentStep,
} from 'src/app-features/contact-enrichment/domain/contact-enrichment.model';
import {
    createEnrichedDomainPayload,
    createEnrichedDomainMetricsInfoPayload,
} from 'src/app-features/contact-enrichment/domain/utils';
import { DataProviderCardContainer } from 'src/app-features/contact-enrichment/presentation/components/data-provider-card/data-provider-card.container';
import { LushaDataProviderCardInfo } from 'src/app-features/contact-enrichment/presentation/components/data-provider-card/utils';
import { EnrichmentCompanySelectContainer } from 'src/app-features/contact-enrichment/presentation/components/enrichment-company-select/enrichment-company-select.containers';
import { EnrichmentContactSelectContainer } from 'src/app-features/contact-enrichment/presentation/components/enrichment-company-select/enrichment-contact-select.container';
import { EnrichmentExceptionIconComponent } from 'src/app-features/contact-enrichment/presentation/components/enrichment-result/contact-enrichment-exception-icon.component';
import { EnrichmentResultDisplayContainer } from 'src/app-features/contact-enrichment/presentation/components/enrichment-result/enrichment-result-display.container';
import { EnrichmentSearchParametersContainer } from 'src/app-features/contact-enrichment/presentation/components/search-parameters/enrichment-search-parameters.container';
import { SearchRefinementCardContainer } from 'src/app-features/contact-enrichment/presentation/components/search-refinement/search-refinement-card.container';
import { SearchRefinementFormContainer } from 'src/app-features/contact-enrichment/presentation/components/search-refinement/search-refinement-form.container';
import { EnrichmentTargetSelectBox } from 'src/app-features/contact-enrichment/presentation/styles';
import { DealContactCompanyForm } from 'src/app-features/contact/data/model/deal-contacts-form.model';
import { DealContactCompany } from 'src/app-features/contact/data/model/deal-contacts.model';
import { ContactUpdateMetricsInfo } from 'src/app-features/contact/domain/deal-contacts-manage.feature.interface';
import {
    CustomParameter,
    CustomParameterEntityType,
} from 'src/domain/models/custom-parameter/custom-parameter.model';
import { Language } from 'src/domain/models/locale/locale.model';
import { ProgressIndicator } from 'src/presentation/shared/progress-indicator/progress-indicator.component';
import { isNotEmpty } from 'src/utils/type.utils';

interface ContactEnrichmentComponentProps {
    dealId: string;
    currentCompany?: DealContactCompany;
    language?: Language;
    isContactEnrichmentLoading: boolean;
    isDomainEnrichmentLoading: boolean;
    currentStep: EnrichmentStep;
    enrichmentResults: ContactEnrichmentResult;
    domainEnrichmentOptions: DomainEnrichmentOption[];
    iterateState: (params?: EnrichmentIterateStateParams) => void;
    updateContactCompany: (
        dealId: string,
        id: string,
        update: DealContactCompanyForm,
        metricsInfo: ContactUpdateMetricsInfo,
        onSuccess?: (company: DealContactCompany) => void,
    ) => void;
    getCustomParameterByName: (
        name: string,
        type: CustomParameterEntityType,
    ) => CustomParameter | undefined;
}
export const ContactEnrichmentComponent: FC<ContactEnrichmentComponentProps> =
    observer(
        ({
            dealId,
            currentCompany,
            language = Language.En,
            domainEnrichmentOptions,
            isContactEnrichmentLoading,
            isDomainEnrichmentLoading,
            enrichmentResults,
            currentStep,
            iterateState,
            updateContactCompany,
            getCustomParameterByName,
        }) => {
            const haveResults = !!(
                enrichmentResults.emails.length ||
                enrichmentResults.linkedins.length ||
                enrichmentResults.positions.length ||
                enrichmentResults.phones.length
            );
            const { t } = useTranslation();
            const [domainAutoEnriching, setDomainAutoEnriching] =
                useState<boolean>(false);
            const saveCompanyInfoAndEnrich = useCallback(
                (payload: DomainEnrichmentOption, refined?: boolean) => {
                    if (isNotEmpty(payload.domain) && currentCompany) {
                        updateContactCompany(
                            dealId,
                            currentCompany.id,
                            createEnrichedDomainPayload(
                                payload,
                                t,
                                language,
                                getCustomParameterByName,
                                currentCompany.notes,
                            ),
                            createEnrichedDomainMetricsInfoPayload(payload),
                            () => {
                                iterateState({
                                    to: EnrichmentStep.contactEnrichment,
                                    refined,
                                });
                            },
                        );
                    }
                },
                [
                    currentCompany,
                    updateContactCompany,
                    dealId,
                    t,
                    language,
                    getCustomParameterByName,
                    iterateState,
                ],
            );

            const onDomainEnrichmentDone = useCallback(
                (results: DomainEnrichmentOption[]) => {
                    if (
                        results.length === 1 ||
                        (results.length > 1 &&
                            results[0].providers.length >
                                results[1].providers.length)
                    ) {
                        saveCompanyInfoAndEnrich(results[0]);
                    } else {
                        setDomainAutoEnriching(false);
                    }
                },
                [saveCompanyInfoAndEnrich],
            );

            useEffect(() => {
                if (currentStep === EnrichmentStep.domainEnrichment) {
                    setDomainAutoEnriching(true);
                } else {
                    setDomainAutoEnriching(false);
                }
            }, [currentStep]);

            useEffect(() => {
                if (
                    (currentStep === EnrichmentStep.domainEnrichment &&
                        domainEnrichmentOptions.length > 0) ||
                    !isDomainEnrichmentLoading
                ) {
                    onDomainEnrichmentDone(domainEnrichmentOptions);
                }
            }, [
                currentStep,
                domainEnrichmentOptions,
                isDomainEnrichmentLoading,
                onDomainEnrichmentDone,
            ]);

            useEffect(() => {
                iterateState();
            });

            if (currentStep === EnrichmentStep.refinement) {
                return (
                    <SearchRefinementFormContainer
                        saveCompanyInfoAndEnrich={saveCompanyInfoAndEnrich}
                    />
                );
            }

            if (currentStep === EnrichmentStep.contactEnrichment) {
                return (
                    <>
                        <EnrichmentSearchParametersContainer />
                        {isContactEnrichmentLoading ? (
                            <ProgressIndicator
                                fullHeight
                                hint={t(
                                    'actions.contact_enrichment.flyout.loading_hint',
                                )}
                            />
                        ) : (
                            <>
                                {haveResults ? (
                                    <EnrichmentResultDisplayContainer />
                                ) : (
                                    <EnrichmentExceptionIconComponent />
                                )}
                                <DataProviderCardContainer
                                    provider={LushaDataProviderCardInfo}
                                    withDivider={haveResults}
                                />
                            </>
                        )}
                    </>
                );
            }
            if (currentStep === EnrichmentStep.domainEnrichment) {
                return (
                    <>
                        <EnrichmentTargetSelectBox>
                            <EnrichmentContactSelectContainer />
                            <EnrichmentCompanySelectContainer />
                        </EnrichmentTargetSelectBox>
                        {isDomainEnrichmentLoading || domainAutoEnriching ? (
                            <ProgressIndicator
                                fullHeight
                                hint={t(
                                    'actions.contact_enrichment.flyout.loading_hint',
                                )}
                            />
                        ) : (
                            <>
                                <EnrichmentExceptionIconComponent isPaused />
                                <SearchRefinementCardContainer
                                    saveCompanyInfoAndEnrich={
                                        saveCompanyInfoAndEnrich
                                    }
                                />
                            </>
                        )}
                    </>
                );
            }

            return (
                <EnrichmentTargetSelectBox>
                    <EnrichmentContactSelectContainer />
                    <EnrichmentCompanySelectContainer />
                </EnrichmentTargetSelectBox>
            );
        },
    );

import styled from '@emotion/styled';

export const MobileMenuIconStyled = styled.div`
    width: 28px;
    display: flex;
    justify-content: center;
    margin-right: 10px;
`;

export const MobileMenuTextStyled = styled.div<{ isActive: boolean }>`
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0.15px;
    color: ${({ isActive }) =>
        isActive ? 'var(--accent-action-button)' : 'var(--main-font)'};
`;

export const MobileMenuItemStyled = styled.div<{ isActive: boolean }>`
    display: flex;
    align-items: center;
    padding: 8px 12px;
    margin: 0 8px;
    border-radius: 8px;
    background: ${({ isActive }) =>
        isActive ? 'var(--button-bg-disabled)' : 'transparent'};
`;

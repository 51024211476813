import styled from '@emotion/styled';
import { Typography } from '@mui/material';

import { Spacing } from 'src/resources/styles/spacing';

export const TemplateTipsList = styled('ul')({
    padding: `${Spacing.s2} ${Spacing.s3}`,
    '& li': {
        color: 'var(--gray-60)',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '150%',
        '& .hubspot-chat-link': {
            color: 'var(--blue-60)',
            '&:hover': {
                cursor: 'pointer',
            },
        },
    },
});

export const PanelLabelStyled = styled(Typography, {
    shouldForwardProp: (prop) => prop !== 'applyTopMargin',
})<{
    applyTopMargin?: boolean;
    required?: boolean;
}>(({ applyTopMargin, required }) => ({
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '24px',
    color: 'var(--gray-80)',
    marginTop: applyTopMargin ? Spacing.s3 : 0,
    marginBottom: Spacing.s1,

    '&::after': required
        ? {
              content: '"*"',
              color: 'var(--red-60)',
              fontWeight: 'normal',
              marginLeft: '5px',
          }
        : undefined,
}));

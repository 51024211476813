export const GrayIcon = () => {
    return (
        <svg
            width="72"
            height="72"
            viewBox="0 0 72 72"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_806_2501)">
                <path
                    d="M12.9427 40.0518C10.4462 41.4932 9.8488 45.1324 12.0109 48.8772C13.8052 51.985 17.5334 53.2228 19.7936 51.9179L22.7837 50.1916C23.0048 48.4073 22.5472 46.2477 21.378 44.2225C19.2159 40.4777 15.4393 38.6104 12.9427 40.0518Z"
                    fill="url(#paint0_linear_806_2501)"
                />
                <path
                    d="M18.1625 49.0925C16.9142 49.8132 15.099 49.006 14.108 47.2896C13.1171 45.5732 13.3257 43.5976 14.574 42.8769L18.246 40.7568C19.4227 41.5619 20.5258 42.7463 21.378 44.2224C21.8356 45.0149 22.1841 45.828 22.4257 46.6311L18.1625 49.0925Z"
                    fill="url(#paint1_linear_806_2501)"
                />
                <path
                    d="M18.1625 49.0926C18.8594 48.6902 18.8716 47.543 18.3531 46.2805C18.1591 45.8081 18.3024 45.2448 18.7447 44.9895L20.4867 43.9837L22.1178 46.809L18.1625 49.0926Z"
                    fill="url(#paint2_linear_806_2501)"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M20.4711 43.9925C20.589 44.173 20.703 44.3584 20.8129 44.5488C21.2705 45.3412 21.619 46.1543 21.8606 46.9573L20.116 47.9645C19.9052 47.1402 19.5628 46.2983 19.1178 45.5275C19.0053 45.3327 18.8888 45.1487 18.7688 44.9753L20.4711 43.9925Z"
                    fill="url(#paint3_linear_806_2501)"
                />
                <path
                    d="M19.7936 51.9177C21.4888 50.939 21.1242 47.6976 19.6828 45.201C18.2414 42.7045 16.1343 41.976 14.5739 42.8768L18.246 40.7568C19.4226 41.5618 20.5257 42.7462 21.378 44.2223C22.5472 46.2475 23.0048 48.4071 22.7837 50.1914L19.7936 51.9177Z"
                    fill="url(#paint4_linear_806_2501)"
                />
                <path
                    d="M42.722 39.4336C40.5278 39.7587 37.6886 38.9666 35.8869 35.8459C34.0851 32.7252 34.2241 29.2718 36.1973 28.1326L30.1642 31.6158C28.191 32.755 28.052 36.2084 29.8538 39.3291C31.6555 42.4498 34.7157 44.0561 36.6889 42.9168L42.722 39.4336Z"
                    fill="#999999"
                />
                <path
                    d="M22.0992 51.3264C22.8572 49.3281 22.8871 46.8367 21.378 44.2228C19.3722 40.7487 15.977 38.8905 13.5033 39.7907L12.9427 40.052C13.1233 39.9478 13.3105 39.8609 13.5033 39.7907L33.2745 30.5737L40.1258 26.6181L51.6488 17.705C54.1454 16.2636 57.922 18.131 60.0841 21.8758C62.2461 25.6206 61.975 29.8249 59.4784 31.2663L45.6717 36.224L38.8205 40.1796L22.9027 52.0276C22.5962 52.2557 21.9637 51.6837 22.0992 51.3264Z"
                    fill="url(#paint5_linear_806_2501)"
                />
                <path
                    d="M21.8164 50.7504L22.7838 50.1919C23.0049 48.4076 22.5473 46.2481 21.3781 44.2229C20.5258 42.7468 19.4227 41.5624 18.2461 40.7573L17.6813 41.0834C18.8579 41.8884 19.9609 43.0727 20.8131 44.5488C22.1028 46.7826 22.2685 48.9269 21.8164 50.7504Z"
                    fill="url(#paint6_linear_806_2501)"
                />
                <path
                    d="M35.3761 43.6744C36.7602 41.9335 37.4994 39.0754 35.6976 35.9547C33.8959 32.834 30.8308 31.2306 28.8514 32.3734L35.4385 28.5703C37.4178 27.4275 40.483 29.031 42.2847 32.1517C44.0865 35.2724 43.9425 38.7286 41.9632 39.8713L35.3761 43.6744Z"
                    fill="#999999"
                />
                <path
                    d="M36.5065 43.022C37.8907 41.2811 38.6298 38.423 36.8281 35.3023C35.0263 32.1816 31.9612 30.5782 29.9819 31.7209L36.569 27.9179C38.5483 26.7751 41.6134 28.3786 43.4151 31.4993C45.2169 34.62 45.0729 38.0762 43.0936 39.2189L36.5065 43.022Z"
                    fill="url(#paint7_linear_806_2501)"
                />
                <path
                    d="M37.1993 35.0879L42.8499 31.8256L49.9581 44.1373C50.2488 44.6409 49.8279 45.2571 49.2531 45.1696L47.0977 44.8413C46.7771 44.7925 46.4654 44.9724 46.3474 45.2745L45.554 47.3053C45.3424 47.8469 44.5983 47.9032 44.3075 47.3997L37.1993 35.0879Z"
                    fill="url(#paint8_linear_806_2501)"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M39.889 39.7467L37.1993 35.088L42.8498 31.8256L45.5397 36.4847C45.124 37.5517 44.3559 38.4899 43.287 39.107C42.2178 39.7243 41.0212 39.9203 39.889 39.7467Z"
                    fill="#999999"
                />
                <circle
                    cx="39.786"
                    cy="34.348"
                    r="5.21973"
                    transform="rotate(-30 39.786 34.348)"
                    fill="#999999"
                />
                <circle
                    cx="40.0244"
                    cy="33.4567"
                    r="5.21973"
                    transform="rotate(-30 40.0244 33.4567)"
                    fill="url(#paint9_linear_806_2501)"
                />
            </g>
            <defs>
                <linearGradient
                    id="paint0_linear_806_2501"
                    x1="20.7606"
                    y1="50.9829"
                    x2="14.3043"
                    y2="39.8002"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#C1C1C1" />
                    <stop offset="1" stopColor="#A2A2A2" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_806_2501"
                    x1="14.0936"
                    y1="47.3811"
                    x2="20.2917"
                    y2="45.1246"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#C1C1C1" />
                    <stop offset="1" stopColor="#A2A2A2" />
                </linearGradient>
                <linearGradient
                    id="paint2_linear_806_2501"
                    x1="18.8044"
                    y1="46.4966"
                    x2="20.4512"
                    y2="47.9765"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="#E1E1E1" />
                </linearGradient>
                <linearGradient
                    id="paint3_linear_806_2501"
                    x1="20.7632"
                    y1="47.5012"
                    x2="19.226"
                    y2="44.8387"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#DEDEDE" />
                    <stop offset="1" stopColor="#BDBDBD" />
                </linearGradient>
                <linearGradient
                    id="paint4_linear_806_2501"
                    x1="17.9282"
                    y1="45.3245"
                    x2="21.4868"
                    y2="51.0975"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="#E1E1E1" />
                </linearGradient>
                <linearGradient
                    id="paint5_linear_806_2501"
                    x1="32.6705"
                    y1="31.4857"
                    x2="40.0285"
                    y2="53.9608"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="#E1E1E1" />
                </linearGradient>
                <linearGradient
                    id="paint6_linear_806_2501"
                    x1="22.9773"
                    y1="48.9502"
                    x2="17.5944"
                    y2="39.6268"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#DEDEDE" />
                    <stop offset="1" stopColor="#BDBDBD" />
                </linearGradient>
                <linearGradient
                    id="paint7_linear_806_2501"
                    x1="46.5747"
                    y1="27.5618"
                    x2="50.1679"
                    y2="35.352"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#C4C4C4" />
                    <stop offset="1" stopColor="#B3B3B3" />
                </linearGradient>
                <linearGradient
                    id="paint8_linear_806_2501"
                    x1="47.9303"
                    y1="35.3974"
                    x2="51.1981"
                    y2="44.9974"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#B3B3B3" />
                    <stop offset="1" stopColor="#B3B3B3" />
                </linearGradient>
                <linearGradient
                    id="paint9_linear_806_2501"
                    x1="40.4312"
                    y1="31.3398"
                    x2="37.9889"
                    y2="38.0109"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#D9D9D9" />
                    <stop offset="1" stopColor="#A6A6A6" />
                </linearGradient>
                <clipPath id="clip0_806_2501">
                    <rect width="72" height="72" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

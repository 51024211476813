import styled from '@emotion/styled';
import { Box } from '@mui/material';

import { Spacing } from 'src/resources/styles/spacing';
import { ZIndex } from 'src/resources/styles/zIndex';

import { ImpersonationHeaderHeight } from './impersonation-global-styles';

export const ImpersonationHeaderStyled = styled(Box)({
    height: ImpersonationHeaderHeight,
    backgroundColor: 'var(--undecided-color)',
    padding: Spacing.s1_5,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'sticky',
    top: 0,
    zIndex: ZIndex.zDrawer,
});

export const ImpersonationInputContainer = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    gap: Spacing.s1_5,

    '.MuiButton-root:not(:hover)': {
        backgroundColor: 'var(--building-radar-color)',
    },

    '.MuiOutlinedInput-notchedOutline': {
        borderColor: 'var(--white) !important',
    },
});

export const ImpersonationSwitchContainer = styled(Box)({
    width: 300,
});

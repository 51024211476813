import { AbortParams } from 'src/data/api/api-client';
import { UserGqlSdk } from 'src/data/api/graphql/graphql-client.wrapper';
import {
    Language,
    languageToBrUserLanguage,
} from 'src/domain/models/locale/locale.model';

interface SetLanguageParams extends AbortParams {
    language: Language;
}
export interface LocalizeService {
    setLanguage: (params: SetLanguageParams) => Promise<boolean>;
}

export const createLocalizeService = (
    userGqlSdk: UserGqlSdk,
): LocalizeService => {
    const setLanguage = async ({ language, signal }: SetLanguageParams) => {
        return (
            await userGqlSdk.UpdateUserLanguage({
                language: languageToBrUserLanguage[language],
            })
        ).updateUserLanguage.ok;
    };

    return {
        setLanguage,
    };
};

/**
 * A typesafe function to take a value by a key from an object
 * @param key
 *
 * @Exapmple
 * const userIds: string[] = users.map(pluck('id'))
 *
 * where user: User[]
 *
 * User {
 *  id: string;
 * }
 *
 */
export const pluck =
    <T extends object, K extends keyof T>(key: K) =>
    (obj: T): T[K] =>
        obj[key];

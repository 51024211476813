export enum ZIndex {
    z0 = 0,
    z1 = 1,
    zDealNavTabContent = 2,
    zProjectLookupInput = 2,
    z3 = 3,
    zCommentEditor = 5,
    zStickyDealHeader = 4,
    // zCommentEditorMentionPopper = 10,    // added in css file, can't use this variable. kept here for reference
    zDealBlockOverflow = 10,
    zModal = 10,
    zPopper = 12,
    zSheetMenuDrawer = 900,
    // zSwipeableDrawer = 1199, // Default drawer in library that renders the deal buckets
    zPrimaryNavBar = 1200, // needed for covering modal backdrop
    /**
     * Tooltips normall have higher z index than drawer and comp. lib. modal
     * We can use this z-index to hide the tooltips that should not be visible when there is a modal open
     */
    zObscuredTooltip = 1250,
    zDrawer = 1300, // Default drawer in material ui e.g. notification
    zDrawerAsSecondLayer = 1350,
    zHubspot = 1400,
    zBackdrop = 1500,
    zModalBackdrop = 1600,
}

import { makeAutoObservable } from 'mobx';

import { IPipelineStatisticsAPI } from 'src/app-features/pipeline-statistics/data/api/pipeline-statistics.api';
import { PipelineStatisticsFragment } from 'src/data/api/graphql/br_process/generated/graphql-sdk';
import { MixpanelEventName } from 'src/data/services/mixpanel/mixpanel.model';
import { MixpanelService } from 'src/data/services/mixpanel/mixpanel.service';
import { handleRequest } from 'src/utils/handle-request.utils';

import { IPipelineStatisticsFeature } from './pipeline-statistics.feature.interface';

export class PipelineStatisticsFeature implements IPipelineStatisticsFeature {
    pipelineStatistics: PipelineStatisticsFragment | null = null;
    isLoading = false;

    setIsLoading = (isLoading: boolean) => {
        this.isLoading = isLoading;
    };

    onGetPipelineStatistics = (
        pipelineStatistics: PipelineStatisticsFragment | null,
    ) => {
        this.pipelineStatistics = pipelineStatistics;
    };

    getPipelineStatistics = (pipelineId: string, forCurrentUser: boolean) => {
        handleRequest(
            this.pipelineStatisticsApi.getPipelineStatistics,
            { forCurrentUser, pipelineId },
            this.onGetPipelineStatistics,
            this.setIsLoading,
        );
    };

    trackEvent = (event: MixpanelEventName) => {
        this.mixpanelService.trackEvent(event);
    };

    constructor(
        private pipelineStatisticsApi: IPipelineStatisticsAPI,
        private mixpanelService: MixpanelService,
    ) {
        makeAutoObservable(this);
    }
}

import { t } from 'i18next';
import { makeAutoObservable } from 'mobx';
import { TFunction, getI18n } from 'react-i18next';

import { IDeveloperApiKeysApi } from 'src/app-features/account-developer-tools/data/api/developer-api-keys.api';
import { IBaseStore } from 'src/data/stores/shared/base.store.interface';
import { handleRequest } from 'src/utils/handle-request.utils';

import { DeveloperApiKey } from './developer-api-key.model';

export interface IAccountDeveloperToolsFeature {
    apiKeys?: DeveloperApiKey[];
    isLoading: boolean;
    lastPlainTextKeyCreated?: string;
    isKeyCreationModalOpened: boolean;
    keyToDelete?: DeveloperApiKey;
    setKeyToDelete: (key?: DeveloperApiKey) => void;
    setIsKeyCreationModalOpened: (flag: boolean) => void;
    fetchCurrentUserApiKeys: () => void;
    createNewApiKey: (keyName: string) => void;
    deleteApiKey: (id: number) => void;
}

export class AccountDeveloperToolsFeature
    implements IAccountDeveloperToolsFeature
{
    t: TFunction<'translation', undefined> = getI18n().t;
    apiKeys?: DeveloperApiKey[] = undefined;
    isLoading = false;
    lastPlainTextKeyCreated?: string = undefined;
    isKeyCreationModalOpened = false;
    keyToDelete?: DeveloperApiKey = undefined;

    constructor(
        private developerApiKeysApi: IDeveloperApiKeysApi,
        private baseStore: IBaseStore,
    ) {
        makeAutoObservable(this);
    }

    setIsLoadingStatus = (flag: boolean) => {
        this.isLoading = flag;
    };

    setIsKeyCreationModalOpened = (flag: boolean) => {
        this.isKeyCreationModalOpened = flag;

        if (this.lastPlainTextKeyCreated) {
            this.lastPlainTextKeyCreated = undefined;
        }
    };

    setKeyToDelete = (key?: DeveloperApiKey) => {
        this.keyToDelete = key;
    };

    fetchCurrentUserApiKeys = () => {
        const onApiKeysLoaded = (keys: DeveloperApiKey[]) => {
            this.apiKeys = keys.sort((a, b) =>
                b.createdOn.localeCompare(a.createdOn),
            );
        };

        /**
         * Only fetch the api key once. After that, we only update the api keys
         * array when adding or removing a key
         */
        if (!this.apiKeys) {
            handleRequest(
                this.developerApiKeysApi.myKeys,
                {},
                onApiKeysLoaded,
                this.setIsLoadingStatus,
                (error) =>
                    this.baseStore.onRequestFailed(
                        'get-current-user-api-keys',
                        error,
                    ),
            );
        }
    };

    createNewApiKey = (keyName: string) => {
        const onApiKeyAdded = (newApiKey: DeveloperApiKey) => {
            this.lastPlainTextKeyCreated = newApiKey.plainTextKey;
            newApiKey.plainTextKey = undefined;

            this.apiKeys = [newApiKey, ...(this.apiKeys ?? [])];
        };

        const onApiKeyCreationFailed = (error?: Error) => {
            if (error) {
                this.setIsKeyCreationModalOpened(false);
            }

            this.baseStore.onRequestFailed('create-new-api-key', error, {
                errorTitle: t(
                    'account.developer_tools.api_keys.creation_failed.title',
                ),
                errorMessage: t(
                    'account.developer_tools.api_keys.creation_failed.message',
                ),
            });
        };

        const trimmedKey = keyName.trim();

        handleRequest(
            this.developerApiKeysApi.createNew,
            { keyName: trimmedKey },
            onApiKeyAdded,
            this.setIsLoadingStatus,
            onApiKeyCreationFailed,
        );
    };

    deleteApiKey = (id: number) => {
        const onApiKeyDeleted = (id: number) => {
            this.apiKeys = this.apiKeys?.filter((key) => key.id !== id);
            this.setKeyToDelete(undefined);
        };

        handleRequest(
            this.developerApiKeysApi.deleteKey,
            { id },
            () => onApiKeyDeleted(id),
            this.setIsLoadingStatus,
            (error) => this.baseStore.onRequestFailed('delete-api-key', error),
        );
    };
}

import styled from '@emotion/styled';
import { Grid } from '@mui/material';
import MuiDialog from '@mui/material/Dialog';

import { Spacing } from 'src/resources/styles/spacing';

export const TextStyled = styled.div`
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: 0.15px;
    width: 284px;
    color(--main-font);
`;

export const WarnIconWrapStyled = styled.div`
    margin-right: ${Spacing.s1};
`;

export const TitleWrapStyled = styled(Grid)`
    button .material-icons {
        color: var(--mid-gray);
    }
    & .MuiGrid-item {
        display: flex;
    }
`;

export const DescriptionWrapStyled = styled(Grid)`
    display: flex;
    margin-top: 16px;
`;

export const DescriptionTextStyled = styled(Grid)`
    font-style: normal;
    font-size: 16px;
    color(--main-font);
`;

export const ButtonGroupStyled = styled(Grid)`
    margin-top: 16px;
    button:first-child {
        margin-right: 8px;
    }
    & .MuiGrid-item {
        display: flex;
        justify-content: flex-end;
    }
`;

export const Dialog = styled(MuiDialog)({
    root: {
        maxWidth: '100%',
    },
});

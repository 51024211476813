import { SearchType as SdkSearchType } from 'src/data/api/graphql/br_search/generated/graphql-sdk';
import { FilterModuleType } from 'src/domain/models/filter-module/filter-module.model';

import { FilterOperation } from './filter-module-config-option';

export enum SearchType {
    Article = 'article',
    Tender = 'tender',
}

export const SearchTypeToSdkMap = {
    [SearchType.Article]: SdkSearchType.Article,
    [SearchType.Tender]: SdkSearchType.Tender,
};

export const SdkToSearchTypeMap = {
    [SdkSearchType.Article]: SearchType.Article,
    [SdkSearchType.Tender]: SearchType.Tender,
};

export interface ConfiguredSearch {
    id: number;
    name: string;
    type: SearchType;
    ownerId: number;
    filters: AppliedFilterModule[];
}

export interface AppliedFilterModule {
    /**
     * Id of the filter module
     */
    id: number;
    /**
     * Name of the filter module
     */
    name: string;
    /**
     * Type of the filter module
     */
    type: FilterModuleType;
    /**
     * Filter module query operation
     * */
    filterOperation: FilterOperation.and;
    /**
     * An object that contains the filter criteria to be sent to the backend.
     * It's in the format of:
     * {criteriaKey: [{key: criteriaKey, filter: filterType, value: criteriaValue}]}
     * {"name":[{"key":"Test Cesar","filter":"or","value":"Test Cesar"}]}
     */
    searchCriteria: SearchFilterCriteria;
}

export interface FilterCriteria {
    key: string;
    filter: string;
    value: string | (number | null)[];
    [anyKey: string]: string | (number | null)[] | undefined;
}

export interface SearchFilterCriteria {
    [key: string]: FilterCriteria[];
}

export interface InMemoryFilterCriteria {
    filterId: number;
    searchCriteria: SearchFilterCriteria;
}

export interface DraftManipulatedFilters {
    searchId: number;
    unlinkedIds: number[];
    linkedIds: number[];
    initialLinkedIds?: number[];
}

import {
    AvatarData,
    AvatarSizes,
    BrAvatar,
    BrIcon,
    IconName,
} from '@buildingradar/br_component_lib';
import { FC } from 'react';

import { DealContactType } from 'src/app-features/contact/data/model/deal-contacts.model';

import { CrownFilledProfileAvatarStyled } from './styles';

interface OverrideIconData {
    iconName: IconName;
    infotip: string;
}

interface ContactAvatarProps {
    avatarData: AvatarData;
    archived: boolean;
    contactType: DealContactType.company | DealContactType.person;
    overrideIcon?: OverrideIconData;
}

const ColorsMap = {
    [DealContactType.person]: {
        avatarBackgroundColor: 'var(--white)',
        avatarBorderColor: 'var(--blue-30)',
        avatarColor: 'var(--blue-60)',
    },
    [DealContactType.company]: {
        avatarBackgroundColor: 'var(--white)',
        avatarBorderColor: 'var(--magenta-medium)',
        avatarColor: 'var(--magenta-dark)',
    },
    archived: {
        avatarBackgroundColor: 'var(--white)',
        avatarBorderColor: 'var(--gray-30)',
        avatarColor: 'var(--gray-60)',
    },
};

export const ContactCardAvatar: FC<ContactAvatarProps> = ({
    avatarData,
    contactType,
    overrideIcon,
    archived,
}) => {
    const colorStyles =
        ColorsMap[archived === false ? contactType : 'archived'];

    return (
        <>
            <BrAvatar
                avatarData={avatarData}
                backgroundColor={colorStyles.avatarBackgroundColor}
                borderColor={colorStyles.avatarBorderColor}
                color={colorStyles.avatarColor}
                fontWeight={500}
                hasBorder
                fontSize={16}
                size={AvatarSizes.l}
            />
            {overrideIcon && (
                <CrownFilledProfileAvatarStyled>
                    <BrIcon
                        iconSize={20}
                        iconName={overrideIcon.iconName}
                        iconColor="var(--white)"
                    />
                </CrownFilledProfileAvatarStyled>
            )}
        </>
    );
};

import { BrTextInputStatus } from '@buildingradar/br_component_lib';
import { useEffect, useRef, useState } from 'react';

export const useAnimationStatus = (status: BrTextInputStatus) => {
    const [state, setState] = useState<BrTextInputStatus>(status);
    const timeout1 = useRef<any>();
    const timeout2 = useRef<any>();

    useEffect(() => {
        if (status !== 'progress') {
            //progress animation should be visible at least 500ms
            timeout1.current = setTimeout(() => setState(status), 500);

            if (status === 'success') {
                //success animation should disappear after 1500-500 = 1000 ms
                timeout2.current = setTimeout(() => setState('idle'), 1500);
            }
        } else {
            clearTimeout(timeout1.current);
            clearTimeout(timeout2.current);
            setState(status);
        }
    }, [status]);

    return state;
};

import {
    BrIcon,
    BrSelect,
    IconName,
    MenuItemConfigurationV2,
} from '@buildingradar/br_component_lib';
import { observer } from 'mobx-react';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { DealContactCompany } from 'src/app-features/contact/data/model/deal-contacts.model';
import {
    DataProviderLogo,
    DataProviderSource,
} from 'src/app-features/contact-enrichment/domain/contact-enrichment.model';
import {
    GoogleEnrichmentData,
    GoogleEnrichmentResultKey,
} from 'src/app-features/google-enrichment/data/google-enrichment.model';
import { ActionPanelLabelStyled } from 'src/presentation/modules/enablement-actions-panel/actions/styled';
import { LoadingSpinner } from 'src/presentation/shared/loading-spinner/loading-spinner';

import { GoogleEnrichmentInfoField } from './company-field/google-enrichment-info-field';
import { GoogleEnrichmentCompanySelectContainer } from './google-enrichment-company-select.container';
import {
    EmptyStateStyled,
    EmptyStateWrapper,
    GogoleEnrichmentResultsTitle,
    GoogleEnrichmentContentStyled,
    GoogleEnrichmentResultsWrapper,
    GoogleImageStyled,
    LoadingIndicaterWrapper,
} from './styles';

interface GoogleEnrichmentContentComponentProps {
    dealId: string;
    selectedCompany?: DealContactCompany;
    isGoogleEnrichmentLoading: boolean;
    isPossibleCompanyMatchesLoading: boolean;
    enrichmentData: GoogleEnrichmentData | null;
    enrichmentOptions: MenuItemConfigurationV2[];
    selectedEnrichmentOption: MenuItemConfigurationV2 | null;
    selectedData: Map<GoogleEnrichmentResultKey, string>;
    customFields: string[];
    selectDataToSave: (key: GoogleEnrichmentResultKey, value?: string) => void;
    getEnrichmentData: (placeId: string) => void;
    getCompanyLocations: (dealId: string, name: string) => void;
    setSelectedEnrichmentOption: (id?: string) => void;
}
export const GoogleEnrichmentContentComponent: FC<GoogleEnrichmentContentComponentProps> =
    observer(
        ({
            selectedData,
            selectedCompany,
            dealId,
            isGoogleEnrichmentLoading,
            isPossibleCompanyMatchesLoading,
            enrichmentData,
            enrichmentOptions,
            selectedEnrichmentOption,
            customFields,
            selectDataToSave,
            getEnrichmentData,
            getCompanyLocations,
            setSelectedEnrichmentOption,
        }) => {
            useEffect(() => {
                if (selectedCompany?.id) {
                    getCompanyLocations(dealId, selectedCompany.name);
                }
            }, [
                getCompanyLocations,
                selectedCompany?.id,
                dealId,
                selectedCompany?.name,
            ]);

            useEffect(() => {
                if (selectedEnrichmentOption) {
                    getEnrichmentData(selectedEnrichmentOption.value);
                }
            }, [getEnrichmentData, selectedEnrichmentOption]);

            const { t } = useTranslation();
            const showLoadingIndicator =
                isPossibleCompanyMatchesLoading ||
                isGoogleEnrichmentLoading ||
                (selectedEnrichmentOption && !enrichmentData);
            const noResultFound =
                (!enrichmentData ||
                    enrichmentData.results.length === 0 ||
                    enrichmentOptions.length === 0) &&
                !isGoogleEnrichmentLoading;

            return (
                <>
                    <GoogleEnrichmentCompanySelectContainer dealId={dealId}/>
                    {showLoadingIndicator && (
                        <LoadingIndicaterWrapper>
                            <LoadingSpinner />
                        </LoadingIndicaterWrapper>
                    )}
                    {!showLoadingIndicator && selectedCompany && (
                        <GoogleEnrichmentContentStyled>
                            {/** only show dropdown for options when there's more than 1 matches*/}
                            {enrichmentOptions.length > 1 && (
                                <>
                                    <ActionPanelLabelStyled>
                                        {t(
                                            'actions.google_enrichment.option_select_label',
                                            {
                                                value: enrichmentOptions.length,
                                            },
                                        )}
                                    </ActionPanelLabelStyled>
                                    <BrSelect
                                        options={enrichmentOptions}
                                        selectedValue={
                                            selectedEnrichmentOption?.value
                                        }
                                        fullWidth
                                        margin="none"
                                        onSelectionChange={(item) =>
                                            setSelectedEnrichmentOption(item)
                                        }
                                    />
                                </>
                            )}

                            {noResultFound && (
                                <EmptyStateWrapper>
                                    <EmptyStateStyled>
                                        <BrIcon
                                            iconName={IconName.BrSearchIcon}
                                        />
                                    </EmptyStateStyled>
                                    {t(
                                        'actions.google_enrichment.no_result_text',
                                    )}
                                </EmptyStateWrapper>
                            )}
                            {!noResultFound && enrichmentData && (
                                <GoogleEnrichmentResultsWrapper>
                                    <GogoleEnrichmentResultsTitle>
                                        {t(
                                            'actions.google_enrichment.results.title',
                                        )}
                                    </GogoleEnrichmentResultsTitle>
                                    <GoogleImageStyled
                                        key={DataProviderSource.Google}
                                        alt={DataProviderSource.Google}
                                        src={
                                            DataProviderLogo[
                                                DataProviderSource.Google
                                            ]
                                        }
                                    />
                                    {enrichmentData.results.map(
                                        ({ key, value }) => (
                                            <GoogleEnrichmentInfoField
                                                className={key}
                                                config={key}
                                                key={key}
                                                value={value}
                                                hasCustomFieldMapping={customFields.includes(
                                                    key,
                                                )}
                                                checked={
                                                    !!selectedData.get(key)
                                                }
                                                onCheckedChange={(checked) => {
                                                    selectDataToSave(
                                                        key,
                                                        checked
                                                            ? value
                                                            : undefined,
                                                    );
                                                }}
                                            />
                                        ),
                                    )}
                                </GoogleEnrichmentResultsWrapper>
                            )}
                        </GoogleEnrichmentContentStyled>
                    )}
                </>
            );
        },
    );

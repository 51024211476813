import { FC } from 'react';

import { FoldersSectionContainer } from './components/folders-section/folders-section.container';
import { OtherSectionContainer } from './components/other-section/other-section.container';
import { SubMenuContainer } from './components/sub-menu/sub-menu.container';
import {
    PrimaryNavbarStyled,
    PrimaryNavbarTopSection,
} from './primary-nav-bar.styled';

export const PrimaryNavbarComponent: FC = () => {
    return (
        <PrimaryNavbarStyled className="primary-nav-bar">
            <PrimaryNavbarTopSection>
                <FoldersSectionContainer />
                <SubMenuContainer />
            </PrimaryNavbarTopSection>
            <OtherSectionContainer />
        </PrimaryNavbarStyled>
    );
};

import { IContactDataContext } from './contact-data-context';
import { IDealDataContext } from './deal-data-context';
import { ILeadDataContext } from './lead-data-context';
import { IMeDataContext } from './me-data-context';

export class TemplateDataContext {
    deal?: IDealDataContext;
    contact?: IContactDataContext;
    lead?: ILeadDataContext;
    me?: IMeDataContext;

    constructor(
        deal?: IDealDataContext,
        contact?: IContactDataContext,
        lead?: ILeadDataContext,
        me?: IMeDataContext,
    ) {
        this.deal = deal;
        this.contact = contact;
        this.lead = lead;
        this.me = me;
    }
}

import { BrTextInputStatus } from '@buildingradar/br_component_lib';
import styled from '@emotion/styled';
import { Box } from '@mui/material';

export const TextPreviewStyled = styled(Box, {
    shouldForwardProp: (prop) => prop !== 'isHighlighted',
})<{
    isHighlighted?: boolean;
    status?: BrTextInputStatus;
    multiline?: boolean;
}>(({ isHighlighted, status, multiline }) => ({
    minWidth: 0,
    fontWeight: '400',
    fontSize: '1rem',
    lineHeight: '1.4375em',
    letterSpacing: '0.00938em',
    color: 'var(--gray-70)',
    boxSizing: 'border-box',
    cursor: 'text',
    width: '100%',
    position: 'relative',
    padding: '8.5px 22px 8.5px 14px',
    flex: 1,
    boxShadow: isHighlighted
        ? '0px 0px 9px 0px rgb(84 85 251 / 20%)'
        : undefined,
    wordBreak: 'break-word',
    maxWidth: multiline ? undefined : '100%',
    maxHeight: multiline ? 'auto' : '40px',
    overflowX: multiline ? 'auto' : 'hidden',
    whiteSpace: multiline ? 'normal' : 'nowrap',
    textOverflow: multiline ? 'wrap' : 'ellipsis',
    ':after': {
        content: '""',
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        borderWidth: '1px',
        borderStyle: 'solid',
        borderRadius: '4px',
        borderColor: (() => {
            switch (status) {
                case 'progress':
                    return 'var(--blue-60)';
                case 'success':
                    return 'var(--green-30)';
                case 'error':
                    return 'var(--red-60)';
                default:
                    return isHighlighted ? 'var(--blue-30)' : 'var(--gray-20)';
            }
        })(),
        transition: 'border-color 0.3s',
    },

    ':hover': {
        ':after': {
            borderColor: status === 'idle' ? 'var(--gray-30)' : undefined,
        },
    },
}));

export const LinkStyled = styled('a')({
    color: 'var(--blue-60)',
    cursor: 'pointer',
    position: 'inherit',
    zIndex: 1,
    '&:hover': {
        textDecoration: 'underline',
    },
});

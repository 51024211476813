import { withFeatures } from 'src/utils/component.utils';

import { EnablementActionsPanelComponent } from './enablement-actions-panel.component';

export const EnablementActionsPanelContainer = withFeatures(
    EnablementActionsPanelComponent,
)((f) => ({
    isOpened: f.quickActionsFeature.isQuickActionsPanelOpen,
    action: f.quickActionsFeature.currentQuickAction,
    selectedEntityId: f.quickActionsFeature.selectedEntityId,
    selectedEntity: f.quickActionsFeature.selectedEntity,
    cleanAction: f.quickActionsFeature.clean,
}));

import { BrText, BrTextInputStatus } from '@buildingradar/br_component_lib';
import { TFunction } from 'react-i18next';

import {
    DealContactToEdit,
    DealContactType,
} from 'src/app-features/contact/data/model/deal-contacts.model';

import { DealContactFormContainer } from './components/deal-contact-form.container';
import { DealContactNoteFormComponent } from './components/deal-contact-note-form.component';
import { CompanyFormConfig, PersonFormConfig } from './form-utils/form-config';
import { ContactDetailPanelTabContentStyled } from './styles';

export enum ContactPanelTabValue {
    detail = 'detail',
    notes = 'notes',
}

interface TabConfigPayload {
    isEditMode: boolean;
    selectedContact: DealContactToEdit;
    dealId: string;
    noteStatus?: BrTextInputStatus;
    formConfig: (CompanyFormConfig | PersonFormConfig)[];
    t: TFunction;
    onSave: (field: string, value: string) => void;
    onLinkClicked: (link: string) => void;
}

export const getContactPanelTabConfigurations = ({
    isEditMode,
    selectedContact,
    dealId,
    noteStatus,
    formConfig,
    onLinkClicked,
    t,
    onSave,
}: TabConfigPayload) => {
    const focusField =
        selectedContact?.focusField ??
        (selectedContact.type === DealContactType.person
            ? 'firstName'
            : 'name');
    return [
        {
            tabValue: ContactPanelTabValue.detail,
            label: (
                <BrText size={16} weight={500}>
                    {isEditMode
                        ? t('contact_panel.tabs.details')
                        : t('contact_panel.tabs.add_details')}
                </BrText>
            ),
            content: (
                <ContactDetailPanelTabContentStyled>
                    <DealContactFormContainer
                        type={selectedContact.type}
                        formConfig={formConfig}
                        dealId={dealId}
                        isEditMode={isEditMode}
                        onSave={onSave}
                        focusField={focusField}
                    />
                </ContactDetailPanelTabContentStyled>
            ),
        },
        {
            tabValue: ContactPanelTabValue.notes,
            label: (
                <BrText size={16} weight={500}>
                    {isEditMode
                        ? t('contact_panel.tabs.notes')
                        : t('contact_panel.tabs.add_notes')}
                </BrText>
            ),
            content: (
                <ContactDetailPanelTabContentStyled>
                    <DealContactNoteFormComponent
                        onSave={onSave}
                        setClickedLink={onLinkClicked}
                        status={noteStatus}
                    />
                </ContactDetailPanelTabContentStyled>
            ),
        },
    ];
};

export const buildUpdateObject = (
    selectedContact: DealContactToEdit,
    field: string,
    value: string,
) => {
    const updateObject: any = {};
    if (field.startsWith('customFields')) {
        const fieldAreas = field.split('.');
        const index = +fieldAreas[1];
        const fieldName = selectedContact.customFields?.[index].name;
        const fieldValue = selectedContact.customFields?.[index].value;
        if (fieldValue !== value) {
            updateObject['customInput'] = {
                customValues: [{ name: fieldName, value }],
            };
        }
    } else {
        const hasChangedValue = !!Object.entries(selectedContact).find(
            (entry) => entry[0] === field && entry[1] !== value,
        );
        if (hasChangedValue) {
            updateObject[field] = value;
            if (field === 'role' && value !== '') {
                updateObject['isKey'] = true;
            }
        }
    }
    return updateObject;
};

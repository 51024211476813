import { makeAutoObservable } from 'mobx';

import { AchievementService } from 'src/app-features/achievements/domain/service/achievements.service';
import { DealsApi } from 'src/data/api/deal/deal.api';
import { UserAchievementName } from 'src/data/api/graphql/br_process/generated/graphql-sdk';
import { MixpanelEventName } from 'src/data/services/mixpanel/mixpanel.model';
import { MixpanelService } from 'src/data/services/mixpanel/mixpanel.service';
import { DealsStore } from 'src/data/stores/deals/deals.store';
import { PipelineStore } from 'src/data/stores/pipeline/pipeline.store';
import { IBaseStore } from 'src/data/stores/shared/base.store.interface';
import { ActionFeatureLaunchSource } from 'src/domain/features/account-configuration/helpers/action-feature.helper';
import { Deal, getPauseData } from 'src/domain/models/deal/deal.model';
import { getNumberOfDaysAgo } from 'src/utils/datetime.utils';
import { handleRequestAsync } from 'src/utils/handle-request.utils';

export interface DealPauseFeature {
    isPauseModalOpen: boolean;
    openPauseModal: (launchSource: ActionFeatureLaunchSource) => void;
    closePauseModal: () => void;
    pauseDeal: (
        deal: Deal,
        pausedUntil: Date,
        reason?: string,
    ) => Promise<void>;
    unPauseDeal: (deal: Deal) => Promise<void>;
}

export class DealPauseFeatureImpl implements DealPauseFeature {
    isPauseModalOpen = false;
    launchSource: ActionFeatureLaunchSource | null = null;

    constructor(
        private dealsStore: DealsStore,
        private pipelineStore: PipelineStore,
        private dealsApi: DealsApi,
        private mixpanelService: MixpanelService,
        private baseStore: IBaseStore,
        private achievementService: AchievementService,
    ) {
        makeAutoObservable(this);
    }

    openPauseModal = (launchSource: ActionFeatureLaunchSource) => {
        this.isPauseModalOpen = true;
        this.launchSource = launchSource;
    };

    closePauseModal = () => {
        this.isPauseModalOpen = false;
    };

    onPauseDeal = (deal: Deal, pausedUntil: Date | null, reason?: string) => {
        const isAlreadyPaused = deal.pauseData.isPaused;
        const newDeal = { ...deal };
        newDeal.pauseData = getPauseData(pausedUntil?.toISOString());
        this.dealsStore.dealsMap.set(deal.id, newDeal);

        /**
         * This line is important as we need to re-fetch the alert from the server
         * after pausing/unpausing the deal to get a fresh alert type considering the hierarch
         */
        this.dealsStore.setNeedToSyncDealAlert(true);

        if (pausedUntil) {
            this.mixpanelService.trackEvent(
                MixpanelEventName.PausedDeal,
                {
                    ...this.getPauseEventProperties(
                        pausedUntil,
                        isAlreadyPaused,
                        reason,
                    ),
                },
                deal.id,
                this.dealsStore.getDeal,
                this.pipelineStore.getPipeline,
            );
        } else {
            this.mixpanelService.trackEvent(
                MixpanelEventName.ReactivatedDeal,
                undefined,
                deal.id,
                this.dealsStore.getDeal,
                this.pipelineStore.getPipeline,
            );
        }

        this.achievementService.call(UserAchievementName.PauseADeal);
    };

    pauseDeal = async (deal: Deal, pausedUntil: Date, reason?: string) => {
        try {
            await handleRequestAsync(this.dealsApi.pauseDeal, {
                dealId: deal.id,
                pausedUntil,
            });
            this.onPauseDeal(deal, pausedUntil, reason);
        } catch (error) {
            this.baseStore.onRequestFailed('pause-deal', error as Error);
        }
    };

    unPauseDeal = async (deal: Deal) => {
        try {
            await handleRequestAsync(this.dealsApi.unPauseDeal, {
                dealId: deal.id,
            });
            this.onPauseDeal(deal, null);
        } catch (error) {
            this.baseStore.onRequestFailed('unpause-deal', error as Error);
        }
    };

    getPauseEventProperties = (
        pausedUntil: Date | null,
        isAlreadyPaused = false,
        reason?: string,
    ) => {
        if (!pausedUntil) {
            return null;
        }
        //minus because future
        const numberOfDaysPausedIntoFuture = -getNumberOfDaysAgo(pausedUntil);
        return {
            pauseDealLaunchSource: this.launchSource,
            numberOfDaysPausedIntoFuture,
            pausePeriodUpdated: isAlreadyPaused,
            reason,
        };
    };
}

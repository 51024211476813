import { makeAutoObservable } from 'mobx';

import { PipelineDealCollection } from 'src/data/api/deal/deal.api';
import { PipelineDeal } from 'src/domain/models/deal/deal.model';

interface IPipelineTableViewStore {
    pipelineDeals: Map<string, PipelineDeal>;
    totalPipelineDealsCount: number;
    setFreshRequestedDeals: (dealsCollection: PipelineDealCollection) => void;
}

export class PipelineTableViewStore implements IPipelineTableViewStore {
    constructor() {
        makeAutoObservable(this);
    }

    pipelineDeals: Map<string, PipelineDeal> = new Map();
    totalPipelineDealsCount = 0;

    setFreshRequestedDeals = (dealsCollection: PipelineDealCollection) => {
        this.pipelineDeals.clear();
        dealsCollection.deals.forEach((deal) =>
            this.pipelineDeals.set(deal.id, deal),
        );
        this.totalPipelineDealsCount = dealsCollection.totalCount;
    };
}

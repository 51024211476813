import { observer } from 'mobx-react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useAuthFeature } from 'src/app-context/use-features';

import { SubMenuComponent } from './sub-menu.component';

export const SubMenuContainer: FC = observer(() => {
    const { t } = useTranslation();
    const { user } = useAuthFeature();

    if (!user) {
        return null;
    }

    return <SubMenuComponent t={t} user={user} />;
});

import { BrText, TextSize } from '@buildingradar/br_component_lib';
import Box from '@mui/material/Box';
import { CircularProgressProps } from '@mui/material/CircularProgress';
import { observer } from 'mobx-react';
import { FC } from 'react';

import {
    ProgressIndicatorWrapper,
    ForegroundCircularIndicator,
} from './styles';

interface ProgressIndicatorProps extends CircularProgressProps {
    hint?: string;
    fullHeight?: boolean;
}

export const ProgressIndicator: FC<ProgressIndicatorProps> = observer(
    ({ hint = 'Loading..', fullHeight, ...props }) => {
        return (
            <ProgressIndicatorWrapper
                sx={{ height: fullHeight ? '100%' : 'auto' }}
            >
                <Box sx={{ position: 'relative', paddingBottom: '10px' }}>
                    <ForegroundCircularIndicator size={72} {...props} />
                </Box>
                <BrText size={TextSize.s} sx={{ textAlign: 'center' }}>
                    {hint}
                </BrText>
            </ProgressIndicatorWrapper>
        );
    },
);

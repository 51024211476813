import { print } from 'graphql';

import { AbortParams } from 'src/data/api/api-client';
import {
    AskQuestionQuery,
    AskQuestionRealtimeDocument,
    AskQuestionRealtimeSubscriptionVariables,
} from 'src/data/api/graphql/br_process/generated/graphql-sdk';
import { ProcessGqlSdk } from 'src/data/api/graphql/graphql-client.wrapper';
import { doNothing } from 'src/utils/function.utils';
import { checkWebSocket } from 'src/utils/websocket.utils';

interface GetQuestionParams extends AbortParams {
    projectId: string;
    question: string;
    documentId?: string;
    onNextValue?: (value: string) => void;
}

export interface XRayApi {
    getAnswers: (params: GetQuestionParams) => Promise<string>;
    getAnswerHybrid: (params: GetQuestionParams) => Promise<string>;
}

export const createXRayApi = (processGqlSdk: ProcessGqlSdk): XRayApi => {
    const getAnswerHybrid = async (params: GetQuestionParams) => {
        if (await checkWebSocket()) {
            return getAnswerSubscription(params);
        }
        return getAnswerGraphql(params);
    };

    const getAnswerGraphql = async ({
        projectId,
        question,
        documentId,
        signal,
    }: GetQuestionParams) => {
        const response = await processGqlSdk.AskQuestion(
            { projectId: +projectId, question, documentId },
            { signal },
        );
        return response.actionFeatureXray.answer;
    };

    const getAnswerSubscription = async ({
        projectId,
        question,
        documentId,
        onNextValue = doNothing,
        signal,
    }: GetQuestionParams): Promise<string> => {
        let answer = '';
        return new Promise((resolve, reject) => {
            processGqlSdk.runSubscription<AskQuestionQuery>(
                {
                    query: print(AskQuestionRealtimeDocument),
                    variables: {
                        projectId: +projectId,
                        question,
                        documentId,
                    } as AskQuestionRealtimeSubscriptionVariables,
                },
                (value) => {
                    answer += value.actionFeatureXray.answer;
                    onNextValue(answer);
                },
                () => resolve(answer),
                (error) => reject(error),
                signal,
            );
        });
    };

    return {
        getAnswers: getAnswerGraphql,
        getAnswerHybrid,
    };
};

import { MenuItemConfiguration } from '@buildingradar/br_component_lib';
import { TFunction } from 'i18next';
import { isFunction } from 'lodash';
import { UseFormGetValues } from 'react-hook-form';

import {
    DealContactCompanyForm,
    DealContactPersonForm,
    DealContactUnionForm,
} from 'src/app-features/contact/data/model/deal-contacts-form.model';
import { RoleToContactMap } from 'src/app-features/contact/data/model/deal-contacts.interface';
import {
    DealContactCompany,
    DealContactType,
} from 'src/app-features/contact/data/model/deal-contacts.model';
import { CustomParameter } from 'src/domain/models/custom-parameter/custom-parameter.model';
import {
    FormConfig,
    FormControlConfig,
    SimpleFieldConfig,
} from 'src/presentation/shared/form-control/form.model';

export type CompanyFormConfig = FormControlConfig<DealContactCompanyForm>;
export type PersonFormConfig = FormControlConfig<DealContactPersonForm>;

export type GetValuesFn = (payload?: string | string[]) => object;

const companyConfig: FormControlConfig<DealContactPersonForm> = {
    type: 'selection-parameter',
    name: 'relevantCompanyId',
    placeholder:
        'deal_view.contacts_section.contact_form.company_selection_placeholder',
    label: 'deal_view.contacts_section.contact_form.company_selection_label',
    className: 'company',
    options: [],
    resettable: true,
};

const getPersonNameConfigs = (
    getValues: GetValuesFn,
): FormControlConfig<DealContactPersonForm>[] => [
    {
        type: 'single-line-text',
        name: 'firstName',
        placeholder:
            'deal_view.contacts_section.contact_form.contact_first_name_placeholder',
        label: 'deal_view.contacts_section.contact_form.contact_first_name_label',
        className: 'first-name',
        errorText:
            'deal_view.contacts_section.contact_form.error_fill_first_name_or_last_name',
        validate: (firstName) => !!firstName || !!getValues('lastName'),
    },
    {
        type: 'single-line-text',
        name: 'lastName',
        placeholder:
            'deal_view.contacts_section.contact_form.contact_last_name_placeholder',
        label: 'deal_view.contacts_section.contact_form.contact_last_name_label',
        className: 'last-name',
    },
];

const customFieldConfig: FormControlConfig<DealContactPersonForm> = {
    type: 'custom-parameter-line',
    customFields: [],
};

export const getEnrichmentRefinementContactConfig = (
    getValues: GetValuesFn,
): FormConfig<DealContactPersonForm> => [
    ...getPersonNameConfigs(getValues),
    ...companyOnlyFormConfig,
];

const getDealContactPersonFormConfig = (
    getValues: GetValuesFn,
): FormConfig<DealContactPersonForm> => [
    ...getPersonNameConfigs(getValues),
    companyConfig,
    customFieldConfig,
];

export const companyOnlyFormConfig: FormConfig<DealContactPersonForm> = [
    {
        ...companyConfig,
        label: 'actions.contact_enrichment.input.company_label',
        resettable: false,
        required: true,
    },
];

export const notesConfig: SimpleFieldConfig<DealContactUnionForm> = {
    type: 'multi-line-text',
    name: 'notes',
    placeholder:
        'deal_view.contacts_section.contact_form.contact_notes_placeholder',
    label: 'deal_view.contacts_section.contact_form.contact_notes_label',
    className: 'notes',
};

export const companyNameConfig: FormControlConfig<DealContactCompanyForm> = {
    type: 'single-line-text',
    name: 'name',
    required: true,
    placeholder:
        'deal_view.contacts_section.contact_form.company_name_placeholder',
    label: 'deal_view.contacts_section.contact_form.company_name_label',
    className: 'company-name',
};

const dealCompanyFormConfig: FormConfig<DealContactCompanyForm> = [
    companyNameConfig,
    customFieldConfig,
];

export const getAvailableRolesOptions = (
    availableRoles: string[],
    alreadyUsed: RoleToContactMap,
    t: TFunction,
    isPerson: boolean,
    selectedContactId?: string,
): MenuItemConfiguration[] => {
    availableRoles = ['', ...availableRoles];
    const options: MenuItemConfiguration[] = availableRoles.map((role) => {
        const label =
            !alreadyUsed[role] || alreadyUsed[role] === selectedContactId
                ? role === ''
                    ? t('deal_view.contacts_section.role.no_deal_role')
                    : role
                : `${role} [${t(
                      'deal_view.contacts_section.reassign_role_confirmation_modal.role_reassigned',
                  )}]`;
        const category = t(
            isPerson
                ? 'deal_view.contacts_section.role.assign_to_role_contact'
                : 'deal_view.contacts_section.role.assign_to_role_company',
        );
        return {
            label,
            value: role,
            groupingCategoryId: category,
        };
    });
    return options;
};

const defaultContactCompanyFormValue: DealContactCompanyForm = {
    name: '',
    role: '',
    customFields: [],
    notes: '',
    formType: DealContactType.company,
    isKey: true,
};

const defaultContactPersonFormValue: DealContactPersonForm = {
    firstName: '',
    lastName: '',
    role: '',
    notes: '',
    customFields: [],
    isKey: true,
    formType: DealContactType.person,
    relevantCompanyId: '',
};

export const getDefaultContactFormData = (
    isPerson: boolean,
    personCustomFields: CustomParameter[],
    companyCustomFields: CustomParameter[],
    defaultRole?: string,
    relevantCompanyId?: string,
): DealContactPersonForm | DealContactCompanyForm => {
    return isPerson
        ? {
              ...defaultContactPersonFormValue,
              customFields: personCustomFields,
              role: defaultRole ?? '',
              relevantCompanyId,
          }
        : {
              ...defaultContactCompanyFormValue,
              customFields: companyCustomFields,
              role: defaultRole ?? '',
          };
};

export const buildContactFormConfig = (
    isPerson: boolean,
    companies: DealContactCompany[],
    personCustomFields: CustomParameter[],
    companyCustomFields: CustomParameter[],
    getValues: UseFormGetValues<DealContactUnionForm>,
    customConfig?:
        | FormConfig<DealContactPersonForm>
        | FormConfig<DealContactCompanyForm>
        | ((
              getValues: GetValuesFn,
          ) =>
              | FormConfig<DealContactPersonForm>
              | FormConfig<DealContactCompanyForm>),
) => {
    const configChoice = customConfig
        ? isFunction(customConfig)
            ? customConfig(getValues)
            : customConfig
        : isPerson
          ? getDealContactPersonFormConfig(getValues)
          : dealCompanyFormConfig;
    return configChoice.map((config) => {
        if (config.type === 'selection-parameter') {
            if (config.name === 'relevantCompanyId') {
                config.options = companies.map(({ id, name }) => ({
                    value: id,
                    label: name,
                }));
            }
        } else if (config.type === 'custom-parameter-line') {
            config.customFields = isPerson
                ? personCustomFields
                : companyCustomFields;
        }
        return config;
    });
};

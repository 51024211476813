import styled from '@emotion/styled';

export const TopItemsStyled = styled.div`
    display: flex;
    flex-direction: column;
`;

export const NarrowBorder = styled.div`
    height: 2px;
    background-color: var(--gray-icon-disabled);
    margin: 0 24px 12px;
`;

export const BottomItemsStyled = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0 0 12px;
`;

import { makeAutoObservable } from 'mobx';

import { UserSettingName } from 'src/data/api/graphql/br_user/generated/graphql-sdk';
import { UsersApi } from 'src/data/api/user/user.api';
import { IBaseStore } from 'src/data/stores/shared/base.store.interface';
import { UserStore } from 'src/data/stores/user/user.store';
import {
    DEFAULT_CSV_DELIMITER,
    Settings,
    userSettingsMap,
} from 'src/domain/models/settings/settings.model';
import { User } from 'src/domain/models/user/user.model';
import { doNothing } from 'src/utils/function.utils';
import { Cancellable, handleRequest } from 'src/utils/handle-request.utils';

export interface UserSettingsFeature {
    userSettings: Settings | undefined;
    csvDelimiter: string;
    updateCSVOption: (csvOption: string) => Cancellable;
    updateDefaultPipeline: (pipelineId: string) => Cancellable;
    updateSetting: (
        setting: UserSettingName,
        value: string | boolean | number[] | string[],
    ) => Cancellable;
}

export class UserSettingsFeatureImpl implements UserSettingsFeature {
    get user(): User | undefined {
        return this.userStore.user;
    }

    get userSettings() {
        return this.user?.settings;
    }

    get csvDelimiter(): string {
        return this.user?.settings.csvDelimiter ?? DEFAULT_CSV_DELIMITER;
    }

    onUpdateCSVOption = (csvOption: string) => {
        if (this.user) {
            this.user.settings.csvDelimiter = csvOption;
        }
    };

    onUpdatePipelineOption = (pipelineId: string) => {
        if (this.user) {
            this.user.settings.defaultPipeline = pipelineId;
        }
    };

    updateCSVOption = (csvOption: string) => {
        return handleRequest(
            this.userApi.changeUserSetting,
            { setting: UserSettingName.CsvDelimiter, value: csvOption },
            () => this.onUpdateCSVOption(csvOption),
            doNothing,
            (error) =>
                this.baseStore.onRequestFailed('update-csv-option', error),
        );
    };

    updateDefaultPipeline = (pipelineId: string) => {
        return handleRequest(
            this.userApi.changeUserSetting,
            { setting: UserSettingName.DefaultPipeline, value: pipelineId },
            () => this.onUpdatePipelineOption(pipelineId),
            doNothing,
            (error) =>
                this.baseStore.onRequestFailed(
                    'update-default-pipeline',
                    error,
                ),
        );
    };

    onSettingUpdated = (
        setting: UserSettingName,
        value: string | boolean | number[] | string[],
    ) => {
        if (this.user) {
            this.user.settings = {
                ...this.user.settings,
                [userSettingsMap[setting]]: value,
            };
        }
    };

    updateSetting = (
        setting: UserSettingName,
        value: string | boolean | number[] | string[],
    ) => {
        return handleRequest(
            this.userApi.changeUserSetting,
            { setting, value },
            () => this.onSettingUpdated(setting, value),
            doNothing,
            (error) =>
                this.baseStore.onRequestFailed('update-user-setting', error),
        );
    };

    constructor(
        private userStore: UserStore,
        private userApi: UsersApi,
        private baseStore: IBaseStore,
    ) {
        makeAutoObservable(this);
    }
}

import { SalesPeriodFrequency as RawSalesPeriodFrequency } from 'src/data/api/graphql/br_process/generated/graphql-sdk';

export interface RawSalesPeriodConfiguration {
    frequency?: RawSalesPeriodFrequency | null;
}

enum SalesPeriodFrequency {
    Annual = 'Annual',
    Quarter = 'Quarter',
}

const FrequencyRawToModelMap: {
    [key in RawSalesPeriodFrequency]: SalesPeriodFrequency;
} = {
    ANNUALLY: SalesPeriodFrequency.Annual,
    QUARTERLY: SalesPeriodFrequency.Quarter,
};

export interface ISalesPeriodConfiguration {
    frequency?: SalesPeriodFrequency;
}

export class SalesPeriodConfiguration implements ISalesPeriodConfiguration {
    frequency?: SalesPeriodFrequency;

    constructor(period?: RawSalesPeriodConfiguration) {
        this.frequency = period?.frequency
            ? FrequencyRawToModelMap[period.frequency]
            : undefined;
    }
}

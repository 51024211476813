import { ReminderTargetType as ReminderTargetTypeQl } from 'src/data/api/graphql/br_process/generated/graphql-sdk';

export enum ReminderTargetType {
    Deal = 'Deal',
}

export const TargetTypeModelToSdkMap: {
    [key in ReminderTargetType]: ReminderTargetTypeQl;
} = {
    Deal: ReminderTargetTypeQl.Deal,
};

export const TargetTypeSdkToModelMap: {
    [key in ReminderTargetTypeQl]: ReminderTargetType;
} = {
    DEAL: ReminderTargetType.Deal,
};

import {
    BrButton,
    BrButtonColor,
    BrButtonVariant,
    createToast,
} from '@buildingradar/br_component_lib';
import { Link } from 'react-router-dom';

import { ToasterMessage } from 'src/domain/features/toaster/toaster.utils';

export const brToast = (
    message: ToasterMessage,
    onClose: () => void,
    isMobile?: boolean,
): string | number => {
    const actionNode = (message?.primaryLink || message?.secondaryLink) && (
        <div>
            {message.primaryLink && (
                <Link to={message.primaryLink}>
                    <BrButton variant={BrButtonVariant.text} onClick={onClose}>
                        {message?.primaryLinkText}
                    </BrButton>
                </Link>
            )}
            {message.secondaryLink && (
                <Link to={message.secondaryLink}>
                    <BrButton
                        color={BrButtonColor.standard}
                        variant={BrButtonVariant.text}
                        onClick={onClose}
                    >
                        {message?.secondaryLinkText}
                    </BrButton>
                </Link>
            )}
        </div>
    );
    const primaryAction = () => {
        message?.primaryAction?.();
        message?.primaryAsCloseButton && onClose();
    };

    const secondaryAction = () => {
        message?.secondaryAction?.();
        message?.secondaryAsCloseButton && onClose();
    };

    const showPrimaryAction =
        !!message?.primaryAction || !!message?.primaryAsCloseButton;
    const showSecondaryAction =
        !!message?.secondaryAction || !!message?.secondaryAsCloseButton;

    return createToast({
        title: message.title,
        message: !isMobile ? message.message : undefined,
        autoClose: message.autoClose,
        primaryAction: showPrimaryAction ? primaryAction : undefined,
        primaryActionText: message.primaryActionText,
        secondaryAction: showSecondaryAction ? secondaryAction : undefined,
        secondaryActionText: message.secondaryActionText,
        actionNode,
        type: message.type,
        iconNode: message.iconNode,
        showProgress: message.showProgress,
    });
};

import {
    BrButton,
    BrButtonVariant,
    BrSelect,
    MenuItemConfiguration,
} from '@buildingradar/br_component_lib';
import { observer } from 'mobx-react';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TemplateDataContext } from 'src/domain/features/actions/quick-actions/email-templates/data-context/template-data-context';
import { EmailTemplate } from 'src/domain/models/email-templates/email-templates.model';
import { ActionContentComponent } from 'src/presentation/modules/enablement-actions-panel/actions/action-content.component';
import { ExpandedTemplateModal } from 'src/presentation/modules/enablement-actions-panel/actions/email-template/components/expanded-template-modal.component';
import { SubjectBodyForm } from 'src/presentation/modules/enablement-actions-panel/actions/email-template/components/subject-body-form.component';
import { ActionPanelLabelStyled } from 'src/presentation/modules/enablement-actions-panel/actions/styled';
import { recreateTemplateFromHtml } from 'src/presentation/shared/token-field/token-field.utils';

import { TemplateTipsList } from './styled';

interface EmailTemplatePanelComponentProps {
    dealId: string;
    selectedContactId: string;
    selectableTemplatesOptions: MenuItemConfiguration[];
    selectableContactsOptions: MenuItemConfiguration[];
    originalTemplates: EmailTemplate[];
    dataContext?: TemplateDataContext;
    setSelectedEntityId: (entityId: string) => void;
    expandHubSpotChat: () => void;
    onEmailTemplatesCloseRequested: () => void;
    setCurrentDealId: (id?: string) => void;
    createEmail: (subject: string, body: string) => void;
    copyToEmailClipboard: (subject: string, body: string) => void;
}

export const EmailTemplatePanelComponent: FC<EmailTemplatePanelComponentProps> =
    observer(
        ({
            dealId,
            selectedContactId,
            selectableTemplatesOptions,
            selectableContactsOptions,
            originalTemplates,
            dataContext,
            setSelectedEntityId,
            expandHubSpotChat,
            onEmailTemplatesCloseRequested,
            setCurrentDealId,
            createEmail,
            copyToEmailClipboard,
        }) => {
            const { t } = useTranslation();

            const [isExpandedTemplate, setIsExpandedTemplate] = useState(false);
            const [selectedInitialTemplate, setSelectedInitialTemplate] =
                useState(
                    originalTemplates.length ? originalTemplates[0] : undefined,
                );
            const [bodyTokenMap] = useState<Map<string, string>>(new Map());
            const [subjectTokenMap] = useState<Map<string, string>>(new Map());

            const subjectFieldRef = useRef<HTMLDivElement>(null);
            const bodyFieldRef = useRef<HTMLDivElement>(null);

            const bodyExpandedFieldRef = useRef<HTMLDivElement>(null);
            const subjectExpandedFieldRef = useRef<HTMLDivElement>(null);

            const [body, setBody] = useState<string>(
                selectedInitialTemplate?.body ?? '',
            );
            const [subject, setSubject] = useState<string>(
                selectedInitialTemplate?.subject ?? '',
            );

            const registerBodyToken = useCallback(
                (tokenId: string, token: string) => {
                    bodyTokenMap.set(tokenId, token);
                },
                [bodyTokenMap],
            );

            const registerSubjectToken = useCallback(
                (tokenId: string, token: string) => {
                    subjectTokenMap.set(tokenId, token);
                },
                [subjectTokenMap],
            );

            const recreateTemplates = useCallback(() => {
                const bodyHtml =
                    bodyExpandedFieldRef.current?.innerHTML ??
                    bodyFieldRef.current?.innerHTML ??
                    '';
                setBody(recreateTemplateFromHtml(bodyHtml, bodyTokenMap));
                const subjectHtml =
                    subjectExpandedFieldRef.current?.innerHTML ??
                    subjectFieldRef.current?.innerHTML ??
                    '';
                setSubject(
                    recreateTemplateFromHtml(subjectHtml, subjectTokenMap),
                );
            }, [bodyTokenMap, subjectTokenMap]);

            useEffect(() => {
                setCurrentDealId(dealId);
            }, [dealId, setCurrentDealId]);

            const onTemplateSelected = (templateName: string | undefined) => {
                const newTemplate = originalTemplates.find(
                    ({ name }) => name === templateName,
                );
                setSelectedInitialTemplate(newTemplate);
                setBody(newTemplate?.body ?? '');
                setSubject(newTemplate?.subject ?? '');
            };

            const onContactSuccessManagerClicked = useCallback(() => {
                expandHubSpotChat();
                onEmailTemplatesCloseRequested();
            }, [expandHubSpotChat, onEmailTemplatesCloseRequested]);

            const onToggleTemplateExpandedMode = useCallback(
                (isExpanded: boolean) => () => {
                    recreateTemplates();
                    setIsExpandedTemplate(isExpanded);
                },
                [recreateTemplates],
            );

            const onCreateEmailClicked = useCallback(() => {
                createEmail(
                    subjectExpandedFieldRef.current?.textContent ??
                        subjectFieldRef.current?.textContent ??
                        '',
                    bodyExpandedFieldRef.current?.innerText ??
                        bodyFieldRef.current?.innerText ??
                        '',
                );
            }, [createEmail]);

            const onCopyToClipboardClicked = useCallback(() => {
                copyToEmailClipboard(
                    subjectExpandedFieldRef.current?.textContent ??
                        subjectFieldRef.current?.textContent ??
                        '',
                    bodyExpandedFieldRef.current?.innerText ??
                        bodyFieldRef.current?.innerText ??
                        '',
                );
            }, [copyToEmailClipboard]);

            const templatesOptionsToDisplay: MenuItemConfiguration[] =
                selectableTemplatesOptions.length
                    ? selectableTemplatesOptions
                    : [
                          {
                              value: '',
                              label: t(
                                  'actions.email_templates.no_templates_found_dropdown_content',
                              ),
                          },
                      ];

            const selectContact = useCallback(
                (contactId: string | undefined) => {
                    recreateTemplates();
                    setSelectedEntityId(contactId ?? '');
                },
                [recreateTemplates, setSelectedEntityId],
            );

            return (
                <ActionContentComponent
                    title={t('actions.email_templates.side_panel_title')}
                    body={
                        <>
                            <ActionPanelLabelStyled>
                                {t(
                                    'actions.email_templates.template_dropdown_label',
                                )}
                            </ActionPanelLabelStyled>
                            <BrSelect
                                options={templatesOptionsToDisplay}
                                selectedValue={
                                    selectedInitialTemplate?.name ??
                                    templatesOptionsToDisplay[0].value
                                }
                                disabled={!selectableTemplatesOptions.length}
                                onSelectionChange={onTemplateSelected}
                                fullWidth
                                margin="none"
                            />
                            <ActionPanelLabelStyled applyTopMargin>
                                {t(
                                    'actions.email_templates.contact_dropdown_label',
                                )}
                            </ActionPanelLabelStyled>
                            <BrSelect
                                options={selectableContactsOptions}
                                selectedValue={selectedContactId}
                                onSelectionChange={selectContact}
                                fullWidth
                                margin="none"
                            />
                            <SubjectBodyForm
                                initialSubjectValue={subject}
                                initialBodyValue={body}
                                subjectFieldRef={subjectFieldRef}
                                bodyFieldRef={bodyFieldRef}
                                dataContext={dataContext}
                                onToggleExpandedMode={
                                    onToggleTemplateExpandedMode
                                }
                                registerBodyToken={registerBodyToken}
                                registerSubjectToken={registerSubjectToken}
                            />
                            <TemplateTipsList>
                                <li>
                                    {t(
                                        'actions.email_templates.tips.format_removed_text',
                                    )}
                                </li>
                                <li>
                                    {t('actions.email_templates.tips.problems')}{' '}
                                    <span
                                        onClick={onContactSuccessManagerClicked}
                                        className="hubspot-chat-link"
                                    >
                                        {t(
                                            'actions.email_templates.tips.contact_success_manager',
                                        )}
                                    </span>
                                </li>
                            </TemplateTipsList>
                            <ExpandedTemplateModal
                                isOpen={isExpandedTemplate}
                                initialSubjectValue={subject}
                                initialBodyValue={body}
                                subjectFieldRef={subjectExpandedFieldRef}
                                bodyFieldRef={bodyExpandedFieldRef}
                                dataContext={dataContext}
                                onCopyToClipboard={onCopyToClipboardClicked}
                                onCreateEmail={onCreateEmailClicked}
                                onClose={onToggleTemplateExpandedMode(false)}
                            />
                        </>
                    }
                    actions={
                        <>
                            <BrButton fullWidth onClick={onCreateEmailClicked}>
                                {t(
                                    'actions.email_templates.create_email_button_label',
                                )}
                            </BrButton>
                            <BrButton
                                variant={BrButtonVariant.outlined}
                                fullWidth
                                onClick={onCopyToClipboardClicked}
                            >
                                {t(
                                    'actions.email_templates.copy_to_clipboard_button_label',
                                )}
                            </BrButton>
                            <BrButton
                                variant={BrButtonVariant.outlined}
                                fullWidth
                                onClick={onEmailTemplatesCloseRequested}
                            >
                                {t('common.close')}
                            </BrButton>
                        </>
                    }
                    onClose={onEmailTemplatesCloseRequested}
                />
            );
        },
    );

import { AbortParams, ApiClient } from 'src/data/api/api-client';
import { ApiLookupUrls } from 'src/data/api/api-list';
import { RawDocument } from 'src/domain/models/document/document.model';
import {
    createLeadFromDocGroup,
    Lead,
} from 'src/domain/models/lead/lead.model';

interface GetFilteredLookupLeadsParams extends AbortParams {
    freetext: string;
    location: string;
    language: string;
    offset: number;
    limit: number;
}
interface DocumentGroupCollection {
    [key: string]: string[];
}
export interface RawPopulatedDocumentGroup {
    doc_group_created: string;
    doc_group_id: string;
    doc_group_updated: string;
    pipe_db_collection: string;
    document_ids: string[];
    merged_document: RawDocument;
}
interface GetLookupToolApiResponse {
    doc_group_collections: DocumentGroupCollection;
    populated_doc_groups: RawPopulatedDocumentGroup[];
    search_count: number;
}
export interface LookupToolResponse {
    leads: Lead[];
    search_count: number;
}
export interface LookupToolApi {
    getFilteredLookupLeads: (
        params: GetFilteredLookupLeadsParams,
    ) => Promise<LookupToolResponse>;
}

export const createLookupToolApi = (
    lookupApiClient: ApiClient,
): LookupToolApi => {
    const getFilteredLookupLeads = async (
        params: GetFilteredLookupLeadsParams,
    ) => {
        const { signal, ...lookupParams } = params;

        const body = { ...lookupParams };
        const url = ApiLookupUrls.search;
        const lookupResult =
            await lookupApiClient.post<GetLookupToolApiResponse>({
                url,
                body,
                signal,
                isBodyJson: true,
                headers: { 'Content-Type': 'application/json' },
            });
        const leads: Lead[] = lookupResult.populated_doc_groups.map(
            createLeadFromDocGroup,
        );
        return { leads, search_count: lookupResult.search_count };
    };

    return {
        getFilteredLookupLeads,
    };
};

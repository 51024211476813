import { makeAutoObservable } from 'mobx';

import {
    DealContactCompany,
    DealContactPerson,
} from 'src/app-features/contact/data/model/deal-contacts.model';

import { IDealContactsStore } from './deal-contacts.store.interface';

export class DealContactsStore implements IDealContactsStore {
    companiesMap: Map<string, DealContactCompany> = new Map();
    personsMap: Map<string, DealContactPerson> = new Map();

    constructor() {
        makeAutoObservable(this);
    }
    get personIds() {
        return Array.from(this.personsMap.keys());
    }
    get companyIds() {
        return Array.from(this.companiesMap.keys());
    }
    addContactPerson = (person: DealContactPerson) => {
        this.personsMap.set(person.id, person);
    };

    addContactCompany = (company: DealContactCompany) => {
        this.companiesMap.set(company.id, company);
    };

    updateContactPerson = (person: DealContactPerson) => {
        this.personsMap.set(person.id, person);
    };

    updateContactCompany = (company: DealContactCompany): void => {
        this.companiesMap.set(company.id, company);
    };

    removeContactPerson = (id: string): void => {
        this.personsMap.delete(id);
    };

    removeContactCompany = (id: string): void => {
        this.companiesMap.delete(id);
    };

    clear = () => {
        this.companiesMap.clear();
        this.personsMap.clear();
    };
}

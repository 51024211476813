import { CategoryQl } from 'src/data/api/graphql/br_search/generated/graphql-sdk';

export interface RawCategory {
    ranking: number;
    name: string;
    confidence_score?: number;
    is_precise?: boolean;
}

export interface Category {
    ranking: number;
    name: string;
    confidenceScore: number;
    isPrecise: boolean;
}

export const createCategory = (rawCategory: RawCategory): Category => {
    const {
        ranking,
        name,
        confidence_score: confidenceScore,
        is_precise: isPrecise,
    } = rawCategory;
    return {
        ranking,
        name,
        confidenceScore: confidenceScore ?? 0,
        isPrecise: !!isPrecise,
    };
};

export const createCategoryFromSdk = (rawCategory: CategoryQl): Category => {
    const { ranking, name, confidenceScore, isPrecise } = rawCategory;
    return {
        ranking: ranking ?? 0,
        name,
        confidenceScore: confidenceScore ?? 0,
        isPrecise: !!isPrecise,
    };
};

// like categoryMap from textRepresentationUtils
// if needed to add subcategories => get it from categoryMap
const categoryMap: Record<string, string | undefined> = {
    office: 'lead.category.office',
    education_science: 'lead.category.education_and_science',
    health: 'lead.category.health',
    sport: 'lead.category.sport',
    hospitality: 'lead.category.hospitality',
    landscaping: 'lead.category.landscaping',
    infrastructure: 'lead.category.infrastructure',
    agriculture: 'lead.category.agriculture',
    commercial: 'lead.category.commercial',
    residential: 'lead.category.residential',
    culture_event: 'lead.category.culture_and_event',
    industrial: 'lead.category.industrial',
    public_service_administration:
        'lead.category.public_service_and_administration',
    historical_religious: 'lead.category.historical_and_religious',
};

export const getCategoryText = (category: Category): string => {
    const categorySelection = categoryMap[category.name];

    return categorySelection ?? category.name;
};

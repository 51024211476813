/**
 * just make an array with length === count and fill it with the same item
 * @param item
 * @param count
 */
export const multiple = <T>(
    getItem: (index: number) => T,
    count: number,
): T[] => new Array(count).fill(undefined).map((_, index) => getItem(index));

interface Orderable {
    order: number | null;
}

const compareByOrder = <T extends Orderable>(a: T, b: T) => {
    return (a.order ?? 0) - (b.order ?? 0);
};

/**
 * creates new array of items, sort them and return them.
 * this function is carried, so we should apply the result to compare function, like:
 * @example const sorted = sortNewArray(list)((a, b) => b - a);
 * @param list
 */
export const sortNewArray =
    <T>(list: T[]) =>
    (compare: (a: T, b: T) => number) => {
        const newList = [...list];
        newList.sort(compare);
        return newList;
    };

/**
 * sort items by order and return new array by default or in place
 * @param list
 * @param createNewArray, @default = true
 */
export const sortByOrder = <T extends Orderable>(
    list: T[],
    createNewArray = true,
): T[] => {
    const sortingFunction = createNewArray ? sortNewArray(list) : list.sort;
    return sortingFunction(compareByOrder);
};

export const allMembersEqual = <T>(arr: T[]) => arr.every((v) => v === arr[0]);

import { ImageQl } from 'src/data/api/graphql/br_search/generated/graphql-sdk';

export interface RawImage {
    url: string;
    source_url: string;
    source_domain: string;
    source_title: string;
    quality_score: number;
}

export interface Image {
    url: string;
    sourceURL: string;
    sourceDomain: string;
    sourceTitle: string;
    qualityScore: number;
}

export const createImage = (rawImage: RawImage): Image => {
    return {
        url: rawImage.url,
        sourceURL: rawImage.source_url,
        sourceDomain: rawImage.source_domain,
        sourceTitle: rawImage.source_title,
        qualityScore: rawImage.quality_score,
    };
};

export const createImageFromSdk = (rawImage: ImageQl): Image => {
    return {
        url: rawImage.url,
        sourceURL: rawImage.sourceUrl,
        sourceDomain: rawImage.sourceDomain,
        sourceTitle: rawImage.sourceTitle ?? '',
        qualityScore: rawImage.qualityScore ?? 0,
    };
};

import styled from '@emotion/styled';
import { Box, CircularProgress, circularProgressClasses } from '@mui/material';

export const ForegroundCircularIndicator = styled(CircularProgress)({
    color: '#6567f4',
    animationDuration: '3000ms',
    [`& .${circularProgressClasses.circle}`]: {
        strokeLinecap: 'round',
    },
});

export const ProgressIndicatorWrapper = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
});

import {
    BrButtonColor,
    BrButtonVariant,
    IconName,
} from '@buildingradar/br_component_lib';
import { Box } from '@mui/material';
import { observer } from 'mobx-react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ConfirmModal } from 'src/presentation/shared/modal-confirm/modal-confirm';
import { Spacing } from 'src/resources/styles/spacing';

interface ComponentProps {
    isPerson: boolean;
    open: boolean;
    onCancel: () => void;
    onConfirm: () => void;
}
export const ContactPanelConfirmModal: FC<ComponentProps> = observer(
    ({ isPerson, open, onCancel, onConfirm }) => {
        const { t } = useTranslation();
        return (
            <ConfirmModal
                icon={IconName.BrWarningIcon}
                title={t(
                    isPerson
                        ? 'deal_view.contacts_section.leave_without_save_modal.title.company'
                        : 'deal_view.contacts_section.leave_without_save_modal.title.contact',
                )}
                descriptionNode={
                    <Box mt={Spacing.s2}>
                        {t(
                            'deal_view.contacts_section.leave_without_save_modal.description_part1',
                        )}
                        <b>{t('common.cancel')}</b>
                        {t(
                            'deal_view.contacts_section.leave_without_save_modal.description_part2',
                        )}
                        <b>{t('common.delete')}</b>
                        {t(
                            'deal_view.contacts_section.leave_without_save_modal.description_part3',
                        )}
                    </Box>
                }
                btnCancel={t('common.delete')}
                btnOk={t('common.cancel')}
                open={open}
                onCancel={onCancel}
                onConfirm={onConfirm}
                className="leave-without-save-modal-contact-modal"
                btnOkVariant={BrButtonVariant.outlined}
                btnCancelColor={BrButtonColor.danger}
                btnCancelVariant={BrButtonVariant.text}
            />
        );
    },
);

import {
    ArticleDocumentPartFragment,
    TenderDocumentPartFragment,
} from 'src/data/api/graphql/br_project/generated/graphql-sdk';
import {
    Article,
    createArticle,
    createArticleLegacy,
    RawArticle,
} from 'src/domain/models/article/article.model';
import {
    BrDateTime,
    BrDateTimeSdk,
    createNullableBrDateTime,
    createNullableBrDateTimeFromSdk,
    RawBrDateTime,
} from 'src/domain/models/br-time/br-time.model';
import {
    Category,
    createCategory,
    createCategoryFromSdk,
    RawCategory,
} from 'src/domain/models/category/category.model';
import {
    Contact,
    RawContact,
    createContacts,
    createContactsFromSdk,
} from 'src/domain/models/contact/contact.model';
import {
    createImage,
    createImageFromSdk,
    Image,
    RawImage,
} from 'src/domain/models/image/image.model';
import {
    ConstructionPhase,
    createConstructionPhase,
    createConstructionPhaseFromSdk,
    RawConstructionPhase,
} from 'src/domain/models/phase/phase.model';
import {
    createTender,
    createTenderLegacy,
    RawTender,
    Tender,
} from 'src/domain/models/tender/tender.model';
import { isNotEmpty } from 'src/utils/type.utils';

import { getDocumentTypeFromId } from './document.utils';
import { ProjectAiGeneratedData } from '../lead/project-ai-generated-data';

export enum DocumentType {
    Project = 'project',
    Article = 'article',
    Tender = 'tender',
}

export const readableDocumentType: Record<DocumentType, string> = {
    [DocumentType.Article]: 'lead.document_type.article',
    [DocumentType.Tender]: 'lead.document_type.tender',
    [DocumentType.Project]: 'lead.document_type.project',
};

export interface RawBaseDocument<T extends DocumentType> {
    type: T;
    item_id: string;
    title: string;
    title_alternative: string | null;
    title_english: string | null;
    title_alternative_english: string | null;
    categories: null | RawCategory[];
    construction_phases: null | RawConstructionPhase[];
    volume_reference: number | null; // ALSO IN TENDER
    source_urls: string[] | null;
    source_domains: string[] | null;
    language: string;
    /**
     * @deprecated
     */
    description: string | null;
    descriptions: string[] | null;
    source_published_date: RawBrDateTime | null;
    images?: RawImage[];
    contacts: null | RawContact[];
}

export interface BaseDocument<T extends DocumentType> {
    type: T;
    itemId: string;
    title: string;
    titleAlternative: string | null;
    titleEnglish: string | null;
    categories: Category[];
    constructionPhases: ConstructionPhase[];
    volumeReference: number | null;
    sourceUrls: string[];
    sourceDomains: string[];
    description: string;
    descriptions: string[];
    sourcePublishedDate: BrDateTimeSdk | BrDateTime | null;
    language?: string;
    images: Image[];
    contacts: Contact[];
}

export const createBaseDocumentLegacy = <T extends DocumentType>(
    rawBaseDocument: RawBaseDocument<T>,
): BaseDocument<T> => {
    const descriptions = rawBaseDocument.descriptions ?? [];
    const description =
        rawBaseDocument.description ?? descriptions.length > 0
            ? descriptions.join(' ')
            : '';

    return {
        type: rawBaseDocument.type,
        volumeReference: rawBaseDocument.volume_reference,
        itemId: rawBaseDocument.item_id,
        title: rawBaseDocument.title,
        titleAlternative: rawBaseDocument.title_alternative,
        titleEnglish: rawBaseDocument.title_english,
        categories: rawBaseDocument.categories
            ? rawBaseDocument.categories.map(createCategory)
            : [],
        constructionPhases: rawBaseDocument.construction_phases
            ? rawBaseDocument.construction_phases.map(createConstructionPhase)
            : [],
        description,
        descriptions,
        language: rawBaseDocument.language,
        sourceUrls: rawBaseDocument.source_urls ?? [],
        sourceDomains: rawBaseDocument.source_domains ?? [],
        sourcePublishedDate: createNullableBrDateTime(
            rawBaseDocument.source_published_date,
        ),
        images: (rawBaseDocument.images ?? []).map(createImage),
        contacts: rawBaseDocument.contacts
            ? createContacts(rawBaseDocument.contacts)
            : [],
    };
};

export const createBaseDocument = <T extends DocumentType>(
    rawBaseDocument: ArticleDocumentPartFragment | TenderDocumentPartFragment,
    documentType: T,
): BaseDocument<T> => {
    const descriptions = rawBaseDocument.descriptions ?? [];
    const description =
        rawBaseDocument.description ?? descriptions.length > 0
            ? descriptions.join(' ')
            : '';

    return {
        type: documentType,
        volumeReference: isNotEmpty(rawBaseDocument.volumeReference)
            ? Number(rawBaseDocument.volumeReference)
            : null,
        itemId: rawBaseDocument.itemId,
        title: rawBaseDocument.title,
        titleAlternative: rawBaseDocument.titleAlternative ?? null,
        titleEnglish: rawBaseDocument.titleEnglish ?? null,
        categories: rawBaseDocument.categories
            ? rawBaseDocument.categories.map(createCategoryFromSdk)
            : [],
        constructionPhases: rawBaseDocument.constructionPhases
            ? rawBaseDocument.constructionPhases.map(
                  createConstructionPhaseFromSdk,
              )
            : [],
        description,
        descriptions,
        language: rawBaseDocument.language ?? undefined,
        sourceUrls: rawBaseDocument.sourceUrls ?? [],
        sourceDomains: rawBaseDocument.sourceDomains ?? [],
        sourcePublishedDate: createNullableBrDateTimeFromSdk(
            rawBaseDocument.sourcePublishedDate ?? null,
        ),
        images: (rawBaseDocument.images ?? []).map(createImageFromSdk),
        contacts: rawBaseDocument.contacts
            ? createContactsFromSdk(rawBaseDocument.contacts)
            : [],
    };
};

export type RawDocument = RawArticle | RawTender;
export type Document = Article | Tender;

export const createDocumentLegacy = (rawDocument: RawDocument): Document => {
    let document: Document;
    switch (rawDocument.type) {
        case DocumentType.Article: {
            document = createArticleLegacy(rawDocument);
            break;
        }
        case DocumentType.Tender: {
            document = createTenderLegacy(rawDocument);
            break;
        }
    }

    return document;
};

export const createDocument = (
    raw: ArticleDocumentPartFragment | TenderDocumentPartFragment,
    docGroupId: string,
    generatedProjectsData?: Map<string, ProjectAiGeneratedData>,
): Document => {
    const documentType = getDocumentTypeFromId(raw.itemId);

    switch (documentType) {
        case DocumentType.Article:
            return createArticle(
                raw as ArticleDocumentPartFragment,
                docGroupId,
                generatedProjectsData,
            );
        case DocumentType.Tender:
            return createTender(
                raw as TenderDocumentPartFragment,
                docGroupId,
                generatedProjectsData,
            );
        default:
            throw new Error(`Document type ${documentType} is not supported`);
    }
};

import { dismissToast } from '@buildingradar/br_component_lib';
import { makeAutoObservable } from 'mobx';

import { ToasterMessage } from 'src/domain/features/toaster/toaster.utils';
import { MediaQuery, checkMediaQuery } from 'src/resources/styles/media.mixins';

import { brToast } from './utils';

export const DEFAULT_SNACKBAR_DURATION = 3000;

export interface ToasterStore {
    showMessage: (message: ToasterMessage) => () => void;
}

export class ToasterStoreImpl implements ToasterStore {
    constructor() {
        makeAutoObservable(this);
    }

    showMessage = (message: ToasterMessage): (() => void) => {
        const isMobile = checkMediaQuery(MediaQuery.phone);
        let closeId: string | number = -1;
        const close = () => {
            dismissToast(closeId);
        };
        closeId = brToast(message, close, isMobile);

        return close;
    };
}

import React from 'react';

import {
    EmptyNotificationsDescription,
    EmptyNotificationsMain,
    EmptyNotificationsStyled,
    EmptyNotificationsTip,
    EmptyNotificationsTitle,
    EmptyNotificationsZero,
    TipBold,
} from './empty-notifications-list.styled';

interface EmptyNotificationsListProps {
    title: string;
    subtitle?: string;
    tip?: {
        tipLabel: string;
        tipText: string;
    };
}

export const EmptyNotificationsList: React.FC<EmptyNotificationsListProps> = ({
    title,
    subtitle,
    tip,
}) => {
    return (
        <EmptyNotificationsStyled>
            <EmptyNotificationsMain>
                <EmptyNotificationsZero>0</EmptyNotificationsZero>
                <EmptyNotificationsTitle>{title}</EmptyNotificationsTitle>
                {subtitle && (
                    <EmptyNotificationsDescription>
                        {subtitle}
                    </EmptyNotificationsDescription>
                )}
            </EmptyNotificationsMain>
            {tip && (
                <EmptyNotificationsTip>
                    <TipBold>{tip.tipLabel}</TipBold> {tip.tipText}
                </EmptyNotificationsTip>
            )}
        </EmptyNotificationsStyled>
    );
};

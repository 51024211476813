import saveAs from 'file-saver';
import QRCode from 'qrcode';
import vCardsJS from 'vcards-js';

export interface CreateContactVCardPayload {
    firstName?: string | null;
    lastName?: string | null;
    organization?: string | null;
    workPhone?: string | null;
    cellPhone?: string | null;
    workEmail?: string | null;
    workAddress?: string | null;
    title?: string | null;
}

const DefaultQrCodeColor = '#0f172a';

export interface ISaveContactToPhoneService {
    /**
     * Build a vCard formatted string based on a DealContact
     */
    buildContactVCard: (payload: CreateContactVCardPayload) => string;

    /**
     * Receives a vCardContent in a string format and a file name and download
     * the file directly from the browser without the need of a server
     */
    downloadVCard: (vCardContent: string, fileName: string) => void;

    /**
     * Builds a Qr Code Url according the passed content and color
     */
    buildQrCodeUrl: (content: string) => Promise<string | undefined>;
}

export const createSaveContactToPhoneService =
    (): ISaveContactToPhoneService => {
        const buildContactVCardObject = (
            payload: CreateContactVCardPayload,
        ) => {
            const vCard = vCardsJS();

            if (payload.firstName) {
                vCard.firstName = payload.firstName;
            }

            if (payload.lastName) {
                vCard.lastName = payload.lastName;
            }

            if (payload.organization) {
                vCard.organization = payload.organization;
            }

            if (payload.workPhone) {
                vCard.workPhone = payload.workPhone;
            }

            if (payload.cellPhone) {
                vCard.cellPhone = payload.cellPhone;
            }

            if (payload.workEmail) {
                vCard.workEmail = payload.workEmail;
            }

            if (payload.title) {
                vCard.title = payload.title;
            }

            if (payload.workAddress) {
                vCard.workAddress.label = 'Contact Work Address';
                vCard.workAddress.street = payload.workAddress;
            }

            vCard.url = window.location.href;

            return vCard;
        };
        const buildContactVCard = (
            payload: CreateContactVCardPayload,
        ): string => {
            return buildContactVCardObject(payload).getFormattedString();
        };

        const downloadVCard = (vCardContent: string, fileName: string) => {
            const blob = new Blob([vCardContent], { type: 'text/vcard' });
            saveAs(blob, `${fileName}.vcf`);
        };

        const buildQrCodeUrl = async (
            content: string,
        ): Promise<string | undefined> => {
            try {
                const qrCodeUrl = await QRCode.toDataURL(content, {
                    color: { dark: DefaultQrCodeColor },
                });
                return qrCodeUrl;
            } catch (error) {
                console.error('Error while generating qr code url', error);
            }
        };

        return {
            buildContactVCard,
            downloadVCard,
            buildQrCodeUrl,
        };
    };

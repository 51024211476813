import { UserSettingName } from 'src/data/api/graphql/br_user/generated/graphql-sdk';
import { SortingSetting } from 'src/domain/models/settings/settings.model';

export enum PerformanceViewVisualizationLayout {
    grid = 'grid',
    table = 'table',
}

/**
 * Describes external sources which may need to trigger a refresh
 * of the pipeline data. This should NOT include sources which are part of
 * table or pipeline data feature directly (Eg.: sorting, pagination, key search),
 * as we can simply call the request directly.
 *
 * This is meant to be used as a event driven approach when we need to refetch
 * from a architectural disconnected place (such as view preferences or dynamic filters feature needing to
 * trigger a table data refresh)
 */
export enum RefetchPipelineViewDataSource {
    viewPreferences = 'viewPreferences',
    dynamicFilters = 'dynamicFilters',
    bulkMoveDeal = 'bulkMoveDeal',
    bulkEditDeals = 'bulkEditDeals',
    switchPipeline = 'switchPipeline',
    tableCellUpdate = 'tableCellUpdate',
}

/**
 * Describes the type of the pipeline request.
 * For now, only two possibilities: deal boxes mode (uses `pipelineStatistics`)
 * or table mode (uses `dealsCollection`)
 */
export enum RefetchPipelineViewDataType {
    pipelineStatistics = 'pipelineStatistics',
    dealsCollection = 'dealsCollection',
}

export interface RefetchPipelineViewDataState {
    active: boolean;
    refetchType?: RefetchPipelineViewDataType;
    source?: RefetchPipelineViewDataSource;
}

type PipelineViewPreference<T> = {
    value?: T;
    setting: UserSettingName;
};

export type ViewPreferenceValueType =
    | PipelineViewPreference<boolean>
    | PipelineViewPreference<string>
    | PipelineViewPreference<string[]>
    | PipelineViewPreference<number>
    | PipelineViewPreference<number[]>
    | PipelineViewPreference<Record<string, string[]>>
    | PipelineViewPreference<SortingSetting[]>;

/**
 * Helper function which returns the fetch type for the pipeline data
 * based on the visualization mode
 * @param viewMode The visualization mode (normally coming from the user preferences)
 * @returns The data pipeline fetch type
 */
export const getPipelineViewDataTypeBasedOnVisualizationMode = (
    viewMode: PerformanceViewVisualizationLayout,
): RefetchPipelineViewDataType => {
    return viewMode === PerformanceViewVisualizationLayout.grid
        ? RefetchPipelineViewDataType.pipelineStatistics
        : RefetchPipelineViewDataType.dealsCollection;
};

/**
 * List of refetch sources that should trigger dynamic filter request
 */
export const RequestDynamicFilterSources: (
    | RefetchPipelineViewDataSource
    | undefined
)[] = [
    RefetchPipelineViewDataSource.bulkMoveDeal,
    RefetchPipelineViewDataSource.bulkEditDeals,
    RefetchPipelineViewDataSource.switchPipeline,
    RefetchPipelineViewDataSource.viewPreferences,
    RefetchPipelineViewDataSource.tableCellUpdate,
    undefined,
];

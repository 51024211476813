import { print } from 'graphql';

import { ProcessGqlSdk } from 'src/data/api/graphql/graphql-client.wrapper';
import {
    ContactExtractionResult,
    ContactExtractionResultStatus,
    DealCompanyCandidate,
    DealContactCandidate,
} from './contact-extraction.model';
import { ContactExtractionStatus as ExtractionAPIResultStatus } from 'src/data/api/graphql/br_process/generated/graphql-sdk';
import { AbortParams } from 'src/data/api/api-client';
import { checkWebSocket } from 'src/utils/websocket.utils';
import { languageModelToRaw } from 'src/domain/models/deal-export/deal-export.model';
import { Language } from 'src/domain/models/locale/locale.model';
import {
    ContactExtractionGetContactsInDealDocument,
    ContactExtractionGetContactsInDealSubscription,
    ExtractContactStatus,
} from 'src/data/api/graphql/br_process/generated/graphql-sdk';

interface GetContactExtractionParams extends AbortParams {
    dealId: string;
    language: Language;
}

interface GetAutoExtractionResultParams extends AbortParams {
    dealId: string;
}

export interface IContactExtractionApi {
    getContactExtractionHybrid: (
        params: GetContactExtractionParams,
    ) => Promise<ContactExtractionResult>;
    getAutoExtractionResult: (
        params: GetAutoExtractionResultParams,
    ) => Promise<ContactExtractionResult>;
    runAutoExtraction: (params: GetContactExtractionParams) => Promise<boolean>;
}

export const createContactExtractionApi = (
    processGqlSdk: ProcessGqlSdk,
): IContactExtractionApi => {
    const getContactExtractionHybrid = async (
        params: GetContactExtractionParams,
    ): Promise<ContactExtractionResult> => {
        if (await checkWebSocket()) {
            return getContactExtractionSubscription(params);
        }
        return getContactExtractionQuery(params);
    };

    const getAutoExtractionResult = async (
        params: GetAutoExtractionResultParams,
    ): Promise<ContactExtractionResult> => {
        const { getContactExtractionResult: result } =
            await processGqlSdk.GetContactExtractionResult(
                {
                    dealId: params.dealId,
                },
                {
                    signal: params.signal,
                },
            );
        const neverRun = result === null;
        const noContacts =
            result?.status === ExtractionAPIResultStatus.Success &&
            !result.extractedCompanies?.length &&
            !result.extractedContacts?.length;
        const status = neverRun
            ? ContactExtractionResultStatus.NeverRun
            : noContacts
              ? ContactExtractionResultStatus.NoContacts
              : extractionStatusMap[
                    result?.status ?? ExtractContactStatus.Error
                ];
        return {
            status,
            contactCandidates: result?.extractedContacts ?? [],
            companyCandidates: result?.extractedCompanies ?? [],
        };
    };

    const runAutoExtraction = async (
        params: GetContactExtractionParams,
    ): Promise<boolean> => {
        const response = await processGqlSdk.RunContactExtractionForDeal(
            {
                dealId: params.dealId,
                language: languageModelToRaw[params.language],
            },
            { signal: params.signal },
        );
        return response.runContactExtractionForDeal.ok;
    };

    const getContactExtractionQuery = async (
        params: GetContactExtractionParams,
    ): Promise<ContactExtractionResult> => {
        const { contactExtractionGetContactsInDeal: result } =
            await processGqlSdk.ContactExtractionGetContactsInDealQuery(
                {
                    dealId: params.dealId,
                    language: languageModelToRaw[params.language],
                },
                { signal: params.signal },
            );
        return {
            status: extractionStatusMap[
                result?.status ?? ExtractContactStatus.Error
            ],
            contactCandidates: result.extractedContacts,
            companyCandidates: result.extractedCompanies,
        };
    };
    const getContactExtractionSubscription = async (
        params: GetContactExtractionParams,
    ): Promise<ContactExtractionResult> => {
        const contactCandidates: DealContactCandidate[] = [];
        const companyCandidates: DealCompanyCandidate[] = [];
        let status: ContactExtractionResultStatus;

        const onExtractedContactsArrived = (
            result: ContactExtractionGetContactsInDealSubscription,
        ) => {
            contactCandidates.push(
                ...(result.contactExtractionGetContactsInDeal
                    ?.extractedContacts ?? []),
            );
            companyCandidates.push(
                ...(result.contactExtractionGetContactsInDeal
                    ?.extractedCompanies ?? []),
            );
            status =
                extractionStatusMap[
                    result.contactExtractionGetContactsInDeal?.status ??
                        ExtractContactStatus.Error
                ];
        };

        return new Promise((resolve, reject) => {
            processGqlSdk.runSubscription<ContactExtractionGetContactsInDealSubscription>(
                {
                    query: print(ContactExtractionGetContactsInDealDocument),
                    variables: {
                        dealId: params.dealId,
                        language: languageModelToRaw[params.language],
                    },
                },
                onExtractedContactsArrived,
                () => resolve({ contactCandidates, companyCandidates, status }),
                (error) => reject(error),
                params.signal,
            );
        });
    };

    return {
        getContactExtractionHybrid,
        getAutoExtractionResult,
        runAutoExtraction,
    };
};

const extractionStatusMap: Record<
    ExtractContactStatus | ExtractionAPIResultStatus,
    ContactExtractionResultStatus
> = {
    [ExtractionAPIResultStatus.Failed]: ContactExtractionResultStatus.Error,
    [ExtractContactStatus.Error]: ContactExtractionResultStatus.Error,
    [ExtractContactStatus.NoContacts]: ContactExtractionResultStatus.NoContacts,
    [ExtractionAPIResultStatus.Success]: ContactExtractionResultStatus.Success,
    [ExtractionAPIResultStatus.InProgress]:
        ContactExtractionResultStatus.InProgress,
};

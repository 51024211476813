import { IconName } from '@buildingradar/br_component_lib';
import { PredefinedCustomFields } from 'src/app-features/contact/data/model/deal-contacts.model';

import {
    ActionFeaturesIdentifiers,
    ContactQuickActionsCategory,
} from 'src/domain/features/account-configuration/helpers/action-feature.helper';
import { CustomParameter } from 'src/domain/models/custom-parameter/custom-parameter.model';

interface FieldQuickAction {
    label: string;
    name: ActionFeaturesIdentifiers;
    icon: IconName;
    tooltip?: string;
    category: ContactQuickActionsCategory;
}

type EmptyFieldQuickAction = Omit<FieldQuickAction, 'label'>;

export interface ContactCardFieldConfig {
    icon: IconName;
    label: string;
    value: string | null;
    name: string;
    quickAction?: FieldQuickAction;
    emptyStateAction?: EmptyFieldQuickAction;
}

const LINKED_IN_URL = 'linkedin.com';

export const isLinkedinUrl = (url?: string | null) =>
    (url ?? '').includes(LINKED_IN_URL);

const buildContactProviderEmptyActionConfig = (
    actionFeatureEnabledChecker: (
        actionIdentifier: ActionFeaturesIdentifiers,
    ) => boolean,
): EmptyFieldQuickAction | undefined => {
    const isContactEnrichmentEnabled = actionFeatureEnabledChecker(
        ActionFeaturesIdentifiers.contact_enrichment,
    );
    return isContactEnrichmentEnabled
        ? {
              name: ActionFeaturesIdentifiers.contact_enrichment,
              icon: IconName.BrContactSearchIcon,
              tooltip:
                  'deal_view.contacts_section.quick_actions.contact_data_provider.tooltip',
              category: ContactQuickActionsCategory.Research,
          }
        : undefined;
};

const buildGoogleEnrichmentEmptyActionConfig = (
    actionFeatureEnabledChecker: (
        actionIdentifier: ActionFeaturesIdentifiers,
    ) => boolean,
): EmptyFieldQuickAction | undefined => {
    const isGoogleEnrichmentEnabled = actionFeatureEnabledChecker(
        ActionFeaturesIdentifiers.google_enrichment,
    );
    return isGoogleEnrichmentEnabled
        ? {
              name: ActionFeaturesIdentifiers.google_enrichment,
              icon: IconName.BrCompanyIcon,
              tooltip:
                  'deal_view.contacts_section.quick_actions.google_enrichment.tooltip',
              category: ContactQuickActionsCategory.Research,
          }
        : undefined;
};

export const getContactCardFieldConfigs = (
    isPerson: boolean,
    parameters: CustomParameter[],
    isVPOEnabled: boolean,
    actionFeatureEnabledChecker: (
        actionIdentifier: ActionFeaturesIdentifiers,
    ) => boolean,
): ContactCardFieldConfig[] =>
    parameters
        .slice()
        .sort((a, b) => a.order - b.order)
        .map((param) => {
            let icon: IconName;
            let quickAction: FieldQuickAction | undefined = undefined;
            let emptyStateAction: EmptyFieldQuickAction | undefined = undefined;

            switch (param.name) {
                case PredefinedCustomFields.position:
                    icon = IconName.BrWorkOutlineIcon;
                    break;
                case PredefinedCustomFields.mobile:
                    icon = IconName.BrMobileDeviceIcon;
                    if (isPerson) {
                        quickAction = {
                            label: 'deal_view.contacts_section.quick_actions.save_contact',
                            name: ActionFeaturesIdentifiers.save_contact_to_phone,
                            icon: IconName.BrSendToPhoneIcon,
                            category: ContactQuickActionsCategory.Outreach,
                        };
                        emptyStateAction =
                            buildContactProviderEmptyActionConfig(
                                actionFeatureEnabledChecker,
                            );
                    }
                    break;
                case PredefinedCustomFields.telephone:
                    icon = IconName.BrCallIcon;
                    if (isPerson) {
                        quickAction = {
                            label: 'deal_view.contacts_section.quick_actions.save_contact',
                            name: ActionFeaturesIdentifiers.save_contact_to_phone,
                            icon: IconName.BrSendToPhoneIcon,
                            category: ContactQuickActionsCategory.Outreach,
                        };
                        emptyStateAction =
                            buildContactProviderEmptyActionConfig(
                                actionFeatureEnabledChecker,
                            );
                    } else {
                        emptyStateAction =
                            buildGoogleEnrichmentEmptyActionConfig(
                                actionFeatureEnabledChecker,
                            );
                    }
                    break;
                case PredefinedCustomFields.mail:
                    icon = IconName.BrEmailIcon;
                    if (isPerson) {
                        if (isVPOEnabled) {
                            quickAction = {
                                label: 'deal_view.contacts_section.quick_actions.value_prop_outreach',
                                name: ActionFeaturesIdentifiers.value_prop_outreach,
                                icon: IconName.BrEmailBoltIcon,
                                category: ContactQuickActionsCategory.Outreach,
                            };
                        }
                        emptyStateAction =
                            buildContactProviderEmptyActionConfig(
                                actionFeatureEnabledChecker,
                            );
                    }
                    break;
                case PredefinedCustomFields.url:
                    icon = IconName.BrGlobeIcon;
                    quickAction = {
                        label: 'deal_view.contacts_section.custom_fields.tooltips.open_in_new_tab',
                        name: ActionFeaturesIdentifiers.direct_weblink,
                        icon: IconName.BrOpenInNewIcon,
                        category: ContactQuickActionsCategory.Research,
                    };
                    if (!isPerson) {
                        emptyStateAction =
                            buildGoogleEnrichmentEmptyActionConfig(
                                actionFeatureEnabledChecker,
                            );
                    }
                    break;
                case PredefinedCustomFields.address:
                    icon = IconName.BrPlaceOutlinedIcon;
                    quickAction = {
                        label: 'deal_view.contacts_section.custom_fields.tooltips.open_in_new_tab',
                        name: ActionFeaturesIdentifiers.direct_google_maps,
                        icon: IconName.BrOpenInNewIcon,
                        category: ContactQuickActionsCategory.Research,
                    };
                    if (!isPerson) {
                        emptyStateAction =
                            buildGoogleEnrichmentEmptyActionConfig(
                                actionFeatureEnabledChecker,
                            );
                    }
                    break;
                case PredefinedCustomFields.linkedin:
                    icon = IconName.BrLinkedinIcon;
                    quickAction = {
                        label: 'deal_view.contacts_section.custom_fields.tooltips.open_in_new_tab',
                        name: ActionFeaturesIdentifiers.direct_weblink,
                        icon: IconName.BrOpenInNewIcon,
                        category: ContactQuickActionsCategory.Research,
                    };
                    emptyStateAction = {
                        tooltip:
                            'deal_view.contacts_section.custom_fields.tooltips.lookup_linkedin',
                        name: ActionFeaturesIdentifiers.linkedin_lookup,
                        icon: IconName.BrLinkedinIcon,
                        category: ContactQuickActionsCategory.Research,
                    };
                    break;
                default:
                    icon = IconName.BrCategoryOutlinedIcon;
                    break;
            }
            return {
                icon,
                name: param.name,
                label: param.label,
                value: param.value,
                quickAction,
                emptyStateAction,
            };
        });

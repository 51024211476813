/* eslint-disable @typescript-eslint/ban-ts-comment */
import { observer } from 'mobx-react';
import { FC } from 'react';

import { AppFeatures } from 'src/app-context/app-context';
import { useFeatures } from 'src/app-context/use-features';

export const withFeatures = <T,>(Component: FC<T>) => {
    return <K extends keyof T>(
        getProps: (features: AppFeatures) => Pick<T, K>,
    ) => {
        return observer((containerProps: Omit<T, K>) => {
            const features = useFeatures();
            const props = getProps(features);
            /* @ts-ignore */
            return <Component {...props} {...containerProps} />;
        });
    };
};

import { makeAutoObservable } from 'mobx';

import {
    createFolderCounters,
    FolderCounters,
} from 'src/domain/models/folder/folder.model';

export interface FolderCountersStore {
    folderCounters: FolderCounters;
    isLoading: boolean;
    setLoading: (loading: boolean) => void;
    setFolderCounters: (counters: FolderCounters) => void;
    updateFolderCounters: (counters: FolderCounters) => void;
}

export class FolderCountersStoreImpl implements FolderCountersStore {
    folderCounters = createFolderCounters({});
    isLoading = false;

    setFolderCounters = (counters: FolderCounters) => {
        this.folderCounters = counters;
    };

    setLoading = (loading: boolean) => {
        this.isLoading = loading;
    };

    updateFolderCounters = (counters: FolderCounters) => {
        if (!counters.inbox) {
            return;
        }
        for (const searchId in counters.inbox) {
            if (
                Object.prototype.hasOwnProperty.call(counters.inbox, searchId)
            ) {
                this.folderCounters.inbox[searchId] += counters.inbox[searchId];
            }
        }
    };

    constructor() {
        makeAutoObservable(this);
    }
}

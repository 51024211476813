import { BrTextInput } from '@buildingradar/br_component_lib';
import styled from '@emotion/styled';
import { Box } from '@mui/material';
import zIndex from '@mui/material/styles/zIndex';

import { TextPreviewStyled } from 'src/presentation/shared/text-preview/styles';

export const FormInputWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`;

export const FormTextFieldStyled = styled(BrTextInput)`
    input, .MuiInputBase-multiline {
        padding: 8px 12px;
        height: auto;
        line-height: 24px;
    }

    label {
        transform: translate(12px, 8.5px) scale(1); !important;
    }

    .MuiFormHelperText-root{
        position: absolute;
        bottom: -20px;
    }

    z-index: ${zIndex.drawer}
`;

export const NoteFieldStyled = styled(Box)({
    [`${TextPreviewStyled}:after`]: {
        borderWidth: '1px !important',
    },
});

import { makeAutoObservable } from 'mobx';

import { AssociatedCompaniesStore } from 'src/data/stores/associated-companies/associated-companies.store';
import { Contact } from 'src/domain/models/contact/contact.model';
import { Lead } from 'src/domain/models/lead/lead.model';

import { sortCompaniesByDataAndAlphabets } from './associated-companies.util';

export interface AssociatedCompaniesFeature {
    companiesWithContacts: Contact[];
    totalCompaniesCount: number;
    loadAssociatedCompanies: (lead: Lead) => void;
    showMoreItems: () => void;
    showLessItems: () => void;
}

export const ITEMS_PER_LOAD = 6;
export class AssociatedCompaniesFeatureImpl
    implements AssociatedCompaniesFeature
{
    public currentPage = 1;

    public get companiesWithContacts() {
        const associatedCompanies = [
            ...this.associatedCompaniesStore.associatedCompanies,
        ];
        const sortedCompanies =
            sortCompaniesByDataAndAlphabets(associatedCompanies);
        return sortedCompanies.slice(0, ITEMS_PER_LOAD * this.currentPage);
    }

    public get totalCompaniesCount() {
        return this.associatedCompaniesStore.associatedCompanies.length;
    }

    constructor(
        private readonly associatedCompaniesStore: AssociatedCompaniesStore,
    ) {
        makeAutoObservable(this);
    }

    public showMoreItems = () => {
        this.currentPage += 1;
    };

    public showLessItems = () => {
        this.currentPage = 1;
    };

    public loadAssociatedCompanies = (lead: Lead) => {
        const { contacts } = lead.mergedDocument;
        this.associatedCompaniesStore.setAssociatedCompanies(contacts);
    };
}

import { css } from '@emotion/react';

import { MediaQuery } from 'src/resources/styles/media.mixins';
import { Spacing } from 'src/resources/styles/spacing';

export const WrapperBorderRadius = css`
    border-top-left-radius: ${Spacing.s2};
    border-bottom-left-radius: ${Spacing.s2};

    ${MediaQuery.tablet} {
        border-radius: 0;
    }
`;

import { css } from '@emotion/react';

const colors = css`
    --dark-background: #2a2836;

    // @TODO change color names to be not-related to real colors,
    // so remove 'gray', white, light etc.
    // consider using 'primary/secondary' background, may be 'tertiary'
    // or 'main' 'additional', or anything else...
    // it's important to make theming better in future
    --accent: #2f1fa5;
    --accent-active: #211271;
    --accent-text: #2b2893;
    --gray-text: #6b6e78;
    --dark-gray: #434754;
    --gray: #94959e;
    --light-gray: #c9cace;
    --border-color: #d2d7fb;
    --light-border: #bcbcef;
    --light-text-color: white;
    --white-background: white;
    --light-background: #e4e5ea;
    --primary-color-b-10: #eff1ff;
    --red: #ef3131;
    --dark-border: #d8d8d8;

    // login page
    --light-border-color: var(--primary-color-b-10);

    // usage-colors
    --primary-text: var(--dark-gray);
    --gray-icon-color: #878d95;
    --error-color: var(--red);
    --button-gray-bg: #dde1e6;
    --lead-cart-border: var(--primary-color-b-10);
    --button-bg: #dadaff;
    --button-active-bg: #bbbbfa;
    --sidebar-background: var(--dark-background);
    --border-hover: var(--light-border);
    --secondary-text: var(--gray);

    // comments-colors
    --comment-border: var(--dark-border);
    --section-border: var(--primary-color-b-10);
    --mentions-background: var(--primary-color-b-10);

    // deal colors
    --error-button-color-hover: #e02020;
    --undecided-color: #fac710;
    --regular-deal-color: #5455fb;
    --weaker-deal-color: #9e9ffc;
    --stale-deal-color: #ffd200;
    --demand-deal-color: #fed7da;
    --demand-deal-border-color: #d8222f;
    --anticipated-deal-border-color: #a2a9b0;

    // new design variables
    --accent-light-background: rgba(242, 243, 255, 0.5);
    --accent-background: #f2f3ff;
    --accent-button-text: #3027a2;
    --accent-action-button: rgba(84, 85, 251, 1);
    --accent-button-disabled: rgba(84, 85, 251, 0.5);
    --accent-action-button-hover: #3d39d8;
    --accent-active-action-button: #dddffe;
    --orange-action-button: #ff4d00;
    --orange-action-button-hover: #e53801;
    --black-text: #121719;
    --button-bg-disabled: #f2f4f8; // gray 10
    --accent-link: rgba(84, 85, 251, 1);
    --accent-3: rgba(84, 85, 251, 0.3);
    --accent-4: rgba(84, 85, 251, 0.4);
    --white: #fff;
    --gray-background: var(--button-bg-disabled);
    --main-font: #343a3f; // gray 80
    --background-hover: var(--button-gray-bg);
    --unseen-marker: #f6173b;
    --light-danger: #fed7da;
    --unseen-marker-border: #f2dee6;
    --gray-text-color: #697077; // gray 60
    --gray-button-outline: var(--gray-text-color);
    --page-background: #f7f7f7;
    --gray-border-color: #dde1e6; // gray 20
    --gray-icon-disabled: #c1c7cc;
    --darkest-gray: #4e5358; // gray 70
    --mid-gray: #878d95;
    --font-icon-bg-hover: #ebebeb;
    --gray-font-icon-bg-hover: #dfe1e5;
    --text-disabled: #a2a9b0; // gray 40
    --text-disabled-hover: #95989b;
    --yellow-icon-bg: #fff597;
    --yellow-icon-bg-hover: #ffef63;
    --green-button: #27bd60;
    --green-chateau: #3da765;
    --yellow-golden: #edcb34;
    --blue-cornflower: #7b75f3;
    --red-rose: #e8529d;
    --yellow-orange: #fbad42;
    --light-yellow-bg: #fce7a1;
    --blue-jelly-bean: #288ea2;
    --green-apple: #65ba3f;
    --pink-geraldine: #fb8280;
    --blue-steel: #447aba;
    --green-atlantis: #a5c530;
    --purple-heliotrope: #e281fc;
    --red-amaranth: #e63b53;
    --pink-lavender: #fba9e3;
    --green-button-hover: rgba(61, 167, 101, 1);
    --green-button-3: rgba(61, 167, 101, 0.3);
    --green-button-4: rgba(61, 167, 101, 0.4);
    --green-button-disabled: rgba(39, 189, 96, 0.5);
    --green-confirm: rgba(72, 189, 105, 1);
    --light-green: rgba(223, 251, 231, 1);
    --disqualified-button: #ff6a00;
    --disqualified-button-hover: rgba(255, 76, 0, 1);
    --disqualified-button-3: rgba(255, 76, 0, 0.3);
    --disqualified-button-4: rgba(255, 76, 0, 0.4);
    --disqualified-button-disabled: rgba(255, 106, 0, 0.5);
    --gray-button-disabled: rgba(162, 169, 176, 0.5);
    --toggle-white: #f1f1f1;
    --toggle-shadow-active: rgba(84, 85, 251, 0.1);
    --toggle-track-active: #bfc1fd;
    --blue-cornflower: #5455fb;
    --midnight-blue: #21186f;
    --gray-button-hover: #ffffff14;
    --light-yellow: #fff8c7;
    --failure-red: #f84f5a;
    --pastel-red: #fff1f1; // Red 10
    --pastel-green: #dffbe7; // Green 10
    --pastel-dark-green: #2ca04c; // Green 50
    --green-30: #73db8f; // Green 30
    --soft-gray: #f4f5f8;
    --newsfeed-bg: #f2f4f8;
    --vanta-orange: #ff4c00;
    --dark-green: #207f3b;
    --light-gray-bg: #c4c4c4;
    --lime-green: #a9efbc;
    --gray-transparent: #1217190a;
    --drawer-bg: #f2f4f8;
    --lite-gray: #f1f2f4;
    --dark-lite-gray: #878d96;
    --coral-red: #fd848b;
    --gray-lite: #c3cbd5;
    --building-radar-color: #f6173b;
    --gpt-powered: #dd6bde;
    --magenta-dark: #e6147d;
    --magenta-medium: #feb8db;
    --magenta-light: #FCEEF;
    --charcoal-lite: #3f4b5a;

    // system design colors

    --white: #ffffff;
    --black: #000000;

    --gray-10: #f2f4f8;
    --gray-20: #dde1e6;
    --gray-30: #c1c7cc;
    --gray-40: #a2a9b0;
    --gray-50: #878d95;
    --gray-60: #697077;
    --gray-70: #4e5358;
    --gray-80: #343a3f;
    --gray-90: #212729;
    --gray-100: #121719;

    --blue-10: #f2f3ff;
    --blue-20: #dddffe;
    --blue-30: #bfc1fd;
    --blue-40: #9e9ffc;
    --blue-50: #7a7cfb;
    --blue-60: #5455fb;
    --blue-70: #3d39d8;
    --blue-80: #3027a2;
    --blue-90: #21186f;
    --blue-100: #140e43;

    --green-10: #dffbe7;
    --green-20: #a9efbc;
    --green-30: #73db8f;
    --green-40: #48bd69;
    --green-50: #2ca04c;
    --green-60: #207f3b;
    --green-70: #145f2a;
    --green-80: #084319;
    --green-90: #042d0e;
    --green-100: #081909;

    --yellow-10: #fff8c7;
    --yellow-20: #fff4a2;
    --yellow-30: #fff07f;
    --yellow-40: #ffed65;
    --yellow-50: #feea4e;
    --yellow-60: #fcd748;
    --yellow-70: #fabf40;
    --yellow-80: #f7a738;
    --yellow-90: #f37f2b;
    --yellow-100: #ea690c;

    --red-10: #fff1f1;
    --red-20: #fed7da;
    --red-30: #feb4b9;
    --red-40: #fd848b;
    --red-50: #f84f5a;
    --red-60: #d8222f;
    --red-70: #a01b24;
    --red-80: #741017;
    --red-90: #51050b;
    --red-100: #2d070a;
`;

export const themeStyles = css`
    // base colors
    html {
        ${colors};
    }
`;

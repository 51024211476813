import { withFeatures } from 'src/utils/component.utils';

import { ImpersonationHeaderComponent } from './impersonation-header.component';

export const ImpersonationHeaderContainer = withFeatures(
    ImpersonationHeaderComponent,
)((f) => ({
    user: f.authFeature.user,
    isLoading: f.authFeature.isLoading,
    impersonateUser: f.authFeature.impersonateUser,
    endImpersonation: f.authFeature.endImpersonation,
}));

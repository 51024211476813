import styled from '@emotion/styled';
import { Box } from '@mui/material';

export const ArticleWizardInfoStyled = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '36px',
});

export const ArticleWizardErrorIconStyled = styled(Box)({
    borderRadius: '50%',
    backgroundColor: 'var(--yellow-10)',
    width: '64px',
    height: '64px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '16px',
});

export const XRayAnswerListWrapper = styled(Box)({
    display: 'flex',
    width: '100%',
    justifySelf: 'flex-start',
    alignItems: 'center',
    flexWrap: 'wrap',
});

import { Fragment } from 'react';

import { isEmail } from 'src/utils/email.utils';
import { addSpaceAroundControlCharacters } from 'src/utils/string.utils';
import { getUrlMatch } from 'src/utils/url.utils';

import { LinkStyled } from './styles';

export const textToHTML = (
    text: string,
    onLinkClicked: (url: string) => void,
) =>
    addSpaceAroundControlCharacters(text)
        .split(' ')
        .map((word: string, idx: number) => {
            const isNotLast = idx !== text.length;

            if (word === '\n') {
                return <br key={idx} />;
            }

            const urlMatch = getUrlMatch(word);
            let htmlComponent;

            if (urlMatch) {
                htmlComponent = (
                    <>
                        <LinkStyled
                            href={urlMatch}
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                onLinkClicked(urlMatch);
                            }}
                        >
                            {word}
                        </LinkStyled>
                        {isNotLast && ' '}
                    </>
                );
            } else if (isEmail(word)) {
                htmlComponent = (
                    <>
                        <LinkStyled
                            href={`mailto:${word}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                        >
                            {word}
                        </LinkStyled>
                        {isNotLast && ' '}
                    </>
                );
            } else {
                htmlComponent = (
                    <>
                        <span key={word}>{word}</span>
                        {isNotLast && ' '}
                    </>
                );
            }

            return <Fragment key={idx}>{htmlComponent}</Fragment>;
        });

import { observer } from 'mobx-react';
import { FC, useCallback, useState } from 'react';

import { User } from 'src/domain/models/user/user.model';
import { MobileMenuContentComponent } from 'src/presentation/modules/mobile-menu/components/mobile-menu-content/mobile-menu-content.component';
import { MoreSubmenuComponent } from 'src/presentation/modules/mobile-menu/components/more-submenu/more-submenu.component';
import { NotificationsPanelContainer } from 'src/presentation/modules/notifications-flyout/components/notifications-panel/notifications-panel.container';

import { MobileMenuWrapper } from './mobile-menu.styled';

interface MobileMenuComponentProps {
    currentRoute: string;
    areNotificationsOpened: boolean;
    user: User;
    toggleMenuOpen: (flag: boolean) => void;
    toggleNotifications: (flag: boolean) => void;
}

export const MobileMenuComponent: FC<MobileMenuComponentProps> = observer(
    ({
        user,
        toggleMenuOpen,
        currentRoute,
        toggleNotifications,
        areNotificationsOpened,
    }) => {
        const [isMoreOpened, setMoreOpened] = useState(false);

        const closeMenu = useCallback(() => {
            toggleMenuOpen(false);
            toggleNotifications(false);
        }, [toggleMenuOpen, toggleNotifications]);

        const closeMore = useCallback(() => setMoreOpened(false), []);
        const openMore = useCallback(() => setMoreOpened(true), []);
        const openNotifications = useCallback(
            () => toggleNotifications(true),
            [toggleNotifications],
        );

        if (areNotificationsOpened) {
            return (
                <MobileMenuWrapper>
                    <NotificationsPanelContainer />
                </MobileMenuWrapper>
            );
        }

        if (isMoreOpened) {
            return (
                <MobileMenuWrapper>
                    <MoreSubmenuComponent
                        user={user}
                        onCloseMore={closeMore}
                        onLinkClick={closeMenu}
                        currentRoute={currentRoute}
                    />
                </MobileMenuWrapper>
            );
        }

        return (
            <MobileMenuWrapper>
                <MobileMenuContentComponent
                    user={user}
                    openNotifications={openNotifications}
                    openMore={openMore}
                    areNotificationsOpened={areNotificationsOpened}
                    currentRoute={currentRoute}
                    closeMenu={closeMenu}
                />
            </MobileMenuWrapper>
        );
    },
);

import { BrTextInputProps } from '@buildingradar/br_component_lib';
import { observer } from 'mobx-react';
import { FC, useCallback, useState } from 'react';

import { ConcreteParameterProps } from 'src/presentation/modules/deal-view/components/deal-custom-parameter/deal-custom-parameter.component';
import { TextInput } from 'src/presentation/shared/text-input/text-input';
import { TextPreview } from 'src/presentation/shared/text-preview/text-preview.component';

interface StringParameterComponentProps
    extends ConcreteParameterProps,
        BrTextInputProps {
    name: string;
    autoFocus?: boolean;
    placeholder?: string;
    multiline?: boolean;
    setClickedLink: (link: string) => void;
}

const STRING_PARAMETER_FIELD_MAX_CHAR = 5000;
export const StringParameterComponent: FC<StringParameterComponentProps> =
    observer(
        ({
            value,
            name,
            placeholder,
            autoFocus,
            isLinkedToObjective,
            status = 'idle',
            multiline = false,
            helperText,
            type,
            setValue,
            setClickedLink,
        }) => {
            const [isPreviewMode, setIsPreviewMode] = useState<boolean>(
                autoFocus ? false : true,
            );

            const blurEventHandler = useCallback(() => {
                setIsPreviewMode(true);
            }, []);

            return (
                <>
                    {!isPreviewMode ? (
                        <TextInput
                            name={name}
                            placeholder={placeholder}
                            value={value}
                            blurOnEnter
                            focusInput
                            maxLength={STRING_PARAMETER_FIELD_MAX_CHAR}
                            className="value"
                            hasPermanentBorder
                            isHighlighted={isLinkedToObjective}
                            helperText={helperText}
                            status={status}
                            multiline={multiline}
                            onChange={setValue}
                            onBlur={blurEventHandler}
                            type={type}
                        />
                    ) : (
                        <TextPreview
                            multiline={multiline}
                            value={value}
                            isHighlighted={isLinkedToObjective}
                            isPreviewMode={isPreviewMode}
                            status={status}
                            helperText={helperText}
                            placeholder={placeholder}
                            onClick={() => setIsPreviewMode(false)}
                            onLinkClicked={setClickedLink}
                            obscure={type === 'password'}
                        />
                    )}
                </>
            );
        },
    );

import styled from '@emotion/styled';
import { Box } from '@mui/material';

import { Spacing } from 'src/resources/styles/spacing';

export const DataProviderContentStyled = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    gap: Spacing.s3,
    paddingBottom: Spacing.s2,
});

export const DataProviderFieldStyled = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    gap: Spacing.s1,
    marginBottom: Spacing.s1,
});

export const DataProviderHeaderStyled = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    marginBottom: Spacing.s2,
});

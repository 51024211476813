import {
    TenderChildCodePartFragment,
    TenderRootCodePartFragment,
} from 'src/data/api/graphql/br_search/generated/graphql-sdk';

export interface TenderRootCode {
    id: string;
    name: string;
    hasChildren: boolean;
    isRoot: boolean;
    children: TenderChildCode[];
}

export interface TenderChildCode {
    id: string;
    name: string;
    hasChildren: boolean;
    numberOfChildren: number;
    isLeaf: boolean;
    isRoot: boolean;
    procurementSystem: string;
    parent?: string;
    children?: TenderChildCode[];
}

export const createTenderRootCode = (
    raw: TenderRootCodePartFragment,
): TenderRootCode => {
    return {
        id: raw.id,
        name: raw.name,
        hasChildren: raw.hasChildren,
        isRoot: raw.root,
        children: (raw.children ?? []).map((child) =>
            createTenderChildCode(child as TenderChildCodePartFragment),
        ),
    };
};

export const createTenderChildCode = (
    raw: TenderChildCodePartFragment,
): TenderChildCode => {
    return {
        id: raw.id,
        name: raw.name,
        hasChildren: raw.hasChildren,
        numberOfChildren: raw.nChildren,
        isLeaf: raw.leaf,
        isRoot: raw.root,
        procurementSystem: raw.procurementSystem,
        parent: raw.parent ?? undefined,
        children: raw.children?.map((child) =>
            createTenderChildCode(child as TenderChildCodePartFragment),
        ),
    };
};

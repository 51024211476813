import { AbortParams } from 'src/data/api/api-client';
import { SearchGqlSdk } from 'src/data/api/graphql/graphql-client.wrapper';
import {
    createSearchFromSubscribedSearch,
    Search,
} from 'src/domain/models/search/search.model';
import {
    createSubscription,
    SearchSubscriptionData,
} from 'src/domain/models/subscription/subscription.model';

export interface SubscriptionApi {
    getUserSubscriptions: (
        params: GetParams,
    ) => Promise<SearchSubscriptionData[]>;
    subscribeToSearch: (
        params: SubscribeOrUnsubscribeToSearchParams,
    ) => Promise<Search>;
    unsubscribeFromSearch: (
        params: SubscribeOrUnsubscribeToSearchParams,
    ) => Promise<boolean>;
    pauseSearch: (params: UpdateSearchParams) => Promise<boolean>;
    resumeSearch: (params: UpdateSearchParams) => Promise<boolean>;
}

type GetParams = AbortParams;

interface SubscribeOrUnsubscribeToSearchParams extends AbortParams {
    subscriberId: number;
    searchId: number;
}

interface UpdateSearchParams extends AbortParams {
    searchId: number;
}

export const createSubscriptionsApi = (
    searchGqlSdk: SearchGqlSdk,
): SubscriptionApi => {
    const getUserSubscriptions = async () => {
        const { searchesAndSubscriptions } =
            await searchGqlSdk.GetSearchesAndSubscriptions();
        return searchesAndSubscriptions.map(createSubscription);
    };

    const subscribeToSearch = async (
        params: SubscribeOrUnsubscribeToSearchParams,
    ) => {
        const { subscribeSearch } =
            await searchGqlSdk.SubscribeToSearch(params);
        return createSearchFromSubscribedSearch(subscribeSearch);
    };

    const unsubscribeFromSearch = async (
        params: SubscribeOrUnsubscribeToSearchParams,
    ) => {
        const { unsubscribeSearch } =
            await searchGqlSdk.UnsubscribeToSearch(params);
        return unsubscribeSearch.ok;
    };

    const pauseSearch = async (params: UpdateSearchParams) => {
        const { pauseSearch } = await searchGqlSdk.PauseSearch(params);
        return pauseSearch.ok;
    };

    const resumeSearch = async (params: UpdateSearchParams) => {
        const { unpauseSearch } = await searchGqlSdk.UnpauseSearch(params);
        return unpauseSearch.ok;
    };

    return {
        getUserSubscriptions,
        subscribeToSearch,
        unsubscribeFromSearch,
        pauseSearch,
        resumeSearch,
    };
};

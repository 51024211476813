import { makeAutoObservable } from 'mobx';

import { ToasterStore } from 'src/data/stores/toaster/toaster.store';

import { ToasterMessage } from './toaster.utils';

export interface ToasterFeature {
    showMessage: (message: ToasterMessage) => void;
}

export class ToasterFeatureImpl implements ToasterFeature {
    constructor(private toasterStore: ToasterStore) {
        makeAutoObservable(this);
    }

    public showMessage = (message: ToasterMessage) => {
        this.toasterStore.showMessage(message);
    };
}

import { BrInfotip, BrText } from '@buildingradar/br_component_lib';
import { observer } from 'mobx-react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import {
    DataProviderLogo,
    DataProviderSource,
} from 'src/app-features/contact-enrichment/domain/contact-enrichment.model';

import { EnrichmentSourceCountStyled, SourcesListStyled } from './styles';

interface EnrichmentSourcesBadgeComponentProps {
    providers?: DataProviderSource[];
    highlight?: boolean;
}
export const EnrichmentSourcesBadgeComponent: FC<EnrichmentSourcesBadgeComponentProps> =
    observer(({ providers = [], highlight = false }) => {
        const { t } = useTranslation();
        return (
            <BrInfotip
                showArrow
                disableInteractive
                content={
                    <SourcesListStyled>
                        <BrText sx={{ margin: '5px' }}>
                            {t(
                                'actions.contact_enrichment.result.sources_badge_title',
                                {
                                    value: providers.length,
                                },
                            )}
                        </BrText>
                        {providers.map((provider) => (
                            <img
                                key={provider}
                                alt={provider}
                                src={DataProviderLogo[provider]}
                                style={{
                                    paddingLeft: '4px',
                                    maxWidth: '128px',
                                    height: '24px',
                                    objectFit: 'contain',
                                }}
                            />
                        ))}
                    </SourcesListStyled>
                }
            >
                <EnrichmentSourceCountStyled
                    className="provider-count-badge"
                    highlight={highlight}
                >
                    {providers.length}
                </EnrichmentSourceCountStyled>
            </BrInfotip>
        );
    });

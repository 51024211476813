import { copyValueToClipboard } from 'src/utils/string.utils';

export const copyEmailToClipboard = (
    email: string | undefined,
    subject: string | undefined,
    body: string | undefined,
) => {
    const emailAddress = email ? `${email}\n\n` : '';
    const subjectContent = subject ? `${subject}\n\n` : '';
    const copiedContent = `${emailAddress}${subjectContent}${body}`;

    copyValueToClipboard(copiedContent);
};

import styled from '@emotion/styled';
import { Box } from '@mui/material';

export const ContactDetailPanelActionBodyStyled = styled(Box)({
    width: '100%',
    height: 'auto',
    overflowY: 'auto',
});

export const ContactDetailPanelTabContentStyled = styled(Box)({
    overflowY: 'auto',
    maxHeight: 'calc(100vh - 60px - 50px - 144px)',
    paddingLeft: '8px',
    paddingRight: '16px',
    marginRight: '-16px',
    paddingBottom: '12px',
});

export const ContactDetailPanelHeaderStyled = styled(Box)({
    width: '100%',
    height: 'auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: '10px',
});

import styled from '@emotion/styled';

export const MobileMenuHeaderWrapper = styled.div`
    display: flex;
    justify-content: center;
    position: relative;
    width: 100%;
    height: 64px;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.4px;
    color: var(--main-font);
`;

export const CloseMenuWrapperStyled = styled.div`
    position: absolute;
    left: 16px;
`;

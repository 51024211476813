import { makeAutoObservable } from 'mobx';

import { CustomFieldsApi } from 'src/data/api/custom-fields/custom-fields.api';
import { CustomFieldsStore } from 'src/data/stores/custom-fields/custom-fields.store';
import { IBaseStore } from 'src/data/stores/shared/base.store.interface';
import { UserStore } from 'src/data/stores/user/user.store';
import { Lead } from 'src/domain/models/lead/lead.model';
import { User } from 'src/domain/models/user/user.model';
import { doNothing } from 'src/utils/function.utils';
import { Cancellable, handleRequest } from 'src/utils/handle-request.utils';

export interface CustomFieldsFeature {
    saveCustomTitle: (lead: Lead, value: string) => Cancellable;
    titleMap: Map<string, string>;
}

export class CustomFieldsFeatureImpl implements CustomFieldsFeature {
    get me(): User | undefined {
        return this.userStore.user;
    }

    get titleMap() {
        return this.customFieldsStore.titleMap;
    }

    constructor(
        private customFieldsApi: CustomFieldsApi,
        private userStore: UserStore,
        private customFieldsStore: CustomFieldsStore,
        private baseStore: IBaseStore,
    ) {
        makeAutoObservable(this);
    }

    saveCustomTitle = (lead: Lead, value: string) => {
        return handleRequest(
            this.customFieldsApi.updateCustomTitle,
            {
                projectId: Number(lead.id),
                title: value,
            },
            () =>
                this.customFieldsStore.setLeadCustomFieldValue(
                    lead,
                    'title',
                    value,
                ),
            doNothing,
            (error) =>
                this.baseStore.onRequestFailed('custom-title-save', error),
        );
    };
}

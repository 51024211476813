import { AbortParams } from 'src/data/api/api-client';
import { doNothing } from './function.utils';

/**
 * Interface for any async action which could be canceled
 */
export interface Cancellable {
    cancel: () => void;
}

const RETRY_ERRORS = ['WriteConflict'];

const ABORT_ERROR_TEXT = [
    'The user aborted a request.',
    'Aborted',
    'The operation was aborted. ',
];

const abortMap: Map<string, AbortController> = new Map();

export const isAbortError = (error: Error) =>
    ABORT_ERROR_TEXT.includes(error.message);

export const emptyCancellable: Cancellable = { cancel: () => doNothing };

export const abortOngoingRequests = (requestName: string) => {
    abortMap.get(requestName)?.abort();
};

/**
 * Helps to make requests, store errors and loading state
 * @param request
 * @param requestParams
 * @param setValue
 * @param setLoading
 * @param setError
 * @returns Cancellable object with 'cancel' method to abort request
 */
export const handleRequest = <TParams extends object, TReturn>(
    request: (params: TParams & AbortParams) => Promise<TReturn>,
    params: TParams,
    onResponseHandler: (value: TReturn) => void,
    setLoading?: (flag: boolean) => void,
    setError?: (error?: Error) => void,
    requestName?: string,
): Cancellable => {
    let retryCount = 1;
    const abortController = new AbortController();
    setLoading && setLoading(true);
    setError && setError(undefined);

    if (requestName) {
        abortOngoingRequests(requestName);
        abortMap.set(requestName, abortController);
    }

    const catchError = (error: Error) => {
        setError && setError(error);
        if (!isAbortError(error)) {
            setLoading && setLoading(false);
        }
    };
    const onResponse = (value: TReturn) => {
        onResponseHandler(value);
        setLoading && setLoading(false);
    };

    const retryHandler = (error: Error) => {
        if (
            retryCount > 0 &&
            RETRY_ERRORS.some((retryErrorMessage) =>
                error.message.includes(retryErrorMessage),
            )
        ) {
            request({ ...params, signal: abortController.signal })
                .then(onResponse)
                .catch(retryHandler);
            retryCount--;
        } else {
            catchError(error);
        }
    };

    request({ ...params, signal: abortController.signal })
        .then(onResponse)
        .catch(retryHandler);

    return {
        cancel: () => {
            setLoading && setLoading(false);
            abortController.abort();
        },
    };
};

/**
 * Helper function to make requests in an await/async manner.
 * IMPORTANT: If a setError function is not provided, the error will be thrown and the caller will need to handle it.
 * @param request The request callback to be executed
 * @param params The payload needed for the request
 * @param setLoading A optional callback to handle the request loading state
 * @param setError A optional callback to handle any request errors
 * @param requestName A optional request key name used to control the request cancelable behavior.
 * When specified, subsequent requests with the same key will cancel the previous one
 * @returns A generic promise TReturn
 */
export const handleRequestAsync = async <TParams extends object, TReturn>(
    request: (params: TParams & AbortParams) => Promise<TReturn>,
    params: TParams,
    setLoading?: (flag: boolean) => void,
    setError?: (error?: Error) => void,
    requestName?: string,
): Promise<TReturn | undefined> => {
    let retryCount = 1;
    const abortController = new AbortController();
    setError && setError(undefined);

    if (requestName) {
        abortOngoingRequests(requestName);
        abortMap.set(requestName, abortController);
    }

    const catchError = (error: Error) => {
        if (!isAbortError(error)) {
            setLoading && setLoading(false);
        }

        if (setError) {
            setError(error);
        } else {
            throw error;
        }
    };

    const retryHandler = async (error: Error) => {
        if (
            retryCount > 0 &&
            RETRY_ERRORS.some((retryErrorMessage) =>
                error.message.includes(retryErrorMessage),
            )
        ) {
            try {
                return await request({
                    ...params,
                    signal: abortController.signal,
                });
            } catch (error) {
                retryHandler(error as Error);
            }
            retryCount--;
        } else {
            await catchError(error);
        }
    };

    try {
        setLoading && setLoading(true);
        const response = await request({
            ...params,
            signal: abortController.signal,
        });
        setLoading && setLoading(false);
        return response;
    } catch (error) {
        await retryHandler(error as Error);
    }

    return Promise.resolve(undefined);
};

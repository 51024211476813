import { withFeatures } from 'src/utils/component.utils';

import { ContactEnrichmentPanelActionsComponent } from './contact-enrichment-actions.component';

export const ContactEnrichmentPanelActionsContainer = withFeatures(
    ContactEnrichmentPanelActionsComponent,
)((f) => ({
    dealId: f.quickActionsFeature.selectedDealId,
    isLoading: f.contactEnrichmentFeature.isContactEnrichmentLoading,
    currentStep: f.contactEnrichmentFeature.currentStep,
    selectedValues: f.contactEnrichmentFeature.contactEnrichmentResultSelection,
    enrichmentTarget: f.contactEnrichmentFeature.selectedContact,
    selectedEnrichmentOption:
        f.contactEnrichmentFeature.domainEnrichmentResultSelection,
    fieldProgress: f.dealContactsManageFeature.fieldProgress,
    domainOptions: f.contactEnrichmentFeature.domainEnrichmentResults,
    language: f.authFeature.user?.language,
    iterateState: f.contactEnrichmentFeature.iterateState,
    onSelectedEnrichmentOption:
        f.contactEnrichmentFeature.onSelectDomainEnrichmentResult,
    updateContactPerson: f.dealContactsManageFeature.updateDealContactPerson,
    updateContactCompany: f.dealContactsManageFeature.updateDealContactCompany,
    getCustomParameterByName:
        f.accountConfigurationFeature.getCustomParameterByName,
}));

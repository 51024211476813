import { withFeatures } from 'src/utils/component.utils';

import { EmailTemplatePanelComponent } from './email-template-panel.component';

export const EmailTemplatePanelContainer = withFeatures(
    EmailTemplatePanelComponent,
)((f) => ({
    dealId: f.quickActionsFeature.selectedDealId,
    selectedContactId: f.quickActionsFeature.selectedEntityId ?? '',
    selectableTemplatesOptions: f.emailTemplatesFeature.selectableTemplates,
    selectableContactsOptions: f.emailTemplatesFeature.selectableContacts,
    originalTemplates: f.emailTemplatesFeature.originalTemplates,
    dataContext: f.emailTemplatesFeature.templateDataContext,
    setSelectedEntityId: f.quickActionsFeature.setSelectedEntityId,
    expandHubSpotChat: f.dealViewFeature.expandHubSpotChat,
    onEmailTemplatesCloseRequested: f.quickActionsFeature.clean,
    setCurrentDealId: f.emailTemplatesFeature.setCurrentDealId,
    createEmail: f.emailTemplatesFeature.createEmailForNativeOS,
    copyToEmailClipboard: f.emailTemplatesFeature.copyEmailToClipboard,
}));

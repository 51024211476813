import styled from '@emotion/styled';
import { Box } from '@mui/material';

export const EnrichmentSourceCountStyled = styled(Box, {
    shouldForwardProp: (prop) => prop !== 'highlight',
})<{
    highlight?: boolean;
}>(({ highlight = false }) => ({
    borderRadius: '4px',
    display: 'flex',
    height: '24px',
    lineHeight: '24px',
    padding: '12px 8px',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: highlight ? '#FFF2FF' : 'var(--gray-10)',
    color: highlight ? '#9F1AA1' : 'var(--gray-60)',
}));

export const SourcesListStyled = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '8px',
    paddingBottom: '8px',
});

import { makeAutoObservable } from 'mobx';

import { openLink } from 'src/utils/url.utils';

export interface IExternalLinkFeature {
    clickedLink: string | undefined;
    setClickedLink: (link?: string) => void;
}

export class ExternalLinkFeature implements IExternalLinkFeature {
    clickedLink: string | undefined = undefined;

    setClickedLink = (link?: string, showModal = true) => {
        if (showModal) {
            this.clickedLink = link;
        } else if (link) {
            openLink(link);
        }
    };

    constructor() {
        makeAutoObservable(this);
    }
}

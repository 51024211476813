import { withFeatures } from 'src/utils/component.utils';

import { LinkClickWarningComponent } from './link-click-warning.component';

export const LinkClickWarningContainer = withFeatures(
    LinkClickWarningComponent,
)((f) => ({
    clickedLink: f.externalLinkFeature.clickedLink,
    setClickedLink: f.externalLinkFeature.setClickedLink,
}));

import styled from '@emotion/styled';

import { StyledFolderCounter } from 'src/presentation/modules/folder-secondary-nav-bar/components/search-tag/search-tag.styled';
import { MediaQuery } from 'src/resources/styles/media.mixins';
import { textEllipsisMixin } from 'src/resources/styles/mixins';
import { Spacing } from 'src/resources/styles/spacing';

export const SecondaryNavBarStyled = styled.div`
    width: 384px;
    padding: ${Spacing.s1_5} ${Spacing.s4} ${Spacing.s12} ${Spacing.s4};

    flex: none;
    display: flex;
    flex-direction: column;

    ${MediaQuery.smallDesktop} {
        width: 314px;
    }

    ${MediaQuery.tablet} {
        width: 384px;
        flex: 1;
    }

    ${MediaQuery.phone} {
        padding: 12px 24px;
    }

    &:hover ${StyledFolderCounter} {
        opacity: 1;
    }

    height: 100vh;
    position: sticky;
    top: 0;
`;

export const SecondaryNavBarHeaderStyled = styled.div`
    display: flex;
    flex-shrink: 0;
    margin-bottom: 16px;

    ${MediaQuery.tablet} {
        justify-content: space-between;
    }
`;

export const SecondaryNavBarTitleStyled = styled.h1`
    font-size: 28px;
    font-weight: 500;
    line-height: 48px;
    color: var(--main-font);
    display: block;
    ${textEllipsisMixin}
`;

export const BackLink = styled.button`
    display: flex;
    align-items: center;
`;

import {
    ObjectivePartFragment,
    LinkedFieldType,
    ObjectiveType as ObjectiveTypeRaw,
} from 'src/data/api/graphql/br_process/generated/graphql-sdk';
import {
    SuccessState,
    SuccessStateRawToModel,
} from 'src/domain/models/success-state/success-state.model';

export interface LinkedField {
    name: string;
    type: LinkedFieldType;
}

export enum ObjectiveType {
    CONFIRM = 'CONFIRM',
    QUESTION = 'QUESTION',
}

const ObjectiveTypeRawToModel: Record<ObjectiveTypeRaw, ObjectiveType> = {
    [ObjectiveTypeRaw.Confirm]: ObjectiveType.CONFIRM,
    [ObjectiveTypeRaw.Question]: ObjectiveType.QUESTION,
};

export interface Objective {
    id: string;
    criteria: string;
    status: string;
    state: SuccessState;
    linkedField: LinkedField | null;
    type: ObjectiveType;
    order: number | null;
}

export const createObjective = (
    rawObjective: ObjectivePartFragment,
): Objective => {
    const { criteria, status, id, state, linkedField, type, order } =
        rawObjective;
    return {
        id,
        criteria,
        status,
        state: SuccessStateRawToModel[state],
        linkedField: linkedField || null,
        type: ObjectiveTypeRawToModel[type],
        order: order ?? null,
    };
};

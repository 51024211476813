import {
    CompanyOutreachStage,
    ContactOutreachStage,
} from 'src/data/api/graphql/br_process/generated/graphql-sdk';

export type DealCompanyOutreachStage = 'FIND_PEOPLE' | 'RESEARCH';

type DealPersonOutreachStage =
    | 'IDENTIFIED'
    | 'IN_CONTACT'
    | 'OUTREACH'
    | 'RESEARCH';

export interface RawActionFeatureConfiguration {
    active: boolean;
    name: string;
    order: number;
    settings?: any;
    companyOutreachStages?: CompanyOutreachStage[] | null;
    contactOutreachStages?: ContactOutreachStage[] | null;
}

export interface IActionFeatureConfiguration {
    isActivated: boolean;
    name: string;
    order: number;
    settings: {
        [key: string]:
            | string
            | number
            | string[]
            | number[]
            | object[]
            | undefined;
    };
    companyOutreachStages?: DealCompanyOutreachStage[];
    personOutreachStages?: DealPersonOutreachStage[];
}

export class ActionFeatureConfiguration implements IActionFeatureConfiguration {
    isActivated: boolean;
    name: string;
    order: number;
    settings: {
        [key: string]: string | number | string[] | number[] | undefined;
    };
    companyOutreachStages?: DealCompanyOutreachStage[];
    personOutreachStages?: DealPersonOutreachStage[];
    constructor({
        active,
        name,
        order,
        settings,
        companyOutreachStages,
        contactOutreachStages,
    }: RawActionFeatureConfiguration) {
        this.isActivated = active;
        this.name = name;
        this.order = order;
        this.settings = settings;
        this.companyOutreachStages =
            companyOutreachStages?.map(
                (item) => item.toString() as DealCompanyOutreachStage,
            ) ?? undefined;
        this.personOutreachStages =
            contactOutreachStages?.map(
                (item) => item.toString() as DealPersonOutreachStage,
            ) ?? undefined;
    }
}

import { withFeatures } from 'src/utils/component.utils';

import { NotificationsPanel } from './notifications-panel.component';

export const NotificationsPanelContainer = withFeatures(NotificationsPanel)(
    (f) => ({
        toggleNotificationsOpened:
            f.notificationsFeature.toggleNotificationsOpened,
    }),
);

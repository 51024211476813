import { OutreachBlockType } from './outreach-block-type';
import { OutreachType } from './outreach-type';
import { PropositionType } from './propositionType';

export interface OutreachPromptBlock {
    id: string;
    companyId: number;
    createdAt: Date;
    updatedAt: Date;
    type: OutreachBlockType;
    title: string;
    text: string;
    outreachType: OutreachType;
}

export type OutreachPromptBlocksByType = {
    valueProposition: OutreachPromptBlock[];
    desiredOutcome: OutreachPromptBlock[];
    writingStyle: OutreachPromptBlock[];
};

export interface UpsertPromptBlockPayload {
    id?: string;
    title: string;
    text: string;
    type: OutreachBlockType;
    dealId: string;
    propositionType: PropositionType;
}

export interface DeletePromptBlockPayload {
    id: string;
    dealId: string;
    type: OutreachBlockType;
    propositionType: PropositionType;
}

export interface AddOrRemovePromptBlockPayload {
    blockId: string;
    type: OutreachBlockType;
    dealId: string;
    propositionType: PropositionType;
}

export enum BlockSettingTrackingAction {
    added = 'Added to settings',
    removed = 'Removed from settings',
}

export enum OutreachActivityType {
    phoneCall = 'PHONE_CALL',
    email = 'EMAIL',
    OTHER = 'OTHER',
}

export enum DealActivityType {
    outreachAttempt = 'OUTREACH_ATTEMPT',
}

export enum OutreachActivityResult {
    interested = 'interested',
    referral = 'referral',
    futureInterest = 'futureInterest',
    notInterested = 'notInterested',
    didNotConnect = 'didNotConnect',
    emailSent = 'emailSent',
    other = 'other',
}

export interface DealActivity {
    id: string;
    companyBid: number;
    createdDate: Date;
    updatedDate: Date;
    userId: number;
    type: DealActivityType;
    notes?: string;
    contactId?: string;
    relevantCompanyId?: string;
    subType: OutreachActivityType;
    customType?: string;
}

export enum OutreachNextAction {
    none = 'none',
    reminderTomorrow = 'reminder_tomorrow',
    reminderNextWeek = 'reminder_next_week',
    reminderNextMonth = 'reminder_next_month',
    reminderCustom = 'reminder_custom',
    createContact = 'create_contact',
}

export interface OutreachNextActionContactCreateData {
    firstName?: string;
    lastName?: string;
    email?: string;
    mobile?: string;
    telephone?: string;
    relevantCompanyId?: string;
    isEdited?: boolean;
}

export interface OutreachNextActionData {
    enabled: boolean;
    action: OutreachNextAction;
    reminderDate?: Date;
    contactCreationData?: OutreachNextActionContactCreateData;
}

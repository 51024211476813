import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';

export const ContactActionIconButtonStyled = styled(IconButton, {
    shouldForwardProp: (prop) => prop !== 'isMobile',
})<{ isMobile?: boolean }>(({ isMobile }) => ({
    '&.Mui-disabled': {
        pointerEvents: 'auto',
    },
    '&.MuiIconButton-root': {
        padding: 0,
        width: isMobile ? '36px' : '30px',
        height: isMobile ? '36px' : '30px',
    },
}));

export const WrappedWebIconsTipText = styled(Typography)({
    fontSize: '14px',
    lineHeight: '150%',
    color: 'var(--gray-80)',
    letterSpacing: '0.4px',
});

import { makeAutoObservable } from 'mobx';

import {
    ValueMilestoneFragment,
    ValueMilestoneType,
} from 'src/data/api/graphql/br_process/generated/graphql-sdk';
import { Stage } from 'src/domain/models/deal-stage/deal-stage.model';
import { Pipeline } from 'src/domain/models/pipeline/pipeline.model';
import { isNonNullable } from 'src/utils/is-non-nullable.utils';

export interface PipelineStore {
    pipelineIds: string[];
    arePipelinesLoading: boolean;
    needToRequestPipelines: boolean;
    currentSelectedPipeline?: Pipeline;
    valueMilestones: ValueMilestoneFragment[] | undefined;
    setNeedToRequestPipelines: (flag: boolean) => void;
    setLoadingPipelines: (loading: boolean) => void;
    setPipelines: (pipelines: Pipeline[]) => void;
    getPipeline: (pipelineId: string) => Pipeline | null;
    setCurrentSelectedPipeline: (pipeline: Pipeline) => void;
    getPipelineStages: (pipelineId?: string) => {
        stageMap: Map<string, Stage> | undefined;
        stages: Stage[];
    };
    setValueMilestones: (milestones: ValueMilestoneFragment[]) => void;
    isValueMilestoneSet: (
        type: ValueMilestoneType,
        pipelineId?: string,
    ) => boolean;
    isValueMilestoneSetForAnyPipeline: (type: ValueMilestoneType) => boolean;
}

export class PipelineStoreImpl implements PipelineStore {
    pipelineIds: string[] = [];
    pipelines: Map<string, Pipeline> = new Map();
    arePipelinesLoading = false;
    needToRequestPipelines = true;
    currentSelectedPipeline?: Pipeline;
    valueMilestones: ValueMilestoneFragment[] | undefined = undefined;

    setPipelines = (pipelines: Pipeline[]) => {
        this.pipelineIds = [];
        this.pipelines.clear();
        pipelines.forEach((pipeline) => {
            const { id } = pipeline;
            this.pipelines.set(id, pipeline);
            this.pipelineIds.push(id);
        });

        this.needToRequestPipelines = false;
    };

    getPipeline = (pipelineId: string): Pipeline | null => {
        return this.pipelines.get(pipelineId) ?? null;
    };

    setLoadingPipelines = (loading: boolean) => {
        this.arePipelinesLoading = loading;
    };

    setNeedToRequestPipelines = (flag: boolean) => {
        this.needToRequestPipelines = flag;
    };

    setCurrentSelectedPipeline = (pipeline: Pipeline) => {
        this.currentSelectedPipeline = pipeline;
    };

    getPipelineStages = (pipelineId?: string) => {
        const pipeline = this.getPipeline(
            pipelineId ?? this.currentSelectedPipeline?.id ?? '',
        );
        const stageIdsInOrder = pipeline?.config.stageConfig.stageIdsInOrder;
        const stageMap = pipeline?.config.stageConfig.stageMap;
        const stages: Stage[] =
            stageIdsInOrder
                ?.map((s) => stageMap?.get(s))
                .filter(isNonNullable) ?? [];
        return { stageMap, stages };
    };

    setValueMilestones = (milestones: ValueMilestoneFragment[]) => {
        this.valueMilestones = milestones;
    };

    isValueMilestoneSet = (type: ValueMilestoneType, pipelineId?: string) => {
        return !!this.valueMilestones?.some(
            (v) =>
                v.type === type &&
                (pipelineId ? v.pipeline === pipelineId : true),
        );
    };

    isValueMilestoneSetForAnyPipeline = (type: ValueMilestoneType) => {
        return this.pipelineIds.some((pipelineId) =>
            this.isValueMilestoneSet(type, pipelineId),
        );
    };

    constructor() {
        makeAutoObservable(this);
    }
}

import { PipelineStagesDataConfigFragment } from 'src/data/api/graphql/br_process/generated/graphql-sdk';
import { Objective } from 'src/domain/models/objective/objective.model';

export enum StageType {
    last = 'last-stage',
    general = 'general-stage',
    initial = 'initial-stage',
}
export interface Stage {
    id: string;
    name: string;
    order: number;
    type: StageType;
    targetConversionRate: number;
    defaultObjectives: Partial<Objective>[];
}

export const createStage = (
    rawStage: PipelineStagesDataConfigFragment,
    lastStageId: string,
    initialStageId: string,
): Stage => {
    const { id, name, order, targetConversionRate } = rawStage;
    let type: StageType = StageType.general;
    if (id === lastStageId) {
        type = StageType.last;
    } else if (id === initialStageId) {
        type = StageType.initial;
    }

    // backend sends null for final stages. Check that they don't send this for general stages
    if (id !== lastStageId && targetConversionRate === null) {
        console.error(
            "targetConversionRate can't be null for non-heaven and non-hell stage",
        );
    }

    return {
        id,
        type,
        name,
        order,
        targetConversionRate: targetConversionRate ?? 1,
        defaultObjectives: (rawStage.defaultObjectives ?? []).map((obj) => ({
            criteria: obj.criteria,
            linkedField: obj.linkedField,
        })),
    };
};

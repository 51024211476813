import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { AppRoute } from 'src/presentation/modules/router/app-route.list';

export const useQuery = () => {
    const { search } = useLocation();

    return useMemo(() => new URLSearchParams(search), [search]);
};

/**
 * Check if the current URL is active
 * @param route The route to check
 * @returns True if the current URL is active, false otherwise
 */
export const useUrlActive = (route: AppRoute) => {
    const location = useLocation();

    return location.pathname.includes(route);
};

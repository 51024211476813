import styled from '@emotion/styled';

import { ZIndex } from 'src/resources/styles/zIndex';

export const PrimaryNavbarStyled = styled.aside`
    width: 64px;
    background-color: var(--button-gray-bg);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    overflow: hidden;
    line-height: 16px;
    flex: none;
    z-index: ${ZIndex.zPrimaryNavBar};

    position: sticky;
    top: 0;
    height: 100vh;
`;

export const PrimaryNavbarTopSection = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
`;

export const StyledIcon = styled.div`
    text-align: center;
    width: 100%;
    height: 64px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--button-gray-bg);
    border: 5px solid transparent;
`;

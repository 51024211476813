export const createCustomTitle = (
    leadId: number,
    value: string,
): CustomTitle => {
    return {
        leadId,
        value,
    };
};

export interface CustomTitle {
    leadId: number;
    value: string;
}

import { makeAutoObservable } from 'mobx';

import { Lead } from 'src/domain/models/lead/lead.model';

export interface LeadsStore {
    ids: string[];
    leads: Map<string, Lead>;
    addId: (id: string) => void;
    removeId: (id: string) => void;
    set: (lead: Lead) => void;
    remove: (leadId: string) => void;
    clear: () => void;
    setLoading: (flag: boolean) => void;
    isLoading: boolean;
    setSingleLeadLoading: (flag: boolean) => void;
    isSingleLeadLoading: boolean;
}

export class LeadsStoreImpl implements LeadsStore {
    ids: string[] = [];
    leads: Map<string, Lead> = new Map();
    isLoading = false;
    isSingleLeadLoading = false;

    set = (lead: Lead) => {
        this.leads.set(lead.id, lead);
    };

    addId = (id: string) => {
        this.ids.push(id);
    };

    removeId = (id: string) => {
        this.ids = this.ids.filter((leadId) => leadId !== id);
    };

    remove = (leadId: string) => {
        this.leads.delete(leadId);
    };

    clear = () => {
        this.leads.clear();
        this.ids = [];
    };

    setLoading = (flag: boolean) => {
        this.isLoading = flag;
    };

    setSingleLeadLoading = (flag: boolean) => {
        this.isSingleLeadLoading = flag;
    };

    constructor() {
        makeAutoObservable(this);
    }
}

import styled from '@emotion/styled';
import { Box } from '@mui/material';

import { MediaQuery } from 'src/resources/styles/media.mixins';

export const ProfileDisplayWrapper = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    width: '100%',
    height: '48px',
    gap: '13px',

    [MediaQuery.phone]: {
        height: 'auto',
    },
});

export const ProfileInfoWrapper = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    width: 'calc(100% - 40px - 13px)',
});

export const ProfileNameRowWrapper = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    width: '100%',
    fontSize: '16px !important',
});

export const ProfileNameWrapper = styled.p({
    color: 'var(--gray-100)',
    fontSize: '16px',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '140%',
    letterSpacing: ' 0.225px',
    height: '22px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: 'calc(100% - 44px)',
    marginRight: '8px',
});

export const ProfileSubtitleWrapper = styled(Box, {
    shouldForwardProp: (prop) =>
        prop !== 'isSet' &&
        prop !== 'isPerson' &&
        prop !== 'archived' &&
        prop !== 'isPointer',
})<{
    isSet: boolean;
    isPerson: boolean;
    archived: boolean;
    isPointer: boolean;
}>(({ isSet = false, isPerson = true, archived = false, isPointer }) => ({
    width: '100%',
    fontSize: '16px',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '120%',
    height: '20px',
    color: archived
        ? 'var(--gray-60)'
        : isPerson
          ? 'var(--blue-60)'
          : 'var(--magenta-dark)',
    textDecorationLine: isSet ? 'none' : 'underline',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    cursor: isPointer ? 'pointer' : 'default',
}));

export const CrownFilledProfileAvatarStyled = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '40px',
    height: '40px',
    backgroundColor: 'var(--blue-60)',
    borderRadius: '20px',
    position: 'fixed',
});

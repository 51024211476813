import { Language } from 'src/domain/models/locale/locale.model';
import { isDateValid } from 'src/utils/datetime.utils';

export interface LocalStorageService {
    setUserId: (id: number) => void;
    getLanguage: () => Language;
    setLanguage: (lang: Language) => void;
    getNewestNotificationDate: () => Date | undefined;
    setNewestNotificationDate: (date: Date) => void;
    getNotificationsAreChecked: () => boolean;
    setNotificationsAreChecked: (flag: boolean) => void;
}

enum LocalStorageKey {
    userId = 'user-id',
    language = 'language',
    newestNotificationDate = 'newest-notification-date',
    notificationsAreChecked = 'notifications-are-checked',
}

// ALL LOCAL STORAGE USAGES SHOULD BE HERE
export const createLocalStorageService = (): LocalStorageService => {
    const setUserId = (id: number): void => {
        localStorage.setItem(LocalStorageKey.userId, `${id}`);
    };

    const getLanguage = (): Language => {
        const lang = localStorage.getItem(LocalStorageKey.language);
        if (lang === Language.De) {
            return Language.De;
        } else {
            return Language.En;
        }
    };

    const setLanguage = (lang: Language) => {
        localStorage.setItem(LocalStorageKey.language, lang);
    };

    const getNewestNotificationDate = (): Date | undefined => {
        const dateString = localStorage.getItem(
            LocalStorageKey.newestNotificationDate,
        );
        if (!dateString) {
            return undefined;
        }

        const date = new Date(dateString);
        return isDateValid(date) ? date : undefined;
    };

    const setNewestNotificationDate = (date: Date) => {
        localStorage.setItem(
            LocalStorageKey.newestNotificationDate,
            date.toString(),
        );
    };

    const getNotificationsAreChecked = (): boolean => {
        return (
            localStorage.getItem(LocalStorageKey.notificationsAreChecked) ===
            'true'
        );
    };

    const setNotificationsAreChecked = (flag: boolean) => {
        localStorage.setItem(
            LocalStorageKey.notificationsAreChecked,
            `${flag}`,
        );
    };

    return {
        setUserId,
        getLanguage,
        setLanguage,
        getNewestNotificationDate,
        setNewestNotificationDate,
        getNotificationsAreChecked,
        setNotificationsAreChecked,
    };
};

import {
    CustomFieldQl,
    CustomFieldType,
} from 'src/data/api/graphql/br_process/generated/graphql-sdk';

export enum CustomParameterType {
    DECISION = 'DECISION',
    FLOAT = 'FLOAT',
    INTEGER = 'INTEGER',
    ISODATE = 'ISODATE',
    STRING = 'STRING',
}

export type CustomParameterEntityType =
    | 'dealCustomFields'
    | 'contactCustomFields'
    | 'companyCustomFields';

export interface CustomParameter {
    acceptedValues: string[] | null;
    defaultValue: string | null;
    label: string;
    name: string;
    type: CustomParameterType;
    value: string | null;
    order: number;
    isTag: boolean;
    isVisibleInDealPage: boolean;
}

const CustomFieldTypeToModel: Record<CustomFieldType, CustomParameterType> = {
    [CustomFieldType.Decision]: CustomParameterType.DECISION,
    [CustomFieldType.Float]: CustomParameterType.FLOAT,
    [CustomFieldType.Integer]: CustomParameterType.INTEGER,
    [CustomFieldType.Isodate]: CustomParameterType.ISODATE,
    [CustomFieldType.String]: CustomParameterType.STRING,
    [CustomFieldType.DecisionContacts]: CustomParameterType.DECISION,
    [CustomFieldType.DecisionCompanies]: CustomParameterType.DECISION,
};

export const createCustomParameter = (
    rawData: CustomFieldQl,
): CustomParameter => {
    const {
        acceptedValues,
        defaultValue,
        label,
        name,
        type,
        value,
        order,
        isTag,
        isVisibleInDealPage,
    } = rawData;
    return {
        acceptedValues: acceptedValues ?? null,
        defaultValue: defaultValue ?? null,
        label,
        name,
        type: CustomFieldTypeToModel[type],
        value: value ?? '',
        order,
        isTag,
        isVisibleInDealPage,
    };
};

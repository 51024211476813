import { CircularProgress } from '@mui/material';
import { FC } from 'react';

interface LoadingSpinnerProps {
    theme?: 'primary' | 'secondary' | 'inherit';
    size?: number;
    color?: string;
    thickness?: number;
}

export const LoadingSpinner: FC<LoadingSpinnerProps> = ({
    theme = 'primary',
    size = 40,
    color,
    thickness,
}) => {
    return (
        <CircularProgress
            size={size}
            sx={{
                color: color
                    ? color
                    : theme === 'primary'
                      ? 'var(--blue-60)'
                      : 'var(--gray-60)',
            }}
            style={{ margin: '6px' }}
            thickness={thickness}
        />
    );
};

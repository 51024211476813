import {
    BrIcon,
    BrInfotip,
    BrText,
    IconName,
    IconSize,
} from '@buildingradar/br_component_lib';
import { Box } from '@mui/material';
import { observer } from 'mobx-react';
import { FC, useState, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { ConfiguredSearch } from 'src/app-features/searches-configuration/domain/models/configured-search';
import { useSearchOwnerChecker } from 'src/app-features/searches-configuration/presentation/utils/useSearchOwnerChecker';
import { Spacing } from 'src/resources/styles/spacing';
import { cutText } from 'src/utils/string.utils';

import { SearchListTileStyled } from './styles';

interface SubscribedSearchTileComponentProps {
    isSelected: boolean;
    search: ConfiguredSearch;
    onDeleteSearch: (e: React.MouseEvent<HTMLElement>) => void;
}
export const SubscribedSearchTileComponent: FC<SubscribedSearchTileComponentProps> =
    observer(({ isSelected, search, onDeleteSearch }) => {
        const [isHovering, setIsHovering] = useState<boolean>(false);
        const { t } = useTranslation();

        const { isSearchOwner } = useSearchOwnerChecker(search.ownerId);

        const handleOnDeleteSearch = (e: MouseEvent<HTMLElement>) => {
            e.stopPropagation();
            if (!isSearchOwner) {
                return;
            }

            onDeleteSearch(e);
        };

        const deleteIconElement = (
            <BrIcon
                onClick={handleOnDeleteSearch}
                className="delete-search"
                iconName={IconName.BrDeleteOutlineIcon}
                iconSize={IconSize.small}
                iconColor={!isSearchOwner ? 'var(--gray-40)' : undefined}
            />
        );

        return (
            <Box
                width="100%"
                marginLeft={Spacing.s1_5}
                onMouseEnter={() => setIsHovering(true)}
                onMouseLeave={() => setIsHovering(false)}
            >
                <SearchListTileStyled isSearchOwner={isSearchOwner}>
                    <Box display="flex" alignItems="center" gap={Spacing.s1}>
                        <BrIcon
                            iconName={IconName.BrCircleIcon}
                            iconSize={10}
                        />
                        <BrText>{cutText(search.name, 25)}</BrText>
                    </Box>

                    {(isSelected || isHovering) &&
                        (isSearchOwner ? (
                            deleteIconElement
                        ) : (
                            <BrInfotip
                                content={t(
                                    'search_configuration.subscribed_list.blocked_delete_icon.infotip',
                                )}
                            >
                                {deleteIconElement}
                            </BrInfotip>
                        ))}
                </SearchListTileStyled>
            </Box>
        );
    });

import { makeAutoObservable } from 'mobx';

import { LinkedFieldType } from 'src/data/api/graphql/br_process/generated/graphql-sdk';
import {
    LinkedField,
    Objective,
} from 'src/domain/models/objective/objective.model';

export interface ObjectivesStore {
    objectivesMap: Map<string, Objective>;
    objectivesUpdateLoadingMap: Map<string, boolean>;
    selectedObjectiveId: string | null;
    setObjectives: (objectives: Objective[]) => void;
    getObjectiveIdLinkedToParameter: (
        name: string,
        type: LinkedFieldType,
    ) => string | undefined;
    clear: () => void;
}

export class ObjectivesStoreImpl implements ObjectivesStore {
    objectivesMap: Map<string, Objective> = new Map();
    linkedCustomParametersMap: Map<string, string> = new Map();
    linkedNativeParametersMap: Map<string, string> = new Map();
    linkedRolesParametersMap: Map<string, string> = new Map();
    objectivesUpdateLoadingMap: Map<string, boolean> = new Map();
    selectedObjectiveId: string | null = null;

    constructor() {
        makeAutoObservable(this);
    }

    clear = () => {
        this.objectivesMap.clear();
        this.linkedCustomParametersMap.clear();
        this.linkedNativeParametersMap.clear();
        this.objectivesUpdateLoadingMap.clear();
        this.linkedRolesParametersMap.clear();
    };

    setObjectives = (objectives: Objective[]) => {
        objectives.forEach((objective) => {
            this.objectivesMap.set(objective.id, objective);
            this.objectivesUpdateLoadingMap.set(objective.id, false);
            objective.linkedField &&
                this.addLinkedParameter(objective.linkedField, objective.id);
        });
    };

    addLinkedParameter = (linkedField: LinkedField, objectiveId: string) => {
        if (linkedField.type === LinkedFieldType.DealCustom) {
            this.linkedCustomParametersMap.set(linkedField.name, objectiveId);
        } else {
            switch (linkedField.name) {
                case 'title':
                    this.linkedNativeParametersMap.set('title', objectiveId);
                    break;
                case 'value':
                    this.linkedNativeParametersMap.set('value', objectiveId);
                    break;
                case 'notes':
                    this.linkedNativeParametersMap.set('notes', objectiveId);
                    break;
                default:
                    this.linkedRolesParametersMap.set(
                        linkedField.name,
                        objectiveId,
                    );
            }
        }
    };

    getObjectiveIdLinkedToParameter = (
        name: string,
        type: LinkedFieldType,
    ): string | undefined => {
        switch (type) {
            case LinkedFieldType.DealCustom: {
                return this.linkedCustomParametersMap.get(name);
            }
            case LinkedFieldType.DealNative: {
                return this.linkedNativeParametersMap.get(name);
            }
            default: {
                return this.linkedRolesParametersMap.get(name);
            }
        }
    };
}

import { TFunction } from 'react-i18next';

import { DealContactCompanyForm } from 'src/app-features/contact/data/model/deal-contacts-form.model';
import { DealContactType } from 'src/app-features/contact/data/model/deal-contacts.model';
import { ContactUpdateMetricsInfo } from 'src/app-features/contact/domain/deal-contacts-manage.feature.interface';
import {
    ContactEnrichmentData,
    DataProviderSource,
} from 'src/app-features/contact-enrichment/domain/contact-enrichment.model';
import {
    GoogleEnrichmentResultKey,
    GoogleEnrichmentResultKeyList,
} from 'src/app-features/google-enrichment/data/google-enrichment.model';
import { Language } from 'src/domain/models/locale/locale.model';
import { dateToString } from 'src/utils/datetime.utils';

export const buildGoogleEnrichmentCompanyUpdatePayload = (
    originalNote: string,
    data: Map<GoogleEnrichmentResultKey, string>,
    customFields: string[],
    t: TFunction,
    language: Language,
): { form: DealContactCompanyForm; metricsInfo: ContactUpdateMetricsInfo } => {
    const dataList = Array.from(data.entries());
    const directSaveFields = dataList
        .filter(([key]) => customFields.includes(key))
        .map(([key, value]) => ({
            name: key,
            value,
        }));

    const hasEnrichmentFor = (key: string) =>
        !!directSaveFields.find((item) => item.name === key);

    const noteSaveFields = dataList.filter(
        ([key]) => !customFields.includes(key),
    );
    const addedNote = noteSaveFields
        .map(([key, value]) =>
            t('actions.google_enrichment.data_provided_by', {
                field: t(`actions.google_enrichment.results.label.${key}`),
                fieldValue: value,
                providerName: 'Google',
                providerUrl: 'maps.google.com',
                date: dateToString(new Date(), language),
                interpolation: { escapeValue: false },
            }),
        )
        .join('\n');

    const field = customFields
        .map((field) => field)
        .filter((field) => hasEnrichmentFor(field));

    const enrichmentInfo: {
        [key in GoogleEnrichmentResultKey]?: ContactEnrichmentData;
    } = {};
    GoogleEnrichmentResultKeyList.forEach((key) => {
        if (hasEnrichmentFor(key)) {
            enrichmentInfo[key] = {
                value: data.get(key) ?? '',
                providers: [DataProviderSource.Google],
            };
        }
    });
    return {
        form: {
            formType: DealContactType.company,
            customInput: {
                customValues: directSaveFields,
            },
            notes: noteSaveFields.length
                ? originalNote.concat('\n', addedNote)
                : originalNote,
        },
        metricsInfo: {
            field,
            enrichmentInfo,
        },
    };
};

import { SearchFilterModuleParam } from 'src/app-features/searches-configuration/api/searches.api';
import {
    AppliedFilterModule,
    ConfiguredSearch,
    SearchFilterCriteria,
} from 'src/app-features/searches-configuration/domain/models/configured-search';
import { FilterOperation } from 'src/app-features/searches-configuration/domain/models/filter-module-config-option';
import {
    FilterModuleType,
    FilterModule,
} from 'src/domain/models/filter-module/filter-module.model';
import {
    Search,
    SearchFilterModule,
} from 'src/domain/models/search/search.model';
import { sortNewArray } from 'src/utils/array.utils';

export const mapSearchFilterModuleToAppliedFilterModule = (
    searchFilter: SearchFilterModule,
    filterModulesMap: Map<number, FilterModule>,
): AppliedFilterModule => {
    const { key } = searchFilter;

    const filterModule = filterModulesMap.get(key);

    if (!filterModule) {
        throw new Error(
            `Filter module with id ${key} not found in the fetched filter list`,
        );
    }

    const { name, type } = filterModule;

    const appliedFilter: AppliedFilterModule = {
        id: key,
        name,
        type,
        filterOperation: FilterOperation.and,
        searchCriteria: buildSearchFilterQueryFromJsonData(filterModule),
    };

    return appliedFilter;
};

/**
 * Function which gets the filter module data string and converts it to a query object
 * well structured to be sent to the backend when necessary
 * @param filterModule The filter module as it comes from the backend
 * @returns A filter query object
 */
const buildSearchFilterQueryFromJsonData = (
    filterModule: FilterModule,
): SearchFilterCriteria => {
    const dataObject = JSON.parse(filterModule.data);

    const filterQueryObject: SearchFilterCriteria = {};

    for (const filterKey of Object.keys(dataObject)) {
        const filterCriteriaArray: string[] | object[] = dataObject[filterKey];

        if (!filterQueryObject[filterKey]) {
            filterQueryObject[filterKey] = [];
        }

        for (const filterCriteria of filterCriteriaArray) {
            if (typeof filterCriteria === 'string') {
                const { filter, ...rest } = JSON.parse(filterCriteria);
                filterQueryObject[filterKey].push({
                    key: JSON.stringify(rest),
                    filter,
                    value: rest.value,
                });
            } else {
                filterQueryObject[filterKey].push({
                    key: (filterCriteria as any).key,
                    value: (filterCriteria as any).value,
                    filter: (filterCriteria as any).filter,
                });
            }
        }
    }

    return filterQueryObject;
};

/**
 * It returns the available filter modules by type + any general filter.
 * @param filterType Type of the filter.
 * @param filterModules List of filter modules
 * @returns The list of filter modules that match the filter type + any general filter
 */
export const getAvailableSortedFilterModulesByType = (
    filterType: FilterModuleType,
    filterModules: FilterModule[],
    alreadySelectedIds: number[],
    searchTerm: string,
): FilterModule[] => {
    const generalFilters = filterModules.filter(
        (f) => f.type === FilterModuleType.General,
    );
    const filtersForType = filterModules.filter((f) => f.type === filterType);

    const availableFilter = [...generalFilters, ...filtersForType]
        .filter((f) => !alreadySelectedIds.includes(f.id))
        .filter((f) => f.name.toLowerCase().includes(searchTerm.toLowerCase()));

    const sorted = sortNewArray(availableFilter)((a, b) =>
        a.name.localeCompare(b.name),
    );

    return sorted;
};

/**
 * It returns the selected search from the list of ordered searches
 * @param id Id of the search to be selected
 * @param orderedSearches List of ordered searches
 * @returns The selected search or undefined if it's not found
 */
export const getSelectedSearch = (
    id: number,
    orderedSearches: ConfiguredSearch[],
): ConfiguredSearch | undefined =>
    orderedSearches.find((search) => search.id === id);

export const buildSearchFilterModulesPayload = (
    filters: AppliedFilterModule[],
): SearchFilterModuleParam[] => {
    const params: SearchFilterModuleParam[] = filters.map((filter) => ({
        moduleId: filter.id,
        data: filter.searchCriteria,
    }));

    return params;
};

export const replaceDraftSearchByCreatedOneOnUserSearches = (
    createdSearch: ConfiguredSearch,
    userSearches?: Search[],
) => {
    if (userSearches) {
        const draftSearchIndex = userSearches.findIndex(
            (search) => search.id === 0,
        );
        userSearches[draftSearchIndex] = {
            id: createdSearch.id,
            name: createdSearch.name,
            type: createdSearch.type,
            ownerId: createdSearch.ownerId,
            searchData: {
                filterModules: createdSearch.filters.map(({ id }) => ({
                    key: id,
                    filter: FilterOperation.or,
                    value: id.toString(),
                })),
            },
        };
    }
};

import {
    ContactEnrichmentResult,
    DataProviderSource,
    DomainEnrichmentOption,
} from 'src/app-features/contact-enrichment/domain/contact-enrichment.model';
import { AbortParams } from 'src/data/api/api-client';
import { EnrichmentDataProviderSource } from 'src/data/api/graphql/br_process/generated/graphql-sdk';
import { ProcessGqlSdk } from 'src/data/api/graphql/graphql-client.wrapper';

interface GetEnrichmentParams extends AbortParams {
    fullName: string;
    companyName: string;
    dealId: string;
    linkedin?: string;
    domain?: string;
    contactId: string;
    triggeredFromSearchRefinement: boolean;
}

interface GetDomainEnrichmentParams extends AbortParams {
    name: string;
    dealId: string;
    language: string;
    relevantCompanyId: string;
    contactId: string;
}

export interface IContactEnrichmentApi {
    getContactEnrichmentData: (
        params: GetEnrichmentParams,
    ) => Promise<ContactEnrichmentResult>;
    getDomainEnrichmentData: (
        params: GetDomainEnrichmentParams,
    ) => Promise<DomainEnrichmentOption[]>;
}

export const createContactEnrichmentApi = (
    processGqlSdk: ProcessGqlSdk,
): IContactEnrichmentApi => {
    const getDomainEnrichmentData = async (
        params: GetDomainEnrichmentParams,
    ) => {
        const response = await processGqlSdk.GetDomainEnrichmentResult({
            dealId: params.dealId,
            companyName: params.name,
            relevantCompanyId: params.relevantCompanyId,
            contactId: params.contactId,
        });

        const results: DomainEnrichmentOption[] =
            response.actionFeatureContactEnrichmentCompanyDomain.map(
                (item) => ({
                    name: item.name,
                    domain: item.domain,
                    isCustom: false,
                    providers: item.providers.map(
                        (provider) => provider as unknown as DataProviderSource,
                    ),
                }),
            );

        return results;
    };

    const getContactEnrichmentData = async (
        params: GetEnrichmentParams,
    ): Promise<ContactEnrichmentResult> => {
        const { actionFeatureContactEnrichment: response } =
            await processGqlSdk.GetContactEnrichmentResult({
                dealId: params.dealId,
                company: params.companyName,
                domain: params.domain,
                contactName: params.fullName,
                contactId: params.contactId,
                triggeredFromSearchRefinement:
                    params.triggeredFromSearchRefinement,
            });

        const result: ContactEnrichmentResult = {
            emails: checkAndReturnArray(response.emails),
            phones: checkAndReturnArray(response.phones),
            linkedins: checkAndReturnArray(response.linkedins),
            positions: checkAndReturnArray(response.positions),
        };
        return result;
    };

    const checkAndReturnArray = (
        arr?: { value: string; providers: EnrichmentDataProviderSource[] }[],
    ) =>
        arr?.map((item) => ({
            value: item.value,
            providers: item.providers as unknown as DataProviderSource[],
        })) ?? [];

    return {
        getContactEnrichmentData,
        getDomainEnrichmentData,
    };
};

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import NotificationsIcon from '@mui/icons-material/Notifications';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';

export const StyledOtherSection = styled.div`
    width: 100%;
    background-color: var(--button-bg-disabled);
`;

const StyledOtherCss = css`
    color: var(--gray);
    height: 40px;
    font-weight: 500;
    text-align: center;
    background-color: var(--button-gray-bg);
    text-align: center;
    width: 100%;
    height: 64px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    &:hover {
        path {
            fill: var(--accent-link);
        }
    }
`;

export const StyledOtherItem = styled.div`
    ${StyledOtherCss};
`;

export const StyledOtherItemButton = styled.button`
    ${StyledOtherCss};
`;

export const ArrowStyled = styled.div<{
    isVisible: boolean;
    hasAnimation: boolean;
}>`
    width: 0;
    height: 0;
    border-top: 16px solid transparent;
    border-right: 13px solid var(--button-bg-disabled);
    border-bottom: 14px solid transparent;
    position: absolute;
    ${({ hasAnimation }) => hasAnimation && 'transition: left 0.3s;'};
    left: ${({ isVisible }) => (isVisible ? '80%' : '100%')};
`;

export const HelpIconStyled = styled(HelpOutlineIcon)`
    font-size: 32px !important;
    color: var(--main-font);
`;

export const NotificationIconStyled = styled(NotificationsOutlinedIcon)`
    font-size: 32px !important;
    color: var(--main-font);
`;
export const NotificationActiveIconStyled = styled(NotificationsIcon)`
    font-size: 32px !important;
    color: var(--accent-link);
`;
export const NewNotificationsMarker = styled.div`
    width: 8px;
    height: 8px;
    background-color: var(--unseen-marker);
    border: 1px solid transparent;
    border-radius: 50%;
    position: absolute;
    top: 12px;
    right: 12px;
`;

import {
    BrButton,
    BrButtonVariant,
    BrDatePicker,
} from '@buildingradar/br_component_lib';
import { useMediaQuery } from '@mui/material';
import { observer } from 'mobx-react';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Colleague } from 'src/domain/models/colleague/colleague.model';
import { Language } from 'src/domain/models/locale/locale.model';
import { ReminderTargetType } from 'src/domain/models/reminder/reminder-target-type';
import {
    CreateReminderData,
    ReminderPanelFields,
    UpdateReminderData,
} from 'src/domain/models/reminder/reminder.model';
import { ActionContentComponent } from 'src/presentation/modules/enablement-actions-panel/actions/action-content.component';
import { ActionPanelLabelStyled } from 'src/presentation/modules/enablement-actions-panel/actions/styled';
import useCurrentUser from 'src/presentation/modules/providers/user.provider';
import { FormTextFieldStyled } from 'src/presentation/shared/form-control/form-control.styled';
import { UserAssignmentDropdown } from 'src/presentation/shared/user-assignment-dropdown/user-assignment-dropdown.component';
import { MediaQuery } from 'src/resources/styles/media.mixins';
import {
    getLocaleForDatePicker,
    getTomorrow,
    isDateOverdue,
} from 'src/utils/datetime.utils';
import { useLocation } from 'react-router-dom';
import { ReminderFormData } from './utils';

interface ReminderPanelComponentProps {
    dealId: string;
    isPanelOpened: boolean;
    assignToUsers: Colleague[];
    currentSelectedDealId: string;
    reminderToEdit?: ReminderPanelFields;
    onRemindersCloseRequested: () => void;
    onCreateReminderRequested: (
        data: CreateReminderData,
        dealId?: string,
        onSuccess?: () => void,
    ) => Promise<void>;
    onUpdateReminderRequested: (
        reminderId: string,
        data: UpdateReminderData,
        dealId?: string,
        onSuccess?: () => void,
    ) => Promise<void>;
    dismissReminder: (
        reminderId: string,
        dealId?: string,
        onSuccess?: () => void,
    ) => Promise<void>;
    triggerPipelineDataRefresh: () => void;
    language: Language;
}

const defaultValues: ReminderFormData = {
    description: '',
    dueDate: getTomorrow(),
};

export const ReminderPanelComponent: FC<ReminderPanelComponentProps> = observer(
    ({
        dealId,
        isPanelOpened,
        assignToUsers,
        currentSelectedDealId,
        reminderToEdit,
        onRemindersCloseRequested,
        onCreateReminderRequested,
        onUpdateReminderRequested,
        dismissReminder,
        triggerPipelineDataRefresh,
        language,
    }) => {
        const { itemId } = useCurrentUser();
        const { t } = useTranslation();
        const isMobile = useMediaQuery(MediaQuery.phone);
        const [currentAssignedTo, setCurrentAssignedTo] = useState(itemId);

        const { control, handleSubmit, reset } = useForm<ReminderFormData>({
            defaultValues,
        });

        const isEditMode = !!reminderToEdit && !!reminderToEdit.id;
        const isOverdue =
            !!reminderToEdit &&
            isDateOverdue(reminderToEdit.dueDate ?? getTomorrow());

        const { pathname } = useLocation();
        const currentLocationWhenOpening = useRef<string>();

        useEffect(() => {
            if (isPanelOpened) {
                if (!currentLocationWhenOpening.current) {
                    currentLocationWhenOpening.current = pathname;
                    return;
                }

                if (pathname !== currentLocationWhenOpening.current) {
                    onRemindersCloseRequested();
                }
            }
        }, [isPanelOpened, onRemindersCloseRequested, pathname]);

        useEffect(() => {
            if (reminderToEdit) {
                reset({
                    description: reminderToEdit.description ?? '',
                    dueDate: reminderToEdit.dueDate ?? getTomorrow(),
                });
                if (reminderToEdit.assigneeId) {
                    setCurrentAssignedTo(reminderToEdit.assigneeId);
                }
            } else {
                reset(defaultValues);
            }
        }, [reminderToEdit, reset]);

        useEffect(() => {
            if (!isPanelOpened) {
                reset(defaultValues);
                setCurrentAssignedTo(itemId);
            }
        }, [itemId, isPanelOpened, reset]);

        const onDismissReminder = useCallback(
            () =>
                reminderToEdit?.id &&
                dismissReminder(
                    reminderToEdit.id,
                    reminderToEdit.target!.id,
                    () => {
                        triggerPipelineDataRefresh();
                    },
                ),
            [
                reminderToEdit?.id,
                reminderToEdit?.target,
                dismissReminder,
                triggerPipelineDataRefresh,
            ],
        );

        const onSubmit = useCallback(
            (data: ReminderFormData) => {
                if (isEditMode) {
                    onUpdateReminderRequested(
                        reminderToEdit!.id!,
                        {
                            description: data.description,
                            dueDate: new Date(data.dueDate!),
                            assigneeId: currentAssignedTo!,
                        },
                        dealId,
                        () => {
                            triggerPipelineDataRefresh();
                        },
                    );
                } else {
                    onCreateReminderRequested(
                        {
                            description: data.description,
                            dueDate: new Date(data.dueDate!),
                            assigneeId: currentAssignedTo!,
                            target: {
                                id: currentSelectedDealId,
                                type: ReminderTargetType.Deal,
                            },
                        },
                        dealId,
                        () => {
                            triggerPipelineDataRefresh();
                        },
                    );
                }
            },
            [
                isEditMode,
                onUpdateReminderRequested,
                reminderToEdit,
                currentAssignedTo,
                dealId,
                triggerPipelineDataRefresh,
                onCreateReminderRequested,
                currentSelectedDealId,
            ],
        );

        return (
            <ActionContentComponent
                contentSxOverride={{ width: isMobile ? '100%' : '340px' }}
                title={
                    isEditMode
                        ? t('enablement_panel.reminders.title_edit_mode')
                        : t('enablement_panel.reminders.title_create_mode')
                }
                body={
                    <>
                        <ActionPanelLabelStyled>
                            {t('enablement_panel.reminders.note_input_label')}
                        </ActionPanelLabelStyled>
                        <Controller
                            name="description"
                            control={control}
                            render={({
                                field: { onChange, value },
                                fieldState: { error },
                            }) => {
                                return (
                                    <FormTextFieldStyled
                                        autoFocus
                                        margin="none"
                                        name="description"
                                        type="text"
                                        placeholder={t(
                                            'enablement_panel.reminders.note_input_placeholder',
                                        )}
                                        value={value}
                                        onChange={onChange}
                                        multiline
                                        fullWidth
                                        minRows={4}
                                        boldBorder
                                    />
                                );
                            }}
                        />

                        <ActionPanelLabelStyled applyTopMargin required>
                            {t(
                                'enablement_panel.reminders.due_date_input_label',
                            )}
                        </ActionPanelLabelStyled>
                        <Controller
                            name="dueDate"
                            control={control}
                            rules={{
                                required: t('common.required_field_text'),
                            }}
                            render={({
                                field: { onChange, value },
                                fieldState: { error },
                            }) => {
                                const handleChange = useCallback(
                                    (date: Date | null) => {
                                        onChange(date);
                                    },
                                    [onChange],
                                );

                                return (
                                    <BrDatePicker
                                        margin="none"
                                        value={value}
                                        className="picker"
                                        id="reminder-due-date-picker"
                                        onDateChange={handleChange}
                                        helperText={error && error.message}
                                        status={error ? 'error' : 'idle'}
                                        fullWidth
                                        boldBorder
                                        locale={getLocaleForDatePicker(
                                            language,
                                        )}
                                    />
                                );
                            }}
                        />

                        <ActionPanelLabelStyled applyTopMargin>
                            {t(
                                'enablement_panel.reminders.assign_to_input_label',
                            )}
                        </ActionPanelLabelStyled>
                        <UserAssignmentDropdown
                            assignToUsers={assignToUsers}
                            currentAssignedTo={currentAssignedTo}
                            onUserSelected={setCurrentAssignedTo}
                        />
                    </>
                }
                actions={
                    <>
                        <BrButton
                            fullWidth
                            onClick={handleSubmit(onSubmit)}
                            variant={
                                !isOverdue
                                    ? BrButtonVariant.contained
                                    : BrButtonVariant.outlined
                            }
                        >
                            {t(
                                isEditMode
                                    ? 'enablement_panel.reminders.update_reminder_button_label'
                                    : 'enablement_panel.reminders.set_reminder_button_label',
                            )}
                        </BrButton>
                        {isEditMode && (
                            <BrButton
                                className="dismiss-btn"
                                variant={
                                    isOverdue
                                        ? BrButtonVariant.contained
                                        : BrButtonVariant.outlined
                                }
                                fullWidth
                                onClick={onDismissReminder}
                            >
                                {t(
                                    'enablement_panel.reminders.dismiss_button_text',
                                )}
                            </BrButton>
                        )}
                        <BrButton
                            variant={BrButtonVariant.outlined}
                            fullWidth
                            onClick={onRemindersCloseRequested}
                        >
                            {t('common.close')}
                        </BrButton>
                    </>
                }
                onClose={onRemindersCloseRequested}
            />
        );
    },
);

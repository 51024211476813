/**
 * Creates a new error object by assigning the properties of the original error object.
 * @param originalError The original error object to assign.
 * @returns A new error object with the assigned properties.
 */
export function assignError(originalError: Error): Error {
    const { name, message, stack } = originalError;
    const newError = new Error(message);

    Object.assign(newError, { name, stack });

    return newError;
}

// import {ZIndex} from '../../../resources/styles/zIndex';

export interface HubSpotService {
    logInHubSpot: (email: string, hubspotToken: string) => void;
    logOutHubSpot: () => void;
    showHubSpot: () => void;
    hideHubSpot: () => void;
    expandHubSpotChat: () => void;
    collapseHubSpotChat: () => void;
}

export const createHubSpotService = (): HubSpotService => {
    let isLoaded = false;
    let script: HTMLScriptElement | null = null;

    const loadScript = (): HTMLScriptElement => {
        window.hsConversationsSettings = {
            loadImmediately: false,
        };
        script = document.createElement('script');
        script.setAttribute('src', '//js-na1.hs-scripts.com/5564215.js');
        script.setAttribute('id', 'hs-script-loader');
        script.setAttribute('type', 'text/javascript');
        script.async = true;
        script.defer = true;
        document.body.appendChild(script);
        return script;
    };

    const initAgent = (email: string, hubspotToken: string) => {
        window.hsConversationsSettings = {
            identificationEmail: email,
            identificationToken: hubspotToken,
        };
        const { HubSpotConversations } = window;
        HubSpotConversations?.widget.load();
    };

    const logInHubSpot = (email: string, hubspotToken: string) => {
        if (!isLoaded) {
            const script = loadScript();
            script.onload = () => {
                isLoaded = true;
                initAgent(email, hubspotToken);
            };
        }
    };

    const logOutHubSpot = () => {
        const { HubSpotConversations } = window;
        if (HubSpotConversations) {
            isLoaded = false;
            script?.remove();
            HubSpotConversations.widget.remove();
        }
    };

    const showHubSpot = () => {
        const { HubSpotConversations } = window;
        if (HubSpotConversations) {
            HubSpotConversations.widget.load();
        }
    };
    const hideHubSpot = () => {
        const { HubSpotConversations } = window;
        if (HubSpotConversations) {
            HubSpotConversations.widget.remove();
        }
    };

    const expandHubSpotChat = () => {
        const { HubSpotConversations } = window;
        if (HubSpotConversations) {
            HubSpotConversations.widget.open();
        }
    };

    const collapseHubSpotChat = () => {
        const { HubSpotConversations } = window;
        if (HubSpotConversations) {
            HubSpotConversations.widget.close();
        }
    };

    return {
        showHubSpot,
        hideHubSpot,
        logInHubSpot,
        logOutHubSpot,
        expandHubSpotChat,
        collapseHubSpotChat,
    };
};

import { makeAutoObservable } from 'mobx';
import {
    OutreachPromptBlock,
    OutreachPromptBlocksByType,
} from 'src/app-features/ai-value-proposition-outreach/domain/model/outreach-block.model';

import { IAccountConfigurationStore } from 'src/data/stores/account-configuration/account-configuration.store.interface';
import { ActionFeaturesIdentifiers } from 'src/domain/features/account-configuration/helpers/action-feature.helper';
import { IAccountConfiguration } from 'src/domain/models/account-configuration/account-configuration.model';
import { IActionFeatureConfiguration } from 'src/domain/models/account-configuration/action-feature-configuration/action-feature-configuration.model';
import { ISalesPeriodConfiguration } from 'src/domain/models/account-configuration/sales-period-configuration/sales-period-configuration.model';
import {
    CustomParameter,
    CustomParameterEntityType,
} from 'src/domain/models/custom-parameter/custom-parameter.model';
import { OutreachBlockType as SdkOutreachBlockType } from 'src/data/api/graphql/br_process/generated/graphql-sdk';
import { sortByOrder } from 'src/utils/array.utils';
import { OutreachType } from 'src/app-features/ai-value-proposition-outreach/domain/model/outreach-type';
import {
    OutreachBlockType,
    SdkToOutreachBlockType,
} from 'src/app-features/ai-value-proposition-outreach/domain/model/outreach-block-type';

type RawBlockConfiguration = {
    id: string;
    company_bid: number;
    created_date: string;
    updated_date: string;
    type: SdkOutreachBlockType;
    title: string;
    text: string;
};

const VALUE_PROP_SETTINGS_KEY = 'outreach_blocks';

export class AccountConfigurationStore implements IAccountConfigurationStore {
    configuration?: IAccountConfiguration;
    customParameterTag?: CustomParameter;

    constructor() {
        makeAutoObservable(this);
    }

    get accountConfiguration(): IAccountConfiguration | undefined {
        return this.configuration;
    }

    get actionFeaturesConfigurations(): IActionFeatureConfiguration[] {
        return this.accountConfiguration?.actionFeatures ?? [];
    }

    get salesPeriodConfiguration(): ISalesPeriodConfiguration | undefined {
        return this.accountConfiguration?.salesPeriod;
    }

    get isEstimatedDealValueVisible(): boolean {
        return this.accountConfiguration?.isEstimatedDealValueVisible ?? false;
    }

    get haveContactRoles(): boolean {
        return (this.accountConfiguration?.contactRoles.length ?? 0) > 0;
    }

    setAccountConfiguration = (configuration: IAccountConfiguration) => {
        this.configuration = {
            actionFeatures: sortByOrder([...configuration.actionFeatures]),
            salesPeriod: configuration.salesPeriod,
            dealCustomFields: configuration.dealCustomFields,
            contactCustomFields: configuration.contactCustomFields,
            companyCustomFields: configuration.companyCustomFields,
            contactRoles: configuration.contactRoles,
            companyRoles: configuration.companyRoles,
            isEstimatedDealValueVisible:
                configuration.isEstimatedDealValueVisible,
        };
        this.customParameterTag = configuration.dealCustomFields.find(
            (c) => c.isTag,
        );
    };

    getActionFeatureConfigurationByIdentifier = (
        identifier: ActionFeaturesIdentifiers,
    ): IActionFeatureConfiguration | undefined => {
        return this.accountConfiguration?.actionFeatures.find(
            ({ name }) => name === identifier,
        );
    };

    getCustomParameterByName = (
        name: string,
        type: CustomParameterEntityType,
    ): CustomParameter | undefined => {
        return this.accountConfiguration?.[type].find((c) => c.name === name);
    };

    setActionSettings = (
        identifier: ActionFeaturesIdentifiers,
        key: string,
        value: any,
    ) => {
        const index = this.actionFeaturesConfigurations.findIndex(
            (c) => c.name === identifier,
        );
        if (index >= 0) {
            const action = this.actionFeaturesConfigurations[index];
            this.actionFeaturesConfigurations[index] = {
                ...action,
                settings: { [key]: value },
            };
        }
    };

    isActionFeatureEnabled = (
        actionIdentifier: ActionFeaturesIdentifiers,
    ): boolean => {
        const actionFeature = this.actionFeaturesConfigurations.find(
            ({ name }) => name === actionIdentifier,
        );

        return !!actionFeature?.isActivated;
    };

    get accountPromptBlocksConfig(): OutreachPromptBlocksByType {
        const allBlocks: OutreachPromptBlock[] = (
            (this.getActionFeatureConfigurationByIdentifier(
                ActionFeaturesIdentifiers.value_prop_outreach,
            )?.settings[VALUE_PROP_SETTINGS_KEY] as object[]) ?? []
        )
            .map((block) => {
                const rawBlock = block as RawBlockConfiguration;
                return {
                    id: rawBlock.id,
                    companyId: rawBlock.company_bid,
                    createdAt: new Date(rawBlock.created_date),
                    updatedAt: new Date(rawBlock.updated_date),
                    type: SdkToOutreachBlockType[
                        rawBlock.type as SdkOutreachBlockType
                    ],
                    title: rawBlock.title,
                    text: rawBlock.text,
                    outreachType: OutreachType.all,
                };
            })
            .sort((a, b) => a.title.localeCompare(b.title));

        return {
            valueProposition: allBlocks.filter(
                (block) => block.type === OutreachBlockType.valueProposition,
            ),
            desiredOutcome: allBlocks.filter(
                (block) => block.type === OutreachBlockType.desiredOutcome,
            ),
            writingStyle: allBlocks.filter(
                (block) => block.type === OutreachBlockType.writingStyle,
            ),
        };
    }
}

import { makeAutoObservable } from 'mobx';

import { Contact } from 'src/domain/models/contact/contact.model';

export interface AssociatedCompaniesStore {
    associatedCompanies: Contact[];
    clearAssociatedCompanies: () => void;
    setAssociatedCompanies: (associatedCompanies: Contact[]) => void;
    setAssociatedCompany: (associatedCompany: Contact) => void;
    getAssociatedCompany: (companyName: string) => Contact | undefined;
    deleteAssociatedCompany: (companyName: string) => void;
}

export class AssociatedCompaniesStoreImpl implements AssociatedCompaniesStore {
    private associatedCompaniesMap: Map<string, Contact> = new Map();
    isLoading = false;
    error: Error | undefined = undefined;

    clearAssociatedCompanies = () => {
        this.associatedCompaniesMap.clear();
    };

    setAssociatedCompanies = (associatedCompanies: Contact[]) => {
        this.clearAssociatedCompanies();
        associatedCompanies.forEach((contact) => {
            this.associatedCompaniesMap.set(contact.companyName, contact);
        });
    };

    setAssociatedCompany = (associatedCompany: Contact) => {
        this.associatedCompaniesMap.set(
            associatedCompany.companyName,
            associatedCompany,
        );
    };

    getAssociatedCompany = (companyName: string): Contact | undefined => {
        return this.associatedCompaniesMap.get(companyName);
    };

    deleteAssociatedCompany = (companyName: string) => {
        this.associatedCompaniesMap.delete(companyName);
    };

    get associatedCompanies() {
        return Array.from(this.associatedCompaniesMap).map(
            ([, value]) => value,
        );
    }

    constructor() {
        makeAutoObservable(this);
    }
}

import { UserAchievementName } from 'src/data/api/graphql/br_process/generated/graphql-sdk';
import {
    CallbackService,
    createCallbackService,
} from 'src/data/services/callback/callback.service';

type AchievementCallback = (event: UserAchievementName) => void;

export type AchievementService = CallbackService<AchievementCallback>;

export const createAchievementService = (): AchievementService => {
    return createCallbackService<AchievementCallback>();
};

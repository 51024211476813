import { Margins, Style, TableLayout } from 'pdfmake/interfaces';

export const twoColumnTableLayout: TableLayout = {
    hLineWidth: () => {
        return 0;
    },
    vLineWidth: () => {
        return 0;
    },
    hLineColor: () => {
        return '#FFFFFF';
    },
    paddingLeft: () => {
        return 0;
    },
    paddingRight: (i: number) => {
        return i === 0 ? 5 : 0;
    },
};

export const titleStyle: Style = {
    fontSize: 16,
};
export const sectionHeaderStyle: Style = {
    fontSize: 16,
};

//for some reason, margins are not working inside style which used to work in angular.
export const sectionHeaderMargin: Margins = [0, 6];
export const sectionMargin: Margins = [0, 5, 0, 5];
export const exportedTextMargin: Margins = [0, 0, 15, 0];

export const contactTitleStyle: Style = {
    fontSize: 16,
};
export const minorInfoStyle: Style = { color: '#777777' };
export const footerStyle: Style = { color: 'gray', fontSize: 10 };
export const exportedStyle: Style = {
    color: 'gray',
    fontSize: 10,
    alignment: 'right',
};

export const defaultStyle: Style = {
    color: '#272727',
    fontSize: 12,
};
export const pageMargins: Margins = [40, 70, 40, 60];

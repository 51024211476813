import { lazy, memo } from 'react';
import { Route, Switch } from 'react-router-dom';

import { AppRoute } from 'src/presentation/modules/router/app-route.list';
import { SessionComponent } from 'src/presentation/modules/session/session.component';

const LazyLoginPage = lazy(() =>
    import('../login-page/login-page.container').then((module) => ({
        default: module.LoginPageContainer,
    })),
);

const LazyForgetPasswordPage = lazy(() =>
    import('../forget-password-page/forget-password-page.container').then(
        (module) => ({
            default: module.ForgetPasswordPageContainer,
        }),
    ),
);

const LazyNewPasswordPage = lazy(() =>
    import('../new-password-page/new-password-page.container').then(
        (module) => ({
            default: module.NewPasswordPageContainer,
        }),
    ),
);

export const MainComponent = memo(() => {
    return (
        <Switch>
            <Route
                path={AppRoute.login}
                exact
                render={() => <LazyLoginPage />}
            />

            <Route
                path={AppRoute.recoverPassword}
                render={() => <LazyForgetPasswordPage />}
            />
            <Route
                path={`${AppRoute.newPassword}/:token`}
                render={() => <LazyNewPasswordPage />}
            />
            <Route path={AppRoute.empty} render={() => <SessionComponent />} />
            <Route
                render={() => {
                    return <>No page found: {window.location.pathname}</>;
                }}
            />
        </Switch>
    );
});

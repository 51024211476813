import { ProcessGqlSdk } from 'src/data/api/graphql/graphql-client.wrapper';
import { formatToApiDateFormat } from 'src/utils/datetime.utils';

import {
    CreateReminderParams,
    UpdateReminderParams,
    DismissReminderParams,
} from './api-params';
import { ReminderResponse } from './api-responses';
import { IRemindersApi } from './reminders.interface';

export const createRemindersApi = (
    processGqlSdk: ProcessGqlSdk,
): IRemindersApi => {
    const createReminder = async (
        params: CreateReminderParams,
    ): Promise<ReminderResponse> => {
        const { signal, ...paramsRest } = params;

        const dueDate = formatToApiDateFormat(paramsRest.dueDate);

        const response = await processGqlSdk.CreateReminder({
            data: { ...paramsRest, dueDate },
        });

        return response.createReminder;
    };

    const updateReminder = async (
        params: UpdateReminderParams,
    ): Promise<ReminderResponse> => {
        const { signal, reminderId, ...paramsRest } = params;

        const dueDate = formatToApiDateFormat(paramsRest.dueDate);

        const response = await processGqlSdk.UpdateReminder({
            reminderId,
            update: { ...paramsRest, dueDate },
        });

        return response.updateReminder;
    };

    const dismissReminder = async ({ reminderId }: DismissReminderParams) => {
        const response = await processGqlSdk.DismissReminder({ reminderId });
        return response.completeReminder.id;
    };

    return {
        createReminder,
        updateReminder,
        dismissReminder,
    };
};

import { makeAutoObservable } from 'mobx';

import { isAbortError } from 'src/utils/handle-request.utils';

// probably move to error model
export const UNAUTHORIZED_MESSAGE = 'Unauthorized';

export interface ErrorsStore {
    errors: Map<string, Error>;
    unauthorizeError: Error | undefined;
    handle: (key: string) => (error?: Error) => void;
    clearError: (key: string) => void;
    clearAllErrors: () => void;
    setUnauthorizeError: (error?: Error) => void;
}

export class ErrorsStoreImpl implements ErrorsStore {
    private errorsMap: Map<string, Error> = new Map();

    // CHANGE TO MAP
    get errors() {
        return this.errorsMap;
    }

    public unauthorizeError: Error | undefined = undefined;

    public setUnauthorizeError = (error?: Error) => {
        this.unauthorizeError = error;
    };

    handle = (key: string) => (error?: Error) => {
        if (error) {
            if (error.message === UNAUTHORIZED_MESSAGE) {
                this.setUnauthorizeError(error);
            } else if (!isAbortError(error)) {
                console.error(error.message);
                this.setError(key, error);
            }
        } else {
            this.setUnauthorizeError(undefined);
            this.clearError(key);
        }
    };

    clearAllErrors = () => {
        this.errorsMap.clear();
    };

    clearError = (key: string) => {
        this.errorsMap.delete(key);
    };

    setError = (key: string, error: Error) => {
        this.errorsMap.set(key, error);
    };

    constructor() {
        makeAutoObservable(this);
    }
}

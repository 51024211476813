export enum OtherRoutes {
    pipepline = 'pipeline',
    searches = 'searches',
    notifications = 'notifications',
    help = 'help',
    settings = 'settings',
}
export const readableOtherRoutes: Record<OtherRoutes, string> = {
    [OtherRoutes.pipepline]: 'Project Pipeline',
    [OtherRoutes.searches]: 'project_lookup.title',
    [OtherRoutes.notifications]: 'navbar.notifications',
    [OtherRoutes.help]: 'navbar.help',
    [OtherRoutes.settings]: 'navbar.settings',
};

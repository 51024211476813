import {
    BrCheckboxOption,
    BrInfotip,
    BrText,
} from '@buildingradar/br_component_lib';
import { Box } from '@mui/material';
import { observer } from 'mobx-react';
import { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { isLinkedinUrl } from 'src/app-features/contact/presentation/contact-section/components/contact-card/field-configs';
import { ContactEnrichmentData } from 'src/app-features/contact-enrichment/domain/contact-enrichment.model';
import { EnrichmentSourcesBadgeComponent } from 'src/app-features/contact-enrichment/presentation/components/source-badge/source-badge.component';
import {
    EnrichmentResultFieldStyled,
    EnrichmentResultGroupStyled,
} from 'src/app-features/contact-enrichment/presentation/styles';
import { ActionPanelLabelStyled } from 'src/presentation/modules/enablement-actions-panel/actions/styled';
import { removeUrlPrefix } from 'src/utils/url.utils';
import { LinkStyled } from './styles';

interface EnrichmentResultFieldComponentProps {
    options: ContactEnrichmentData[];
    label: string;
    selectedValue?: ContactEnrichmentData;
    isLink?: boolean;
    setClickedLink: (link: string) => void;
    onSelect: (val?: ContactEnrichmentData) => void;
}
export const EnrichmentResultFieldComponent: FC<EnrichmentResultFieldComponentProps> =
    observer(
        ({
            label,
            options,
            selectedValue,
            isLink,
            onSelect,
            setClickedLink,
        }) => {
            const { t } = useTranslation();

            const onClickLink = useCallback(
                (
                    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
                    link: string,
                ) => {
                    e.stopPropagation();
                    e.preventDefault();
                    setClickedLink(link);
                },
                [setClickedLink],
            );

            const isLinkedinSection = isLinkedinUrl(options[0].value);

            const checkboxOptions: BrCheckboxOption[] = useMemo(() => {
                return options.map(
                    (item): BrCheckboxOption => ({
                        name: item.value,
                        checked: selectedValue?.value === item.value,
                        label: (
                            <EnrichmentResultFieldStyled>
                                <EnrichmentSourcesBadgeComponent
                                    providers={item.providers}
                                    highlight={item.providers.length > 1}
                                />
                                <BrInfotip
                                    disableInteractive
                                    showArrow
                                    content={
                                        isLinkedinSection ? (
                                            t(
                                                'deal_view.contacts_section.quick_actions.linkedin_lookup',
                                            )
                                        ) : (
                                            <BrText>{item.value}</BrText>
                                        )
                                    }
                                    placement="top"
                                >
                                    <Box maxWidth="280px" marginLeft="10px">
                                        <BrText
                                            noWrap
                                            color={
                                                isLink
                                                    ? 'var(--blue-60)'
                                                    : 'var(--gray-80)'
                                            }
                                        >
                                            {isLink ? (
                                                <LinkStyled
                                                    href={item.value}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    onClick={(e) =>
                                                        onClickLink(
                                                            e,
                                                            item.value,
                                                        )
                                                    }
                                                >
                                                    {removeUrlPrefix(
                                                        item.value,
                                                    )}
                                                </LinkStyled>
                                            ) : (
                                                item.value
                                            )}
                                        </BrText>
                                    </Box>
                                </BrInfotip>
                            </EnrichmentResultFieldStyled>
                        ),
                    }),
                );
            }, [
                isLink,
                isLinkedinSection,
                onClickLink,
                options,
                selectedValue?.value,
                t,
            ]);

            const onCheck = useCallback(
                (checked: boolean, checkboxName: string) => {
                    if (checked) {
                        onSelect(
                            options.find((opt) => opt.value === checkboxName),
                        );
                    } else {
                        onSelect(undefined);
                    }
                },
                [onSelect, options],
            );
            return (
                <>
                    <ActionPanelLabelStyled applyTopMargin>{`${t(label)}(${
                        options.length
                    })`}</ActionPanelLabelStyled>
                    <EnrichmentResultGroupStyled
                        options={checkboxOptions}
                        onCheckedChange={onCheck}
                    />
                </>
            );
        },
    );

import { observer } from 'mobx-react';
import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import {
    DealContactCompany,
    DealContactPerson,
} from 'src/app-features/contact/data/model/deal-contacts.model';
import {
    DomainEnrichmentOption,
    EnrichmentStep,
} from 'src/app-features/contact-enrichment/domain/contact-enrichment.model';
import { buildSelectedDomainOption } from 'src/app-features/contact-enrichment/domain/utils';
import { CustomDomainInput } from 'src/app-features/contact-enrichment/presentation/components/search-refinement/custom-domain-input/custom-domain-input.component';
import { ProgressIndicator } from 'src/presentation/shared/progress-indicator/progress-indicator.component';

import { SearchRefinementDomainSelection } from './search-refinement-domain-selection.component';
import { Button } from '@buildingradar/ui_library/button';
import { EnrichmentIterateStateParams } from 'src/app-features/contact-enrichment/domain/contact-enrichment.feature';

interface SearchRefinementCardProps {
    contact?: DealContactPerson;
    isLoading: boolean;
    results: DomainEnrichmentOption[];
    currentCompany?: DealContactCompany;
    initialDomain?: string | null;
    hideTitle?: boolean;
    useCheckbox?: boolean;
    selectedDomainOption?: DomainEnrichmentOption;
    iterateState: (params?: EnrichmentIterateStateParams) => void;
    onSelectDomainOption: (option?: DomainEnrichmentOption) => void;
    saveCompanyInfoAndEnrich: (
        payload: DomainEnrichmentOption,
        refined?: boolean,
    ) => void;
}
export const SearchRefinementCard: FC<SearchRefinementCardProps> = observer(
    ({
        hideTitle,
        isLoading,
        results,
        initialDomain,
        currentCompany,
        contact,
        useCheckbox,
        selectedDomainOption,
        onSelectDomainOption,
        iterateState,
        saveCompanyInfoAndEnrich,
    }) => {
        const { t } = useTranslation();

        if (!contact || !currentCompany) {
            return null;
        }

        const onDomainSelected = useCallback(
            (domainOption?: DomainEnrichmentOption) => {
                if (useCheckbox) {
                    onSelectDomainOption(domainOption);
                } else {
                    const selection = buildSelectedDomainOption(
                        results,
                        domainOption,
                    );
                    !!selection &&
                        saveCompanyInfoAndEnrich(selection, useCheckbox);
                }
            },
            [
                onSelectDomainOption,
                results,
                saveCompanyInfoAndEnrich,
                useCheckbox,
            ],
        );

        const handleSkipDomainRefinement = () => {
            iterateState({ to: EnrichmentStep.contactEnrichment });
        };

        return (
            <div className="flex flex-col rounded border border-solid border-indigo-100 px-4 py-2">
                {!hideTitle && (
                    <div className="h-7 text-sm font-semibold uppercase leading-7 tracking-wide text-gray-400">
                        {t(
                            'actions.contact_enrichment.search_refinement.title',
                        )}
                    </div>
                )}
                <div className="pt-2">
                    {isLoading ? (
                        <ProgressIndicator
                            hint={t(
                                'actions.contact_enrichment.search_refinement.loading_hint',
                            )}
                        />
                    ) : (
                        <>
                            {!results.length ? (
                                <>
                                    <div className="mx-3 mb-3 text-base tracking-wide text-amber-500">
                                        {t(
                                            'actions.contact_enrichment.search_refinement.failure_hint',
                                        )}
                                    </div>
                                    <CustomDomainInput
                                        initialDomain={initialDomain}
                                        relevantCompany={
                                            contact.relevantCompany
                                        }
                                        onDomainSelected={onDomainSelected}
                                        useCheckbox={useCheckbox}
                                        selectedOption={selectedDomainOption}
                                    />
                                </>
                            ) : (
                                <>
                                    <CustomDomainInput
                                        initialDomain={initialDomain}
                                        relevantCompany={
                                            contact.relevantCompany
                                        }
                                        onDomainSelected={onDomainSelected}
                                        selectedOption={selectedDomainOption}
                                        useCheckbox={useCheckbox}
                                    />
                                    <SearchRefinementDomainSelection
                                        results={results}
                                        useCheckbox={useCheckbox}
                                        selectedOption={selectedDomainOption}
                                        onDomainSelected={onDomainSelected}
                                    />
                                </>
                            )}
                            {!useCheckbox && (
                                <Button
                                    color="secondary"
                                    variant="ghost"
                                    size="sm"
                                    onClick={handleSkipDomainRefinement}
                                >
                                    {t(
                                        'actions.contact_enrichment.search_refinement.actions.skip',
                                    )}
                                </Button>
                            )}
                        </>
                    )}
                </div>
            </div>
        );
    },
);

enum EnterpriseErrorCodes {
    invalidAuth = 401,
    duplicate = 409,
    internalError = 500,
    crmError = 502,
}

enum EnterpriseErrorMessages {
    invalidAuth = 'lead.export_error.invalid_auth',
    duplicate = 'lead.export_error.duplicate',
    internalError = 'lead.export_error.internal_error',
    crmError = 'lead.export_error.crm_error',
}

export const throwRelevantError = (errorCode: number): void => {
    switch (errorCode) {
        case EnterpriseErrorCodes.invalidAuth: {
            throw new Error(EnterpriseErrorMessages.invalidAuth);
        }
        case EnterpriseErrorCodes.duplicate: {
            throw new Error(EnterpriseErrorMessages.duplicate);
        }
        case EnterpriseErrorCodes.internalError: {
            throw new Error(EnterpriseErrorMessages.internalError);
        }
        case EnterpriseErrorCodes.crmError: {
            throw new Error(EnterpriseErrorMessages.crmError);
        }
    }
    throw new Error('lead.export_error.default');
};

import { BrButtonColor } from '@buildingradar/br_component_lib';
import styled from '@emotion/styled';

import { DealState } from 'src/domain/models/deal/deal.model';
import { Link } from 'src/presentation/shared/link/link.component';
import { MediaQuery } from 'src/resources/styles/media.mixins';
import { Spacing } from 'src/resources/styles/spacing';
import { ZIndex } from 'src/resources/styles/zIndex';

const StateBackgroundColor: Record<DealState, string> = {
    [DealState.inPlay]: 'var(--accent-background)',
    [DealState.disqualified]: 'var(--pastel-red)',
    [DealState.done]: 'var(--pastel-green)',
};

export const StateButtonColor: Record<DealState, BrButtonColor> = {
    [DealState.inPlay]: BrButtonColor.primary,
    [DealState.disqualified]: BrButtonColor.danger,
    [DealState.done]: BrButtonColor.green,
};
export const DealHeaderStickyWrapperStyled = styled.div`
    position: sticky;
    top: 0px;
    width: 100%;
    z-index: ${ZIndex.zStickyDealHeader};
    background-color: transparent;
`;

export const DealTopLineStyled = styled.div<{ dealState: DealState }>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px;
    border-radius: 4px;
    background-color: ${({ dealState }) => StateBackgroundColor[dealState]};

    ${MediaQuery.tablet} {
        padding: ${Spacing.s2} ${Spacing.s2};
    }
`;

export const WrapperStyled = styled.div`
    display: grid;
    grid-auto-flow: column;
    align-items: center;
    column-gap: 16px;
`;

export const MobileBackLinkStyled = styled(Link)`
    display: flex;
    align-item: center;
    margin-right: -8px;
`;

export const DealDesktopHeaderStyled = styled.div`
    margin-bottom: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: ${Spacing.s2};
`;

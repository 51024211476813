import { withFeatures } from 'src/utils/component.utils';

import { GoogleEnrichmentCompanySelect } from './google-enrichment-company-select.component';

export const GoogleEnrichmentCompanySelectContainer = withFeatures(
    GoogleEnrichmentCompanySelect,
)((f) => ({
    selectedCompany: f.googleEnrichmentFeature.selectedCompany,
    selectedContact: f.googleEnrichmentFeature.selectedContact,
    companyOptions: f.googleEnrichmentFeature.companyOptions,
    setSelectedCompany: f.googleEnrichmentFeature.setSelectedCompany,
    updateContactPerson: f.dealContactsManageFeature.updateDealContactPerson,
}));

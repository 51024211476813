import { Tooltip } from '@buildingradar/ui_library/tooltip';
import { Files } from 'lucide-react';
import { FC } from 'react';

import { copyValueToClipboard } from 'src/utils/string.utils';

interface CopyToClipboardProps {
    text: string;
    tooltipText: string;
}
export const CopyToClipboard: FC<CopyToClipboardProps> = ({
    text,
    tooltipText,
}) => {
    const handleOnIconClick = () => {
        copyValueToClipboard(text);
    };

    return (
        <Tooltip content={tooltipText} side="top">
            <Files size={18} onClick={handleOnIconClick} className="ml-1" />
        </Tooltip>
    );
};

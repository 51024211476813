import {
    AvatarSizes,
    BrAvatar,
    BrButtonColor,
    BrButtonDropdown,
    BrButtonVariant,
    BrIcon,
    BrText,
    IconName,
    IconSize,
    MenuItemConfiguration,
    TextWeight,
} from '@buildingradar/br_component_lib';
import { Box, useMediaQuery } from '@mui/material';
import { observer } from 'mobx-react';
import { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Colleague } from 'src/domain/models/colleague/colleague.model';
import { UNASSIGNED_USER_ID } from 'src/domain/models/deal/deal.model';
import { getUserFullName } from 'src/domain/models/user/user.model';
import { MediaQuery } from 'src/resources/styles/media.mixins';
import { Spacing } from 'src/resources/styles/spacing';

import { UserAssignmentStyled } from './styles';

interface UserAssignmentDropdownComponentProps {
    currentAssignedTo?: number;
    assignToUsers: Colleague[];
    showAvatarOnly?: boolean;
    displayUnassignedOption?: boolean;
    showLabel?: boolean;
    onUserSelected: (userId: number) => void;
    fullWidth?: boolean;
}

export const UserAssignmentDropdown: FC<UserAssignmentDropdownComponentProps> =
    observer(
        ({
            currentAssignedTo,
            assignToUsers,
            showAvatarOnly,
            displayUnassignedOption,
            showLabel,
            onUserSelected,
            fullWidth = false,
        }) => {
            const { t } = useTranslation();
            const isTablet = useMediaQuery(MediaQuery.tablet);
            const isMobile = useMediaQuery(MediaQuery.phone);

            const assignToUserAvatarOptions: MenuItemConfiguration[] =
                useMemo(() => {
                    const options = assignToUsers.map((user) => {
                        const avatarDisplayName = getUserFullName(user);

                        return {
                            value: user.itemId.toString(),
                            customContent: {
                                element: (
                                    <>
                                        <BrAvatar
                                            avatarData={user}
                                            size={AvatarSizes.s}
                                        />
                                        <Box sx={{ ml: 1 }}>
                                            <BrText>{avatarDisplayName}</BrText>
                                        </Box>
                                    </>
                                ),
                                searchTerm: avatarDisplayName,
                            },
                        };
                    });

                    if (displayUnassignedOption) {
                        options.unshift({
                            value: UNASSIGNED_USER_ID.toString(),
                            customContent: {
                                element: (
                                    <>
                                        <BrIcon
                                            iconName={
                                                IconName.BrPersonOutlineIcon
                                            }
                                            iconColor={'var(--gray-80)'}
                                        />
                                        <Box sx={{ ml: 1 }}>
                                            <BrText>
                                                {t('common.unassigned')}
                                            </BrText>
                                        </Box>
                                    </>
                                ),
                                searchTerm: t('common.unassigned'),
                            },
                        });
                    }

                    return options;
                }, [assignToUsers, displayUnassignedOption, t]);

            const assignedUser = useMemo(
                () =>
                    assignToUsers.find(
                        ({ itemId }) => itemId === currentAssignedTo!,
                    ),
                [assignToUsers, currentAssignedTo],
            );

            const assignedUserAvatar = assignedUser && (
                <BrAvatar avatarData={assignedUser} size={AvatarSizes.s} />
            );

            const assignedUserName =
                assignedUser && getUserFullName(assignedUser);

            const unassignedUserAvatar = (
                <BrIcon
                    iconName={IconName.BrPersonOutlineIcon}
                    iconSize={IconSize.large}
                    iconColor="var(--gray-60)"
                />
            );

            const label = (
                <Box
                    sx={{
                        maxWidth: fullWidth
                            ? '100%'
                            : isTablet
                              ? '115px'
                              : undefined,
                        width: fullWidth ? '100%' : undefined,
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        marginRight: showAvatarOnly
                            ? `-${Spacing.s1}`
                            : undefined,
                        textAlign: 'start',
                    }}
                >
                    {showAvatarOnly
                        ? ''
                        : assignedUser
                          ? assignedUserName
                          : t('common.unassigned')}
                </Box>
            );

            const handleUserSelection = useCallback(
                (selectedId: string) => {
                    onUserSelected(Number(selectedId));
                },
                [onUserSelected],
            );

            const showHorizontalLabel = !isTablet && showLabel;

            return (
                <UserAssignmentStyled fullWidth={fullWidth}>
                    {showHorizontalLabel && (
                        <BrText
                            weight={TextWeight.semibold1}
                            color="var(--gray-80)"
                        >
                            {t('deal_view.user_assignment.label')}
                        </BrText>
                    )}
                    <Box
                        sx={{
                            width:
                                fullWidth || !showHorizontalLabel
                                    ? '100%'
                                    : undefined,
                        }}
                    >
                        <BrButtonDropdown
                            label={label}
                            isItemSelected={(item) =>
                                item ===
                                (assignedUser?.itemId.toString() ??
                                    UNASSIGNED_USER_ID.toString())
                            }
                            items={assignToUserAvatarOptions}
                            startCustomElement={
                                assignedUser
                                    ? assignedUserAvatar
                                    : unassignedUserAvatar
                            }
                            onItemSelected={handleUserSelection}
                            color={BrButtonColor.standard}
                            variant={BrButtonVariant.text}
                            fullWidth
                            enableSearch
                            stopContentClickPropagation
                            preventContentClickDefault
                            useSheetMenu={isMobile}
                            maxMenuHeight={600}
                            searchLabel={t('common.search')}
                            searchPlaceholder={t('common.search_placeholder')}
                        />
                    </Box>
                </UserAssignmentStyled>
            );
        },
    );

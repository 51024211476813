import { OutreachBlockType as SdkOutreachBlockType } from 'src/data/api/graphql/br_process/generated/graphql-sdk';

export enum OutreachBlockType {
    valueProposition = 'valueProposition',
    desiredOutcome = 'desiredOutcome',
    writingStyle = 'writingStyle',
}

export const SdkToOutreachBlockType = {
    [SdkOutreachBlockType.ValueProposition]: OutreachBlockType.valueProposition,
    [SdkOutreachBlockType.DesiredOutcome]: OutreachBlockType.desiredOutcome,
    [SdkOutreachBlockType.WritingStyle]: OutreachBlockType.writingStyle,
};

export const OutreachBlockTypeToSdk = {
    [OutreachBlockType.valueProposition]: SdkOutreachBlockType.ValueProposition,
    [OutreachBlockType.desiredOutcome]: SdkOutreachBlockType.DesiredOutcome,
    [OutreachBlockType.writingStyle]: SdkOutreachBlockType.WritingStyle,
};

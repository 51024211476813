import { makeAutoObservable } from 'mobx';

export interface IVpoGenerationStore {
    vpoSessionId: string | null;
    buildVpoSessionId: (userId: number) => string;
    clearVpoSessionId: () => void;
}

export class VpoGenerationStore implements IVpoGenerationStore {
    vpoSessionId: string | null = null;

    constructor() {
        makeAutoObservable(this);
    }

    buildVpoSessionId = (userId: number): string => {
        this.vpoSessionId = `${userId}_${new Date().getTime()}`;
        return this.vpoSessionId;
    };

    clearVpoSessionId = (): void => {
        this.vpoSessionId = null;
    };
}

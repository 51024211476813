import {
    DealContactCompanyForm,
    DealContactPersonForm,
} from 'src/app-features/contact/data/model/deal-contacts-form.model';
import {
    DealContactType,
    PartialDealContactCompany,
    PartialDealContactPerson,
    PartialDealContactType,
} from 'src/app-features/contact/data/model/deal-contacts.model';

export const mapDealContactToForm = (contact: PartialDealContactType) => {
    return contact.type === DealContactType.person
        ? mapDealContactToPersonForm(contact)
        : mapDealContactToCompanyForm(contact);
};

const mapDealContactToPersonForm = (
    contact: PartialDealContactPerson,
): DealContactPersonForm => ({
    formType: DealContactType.person,
    firstName: contact.firstName ?? undefined,
    lastName: contact.lastName ?? undefined,
    relevantCompanyId: contact.relevantCompanyId,
    role: contact.role ?? undefined,
    isKey: contact.isKey ?? undefined,
    notes: contact.notes ?? undefined,
    customFields: contact.customFields,
});

const mapDealContactToCompanyForm = (
    contact: PartialDealContactCompany,
): DealContactCompanyForm => ({
    formType: DealContactType.company,
    name: contact.name,
    role: contact.role ?? undefined,
    notes: contact.notes ?? undefined,
    customFields: contact.customFields,
});

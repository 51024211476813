import { makeAutoObservable } from 'mobx';

import { ISearchesApi } from 'src/app-features/searches-configuration/api/searches.api';
import { SubscriptionApi } from 'src/data/api/subscriptions/subscriptions.api';
import { LeadsStore } from 'src/data/stores/leads/leads.store';
import { IBaseStore } from 'src/data/stores/shared/base.store.interface';
import { SubscriptionsStore } from 'src/data/stores/subscriptions/subscriptions.store';
import { UserStore } from 'src/data/stores/user/user.store';
import {
    AssociatedSearch,
    createAssociatedSearch,
} from 'src/domain/models/associated-searches/associated-searches.model';
import { FilterModule } from 'src/domain/models/filter-module/filter-module.model';
import { Lead } from 'src/domain/models/lead/lead.model';
import { Search } from 'src/domain/models/search/search.model';
import { SearchSubscriptionData } from 'src/domain/models/subscription/subscription.model';
import { doNothing } from 'src/utils/function.utils';
import {
    Cancellable,
    handleRequest,
    handleRequestAsync,
} from 'src/utils/handle-request.utils';
import { isNonNullable } from 'src/utils/is-non-nullable.utils';

export interface AssociatedSearchesFeature {
    getAssociatedSearches: (lead: Lead) => void;
    associateSearchesCount: number;
    subscriptionList: SearchSubscriptionData[];
    requestSearchSubscriptions: () => Cancellable;
    subscribeToSearch: (
        searchId: number,
        subscriberId: number,
    ) => Promise<void>;
    unsubscribeFromSearch: (
        searchId: number,
        subscriberId: number,
    ) => Promise<void>;
    requestFilterModules: () => Cancellable;
    filterModules: Map<number, FilterModule>;
    pauseSearch: (searchId: number) => Cancellable;
    resumeSearch: (searchId: number) => Cancellable;
    deleteSearch: (searchId: number) => Cancellable;
    setSelectedSearchId: (searchId: number) => void;
    selectedSearch: SearchSubscriptionData | undefined;
    isDetailModalOpen: boolean;
    setIsDetailModalOpen: (flag: boolean) => void;
}

export class AssociatedSearchesFeatureImpl
    implements AssociatedSearchesFeature
{
    associatedSearches: AssociatedSearch[] = [];
    selectedSearchId = -1;
    isDetailModalOpen = false;

    public get associateSearchesCount() {
        return this.associatedSearches.length;
    }

    public get subscriptionList() {
        return this.subscriptionsStore.subscriptionsList;
    }

    public get filterModules() {
        return this.subscriptionsStore.filterModules;
    }

    public get selectedSearch() {
        return this.subscriptionsStore.subscriptions.get(this.selectedSearchId);
    }

    setIsDetailModalOpen = (flag: boolean) => {
        this.isDetailModalOpen = flag;
    };

    setSelectedSearchId = (searchId: number) => {
        this.selectedSearchId = searchId;
    };

    constructor(
        private subscriptionApi: SubscriptionApi,
        private userStore: UserStore,
        private leadsStore: LeadsStore,
        private subscriptionsStore: SubscriptionsStore,
        private searchesApi: ISearchesApi,
        private baseStore: IBaseStore,
    ) {
        makeAutoObservable(this);
    }

    private clearAssociatedSearches = () => {
        this.associatedSearches = [];
    };

    public getAssociatedSearches = (lead: Lead) => {
        this.clearAssociatedSearches();
        if (lead.searchIds.length) {
            this.requestUserSubscription(lead);
        }
    };

    public setAssociatedSearches = (associatedSearches: AssociatedSearch[]) => {
        this.associatedSearches = associatedSearches;
    };

    private setSearchesFromSubscriptions = (lead: Lead) => {
        const leadSearches = lead.searchIds
            .map((searchId) => {
                const subscription =
                    this.subscriptionsStore.subscriptions.get(searchId);
                return subscription
                    ? createAssociatedSearch({
                          item_id: subscription.id,
                          name: subscription.name,
                      })
                    : undefined;
            })
            .filter(isNonNullable);
        this.setAssociatedSearches(leadSearches);
    };

    public setUserSubscriptions = (
        userSubscriptions: SearchSubscriptionData[],
        lead: Lead,
    ) => {
        this.subscriptionsStore.setSubscriptions(userSubscriptions);
        this.setSearchesFromSubscriptions(lead);
    };

    private requestUserSubscription = (lead: Lead) => {
        if (!this.subscriptionsStore.subscriptions.size) {
            this.requestSearchSubscriptions();
        } else {
            this.setSearchesFromSubscriptions(lead);
        }
    };

    public requestSearchSubscriptions = () => {
        return handleRequest(
            this.subscriptionApi.getUserSubscriptions,
            {},
            this.subscriptionsStore.setSubscriptions,
            doNothing,
            (error) =>
                this.baseStore.onRequestFailed(
                    'request-search-subscriptions',
                    error,
                ),
        );
    };

    onSubscribeToSearch = (search: Search, subscriberId: number) => {
        const user = this.userStore.user;
        if (!user) {
            return;
        }
        this.subscriptionsStore.subscribeUserToSearchSubscription(
            subscriberId,
            search.id,
        );
        if (subscriberId === user.itemId) {
            this.userStore.addToSearches(search);
        }
    };

    public subscribeToSearch = async (
        searchId: number,
        subscriberId: number,
    ) => {
        try {
            const search = await handleRequestAsync(
                this.subscriptionApi.subscribeToSearch,
                {
                    subscriberId,
                    searchId,
                },
            );

            if (search) {
                this.onSubscribeToSearch(search, subscriberId);
            }
        } catch (error) {
            this.baseStore.onRequestFailed(
                'subscribe-to-search',
                error as Error,
            );
        }
    };

    onUnsubscribeFromSearch = (searchId: number, subscriberId: number) => {
        const user = this.userStore.user;
        if (!user) {
            return;
        }
        this.subscriptionsStore.unsubscribeUserToSearchSubscription(
            subscriberId,
            searchId,
        );
        if (subscriberId === user.itemId) {
            this.userStore.removeFromSearches(searchId);
            this.leadsStore.clear();
        }
    };

    public unsubscribeFromSearch = async (
        searchId: number,
        subscriberId: number,
    ) => {
        try {
            const unsubscribed = await handleRequestAsync(
                this.subscriptionApi.unsubscribeFromSearch,
                {
                    subscriberId,
                    searchId,
                },
            );

            if (unsubscribed) {
                this.onUnsubscribeFromSearch(searchId, subscriberId);
            }
        } catch (error) {
            this.baseStore.onRequestFailed(
                'unsubscribe-from-search',
                error as Error,
            );
        }
    };

    public requestFilterModules = () => {
        return handleRequest(
            this.searchesApi.getFilterModules,
            {},
            this.subscriptionsStore.setFilterModules,
            doNothing,
            (error) =>
                this.baseStore.onRequestFailed('request-filter-modules', error),
        );
    };

    public pauseSearch = (searchId: number) => {
        return handleRequest(
            this.subscriptionApi.pauseSearch,
            { searchId },
            () => this.subscriptionsStore.pauseSearch(searchId),
            doNothing,
            (error) => this.baseStore.onRequestFailed('pause-search', error),
        );
    };

    public resumeSearch = (searchId: number) => {
        return handleRequest(
            this.subscriptionApi.resumeSearch,
            { searchId },
            () => this.subscriptionsStore.resumeSearch(searchId),
            doNothing,
            (error) => this.baseStore.onRequestFailed('resume-search', error),
        );
    };

    onDeleteSearch = (searchId: number) => {
        this.subscriptionsStore.deleteSearch(searchId);
        this.userStore.removeFromSearches(searchId);
        this.leadsStore.clear();
        this.setIsDetailModalOpen(false);
    };

    public deleteSearch = (searchId: number) => {
        return handleRequest(
            this.searchesApi.deleteSearch,
            { id: searchId },
            () => this.onDeleteSearch(searchId),
            doNothing,
            (error) => this.baseStore.onRequestFailed('delete-search', error),
        );
    };
}

import { TabConfiguration } from '@buildingradar/br_component_lib';
import { TFunction } from 'react-i18next';

import { MentionsDashboardContainer } from 'src/presentation/modules/notifications-flyout/components/mentions-dashboard/mentions-dashboard.container';
import { RemindersDashboardContainer } from 'src/presentation/modules/notifications-flyout/components/reminders-dashboard/reminders-dashboard.container';

export const buildTabConfiguration = (
    t: TFunction<'translation', undefined>,
): TabConfiguration[] => {
    const config: TabConfiguration[] = [];

    config.push({
        label: t('notification_flyout.reminders_tab.tab_title'),
        tabValue: 'reminders',
        content: <RemindersDashboardContainer />,
    });

    config.push({
        label: t('notification_flyout.mentions_tab.tab_title'),
        tabValue: 'mentions',
        content: <MentionsDashboardContainer />,
    });

    return config;
};

import { useMediaQuery } from '@mui/material';
import { observer } from 'mobx-react';
import { FC, ReactNode } from 'react';
import { useLocation } from 'react-router';

import { MobileMenuSwiperContainer } from 'src/presentation/modules/mobile-menu/mobile-menu-swiper.container';
import { NotificationsFlyoutContainer } from 'src/presentation/modules/notifications-flyout/notifications-flyout.container';
import { PrimaryNavbarComponent } from 'src/presentation/modules/primary-nav-bar/primary-nav-bar.component';
import { AppRoute } from 'src/presentation/modules/router/app-route.list';
import { MediaQuery } from 'src/resources/styles/media.mixins';

import { PageStyled, ContentWrapperStyled } from './session-layout.styled';

export const SessionLayout: FC<{ children?: ReactNode }> = observer(
    ({ children }) => {
        const isTablet = useMediaQuery(MediaQuery.tablet);
        const path = useLocation().pathname;
        const hasSecondaryNavbar = path.startsWith(AppRoute.pipelines);

        return (
            <PageStyled>
                {isTablet ? (
                    <MobileMenuSwiperContainer />
                ) : (
                    <>
                        <PrimaryNavbarComponent />
                        <NotificationsFlyoutContainer />
                    </>
                )}
                <ContentWrapperStyled hasSecondaryNavbar={hasSecondaryNavbar}>
                    {children}
                </ContentWrapperStyled>
            </PageStyled>
        );
    },
);

import { useMediaQuery } from '@mui/material';
import { observer } from 'mobx-react';
import { FC, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { ActionContentComponent } from 'src/presentation/modules/enablement-actions-panel/actions/action-content.component';
import { MediaQuery } from 'src/resources/styles/media.mixins';

import { ContactEnrichmentPanelActionsContainer } from './components/action-buttons/contact-enrichment-actions.container';
import { ContactEnrichmentContainer } from './components/enrichment-content/contact-entichment-content.container';

interface ContactEnrichmentPanelComponentProps {
    enrichmentCleanUp: () => void;
    quickActionCleanUp: () => void;
}

export const ContactEnrichmentPanelComponent: FC<ContactEnrichmentPanelComponentProps> =
    observer(({ enrichmentCleanUp, quickActionCleanUp }) => {
        const { t } = useTranslation();
        const isMobile = useMediaQuery(MediaQuery.phone);
        useEffect(() => enrichmentCleanUp, [enrichmentCleanUp]);
        const onClose = useCallback(() => {
            quickActionCleanUp();
            enrichmentCleanUp();
        }, [enrichmentCleanUp, quickActionCleanUp]);
        return (
            <ActionContentComponent
                contentSxOverride={{ width: isMobile ? '100%' : '340px' }}
                bodySxOverride={{
                    marginRight: '-16px',
                    paddingRight: '16px',
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                }}
                title={t('actions.contact_enrichment.flyout.title')}
                body={<ContactEnrichmentContainer />}
                actions={
                    <ContactEnrichmentPanelActionsContainer onClose={onClose} />
                }
                onClose={onClose}
            />
        );
    });

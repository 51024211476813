import { withFeatures } from 'src/utils/component.utils';

import { ContactEnrichmentPanelComponent } from './contact-enrichment-panel.component';

export const ContactEnrichmentPanelContainer = withFeatures(
    ContactEnrichmentPanelComponent,
)((f) => ({
    enrichmentCleanUp: f.contactEnrichmentFeature.clean,
    quickActionCleanUp: f.quickActionsFeature.clean,
}));

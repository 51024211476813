import { observer } from 'mobx-react';
import { FC } from 'react';
import { FormProvider } from 'react-hook-form';

import {
    DealContactCompany,
    DealContactType,
} from 'src/app-features/contact/data/model/deal-contacts.model';
import { DealContactFormContainer } from 'src/app-features/contact/presentation/contact-panel/components/deal-contact-form.container';
import { companyNameConfig } from 'src/app-features/contact/presentation/contact-panel/form-utils/form-config';
import { useContactForm } from 'src/app-features/contact/presentation/contact-panel/form-utils/form-hooks';

interface SearchRefinementCompanyFormComponentProps {
    dealId: string;
    selectedCompany: DealContactCompany;
}
export const SearchRefinementCompanyFormComponent: FC<SearchRefinementCompanyFormComponentProps> =
    observer(({ dealId, selectedCompany }) => {
        const {
            formApi: companyFormApi,
            formConfig: companyFormConfig,
            onUpdateContactField: onUpdateCompany,
        } = useContactForm({
            dealId,
            selectedContact: selectedCompany,
            customConfig: [companyNameConfig],
        });
        return (
            <FormProvider {...companyFormApi}>
                <DealContactFormContainer
                    dealId={dealId}
                    type={DealContactType.company}
                    formConfig={companyFormConfig}
                    isEditMode={true}
                    onSave={onUpdateCompany}
                />
            </FormProvider>
        );
    });

export interface PendoMetaData {
    visitor: {
        id: string | number;
        email: string;
        full_name: string;
        role: string;
        locale: string;
    };
    account: { id: string | number; companyName: string };
}

export enum EventType {
    SO_CONFIRMED = 'Confirmed SO by providing value',
}

export enum PendoLocale {
    EN = 'en_US',
    DE = 'de_DE',
}

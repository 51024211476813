import { withFeatures } from 'src/utils/component.utils';

import { EnrichmentResultDisplayComponent } from './enrichment-result-display.component';

export const EnrichmentResultDisplayContainer = withFeatures(
    EnrichmentResultDisplayComponent,
)((f) => ({
    enrichmentResults: f.contactEnrichmentFeature.contactEnrichmentResults,
    selectedValues: f.contactEnrichmentFeature.contactEnrichmentResultSelection,
    onSelect: f.contactEnrichmentFeature.onSelectContactEnrichmentResult,
    setClickedLink: f.externalLinkFeature.setClickedLink,
}));

import { makeAutoObservable } from 'mobx';

import {
    LookupToolApi,
    LookupToolResponse,
} from 'src/data/api/lookup-tool/lookup-tool.api';
import { MixpanelEventName } from 'src/data/services/mixpanel/mixpanel.model';
import { MixpanelService } from 'src/data/services/mixpanel/mixpanel.service';
import { LeadsStore } from 'src/data/stores/leads/leads.store';
import { IBaseStore } from 'src/data/stores/shared/base.store.interface';
import { Lead } from 'src/domain/models/lead/lead.model';
import { Language } from 'src/domain/models/locale/locale.model';
import { Country, UnitedKingdom } from 'src/resources/countries/countries';
import {
    Cancellable,
    emptyCancellable,
    handleRequest,
} from 'src/utils/handle-request.utils';

export interface ProjectLookupFeature {
    ids: string[];
    leadsMap: Map<string, Lead>;
    isLoading: boolean;
    searchQuery: string;
    allItemsLoaded: boolean;
    locationFilter: Country;
    languageFilter: Language;
    loadMoreLookupSearch: () => Cancellable;
    executeLookupSearch: (query: string) => Cancellable;
    setLanguageFilter: (languageFilter: Language) => void;
    setLocationFilter: (locationFilter: Country) => void;
    clearEntities: () => void;
}

export class ProjectLookupFeatureImpl implements ProjectLookupFeature {
    searchPage = 0;
    searchResultCount = 0;
    query = '';
    locationFilter: Country = UnitedKingdom;
    languageFilter: Language = Language.De;
    allItemsLoaded = false;

    static get TotalItemsByPage() {
        return 10;
    }

    public get isLoading(): boolean {
        return this.leadsStore.isLoading;
    }

    public get ids(): string[] {
        return this.leadsStore.ids;
    }

    public get leadsMap(): Map<string, Lead> {
        return this.leadsStore.leads;
    }

    public get searchQuery(): string {
        return this.query;
    }

    public clearEntities = () => {
        this.query = '';
        this.leadsStore.clear();
    };

    private setLoadingSearchResults = (flag: boolean) => {
        this.leadsStore.setLoading(flag);
    };

    public setAllItemsLoaded = (flag: boolean) => {
        this.allItemsLoaded = flag;
    };

    private saveNewPage = (leads: Lead[]) => {
        leads.forEach((lead) => {
            this.leadsStore.set(lead);
            this.leadsStore.addId(lead.id);
        });
    };

    public setLocationFilter = (locationFilter: Country) => {
        this.locationFilter = locationFilter;
    };

    public setLanguageFilter = (languageFilter: Language) => {
        this.languageFilter = languageFilter;
    };

    constructor(
        private lookupToolApi: LookupToolApi,
        private leadsStore: LeadsStore,
        private mixpanelService: MixpanelService,
        private baseStore: IBaseStore,
    ) {
        makeAutoObservable(this);
    }

    public executeLookupSearch = (query: string): Cancellable => {
        this.clearEntities();
        this.query = query;
        this.searchResultCount = 0;
        this.searchPage = 0;
        this.setAllItemsLoaded(false);
        return this.requestSearchResults(
            this.query,
            this.locationFilter,
            this.languageFilter,
            0,
        );
    };

    public onSearchResponse = (
        searchResult: LookupToolResponse,
        data: { [key: string]: string },
    ) => {
        this.searchResultCount = searchResult.search_count;
        this.setAllItemsLoaded(
            this.searchResultCount <=
                (this.searchPage + 1) *
                    ProjectLookupFeatureImpl.TotalItemsByPage,
        );
        this.searchPage += 1;
        this.saveNewPage(searchResult.leads);
        this.mixpanelService.trackEvent(
            MixpanelEventName.SearchedProjectLookup,
            data,
        );
    };

    public requestSearchResults = (
        query: string,
        location: Country,
        language: Language,
        offset: number,
    ): Cancellable => {
        if (this.allItemsLoaded) {
            return emptyCancellable;
        }

        return handleRequest(
            this.lookupToolApi.getFilteredLookupLeads,
            {
                freetext: query,
                location: location.code.toUpperCase(),
                language,
                offset,
                limit: ProjectLookupFeatureImpl.TotalItemsByPage,
            },
            (searchResult: LookupToolResponse) =>
                this.onSearchResponse(searchResult, {
                    query,
                    projectLookupLocation: location.code,
                    projectLookupLanguage: language,
                }),
            this.setLoadingSearchResults,
            this.onSearchError,
        );
    };

    onSearchError = (error?: Error) => {
        this.baseStore.onRequestFailed('project-lookup', error);
        if (error) {
            this.setAllItemsLoaded(true);
        }
    };

    public loadMoreLookupSearch = (): Cancellable => {
        return this.requestSearchResults(
            this.query,
            this.locationFilter,
            this.languageFilter,
            this.searchPage * ProjectLookupFeatureImpl.TotalItemsByPage,
        );
    };
}

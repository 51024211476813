export const ColouredIcon = () => {
    return (
        <svg
            width="72"
            height="72"
            viewBox="0 0 72 72"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g filter="url(#filter0_ddd_806_2496)">
                <path
                    d="M17.6591 25.51C17.6591 27.7532 18.9835 29.5717 20.6173 29.5717H36.6133C34.9795 29.5717 33.6551 27.7532 33.6551 25.51C33.6551 23.2669 34.9795 21.4484 36.6133 21.4484H20.6173C18.9835 21.4484 17.6591 23.2669 17.6591 25.51Z"
                    fill="#C4E6FF"
                />
                <path
                    d="M22.6766 27.0573C22.6766 28.4459 21.8157 29.5716 20.7536 29.5716H37.0112C38.0733 29.5716 38.9342 28.4459 38.9342 27.0573C38.9342 25.6687 38.0733 24.543 37.0112 24.543H20.7536C21.8157 24.543 22.6766 25.6687 22.6766 27.0573Z"
                    fill="url(#paint0_linear_806_2496)"
                />
                <path
                    d="M23.8371 27.0573C23.8371 28.4459 23.3629 29.5716 22.3009 29.5716H37.0112C38.0733 29.5716 38.9342 28.4459 38.9342 27.0573C38.9342 25.6687 38.0733 24.543 37.0112 24.543H22.3009C23.3629 24.543 23.8371 25.6687 23.8371 27.0573Z"
                    fill="#93D1FF"
                />
                <path
                    d="M42.4156 21.4484H20.5602C22.8275 21.4484 24.6655 23.9717 24.6655 27.0844V45.9845C24.6655 49.0972 26.5035 51.6205 28.7708 51.6205H50.6262C48.3589 51.6205 46.5209 49.0972 46.5209 45.9845V27.0844C46.5209 23.9717 44.6829 21.4484 42.4156 21.4484Z"
                    fill="url(#paint1_linear_806_2496)"
                />
                <path
                    d="M28.7708 51.6205H50.6262C48.3589 51.6205 46.5209 49.0972 46.5209 45.9846L25.3233 49.0458C26.0548 50.5953 27.3256 51.6205 28.7708 51.6205Z"
                    fill="#C4E6FF"
                />
                <path
                    d="M43.2004 46.0116C43.2004 44.623 44.0613 43.4973 45.1234 43.4973H28.8657C27.8037 43.4973 26.9428 44.623 26.9428 46.0116C26.9428 47.4002 27.8037 48.5259 28.8657 48.5259H45.1234C44.0613 48.5259 43.2004 47.4002 43.2004 46.0116Z"
                    fill="#C4E6FF"
                />
                <path
                    d="M43.2004 46.0116C43.2004 44.623 44.0613 43.4973 45.1234 43.4973H28.8657C30.6176 45.2491 31.0044 46.7248 30.4241 48.5259H45.1234C44.0613 48.5259 43.2004 47.4002 43.2004 46.0116Z"
                    fill="#93D1FF"
                />
                <path
                    d="M51.5 47.5589C51.5 45.3157 50.1756 43.4972 48.5418 43.4972H28.8769C30.5106 43.4972 31.8351 45.3157 31.8351 47.5589C31.8351 49.802 30.5106 51.6205 28.8769 51.6205H48.5418C50.1756 51.6205 51.5 49.802 51.5 47.5589Z"
                    fill="url(#paint2_linear_806_2496)"
                />
                <rect
                    x="27.7164"
                    y="28.0244"
                    width="7.73642"
                    height="1.54728"
                    rx="0.773642"
                    fill="#83CAFE"
                />
                <rect
                    x="27.7164"
                    y="33.4399"
                    width="16.6333"
                    height="1.54728"
                    rx="0.773642"
                    fill="#83CAFE"
                />
                <rect
                    x="27.7164"
                    y="39.1818"
                    width="11.2178"
                    height="1.54728"
                    rx="0.773642"
                    fill="#83CAFE"
                />
                <path
                    d="M53.7521 14.2158C51.2695 12.7825 48.0951 13.633 46.6618 16.1156L43.2172 22.0818C43.1095 22.2684 43.0736 22.4879 43.1162 22.699L43.4728 24.4633C43.6104 25.1444 42.8801 25.6694 42.2783 25.322C41.8923 25.0991 41.3988 25.2314 41.176 25.6173L39.7412 28.1025C38.3078 30.585 39.1584 33.7595 41.641 35.1928C44.1236 36.6261 47.298 35.7755 48.7313 33.2929L53.6862 24.7108C53.876 24.382 53.7634 23.9616 53.4346 23.7718C52.9288 23.4798 52.9947 22.7299 53.5437 22.5305L55.0139 21.9966C55.2095 21.9256 55.3738 21.7878 55.4778 21.6076L55.6519 21.3061C57.0852 18.8235 56.2346 15.6491 53.7521 14.2158Z"
                    fill="url(#paint3_linear_806_2496)"
                />
                <path
                    d="M38.5439 40.8158C38.1301 40.5769 37.9884 40.0478 38.2273 39.6341L49.4733 20.1554C49.7122 19.7417 50.2412 19.5999 50.655 19.8388C51.0687 20.0777 51.2105 20.6067 50.9716 21.0205L39.7256 40.4992C39.4867 40.9129 38.9577 41.0547 38.5439 40.8158Z"
                    fill="#F0832A"
                />
            </g>
            <defs>
                <filter
                    id="filter0_ddd_806_2496"
                    x="7.04922"
                    y="3.79385"
                    width="59.9088"
                    height="59.3207"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="0.884153" />
                    <feGaussianBlur stdDeviation="5.30492" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_806_2496"
                    />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="3.53661" />
                    <feGaussianBlur stdDeviation="3.53661" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.02 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="effect1_dropShadow_806_2496"
                        result="effect2_dropShadow_806_2496"
                    />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="0.884153" />
                    <feGaussianBlur stdDeviation="1.76831" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.01 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="effect2_dropShadow_806_2496"
                        result="effect3_dropShadow_806_2496"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect3_dropShadow_806_2496"
                        result="shape"
                    />
                </filter>
                <linearGradient
                    id="paint0_linear_806_2496"
                    x1="29.7651"
                    y1="24.5437"
                    x2="29.8559"
                    y2="29.3155"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="#BDE3FF" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_806_2496"
                    x1="35.4528"
                    y1="24.2891"
                    x2="27.3296"
                    y2="50.3996"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="#BDE3FF" />
                </linearGradient>
                <linearGradient
                    id="paint2_linear_806_2496"
                    x1="38.4361"
                    y1="44.7907"
                    x2="38.4361"
                    y2="51.56"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="#BDE3FF" />
                </linearGradient>
                <linearGradient
                    id="paint3_linear_806_2496"
                    x1="51.2569"
                    y1="22.6725"
                    x2="41.564"
                    y2="53.471"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F4CE57" />
                    <stop offset="1" stopColor="#F08229" />
                </linearGradient>
            </defs>
        </svg>
    );
};

export enum Spacing {
    // number means multiplier of 8
    s0_5 = '4px',
    s1 = '8px',
    s1_5 = '12px',
    s2 = '16px',
    s2_5 = '20px',
    s3 = '24px',
    s4 = '32px',
    s5 = '40px',
    s6 = '48px',
    s7 = '56px',
    s8 = '64px',
    s10 = '80px',
    s12 = '96px',
    s13 = '100px',
}

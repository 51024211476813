import { UIEvent } from 'react';

/**
 * just a simple function which are created only once and can be used as a callback to react elements
 * @param e - any ui event
 */
export const stopPropagation = <T extends HTMLElement>(event: UIEvent<T>) => {
    event.stopPropagation();
};

/**
 * just a simple function which are created only once and can be used as a callback to react elements
 * @param e - any ui event
 */
export const preventDefault = <T extends HTMLElement>(event: UIEvent<T>) => {
    event.preventDefault();
};

/**
 * just a simple function which are created only once and can be used as a callback to react elements
 * @param e - any ui event
 */
export const runWithStopPropagation =
    (fn: () => void) =>
    <T extends HTMLElement>(event: UIEvent<T>) => {
        event.stopPropagation();
        fn();
    };

import { Location as Loc, Path } from 'history';
import { createBrowserHistory } from 'history';

import { isLocalDevelopment } from './utils/is-local-development.utils';

export const history = createBrowserHistory();

/**
 * Only request version after 60 seconds (default) of the previous request
 * Within 60 seconds, use previous version check (true)
 */
const VERSION_CHECK_INTERVAL = +(
    import.meta.env.VITE_VERSION_CHECK_INTERVAL ?? 60 * 1000
);
let versionMatch: boolean | undefined = undefined;
const isVersionMatch = async () => {
    if (versionMatch === undefined) {
        versionMatch = await fetch('/static/version.json', {
            cache: 'no-store',
        })
            .then((response) => response.json())
            .then((data) => {
                const buildVersion = data.buildVersion;
                return buildVersion === import.meta.env.VITE_BUILD_VERSION;
            })
            .catch(() => true);

        setTimeout(() => {
            versionMatch = undefined;
        }, VERSION_CHECK_INTERVAL);
    }
    return versionMatch;
};

if (!isLocalDevelopment()) {
    /**
     * Override push and replace functions to allow/block url changes, and hard refresh if necessary
     * we do not use history.block because it does not allow async calls
     * @param path
     * @param state
     */
    const getOverride =
        (method: (path: Path | Loc<unknown>, state?: unknown) => void) =>
        async (path: Path | Loc<unknown>, state?: unknown) => {
            if (await isVersionMatch()) {
                method(path, state);
            } else {
                if (typeof path === 'object') {
                    window.location.href =
                        path.pathname + path.search + path.hash;
                } else {
                    window.location.href = path;
                }
            }
        };

    history.push = getOverride(history.push);
    history.replace = getOverride(history.replace);
}

import { encode } from 'js-base64';

import { DealContactType } from 'src/app-features/contact/data/model/deal-contacts.model';

const GOOGLE_SEARCH_URL = 'https://www.google.com/search?q=%query';
const LINKEDIN_SEARCH_URL =
    'https://www.linkedin.com/search/results/all/?keywords=%query';
const LINKEDIN_FIND_PEOPLE_URL =
    'https://www.linkedin.com/search/results/people/?keywords=%query';
const SALES_FORCE_LOOKUP_URL =
    'https://<subdomain>.lightning.force.com/one/one.app#<query>';
const XING_COMPANY_LOOKUP_URL =
    'https://www.xing.com/search/companies?keywords=%query';
const XING_CONTACT_LOOKUP_URL =
    'https://www.xing.com/search/members?keywords=%query';
const HUBSPOT_LOOKUP_URL =
    'https://app.hubspot.com/contacts/%accountId?globalSearchQuery=%query';
const MS_DYNAMICS_LOOKUP_URL =
    '%domain/main.aspx?appid=%app_id&pagetype=search&searchText=%query&searchType=0';
const SALES_FORCE_CLASSIC_LOOKUP_URL =
    'https://<subdomain>.my.salesforce.com/_ui/search/ui/UnifiedSearchResults?str=<query>';
export const createGoogleQueryLink = (query: string) =>
    GOOGLE_SEARCH_URL.replace('%query', encodeURIComponent(query));

export const createLinkedinQueryLink = (query: string, findPeople: boolean) => {
    return (
        findPeople ? LINKEDIN_FIND_PEOPLE_URL : LINKEDIN_SEARCH_URL
    ).replace('%query', encodeURIComponent(query));
};

export const createHubspotQueryLink = (accountId: string, query: string) =>
    HUBSPOT_LOOKUP_URL.replace('%query', encodeURIComponent(query)).replace(
        '%accountId',
        accountId,
    );

export const createMsDynamicsQueryLink = (
    domain: string,
    appId: string,
    query: string,
) =>
    MS_DYNAMICS_LOOKUP_URL.replace('%domain', domain)
        .replace('%app_id', appId)
        .replace('%query', encodeURIComponent(query));

export const createSalesForceClassicLookupQueryLink = (
    subdomain: string,
    query: string,
) =>
    SALES_FORCE_CLASSIC_LOOKUP_URL.replace('<subdomain>', subdomain).replace(
        '<query>',
        encodeURIComponent(query),
    );

export const createSalesForceLookupQueryLink = (
    subdomain: string,
    lookupEntity: string,
) => {
    const queryObj = {
        componentDef: 'forceSearch:search',
        attributes: {
            term: lookupEntity,
            scopeMap: {
                type: 'TOP_RESULTS',
            },
            context: {
                disableSpellCorrection: false,
                SEARCH_ACTIVITY: {
                    term: lookupEntity,
                },
            },
        },
    };

    return SALES_FORCE_LOOKUP_URL.replace('<subdomain>', subdomain).replace(
        '<query>',
        encode(JSON.stringify(queryObj)),
    );
};

export const createXingLookupQueryLink = (
    query: string,
    type: DealContactType,
) => {
    const url =
        type === DealContactType.company
            ? XING_COMPANY_LOOKUP_URL
            : XING_CONTACT_LOOKUP_URL;
    return url.replace('%query', encodeURIComponent(query));
};

import styled from '@emotion/styled';

export const BackButtonStyled = styled.button`
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.4px;
    height: 64px;

    color: var(--main-font);
    padding: 0 20px;

    :hover {
        * {
            color: var(--accent-action-button);
        }
    }
`;

export const BackButtonTextStyled = styled.div`
    margin-left: 8px;
`;

export const MoreWrapperStyled = styled.div`
    display: flex;
    flex-direction: column;
`;

import { observer } from 'mobx-react';
import { ReactNode, FC } from 'react';

import {
    MobileMenuItemStyled,
    MobileMenuIconStyled,
    MobileMenuTextStyled,
} from 'src/presentation/modules/mobile-menu/components/mobile-menu-item/mobile-menu-item.styled';
import { Localized } from 'src/presentation/shared/localized/localized';

interface ItemContentProps {
    onClick?: () => void;
    className?: string;
    isActive: boolean;
    text: string;
    Icon: ReactNode;
}

export const ItemContent: FC<ItemContentProps> = observer(
    ({ onClick, className, isActive, text, Icon }) => (
        <MobileMenuItemStyled
            className={className}
            onClick={onClick}
            as={onClick ? 'button' : 'div'}
            isActive={isActive}
        >
            <MobileMenuIconStyled>{Icon}</MobileMenuIconStyled>
            <MobileMenuTextStyled isActive={isActive}>
                <Localized>{text}</Localized>
            </MobileMenuTextStyled>
        </MobileMenuItemStyled>
    ),
);

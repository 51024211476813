import { AbortParams } from 'src/data/api/api-client';
import { SearchGqlSdk } from 'src/data/api/graphql/graphql-client.wrapper';
import { DocumentType } from 'src/domain/models/document/document.model';
import { Lead } from 'src/domain/models/lead/lead.model';

interface GetProcurementTextsParams extends AbortParams {
    cpvCodes: string[];
    naicsCodes: string[];
    unspscCodes: string[];
}

interface GetProcurementTextsForManyLeadsParams extends AbortParams {
    leads: Lead[];
}
export interface LeadFieldsApi {
    getProcurementTexts: (
        params: GetProcurementTextsParams,
    ) => Promise<string[]>;
    getProcurementTextsForManyLeads: (
        params: GetProcurementTextsForManyLeadsParams,
    ) => Promise<Record<string, string[]>>;
}

export const createLeadFieldsApi = (
    searchGqlSkd: SearchGqlSdk,
): LeadFieldsApi => {
    const getProcurementTexts = async (params: GetProcurementTextsParams) => {
        const response = await searchGqlSkd.GetTenderTextFromCodes(params);
        return response.tenderTextFromCodes;
    };

    const getProcurementTextsForManyLeads = async (
        params: GetProcurementTextsForManyLeadsParams,
    ) => {
        const { leads, signal } = params;

        const emptyTenderCodeRequest = (lead: Lead) =>
            Promise.resolve({ [lead.id]: [] });

        const tenderCodeRequestsForAllLeads = leads.map(async (lead) => {
            if (lead.mergedDocument.type === DocumentType.Tender) {
                const cpvCodes = lead.mergedDocument.cpvCodes ?? [];
                const naicsCodes = lead.mergedDocument.naicsCodes ?? [];
                const unspscCodes = lead.mergedDocument.unspscCodes ?? [];

                if (
                    !cpvCodes?.length &&
                    !naicsCodes?.length &&
                    !unspscCodes?.length
                ) {
                    return emptyTenderCodeRequest(lead);
                }

                const values = await getProcurementTexts({
                    signal,
                    cpvCodes,
                    naicsCodes,
                    unspscCodes,
                });
                return { [lead.id]: values };
            }

            return emptyTenderCodeRequest(lead);
        });

        const procurementTexts = await Promise.all(
            tenderCodeRequestsForAllLeads,
        );

        return procurementTexts.reduce((map, textData) => {
            return { ...map, ...textData };
        }, {});
    };
    return {
        getProcurementTexts,
        getProcurementTextsForManyLeads,
    };
};

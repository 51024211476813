import {
    BrTextInputStatus,
    getEndAdornment,
    useShownStatus,
} from '@buildingradar/br_component_lib';
import { observer } from 'mobx-react';
import { FC, useCallback, useMemo, useState } from 'react';
import { Controller, Path, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { DealContactUnionForm } from 'src/app-features/contact/data/model/deal-contacts-form.model';
import { notesConfig } from 'src/app-features/contact/presentation/contact-panel/form-utils/form-config';
import { CUSTOM_PARAMETER_SAVE_DEBOUNCE } from 'src/domain/features/custom-parameters/custom-parameters.utils';
import { TextInput } from 'src/presentation/shared/text-input/text-input';
import { textToHTML } from 'src/presentation/shared/text-preview/utils';
import { useDebounceCallback } from 'src/utils/hooks/use-debounce';

import { useAnimationStatus } from './hooks';
import {
    ContactNotesPreviewStyled,
    DealContactNoteFormStyled,
    DealContactNoteStatusStyled,
    NotesFieldWrapperStyled,
} from './styles';

interface DealContactNoteFormComponentProps {
    selectedContactId?: string;
    status?: BrTextInputStatus;
    onSave?: (field: string, value: string) => void;
    setClickedLink: (link: string) => void;
}
const NOTES_PARAMETER_FIELD_MAX_CHAR = 5000;
const NOTES_ROW_COUNT = 17;
export const DealContactNoteFormComponent: FC<DealContactNoteFormComponentProps> =
    observer(({ status = 'idle', onSave, setClickedLink }) => {
        const { t } = useTranslation();
        const { control } = useFormContext<DealContactUnionForm>();

        const animationStatus = useAnimationStatus(status);
        const shownStatus = useShownStatus(animationStatus);
        const endAdornment = getEndAdornment(
            null,
            animationStatus,
            shownStatus,
        );

        return (
            <DealContactNoteFormStyled>
                <Controller<DealContactUnionForm>
                    name={notesConfig.name as Path<DealContactUnionForm>}
                    rules={{
                        required: notesConfig.required,
                        validate: (val) =>
                            notesConfig.validate?.(val as string),
                    }}
                    control={control}
                    render={({ field }) => {
                        const [isPreviewMode, setIsPreviewMode] =
                            useState<boolean>(false);

                        const blurEventHandler = useCallback(() => {
                            field.onBlur();
                            setIsPreviewMode(true);
                        }, [field]);

                        const textHTML = useMemo(
                            () =>
                                isPreviewMode && field.value
                                    ? textToHTML(
                                          field.value.toString(),
                                          setClickedLink,
                                      )
                                    : null,
                            [field.value, isPreviewMode],
                        );

                        const saveDebounced = useDebounceCallback(
                            onSave,
                            CUSTOM_PARAMETER_SAVE_DEBOUNCE,
                            [],
                        );
                        const onChange = useCallback(
                            (value: string) => {
                                field.onChange(value);
                                saveDebounced(field.name, value);
                            },
                            [field, saveDebounced],
                        );

                        return (
                            <NotesFieldWrapperStyled
                                className={notesConfig.className}
                            >
                                {!isPreviewMode ? (
                                    <TextInput
                                        placeholder={t(notesConfig.placeholder)}
                                        value={field.value?.toString()}
                                        multiline
                                        onChange={onChange}
                                        onBlur={blurEventHandler}
                                        blurOnEnter
                                        focusInput
                                        maxLength={
                                            NOTES_PARAMETER_FIELD_MAX_CHAR
                                        }
                                        className="value"
                                        minRows={NOTES_ROW_COUNT}
                                        maxRows={NOTES_ROW_COUNT}
                                        hasPermanentBorder
                                    />
                                ) : (
                                    <ContactNotesPreviewStyled
                                        onClick={() => setIsPreviewMode(false)}
                                        status={animationStatus}
                                    >
                                        {(
                                            (field.value?.toString() ??
                                                '') as string
                                        ).length ? (
                                            textHTML
                                        ) : (
                                            <div
                                                style={{
                                                    color: 'var(--gray-50)',
                                                }}
                                            >
                                                {t(notesConfig.placeholder)}
                                            </div>
                                        )}
                                    </ContactNotesPreviewStyled>
                                )}
                            </NotesFieldWrapperStyled>
                        );
                    }}
                />
                <DealContactNoteStatusStyled>
                    {endAdornment}
                </DealContactNoteStatusStyled>
            </DealContactNoteFormStyled>
        );
    });

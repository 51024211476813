import {
    OutboundEmailQl,
    UserLanguage,
} from 'src/data/api/graphql/br_process/generated/graphql-sdk';
import { Language } from 'src/domain/models/locale/locale.model';

export interface DealExport {
    header: string;
    row: string;
}

export interface OutboundEmail {
    enqueuedDate?: Date;
    id: string;
    mtaMessageId?: string;
    senderBid: number;
    sentDate?: Date;
}

export const languageModelToRaw: Record<Language, UserLanguage> = {
    [Language.De]: UserLanguage.De,
    [Language.En]: UserLanguage.En,
};

export const emailRawToModel = (
    outboundEmail: OutboundEmailQl,
): OutboundEmail => {
    const { id, enqueuedDate, senderBid, sentDate, mtaMessageId } =
        outboundEmail;
    return {
        id,
        enqueuedDate,
        senderBid,
        sentDate,
        mtaMessageId: mtaMessageId ?? undefined,
    };
};

import { BrIcon, BrInfotip, IconName } from '@buildingradar/br_component_lib';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { observer } from 'mobx-react';
import { FC, RefObject, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { generatePipeFunctions } from 'src/domain/features/actions/quick-actions/email-templates/pipe-functions/pipe-function-generator';
import { PanelLabelStyled } from 'src/presentation/modules/enablement-actions-panel/actions/email-template/styled';
import { TokenFieldComponent } from 'src/presentation/shared/token-field/token-field.component';
import { Spacing } from 'src/resources/styles/spacing';

interface SubjectBodyFormProps {
    /**
     * Initial value provided to the subject token input field.
     * This is the text which may contain tokens and will be parsed by the TokenField component
     */
    initialSubjectValue?: string;
    /**
     * Initial value provided to the body token input field.
     * This is the text which may contain tokens and will be parsed by the TokenField component
     */
    initialBodyValue?: string;
    /**
     * A ref to be bound to the subject input. We need this to get the subject content,
     * since the TokenField component is used in a uncontrolled way
     */
    subjectFieldRef?: RefObject<HTMLDivElement>;
    /**
     * A ref to be bound to the body input. We need this to get the body content,
     * since the TokenField component is used in a uncontrolled way
     */
    bodyFieldRef?: RefObject<HTMLDivElement>;
    /**
     * Prop to control the component's top margin
     */
    applyTopMargin?: boolean;
    /**
     * Prop to control the number of rows the body input will have. Defaulted to 6
     */
    bodyNumberOfRows?: number;
    /**
     * Data context used by the subject/body TokenFields to parse the tokens
     * from `initialSubjectValue` and `initialBodyValue`
     */
    dataContext?: Record<string, any>;
    /**
     * Call back for the toggle expand function
     */
    onToggleExpandedMode?: (isExpanded: boolean) => () => void;
    registerBodyToken?: (tokenId: string, token: string) => void;
    registerSubjectToken?: (tokenId: string, token: string) => void;
}

export const SubjectBodyForm: FC<SubjectBodyFormProps> = observer(
    ({
        initialSubjectValue,
        initialBodyValue,
        subjectFieldRef,
        bodyFieldRef,
        applyTopMargin = true,
        bodyNumberOfRows = 6,
        dataContext = {},
        onToggleExpandedMode,
        registerBodyToken,
        registerSubjectToken,
    }) => {
        const { t } = useTranslation();

        const templatePipeFunctions = useMemo(
            () =>
                dataContext?.contact
                    ? generatePipeFunctions(dataContext.contact)
                    : undefined,
            [dataContext?.contact],
        );

        return (
            <>
                <PanelLabelStyled applyTopMargin={applyTopMargin}>
                    {t('actions.email_templates.subject_line_input_label')}
                </PanelLabelStyled>
                <TokenFieldComponent
                    id="email-subject-input"
                    text={initialSubjectValue ?? ''}
                    data={dataContext}
                    contentRef={subjectFieldRef}
                    pipes={templatePipeFunctions}
                    registerToken={registerSubjectToken}
                />

                <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    marginTop={Spacing.s3}
                >
                    <PanelLabelStyled>
                        {t('actions.email_templates.email_body_input_label')}
                    </PanelLabelStyled>
                    {onToggleExpandedMode && (
                        <BrInfotip content={t('common.expand')} placement="top">
                            <IconButton onClick={onToggleExpandedMode(true)}>
                                <BrIcon iconName={IconName.BrExpandIcon} />
                            </IconButton>
                        </BrInfotip>
                    )}
                </Box>
                <TokenFieldComponent
                    id="email-body-input"
                    text={initialBodyValue ?? ''}
                    data={dataContext}
                    rows={bodyNumberOfRows}
                    contentRef={bodyFieldRef}
                    pipes={templatePipeFunctions}
                    registerToken={registerBodyToken}
                />
            </>
        );
    },
);

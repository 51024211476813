export const GoldIcon = () => {
    return (
        <svg
            width="72"
            height="72"
            viewBox="0 0 72 72"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_806_2505)">
                <g filter="url(#filter0_ddd_806_2505)">
                    <rect
                        width="4.02543"
                        height="31.8009"
                        transform="matrix(-0.707107 0.707107 0.707107 0.707107 33.822 32.1811)"
                        fill="url(#paint0_linear_806_2505)"
                    />
                    <rect
                        width="5.6356"
                        height="7.24577"
                        rx="0.805085"
                        transform="matrix(-0.707107 0.707107 0.707107 0.707107 32.968 30.1886)"
                        fill="#F0DD04"
                    />
                    <path
                        d="M28.4138 33.6043L32.3987 29.6193L35.5298 32.7504L32.3987 37.5893L28.4138 33.6043Z"
                        fill="#DFCD07"
                    />
                    <path
                        d="M29.271 23.645C25.4344 19.8084 21.3276 17.1198 16.2119 16.5946C15.7366 16.5458 15.3404 16.942 15.3892 17.4173C15.9144 22.5329 18.603 26.6398 22.4396 30.4763C22.7394 30.7762 23.1881 30.8497 23.5995 30.7469C24.3709 30.5541 25.0459 30.6429 25.4645 31.0615C25.813 31.41 25.9329 31.9363 25.8519 32.548C25.7658 33.1993 25.8576 33.8944 26.3222 34.3589L27.8445 35.8812C28.4733 36.5101 29.4928 36.5101 30.1216 35.8812L34.6759 31.327C35.3047 30.6982 35.3047 29.6787 34.6759 29.0499L33.1535 27.5275C32.689 27.063 31.9939 26.9711 31.3426 27.0573C30.7309 27.1383 30.2046 27.0184 29.8561 26.6699C29.4375 26.2513 29.3487 25.5763 29.5415 24.8049C29.6443 24.3935 29.5708 23.9448 29.271 23.645Z"
                        fill="url(#paint1_linear_806_2505)"
                    />
                    <path
                        d="M15.6053 16.9861C15.2027 17.1872 15.3184 18.0893 15.4656 18.8211C16.3595 23.2628 18.9329 26.9696 22.4396 30.4763C22.7403 30.777 23.189 30.8529 23.6041 30.7608C24.3447 30.5966 24.9973 30.7003 25.4115 31.1145C25.7566 31.4596 25.8862 31.9704 25.8247 32.5596C25.7575 33.2039 25.8513 33.888 26.3093 34.346L27.8445 35.8812C28.4733 36.5101 29.4928 36.5101 30.1217 35.8812L32.2503 33.7526L15.6053 16.9861Z"
                        fill="#FEE37A"
                    />
                    <rect
                        x="39.4866"
                        y="32"
                        width="4.02543"
                        height="31.8009"
                        transform="rotate(45 39.4866 32)"
                        fill="url(#paint2_linear_806_2505)"
                    />
                    <rect
                        x="40.5095"
                        y="30.1884"
                        width="5.6356"
                        height="7.24577"
                        rx="0.805085"
                        transform="rotate(45 40.5095 30.1884)"
                        fill="#FDF05D"
                    />
                    <path
                        d="M45.0637 33.6041L41.0788 29.6191L37.9477 32.7501L41.0788 37.589L45.0637 33.6041Z"
                        fill="#F8E50F"
                    />
                    <path
                        d="M44.2066 23.6448C48.0432 19.8082 52.15 17.1196 57.2656 16.5944C57.7409 16.5456 58.1371 16.9418 58.0883 17.4171C57.5631 22.5328 54.8745 26.6396 51.0379 30.4762C50.7381 30.776 50.2894 30.8495 49.8781 30.7467C49.1066 30.5539 48.4317 30.6427 48.0131 31.0613C47.6645 31.4098 47.5447 31.9361 47.6256 32.5478C47.7118 33.1991 47.6199 33.8942 47.1554 34.3587L45.633 35.8811C45.0042 36.5099 43.9847 36.5099 43.3559 35.8811L38.8017 31.3268C38.1729 30.698 38.1729 29.6785 38.8017 29.0497L40.324 27.5274C40.7885 27.0628 41.4837 26.971 42.1349 27.0571C42.7466 27.1381 43.2729 27.0182 43.6215 26.6697C44.0401 26.2511 44.1288 25.5761 43.936 24.8047C43.8332 24.3933 43.9068 23.9446 44.2066 23.6448Z"
                        fill="url(#paint3_linear_806_2505)"
                    />
                    <path
                        d="M57.3498 17.3513C57.5688 17.1285 57.9385 17.0549 58.0454 17.3485C58.1839 17.7291 58.113 18.3182 58.0119 18.8209C57.118 23.2626 54.5446 26.9694 51.0379 30.4762C50.7372 30.7768 50.2885 30.8527 49.8734 30.7607C49.1328 30.5964 48.4802 30.7002 48.066 31.1143C47.7209 31.4594 47.5913 31.9702 47.6528 32.5595C47.72 33.2037 47.6262 33.8878 47.1682 34.3458L45.633 35.8811C45.0042 36.5099 43.9847 36.5099 43.3558 35.8811L41.2272 33.7524L57.3498 17.3513Z"
                        fill="#FEE37A"
                    />
                </g>
            </g>
            <defs>
                <filter
                    id="filter0_ddd_806_2505"
                    x="1.29837"
                    y="3.72877"
                    width="70.855"
                    height="68.9854"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="1.16923" />
                    <feGaussianBlur stdDeviation="7.0154" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_806_2505"
                    />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="4.67693" />
                    <feGaussianBlur stdDeviation="4.67693" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.02 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="effect1_dropShadow_806_2505"
                        result="effect2_dropShadow_806_2505"
                    />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="1.16923" />
                    <feGaussianBlur stdDeviation="2.33847" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.01 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="effect2_dropShadow_806_2505"
                        result="effect3_dropShadow_806_2505"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect3_dropShadow_806_2505"
                        result="shape"
                    />
                </filter>
                <linearGradient
                    id="paint0_linear_806_2505"
                    x1="1.48098"
                    y1="13.4044"
                    x2="7.71902"
                    y2="15.0989"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FEE07A" />
                    <stop offset="1" stopColor="#FBE8A3" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_806_2505"
                    x1="22.6558"
                    y1="24.9206"
                    x2="28.5231"
                    y2="37.5109"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FFF6D8" />
                    <stop offset="1" stopColor="#FBE8A7" />
                </linearGradient>
                <linearGradient
                    id="paint2_linear_806_2505"
                    x1="40.9676"
                    y1="45.4044"
                    x2="47.2057"
                    y2="47.0989"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FFF6D8" />
                    <stop offset="1" stopColor="#FBE8A7" />
                </linearGradient>
                <linearGradient
                    id="paint3_linear_806_2505"
                    x1="50.8217"
                    y1="24.9204"
                    x2="44.9545"
                    y2="37.5107"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FFF6D8" />
                    <stop offset="1" stopColor="#FBE8A7" />
                </linearGradient>
                <clipPath id="clip0_806_2505">
                    <rect width="72" height="72" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

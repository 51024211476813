import { IconName } from '@buildingradar/br_component_lib';
import {
    DealActivity,
    OutreachActivityResult,
    OutreachActivityType,
} from './deal-activity.model';

export enum OutreachActivityLaunchSource {
    contactCard = 'Contact Card',
    contactCardCallAction = 'Contact Card: Prepare call',
    contactCardEmailAction = 'Contact Card: Prepare mail',
    contactCardLogAction = 'Contact Card: Log outreach',
}

export enum OutreachActivityLogSource {
    outreachLogModal = 'Log Outreach Modal',
    outreachModalCallTab = 'Outreach Modal: Prepare call tab',
    outreachModalEmailTab = 'Outreach Modal: Prepare mail tab',
    outreachModalLogTab = 'Outreach Modal: Log outreach tab',
}

export const OutreachActivityTypeToLogSource: Record<
    OutreachActivityType,
    OutreachActivityLogSource
> = {
    [OutreachActivityType.phoneCall]:
        OutreachActivityLogSource.outreachModalCallTab,
    [OutreachActivityType.email]:
        OutreachActivityLogSource.outreachModalEmailTab,
    [OutreachActivityType.OTHER]: OutreachActivityLogSource.outreachModalLogTab,
};

export const OutreachActivityTypeToMetricsTabName: Record<
    OutreachActivityType,
    string
> = {
    [OutreachActivityType.phoneCall]: 'Prepare call tab',
    [OutreachActivityType.email]: 'Prepare email tab',
    [OutreachActivityType.OTHER]: 'Log outreach tab',
};

export enum OutreachModeMetricsType {
    FIRST_MAIL = 'FIRST_MAIL',
    FOLLOW_UP_MAIL = 'FOLLOW_UP_MAIL',
    PHONE_CALL = OutreachActivityType.phoneCall,
    OTHER = OutreachActivityType.OTHER,
}

export const OutreachLogDefaultResults: OutreachActivityResult[] = [
    OutreachActivityResult.interested,
    OutreachActivityResult.referral,
    OutreachActivityResult.futureInterest,
    OutreachActivityResult.notInterested,
    OutreachActivityResult.didNotConnect,
    OutreachActivityResult.other,
];

export const OutreachResultTypeToColor: Record<
    OutreachActivityResult,
    'green' | 'red' | 'grey'
> = {
    [OutreachActivityResult.interested]: 'green',
    [OutreachActivityResult.referral]: 'green',
    [OutreachActivityResult.futureInterest]: 'green',
    [OutreachActivityResult.notInterested]: 'red',
    [OutreachActivityResult.didNotConnect]: 'grey',
    [OutreachActivityResult.other]: 'grey',
    [OutreachActivityResult.emailSent]: 'green',
};

export const OutreachActivityTypeToColor: Record<
    OutreachActivityType,
    'green' | 'red' | 'pink'
> = {
    [OutreachActivityType.phoneCall]: 'green',
    [OutreachActivityType.email]: 'pink',
    [OutreachActivityType.OTHER]: 'red',
};

export const OutreachActivityTypeToName: Record<
    OutreachActivityType,
    'call' | 'email' | 'log_update'
> = {
    [OutreachActivityType.phoneCall]: 'call',
    [OutreachActivityType.email]: 'email',
    [OutreachActivityType.OTHER]: 'log_update',
};

export const OutreachActivityTypeToIcon: Record<
    OutreachActivityType,
    IconName
> = {
    [OutreachActivityType.phoneCall]: IconName.BrCallForwardIcon,
    [OutreachActivityType.email]: IconName.BrSendEmailIcon,
    [OutreachActivityType.OTHER]: IconName.BrDescriptionOutlinedIcon,
};

export const filterDealActivitiesByContactId = (
    activities: DealActivity[],
    contactId?: string,
) => activities.filter((a) => a.contactId === contactId);
export const filterDealActivitiesByCompanyId = (
    activities: DealActivity[],
    contactId?: string,
) => activities.filter((a) => a.relevantCompanyId
 === contactId);

export const hasAtLeastOneActivityOfType = (
    activities: DealActivity[],
    type: OutreachActivityType,
) => activities.filter((activity) => activity.subType === type).length > 0;

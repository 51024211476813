import { GridColumnHeaderParams } from '@mui/x-data-grid';

import {
    DealAnnualSalesPeriodFragment,
    DealQuarterlySalesPeriodFragment,
    FilterKeys,
    FilterOperators as RawFilterOperators,
    SortingKeys,
    SortingOperators,
    SortingOpQl,
} from 'src/data/api/graphql/br_process/generated/graphql-sdk';
import { DealsStore } from 'src/data/stores/deals/deals.store';
import { MinimalDeal } from 'src/domain/models/deal/deal.model';
import { sortNewArray } from 'src/utils/array.utils';

export interface FilterModel {
    field: string;
    value: string | string[];
    filterOperator?: string;
}

export type FilterFn = (
    field: string,
    value: string | string[],
    filterOperator?: string,
) => void;
export type ArrayFilterFn = (filters: FilterModel[]) => void;

export type SortOrderType = 'asc' | 'desc' | undefined | null;
export type SortFn = (field: string, order: SortOrderType) => void;

export type FilterableCustomColumnMap =
    | {
          [key: string]: {
              filterable: boolean;
              customArrayFilterFn?: (
                  params: GridColumnHeaderParams,
                  arrayFilterHandler: ArrayFilterFn,
                  ...extraArgs: any[]
              ) => JSX.Element;
              customSingleFilterFn?: (
                  params: GridColumnHeaderParams,
                  singleFilterHandler: FilterFn,
                  ...extraArgs: any[]
              ) => JSX.Element;
          };
      }
    | undefined;

export interface SortModel {
    field: string;
    order: SortOrderType;
}

export enum DealField {
    dealTitle = 'dealTitle',
    dealStage = 'dealStage',
    owner = 'owner',
    pipeline = 'pipeline',
    state = 'state',
    dealName = 'dealName',
    value = 'value',
    createdDate = 'createdDate',
    completedDate = 'completedDate',
    dateCsvExported = 'dateCsvExported',
    expectedSalesPeriod = 'expectedSalesPeriod',
}
type SortOrderToOrdersType = {
    [key in string]: SortOrder;
};

type FilterOrderToOrdersType = {
    [key in string]: string;
};

export enum SortOrder {
    asc = 'asc',
    desc = 'desc',
}

export const SortOrderToOrderType: SortOrderToOrdersType = {
    SOP_ASC: SortOrder.asc,
    SOP_DESC: SortOrder.desc,
};

export const FilterOrderToOrderType: FilterOrderToOrdersType = {
    FOP_IN: 'contains',
    FOP_REGEX: 'regex',
    FOP_EQ: 'equal',
};

type GridFilterKeysToApiKeys = Record<string, FilterKeys>;
type ApiKeysToGridFilterKeys = Partial<Record<FilterKeys, string>>;
type GridFilterOperatorsKeys = Record<string, RawFilterOperators>;
type GridSortKeysToApiKeys = Record<string, SortingKeys>;
type ApiKeysToGridSortKeys = Partial<Record<SortingKeys, string>>;
type GridSortOrderKeysToApiOrderKeys = Record<string, SortingOperators>;
export const sortOrder: GridSortOrderKeysToApiOrderKeys = {
    asc: SortingOperators.SopAsc,
    desc: SortingOperators.SopDesc,
};
export const sortFields: GridSortKeysToApiKeys = {
    dealName: SortingKeys.SkDealTitle,
    value: SortingKeys.SkDealValueCurrencyNaive,
    createdDate: SortingKeys.SkDealCreatedDate,
    completedDate: SortingKeys.SkDealCompletedDate,
    dealStage: SortingKeys.SkDealCurrentStageId,
    [DealField.dateCsvExported]: SortingKeys.SkDealCsvExportedDate,
    [DealField.expectedSalesPeriod]: SortingKeys.SkDealExpectedSalesPeriod,
};
export const apiSortingKeysToFields: ApiKeysToGridSortKeys = {
    [SortingKeys.SkDealTitle]: DealField.dealName,
    [SortingKeys.SkDealCurrentStageId]: DealField.dealStage,
    [SortingKeys.SkDealValueCurrencyNaive]: DealField.value,
    [SortingKeys.SkDealCreatedDate]: DealField.createdDate,
    [SortingKeys.SkDealCompletedDate]: DealField.completedDate,
    [SortingKeys.SkDealCsvExportedDate]: DealField.dateCsvExported,
    [SortingKeys.SkDealExpectedSalesPeriod]: DealField.expectedSalesPeriod,
};
export const filterFields: GridFilterKeysToApiKeys = {
    dealTitle: FilterKeys.FkDealTitle,
    dealStage: FilterKeys.FkDealCurrentStageId,
    owner: FilterKeys.FkDealAssigneeBid,
    pipeline: FilterKeys.FkDealPipelineId,
    state: FilterKeys.FkDealState,
    dealState: FilterKeys.FkDealCurrentStageId,
    [DealField.completedDate]: FilterKeys.FkDealCompletedDate,
    [DealField.dateCsvExported]: FilterKeys.FkDealCsvExportedDate,
};
export const apiFilterKeysToFields: ApiKeysToGridFilterKeys = {
    [FilterKeys.FkDealTitle]: 'dealTitle',
    [FilterKeys.FkDealCurrentStageId]: 'dealStage',
    [FilterKeys.FkDealAssigneeBid]: 'owner',
    [FilterKeys.FkDealPipelineId]: 'pipeline',
    [FilterKeys.FkDealState]: 'state',
    [FilterKeys.FkDealCompletedDate]: 'completedDate',
    [FilterKeys.FkDealCsvExportedDate]: 'dateCsvExported',
    [FilterKeys.FkDealCreatedDate]: 'createdDate',
};
export const filterOperators: GridFilterOperatorsKeys = {
    contains: RawFilterOperators.FopIn,
    regex: RawFilterOperators.FopRegex,
    equal: RawFilterOperators.FopEq,
    lessThan: RawFilterOperators.FopLte,
    greaterThan: RawFilterOperators.FopGte,
};

export const getExportedCsvDateSortingTokenIfApplied = (
    dealsStore: DealsStore,
): SortingOpQl | undefined =>
    dealsStore.sortOptions.find(
        (f) => f.field === SortingKeys.SkDealCsvExportedDate,
    );

export const customSortDealsByCsvExportedDate = (
    minimalDeals: MinimalDeal[],
    sortOption: SortingOpQl,
) => {
    const sorted = sortNewArray(minimalDeals)((a, b) => {
        const aDate = a.exportedDate?.csv
            ? new Date(a.exportedDate?.csv)
            : new Date();
        const bDate = b.exportedDate?.csv
            ? new Date(b.exportedDate?.csv)
            : new Date();

        if (sortOption.sop === SortingOperators.SopAsc) {
            return aDate.getTime() - bDate.getTime();
        }

        return bDate.getTime() - aDate.getTime();
    });

    return sorted;
};

export const EmptyFilterValue = 'EmptyFilterValue';

export interface SalesPeriodFilteringOptionsMap {
    [period: string]:
        | DealAnnualSalesPeriodFragment
        | DealQuarterlySalesPeriodFragment;
}

import { isDateValid } from 'src/utils/datetime.utils';

export const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    if (isDateValid(date)) {
        return date.toLocaleDateString(undefined, {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
        });
    }
};

import { DVCJSON, DVCVariableValue } from '@devcycle/js-client-sdk';
import { DVCVariable } from 'node_modules/@devcycle/js-client-sdk/src/Variable';
import { useEffect, useState } from 'react';

import {
    FeatureFlag,
    FeatureFlagValue,
} from 'src/data/services/feature-flags/feature-flags.model';
import { getDVCClient } from 'src/data/services/feature-flags/feature-flags.service';

type FeatureFlagCallback = (value?: DVCVariableValue) => void;
const callbackMap: Map<FeatureFlag, FeatureFlagCallback[]> = new Map();

/**
 * A hook to resolve the feature flag.
 *
 * @param key Feature flag key. Has to belong to the FeatureFlag enum.
 * @param defaultValue A value to return if the feature flag is not defined.
 * @returns The resolved feature flag value. The returned value is expected to be of same type as the defaultValue.
 */
export const useFeatureFlag = (
    key: FeatureFlag,
    defaultValue: FeatureFlagValue,
    type: 'string' | 'json' = 'string',
) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_, forceRerender] = useState({});
    const dvcClient = getDVCClient();
    const variableRaw = dvcClient.variable(key, defaultValue);
    const variable =
        type === 'string'
            ? (variableRaw as DVCVariable<string>)
            : (variableRaw as DVCVariable<DVCJSON>);

    useEffect(() => {
        const callback = () => forceRerender({});

        const existingCallbacks = callbackMap.get(key);
        if (existingCallbacks) {
            existingCallbacks.push(callback);
        } else {
            callbackMap.set(key, [callback]);
        }

        if (!variable.callback) {
            variable.onUpdate(() => {
                const callbacks = callbackMap.get(key);
                callbacks?.forEach((callback) => callback(variable.value));
            });
        }

        return () => {
            const callbacks = callbackMap.get(key);
            if (callbacks && callbacks.length > 0) {
                callbackMap.set(
                    key,
                    callbacks.filter((c) => c !== callback),
                );
            }
        };
    }, [defaultValue, key, variable]);

    return variable.value;
};

export const useFeatureFlagBool = (key: FeatureFlag) => {
    return useFeatureFlag(key, false).toString().toLowerCase() === 'true';
};

export const useFeatureFlagJSON = (key: FeatureFlag) => {
    return useFeatureFlag(key, {}, 'json');
};

export const useFeatureFlagInt = (key: FeatureFlag, defaultValue: number) => {
    return Number.parseInt(useFeatureFlag(key, defaultValue).toString());
};
export const getFeatureFlagBool = (key: FeatureFlag) => {
    const dvcClient = getDVCClient();
    const variable = dvcClient.variable(
        key,
        false as any,
    ) as DVCVariable<string>;
    return variable.value.toString().toLowerCase() === 'true';
};

export const getFeatureFlagNumber = (key: FeatureFlag) => {
    const dvcClient = getDVCClient();
    const variable = dvcClient.variable(key, 0) as DVCVariable<number>;
    return variable.value;
};

import { AbortParams } from 'src/data/api/api-client';
import {
    PipelineStageStatisticsPartFragment,
    PipelineStatisticsDealPartFragment,
    SortingKeys,
    SortingOperators,
    ValueMilestoneFragment,
} from 'src/data/api/graphql/br_process/generated/graphql-sdk';
import { ProcessGqlSdk } from 'src/data/api/graphql/graphql-client.wrapper';
import { CustomParameter } from 'src/domain/models/custom-parameter/custom-parameter.model';
import { createMinimalDealOfPipeline, MinimalDeal } from 'src/domain/models/deal/deal.model';
import {
    DynamicFilter,
    DynamicFilterMetric,
} from 'src/domain/models/dynamic-filter/dynamic-filter.model';
import {
    createPipeline,
    createStageStatistics,
    Pipeline,
    StageStatistics,
} from 'src/domain/models/pipeline/pipeline.model';

import {
    buildQueryFilters,
    getSafeStageId,
    getStageStatisticsQuery,
} from './pipeline.api.utils';

export interface PipelineViewPreferencesParams {
    usersIds: number[];
    showPausedDeals: boolean;
    customParameterTagOptions: string[];
    stageIds: string[];
}

interface GetPipelineStatisticsParams extends AbortParams {
    pipelineId: string;
    pipelineName: string;
    stageIds: string[];
    viewPreferences?: PipelineViewPreferencesParams;
    dynamicFilters: DynamicFilter[];
    customParameterTag?: CustomParameter;
}

interface GetPipelineDynamicFilterMetricsParams extends AbortParams {
    pipelineId: string;
    viewPreferences?: PipelineViewPreferencesParams;
    customParameterTag?: CustomParameter;
}

export interface PipelineApi {
    getPipelinesData: (params: AbortParams) => Promise<Pipeline[]>;
    getPipelineStatistics: (
        params: GetPipelineStatisticsParams,
    ) => Promise<StageStatistics[]>;
    getPipelineDynamicFilterMetrics: (
        params: GetPipelineDynamicFilterMetricsParams,
    ) => Promise<DynamicFilterMetric[]>;
    getValueMilestones: (
        params: AbortParams,
    ) => Promise<ValueMilestoneFragment[]>;
}

export const createPipelineAPi = (
    processGqlSdk: ProcessGqlSdk,
): PipelineApi => {
    const getPipelinesData = async (
        params: AbortParams,
    ): Promise<Pipeline[]> => {
        const response = await processGqlSdk.AllPipelinesQuery({
            queryParams: {
                sorting: [
                    {
                        field: SortingKeys.SkPipeCreatedDate,
                        sop: SortingOperators.SopAsc,
                    },
                ],
            },
        });
        return response.allPipelines.map(createPipeline);
    };

    const getPipelineStatistics = async ({
        pipelineId,
        pipelineName,
        stageIds,
        viewPreferences,
        dynamicFilters,
        customParameterTag,
        signal,
    }: GetPipelineStatisticsParams): Promise<StageStatistics[]> => {
        const response = await processGqlSdk.rawGraphqlRequest<any>(
            getStageStatisticsQuery(
                stageIds,
                pipelineId,
                buildQueryFilters(viewPreferences, customParameterTag, true),
                dynamicFilters,
            ),
            {
                pipelineId,
                filters: buildQueryFilters(
                    viewPreferences,
                    customParameterTag,
                    false,
                ),
                dynamicFilters,
            },
            { signal },
        );

        if (!response.pipeline) {
            return [];
        }

        return response.pipeline.statistics.stages.map(
            (stageStatistics: PipelineStageStatisticsPartFragment) => {
                const safeStageId = getSafeStageId(stageStatistics.id);
                const dealsPerStage: PipelineStatisticsDealPartFragment[] =
                    response[safeStageId].edges.map(
                        (edge: {
                            node: MinimalDeal;
                        }) => {
                            return { ...edge.node };
                        },
                    );
                const dealsPerStageCount = response[safeStageId].totalCount;
                return createStageStatistics(
                    stageStatistics,
                    dealsPerStage.map((deal) =>
                        createMinimalDealOfPipeline(deal, {
                            id: pipelineId,
                            name: pipelineName,
                        }),
                    ),
                    dealsPerStageCount,
                );
            },
        );
    };

    const getPipelineDynamicFilterMetrics = async ({
        pipelineId,
        viewPreferences,
        customParameterTag,
        signal,
    }: GetPipelineDynamicFilterMetricsParams) => {
        const response = await processGqlSdk.GetDynamicFilterMetrics(
            {
                pipelineId,
                filters: buildQueryFilters(
                    viewPreferences,
                    customParameterTag,
                    true,
                ),
            },
            { signal },
        );
        return response.pipeline.statistics.dynamicFiltersMetrics;
    };

    const getValueMilestones = async ({ signal }: AbortParams) => {
        const response = await processGqlSdk.GetValueMilestones({}, { signal });
        return response.allValueMilestones;
    };

    return {
        getPipelinesData,
        getPipelineStatistics,
        getPipelineDynamicFilterMetrics,
        getValueMilestones,
    };
};

import { BrText } from '@buildingradar/br_component_lib';
import styled from '@emotion/styled';
import { Box } from '@mui/system';

import { Spacing } from 'src/resources/styles/spacing';
import { ZIndex } from 'src/resources/styles/zIndex';

export const DrawerContentStyled = styled(Box)({
    width: '340px',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
});

export const DrawerHeaderStyled = styled(Box)({
    top: 0,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: `${Spacing.s2} ${Spacing.s3}`,
    overflow: 'hidden',
    position: 'sticky',
    background: '#FFFFFF',
    zIndex: ZIndex.z3,
    borderBottom: '1px solid var(--gray-20)',
    margin: '0px -24px',
    flexShrink: 0,
});

export const DrawerBodyStyled = styled(Box)({
    flexGrow: '1',
    overflowY: 'auto',
    overflowX: 'hidden',
    marginTop: Spacing.s2,
});

export const DrawerActionStyled = styled(Box)({
    background: '#FFFFFF',
    display: 'grid',
    gridTemplateColumns: 'auto',
    gap: Spacing.s2,
    padding: `${Spacing.s2} 24px ${Spacing.s4}`,
    position: 'sticky',
    margin: '0px -24px',
    bottom: 0,
    zIndex: ZIndex.z3,
    paddingBottom: Spacing.s4,
    paddingTop: Spacing.s2,
    flexShrink: 0,
});

export const ActionPanelLabelStyled = styled(BrText, {
    shouldForwardProp: (prop) =>
        prop !== 'applyTopMargin' && prop !== 'applyBottomMargin',
})<{
    applyTopMargin?: boolean;
    applyBottomMargin?: boolean;
    required?: boolean;
}>(({ applyTopMargin, applyBottomMargin = true, required }) => ({
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '24px',
    color: 'var(--gray-80)',
    marginTop: applyTopMargin ? Spacing.s3 : 0,
    marginBottom: applyBottomMargin ? Spacing.s1 : 0,

    '&::after': required
        ? {
              content: '"*"',
              color: 'var(--red-60)',
              fontWeight: 'normal',
              marginLeft: '5px',
          }
        : undefined,
}));

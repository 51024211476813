import { BrIcon, IconName } from '@buildingradar/br_component_lib';
import { IconButton } from '@mui/material';
import { observer } from 'mobx-react';
import { FC, useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { Localized } from 'src/presentation/shared/localized/localized';
import { Spacing } from 'src/resources/styles/spacing';
import { IChildren } from 'src/utils/react.utils';

import {
    SecondaryNavBarStyled,
    SecondaryNavBarHeaderStyled,
    BackLink,
    SecondaryNavBarTitleStyled,
} from './secondary-navbar-layout.styled';

interface SecondaryNavbarLayoutProps extends IChildren {
    title: string;
    backLink?: string;
    className?: string;
    onCloseMenu?: () => void;
    onClickBack?: () => void;
}
export const SecondaryNavbarLayout: FC<SecondaryNavbarLayoutProps> = observer(
    ({ title, children, backLink, className, onCloseMenu, onClickBack }) => {
        const history = useHistory();

        const onClickToBack = useCallback(() => {
            if (onClickBack) {
                onClickBack();
            } else if (backLink) {
                history.push(backLink);
            }
        }, [backLink, history, onClickBack]);

        return (
            <SecondaryNavBarStyled className={className + ' secondary-nav-bar'}>
                <SecondaryNavBarHeaderStyled className="nav-bar-header">
                    {!!(backLink || onClickBack) && (
                        <BackLink
                            onClick={onClickToBack}
                            className="back-button"
                        >
                            <IconButton sx={{ mr: Spacing.s2 }}>
                                <BrIcon iconName={IconName.BrArrowBackIcon} />
                            </IconButton>
                        </BackLink>
                    )}
                    <SecondaryNavBarTitleStyled className="header-title">
                        <Localized>{title}</Localized>
                    </SecondaryNavBarTitleStyled>
                    {onCloseMenu && (
                        <IconButton
                            className="toggle-menu"
                            onClick={onCloseMenu}
                        >
                            <BrIcon iconName={IconName.BrMenuMobileOpenIcon} />
                        </IconButton>
                    )}
                </SecondaryNavBarHeaderStyled>
                {children}
            </SecondaryNavBarStyled>
        );
    },
);
